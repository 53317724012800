import { PaginationMeta } from './../../models/Meta/pagination-meta.model';
import * as ApiRoutes from "../../routes/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import {
  CounterParties,
  SendInvitation,
} from "../../models/CounterParties/counterParties.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { generatePath } from "react-router";
import Rating from "../../models/Rating/rating.model";
import { LocalStorage } from "../../shared/utils/localStorageHelpers";

export class HLCounterPartiesService {
  static fetchCounterParties(
    params: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.HL_COUNTER_PARTIES, {
        params: params,
      })
      .then((response) => {
        const data = deserialize(
          CounterParties,
          response.data["counter_parties"]
        );
        const pagination = deserialize(
          PaginationMeta,
          response.data["meta"]
        );
        onSuccess(data, pagination);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in fetching counter parties!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showCounterParty(
    counterPartyId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_SINGLE_COUNTER_PARTY, {
      counterPartyId,
    });
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          CounterParties,
          response.data["counter_party"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in fetching counter party!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createCounterParty(
    counterParty: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .post(ApiRoutes.HL_COUNTER_PARTIES, counterParty)
      .then((response) => {
        const data = deserialize(
          CounterParties,
          response.data["counter_party"]
        );

        Notification({
          message: "Hedge Legal",
          description: "Counter Party created successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in creating counter party!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateCounterParties(
    counterPartyId: any,
    counterParty: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_SINGLE_COUNTER_PARTY, {
      counterPartyId,
    });
    // const serializeData = serialize(CounterParties, counterParty);
    // const payload = { counter_party: serializeData };
    axiosInstance
      .put(API_URL, counterParty)
      .then((response) => {
        const data = deserialize(
          CounterParties,
          response.data["counter_party"]
        );

        Notification({
          message: "Hedge Legal",
          description: "Counter Party updated successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in updating counter party!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static sendCounterPartyInvitation(
    counterPartyId: any,
    invitation: SendInvitation,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_SINGLE_COUNTER_PARTY, {
      counterPartyId,
    });
    const payload = serialize(SendInvitation, invitation);
    axiosInstance
      .put(API_URL, payload)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in sending invitation!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteCounterParty(
    counterPartyId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_SINGLE_COUNTER_PARTY, {
      counterPartyId,
    });
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in updating counter party!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
export class HFCounterPartiesService {
  static getUserType() {
    const user = LocalStorage.getItem("USER");
    return user?.role?.includes("hf") ? "hf" : "hl";
  }

  static fetchCounterParties(
    params: {
      name?: string;
      meta?: boolean
      is_suggested?: boolean;
      order_by?: "recently_viewed" | "rating" | "agreements";
      order_direction?: "asc" | "dsc" | "desc";
      page?: number;
      company_id?: number | string;
      per_page?: number;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.HF_COUNTER_PARTIES, {
        params: params,
      })
      .then((response) => {
        const data = deserialize(
          CounterParties,
          response.data["counter_parties"]
        );
        const pagination = deserialize(
          PaginationMeta,
          response.data["meta"]
        );
        onSuccess(data, pagination);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in fetching counter parties!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showCounterParty(
    counterPartyId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HF_SINGLE_COUNTER_PARTY, {
      counterPartyId,
    });
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          CounterParties,
          response.data["counter_party"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in fetching counter party!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createCounterParty(
    counterParty: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    // const serializeData = serialize(CounterParties, counterParty);
    // const payload = { counter_party: serializeData };
    axiosInstance
      .post(ApiRoutes.HF_COUNTER_PARTIES, counterParty)
      .then((response) => {
        const data = deserialize(
          CounterParties,
          response.data["counter_party"]
        );

        Notification({
          message: "Hedge Legal",
          description: "Counter Party suggested successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in creating counter party!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateCounterParties(
    counterParty: CounterParties,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HF_SINGLE_COUNTER_PARTY, {
      counterPartyId: counterParty.id,
    });
    const serializeData = serialize(CounterParties, counterParty);
    const payload = { counter_party: serializeData };
    axiosInstance
      .put(API_URL, payload)
      .then((response) => {
        const data = deserialize(
          CounterParties,
          response.data["counter_party"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in updating counter party!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteCounterParty(
    counterPartyId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HF_SINGLE_COUNTER_PARTY, {
      counterPartyId,
    });
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in updating counter party!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static async postReview(
    id: string,
    review: Rating,
    onSuccess: (review: Rating) => void,
    onFinal: () => void
  ) {
    try {
      const reviewJSON = {
        review: serialize(review),
      };
      const { data } = await axiosInstance.post(
        ApiRoutes.POST_REVIEW?.replace(
          ":userRole",
          this.getUserType()
        )?.replace(":id", id),
        reviewJSON
      );
      const createdReview = deserialize(Rating, data?.rating);
      onSuccess(createdReview);
    } catch (error) {
      console.log(error?.message);
    } finally {
      onFinal();
    }
  }
}
