import { Tag, Space } from "antd";
import React, { useEffect, useState } from "react";
import { TableComponent } from "../../../../../shared/components/TableComponent";
import Notification from "../../../../../shared/components/Notification";
import PlaceholderIcon from "../../../../../assets/placeholder/avatar_placeholder.png";
import EditIcon from "../../../../../assets/icons/edit.svg";
import DeleteIcon from "../../../../../assets/icons/delete.svg";
import moment from "moment";
import { HLFundsService } from "../../../../../services/FundService/Fund.service";
import { Fund } from "../../../../../models/Fund/fund.model";
import { NotificationTypes } from "../../../../../enums/notificationTypes";
import { FundStatusBE } from "../../../../../enums/fundStatuses";
import FundForm from "../FundForm";
import { ConfirmationAlert } from "../../../../../shared/components/ConfirmationAlert";
import { useHistory, useLocation, useParams } from "react-router";
import { FUNDS_SPECIFIC } from "./../../../../../routes/appRoutes";
import { sorter, dateSorter } from "../../../../../shared/utils/tableSorter";
import { aumConvertor } from "../../../../../shared/utils/stringToNumber";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";
import { PaginationMeta } from "../../../../../models/Meta/pagination-meta.model";
interface PreLaunchFundsProps {
  title: string;
  newFund?: Fund;
}

const PreLaunchFunds = (props: PreLaunchFundsProps) => {
  const { title, newFund } = props;
  const history = useHistory();

  const [funds, setFunds] = useState<Fund[]>([]);
  const [pagination, setPagination] = useState<PaginationMeta>();
  const [selectedFund, setSelectedFund] = useState<Fund>();
  const [searchParams, setSearchParams] = useState<{ search?: string, page?: number }>();
  const [formVisibility, setFormVisibility] = useState(false);
  const [showDelete, setDeleteVisibility] = useState(false);
  const [loading, setLoading] = useState(false);

  const URLParams = useParams<{ id: string }>();
  const user = LocalStorage.getItem("USER");

  const searchHandler = (search: any) => {
    setSearchParams({ search, page: 1 });
  };

  useEffect(() => {
    if (!newFund) { return }
    setFunds(prevFunds => [...prevFunds, newFund])
  }, [newFund])

  useEffect(() => {
    fetchFunds()
  }, [searchParams]);

  const fetchFunds = () => {
    setLoading(true)
    const params = {
      page: searchParams?.page || 1,
      status: FundStatusBE.PRELAUNCH,
      company_ids: URLParams?.id ? [URLParams?.id] : []
    };
    if (searchParams?.search) {
      params["search_text"] = searchParams?.search || "";
    }
    HLFundsService.fetchFunds(
      params,
      (funds: Fund[], pagination: PaginationMeta) => {
        setFunds(funds);
        setPagination(pagination)
      },
      (error) => {
        setFunds([]);
        // Notification({
        //   message: "Hedge Legal",
        //   description: "Unable to fetch funds",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => {
        setLoading(false)
      }
    );
  }

  const editHandler = (record: Fund) => {
    setSelectedFund(record);
    setFormVisibility(true);
  };

  const deleteHandler = (id: number, companyId: number) => {
    HLFundsService.deleteFund(
      id,
      { companyId: companyId?.toString() },
      (res) => {
        fetchFunds()
        Notification({
          message: "Hedge Legal",
          description: "Fund deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
      },
      (err) => {
        // Notification({
        //   message: "Hedge Legal",
        //   description: "Fund deletion failed",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => {
        setDeleteVisibility(false);
      }
    );
  };

  return (
    <>
      <TableComponent
        className=" row-clickable"
        onRow={(index, record) => {
          if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == parseInt(URLParams['id']))?.length == 0)) {
            Notification({
              message: "Hedge Legal",
              description: "Access Restricted",
              type: NotificationTypes.ERROR,
            });
          } else {
            LocalStorage.setItem('HL_SELECTED_COMPANY_ID', record.companyId)
            history.push(
              FUNDS_SPECIFIC.replace(":fundId", record.id.toString()),
              { fund: record }
            );
          }
        }}
        onChange={pagination => setSearchParams(prev => ({ ...prev, page: pagination?.current }))}
        loading={loading}
        pagination={{
          pageSize: 20,
          total: pagination?.totalCount,
          current: pagination?.currentPage,
          showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
        }}
        onSearch={searchHandler}
        data={funds}
        scroll={{ x: 1100 }}
        title={title}
        columns={[
          {
            title: "COMPANY NAME",
            dataIndex: "companyName",
            key: "companyName",
            width: 200,
            sorter: (a, b) => sorter(a.name, b.name),
            sortDirections: ["ascend", "descend"],
            render: (text, record: Fund) => (
              <span
              >
                {text}
              </span>
            ),
          },
          {
            title: "NAME",
            dataIndex: "name",
            key: "name",
            width: 200,
            sorter: (a, b) => sorter(a.name, b.name),
            sortDirections: ["ascend", "descend"],
            render: (text, record: Fund) => (
              <span
                className="fund-details--route"
                onClick={() => {
                  LocalStorage.setItem('HL_SELECTED_COMPANY_ID', record.companyId)
                  history.push(
                    FUNDS_SPECIFIC.replace(":fundId", record.id.toString()),
                    { fund: record }
                  );
                }}
              >
                {text}
              </span>
            ),
          },
          {
            title: "STRATEGY",
            dataIndex: "strategy",
            key: "strategy",
            width: 150,
            sorter: (a, b) => sorter(a.strategy, b.strategy),
            sortDirections: ["ascend", "descend"],
            render: (text) => {
              return <p>{text?.replaceAll("_", " ")}</p>
            }
          },
          {
            title: "TARGET AUM",
            key: "targetAum",
            dataIndex: "targetAum",
            width: 150,
            sorter: (a, b) =>
              sorter(
                a.targetAum,
                b.targetAum,
              ),
            sortDirections: ["ascend", "descend"],
            render: (text) => {
              return <p>{aumConvertor(text)}</p>
            }
          },
          {
            title: "TARGET LAUNCH DATE",
            key: "targetLaunchDate",
            dataIndex: "targetLaunchDate",
            width: 200,
            sorter: (a, b) =>
              dateSorter(a.targetLaunchDate, b.targetLaunchDate),
            sortDirections: ["ascend", "descend"],
            render: (text) =>
              text ? <p>{moment(text).format("MMM DD, YYYY")}</p>
                : ""

          },
          {
            title: "PROGRESS",
            dataIndex: "progress",
            key: "progress",
            width: 150,
            sorter: (a, b) =>
              sorter(
                a.progress,
                b.progress
              ),
            sortDirections: ["ascend", "descend"],
            render: (text) => {
              return <p>{text} %</p>
            }
          },
          {
            title: "",
            key: "action",
            width: 50,
            render: (text, record) => (
              <Space size="middle"
                className="table-actions"
                onClick={e => e.stopPropagation()}
              >

                <i
                  className="icon-edit"
                  onClick={() => {
                    if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == parseInt(URLParams['id']))?.length == 0)) {
                      Notification({
                        message: "Hedge Legal",
                        description: "Access Restricted",
                        type: NotificationTypes.ERROR,
                      });
                    } else {
                      editHandler(record)
                    }
                  }}
                  title="Edit"
                />
                <i
                  className="icon-delete"
                  onClick={() => {
                    if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == parseInt(URLParams['id']))?.length == 0)) {
                      Notification({
                        message: "Hedge Legal",
                        description: "Access Restricted",
                        type: NotificationTypes.ERROR,
                      });
                    } else {
                      setSelectedFund(record)
                      setDeleteVisibility(true);
                    }
                  }}
                  title="Delete"
                />
              </Space>
            ),
          },
        ]}
      />
      <ConfirmationAlert
        closeHandler={() => setDeleteVisibility(false)}
        visible={showDelete}
        type="delete"
        deleteHandler={() => deleteHandler(selectedFund?.id, selectedFund?.companyId)}
        cancelHandler={() => setDeleteVisibility(false)}
        title={"Delete Fund"}
        description={selectedFund?.name}
      />
      <FundForm
        title={"Edit fund"}
        visible={formVisibility}
        closeHandler={() => {
          fetchFunds();
          setFormVisibility(false);
        }}
        data={selectedFund}
      ></FundForm>
    </>
  );
};

export default PreLaunchFunds;
