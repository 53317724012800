import { Form, Row, Col, Checkbox } from "antd";
import { Formik, Field } from "formik";
import React, { useEffect, useState } from "react";
import { AttachmentComponent } from "../../../../../../../shared/components/AttachmentComponent";
import { UIModal } from "../../../../../../../shared/components/UIModal";
import Notification from "../../../../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../../../../enums/notificationTypes";
import { HLAgreementService } from "../../../../../../../services/AgreementService/agreement.service";
import { Agreement, Amendment } from "../../../../../../../models/Agreements/agreement.model";
import moment from "moment";
import DatePickerComponent from "../../../../../../../shared/components/DatePickerComponent";
import InputField from "../../../../../../../shared/components/InputField";
import UppyFileUploader from "../../../../../../../shared/components/UppyFileUploader";

interface AmendmentFormProps {
  visible: boolean
  agreement: Agreement
  closeHandler: (agreement?: any) => void
}

const AmendmentForm = (props: AmendmentFormProps) => {
  const { visible, closeHandler, agreement } = props

  const [amendmentFormData, setAmendmentFormData] = useState(new Amendment())
  const [amendmentStatus, setAmendmentStatus] = useState<[]>([]);
  const [amendmentFileName, setAmendmentFileName] = useState<string>('');
  const [amendmentUploadUrl, setAmendmentUploadUrl] = useState<string>('');
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);

  const onSubmit = (values: any, resetForm: Function) => {
    setButtonLoader(true);
    if (amendmentFileName) {
      values.document = amendmentFileName;
      values.isOriginal = false;
    }
    HLAgreementService.createAmendment(
      values,
      agreement?.id?.toString(),
      (response) => {
        Notification({
          message: "Hedge Legal",
          description: "Document created successfully",
          type: NotificationTypes.SUCCESS,
        });
        resetForm();
        closeHandler(response);
      },
      (error) => {
        Notification({
          message: "Hedge Legal",
          description: "Unable to add document",
          type: NotificationTypes.ERROR,
        });
      },
      () => {
        setButtonLoader(false)
      }
    );
  };

  return (
    <Formik
      initialValues={amendmentFormData}
      onSubmit={() => { }}
    >
      {({ setFieldValue, values, resetForm, isValid, dirty }) => {
        return (
          <UIModal
            className="amendment-form__modal"
            visible={visible}
            closeModal={closeHandler}
            title={
              (amendmentFormData?.id ? "Edit " : "Add New ") + " Amendment Document"
            }
            submitProps={{
              text: amendmentFormData?.id ? "Update " : "Add",
              clickHandler: () => {
                onSubmit(values, resetForm);
                closeHandler();
                setAmendmentFileName('');
                setAmendmentUploadUrl('');
              },
              disabled: !isValid || !dirty || !amendmentUploadUrl,
              loading: buttonLoader
            }}
            cancelProps={{
              text: "Cancel",
              clickHandler: () => {
                resetForm();
                closeHandler();
                setAmendmentFileName('');
                setAmendmentUploadUrl('');
              },
            }}
          >
            <Form>
              <div className="amendment-form__container">

                <Row>
                  <Col sm={24} className="amendment-form__field">
                    <InputField
                      placeholder={"Name of Amendment"}
                      name="name"
                      type="text"
                      value={values?.name}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} className="amendment-form__field">
                    <DatePickerComponent
                      name="date"
                      placeholder="Date"
                      className="input-field"
                      value={values?.date && moment(values?.date)}
                      onChange={(value, string) => {
                        setFieldValue("date", string);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm={24}
                    className="amendment-form__field is-fully-executed"
                  >
                    <Field name="isFullyExecuted">
                      {({ field }) => (
                        <Checkbox {...field} >
                          Agreement is fully executed
                        </Checkbox>
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm={24}
                    className="amendment-form__field amendment-form__field--file-upload"
                  >
                    <Field name="document" as="file-upload">
                      {/*<AttachmentComponent
                        image={values?.documentUrl}
                        accept="application/pdf"
                        onUpload={(file) => {
                          setFieldValue("document", file)
                          setFieldValue('isOriginal', false)
                        }}
                        title="Drag & Drop / Click here to upload PDF File"
                      />*/}
                      <UppyFileUploader setFileUploadUrl={setAmendmentUploadUrl}
                        setFileUploadName={setAmendmentFileName}
                        fileUploadUrl={amendmentUploadUrl}
                      />
                    </Field>
                  </Col>
                </Row>
              </div>
            </Form>
          </UIModal>
        );
      }}
    </Formik>
  )
}

export default AmendmentForm
