import React, { FC } from "react";
import "./cpGroupCard.scss";
interface CPGroupCardProps {
  logo: string;
  name: string;
  onClick: () => void;
}
export const CPGroupCard: FC<CPGroupCardProps> = ({ logo, name, onClick }) => {
  return (
    <div className="cp-group-card__container" onClick={onClick}>
      <div className="cp-group-card-image__wrapper">
        <img src={logo} />
      </div>
      <p>{name}</p>
    </div>
  );
};
