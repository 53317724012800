import { Modal } from "antd";
import React from "react";
import { Ticket } from "../../../models/Ticket/ticket.model";
import "./ticketModal.scss"

interface TicketModalProps {
    visible: boolean
    onClose: () => void
    ticketData: Ticket
}

const TicketModal = (props: TicketModalProps) => {

    const {
        visible,
        onClose,
        ticketData,
    } = props

    return <Modal
        className="ticket-modal__modal"
        visible={visible}
        onCancel={onClose}
    >
        <div className="ticket-modal__header">
            <p className="ticket-modal__header--title">{ticketData?.raisedByCompany}</p>
            <p className="ticket-modal__header--subtitle">{ticketData?.raisedBy}</p>
        </div>
        <div className="ticket-modal__body">
            <div className="ticket-modal__body--details">
                <p className="ticket-modal__body--details-title">Date</p>
                <p className="ticket-modal__body--details-subtitle">{ticketData?.raisedAt}</p>
            </div>
            <div className="ticket-modal__body--details">
                <p className="ticket-modal__body--details-title">User Assigned</p>
                <p className="ticket-modal__body--details-subtitle blue">{ticketData?.processedBy}</p>
            </div>
            <div className="ticket-modal__body--details">
                <p className="ticket-modal__body--details-title">Status</p>
                <p className="ticket-modal__body--details-subtitle red">{ticketData?.status}</p>
            </div>
        </div>
        <div className="ticket-modal__description">
            <p className="ticket-modal__description--title">
                {ticketData?.title}
            </p>
            <p className="ticket-modal__description--content">{ticketData?.description}</p>
        </div>
    </Modal>
}

export default TicketModal