import React, { FC, useEffect, useState } from "react";
import { Col, Empty, Pagination, Row, Spin } from "antd";
import faker from "faker";
import CPCard from "../CPCard";
import CPForm from "../CPForm";
import AuthContainer from "../../../../../store/containers/AuthContainer";
import { roles } from "../../../../../shared/components/HOC/roles";
import { CounterParties } from "../../../../../models/CounterParties/counterParties.model";
import {
  HFCounterPartiesService,
  HLCounterPartiesService,
} from "../../../../../services/CounterPartiesService/CounterParties.service";
import { CP_DETAILS } from "../../../../../routes/appRoutes";
import { withRouter } from "react-router";
import { PaginationMeta } from "../../../../../models/Meta/pagination-meta.model";
interface CPListProps {
  refresh: boolean;
  searchText: string;
}
const HLCPList: FC<CPListProps> = (props: any) => {
  const { refresh, history, searchText } = props;
  const [cpList, setCpList] = useState<CounterParties[]>([]);
  const [pagination, setPagination] = useState<PaginationMeta>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const searchTimeOut = setTimeout(() => {
      fetchCPList(1);
    }, 500);
    return () => {
      clearTimeout(searchTimeOut);
    };
  }, [searchText, refresh]);

  const fetchCPList = (page: number) => {
    setLoading(true);
    HLCounterPartiesService.fetchCounterParties(
      {
        name: searchText,
        page
      },
      (response, pagination) => {
        setCpList(response);
        setPagination(pagination)
      },
      () => { },
      () => {
        setLoading(false);
      }
    );
  };

  const CPListTemplate = () => {
    let generatedList = cpList.map((value, index) => {
      return (
        <Col span={12}>
          <CPCard
            cpLogo={value.logoUrl}
            cpName={value.name}
            cpAddress={`${value.address?.street}, ${value.address?.addressLine2}, ${value.address?.cityName}, ${value.address?.provinceName}, ${value.address?.countryName}`}
            cpRating={value.totalRating}
            cpReviews={value.reviewCount}
            cpProducts={value.productsAvailable}
            onClick={() =>
              history.push(CP_DETAILS.replace(':cpId', value?.id?.toString()), {
                name: value.name,
              })
            }
          />
        </Col>
      );
    });
    if (!generatedList.length)
      generatedList = [<Empty className="center-align" />];
    else
      generatedList.push(<Col span={24}>
        <Pagination
          showSizeChanger={false}
          pageSize={20}
          current={pagination?.currentPage || 1}
          total={pagination?.totalCount || 0}
          showTotal={(total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>}
          onChange={page => {
            fetchCPList(page)
          }}
        />
      </Col>)
    return generatedList;
  };

  return (
    <Row gutter={[24, 24]}>
      {!loading && CPListTemplate()}
      {loading && (
        <div className="loading-spinner">
          <Spin />
        </div>
      )}
    </Row>
  );
};
export default withRouter(AuthContainer(HLCPList));
