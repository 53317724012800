import { Card, Table, Tooltip } from "antd";
import React, { FC, useCallback, useEffect, useState } from "react";
import { CounterParties } from "../../../../../models/CounterParties/counterParties.model";
import "../CPBrokers/CPBrokers.scss";
import { generatePath, useHistory } from "react-router";
import { REPORTS_SPECIFIC,REPORTS} from "../../../../../routes/appRoutes";
import { useVT } from "virtualizedtableforantd4";
import { IAuthState } from "../../../../../store/reducers/authReducer";
import AuthContainer from "../../../../../store/containers/AuthContainer";
import { ReportsService } from "../../../../../services/ReportsService/reports.service";
import { ReportHistory } from "../../../../../models/Report/report.model";
import moment from "moment";


interface DashboardReportsProps extends IAuthState  {
  companyId?: string
}

const DashboardReports: FC<DashboardReportsProps> = (props) => {
  const {
    companyId,
    userType,
  } = props
  

  
  const isAdmin = userType === 'HL'
  const history = useHistory();

  const [loader, setLoader] = useState(false);

  const [page, setPage] = useState(1);

  const [hasMore, setHasMore] = useState(true);
  const [reportHistory, setReportHistory] = useState<ReportHistory[]>([]);
  const [historyLoading, setHistoryLoading] = useState<boolean>(false);

  const [vt] = useVT(
    () => ({
      onScroll({ isEnd }) {
        if (isEnd && hasMore) setPage((page) => ++page);
      },
      scroll: {
        y: 400,
      },
    }),
    [hasMore]
  );
  
  const fetchReportHistory = () => {
    setHistoryLoading(true)
    if (isAdmin) {
        ReportsService.fetchHLReportHistory(
            (reportHistories: ReportHistory[]) => {
                setReportHistory(reportHistories);
            },
            () => { },
            () => {
                setHistoryLoading(false)
            }
        )
    } else {
        ReportsService.fetchHFReportHistory(
            (reportHistories: ReportHistory[]) => {
                setReportHistory(reportHistories);
            },
            () => { },
            () => {
                setHistoryLoading(false)
            }
        )
    }
}



  useEffect(() => {

    fetchReportHistory();
  }, []);

  const columns = [
    {
      title: "DATE OF REPORT",
      dataIndex: "date",
      key: "date",
      width: 150,
      ellipsis: { showTitle: false },
      render: text => <Tooltip placement="topLeft" title={text ? moment(text).format("MMM D, YYYY") : ""}>
      {text ? moment(text).format("MMM D, YYYY") : ""} </Tooltip>,
      

  },
  {
      title: "TYPE",
      dataIndex: "agreementType",
      key: "agreementType",
      width: 150,
      ellipsis: { showTitle: false },

  },
 
  ];

  const handleExplore = () => {
    history.push(REPORTS)
  };

  const handleRedirectToCPDetail = (cp: CounterParties) => {
    return {
      onClick: () => {
        history.push(REPORTS_SPECIFIC.replace(":reportId", String(cp?.id)))
   
      },
    };
  };

  return (
    <Card
      className="reports"
      title="Reports"
      extra={<span onClick={handleExplore}>Explore</span>}
    >
      <Table
        bordered
        components={vt}
        pagination={false}
        columns={columns}
        dataSource={reportHistory}
        loading={historyLoading}
        onRow={handleRedirectToCPDetail}
        scroll={{ scrollToFirstRowOnChange: false, y: 400 }}
      />
    </Card>
  );
};

export default AuthContainer(DashboardReports);
