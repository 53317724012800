import React, { FC } from "react";
import "./redeemDoxConfirmation.scss";

interface RedeemDoxConfirmationProps {
  closeHandler: () => void;
}

const RedeemDoxConfirmation: FC<RedeemDoxConfirmationProps> = (props) => {
  const { closeHandler } = props;

  return (
    <div className="redeem-dox-confirmation" onClick={closeHandler}>
      <div className="redeem-dox-confirmation__header">
        <div className="container">
          <i className="icon-like" />
          <p>Congratulations!</p>
        </div>
      </div>
      <div className="redeem-dox-confirmation__content">
        <p className="main">
          You have just redeemed your DoX Coins for Key Term Extraction service
          for free
        </p>

        <p className="note">Our team will be in touch shortly.</p>
      </div>
    </div>
  );
};

export default RedeemDoxConfirmation;
