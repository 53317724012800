import { useState } from "react";
import { generatePath } from "react-router";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { Comment } from "../../models/Comment/comment.model";
import { Reply } from "../../models/Reply/reply.model";
import { Topic } from "../../models/Topic/topic.model";
import {
  DELETE_REPLY,
  DIS_LIKE_COMMENT,
  LIKE_COMMENT,
  LIST_COMMENT_REPLIES,
  LIST_COMPANY_TOPICS,
  LIST_MY_TOPICS,
  LIST_TOPICS,
  LIST_TOPIC_COMMENT,
  VIEW_TOPIC,
} from "../../routes/apiRoutes";
import { LocalStorage } from "../../shared/utils/localStorageHelpers";

type ListType = "explore" | "myTopic" | "myCompanyTopic";
interface ListTopicOptions {
  search_text?: string;
  company_id?: string
  order_by?: "recently_viewed";
  order_direction?: "asc" | "dsc";
  page?: number;
  per_page?: number;
}

const DiscussionBoardService = () => {
  const [currentTopic, setTopic] = useState<Topic>();

  const [topics, setTopics] = useState<Topic[]>([]);

  const [comments, setComments] = useState<Comment[]>([]);

  const [replies, setReplies] = useState<Reply[]>([]);

  const [loading, setLoading] = useState(false);

  const getUserType = () => {
    const user = LocalStorage.getItem("USER");
    return user?.role?.includes("hf") ? "hf" : "hl";
  };

  const getURL = (type: ListType) => {
    switch (type) {
      case "myTopic":
        return LIST_MY_TOPICS;
      case "myCompanyTopic":
        return LIST_COMPANY_TOPICS;
      default:
        return LIST_TOPICS;
    }
  };

  const getTopics = async (type: ListType, params?: ListTopicOptions) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        getURL(type).replace(":userRole", getUserType()),
        {
          params,
        }
      );
      const topics = deserialize(Topic, data?.topics as Topic[]);
      setTopics(topics);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getTopic = async (topicId: string) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        VIEW_TOPIC.replace(":userRole", getUserType())?.replace(":id", topicId)
      );
      const topic = deserialize(Topic, data?.topic);
      setTopic(topic);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const TopicLikeOrDisLike = async (
    topicId: string,
    type: "like" | "dislike"
  ) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        VIEW_TOPIC.replace(":userRole", getUserType())?.replace(
          ":id",
          topicId
        ) +
        "/" +
        type
      );
      return true;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const createTopic = async (topic: Topic) => {
    try {
      setLoading(true);
      const topicJSON = {
        topic: serialize(topic),
      };
      const { data } = await axiosInstance.post(
        LIST_TOPICS.replace(":userRole", getUserType()),
        topicJSON
      );
      const newTopic = deserialize(Topic, data?.topic as Topic);
      setTopics([newTopic, ...topics]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const deleteTopics = async (topicId: number) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.delete(
        LIST_TOPICS.replace(":userRole", getUserType()) + "/" + topicId
      );
      return true;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getComments = async (topicId: number) => {
    try {
      setLoading(true);
      const API_URL = generatePath(
        LIST_TOPIC_COMMENT.replace(":userRole", getUserType()),
        {
          id: topicId,
        }
      );
      const { data } = await axiosInstance.get(API_URL);
      const comments = deserialize(Comment, data?.replies as Comment[]);
      setComments(comments);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const rateComment = async (commentId: number, type: "like" | "dislike") => {
    try {
      setLoading(true);
      const API_URL = generatePath(
        (type === "like" ? LIKE_COMMENT : DIS_LIKE_COMMENT).replace(
          ":userRole",
          getUserType()
        ),
        {
          id: commentId,
        }
      );
      await axiosInstance.get(API_URL);
      return true;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const postComment = async (topicId: string, comment: Comment) => {
    try {
      setLoading(true);
      const API_URL = generatePath(
        LIST_TOPIC_COMMENT.replace(":userRole", getUserType()),
        {
          id: topicId,
        }
      );

      const commentJSON = {
        reply: serialize(comment),
      };

      await axiosInstance.post(API_URL, commentJSON);
      return true;
    } catch (error) {
      console.log(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const updateComment = async (topicId: number, comment: Comment) => {
    try {
      setLoading(true);
      const API_URL = generatePath(
        LIST_TOPIC_COMMENT.replace(
          ":userRole",
          getUserType() + "/" + comment?.id
        ),
        {
          id: topicId,
        }
      );

      const commentJSON = {
        reply: serialize(comment),
      };
      await axiosInstance.put(API_URL, commentJSON);
      return true;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const deleteReply = async (replyId: number) => {
    try {
      setLoading(true);
      const API_URL =
        DELETE_REPLY.replace(":userRole", getUserType()) + "/" + replyId;
      await axiosInstance.delete(API_URL);
      return replyId;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getReply = async (replyId: number) => {
    try {
      setLoading(true);
      const API_URL = generatePath(
        LIST_COMMENT_REPLIES.replace(":userRole", getUserType()),
        {
          id: replyId,
        }
      );
      const { data } = await axiosInstance.get(API_URL);
      const replies = deserialize(Reply, data?.replies as Reply[]);
      setReplies(replies);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const postReply = async (replyId: number, reply: Reply) => {
    try {
      setLoading(true);
      const API_URL = generatePath(
        LIST_COMMENT_REPLIES.replace(":userRole", getUserType()),
        {
          id: replyId,
        }
      );

      const replyJSON = {
        reply: serialize(reply),
      };
      await axiosInstance.post(API_URL, replyJSON);
      return true;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    topics,
    comments,
    replies,
    currentTopic,
    getTopics,
    getComments,
    getReply,
    postComment,
    updateComment,
    postReply,
    createTopic,
    rateComment,
    getTopic,
    TopicLikeOrDisLike,
    deleteTopics,
    deleteReply,
  };
};

export default DiscussionBoardService;
