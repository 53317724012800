import { serializable, alias, primitive } from 'serializr';

export class Ticket {

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('title', primitive()))
    title?: string;

    @serializable(alias('raised_by_email', primitive()))
    email?: string;

    @serializable(alias('description', primitive()))
    description?: string;

    @serializable(alias('status', primitive()))
    status?: string;

    @serializable(alias('hfund_company', primitive()))
    hfundCompany?: string;

    @serializable(alias('hfund_company_id', primitive()))
    hfundCompanyId?: number;

    @serializable(alias('raised_by_company', primitive()))
    raisedByCompany?: string;

    // @serializable(alias('raised_by_company_logo', primitive()))
    // raisedByCompanyLogo?: string;

    @serializable(alias('raised_by', primitive()))
    raisedBy?: string;

    @serializable(alias('processed_by', primitive()))
    processedBy?: string;

    @serializable(alias('raised_at', primitive()))
    raisedAt?: string;

    @serializable(alias('raised_by_id', primitive()))
    raisedById?: string;

    @serializable(alias('processed_by_id', primitive()))
    processedById?: string;

}

export class SupportTicket {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('title', primitive()))
    title?: string;

    @serializable(alias('description', primitive()))
    description?: string;

    @serializable(alias('status', primitive()))
    status?: string = "open";

    @serializable(alias('raised_at', primitive()))
    raisedAt?: string;
}