import React, { FC, useState } from "react";
import { PageHeaderComponent } from "../../../../../shared/components/PageHeader";
import { GeneralItems } from "./GeneralItem";
import { ISDAProtocols } from "./ISDAProtocols";
import { OnboardingTemplate } from "./OnboardingTemplate";
const OnboardingConfig: FC = () => {
  const [tabKey, setTabKey] = useState("1");
  return (
    <>
      <PageHeaderComponent
        title={"Onboarding Items Configuration"}
        showTabs
        tabs={["General Item", "ISDA Protocols", "Onboarding Template"]}
        onChangeTab={(key) => setTabKey(key)}
      />
      <div className="page-body">
        {tabKey == "1" ? <GeneralItems /> : tabKey == "2" ? <ISDAProtocols/> : <OnboardingTemplate />}
      </div>
    </>
  );
};
export default OnboardingConfig;
