import * as ApiRoutes from "../../routes/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { CounterPartiesGroups } from "../../models/CounterParties/counterPartiesGroups.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { generatePath } from "react-router";

export default class CounterPartiesGroupsService {
  static fetchCounterPartiesGroups(
    params: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.HL_COUNTER_PARTY_GROUPS, {
        params
      })
      .then((response) => {
        const data = deserialize(
          CounterPartiesGroups,
          response.data["cp_groups"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in fetching counter party groups!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showCounterPartiesGroup(
    counterPartyGroupId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HF_SINGLE_COUNTER_PARTY_GROUP, {
      counterPartyGroupId,
    });
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          CounterPartiesGroups,
          response.data["cp_group"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in fetching counter party group!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createCounterPartiesGroups(
    counterPartyGroup: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {

    axiosInstance
      .post(ApiRoutes.HL_COUNTER_PARTY_GROUPS, counterPartyGroup, { headers: { "Content-Type": "multipart/form-data" } })
      .then((response) => {
        const data = deserialize(
          CounterPartiesGroups,
          response.data["cp_group"]
        );

        Notification({
          message: 'Hedge Legal',
          description: "Counter Party Group created successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in creating counter party group!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateCounterPartiesGroups(
    counterPartyGroupId: any,
    counterPartyGroup: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HF_SINGLE_COUNTER_PARTY_GROUP, {
      counterPartyGroupId
    });
    // const serializeData = serialize(CounterPartiesGroups, counterPartyGroup);
    // const payload = { cp_group: serializeData };
    axiosInstance
      .put(API_URL, counterPartyGroup, { headers: { "Content-Type": "multipart/form-data" } })
      .then((response) => {
        const data = deserialize(
          CounterPartiesGroups,
          response.data["cp_group"]
        );

        Notification({
          message: 'Hedge Legal',
          description: "Counter Party Group updated successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in updating counter party group!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteCounterPartiesGroups(
    counterPartyGroupId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HF_SINGLE_COUNTER_PARTY_GROUP, {
      counterPartyGroupId,
    });
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in deleting counter party group!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
