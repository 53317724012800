import { Button, Col, Row } from "antd";
import { Formik, Form } from "formik";
import React, { FC } from "react";
import { Company } from "../../../../models/Company/company.model";
import InputField from "../../../../shared/components/InputField";
import "./DoXCoinsForm.scss";

interface DoxCoinsFormProps {
  company: Company;
  closeHandler: () => void;
  submitHandler: (company: Company) => void;
}

const DoxCoinsForm: FC<DoxCoinsFormProps> = (props) => {
  const { company, submitHandler, closeHandler } = props;

  const handleSubmit = (values: Company) => {
    const company = Object.assign(new Company(), values);
    submitHandler(company);
  };

  return (
    <div className="dox-coins-form">
      <Formik
        initialValues={company ?? new Company()}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ values }) => <Form>
          <InputField
            showLabel
            type="text"
            name="doxCoins"
            value={values.doxCoins}
            placeholder="Enter DoX Coins"
            className="mb-2"
          />
          <InputField
            showLabel
            type="text"
            name="reason"
            value={values.reason}
            placeholder="Enter reason"
            className="mb-2"
          />
          <Row gutter={[20, 20]} className="mt-2">
            <Col span={12} onClick={closeHandler}>
              <Button className="full-width">Cancel</Button>
            </Col>
            <Col span={12}>
              <Button className="full-width" type="primary" htmlType="submit">
                Edit
              </Button>
            </Col>
          </Row>
        </Form>}
      </Formik>
    </div>
  );
};

export default DoxCoinsForm;
