import { CaretDownOutlined, EditOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Progress } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FundStatusBE } from "../../../../../enums/fundStatuses";
import { NotificationTypes } from "../../../../../enums/notificationTypes";
import { Fund } from "../../../../../models/Fund/fund.model";
import { ProfileModel } from "../../../../../models/HFund/hfund.model";
import { RegionMetaModel } from "../../../../../models/Meta/meta.model";
import { HLFundsService } from "../../../../../services/FundService/Fund.service";
import { MetaService } from "../../../../../services/MetaService/meta.service";
import ButtonComponent from "../../../../../shared/components/ButtonComponent";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";
import FundForm from "../../Funds/FundForm";
import TerminationForm from "./TerminationForm";
import Notification from "../../../../../shared/components/Notification";
import { aumConvertor } from "../../../../../shared/utils/stringToNumber";

interface GeneralInfoProps {
  data: Fund;
  handleEdit: Function;
}
const GeneralInfo = (props: GeneralInfoProps) => {
  const { data, handleEdit } = props;

  const [fund, setFund] = useState<Fund>(data);
  const [countries, setCountries] = useState<RegionMetaModel[]>([]);
  const [formVisibility, setFormVisibility] = useState(false);
  const [terminateVisibility, setTerminateVisibility] = useState(false);

  const fetchCountries = () => {
    //console.log(fund);

    MetaService.listCountries(
      (countries) => {
        setCountries(countries);
      },
      () => { }
    );
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const stringJoiner = (stringArray: string[] | null[] | undefined[]) => {
    const validArray = stringArray.filter((string) => !!string);
    return validArray.join(", ").replaceAll("_", " ");
  };

  const editFund = () => {
    const companyId = data.companyId.toString() || LocalStorage.getItem("HL_SELECTED_COMPANY_ID");
    const fundData = fund
    fundData.terminatedAt = null;
    fundData.status = FundStatusBE.ACTIVE;
    HLFundsService.updateFund(
      fundData,
      { companyId },
      (res) => {
        setFund(res)
        Notification({
          message: "Hedge Legal",
          description: "Fund reverted successfully",
          type: NotificationTypes.SUCCESS,
        });
      },
      (err) => {
        // ;
        // Notification({
        //   message: "Hedge Legal",
        //   description: "Fund updation failed",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  const TerminationDropDown = (
    <Menu className="active-trigger">
      <Menu.Item key="1"
        onClick={() => {
          editFund()
        }}
      >Revert Termination</Menu.Item>
    </Menu>
  );
  return (
    <>
      <div className="general-info__container">
        <div className="general-info__header">
          <div className="general-info__header--details">
            <div className="general-info__header--details__fund">
              <div className="general-info__header--details--title">
                {fund?.name}
              </div>
              <div className="general-info__header--details--sub-title">
                <p>
                  {
                    countries?.find(
                      (country) => country?.value === fund?.countryId
                    )?.label
                  }
                </p>
                {fund?.fundStructure && (
                  <p className="border-left">
                    {fund?.fundStructure?.replaceAll("_", " ")}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="general-info__header--outer-pad">
            <div className="general-info__header--inner-pad">
              <div className="general-info__header--details__address border-right">
                <div className="general-info__header--details--title">
                  {fund?.companyName}
                </div>
                <div className="general-info__header--details--sub-title">
                  {fund?.addresses &&
                    fund.addresses[0] &&
                    stringJoiner([
                      fund.addresses[0]?.street,
                      fund.addresses[0]?.addressLine,
                      fund.addresses[0]?.cityName,
                      fund.addresses[0]?.provinceName,
                    ])}
                </div>
              </div>
              <div className="general-info__header--details__manager">
                <div className="general-info__header--details--sub-title">
                  Manager
                </div>
                <div className="general-info__header--details--title">
                  {fund?.investmentManagerName}
                </div>
              </div>
            </div>
            <div className="general-info__header--actions">
              {fund?.status === FundStatusBE.TERMINATED && (
                <div className="terminated">
                  <Dropdown overlay={TerminationDropDown} trigger={["click"]}>
                    <span>
                      Terminated <CaretDownOutlined />
                    </span>
                  </Dropdown>
                </div>
              )}
              {fund?.status === FundStatusBE.ACTIVE && (
                <div className="active">
                  <ButtonComponent
                    className="terminate-trigger"
                    type="primary"
                    onClick={() => {
                      setTerminateVisibility(true);
                    }}
                  >
                    Terminate Fund
                  </ButtonComponent>
                </div>
              )}
              <div className="edit-icon">
                <p
                  onClick={() => {
                    setFormVisibility(true);
                  }}
                >
                  <EditOutlined />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="general-info__body">
          <div className="general-info__body__title">
            {fund?.status === FundStatusBE.TERMINATED && (
              <>
                <h2 className="general-info__body__title--title">
                  {fund?.status?.replaceAll("_", " ")} Fund
                </h2>
                <div className="general-info__body__title__dates">
                  {fund?.targetLaunchDate && (
                    <div className="general-info__body__title__date">
                      <p>Lauched on</p>
                      <p>
                        {moment(fund?.targetLaunchDate).format("DD MMM YYYY")}
                      </p>
                    </div>
                  )}
                  {fund?.terminatedAt && (
                    <div className="general-info__body__title__date">
                      <p>Terminated on</p>
                      <p>
                        {fund?.terminatedAt?.length === 4
                          ? fund?.terminatedAt
                          : <p>{moment(fund?.terminatedAt).format("MMM DD, YYYY")}</p>}
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}
            {fund?.status === FundStatusBE.ACTIVE && (
              <div className="general-info__body__title__active">
                <div className="general-info__body__title__active-progress">
                  <Progress
                    status="normal"
                    strokeWidth={10}
                    type="circle"
                    percent={fund?.progress || 0}
                  ></Progress>
                  <div className="general-info__body__title__active-title">
                    <h2 className="general-info__body__title--title">
                      {fund?.status?.replaceAll("_", " ")} Fund
                    </h2>
                    <div className="general-info__body__title__date">
                      {fund?.targetLaunchDate && (
                        <p>
                          Lauched on{" "}
                          <span>
                            {moment(fund?.targetLaunchDate).format(
                              "DD MMM YYYY"
                            )}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="general-info__body__title__active-aum">
                  <p className="title">AUM at Launch</p>{" "}
                  {fund?.aumAtLaunch ? (
                    <p className="value text-center">
                      {aumConvertor(fund?.aumAtLaunch)}
                      <span className="base-currency">
                        {fund?.baseCurrency}
                      </span>
                    </p>
                  ) : (
                    <p className="text-center">--</p>
                  )}
                </div>
                <div className="general-info__body__title__active-aum">
                  <p className="title">Current AUM</p>{" "}
                  {fund?.aum ? (
                    <p className="value text-center">
                      {aumConvertor(fund?.aum)}
                      <span className="base-currency">
                        {fund?.baseCurrency}
                      </span>
                    </p>
                  ) : (
                    <p className="text-center">--</p>
                  )}
                </div>
              </div>
            )}
            {fund?.status === FundStatusBE.PRELAUNCH && (
              <div className="general-info__body__title__active">
                <div className="general-info__body__title__active-progress">
                  <Progress
                    status="normal"
                    strokeWidth={10}
                    type="circle"
                    percent={fund?.progress || 0}
                  ></Progress>
                  <div className="general-info__body__title__active-title">
                    <h2 className="general-info__body__title--title">
                      {fund?.status?.replaceAll("_", " ")} Fund
                    </h2>
                    <div className="general-info__body__title__date">
                      <div className="launch-date">
                        {fund?.targetLaunchDate &&
                          moment(fund?.targetLaunchDate).format("DD MMM YYYY")}
                        {fund?.daysToLaunch && (
                          <p className="days-to-launch">
                            {fund?.daysToLaunch} Days to launch
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="general-info__body__title__active-aum">
                  <p className="title">Target AUM</p>{" "}
                  {fund?.targetAum ? (
                    <p className="value text-center">
                      {aumConvertor(fund?.targetAum)}
                      <span className="base-currency">
                        {fund?.baseCurrency}
                      </span>
                    </p>
                  ) : (
                    <p className="text-center">--</p>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="general-info__body__details">
            <div className="general-info__body__details--row">
              <div className="general-info__body__details--content">
                <p className="title">Feeder Fund Name</p>
                <p className="value">{fund?.feederFundName}</p>
              </div>
            </div>
            <div className="general-info__body__details--row">
              <div className="general-info__body__details--content">
                <p className="title">Strategy</p>
                <p className="value">{fund?.strategy?.replaceAll("_", " ")}</p>
              </div>
              <div className="general-info__body__details--content">
                <p className="title">Sub Strategy</p>
                <p className="value">
                  {fund?.subStrategy?.join(", ")?.replaceAll("_", " ")}
                </p>
              </div>
            </div>
            <div className="general-info__body__details--row">
              <div className="general-info__body__details--content">
                <p className="title">Products Traded</p>
                <p className="value">{stringJoiner(fund?.productsTraded)}</p>
              </div>
              <div className="general-info__body__details--content">
                <p className="title">Geographic Focus/Exposure</p>
                <p className="value">{stringJoiner(fund?.geographicFocus)}</p>
              </div>
            </div>
            <div className="general-info__body__details--row  three-children">
              <div className="general-info__body__details--content">
                <p className="title">Leverage (Long)</p>
                <p className="value">{fund?.leverageLong}</p>
              </div>
              <div className="general-info__body__details--content">
                <p className="title">Leverage (Short)</p>
                <p className="value">{fund?.leverageShort}</p>
              </div>
              <div className="general-info__body__details--content">
                <p className="title">Leverage (net)</p>
                <p className="value">{fund?.leverageNet}</p>
              </div>
            </div>
            <div className="general-info__body__details--row">
              <div className="general-info__body__details--content">
                <p className="title">LEI</p>
                <p className="value">{fund?.lei}</p>
              </div>
              <div className="general-info__body__details--content">
                <p className="title">General Partner</p>
                <p className="value">{fund?.partnerName}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FundForm
        title={"Edit fund"}
        visible={formVisibility}
        closeHandler={(fund?: Fund) => {
          if (fund?.id) {
            handleEdit(fund);
            setFund(fund);
          }
          setFormVisibility(false);
        }}
        data={fund}
        disableTerminatedStatus={true}
      ></FundForm>
      <TerminationForm
        visible={terminateVisibility}
        data={fund}
        closeHandler={(fund?: Fund) => {
          if (fund?.id) {
            setFund(fund);
          }
          setTerminateVisibility(false);
        }}
      />
    </>
  );
};

export default GeneralInfo;
