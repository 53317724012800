import * as Yup from "yup";
import { REGEX } from "../../../shared/utils/regex";

export const changePasswordValidationSchema = Yup.object({
    password: Yup.string()
        .min(8, "Password has to be longer than 8 characters!")
        .required("Password is required!")
        .matches(
            REGEX.PASSWORD,
            "One Uppercase, One Lowercase, One Number and one special case Character"
        ),
    confirmPassword: Yup.string()
        .required("Confirm password is required!")
        .oneOf([Yup.ref("password"), ""], "Passwords must match"),
})