import { serializable, alias, primitive, object, list } from 'serializr';

export class OnboardingItemMetum {
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('description', primitive()))
	description?: string;

	@serializable(alias('category', primitive()))
	category?: string;
}

export class OnboardingItemStatus {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('progress_percent', primitive()))
	progressPercent?: number;
}
export class ResponsibleUser {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('first_name', primitive()))
	firstName?: string;

	@serializable(alias('last_name', primitive()))
	lastName?: string;
}

export class OnboardingItem {
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('fund_id', primitive()))
	fundId?: number;

	@serializable(alias('onboarding_template_id', primitive()))
	onboardingTemplateId?: number;

	@serializable(alias('onboarding_item_metum_id', primitive()))
	onboardingItemMetumId?: number;

	@serializable(alias('onboarding_item_metum_name', primitive()))
	onboardingItemMetumName?: string;


	@serializable(alias('onboarding_item_metum_category', primitive()))
	onboardingItemMetumCategory?: string;

	@serializable(alias('onboarding_item_category', primitive()))
	onboardingItemCategory?: string;

	@serializable(alias('onboarding_item_name', primitive()))
	name?: string;

	@serializable(alias('tier', primitive()))
	tier?: string;

	@serializable(alias('responsible_user_id', primitive()))
	responsibleUserId?: number;

	@serializable(alias('onboarding_item_status_id', primitive()))
	onboardingItemStatusId?: number;

	@serializable(alias('onboarding_item_status_progress_percent', primitive()))
	onboardingItemStatusProgressPercent?: number;

	@serializable(alias('order', primitive()))
	order?: number;

	@serializable(alias('position', primitive()))
	position?: number;

	@serializable(alias('responsible_user_first_name', primitive()))
	responsibleUserFirstName?: string;

	@serializable(alias('responsible_user_last_name', primitive()))
	responsibleUserLastName?: string;

	@serializable(alias('onboarding_item_status_name', primitive()))
	onboardingItemStatusName?: string;

	@serializable(alias('onboarding_item_metum', object(OnboardingItemMetum)))
	onboardingItemMetum?: OnboardingItemMetum;
}
