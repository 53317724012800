import { Checkbox, Col, Row } from "antd";
import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import * as React from "react";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import InputField from "../../../shared/components/InputField";
import "./register.scss";
import { ArrowRightOutlined } from "@ant-design/icons";
import { VERIFY_OTP } from "../../../routes/appRoutes";
import { AuthService } from "../../../services/AuthService/auth.service";
import { useHistory, useLocation } from "react-router";
import { RegisterValidation } from "./registerValidation";
import { Link } from "react-router-dom";
import * as appRoutes from "./../../../routes/appRoutes"

interface FormProps {
  companyName: string;
  firstName: string;
  lastName: string;
  companyRoleName: string;
  email: string;
  isAgreed: boolean;
  mobileNumber: string;
  password: string;
  confirmPassword: string;
  referralCode: string;
}
export const Register = () => {
  const formRef = React.useRef<any>();

  const history = useHistory();

  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const [initialValues, setInitialValues] = React.useState<FormProps>({
    companyName: "",
    firstName: "",
    lastName: "",
    companyRoleName: "",
    email: "",
    mobileNumber: "",
    isAgreed: null,
    password: "",
    confirmPassword: "",
    referralCode: params.get("code"),
  });

  const handleRegister = (values: FormProps) => {
    if (!values.referralCode)
      delete values.referralCode
    AuthService.signUp(
      values,
      () => {
        history.push(VERIFY_OTP, {
          email: values?.email
        });
      },
      () => { }
    );
  };

  return (
    <div className="register-container">
      <h1>Create Account</h1>
      <Formik
        initialValues={initialValues}
        onSubmit={() => { }}
        enableReinitialize
        innerRef={formRef}
        validationSchema={RegisterValidation}
      >
        {({ setFieldValue, values, resetForm, isValid, dirty, errors }) => {
          return (
            <Form>
              <div className="register-form__container">
                <div className="register-form__field">
                  <InputField
                    placeholder={"Company Name *"}
                    name="companyName"
                    type="text"
                    value={values?.companyName}
                  />
                </div>

                <Row justify="space-between" className="mtp-4">
                  <Col span={11}>
                    <InputField
                      placeholder={"First Name"}
                      name="firstName"
                      type="text"
                      value={values?.firstName}
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      placeholder={"Last Name"}
                      name="lastName"
                      type="text"
                      value={values?.lastName}
                    />
                  </Col>
                </Row>

                <div className="register-form__field">
                  <InputField
                    placeholder={"Position at Company *"}
                    name="companyRoleName"
                    type="text"
                    value={values?.companyRoleName}
                  />
                </div>
                <div className="register-form__field">
                  <InputField
                    placeholder={"Email *"}
                    name="email"
                    type="text"
                    value={values?.email}
                  />
                </div>
                <div className="register-form__field">
                  <InputField
                    placeholder={"Phone Number *"}
                    name="mobileNumber"
                    type="text"
                    value={values?.mobileNumber}
                  />
                </div>
                <div className="register-form__field">
                  <InputField
                    placeholder={"Password *"}
                    name="password"
                    type="password"
                    showEye
                    value={values?.password}
                  />
                </div>
                <div className="register-form__field">
                  <InputField
                    placeholder={"Confirm Password *"}
                    name="confirmPassword"
                    type="password"
                    showEye
                    value={values?.confirmPassword}
                  />
                </div>
                <div className="register-form__field">
                  <InputField
                    placeholder={"Referral Code"}
                    name="referralCode"
                    type="text"
                    value={values?.referralCode}
                  />
                </div>
                <div className="register-form__field text-left">
                  <Checkbox
                    className="mr-5"
                    name="isAgreed"
                    onChange={e => setFieldValue("isAgreed", e.target.checked ? true : null)}
                  />
                  <span>I agree to the
                    <Link target={"_blank"} className="mr-5 ml-5" to={appRoutes.TERMS_OF_USE}>Terms of use</Link>
                    and
                    <Link target={"_blank"} className="mr-5 ml-5" to={appRoutes.PRIVACY_POLICY}>Privacy policy</Link>
                  </span>
                </div>

                <div className="btn__wrapper">
                  <ButtonComponent
                    type={"primary"}
                    disabled={!isValid || !dirty}
                    onClick={() => handleRegister(values)}
                    iconRight={<ArrowRightOutlined />}
                    displayType="space-between"
                  >
                    Send Verification code to Email ID
                  </ButtonComponent>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
