import * as Yup from "yup";

export const validateManageUser = Yup.object().shape({
  firstName: Yup.string().required("First Name is required!"),
  lastName: Yup.string().required("Last Name is required!"),
  role: Yup.string().required("Role is required!"),
  email: Yup.string().required("Email is required!").email("Enter a valid Email"),
  phoneNumber: Yup.string().required("Mobile number is required!"),
  // .min(10, "Mobile number must contain 10 digits").max(10, "Mobile number must contain 10 digits"),
  status: Yup.string().required("Status is required!"),
  password: Yup.string().ensure().when('id', {
    is: (userId) => !userId,
    then: Yup.string().required("Password is required!")
  }),
  confirmPassword: Yup.string().ensure().when('id', {
    is: (userId) => !userId,
    then: Yup.string()
      .required("Confirm password is required!")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  }),
});
