import { PlusOutlined, SnippetsOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { AgreementDetailsTabs } from "../../../../../enums/agreementDetails";
import { Agreement } from "../../../../../models/Agreements/agreement.model";
import { PageHeaderComponent } from "../../../../../shared/components/PageHeader";
import AncillaryDocuments from "./AncillaryDocuments";
import "./agreement-details.scss";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";
import Amendments from "./Amendments";
import Notification from "../../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../../enums/notificationTypes";
import { HFAgreementService } from "../../../../../services/AgreementService/agreement.service";
import RelatableDocuments from "./RelatedDocuments";
import ViewAgreement from "./ViewAgreement";

const AgreementDetails = (props) => {
  const { agreement } = props;
  const { pathname } = useLocation();
  const id = pathname.split("/").pop();
  const [activeTab, setActiveTab] = useState<number>();
  const [currentAgreement, setCurrentAgreement] =
    useState<Agreement>(agreement);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (params?.get("type")?.trim() === "ancillary") {
      setTimeout(() => {
        setActiveTab(2);
      }, 2000)
    }
  }, []);

  const tabChangeHandler = (e: string) => setActiveTab(+e);

  const fetchSingleAgreement = () => {
    HFAgreementService.fetchSingleAgreements(
      id,
      (response) => {
        setCurrentAgreement(response);
        if (!activeTab) {
          setActiveTab(1);
        }
      },
      (error) => {
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to fetch agreements",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };
  const agreementUpdateHandler = (agreement: Agreement) => {
    if (agreement?.id) {
      setCurrentAgreement(agreement);
    } else {
      fetchSingleAgreement();
    }
  };
  useEffect(() => {
    fetchSingleAgreement();
  }, [id]);
  return (
    <div className="hf-agreements">
      <PageHeaderComponent
        onChangeTab={tabChangeHandler}
        title={currentAgreement?.name}
        showTabs={!!currentAgreement}
        showBack={!!currentAgreement}
        tabs={Object.values(AgreementDetailsTabs)}
        defaultValue={params.get("type")?.trim() === "ancillary" ? "2" : "1"}
      />
      <div className="agreements-list">
        {activeTab === 1 && (
          <ViewAgreement
            fetchSingleAgreement={fetchSingleAgreement}
            agreement={currentAgreement}
          ></ViewAgreement>
        )}
        {activeTab === 2 && (
          <AncillaryDocuments agreement={currentAgreement}></AncillaryDocuments>
        )}
        {activeTab === 3 && (
          <Amendments
            onAgreementUpdate={agreementUpdateHandler}
            agreement={currentAgreement}
          ></Amendments>
        )}
        {activeTab === 4 && (
          <RelatableDocuments agreement={currentAgreement}></RelatableDocuments>
        )}
      </div>
    </div>
  );
};

export default AgreementDetails;
