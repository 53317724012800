import { serializable, alias, primitive, list, object, custom } from 'serializr';

export default class Rating {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('rating', primitive()))
    rating?: number;

    @serializable(alias('rated_by_id', primitive()))
    ratedBy?: number;

    @serializable(alias('review', primitive()))
    review?: string;

    @serializable(alias('user_first_name', primitive()))
    userFirstName?: string;

    @serializable(alias('user_last_name', primitive()))
    userLastName?: string;

    @serializable(alias('company_strategy', primitive()))
    companyStrategy?: string;

    @serializable(alias('created_at', primitive()))
    createdAt?: string;

    @serializable(alias('company_aum', primitive()))
    companyAum?: string;

    @serializable(alias('company_id', primitive()))
    companyId?: string;

    @serializable(alias('city', primitive()))
    city?: string;

    @serializable(alias('province', primitive()))
    province?: string;

    @serializable(alias('country', primitive()))
    country?: string;
}