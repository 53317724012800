import { FileAddFilled, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Ticket } from "../../../models/Ticket/ticket.model";
import { HelpService } from "../../../services/HelpService/helpService.service";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { PageHeaderComponent } from "../../../shared/components/PageHeader";
import AuthContainer from "../../../store/containers/AuthContainer";
import { IAuthState } from "../../../store/reducers/authReducer";
import TicketForm from "./TicketForm";
import TicketList from "./TicketList";
interface TicketsProps extends IAuthState {

}

const Tickets = (props: TicketsProps) => {

    const {
        userType,
    } = props
    const isAdmin = userType === 'HL'
    const [ticketFormData, setTicketFormData] = useState<Ticket>()
    const [loading, setLoading] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [tickets, setTickets] = useState<Ticket[]>([])


    useEffect(() => {
        if (isAdmin) {
            fetchTickets()
        }
    }, [search])

    const updateTicket = (values: Ticket) => {
        setLoading(true);
        HelpService.editHLTickets(
            values,
            (response) => {
                fetchTickets()
            },
            (error) => {
            },
            () => {
                setLoading(false);
            }
        );
    };

    const fetchTickets = () => {
        setLoading(true)
        HelpService.fetchHLTickets(
            {
                search_text: search
            },
            (tickets: Ticket[]) => {
                setTickets(tickets)
            },
            (error) => { },
            () => {
                setLoading(false)
            },
        )
    }

    const ticketFormCloseHandler = (id?: number) => {
        setTicketFormData(undefined)
        if (id) {
            fetchTickets();
        }
    }
    updateTicket
    return <div className="tickets__container">
        <PageHeaderComponent
            title="Tickets"
            buttons={
                <ButtonComponent type="primary"
                    onClick={() => setTicketFormData(new Ticket())}
                >
                    <PlusOutlined /> New Ticket
                </ButtonComponent>
            }
        />
        <TicketList
            setTicketData={setTicketFormData}
            fetchTickets={fetchTickets}
            tickets={tickets}
            loading={loading}
            updateTicket={updateTicket}
            setLoading={setLoading}
            onSearch={setSearch}
        />
        <TicketForm
            visible={!!ticketFormData}
            ticketData={ticketFormData}
            closeHandler={ticketFormCloseHandler}
        />
    </div>
}

export default AuthContainer(Tickets)