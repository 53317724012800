import { Button, Col, Row } from "antd";
import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import React, { FC } from "react";
import { Notification } from "../../../../../../models/Notification/notification.model";
import InputField from "../../../../../../shared/components/InputField";

interface NotificationFormProps {
  notification: Notification;
  updateNotifications: (notification: Notification) => Promise<boolean>;
  closeHandler: () => void;
}

const NotificationForm: FC<NotificationFormProps> = (props) => {
  const { notification, updateNotifications, closeHandler } = props;

  const handleSubmit = async (notification: Notification) => {
    await updateNotifications(notification);
    closeHandler();
  };
  return (
    <div className="notification-form">
      <Formik
        initialValues={notification ?? new Notification()}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Form>
            <InputField type="number" name="period" placeholder="" />
            <Row justify="end" className="mt-4">
              <Col>
                <Button
                  onClick={() => handleSubmit()}
                  htmlType="button"
                  type="primary"
                  icon={<i className="icon-edit" />}
                >
                  Edit
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NotificationForm;
