import { serialize } from 'serializr';
import { Ticket, SupportTicket } from './../../models/Ticket/ticket.model';
import { deserialize } from 'serializr';
import axiosInstance from "../../interceptor/axiosInstance"
import * as apiRoutes from "./../../routes/apiRoutes"
import { Faq } from '../../models/Faq/faq.model';
import { VideoModel } from '../../models/Video/video.model';

export class HelpService {

    static fetchHLTickets = async (
        params: {
            search_text?: string
        },
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const response = await axiosInstance.get(apiRoutes.HL_TICKETS, { params })
            const data = deserialize(Ticket, response.data['tickets']) as Ticket[]
            onSuccess(data)

        } catch (error) {
            console.log(error);

            onError()
        } finally {
            onFinal()
        }
    }

    static createHLTickets = async (
        data: Ticket,
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const serialisedData = serialize(Ticket, data)
            const ticket = {
                ticket: serialisedData
            }
            const response = await axiosInstance.post(apiRoutes.HL_TICKETS, ticket)
            const responseData = deserialize(Ticket, response.data['ticket'])
            onSuccess(responseData)
        } catch (error) {
            console.log(error);

            onError()
        } finally {
            onFinal()
        }
    }

    static editHLTickets = async (
        data: Ticket,
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const serialisedData = serialize(Ticket, data)
            const ticket = {
                ticket: serialisedData
            }
            const response = await axiosInstance.put(apiRoutes.HL_TICKET.replace(":ticketId", data?.id?.toString()), ticket)
            const responseData = deserialize(Ticket, response.data['ticket'])
            onSuccess(responseData)

        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

    static deleteHLTickets = async (
        ticketId: number,
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const response = await axiosInstance.delete(apiRoutes.HL_TICKET.replace(':ticketId', ticketId?.toString()))
            onSuccess()

        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

    static fetchHFFaqs = async (
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const response = await axiosInstance.get(apiRoutes.HF_FAQS)
            const data = deserialize(Faq, response.data['f_a_qs']) as Faq[]
            onSuccess(data)

        } catch (error) {
            onError()
        } finally {
            onFinal()
        }

    }

    static fetchHLFaqs = async (
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const response = await axiosInstance.get(apiRoutes.HL_FAQS)
            const data = deserialize(Faq, response.data['f_a_qs']) as Faq[]
            onSuccess(data)

        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

    static createHLFaqs = async (
        faq: Faq,
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const data = {
                f_a_q: serialize(Faq, faq)
            }
            const response = await axiosInstance.post(apiRoutes.HL_FAQS, data)
            onSuccess()
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }

    }

    static editHLFaqs = async (
        faq: Faq,
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const data = {
                f_a_q: serialize(Faq, faq)
            }
            const response = await axiosInstance.put(apiRoutes.HL_FAQ.replace(":faqId", faq?.id?.toString()), data)
            onSuccess()
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }

    }

    static deleteHLFaqs = async (
        faqId: number,
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const response = await axiosInstance.delete(apiRoutes.HL_FAQ.replace(":faqId", faqId.toString()),)
            onSuccess()
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }

    }

    static fetchHFTickets = async (
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const response = await axiosInstance.get(apiRoutes.HF_TICKETS)
            const data = deserialize(SupportTicket, response.data["tickets"])
            onSuccess(data)
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }

    }

    static createHFTicket = async (
        ticket: SupportTicket,
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const data = {
                ticket: serialize(SupportTicket, ticket)
            }
            const response = await axiosInstance.post(apiRoutes.HF_TICKETS, data)
            onSuccess()
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

    static deleteHFTicket = async (
        ticketId: number,
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const response = await axiosInstance.delete(apiRoutes.HF_TICKET.replace(':ticketId', ticketId?.toString()))
            onSuccess()
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

    static fetchHFVideos = async (
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const response = await axiosInstance.get(apiRoutes.HF_VIDEOS)
            const data = deserialize(VideoModel, response.data["videos"])
            onSuccess(data)
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

    static fetchHLVideos = async (
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const response = await axiosInstance.get(apiRoutes.HL_VIDEOS)
            const data = deserialize(VideoModel, response.data["videos"])
            onSuccess(data)
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

    static createHLVideos = async (
        faq: VideoModel,
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const data = {
                video: serialize(VideoModel, faq)
            }
            const response = await axiosInstance.post(apiRoutes.HL_VIDEOS, data)
            const responseData = deserialize(VideoModel, response.data)
            onSuccess(responseData)
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }

    }

    static editHLVideos = async (
        video: VideoModel,
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const data = {
                video: serialize(VideoModel, video)
            }
            const response = await axiosInstance.put(apiRoutes.HL_VIDEO.replace(":videoId", video?.id?.toString()), data)
            const responseData = deserialize(VideoModel, response.data)
            onSuccess(responseData)
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }

    }

    static deleteHLVideo = async (
        videoId: number,
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const response = await axiosInstance.delete(apiRoutes.HL_VIDEO.replace(':videoId', videoId?.toString()))
            onSuccess()
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }
}