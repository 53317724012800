import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { date, deserialize } from "serializr";
import { AgreementTypes } from "../../../../../../models/Agreements/agreementTypes.model";
import { AgreementTypesMeta } from "../../../../../../models/Meta/meta.model";
import AgreementTypesService from "../../../../../../services/KeyTermsService/AgreementTypes.service";
import KeyTermGroupsService from "../../../../../../services/KeyTermsService/KeyTermGroups.service";
import KeyTermsService from "../../../../../../services/KeyTermsService/KeyTerms.service";
import DropdownField from "../../../../../../shared/components/DropdownField";
import InputField from "../../../../../../shared/components/InputField";
import { UIModal } from "../../../../../../shared/components/UIModal";
import "../KTReplicateForm/ktReplicateForm.scss";
interface KTEditFormProps {
  item: "key_term_group" | "key_term";
  visible: boolean;
  closeHandler: () => void;
  refreshKT: () => void;
  refreshGroup: () => void;
  data: any;
}
export const KTEditForm: FC<KTEditFormProps> = ({
  item,
  visible,
  closeHandler,
  refreshGroup,
  refreshKT,
  data,
}) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
  });
  const [types, setTypes] = useState<any>();
  useEffect(() => {
    setInitialValues(data);
  }, [data]);
  const onEdit = (values: any) => {
    if (item == "key_term") {
      KeyTermsService.updateKeyTerms(
        data?.selectedType?.id,
        data?.selectedGroup?.id,
        values,
        () => {
          closeHandler();
          refreshKT()
        },
        () => {},
        () => {}
      );
    } else if (item == "key_term_group") {
      KeyTermGroupsService.updateKeyTermGroups(
        data?.selectedType?.id,
        values,
        () => {
          closeHandler();
          refreshGroup()
        },
        () => {},
        () => {}
      );
    } else {
      return;
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      enableReinitialize
    >
      {({ setFieldValue, values, resetForm, isValid }) => {
        return (
          <UIModal
            visible={visible}
            closeModal={closeHandler}
            title={item == "key_term" ? "Update Key Term" : "Update Key Term Group" }
            submitProps={{
              text: "Update",
              clickHandler: () => onEdit(values),
            }}
            cancelProps={{
              text: "Cancel",
              clickHandler: () => {closeHandler()},
            }}
          >
            <Form>
              <div className="kt-replicate-form__container">
                <div className="kt-replicate-form__field">
                  <InputField
                    placeholder="Enter name"
                    name="name"
                    type="text"
                    value={values?.name}
                  />
                </div>
              </div>
            </Form>
          </UIModal>
        );
      }}
    </Formik>
  );
};
