import { alias, primitive, serializable } from "serializr";

export class PaginationMeta {
    @serializable(alias("current_page", primitive()))
    currentPage?: number;

    @serializable(alias("next_page", primitive()))
    nextPage?: number;

    @serializable(alias("prev_page", primitive()))
    prevPage?: number;

    @serializable(alias("total_count", primitive()))
    totalCount?: number;

    @serializable(alias("total_pages", primitive()))
    totalPages?: number;
}