import { alias, primitive, serializable } from "serializr";

export class RegionMetaModel {
  @serializable(alias("name", primitive()))
  label?: string;

  @serializable(alias("id", primitive()))
  value?: string;
}
export class CompanyMetaModel {
  @serializable(alias("companyName", primitive()))
  label?: string;

  @serializable(alias("companyId", primitive()))
  value?: string;
}
export class AgreementTypesMeta {
  @serializable(alias("id", primitive()))
  value?: string;

  @serializable(alias("name", primitive()))
  label?: string;
}
export class OnboardingItems {
  @serializable(alias("id", primitive()))
  value?: string;

  @serializable(alias("name", primitive()))
  label?: string;
}
