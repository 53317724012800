import { Col, Row, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { User } from "../../../../models/User/user.model";
import UserManagementService from "../../../../services/UserManagementService";
import AppModal from "../../../../shared/components/AppModal";
import { ConfirmationAlert } from "../../../../shared/components/ConfirmationAlert";
import { TableComponent } from "../../../../shared/components/TableComponent";
import userRoles from "../../../../shared/utils/userRoles";
import ManageUsersForm from "./ManageUserForm";
import "./manageUsers.scss";
import Notification from "../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../enums/notificationTypes";
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";

interface ManageUsersProps { }

const ManageUsers: FC<ManageUsersProps> = (props) => {
  const { users, getUsers, deleteUser, createUser, updateUser } =
    UserManagementService();

  const params = useParams<{ id: string }>();

  const [dUser, setDeleteUser] = useState<User>();

  const user = LocalStorage.getItem("USER");

  useEffect(() => {
    getUsers('', params.id);
  }, []);

  const columns = [
    {
      title: "NAME",
      dataIndex: "firstName",
      key: "firstName",
      render: (firstName, record: User) => <span className="text-capitalize">{`${firstName} ${record?.lastName}`}</span>,
    },
    { title: "ROLE", dataIndex: "role", key: "role", render: (role) => <span className="text-capitalize">{userRoles?.[role] || role?.replace("_", " ")}</span> },
    {
      title: "MANAGING FUNDS",
      dataIndex: "funds",
      key: "funds",
      render: (funds, user: User) => {
        return user.funds?.length ? (
          <span>
            {user.funds[0]?.name}{" "}
            {user.funds.length > 1 ? (
              <span className="add-on__funds">+{funds.length - 1}</span>
            ) : null}
          </span>
        ) : (
          ""
        );
      },
    },
    { title: "STATUS", dataIndex: "status", key: "status" },
    { title: "PHONE NUMBER", dataIndex: "phoneNumber", key: "phoneNumber" },
    { title: "EMAIL", dataIndex: "email", key: "email" },
    {
      title: "",
      dataIndex: "fundName",
      key: "fundName",
      render: (_, record: User) => (
        <Row>
          <Col className="mr-10">
            <AppModal
              title="Edit User"
              triggerComponent={(openHandler) => (
                <i
                  className="icon-edit"
                  onClick={(e) => {
                    if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == params['id'])?.length == 0)) {
                      Notification({
                        message: "Hedge Legal",
                        description: "Access Restricted",
                        type: NotificationTypes.ERROR,
                      });
                    } else {
                      e.stopPropagation();
                      openHandler();
                    }
                  }}
                />
              )}
              renderComponent={(closeHandler) => (
                <ManageUsersForm
                  updateUserHandler={async (user) => {
                    return await updateUser(user, params.id);
                  }}
                  fromValues={record}
                  closeHandler={() => {
                    closeHandler();
                  }}
                />
              )}
            />
          </Col>
          <Col>
            <i className="icon-delete" onClick={() => {
              if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == params['id'])?.length == 0)) {
                Notification({
                  message: "Hedge Legal",
                  description: "Access Restricted",
                  type: NotificationTypes.ERROR,
                });
              } else {
                setDeleteUser(record)
              }
            }} />
          </Col>
        </Row>
      ),
    },
  ];

  const closeDeleteHandler = (response?: boolean) => {
    setDeleteUser(undefined);
    response && getUsers('', params.id)
  };

  const handleDeleteUser = async () => {
    const isDeleted = await deleteUser(dUser?.id);
    if (isDeleted) {
      closeDeleteHandler();
    }
  };

  const handleSearch = (searchText: string) => {
    getUsers(searchText, params.id);
  };

  return (
    <div className="manage-users">
      <AppModal
        title="Add New User"
        triggerComponent={(openHandler) => (
          <TableComponent
            title="User List"
            data={users}
            columns={columns}
            showSearch
            onSearch={handleSearch}
            showAdd
            onAdd={() => {
              if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == params['id'])?.length == 0)) {
                Notification({
                  message: "Hedge Legal",
                  description: "Access Restricted",
                  type: NotificationTypes.ERROR,
                });
              } else {
                openHandler()
              }
            }
            }
          />
        )}
        renderComponent={(closeHandler) => (
          <ManageUsersForm
            createUserHandler={async (user) => {
              return await createUser(user, params.id);
              // closeHandler();
            }}
            fromValues={new User()}
            closeHandler={() => {
              closeHandler();
            }}
          />
        )}
      />
      <ConfirmationAlert
        type="delete"
        visible={!!dUser}
        title="Are you should you would like to delete the following user?"
        description={`${dUser?.firstName ?? ""} ${dUser?.lastName ?? ""}`}
        deleteHandler={handleDeleteUser}
        closeHandler={closeDeleteHandler}
        cancelHandler={closeDeleteHandler}
      ></ConfirmationAlert>
    </div>
  );
};

export default ManageUsers;
