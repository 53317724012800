import { Button, Col, Row } from "antd";
import React, { FC, useState } from "react";
import { Topic } from "../../../../models/Topic/topic.model";
import DiscussionBoardService from "../../../../services/DiscussionBoardService/DiscussionBoard.service";
import "./discussionForm.scss";

interface DiscussionFormProps {
  loading?: boolean;
  successHandler: (topic: Topic) => void;
  closeHandler: () => void;
}

const DiscussionForm: FC<DiscussionFormProps> = (props) => {
  const { loading, successHandler, closeHandler } = props;

  const [description, setDescription] = useState("");

  const handleCreatePost = async () => {
    if (!description) return
    const topic = Object.assign(new Topic(), {
      description,
    });
    successHandler(topic);
  };

  return (
    <div className="discussion-form">
      <Row justify="space-between">
        <Col>
          <h4>New Discussion</h4>
        </Col>
        <Col>
          <i className="icon-close" />
        </Col>
      </Row>
      <textarea
        placeholder="Type your discussion topic here ..."
        value={description}
        rows={6}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Row justify="end" gutter={[20, 0]}>
        <Col>
          <Button type="text" onClick={closeHandler}>Cancel</Button>
        </Col>
        <Col>
          <Button type="primary" loading={loading} onClick={handleCreatePost}>
            Post
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default DiscussionForm;
