import { PlusOutlined } from "@ant-design/icons";
import { Checkbox, Col, Dropdown, Form, Menu, Row, Space, Table, Tooltip } from "antd";
import pagination from "antd/lib/pagination";
import { Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { Agreement } from "../../../models/Agreements/agreement.model";
import { AgreementTypes } from "../../../models/Agreements/agreementTypes.model";
import { ReportHistory } from "../../../models/Report/report.model";
import { HFAgreementService, HLAgreementService } from "../../../services/AgreementService/agreement.service";
import { FundsService, HLFundsService } from "../../../services/FundService/Fund.service";
import AgreementTypesService from "../../../services/KeyTermsService/AgreementTypes.service";
import { ReportsService } from "../../../services/ReportsService/reports.service";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import DropdownField from "../../../shared/components/DropdownField";
import EmptyState from "../../../shared/components/EmptyState";
import { PageHeaderComponent } from "../../../shared/components/PageHeader";
import { SearchInput } from "../../../shared/components/SearchInput";
import { TableComponent } from "../../../shared/components/TableComponent";
import TableLoader from "../../../shared/components/TableLoader";
import { temporarySorter, sorter } from "../../../shared/utils/tableSorter";
import AuthContainer from "../../../store/containers/AuthContainer";
import { IAuthState } from "../../../store/reducers/authReducer";
import { TicketFormValidation } from "../Tickets/TicketForm/ticketFormValidation";
import KeyTermPanels from "./KeyTermPanels";
import NestedReportTable from "./NestedReportTable";
import * as AppRoutes from "./../../../routes/appRoutes"
import "./reports.scss"
import { arraysEqual } from "../../../shared/utils/arrayEquals";
import { KeyTermGroupsKeyTerm } from "../../../models/KeyTerms/keyTermGroups.model";
import InputField from "../../../shared/components/InputField";
import mailTo from 'mailto-link'
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import moment from "moment";
import TableFilterDropDown from "../../../shared/components/TableFilterDropDown";
import { HFCounterPartiesService } from "../../../services/CounterPartiesService/CounterParties.service";
import { CounterParties } from "../../../models/CounterParties/counterParties.model";
import { Fund } from "../../../models/Fund/fund.model";
import { CaretDownFilled, CaretDownOutlined } from "@ant-design/icons";
import { deserialize } from "serializr";
import { OnboardingItems } from "../../../models/Meta/meta.model";

interface ReportsProps extends IAuthState { }

const Reports = (props: ReportsProps) => {

    const history = useHistory()

    const {
        userType,
    } = props
    const isAdmin = userType === 'HL'

    const [agreements, setAgreements] = useState<Agreement[]>([]);
    const [reportHistory, setReportHistory] = useState<ReportHistory[]>([]);
    const [selectedAgreements, setSelectedAgreements] = useState<number[]>([]);
    const [selectedKeyTerms, setSelectedKeyTerms] = useState<number[]>([]);
    const [availableKeyTerms, setAvailableKeyTerms] = useState<KeyTermGroupsKeyTerm[]>([]);
    const [selectedAgreementTypeId, setSelectedAgreementTypeId] = useState<number>();
    const [selectedCompanyId, setSelectedCompanyId] = useState<number[]>([]);
    const [agreementTypes, setAgreementTypes] = useState<any[]>([]);
    const [companies, setCompanies] = useState<any[]>([]);
    const [search, setSearch] = useState<string>('');
    const [agreementLoading, setAgreementLoading] = useState<boolean>(true);
    const [historyLoading, setHistoryLoading] = useState<boolean>(false);
    const [createReportsLoading, setCreateReportsLoading] = useState<boolean>(false);
    const [allKeyTermsSelected, setAllKeyTermsSelected] = useState<boolean>(false);
    const [filterInfo, setFilterInfo] = useState<any>({});
    const [filters, setFilters] = useState<any>({});
    const [counterParties, setCounterParties] = useState<any[]>([]);
    const [funds, setFunds] = useState<any[]>([]);

    useEffect(() => {
        fetchAgreementTypes()
        fetchCompanies()
        fetchReportHistory()
        fetchCPList()
        fetchFundsList()
    }, [])

    const [sortInfo, setSortInfo] = useState<{ key: string; order: string }>();

    const sortHandler = (key: string, order: string) => {
        setSortInfo({
            key,
            order,
        });
    };

    const filterHandler = (key: string, value: any) => {
        const currentFilter = { ...filterInfo };
        currentFilter[key] = Array.isArray(value) ? Array.from(new Set(value)) : value;
        if (JSON.stringify(filterInfo) === JSON.stringify(currentFilter)) {
            return
        }
        setFilterInfo({ ...currentFilter, search_text: search, page: 1 });
    };

    const filterProperties = (dataIndex: string, sortIndex: string, records) => {
        return {
            filterDropdown: (filterProps) => (
                <TableFilterDropDown
                    {...filterProps}
                    defaultSelected={[...(filterInfo[dataIndex] || []), ...((filters || {})[dataIndex] || [])]}
                    onApply={(key, value) => filterHandler(key, value)}
                    sortIndex={sortIndex}
                    dataIndex={dataIndex}
                    checkboxValues={records}
                    onSort={sortHandler}
                />
            ),
            filterIcon: () => <CaretDownOutlined />,
        };
    };

    const fetchCPList = () => {
        HFCounterPartiesService.fetchCounterParties(
            {},
            (response: CounterParties[]) => {
                const foramattedData = response.map((cp) => {
                    return { label: cp.name, value: cp.id };
                });
                setCounterParties(foramattedData);
            },
            () => { },
            () => { }
        );
    };

    const fetchFundsList = () => {
        FundsService.fetchFunds(
            { meta: true },
            (response: Fund[]) => {
                setFunds(deserialize(OnboardingItems, response));
            },
            (error) => {
            },
            () => { }
        );
    };

    const columns = [{
        title: "FUND",
        dataIndex: "fundName",
        key: "fundName",
        width: 120,
        ellipsis: { showTitle: false },
        sorter: (a, b) => sorter(a.fundName, b.fundName),
        sortOrder: sortInfo?.key === "fundName" && sortInfo?.order,
        ...filterProperties("fund_ids", "fundName", funds),
        render: text => <Tooltip placement="topLeft" title={text || ""}>
            {text || ""}
        </Tooltip>
    },
    {
        title: "CP NAME",
        dataIndex: "cpNameReports",
        key: "cpNameReports",
        width: 120,
        ellipsis: { showTitle: false },
        sorter: (a, b) => sorter(a.cpName, b.cpName),
        ...filterProperties("counter_party_ids", "cpName", counterParties),
        render: text => <Tooltip placement="topLeft" title={text || ""}>
            {text || ""}
        </Tooltip>,
    },
    {
        title: "AGREEMENT NAME",
        dataIndex: "name",
        key: "name",
        width: 120,
        ellipsis: { showTitle: false },
        render: text => <Tooltip placement="topLeft" title={text || ""}>
            {text || ""}
        </Tooltip>
    },
    {
        title: "HFUND COMPANY",
        dataIndex: "companyNameInReports",
        key: "companyNameInReports",
        width: 120,
        ellipsis: { showTitle: false },
        render: text => <Tooltip placement="topLeft" title={text || ""}>
            {text || ""}
        </Tooltip>
    },
    {
        title: "DATE",
        dataIndex: "date",
        key: "date",
        width: 100,
        ellipsis: { showTitle: false },
        render: text => <Tooltip placement="topLeft" title={text ? moment(text).format("MMM D, YYYY") : ""}>
            {text ? moment(text).format("MMM D, YYYY") : ""}
        </Tooltip>,
        // render: (date: string) => moment(date).format("MMM D, YYYY"),
    }]

    useEffect(() => {
        if (agreementTypes?.length && (!isAdmin || companies.length)) {
            fetchAgreements(selectedAgreementTypeId || agreementTypes[0]?.value, selectedCompanyId || [companies[0]?.value], search)
        }
    }, [agreementTypes, companies, search, selectedCompanyId, filterInfo, sortInfo])

    const createReports = () => {
        if (!selectedAgreements.length || !selectedKeyTerms.length) { return }
        setCreateReportsLoading(true)
        const params: any = {}
        if (arraysEqual(selectedKeyTerms.sort(), availableKeyTerms?.map(keyTerm => keyTerm?.id).sort())) {
            params.key_terms = 'all'
            setAllKeyTermsSelected(true)
        } else {
            params.key_term_ids = selectedKeyTerms
            setAllKeyTermsSelected(false)
        }
        if (arraysEqual(selectedAgreements, agreements?.map(agreement => agreement?.id))&&allKeyTermsSelected) {
            params.agreements = 'all'
        } else {
            params.agreement_ids = selectedAgreements
        }
        if (isAdmin) {
            ReportsService.createHLReports(
                {
                    agreement_type_id: selectedAgreementTypeId,
                    company_ids: selectedCompanyId,
                    companies: selectedCompanyId.length ? "" : "all",
                    ...params
                },
                (id: string) => {
                    history.push(AppRoutes.REPORTS_SPECIFIC.replace(":reportId", id))
                },
                () => { },
                () => {
                    setCreateReportsLoading(false)
                }
            )

        } else {
            ReportsService.createHFReports(
                {
                    agreement_type_id: selectedAgreementTypeId,
                    ...params
                },
                (id: string) => {
                    history.push(AppRoutes.REPORTS_SPECIFIC.replace(":reportId", id))
                },
                () => { },
                () => {
                    setCreateReportsLoading(false)
                }
            )
        }
    }
    const fetchReportHistory = () => {
        setHistoryLoading(true)
        if (isAdmin) {
            ReportsService.fetchHLReportHistory(
                (reportHistories: ReportHistory[]) => {
                    setReportHistory(reportHistories);
                },
                () => { },
                () => {
                    setHistoryLoading(false)
                }
            )
        } else {
            ReportsService.fetchHFReportHistory(
                (reportHistories: ReportHistory[]) => {
                    setReportHistory(reportHistories);
                },
                () => { },
                () => {
                    setHistoryLoading(false)
                }
            )
        }
    }

    const fetchAgreementTypes = () => {
        AgreementTypesService.fetchAgreementTypes(
            (response: AgreementTypes[]) => {
                const foramattedData = response?.map((agreement) => {
                    return { label: agreement.name, value: agreement.id };
                });
                // fetchAgreements(foramattedData[0]?.value)
                setAgreementTypes(foramattedData);
            },
            () => { },
            () => { }
        );
    };

    const fetchAgreements = (agreementTypeId: number, companyId?: number[], search?: string) => {
        setSelectedAgreementTypeId(agreementTypeId)
        setSelectedCompanyId(companyId)
        setAgreementLoading(true)
        if (isAdmin) {
            fetchHLAgreements(agreementTypeId, companyId, search, filterInfo.fund_ids, filterInfo.counter_party_ids, sortInfo)
        } else {
            fetchHFAgreements(agreementTypeId, companyId, search, filterInfo.fund_ids, filterInfo.counter_party_ids, sortInfo)
        }
    }

    const fetchCompanies = () => {
        ReportsService.fetchCompanies(
            (companies) => {
                setCompanies(
                    companies?.map((comp) => {
                        return { label: comp.companyName, value: comp.companyId };
                    })
                );
            },
            (error) => {
            },
            () => { }
        );
    };

    const fetchHLAgreements = (agreement_type_id: number, company_ids?: number[], name?: string, fund_ids?: number[], counter_party_ids?: number[], sortInfo?: {}) => {
        ReportsService.fetchHLAgreements(
            {
                agreement_type_id,
                company_ids,
                name,
                fund_ids,
                counter_party_ids,
                sortInfo
            },
            (response) => {
                setAgreements(response);
            },
            (error) => {
            },
            () => {
                setAgreementLoading(false);
            }
        );

    }

    const fetchHFAgreements = (agreement_type_id: number, company_ids?: number[], name?: string, fund_ids?: number[], counter_party_ids?: number[], sortInfo?: {}) => {
        setAgreementLoading(true)
        ReportsService.fetchHFAgreements(
            {
                agreement_type_id,
                company_ids,
                name,
                fund_ids,
                counter_party_ids,
                sortInfo
            },
            (response: Agreement[]) => {
                setAgreements(response);
            },
            (error) => {
            },
            () => {
                setAgreementLoading(false)
            }
        );
    }

    const fetchExcelData = (reportId: string) => {
        if (isAdmin) {
            ReportsService.excelDownloadHL(
                reportId,
                (url: string) => {
                    window.open(url, '_blank').focus();
                },
                () => { },
                () => { })
        } else {
            ReportsService.excelDownloadHF(
                reportId,
                (url: string) => {
                    window.open(url, '_blank').focus();
                },
                () => { },
                () => { })
        }
    }

    const selectionChangeHandler = (selectedRowKeys: number[]) => {
        setSelectedAgreements(selectedRowKeys)
    }

    const mailHandler = (reportId: string) => {
        if (isAdmin) {
            ReportsService.excelDownloadHL(
                reportId,
                (url: string) => {
                    window.open(mailTo({
                        subject: "Hedge Legal Reports",
                        body: 'You can download the report from the below given link  : \n\n' + url
                    }))
                },
                () => { },
                () => { })
        } else {
            ReportsService.excelDownloadHF(
                reportId,
                (url: string) => {
                    window.open(mailTo({
                        subject: "Hedge Fund Reports",
                        body: 'You can download the report from the below given link  : \n\n' + url
                    }))
                },
                () => { },
                () => { })
        }

    }

    const rowSelection = {
        selectedRowKeys: selectedAgreements,
        onChange: selectionChangeHandler,
    };

    const historyColumns = [{
        title: "DATE OF REPORT",
        dataIndex: "date",
        key: "date",
        width: 150,
        ellipsis: { showTitle: false },
        render: text => <Tooltip placement="topLeft" title={text ? moment(text).format("MMM D, YYYY") : ""}>
            {text ? moment(text).format("MMM D, YYYY") : ""}
        </Tooltip>,
    },
    {
        title: "TYPE",
        dataIndex: "agreementType",
        key: "agreementType",
        width: 150,
        ellipsis: { showTitle: false },
        render: text => <Tooltip placement="topLeft" title={text || ""}>
            {text || ""}
        </Tooltip>
    },
    {
        title: "NUMBER OF AGREEMENTS",
        dataIndex: "agreements",
        key: "agreements",
        width: 150
    },
    {
        title: "KEY TERMS",
        dataIndex: "keyTerms",
        key: "keyTerms",
        width: 150
    },
    {
        title: "",
        dataIndex: "actions",
        key: "actions",
        width: 150,
        render: (text, record) => (
            <Fragment>
                <i className="icon-view  hoverable-icon" onClick={(e) => {
                    e.stopPropagation()
                    history.push(AppRoutes.REPORTS_SPECIFIC.replace(":reportId", record?.id))
                }}></i>
                <i className="icon-export  hoverable-icon" onClick={(e) => {
                    e.stopPropagation()
                    fetchExcelData(record?.id)
                }}></i>
                <i className="icon-email hoverable-icon" onClick={(e) => {
                    e.stopPropagation()
                    mailHandler(record?.id)
                }}></i>
                <i className="icon-download hoverable-icon" onClick={(e) => {
                    e.stopPropagation()
                    downloadReportsList(record?.id)
                }}></i>
            </Fragment>
        )
    },
    ]

    const downloadReportsList = (reportId: string) => {
        if (isAdmin) {
            ReportsService.downloadHLReports(
                reportId,
                () => { },
                () => { },
                () => { })
        } else {
            ReportsService.downloadHFReports(
                reportId,
                () => { },
                () => { },
                () => { })
        }
    }

    const selectAllCompanies = (e: CheckboxChangeEvent) => {
        let allCompanies = [];
        if (e.target.checked) {
            allCompanies = companies?.map(company => company.value)
        }
        setSelectedAgreements([])
        setSelectedCompanyId(allCompanies)
    }

    const selectCompany = (values: CheckboxValueType[]) => {
        setSelectedAgreements([])
        setSelectedCompanyId([...values] as number[])
    }

    const menu = (
        <Menu className="reports__company-list">
            <Checkbox indeterminate={true} onChange={selectAllCompanies}>
                Check all
            </Checkbox>
            <Checkbox.Group value={selectedCompanyId} onChange={selectCompany} options={companies} />
        </Menu>
    );

    return <div className="reports__container">
        <PageHeaderComponent title="Reports" />
        <div className="table-title">
            <p>Select Agreements and Key Terms to compare
            </p>
            <div className="table-title--actions">
                <SearchInput onSearch={setSearch} />
                <Formik enableReinitialize
                    initialValues={{
                        typeId: '',
                        companyId: ''
                    }}
                    onSubmit={() => { }}
                >
                    {({ setFieldValue, validateField, values, dirty, errors, resetForm, isValid, setFieldTouched }) => {
                        return (
                            <Form>
                                <DropdownField
                                    mode="single"
                                    optionFilterProp="label"
                                    placeholder={"ISDA"}
                                    name="typeId"
                                    options={agreementTypes}
                                    showSearch={false}
                                    value={values.typeId ? values.typeId : agreementTypes[0]?.label}
                                    showLabel={false}
                                    onChange={(value, record) => {
                                        setFieldValue("typeId", value);
                                        setSelectedAgreementTypeId(value)
                                        fetchAgreements(value, selectedCompanyId)
                                    }}
                                />
                                {isAdmin &&
                                    <Dropdown overlay={menu} trigger={["click"]}>
                                        <ButtonComponent type="default">Select Companies</ButtonComponent>
                                    </Dropdown>
                                }
                            </Form>
                        );
                    }}
                </Formik>
                <ButtonComponent
                    type="primary"
                    loading={createReportsLoading}
                    onClick={() =>
                        createReports()
                    }>Run Report</ButtonComponent>

            </div>
        </div>
        <Row className="reports-generate__container">
            <Col sm={24} md={24} lg={16}>
                <TableComponent
                    scroll={{ y: 500 }}
                    pagination={false}
                    rowSelection={rowSelection}
                    className="table-container reports-table"
                    columns={columns}
                    rowKey={"id"}
                    data={agreementLoading ? [] : agreements}
                    locale={{
                        emptyText: agreementLoading ? <TableLoader length={6} /> : <EmptyState />
                    }}
                    showSearch={false}
                    onChange={selectionChangeHandler}
                    showCheckbox={true}
                />
            </Col>
            <Col sm={24} md={24} lg={8} className="key-term-col">
                <KeyTermPanels
                    isAllKeyTermsSelected={allKeyTermsSelected}
                    setAvailableKeyTerms={setAvailableKeyTerms}
                    setSelectedKeyTerms={setSelectedKeyTerms}
                    agreementTypeId={selectedAgreementTypeId}
                />
            </Col>
        </Row>
        <Row className="report-history-table">
            <Col span={24}>
                <TableComponent
                    title="History"
                    expandable={{
                        expandedRowRender: (e: ReportHistory) => {
                            return <NestedReportTable isAdmin={isAdmin} data={e} loading={historyLoading} />
                        },
                        expandRowByClick: true,
                        rowExpandable: (reportHistory: ReportHistory) => true,
                        expandIcon: () => null,
                        columnWidth: 0,
                    }}
                    className=" row-clickable"
                    showSearch={false}
                    loading={false}
                    columns={historyColumns}
                    scroll={{ x: 750 }}
                    data={reportHistory}

                />
            </Col>
        </Row>
    </div>
}

export default AuthContainer(Reports)