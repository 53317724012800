import { useLocation, useHistory } from 'react-router-dom';
import { removeFalsyKeys } from '../utils/removeFalsyKeys';

const useFilters = () => {

    const history = useHistory()
    const location = useLocation()
    const paramString = location.search

    const getParams = (keys: string[]) => {
        const params: any = {}
        keys?.forEach(key => {
            const value = new URLSearchParams(paramString).get(key)
            params[key] = Number(value) || value
        })
        return params
    }

    const setParams = (filters: any) => {
        const params = new URLSearchParams(removeFalsyKeys(filters))
        history.replace({
            pathname: location.pathname,
            hash: location.hash,
            search: params.toString()
        })
    }

    return {
        location,
        getParams,
        setParams,
    }
}

export default useFilters