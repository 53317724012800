import React, { FC } from "react";
import { AuthRouter } from "../components/Auth/AuthRouter";
import * as appRoutes from "./appRoutes";
import {
  Switch,
  Route,
  Router,
} from "react-router-dom";
import AuthContainer from "../store/containers/AuthContainer";
import HLHome from "../components/HL/Home";
import HFHome from "../components/HF/Home";
import doAuth from "../shared/components/HOC/doAuth";
import { createBrowserHistory } from "history";
import TermsOfUse from "../shared/components/TermsOfUse";
import PrivacyPolicy from "../shared/components/PrivacyPolicy";
const appHistory = createBrowserHistory();

const AppRoutes: FC = (props: any) => {
  const isAuthenticated = (component) => {
    return doAuth(component);
  };

  return (
    <Router history={appHistory}>
      <Switch>
        <Route exact={false} path={appRoutes.AUTH} component={AuthRouter} />
        <Route exact={false} path={appRoutes.TERMS_OF_USE} component={TermsOfUse} />
        <Route exact={false} path={appRoutes.PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route
          exact={false}
          path={appRoutes.HOME}
          component={props.userType == "HF" ? isAuthenticated(HFHome) : isAuthenticated(HLHome)}
        />
      </Switch>
    </Router>
  );
};
export default AuthContainer(AppRoutes);
