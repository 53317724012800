import { serializable, alias, primitive, object } from 'serializr';

export class VideoModel {

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('title', primitive()))
    title?: string;

    @serializable(alias('description', primitive()))
    description?: string;

    @serializable(alias('video_link', primitive()))
    link?: string;

}