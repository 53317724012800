import { HL_MANAGE_HFUND_COMPANY } from './../../routes/apiRoutes';
import { generatePath } from "react-router";
import { deserialize, serialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import { CompanyModel } from "../../models/HFund/hfund.model";
import { HFUND_COMPANIES, MANAGE_HFUND_COMPANY, MANAGE_HL_COMPANY } from "../../routes/apiRoutes";
import Notification from "../../shared/components/Notification";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";

export class CompanyService {
  static listAllCompanies(params: any, onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(HFUND_COMPANIES, {
        params: params
      })
      .then((response) => {
        //console.log(response);
        const companies = deserialize(
          CompanyModel,
          response["data"]["companies"]
        );
        onSuccess(companies);
      })
      .catch((error) => {
        onError(error);
        // Notification({
        //   message: "Failed",
        //   description: "Unable to fetch companies!",
        //   type: NotificationTypes.ERROR,
        // });
      });
  }
  static viewCompany(
    companyId: any,
    userType: "hl" | "hf",
    onSuccess: Function,
    onError: Function
  ) {
    return axiosInstance
      .get(generatePath(userType?.includes("hf") ? MANAGE_HFUND_COMPANY : HL_MANAGE_HFUND_COMPANY, { companyId }))
      .then((response) => {
        const company = deserialize(CompanyModel, response["data"]["company"]);
        onSuccess(company);
      })
      .catch((error) => {
        onError(error);
        // Notification({
        //   message: "Failed",
        //   description: "Unable to fetch company!",
        //   type: NotificationTypes.ERROR,
        // });
      });
  }
  static updateCompany(
    companyId: any,
    data: any,
    onSuccess: Function,
    onError: Function
  ) {
    const serializeData = serialize(CompanyModel, data);
    const payload = { company: serializeData };
    return axiosInstance
      .put(generatePath(MANAGE_HL_COMPANY, { companyId }), convertJSONToFormData(payload), { headers: { "Content-Type": "multipart/form-data" } })
      .then((response) => {
        const company = deserialize(CompanyModel, response["data"]["company"]);
        onSuccess(company);
      })
      .catch((error) => {
        console.log(error)
        onError(error);
        // Notification({
        //   message: "Failed",
        //   description: "Unable to update company!",
        //   type: NotificationTypes.ERROR,
        // });
      });
  }
  static updateHFCompany(
    companyId: any,
    userType: "hl" | "hf",
    companyDetails: any,
    onSuccess: Function,
    onError: Function
  ) {

    const companyJSON = {
      company: serialize(companyDetails)
    }
    return axiosInstance
      .put(generatePath(userType?.includes("hf") ? MANAGE_HFUND_COMPANY : HL_MANAGE_HFUND_COMPANY, { companyId }), convertJSONToFormData(companyJSON), { headers: { "Content-Type": "multipart/form-data" } })
      .then((response) => {
        const company = deserialize(CompanyModel, response["data"]["company"])
        onSuccess(company);
      })
      .catch((error) => {
        onError(error);
        // Notification({
        //   message: "Failed",
        //   description: error?.data?.error || "Unable to update company!",
        //   type: NotificationTypes.ERROR,
        // });
      });
  }
  static createCompany(data: any, onSuccess: Function, onError: Function) {

    return axiosInstance
      .post(HFUND_COMPANIES, data)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError({ error });
        Notification({
          message: "Failed",
          description: error?.data?.error || "Unable to create company!",
          type: NotificationTypes.ERROR,
        });
      });
  }
  static deleteCompany(companyId: any, onSuccess: Function, onError: Function) {
    return axiosInstance
      .delete(generatePath(MANAGE_HL_COMPANY, { companyId }))
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
        // Notification({
        //   message: "Failed",
        //   description: "Unable to delete company!",
        //   type: NotificationTypes.ERROR,
        // });
      });
  }
}
