import React, { FC } from 'react'
import { UIModal } from '../../../../../../../shared/components/UIModal'
import "./scorecardModal.scss"

interface ScorecardModalProps {
    visible: boolean
    closeHandler: () => void
    scorecardUrl: string
}

const ScorecardModal: FC<ScorecardModalProps> = (props) => {
    const {
        visible,
        closeHandler,
        scorecardUrl,
    } = props

    return (
        <UIModal
            visible={visible}
            closeModal={closeHandler}
            className='view-scorecard__modal'
            title="Scorecard"
        >
            <div className='view-scorecard__container'>
                <iframe src={scorecardUrl}></iframe>
            </div>
        </UIModal>
    )
}

export default ScorecardModal