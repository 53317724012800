import { Col, Form, Row } from "antd";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { FundStatusBE } from "../../../../../enums/fundStatuses";
import { NotificationTypes } from "../../../../../enums/notificationTypes";
import { Fund } from "../../../../../models/Fund/fund.model";
import { ProfileModel } from "../../../../../models/HFund/hfund.model";
import { HLFundsService } from "../../../../../services/FundService/Fund.service";
import { MetaService } from "../../../../../services/MetaService/meta.service";
import DatePickerComponent from "../../../../../shared/components/DatePickerComponent";
import DropdownField from "../../../../../shared/components/DropdownField";
import InputField from "../../../../../shared/components/InputField";
import Notification from "../../../../../shared/components/Notification";
import { UIModal } from "../../../../../shared/components/UIModal";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";
import {
  fundStatuses,
  fundStructure,
  feederFund,
  fundStrategies,
  fundSubStrategies,
  productsTraded,
  geographicFocus,
  fundBaseCurrencies,
} from "../../../../HF/Home/Funds/FundForm/fundDefinitions";
import { HLFundFormValidation } from "../../../../HF/Home/Funds/FundForm/fundFormValidation";

interface FundFormProps {
  visible: boolean;
  title: string;
  closeHandler: (fund?: Fund) => void;
  data?: Fund;
  disableTerminatedStatus?: boolean;
}

const FundForm = (props: FundFormProps) => {
  const { visible, title, closeHandler, data, disableTerminatedStatus } = props;
  const { state = {} }: { state: any } = useLocation();

  const [fundFormData, setFundFormData] = useState<Fund>(new Fund());
  const [countries, setCountries] = useState<any>();
  const [companies, setCompanies] = useState<any>();
  const [provinces, setProvinces] = useState<any>();
  const [cities, setCities] = useState<any>();
  const [availableStatus, setAvailableStatus] = useState<any[]>();
  const [companyid, setCompanyId] = useState<string>();
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);

  const fetchCountries = () => {
    MetaService.listCountries(
      (countries) => {
        setCountries(countries);
      },
      () => { }
    );
  };

  const fetchProvinces = (countryId: any) => {
    if (!countryId) {
      return;
    }
    MetaService.listProvinces(
      countryId,
      (provinces) => {
        setProvinces(provinces);

        if (fundFormData?.id) {
          fetchCities(
            fundFormData?.addresses[0]?.countryId,
            fundFormData?.addresses[0]?.provinceId
          );
        }
      },
      () => { }
    );
  };
  const fetchCities = (countryId: any, provinceId: any) => {
    if (!countryId || !provinceId) {
      return;
    }
    MetaService.listCities(
      countryId,
      provinceId,
      (cities) => {
        setCities(cities);
      },
      () => { }
    );
  };

  const fetchCompanies = () => {
    HLFundsService.fetchCompanyMeta(
      (companies) => {
        setCompanies(
          companies.map((comp) => {
            return { label: comp.companyName, value: comp.companyId };
          })
        );
      },
      (error) => {
        //console.log(error);
      },
      () => { }
    );
  };

  const fetchFundDetails = (id: number, companyId: number) => {
    HLFundsService.fetchSingleFund(
      id.toString(),
      { companyId: companyId.toString() },
      (fund: Fund) => {
        setFundFormData(fund);
        fetchProvinces(fund?.addresses[0]?.countryId);
        fetchCities(
          fund?.addresses[0]?.countryId,
          fund?.addresses[0]?.provinceId
        );
      },
      (err) => {
        // ;
        // Notification({
        //   message: "Hedge Legal",
        //   description: "Unable to fetch fund details",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };
  const onSubmit = (fund: Fund, resetForm: Function) => {
    setButtonLoader(true);
    if (fund.id) {
      HLFundsService.updateFund(
        fund,
        { companyId: companyid.toString() },
        (res) => {
          resetForm();
          closeHandler(res);
          Notification({
            message: "Hedge Legal",
            description: "Fund updated successfully",
            type: NotificationTypes.SUCCESS,
          });
        },
        (err) => {
          // ;
          // Notification({
          //   message: "Hedge Legal",
          //   description: "Fund updation failed",
          //   type: NotificationTypes.ERROR,
          // });
        },
        () => {
          setButtonLoader(false)
        }
      );
    } else {
      HLFundsService.createFund(
        fund,
        { companyId: fund?.companyId.toString() },
        (res: Fund) => {
          resetForm();
          closeHandler(res);
          Notification({
            message: "Hedge Legal",
            description: "Fund created successfully",
            type: NotificationTypes.SUCCESS,
          });
        },
        (err) => {
          // ;
          // Notification({
          //   message: "Hedge Legal",
          //   description: "Fund creation failed",
          //   type: NotificationTypes.ERROR,
          // });
        },
        () => {
          setButtonLoader(false)
        }
      );
    }
  };

  useEffect(() => {
    if (data && data.id && data.companyId) {
      setCompanyId(data.companyId.toString());
      fetchFundDetails(data.id, data.companyId);
    }
  }, [data]);

  useEffect(() => {
    if (disableTerminatedStatus) {
      setAvailableStatus(
        fundStatuses.filter((sts) => sts.value !== FundStatusBE.TERMINATED)
      );
    } else {
      setAvailableStatus(fundStatuses);
    }
    fetchCompanies();
    fetchCountries();
  }, []);

  return (
    <Formik enableReinitialize initialValues={fundFormData} validationSchema={HLFundFormValidation} onSubmit={() => { }}>
      {({ setFieldValue, values, dirty, resetForm, isValid, setFieldTouched }) => {
        return (
          <UIModal
            className="fund-form__modal"
            visible={visible}
            closeModal={closeHandler}
            title={(fundFormData.id ? "Edit " : "Add New ") + " Fund"}
            submitProps={{
              text: fundFormData.id ? "Update " : "Create",
              clickHandler: () => {
                onSubmit(values, resetForm);
              },
              disabled: !isValid || !dirty,
              loading: buttonLoader
            }}
            cancelProps={{
              text: "Cancel",
              clickHandler: () => {
                resetForm();
                closeHandler();
              },
            }}
          >
            <Form>
              <div className="fund-form__container">
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <InputField
                      placeholder={"Fund Name *"}
                      name="name"
                      type="text"
                      value={values.name}
                    />
                  </Col>
                  <Col sm={12} className="fund-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp={"companyName"}
                      placeholder={"Company Name *"}
                      name="companyId"
                      options={companies}
                      showSearch={false}
                      value={values.companyId}
                      setFieldTouched={() => setFieldTouched('companyId')}
                      onChange={(value) => {
                        setFieldValue("companyId", value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"Fund Domicile *"}
                      name="countryId"
                      options={countries}
                      showSearch={false}
                      value={values.countryId}
                      setFieldTouched={() => setFieldTouched('countryId')}
                      onChange={(value) => {
                        setFieldValue("countryId", value);
                        fetchProvinces(value);
                      }}
                    />
                  </Col>
                  <Col sm={12} className="fund-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"Fund Structure"}
                      name="fundStructure"
                      options={fundStructure}
                      showSearch={false}
                      value={values.fundStructure}
                      setFieldTouched={() => setFieldTouched('fundStructure')}
                      onChange={(value) =>
                        setFieldValue("fundStructure", value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"Feeder Fund"}
                      name="feederFund"
                      options={feederFund}
                      showSearch={false}
                      setFieldTouched={() => setFieldTouched('feederFund')}
                      value={
                        values?.id
                          ? values?.feederFundName
                            ? true
                            : false
                          : values?.feederFund
                      }
                      onChange={(value) => {
                        setFieldValue("feederFund", value);
                        //console.log("Set fund");

                        if (!value) {
                          //console.log("Set fund name");
                          setFieldValue("feederFundName", null);
                        }
                      }}
                    />
                  </Col>
                  {(values?.feederFund ||
                    (values?.id && values?.feederFundName)) && (
                      <Col sm={12} className="fund-form__field">
                        <InputField
                          placeholder={"Feeder Fund Name (if applicable)"}
                          name="feederFundName"
                          type="text"
                          value={values?.feederFundName}
                        />
                      </Col>
                    )}
                </Row>
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"Status *"}
                      name="status"
                      options={availableStatus}
                      showSearch={false}
                      value={values.status}
                      setFieldTouched={() => setFieldTouched('status')}
                      onChange={(value) => setFieldValue("status", value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <InputField
                      placeholder={"Management Company Name *"}
                      name="companyName"
                      type="text"
                      value={values.companyName}
                    />
                  </Col>
                  <Col sm={12} className="fund-form__field">
                    <InputField
                      placeholder={"Investment Manager Name *"}
                      name="investmentManagerName"
                      type="text"
                      value={values.investmentManagerName}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <InputField
                      placeholder={"Sub Advisor (if applicable)"}
                      name="subAdvisorName"
                      type="text"
                      value={values.subAdvisorName}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"Strategy"}
                      name="strategy"
                      options={fundStrategies}
                      showSearch={false}
                      value={values.strategy}
                      setFieldTouched={() => setFieldTouched('strategy')}
                      onChange={(value) => setFieldValue("strategy", value)}
                    />
                  </Col>
                  <Col sm={12} className="fund-form__field">
                    <DropdownField
                      mode="multiple"
                      optionFilterProp="label"
                      placeholder={"Sub Strategy (if applicable)"}
                      name="subStrategy"
                      options={fundSubStrategies}
                      showSearch={false}
                      value={values.subStrategy}
                      setFieldTouched={() => setFieldTouched('subStrategy')}
                      onChange={(value) => setFieldValue("subStrategy", value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <DropdownField
                      mode="multiple"
                      optionFilterProp="label"
                      placeholder={"Products Traded"}
                      name="productsTraded"
                      options={productsTraded}
                      showSearch={false}
                      value={values.productsTraded}
                      setFieldTouched={() => setFieldTouched('productsTraded')}
                      onChange={(value) =>
                        setFieldValue("productsTraded", value)
                      }
                    />
                  </Col>
                  <Col sm={12} className="fund-form__field">
                    <DropdownField
                      mode="multiple"
                      optionFilterProp="label"
                      placeholder={"Geographic Focus/Exposure"}
                      name="geographicFocus"
                      options={geographicFocus}
                      showSearch={false}
                      value={values.geographicFocus}
                      setFieldTouched={() => setFieldTouched('geographicFocus')}
                      onChange={(value) =>
                        setFieldValue("geographicFocus", value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <DatePickerComponent
                      placeholder={"Target Launch Date"}
                      name="targetLaunchDate"
                      className="input-field"
                      value={
                        values?.targetLaunchDate
                        && moment(values?.targetLaunchDate)
                      }
                      onChange={(value, dateString) => {
                        setFieldValue("targetLaunchDate", dateString);
                      }}
                    ></DatePickerComponent>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <InputField
                      placeholder={"Target AUM"}
                      name="targetAum"
                      type="number"
                      value={values.targetAum}
                    />
                  </Col>
                  {values.status === FundStatusBE.ACTIVE && (
                    <>
                      <Col sm={12} className="fund-form__field">
                        <InputField
                          placeholder={"Current AUM"}
                          name="aum"
                          type="number"
                          value={values.aum}
                        />
                      </Col>
                      <Col sm={12} className="fund-form__field">
                        <InputField
                          placeholder={"AUM at launch"}
                          name="aumAtLaunch"
                          type="number"
                          value={values.aumAtLaunch}
                        />
                      </Col>
                    </>
                  )}
                  <Col sm={12} className="fund-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"Fund Base Currency"}
                      name="baseCurrency"
                      options={fundBaseCurrencies}
                      showSearch={false}
                      value={values.baseCurrency}
                      setFieldTouched={() => setFieldTouched('baseCurrency')}
                      onChange={(value) => setFieldValue("baseCurrency", value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <InputField
                      placeholder={"Leverage (Long)"}
                      name="leverageLong"
                      type="text"
                      value={values.leverageLong}
                    />
                  </Col>
                  <Col sm={12} className="fund-form__field">
                    <InputField
                      placeholder={"Leverage (Short)"}
                      name="leverageShort"
                      type="text"
                      value={values.leverageShort}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <InputField
                      placeholder={"Leverage (Net)"}
                      name="leverageNet"
                      type="text"
                      value={values.leverageNet}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <InputField
                      placeholder={"LEI"}
                      name="lei"
                      type="text"
                      value={values.lei}
                    />
                  </Col>
                  <Col sm={12} className="fund-form__field">
                    <InputField
                      placeholder={"General Partner if applicable/Platform"}
                      name="partnerName"
                      type="text"
                      value={values.partnerName}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <InputField
                      placeholder={"Fund Administrator"}
                      name="fundAdministrator"
                      type="text"
                      value={values.fundAdministrator}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <InputField
                      placeholder={"Registered Address *"}
                      name="addresses[0].street"
                      type="text"
                      value={values?.addresses[0]?.street}
                    />
                  </Col>
                  <Col sm={12} className="fund-form__field">
                    <InputField
                      placeholder={"Address 2"}
                      name="addresses[0].addressLine"
                      type="text"
                      value={values?.addresses[0]?.addressLine}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"Country *"}
                      name="addresses[0].countryId"
                      options={countries}
                      showSearch={false}
                      value={values.addresses[0]?.countryId}
                      setFieldTouched={() => setFieldTouched('addresses[0].countryId')}
                      defaultValue={values.addresses[0]?.countryId}
                      onChange={(value) => {
                        setFieldValue("addresses[0].countryId", value);
                        fetchProvinces(value);
                      }}
                    />
                  </Col>
                  <Col sm={12} className="fund-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"State/Province *"}
                      name="addresses[0].provinceId"
                      options={provinces}
                      showSearch={false}
                      value={values.addresses[0]?.provinceId}
                      setFieldTouched={() => setFieldTouched('addresses[0].provinceId')}
                      defaultValue={values.addresses[0]?.provinceName}
                      onChange={(value) => {
                        setFieldValue("addresses[0].provinceId", value);
                        fetchCities(values?.addresses[0]?.countryId, value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} className="fund-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"City *"}
                      name="addresses[0].cityId"
                      options={cities}
                      showSearch={false}
                      value={values.addresses[0]?.cityId}
                      setFieldTouched={() => setFieldTouched('addresses[0].cityId')}
                      onChange={(value) => {
                        setFieldValue("addresses[0].cityId", value);
                        setFieldValue("addresses[0].addressType", "registered");
                      }}
                    />
                  </Col>
                  <Col sm={12} className="fund-form__field">
                    <InputField
                      placeholder={"Zip Code/Postal Code *"}
                      name="addresses[0].zipcode"
                      type="text"
                      value={values.addresses[0]?.zipcode}
                    />
                  </Col>
                </Row>
              </div>
            </Form>
          </UIModal>
        );
      }}
    </Formik>
  );
};

export default FundForm;
