import * as ApiRoutes from "../../routes/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { UserCounterParties } from "../../models/CounterParties/userCounterParties.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { generatePath } from "react-router";

export default class UserCounterPartiesService {
  static fetchUserCounterParties(
    counterPartyId: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(generatePath(ApiRoutes.HL_COUNTER_PARTIES_USERS, { counterPartyId }))
      .then((response) => {
        const UserCounterPartiess = deserialize(
          UserCounterParties,
          response.data["user_counter_parties"]
        );
        onSuccess(UserCounterPartiess);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in fetching counter party users!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }


  static createUserCounterParties(
    counterPartyId: any,
    counterPartyUser: UserCounterParties,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const serializeData = serialize(UserCounterParties, counterPartyUser);
    const payload = { user_counter_party: serializeData };
    axiosInstance
      .post(
        generatePath(ApiRoutes.HL_COUNTER_PARTIES_USERS, { counterPartyId }),
        payload
      )
      .then((response) => {
        const data = deserialize(UserCounterParties, response.data["user_counter_party"]);
        Notification({
          message: 'Hedge Legal',
          description: "Counter Party created successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in creating counter party user!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateUserCounterParties(
    counterPartyId: any,
    counterPartyUserId: any,
    counterPartyUser: UserCounterParties,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_COUNTER_PARTIES_SINGLE_USER, {
      counterPartyId,
      counterPartyUserId
    });
    const serializeData = serialize(UserCounterParties, counterPartyUser);
    const payload = { user_counter_party: serializeData };
    axiosInstance
      .put(API_URL, payload)
      .then((response) => {
        const data = deserialize(
          UserCounterParties,
          response.data["user_counter_party"]
        );
        Notification({
          message: 'Hedge Legal',
          description: "Counter Party updated successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in creating counter party user!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteUserCounterParties(
    counterPartyId: any,
    counterPartyUserId: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_COUNTER_PARTIES_SINGLE_USER, {
      counterPartyUserId,
      counterPartyId: counterPartyId,
    });
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in deleting counter party user!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
