import { LoadingOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Form, Row, Col } from "antd";
import { Formik, FormikValues } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS } from "../../../routes/appRoutes";
import { AuthService } from "../../../services/AuthService/auth.service";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import InputField from "../../../shared/components/InputField";
import "./forgotPassword.scss"

const ForgotPassword = (props) => {

    const history = useHistory();
    const initialValues = {
        email: "",
    };
    const [loading, setLoading] = useState<boolean>(false);

    const forgotPasswordSubmissionHandler = (values: FormikValues) => {
        const domain = window.location.origin
        setLoading(true)
        AuthService.forgotPassword(
            values.email,
            domain + "/auth/reset-password",
            () => {
                history.push(FORGOT_PASSWORD_SUCCESS)
            },
            () => { },
            () => {
                setLoading(false)
            },
        )
    }

    return (
        <div className="forgot-password-form__container">
            <i className="icon-back" onClick={() => {
                history.goBack()
            }} />
            <h1>Forgot Password</h1>
            <p>Enter Registered Email to receive password reset instruction</p>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    //console.log(values);
                }}
            >
                {({ values }) => (
                    <Form
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                forgotPasswordSubmissionHandler(values);
                            }
                        }}
                    >
                        <Row >
                            <Col span={24} className="forgot-password-form__wrapper">
                                <InputField
                                    type="email"
                                    name="email"
                                    placeholder="Registered Email ID" />
                            </Col>
                            <Col span={24}>
                                <div className="btn__wrapper">
                                    <ButtonComponent
                                        type={"primary"}
                                        onClick={() => {
                                            forgotPasswordSubmissionHandler(values);
                                        }}
                                        iconRight={
                                            loading ? <LoadingOutlined /> : <ArrowRightOutlined />
                                        }
                                        displayType="space-between"
                                        disabled={!values.email}
                                    >
                                        Send Reset Link
                                    </ButtonComponent>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ForgotPassword