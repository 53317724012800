class Text {
    static capitalize(text: string) {
        return text.charAt(0).toUpperCase() + text.slice(1, text.length);
    }

    static snakeToCamel(text: string) {
        if(!text) {
            return ""
        }
        return this.capitalize(text.replace(/_/g, " "));
    }
}

export default Text;