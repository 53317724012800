import React, { useEffect, useState } from "react";
import { SupportTicket } from "../../../../models/Ticket/ticket.model";
import { HelpService } from "../../../../services/HelpService/helpService.service";
import EmptyState from "../../../../shared/components/EmptyState";
import Loader from "../../../../shared/components/Loader";
import RaisedTicket from "../../../../shared/components/RaisedTicket";
import SupportTicketForm from "./SupportTicketForm";
import "./supportTickets.scss"

interface SupportTicketsProps {
    supportTicketFormVisible: boolean
    setSupportTicketFormVisible: (visiblity: boolean, ticket?: SupportTicket) => void
}

const SupportTickets = (props: SupportTicketsProps) => {


    const {
        supportTicketFormVisible,
        setSupportTicketFormVisible,
    } = props

    const [tickets, setTickets] = useState<SupportTicket[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)

    useEffect(() => {
        fetchTickets()
    }, [])

    const fetchTickets = () => {
        setLoading(true)
        HelpService.fetchHFTickets(
            (tickets: SupportTicket[]) => {
                setTickets(tickets)
                setLoading(false)
            },
            () => { },
            () => { }
        )
    }

    const submitHandler = (values: SupportTicket, resetForm: Function) => {
        setSubmitting(true)
        HelpService.createHFTicket(
            values,
            () => {
                fetchTickets()
                resetForm()
                setSupportTicketFormVisible(false)
            },
            () => { },
            () => {
                setSubmitting(false)
            }
        )
    }

    const deleteHandler = (ticketId: number) => {
        HelpService.deleteHFTicket(
            ticketId,
            () => {
                fetchTickets()
            },
            () => { },
            () => {

            }
        )
    }

    const closeFormHandler = () => {
        setSupportTicketFormVisible(false)
    }

    return <>
        <div className="support-tickets__container">
            {loading
                ? <Loader />
                : tickets.length
                    ? tickets?.map(ticket => <RaisedTicket
                        ticket={ticket}
                        deleteHandler={deleteHandler}
                    />)
                    : <EmptyState centerAlign={true} />
            }
        </div>
        <SupportTicketForm
            loading={submitting}
            visible={supportTicketFormVisible}
            closeHandler={closeFormHandler}
            submitHandler={submitHandler} />
    </>
}

export default SupportTickets