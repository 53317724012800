import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { HelpTabList } from "../../../enums/helpTabList";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { PageHeaderComponent } from "../../../shared/components/PageHeader";
import AuthContainer from "../../../store/containers/AuthContainer";
import { IAuthState } from "../../../store/reducers/authReducer";
import Faq from "./Faq";
import SupportTickets from "./SupportTickets";
import Video from "./Video";

interface HelpProps extends IAuthState { }

const Help = (props: HelpProps) => {

    const {
        userType
    } = props

    const [activeTab, setActiveTab] = useState<number>(1);
    const [tabs, setTabs] = useState<string[]>(Object.values(HelpTabList));
    const [faqFormVisible, setFaqFormVisible] = useState<boolean>(false)
    const [videoFormVisible, setVideoFormVisible] = useState<boolean>(false)
    const [supportTicketFormVisible, setSupportTicketFormVisible] = useState<boolean>(false)

    useEffect(() => {
        if (userType === 'HL')
            setTabs(prev => prev.filter(tab => tab !== HelpTabList.SUPPPORT_TICKETS))
    }, [userType])

    const tabChangeHandler = (e: string) => setActiveTab(+e);

    const helpPageHeaderButtonGenerator = () => {
        if (activeTab === 1 && userType === 'HL') {
            return <ButtonComponent
                type="primary"
                onClick={() => setFaqFormVisible(true)}
            > <PlusOutlined /> Add New FAQ</ButtonComponent>
        } else if (activeTab === 2 && userType === 'HL') {
            return <ButtonComponent
                type="primary"
                onClick={() => setVideoFormVisible(true)}
            > <PlusOutlined /> Add New Video</ButtonComponent>
        } else if (activeTab === 3 && userType === 'HF') {
            return <ButtonComponent
                type="primary"
                onClick={() => setSupportTicketFormVisible(true)}
            > <PlusOutlined /> Raise Ticket</ButtonComponent>
        }
        return null
    }

    return (
        <div className="hf-agreements">
            <PageHeaderComponent
                onChangeTab={tabChangeHandler}
                title="Help"
                showTabs
                tabs={tabs}
                buttons={helpPageHeaderButtonGenerator()}
            />
            <div className="help-list">
                {activeTab === 1 && (
                    <Faq
                        isAdmin={userType === 'HL'}
                        faqFormVisible={faqFormVisible}
                        setFaqFormVisible={setFaqFormVisible} />
                )}
                {activeTab === 2 && (
                    <Video
                        isAdmin={userType === 'HL'}
                        videoFormVisible={videoFormVisible}
                        setVideoFormVisible={setVideoFormVisible} />
                )}
                {activeTab === 3 && userType === "HF" && (
                    <SupportTickets
                        supportTicketFormVisible={supportTicketFormVisible}
                        setSupportTicketFormVisible={setSupportTicketFormVisible}
                    />
                )}
            </div>
        </div>
    );
}

export default AuthContainer(Help)