import { useState } from "react";
import { deserialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { AuditTrail } from "../../models/AuditTrail/auditTrail.model";
import {
  GET_AUDIT_TRAIL_VIEW,
  LIST_AUDIT_TRIALS,
  UNDO_AUDIT_TRAIL,
} from "../../routes/apiRoutes";
import { LocalStorage } from "../../shared/utils/localStorageHelpers";

const AuditTrialService = () => {
  const [auditTrails, setAuditTrails] = useState<AuditTrail[]>([]);

  const [auditTrail, setAuditTrail] = useState<AuditTrail>();

  const [successiveTrails, setSuccessiveTrails] = useState<AuditTrail[]>([]);

  const [loading, setLoading] = useState(false);

  const getUserType = () => {
    const user = LocalStorage.getItem("USER");
    return user?.role?.includes("hf") ? "hf" : "hl";
  };

  const getAuditTrials = async () => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get(LIST_AUDIT_TRIALS);
      const auditTrails = deserialize(AuditTrail, data?.audit_trails as any[]);
      setAuditTrails(auditTrails);
    } catch (error) {

    } finally {
      setLoading(false)
    }
  };

  const getAuditTrial = async (audit_trail_id?: number) => {
    try {
      const { data } = await axiosInstance.get(GET_AUDIT_TRAIL_VIEW, {
        params: {
          audit_trail_id,
        },
      });
      const auditTrail = deserialize(AuditTrail, data?.audit_trail);
      const successiveTrails = deserialize(
        AuditTrail,
        data?.successive_trails as any[]
      );
      setAuditTrail(auditTrail);
      setSuccessiveTrails(successiveTrails);
    } catch (error) { }
  };

  const undoAudit = async (audit_trail_id?: number) => {
    setLoading(true);
    try {
      await axiosInstance.post(
        UNDO_AUDIT_TRAIL?.replace(":userRole", getUserType()),
        {
          audit_trail_id,
        }
      );
      return true;
    } catch (error) {
      setLoading(false);
    }
  };

  return {
    loading,
    auditTrail,
    successiveTrails,
    getAuditTrial,
    auditTrails,
    getAuditTrials,
    undoAudit,
  };
};

export default AuditTrialService;
