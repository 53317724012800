import React, { FC, useEffect, useRef, useState } from "react";
import { PageHeaderComponent } from "../../../../shared/components/PageHeader";
import AgreementsList from "./ListAgreements";
import "./agreements.scss";
import { DownloadOutlined, ExportOutlined } from "@ant-design/icons";
import { Button, Checkbox, Space, Tag } from "antd";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import PopoverComponent from "../../../../shared/components/PopoverComponent";
import { HFAgreementService } from "../../../../services/AgreementService/agreement.service";
import {
  Agreement,
  AgreementStatus,
} from "../../../../models/Agreements/agreement.model";
import Notification from "../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../enums/notificationTypes";
import AgreementForm from "./AgreementForm";
import { downloadAllChoices } from "./agreementsDefinitions";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { ConfirmationAlert } from "../../../../shared/components/ConfirmationAlert";
import { deserialize } from "serializr";
import { AgreementTypes } from "../../../../models/Agreements/agreementTypes.model";
import { CounterParties } from "../../../../models/CounterParties/counterParties.model";
import { Fund } from "../../../../models/Fund/fund.model";
import { HFCounterPartiesService } from "../../../../services/CounterPartiesService/CounterParties.service";
import { FundsService } from "../../../../services/FundService/Fund.service";
import AgreementTypesService from "../../../../services/KeyTermsService/AgreementTypes.service";
import { MetaService } from "../../../../services/MetaService/meta.service";
import { temporarySorter } from "../../../../shared/utils/tableSorter";
import { PaginationMeta } from "../../../../models/Meta/pagination-meta.model";
import { OnboardingItems } from "../../../../models/Meta/meta.model";
import { useHistory } from "react-router";
import useFilters from "../../../../shared/hooks/useFilters";

const Agreements: FC = () => {
  const history = useHistory()
  const { getParams, setParams } = useFilters()
  const [agreements, setAgreements] = useState<Agreement[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [successVisible, setSuccessVisibility] = useState(false);
  const [downloadChoices, setDownloadChoices] = useState<
    string[] | CheckboxValueType[]
  >(["all"]);
  const choicePopoverButton = useRef(null);
  const [agreementStatus, setAgreementStatus] = useState<any[]>([]);
  const [counterParties, setCounterParties] = useState<any[]>([]);
  const [funds, setFunds] = useState<any[]>([]);
  const [agreementTypes, setAgreementTypes] = useState<any[]>([]);
  const [filterInfo, setFilterInfo] = useState<{ key: string; value: any }[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationMeta>();

  useEffect(() => {
    fetchFundsList();
    fetchCPList();
    fetchAgreementTypes();
    fetchAgreementStatus();
  }, []);

  let timer;
  useEffect(() => {
    timer = setTimeout(() => {
      fetchAgreements(filterInfo);
    }, 800);

    return () => {
      if (timer)
        clearTimeout(timer)
    }
  }, [filterInfo]);

  useEffect(() => {
    const filters = getParams(["status", "type"]);
    Object.keys(filters).length && setFilterInfo(prev => ({ ...prev, ...filters }));
  }, [history.location.search])

  const fetchAgreementStatus = () => {
    MetaService.agreementStatus(
      (response) => {
        const data = deserialize(
          AgreementStatus,
          response.data["agreement_statuses"]
        ) as AgreementStatus[];
        const formattedData = data.map((sts) => {
          return {
            label: sts.name.replaceAll("_", " "),
            value: sts.id,
            color: sts.color,
          };
        });
        setAgreementStatus(formattedData);
      },
      (error) => {
      }
    );
  };

  const fetchCPList = () => {
    HFCounterPartiesService.fetchCounterParties(
      { meta: true },
      (response: CounterParties[]) => {
        setCounterParties(deserialize(OnboardingItems, response));
      },
      () => { },
      () => { }
    );
  };

  const fetchFundsList = () => {
    FundsService.fetchFunds(
      { meta: true },
      (response: Fund[]) => {
        setFunds(deserialize(OnboardingItems, response));
      },
      (error) => {
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to fetch funds",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  const fetchAgreementTypes = () => {
    AgreementTypesService.fetchAgreementTypes(
      (response: AgreementTypes[]) => {
        const foramattedData = response.map((agreement) => {
          return { label: agreement.name, value: agreement.id };
        });
        setAgreementTypes(foramattedData);
      },
      () => { },
      () => { }
    );
  };

  const fetchAgreements = (
    filters: any = {},
    tableLoading: Function = () => { },
    config?: any
  ) => {
    setAgreements([])
    setLoading(true)
    if (filters?.status)
      filters["agreement_status_ids"] = [+filters.status]
    if (filters?.type)
      filters["agreement_type_ids"] = [+filters.type]
    HFAgreementService.fetchAgreements(
      filters,
      (response, pagination) => {
        // const data = temporarySorter(response, config?.key, config?.order);
        setAgreements(response);
        setPagination(pagination)
      },
      (error) => {
        //console.log(error);
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to fetch agreements",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => {
        tableLoading(false);
        setLoading(false)
        setParams({})
      }
    );
  };

  const exportAgreements = (
    filters: any = {}
  ) => {
    HFAgreementService.exportAgreements(
      filters,
      () => { },
      () => { },
      () => { },
    )
  };

  const dowloadAllChoicesHandler = (downloadChoices: any[]) => {
    const params = {};
    downloadChoices.forEach((value) => {
      params[value] = true;
    });
    HFAgreementService.downloadAgreements(
      params,
      (response) => {
        setDownloadChoices(["all"]);;
        choicePopoverButton.current.click();
        setSuccessVisibility(true);
      },
      (error) => {
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to download all the agreements",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  return (
    <div className="agreements__container">
      <PageHeaderComponent title="Agreements" />
      <div className="agreements-list">
        <AgreementsList
          funds={funds}
          counterParties={counterParties}
          agreementStatus={agreementStatus}
          agreementTypes={agreementTypes}
          updateFilter={setFilterInfo}
          onAdd={() => {
            setVisible(true);
          }}
          filters={filterInfo}
          loading={loading}
          data={agreements}
          showSearch={true}
          showAdd={true}
          pagination={pagination}
          closeHandler={(agreement?: Agreement, filters?: any) => {
            if (agreement?.id) {
              setFilterInfo((prev) => {
                return { ...prev };
              });
            }
            setVisible(false);
          }}
          additionalButtons={
            <>
              <PopoverComponent
                className="download-all"
                showHeader={false}
                content={
                  <>
                    <Checkbox.Group
                      className="download-all-options"
                      options={downloadAllChoices}
                      value={downloadChoices}
                      onChange={(values) => setDownloadChoices(values)}
                    />
                    <ButtonComponent
                      size="small"
                      type="primary"
                      className="download-all--btn"
                      onClick={() => dowloadAllChoicesHandler(downloadChoices)}
                    >
                      Download
                    </ButtonComponent>
                  </>
                }
              >
                <div ref={choicePopoverButton}>
                  <ButtonComponent
                    type="default"
                    size="large"
                    icon={<DownloadOutlined />}
                  />
                </div>
              </PopoverComponent>
              <ButtonComponent
                type="default"
                size="large"
                icon={<i className="icon-export primary" />}
                onClick={() => exportAgreements(filterInfo)}
              />
            </>
          }
        />
      </div>
      <AgreementForm
        visible={visible}
        funds={funds}
        counterParties={counterParties}
        agreementStatus={agreementStatus}
        agreementTypes={agreementTypes}
        closeHandler={(agreement?: Agreement) => {
          if (agreement?.id) {
            setFilterInfo((prev) => {
              return { ...prev };
            });
          }
          setVisible(false);
        }}
      />
      <ConfirmationAlert
        closeHandler={() => {
          setSuccessVisibility(false);
        }}
        visible={successVisible}
        type="success"
        customButtons={
          <div className="download-success--buttons">
            <ButtonComponent
              onClick={() => {
                setSuccessVisibility(false);
              }}
              type="ghost"
            >
              Close
            </ButtonComponent>
          </div>
        }
        cancelHandler={() => {
          setSuccessVisibility(false);
        }}
        title={"Download request successful"}
        description="Link to the documents will be shared with your mail id shortly.."
      />
    </div>
  );
};

export default Agreements;
