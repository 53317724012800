import React, { FC, useState } from "react";
import { useLocation } from "react-router";
import { ProfileModel } from "../../../models/HFund/hfund.model";
import MultiFactorAuthentication from "../MultiFactorAuthentication";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ArrowLeftOutlined } from "@ant-design/icons";

import "./mfaVerification.scss"
import AuthContainer from "../../../store/containers/AuthContainer";
interface LoginProps extends AuthReducerProps { }

const MfaVerification: FC<LoginProps> = (props) => {
    const { state }: { state: ProfileModel } = useLocation()
    const [enableRecovery, setEnableRecovery] = useState<boolean>(false);
    const { user, setUser, userType } = props

    const toggleRecovery = () => {
        setEnableRecovery(!enableRecovery)
    }

    return (
        <div className="profile-verification__container">
            {enableRecovery &&
                <div className="float_left">
                    <ArrowLeftOutlined onClick={toggleRecovery} />
                </div>
            }
            {!enableRecovery && (
                <div className="mt-4">
                    <h1>Multi factor authentication</h1>
                    <p className="mt-2">
                        Enter your one time passcode token from the google authenticator app.
                    </p>
                </div>
            )}
            {enableRecovery && (
                <div className="mt-4">
                    <div className="mt-2">
                        <h1>Use backup verification code</h1>
                    </div>
                    <p className="mt-2">
                        Backup verification code are 6-digit code that are given to you
                        when you set up multi-factor authentication
                    </p>
                </div>
            )}
            <div className="mt-2">
                <MultiFactorAuthentication
                    user={user}
                    setUser={setUser}
                    toggleMfa={true}
                    loggedIn={false}
                    closeModalHandler={() => { }}
                    userType={userType}
                />
            </div>
            {!enableRecovery && <div className="mt-3">
                <a onClick={toggleRecovery}>Problem signing in ?</a>
            </div>}

        </div>
    );
};

export default AuthContainer(MfaVerification);
