import { roles } from "../components/HOC/roles";
import { LocalStorage } from "./localStorageHelpers";

export default Object.freeze({
  [roles.HF_ADMIN]: "HF Admin",
  [roles.HF_USER]: "HF User",
  [roles.HL_ADMIN]: "HL Admin",
  [roles.HL_USER]: "HL User",
  [roles.HL_SUPERADMIN]: "HL Super Admin",
  [roles.HF_SUPER_ADMIN]: "HF Super Admin",
});

export const isHFUser = () => {
  const user = LocalStorage.getItem("USER");
  return [roles.HF_USER, roles.HF_ADMIN, roles.HF_SUPER_ADMIN].includes(user?.role);
}