import { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import { OtherNotification } from "../../models/OtherNotification/otherNotification.model";
import {
  ACTION_CABLE_URL,
  DELETE_NOTIFICATION,
  LIST_AGREEMENT_NOTIFICATIONS,
  LIST_ANCILLARY_NOTIFICATIONS,
  LIST_NOTIFICATION_META,
  LIST_ONBOARDING_NOTIFICATIONS,
  LIST_OTHER_NOTIFICATIONS,
  NOTIFICATIONS_UPDATE_COMPLETION,
  NOTIFICATION_MARK_AS_VIEWED,
  // SHARED_NOTIFICATION_MANAGEMENT,
} from "../../routes/apiRoutes";
import { deserialize, serialize } from "serializr";
import { AgreementNotification } from "../../models/AgreementNotification/agreementNotification.model";
import { AncillaryNotification } from "../../models/AncillaryNotification/ancillaryNotification.model";
import { OnboardingNotification } from "../../models/OnboardingNotification/onboardingNotification.model";
import { LocalStorage } from "../../shared/utils/localStorageHelpers";
import { Notification } from "../../models/Notification/notification.model";
import { NotificationType } from "../../enums/notificationType";
import { generatePath } from "react-router";
import { NotificationModel } from "../../models/Meta/notification.model";
import { createConsumer } from "@rails/actioncable";

const NotificationService = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const [tableNotifications, setTableNotifications] = useState<Notification[]>(
    []
  );

  const [otherNotifications, setOtherNotifications] = useState<
    OtherNotification[]
  >([]);

  const [agreementNotifications, setAgreementNotifications] = useState<
    AgreementNotification[]
  >([]);

  const [ancillaryNotifications, setAncillaryNotifications] = useState<
    AncillaryNotification[]
  >([]);

  const [onboardingNotifications, setOnboardingNotifications] = useState<
    OnboardingNotification[]
  >([]);

  const [loading, setLoading] = useState(false);

  const updateWithUserType = (url: string) => {
    const user = LocalStorage.getItem("USER");
    return url?.replace(":userRole", user?.role?.includes("hf") ? "hf" : "hl");
  };

  const getNotifications = async () => {
    try {
      const { data } = await axiosInstance.get(LIST_NOTIFICATION_META);
      const notifications = deserialize(
        Notification,
        data?.notification_meta as any[]
      );
      setNotifications(notifications);
    } catch (error) {
      console.log(error?.message);
    }
  };

  const updateNotifications = async (notification: Notification) => {
    try {
      const notificationJSON = {
        notification_metum: serialize(notification)
      } 
      const { data } = await axiosInstance.put(LIST_NOTIFICATION_META + "/" + notification?.id , notificationJSON);
      const updatedNotification = deserialize(
        Notification,
        data?.notification_metum
      );
      
      setNotifications(notifications?.map(existingNotification => {
        if(existingNotification?.id === updatedNotification?.id) {
          return updatedNotification;
        }
        return existingNotification;
      }));
      return true;
    } catch (error) {
      console.log(error?.message);
    }
  };

  // const getTableNotifications = async (
  //   notifiableType: NotificationType,
  //   notifiableId: string
  // ) => {
  //   try {
  //     const API_URL = generatePath(SHARED_NOTIFICATION_MANAGEMENT, {
  //       notifiableType,
  //       notifiableId,
  //     });
  //     const { data } = await axiosInstance.get(API_URL);

  //     const notifications = deserialize(
  //       Notification,
  //       data?.notifications as any[]
  //     );
  //     setTableNotifications(notifications);
  //   } catch (error) {
  //     console.log(error?.message);
  //   }
  // };

  // const createTableNotifications = async (
  //   notification: Notification,
  //   notifiableType: NotificationType,
  //   notifiableId: string
  // ) => {
  //   try {
  //     const API_URL = generatePath(SHARED_NOTIFICATION_MANAGEMENT, {
  //       notifiableType,
  //       notifiableId,
  //     });

  //     const notificationJSON = {
  //       notification: serialize(notification),
  //     };

  //     const { data } = await axiosInstance.post(API_URL, notificationJSON);

  //     const createdNotification = deserialize(Notification, data?.notification);

  //     return createdNotification;
  //   } catch (error) {
  //     console.log(error?.message);
  //   }
  // };

  // const updateTableNotifications = async (
  //   notification: Notification,
  //   notifiableType: NotificationType,
  //   notifiableId: string
  // ) => {
  //   try {
  //     const API_URL = generatePath(SHARED_NOTIFICATION_MANAGEMENT, {
  //       notifiableType,
  //       notifiableId,
  //     });

  //     const notificationJSON = {
  //       notification: serialize(notification),
  //     };

  //     const { data } = await axiosInstance.put(API_URL, notificationJSON);

  //     const createdNotification = deserialize(Notification, data?.notification);

  //     return createdNotification;
  //   } catch (error) {
  //     console.log(error?.message);
  //   }
  // };

  const getOtherNotifications = async () => {
    try {
      const { data } = await axiosInstance.get(
        updateWithUserType(LIST_OTHER_NOTIFICATIONS)
      );
      const otherNotifications = deserialize(
        OtherNotification,
        data?.notifications as any[]
      );
      setOtherNotifications(otherNotifications);
    } catch (error) {
      console.log(error?.message);
    }
  };

  const getAgreementNotifications = async () => {
    try {
      const { data } = await axiosInstance.get(
        updateWithUserType(LIST_AGREEMENT_NOTIFICATIONS)
      );
      const agreementNotifications = deserialize(
        AgreementNotification,
        data?.action_items as any[]
      );
      setAgreementNotifications(agreementNotifications);
    } catch (error) {
      console.log(error?.message);
    }
  };

  const getAncillaryNotifications = async () => {
    try {
      const { data } = await axiosInstance.get(
        updateWithUserType(LIST_ANCILLARY_NOTIFICATIONS)
      );
      const ancillaryNotifications = deserialize(
        AncillaryNotification,
        data?.action_items as any[]
      );
      setAncillaryNotifications(ancillaryNotifications);
    } catch (error) {
      console.log(error?.message);
    }
  };

  const getOnboardingNotifications = async () => {
    try {
      const { data } = await axiosInstance.get(
        updateWithUserType(LIST_ONBOARDING_NOTIFICATIONS)
      );
      const onboardingNotifications = deserialize(
        OnboardingNotification,
        data?.action_items as any[]
      );
      setOnboardingNotifications(onboardingNotifications);
    } catch (error) {
      console.log(error?.message);
    }
  };

  const clearNotification = async (id?: number) => {
    try {
      setLoading(true);
      let notificationPayload = {};
      if (id) {
        notificationPayload["notification_ids"] = [id];
      }
      await axiosInstance.put(DELETE_NOTIFICATION, notificationPayload);
      if (id) {
        const notificationIndex = otherNotifications?.findIndex(
          (notification) => notification?.id === id
        );
        const updatedNotification = [...otherNotifications];
        updatedNotification.splice(notificationIndex, 1);
        setOtherNotifications(updatedNotification);
      } else {
        setOtherNotifications([]);
      }
    } catch (error) {
      return false;
    } finally {
      setLoading(false);
    }
  };


  const updateNotificationCompletion = async(
    params: { notificationType: string; notificationId: string },
    notification: NotificationModel,
  )  => {
    try {
      const { notificationType, notificationId } = params;
      const API_URL = generatePath(
        NOTIFICATIONS_UPDATE_COMPLETION.replace(
          ":actionableType",
          notificationType
        )
          .replace(":actionableId", notificationId)
          .replace(":notificationId", notification?.id?.toString())
      );
      await axiosInstance.put(API_URL);

      if(notification?.id) {
        switch(notificationType) {
          case NotificationType.AGREEMENT: 
            getAgreementNotifications();
            break;
          case NotificationType.ANCILLARY_DOCUMENT: 
            getAncillaryNotifications();
            break;
          case NotificationType.ONBOARDING: 
            getOnboardingNotifications();
            break;
        }
      }
    } catch (error) {
        console.log(error);
        
    }
  }



  const connected = () => {
    console.log("Connected");
};

const disconnected = () => {
    console.log("Disconnected");
};

const rejected = (data) => {
    console.log("Rejected", data);
};

  const received = (data) => {
    const notification  = deserialize(OtherNotification, data?.notification);
    setOtherNotifications(notifications => [...notifications, notification]);
  }

  const initNotificationConnection = (userId: number) => {
    const cable = createConsumer(ACTION_CABLE_URL + `?id=${userId}`);
    cable?.subscriptions?.create(
      {
        channel: "NotificationChannel",
        room: `notification_${userId}`,

      },
      {
        connected,
        disconnected,
        received,
        rejected
      }
    );
  }

  const markAsRead = async () => {
    try {
        await axiosInstance.put(NOTIFICATION_MARK_AS_VIEWED);
        setOtherNotifications(otherNotifications => otherNotifications?.map(notification => ({
          ...notification,
          isSeen: true,
        })))
    } catch (error) {
      
    }
  } 

  return {
    loading,
    notifications,
    getNotifications,
    otherNotifications,
    getOtherNotifications,
    agreementNotifications,
    getAgreementNotifications,
    ancillaryNotifications,
    getAncillaryNotifications,
    onboardingNotifications,
    getOnboardingNotifications,
    tableNotifications,
    clearNotification,
    updateNotificationCompletion,
    initNotificationConnection,
    markAsRead,
    updateNotifications,
    // getTableNotifications,
    // createTableNotifications,
    // updateTableNotifications,
  };
};

export default NotificationService;
