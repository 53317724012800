import * as ApiRoutes from "../../routes/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { AgreementTypes } from "../../models/Agreements/agreementTypes.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { generatePath } from "react-router";

export default class AgreementTypesService {
  static fetchAgreementTypes(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.HL_AGREEMENT_TYPES)
      .then((response) => {
        const data = deserialize(
          AgreementTypes,
          response.data["agreement_types"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in fetching agreement types!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createAgreementTypes(
    agreementType: AgreementTypes,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    //console.log(agreementType)
    const serializeData = serialize(AgreementTypes, agreementType);
    const payload = { agreement_type: { ...serializeData, name: agreementType?.name } };
    axiosInstance
      .post(ApiRoutes.HL_AGREEMENT_TYPES, payload)
      .then((response) => {
        const data = deserialize(
          AgreementTypes,
          response.data["agreement_type"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in creating agreement type!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateAgreementTypes(
    agreementType: AgreementTypes,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_SINGLE_AGREEMENT_TYPE, {
      agreementTypeId: agreementType.id,
    });
    const serializeData = serialize(AgreementTypes, agreementType);
    const payload = { agreement_type: serializeData };
    axiosInstance
      .put(API_URL, payload)
      .then((response) => {
        const data = deserialize(
          AgreementTypes,
          response.data["agreement_type"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in updating agreement type!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static replicateAgreementTypes(
    agreementTypeId: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_REPLICATE_AGREEMENT_TYPE, {
      agreementTypeId
    });
    axiosInstance
      .put(API_URL)
      .then((response) => {
        const data = deserialize(
          AgreementTypes,
          response.data["agreement_type"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in replicating agreement type!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static deleteAgreementTypes(
    agreementTypeId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_SINGLE_AGREEMENT_TYPE, {
      agreementTypeId,
    });
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in deleting agreement type!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
