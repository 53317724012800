import { useState } from "react";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { Company } from "../../models/Company/company.model";
import { DoxCoinAllocation } from "../../models/DoxCoinAllocation/doxCoinAllocation.model";
import { DoxCoinTransaction } from "../../models/DoxCoinTransaction/doxCoinTransaction.model";
import { CompanyModel } from "../../models/HFund/hfund.model";
import { Service } from "../../models/Service/service.model";
import {
  DOX_ALLOCATION,
  DOX_COIN_IN_CIRCULATION,
  DOX_CREATE_SERVICES,
  DOX_MECHANISM_UPDATE,
  DOX_POINTS_HISTORY,
  DOX_REDEEMED_SERVICES,
  REDEEM_DOX_COIN,
  TOTAL_DOX_COINS,
  UPDATE_DOX_COINS,
} from "../../routes/apiRoutes";
import { LocalStorage } from "../../shared/utils/localStorageHelpers";

const DoxCoinsService = () => {
  const [doxHistories, setDoxHistories] = useState<DoxCoinTransaction[]>([]);

  const [redeemedServices, setRedeemedServices] = useState<Service[]>([]);

  const [allocations, setAllocations] = useState<DoxCoinAllocation[]>([]);

  const [company, setCompany] = useState<Company>();

  const [companies, setCompanies] = useState<Company[]>([]);

  const [error, setError] = useState<Error>();

  const [loading, setLoading] = useState(false);

  const getUserType = () => {
    const user = LocalStorage.getItem("USER");
    return user?.role?.includes("hf") ? "hf" : "hl";
  };

  const getPointHistories = async (params: { company_id: string }) => {
    try {
      setLoading(true);
      const API_URL = DOX_POINTS_HISTORY?.replace(":userRole", getUserType());
      const { data } = await axiosInstance.get(API_URL, {
        params,
      });

      const doxHistories = deserialize(
        DoxCoinTransaction,
        data?.dox_coin_transactions as DoxCoinTransaction[]
      );
      setDoxHistories(doxHistories);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getRedeemedServices = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        DOX_REDEEMED_SERVICES?.replace(":userRole", getUserType())
      );
      const redeemedServices = deserialize(
        Service,
        data?.services as Service[]
      );
      setRedeemedServices(redeemedServices);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllocation = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        DOX_ALLOCATION?.replace(":userRole", getUserType())
      );
      const allocations = deserialize(
        DoxCoinAllocation,
        data?.dox_coin_allocation as DoxCoinAllocation[]
      );
      setAllocations(allocations);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getTotalDoxCoins = async (setDoxCoins?: (doxCoin: number) => void) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        TOTAL_DOX_COINS?.replace(":userRole", getUserType())
      );
      const company = deserialize(Company, data?.company);
      setDoxCoins && setDoxCoins(company?.doxCoins);
      setCompany(company);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateCompanyDoxCoin = async (company: Company) => {
    const API_URL =
      TOTAL_DOX_COINS?.replace(":userRole", getUserType()) + "/" + company?.id;
    const CompanyDoXCoinJSON = {
      company: serialize(company),
    };
    try {
      setLoading(true);
      const { data } = await axiosInstance.put(API_URL, CompanyDoXCoinJSON);
      const company = deserialize(Company, data?.company);
      const index = companies?.findIndex((c) => c.id === company?.id);
      const updatedCompanies = [...companies];
      updatedCompanies?.splice(index, 1, company);
      setCompanies(updatedCompanies);
      return company;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getRedeemDoXCoin = async (serviceId: string) => {
    try {
      setLoading(true);
      const API_URL = REDEEM_DOX_COIN?.replace(
        ":userRole",
        getUserType()
      ).replace(":id", serviceId);
      const { data } = await axiosInstance.get(API_URL);
      const company = deserialize(Company, data?.company);
      return company;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  REDEEM_DOX_COIN;

  const getDoxCoinCirculation = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        DOX_COIN_IN_CIRCULATION?.replace(":userRole", getUserType())
      );
      const company = deserialize(Company, data?.companies as Company[]);
      setCompanies(company);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const createRedeemedService = async (service: Service) => {
    setLoading(true);
    try {
      const redeemedServiceJSON = {
        service: serialize(service),
      };
      const { data } = await axiosInstance.post(
        DOX_CREATE_SERVICES?.replace(":userRole", getUserType()),
        redeemedServiceJSON
      );
      const redeemedServices = deserialize(Service, data?.service);
      return redeemedServices;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateRedeemedService = async (service: Service) => {
    setLoading(true);
    try {
      const redeemedServiceJSON = {
        service: serialize(service),
      };

      const API_URL = DOX_CREATE_SERVICES + "/" + service?.id;
      const { data } = await axiosInstance.put(
        API_URL?.replace(":userRole", getUserType()),
        redeemedServiceJSON
      );
      const redeemedServices = deserialize(Service, data?.service);
      return redeemedServices;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteRedeemedService = async (service: Service) => {
    setLoading(true);
    try {
      const API_URL = DOX_CREATE_SERVICES + "/" + service?.id;
      await axiosInstance.delete(API_URL?.replace(":userRole", getUserType()));
      return true;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateCoinsUpdateMechanism = async (
    doxCoinAllocation: DoxCoinAllocation
  ) => {
    setLoading(true);
    try {
      const doxCoinAllocationJSON = {
        dox_coin_allocation: serialize(doxCoinAllocation),
      };

      const API_URL = DOX_MECHANISM_UPDATE + "/" + doxCoinAllocation?.id;
      const { data } = await axiosInstance.put(
        API_URL?.replace(":userRole", getUserType()),
        doxCoinAllocationJSON
      );
      const updatedDoxCoinAllocation = deserialize(
        DoxCoinAllocation,
        data?.dox_coin_allocation
      );
      return updatedDoxCoinAllocation;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getHFCompany = async (companyId: string) => {
    try {
      setLoading(true);
      const API_URL = UPDATE_DOX_COINS + "/" + companyId;
      const { data } = await axiosInstance.get(API_URL);
      const company = deserialize(Company, data?.company);
      setLoading(false);
      return company;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const updateDoxCoinHL = async (
    companyId: string,
    type: "Add" | "Reduce",
    company: Company
  ) => {
    try {
      setLoading(true);
      const API_URL =
        UPDATE_DOX_COINS + "/" + companyId + "/" + type.toLocaleLowerCase();
      const companyJSON = {
        company: serialize(company),
      };
      const { data } = await axiosInstance.put(API_URL, companyJSON);
      const updatedCompany = deserialize(Company, data?.company);
      setLoading(false);
      return updatedCompany;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    doxHistories,
    redeemedServices,
    allocations,
    company,
    companies,
    loading,
    error,
    getPointHistories,
    getRedeemedServices,
    getAllocation,
    getTotalDoxCoins,
    getDoxCoinCirculation,
    createRedeemedService,
    setRedeemedServices,
    updateRedeemedService,
    updateCoinsUpdateMechanism,
    deleteRedeemedService,
    getRedeemDoXCoin,
    updateCompanyDoxCoin,
    updateDoxCoinHL,
    getHFCompany,
  };
};

export default DoxCoinsService;
