
import {serializable, alias, object, list, primitive} from 'serializr';

export class Notification { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('action', primitive()))
	action?: string;

	@serializable(alias('period', primitive()))
	period?: string;

	@serializable(alias('description', primitive()))
	description?: string;

	@serializable(alias('notify_at', primitive()))
	notifyAt?: string;

	@serializable(alias("due_time", primitive()))
	dueTime?: string;

}