import React, { FC } from "react";
import "./detailCard.scss";

interface DetailCardProps {
    title: string;
}

const DetailCard: FC<DetailCardProps> = (props) => {
    const { title, children } = props;
    return <div className="detail-card">
        <span className="top-patch">
            {title}
        </span>
        {children}
    </div>
}

export default DetailCard;