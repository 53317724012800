import { Button, Col, Divider, Dropdown, Menu, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import "./sidebar.scss";
import AppLogo from "../../../../assets/background-images/app-logo.png";
import CompanyPlaceholder from "../../../../assets/background-images/auth-bg.png";
import DashboardIcon from "../../../../assets/icons/dashboard.svg";
import HFundIcon from "../../../../assets/icons/hfund.svg";
import FundsIcon from "../../../../assets/icons/funds.svg";
import AgreementsIcon from "../../../../assets/icons/agreements.svg";
import ReportsIcon from "../../../../assets/icons/reports.svg";
import TicketsIcon from "../../../../assets/icons/tickets.svg";
import HLUsersIcon from "../../../../assets/icons/hl-users.svg";
import DoxIcon from "../../../../assets/icons/dox.svg";
import CpIcon from "../../../../assets/icons/cp.svg";
import DiscussionIcon from "../../../../assets/icons/discussion.svg";
import ConfigurationIcon from "../../../../assets/icons/configuration.svg";
import {
  generatePath,
  RouteComponentProps,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";
import SubMenu from "antd/lib/menu/SubMenu";
import * as appRoutes from "../../../../routes/appRoutes";
import AuthContainer from "../../../../store/containers/AuthContainer";
import { CaretDownOutlined } from "@ant-design/icons";
import LayoutContainer from "../../../../store/containers/layoutContainer";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import { LayoutReducerProps } from "../../../../store/reducers/layoutReducer";
import DoxCoinsService from "../../../../services/DoxCoinsService/DoxCoins.service";
import { Company } from "../../../../models/Company/company.model";
import HLFundForm from "../../../HL/Home/Funds/FundForm";
import HFFundForm from "../../../HF/Home/Funds/FundForm";
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";
import { Fund } from "../../../../models/Fund/fund.model";
import {
  FundsService,
  HLFundsService,
} from "../../../../services/FundService/Fund.service";
import {
  HFCounterPartiesService,
  HLCounterPartiesService,
} from "../../../../services/CounterPartiesService/CounterParties.service";
import { CounterParties } from "../../../../models/CounterParties/counterParties.model";
import AgreementTypesService from "../../../../services/KeyTermsService/AgreementTypes.service";
import { AgreementTypes } from "../../../../models/Agreements/agreementTypes.model";
import { MetaService } from "../../../../services/MetaService/meta.service";
import {
  Agreement,
  AgreementStatus,
} from "../../../../models/Agreements/agreement.model";
import { deserialize } from "serializr";
import HLAgreementForm from "../../../HL/Home/Agreements/AgreementForm";
import HFAgreementForm from "../../../HF/Home/Agreements/AgreementForm";
import { roles } from "../../../../shared/components/HOC/roles";
import ChangePasswordModal from "../../../Auth/ChangePasswordModal";

const subSidebar = [
  {
    title: "Dashboard",
    path: appRoutes.HFUND_DASHBOARD,
    disabled: false,
  },
  {
    title: "Funds",
    path: appRoutes.HFUND_FUNDS,
    disabled: false,
  },
  {
    title: "Agreements",
    path: appRoutes.HFUND_AGREEMENTS,
    disabled: false,
  },
  {
    title: "Manage Users",
    path: appRoutes.HFUND_MANAGE_USERS,
    disabled: false,
  },
  {
    title: "DoX Coins",
    path: appRoutes.HFUND_DOX_COINS,
    disabled: false,
  },
  {
    title: "Account Info",
    path: appRoutes.HFUND_ACCOUNT_INFO,
    disabled: false,
  },
];

interface HLSidebar
  extends AuthReducerProps,
  LayoutReducerProps,
  RouteComponentProps { }

const HLSidebar: FC<HLSidebar> = (props) => {
  const paths = useLocation()?.pathname?.split("/");

  const [fundVisibility, setFundVisibility] = useState(false);

  const [agreementVisibility, setAgreementVisibility] = useState(false);

  const [changePasswordVisibility, setChangePasswordVisibility] = useState(false);

  const [agreementStatus, setAgreementStatus] = useState<any[]>([]);

  const [counterParties, setCounterParties] = useState<any[]>([]);

  const [funds, setFunds] = useState<any[]>([]);

  const [agreementTypes, setAgreementTypes] = useState<any[]>([]);

  const hfView = paths?.length > 3 && paths?.includes("hfund");

  const [formVisibility, setFormVisibility] = useState(false);

  const [company, setCompany] = useState<Company>();

  const { getHFCompany } = DoxCoinsService();

  const user = LocalStorage.getItem("USER");

  const isAdmin = user?.role?.includes("hl");

  const fetchFundsList = () => {
    (isAdmin ? HLFundsService : FundsService).fetchFunds(
      {meta:true},
      (response: Fund[]) => {
        const foramattedData = response.map((fund) => {
          return { label: fund.name, value: fund.id };
        });
        setFunds(foramattedData);
      },
      (error) => { },
      () => { }
    );
  };

  const fetchCPList = () => {
    (isAdmin
      ? HLCounterPartiesService
      : HFCounterPartiesService
    ).fetchCounterParties(
      {meta:true},
      (response: CounterParties[]) => {
        const foramattedData = response.map((cp) => {
          return { label: cp.name, value: cp.id };
        });
        setCounterParties(foramattedData);
      },
      () => { },
      () => { }
    );
  };

  const fetchAgreementTypes = () => {
    AgreementTypesService.fetchAgreementTypes(
      (response: AgreementTypes[]) => {
        const foramattedData = response.map((agreement) => {
          return { label: agreement.name, value: agreement.id };
        });
        setAgreementTypes(foramattedData);
      },
      () => { },
      () => { }
    );
  };

  const fetchAgreementStatus = () => {
    MetaService.agreementStatus(
      (response) => {
        const data = deserialize(
          AgreementStatus,
          response.data["agreement_statuses"]
        ) as AgreementStatus[];
        const formattedData = data.map((sts) => {
          return {
            label: sts.name.replaceAll("_", " "),
            value: sts.id,
            color: sts.color,
          };
        });
        setAgreementStatus(formattedData);
      },
      (error) => {
        //console.log(error);
      }
    );
  };

  useEffect(() => {
    fetchFundsList();
    fetchCPList();
    fetchAgreementTypes();
    fetchAgreementStatus();
  }, []);

  const handleLogout = () => {
    // AuthService.logoutUser(
    //   () => {
    props.setUnAuthenticated();
    localStorage.clear();
    props.history.push(appRoutes.LOGIN);
    //   },
    //   () => {},
    //   () => {}
    // ).then();
  };

  const updateCompany = async () => {
    const company = await getHFCompany(paths[2]);
    if (company) {
      props?.setDoxCoin(company?.doxCoins);
      setCompany(company);
    }
  };

  useEffect(() => {
    if (hfView) {
      updateCompany();
    }
  }, [paths[2]]);

  const AddContent = (
    <div>
      <p
        onClick={() => {
          setFundVisibility(true);
        }}
      >
        New Fund
      </p>
      <div className="divider" />
      <p
        onClick={() => {
          setAgreementVisibility(true);
        }}
      >
        New Agreement
      </p>
    </div>
  );

  return (
    <Col className={`sidebar ${hfView && "sidebar-shrink"}`}>
      <Menu
        className={`sidebar hl-sidebar`}
        defaultSelectedKeys={["1"]}
        mode="inline"
        triggerSubMenuAction="click"
        theme="dark"
        inlineCollapsed={hfView}
      >
        <div className="sidebar-app__logo">
          <img src={AppLogo} />
        </div>

        <div className="add-new-sidebar">
          <Popover content={AddContent} placement="right">
            <Button type="primary">
              <i className="icon-add" /> {hfView ? "" : "New"}
            </Button>
          </Popover>
        </div>

        <Menu.Item
          key="1"
          icon={<img src={DashboardIcon} />}
          onClick={() => props.history.push(appRoutes.DASHBOARD)}
        >
          Dashboard
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={<img src={HFundIcon} />}
          onClick={() => props.history.push(appRoutes.HFUND)}
        >
          HFund
        </Menu.Item>
        <Menu.Item
          key="3"
          icon={<img src={FundsIcon} />}
          onClick={() => props.history.push(appRoutes.FUNDS)}
        >
          Funds
        </Menu.Item>
        <Menu.Item
          key="4"
          icon={<img src={AgreementsIcon} />}
          onClick={() => props.history.push(appRoutes.AGREEMENTS)}
        >
          Agreements
        </Menu.Item>
        <Menu.Item
          key="5"
          icon={<img src={ReportsIcon} />}
          onClick={() => props.history.push(appRoutes.REPORTS)}
        >
          Reports
        </Menu.Item>
        <Divider />
        <Menu.Item
          key="6"
          icon={<img src={TicketsIcon} />}
          onClick={() => {
            props.history.push(appRoutes.TICKETS);
          }}
        >
          Tickets
        </Menu.Item>
        <Menu.Item
          key="7"
          icon={<img src={HLUsersIcon} />}
          onClick={() => props.history.push(appRoutes.HL_USERS)}
        >
          HL Users
        </Menu.Item>
        {/* <Menu.Item
          key="8"
          icon={<img src={DoxIcon} />}
          onClick={() => {
            props.history.push(appRoutes.DOX);
          }}
        >
          DoX Coins
        </Menu.Item> */}
        <Divider />
        <Menu.Item
          key="9"
          icon={<img src={CpIcon} />}
          onClick={() => props.history.push(appRoutes.CP)}
        >
          CPs / Brokers
        </Menu.Item>
        {/* <Menu.Item
          key="11"
          icon={<img src={DiscussionIcon} />}
          onClick={() => props.history.push(appRoutes.DISCUSSION_BOARD)}
        >
          Discussion Board
        </Menu.Item> */}
        <Divider />
        {[roles.HL_SUPERADMIN, roles.HL_ADMIN].includes(user?.role) && <SubMenu
          key="sub1"
          icon={<img src={ConfigurationIcon} />}
          title={hfView ? "" : "Configuration"}
        >
          <Menu.Item key="12" onClick={() => props.history.push(appRoutes.KT)}>
            Key Terms List
          </Menu.Item>
          <Menu.Item
            key="13"
            onClick={() => props.history.push(appRoutes.ONBOARDING_CONFIG)}
          >
            Onboarding Config
          </Menu.Item>
          <Menu.Item
            key="14"
            onClick={() => props.history.push(appRoutes.HL_SUBSCRIPTION)}
          >
            Subscription
          </Menu.Item>
          <Menu.Item
            key="15"
            onClick={() =>
              props.history.push(appRoutes.NOTIFICATION_CONFIGURATION)
            }
          >
            Notification Config
          </Menu.Item>
          <Menu.Item
            key="16"
            onClick={() => {
              props.history.push(appRoutes.HELP);
            }}
          >
            Help Config
          </Menu.Item>
          {/* <Menu.Item
            key="17"
            onClick={() => {
              props.history.push(appRoutes.PARTNERS);
            }}
          >
            Partners
          </Menu.Item> */}
        </SubMenu>}
        {!hfView && (
          <Dropdown
            className="hl-sidebar__account"
            trigger={["click"]}
            overlay={
              <Menu className="hl-sidebar-account__menu">
                <Menu.Item onClick={() => props.history.push(appRoutes.ACCOUNT_DETAILS)}>
                  <p>Account details</p>
                </Menu.Item>
                <Menu.Item onClick={() => setChangePasswordVisibility(true)}>
                  <p>Change password</p>
                </Menu.Item>
                <Menu.Item>
                  <p onClick={() => handleLogout()}>Logout</p>
                </Menu.Item>
              </Menu>
            }
          >
            <p>
              {props?.user?.firstName} {props?.user?.lastName}&nbsp;&nbsp;
              <CaretDownOutlined />
            </p>
          </Dropdown>
        )}
      </Menu>
      <ChangePasswordModal
        visible={changePasswordVisibility}
        closeHandler={() => setChangePasswordVisibility(false)} />
      <Menu
        className={`sidebar hf-sidebar-view ${hfView ? "display-show" : "display-none"
          }`}
        defaultSelectedKeys={["2"]}
        mode="inline"
        triggerSubMenuAction="click"
        theme="dark"
      >
        <div className="sidebar-app__logo__mini">
          <img src={company?.logoUrl ?? CompanyPlaceholder} />
        </div>
        <li className="sidebar-company-name">{company?.name}</li>
        {subSidebar?.map(({ title, path, disabled }, index) => (
          <Menu.Item
            disabled={disabled}
            key={(index + 1)?.toString()}
            onClick={() => props.history.push(path?.replace(":id", paths[2]))}
          >
            {title}
          </Menu.Item>
        ))}
      </Menu>
      <HLFundForm
        title={"Add new fund"}
        visible={fundVisibility}
        disableTerminatedStatus
        closeHandler={(newFund?: Fund) => {
          setFundVisibility(false);
        }}
      />
      <HLAgreementForm
        visible={agreementVisibility}
        funds={funds}
        counterParties={counterParties}
        agreementStatus={agreementStatus}
        agreementTypes={agreementTypes}
        closeHandler={(agreement?: Agreement) => {
          setAgreementVisibility(false);
        }}
      />
    </Col>
  );
};
export default withRouter(AuthContainer(LayoutContainer(HLSidebar)));
