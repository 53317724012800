import { serializable, alias, object, list, primitive } from "serializr";
import { CounterParties } from "./counterParties.model";

export class CounterPartiesGroups {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("logo_url", primitive()))
  logoUrl?: string;

  @serializable(alias("counter_parties", list(object(CounterParties))))
  counterParties?: CounterParties[] = [];
}
export class CounterPartiesGroupsMeta {
  @serializable(alias("id", primitive()))
  value?: number;

  @serializable(alias("name", primitive()))
  label?: string;
} 