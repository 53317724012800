import { Button, Col, Row } from "antd";
import React, { FC } from "react";
import { AuditTrail } from "../../../../../../models/AuditTrail/auditTrail.model";
import "./auditTrialView.scss";

interface AuditTrialViewProps {
  auditTrail: AuditTrail;
  closeHandler: (id?: number) => void;
}

const AuditTrialView: FC<AuditTrialViewProps> = (props) => {
  const { auditTrail, closeHandler } = props;

  if (!auditTrail) {
    return null;
  }

  return (
    <div className="audit-trial-view">
      {Object.entries(auditTrail?.changeset)?.map(([key, value]) => (
        <Row className="mb-3">
          <Col className="text-grey" span={8}>
            {key}{" "}
          </Col>
          <Col>{`Change from ${value[0]} to ${value[1]}`}</Col>
        </Row>
      ))}
      <Row justify="end">
        <Col>
          <Button type="primary" onClick={() => closeHandler(auditTrail?.id)}>
            Revert Action
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AuditTrialView;
