import { Col, Divider, Input, Row, Modal } from "antd";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { Comment } from "../../../../../models/Comment/comment.model";
import { Reply as ReplyModel } from "../../../../../models/Reply/reply.model";
import DiscussionBoardService from "../../../../../services/DiscussionBoardService/DiscussionBoard.service";
import "./reply.scss";

interface ReplyProps {
  reply: ReplyModel;
  reload: () => void;
  onShowCommentList?: () => void;
}

const Reply: FC<ReplyProps> = (props) => {
  const { reply, reload } = props;

  const { deleteReply } = DiscussionBoardService();

  return (
    <div className="reply">
      <Row justify="space-between" className="company-details" gutter={[0, 20]}>
        <Col>
          <Row>
            <Col className="mr-10">
              <p className="company-name">{reply?.companyName}</p>
              <div className="date">
                {moment(reply?.date).format("DD MMM, YYYY")}
              </div>
            </Col>
            <Col className="dot-container mr-10">
              <div className="dot" />
            </Col>
            <Col className="name">{reply?.userName}</Col>
          </Row>
        </Col>
        <Col>
          <i
            className="icon-delete"
            onClick={(e) => {
              e.stopPropagation();
              Modal.warning({
                title: "Are you sure you want to delete your reply?",
                closable: true,
                closeIcon: <i className="icon-close" />,
                okText: "Delete",
                onOk: async (closeModal) => {
                  if (await deleteReply(reply?.id)) {
                    reload();
                    closeModal();
                  }
                },
              });
            }}
          />
        </Col>
      </Row>
      <p className="description">{reply?.description}</p>
    </div>
  );
};

export default Reply;
