import { Col, Divider, Input, Row } from "antd";
import moment from "moment";
import React, { FC, Fragment, useEffect, useState } from "react";
import { Comment } from "../../../../../models/Comment/comment.model";
import DiscussionBoardService from "../../../../../services/DiscussionBoardService/DiscussionBoard.service";
import Reply from "../Reply";
import { Reply as ReplyModel } from "../../../../../models/Reply/reply.model";
import "./discussionComment.scss";
import { Topic } from "../../../../../models/Topic/topic.model";
import { Modal } from "antd";
import AuthContainer from "../../../../../store/containers/AuthContainer";
import { AuthReducerProps } from "../../../../../store/reducers/authReducer";

interface DiscussionCommentProps extends AuthReducerProps {
  comment?: Comment;
  disableDivider?: boolean;
  onShowCommentList?: () => void;
  reload?: () => void;
  topic?: Topic;
  searchResult?: boolean;
  noUpdate?: boolean;
}

const DiscussionComment: FC<DiscussionCommentProps> = (props) => {
  const {
    topic,
    user,
    comment,
    disableDivider,
    onShowCommentList,
    reload,
    searchResult,
    noUpdate,
  } = props;

  const [showAllReplies, setShowAllReplies] = useState(false);

  const [reply, setReply] = useState("");

  const { replies, getReply, postReply, rateComment, loading, deleteReply } =
    DiscussionBoardService();

  useEffect(() => {
    getReply(comment?.id);
  }, []);

  const handleCommentClick = () => {
    if (onShowCommentList) {
      return onShowCommentList();
    }
    setShowAllReplies(true);
  };

  const handleAddComment = async () => {
    const data = Object.assign(new ReplyModel(), {
      description: reply,
    });
    if (await postReply(comment?.id, data)) {
      setReply("");
      getReply(comment?.id);
    }
  };

  const handleUpdate = async (type: "like" | "dislike") => {
    if (await rateComment(comment?.id, type)) {
      reload && reload();
    }
  };

  const closeReply = () => setShowAllReplies(false);

  if (!comment) return null;

  return (
    <div onClick={e => e?.stopPropagation()} className="discussion-comment">
      <Row className="company-details" justify="space-between" gutter={[0, 20]}>
        <Col>
          <Row>
            <Col className="mr-10">
              <p className="company-name">{comment?.companyName}</p>
              <div className="date">
                {moment(comment?.date).format("DD MMM, YYYY")}
              </div>
            </Col>
            <Col className="dot-container mr-10">
              <div className="dot" />
            </Col>
            <Col className="name">{comment?.userName}</Col>
          </Row>
        </Col>
        <Col>
          {disableDivider && comment.createdBy === user.id && (
            <i
              className="icon-delete"
              onClick={(e) => {
                e.stopPropagation();
                Modal.warning({
                  title: "Are you sure you want to delete your comment?",
                  closable: true,
                  closeIcon: <i className="icon-close" />,
                  okText: "Delete",
                  onOk: async (closeModal) => {
                    if (await deleteReply(comment?.id)) {
                      reload();
                      closeModal();
                    }
                  },
                });
              }}
            />
          )}
        </Col>
      </Row>
      <p className="description">{comment?.description}</p>
      {!searchResult && (
        <Fragment>
          {!disableDivider && <Divider />}
          {!noUpdate && (
            <Row
              justify="space-between"
              className={disableDivider ? "mt-2" : ""}
            >
              <Col span={12} className="control">
                <i
                  className={`icon-like ${comment?.youLike && "icon-selected"}`}
                  onClick={() => {
                    // if(comment?.youLike)  return;

                    handleUpdate("like")
                  }}
                >
                  {comment?.likes}
                </i>
                <i
                  className={`icon-dislike ${comment?.dislikes && "icon-selected"
                    }`}
                  onClick={() => {
                    // if(comment?.youDislike) return;

                    handleUpdate("dislike")
                  }}
                >
                  {comment?.dislikes}
                </i>
              </Col>
              <Col span={12} className="comments" onClick={handleCommentClick}>
                {disableDivider
                  ? `${comment?.noOfReplies} Replies`
                  : `${topic.noOfComments} Comments`}
              </Col>
            </Row>
          )}
        </Fragment>
      )}

      {showAllReplies && (
        <div className="list-replies mt-5">
          <Row justify="space-between">
            <Col>{/* <h4>Reply</h4>r */}</Col>
            <Col className="hide" onClick={closeReply}>
              Hide
            </Col>
          </Row>
          {replies?.map((reply) => (
            <Reply
              reply={reply}
              key={reply?.id}
              reload={() => {
                getReply(comment?.id);
              }}
            />
          ))}
          <Input
            placeholder="Post your reply"
            value={reply}
            onChange={(e) => setReply(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleAddComment();
              }
            }}
            suffix={
              <Fragment>
                <span
                  className={`posting-loader ${loading && "posting-loading"}`}
                >
                  Posting
                </span>
                &nbsp; <i className="icon-Send" onClick={handleAddComment} />
              </Fragment>
            }
            className="mt-5"
          />
        </div>
      )}
    </div>
  );
};

export default AuthContainer(DiscussionComment);
