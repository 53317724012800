import React, { FC, useEffect, useState } from "react";
import { Col, Row } from "antd";
import Form from "antd/lib/form/Form";
import { Field, Formik } from "formik";
import DropdownField from "../../../shared/components/DropdownField";
import InputField from "../../../shared/components/InputField";
import { AttachmentComponent } from "../../../shared/components/AttachmentComponent";
import "./companyDetails.scss";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { HOME, SUBSCRIPTION } from "../../../routes/appRoutes";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  generatePath,
  Link,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { CompanyService } from "../../../services/CompanyService/company.service";
import { MetaService } from "../../../services/MetaService/meta.service";
import { assets, strategies } from "./companyDefinitions";
import { CompanyValidation } from "./companyValidation";
import { CompanyModel } from "../../../models/HFund/hfund.model";
import AuthContainer from "../../../store/containers/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { LocalStorage } from "../../../shared/utils/localStorageHelpers";
import * as appRoutes from "./../../../routes/appRoutes"

interface FormProps {
  companyLogo: any;
  logoUrl: any;
  companyName: string;
  description: string;
  assetUnderManagement: string;
  strategy: string;
  additionalStrategies: string[];
  firstName: string;
  lastName: string;
  companyRoleName: string;
  email: string;
  mobileNumber: string;
  status: string;
  street: string;
  address2: string;
  city: number;
  state: string;
  zipCode: string;
  country: string;
  mailingAddress: boolean;
  billingAddress: boolean;
  isAgreed: boolean;
  mailingStreet: string;
  mailingAddress2: string;
  mailingCity: number;
  mailingState: string;
  mailingZipCode: string;
  mailingCountry: string;
  billingStreet: string;
  billingAddress2: string;
  billingCity: number;
  billingState: string;
  billingZipCode: string;
  billingCountry: string;
  contactPersonName: string;
  contactPersonTitle: string;
  contactPersonPhone: string;
  contactPersonEmail: string;
  password: string;
  confirmPassword: string;
}

interface CompanyDetailProps extends AuthReducerProps {
  companyId?: number;
  company?: CompanyModel;
  closeHandler?: (company: CompanyModel) => void;
}
const CompanyDetails: FC<CompanyDetailProps> = (props) => {
  const { companyId, company, closeHandler } = props;
  const formRef = React.useRef<any>();
  const history = useHistory();

  const user = LocalStorage.getItem("USER")
  const { state }: { state: any } = useLocation();
  const [loading, setLoading] = useState<boolean>(false)
  const [initialValues, setInitialValues] = React.useState<FormProps>({
    companyLogo: "",
    logoUrl: "",
    companyName: state?.companyName,
    description: "",
    assetUnderManagement: undefined,
    strategy: undefined,
    isAgreed: null,
    additionalStrategies: [""],
    firstName: "",
    lastName: "",
    companyRoleName: "",
    email: "",
    mobileNumber: "",
    street: "",
    status: "",
    address2: "",
    city: undefined,
    state: undefined,
    zipCode: "",
    country: undefined,
    mailingAddress: true,
    billingAddress: true,
    mailingStreet: null,
    mailingAddress2: null,
    mailingCity: undefined,
    mailingState: undefined,
    mailingZipCode: null,
    mailingCountry: undefined,
    billingStreet: null,
    billingAddress2: null,
    billingCity: undefined,
    billingState: undefined,
    billingZipCode: null,
    billingCountry: undefined,
    contactPersonName: "",
    contactPersonTitle: "",
    contactPersonPhone: "",
    contactPersonEmail: "",
    password: "",
    confirmPassword: "",
  });
  const [countries, setCountries] = useState<any>();
  const [provinces, setProvinces] = useState<any>();
  const [cities, setCities] = useState<any>();

  const params = useParams<{ companyId: string }>();

  const handleSubmit = (values: any) => {
    values["addresses"] = [];
    values["addresses"]?.push({
      addressType: "registered",
      street: values?.street,
      addressLine2: values?.address2,
      city: values?.city,
      zipCode: values?.zipCode,
    });
    // if (values.mailingAddress) {
    //   values["addresses"]?.push({
    //     addressType: "mailing",
    //     street: values?.street,
    //     addressLine2: values?.address2,
    //     city: values?.city,
    //     zipCode: values?.zipCode,
    //   });
    // } else {
    //   values["addresses"]?.push({
    //     addressType: "mailing",
    //     street: values?.mailingStreet,
    //     addressLine2: values?.mailingAddress2,
    //     city: values?.mailingCity,
    //     zipCode: values?.mailingZipCode,
    //   });
    // }
    // if (values.billingAddress) {
    //   values["addresses"]?.push({
    //     addressType: "billing",
    //     street: values?.street,
    //     addressLine2: values?.address2,
    //     city: values?.city,
    //     zipCode: values?.zipCode,
    //   });
    // } else {
    //   values["addresses"]?.push({
    //     addressType: "billing",
    //     street: values?.billingStreet,
    //     addressLine2: values?.billingAddress2,
    //     city: values?.billingCity,
    //     zipCode: values?.billingZipCode,
    //   });
    // }
    values["admin"] = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      companyRoleName: values?.companyRoleName,
      email: values?.email,
      mobileNumber: values?.mobileNumber,
      // password: values?.password,
    };
    //console.log(values);
    const companyDetails = Object.assign(new CompanyModel(), { ...values, status: values.status });
    setLoading(true)
    CompanyService.updateHFCompany(
      params?.companyId ?? companyId,
      user?.role,
      companyDetails,
      (company) => {
        if (closeHandler) {
          return closeHandler(company);
        }
        history.push(
          generatePath(SUBSCRIPTION, {
            ...params,
          }),
          company
        );
      },
      () => {
        setLoading(false)
      }
    );
  };
  const fetchCountries = () => {
    MetaService.listCountries(
      (countries) => {
        setCountries(countries);
      },
      () => { }
    );
  };
  const fetchProvinces = (countryId: any) => {
    MetaService.listProvinces(
      countryId,
      (provinces) => {
        setProvinces(provinces);
      },
      () => { }
    );
  };
  const fetchCities = (countryId: any, provinceId: any) => {
    MetaService.listCities(
      countryId,
      provinceId,
      (cities) => {
        setCities(cities);
      },
      () => { }
    );
  };
  const fetchCompany = () => {
    CompanyService.viewCompany(
      params?.companyId ?? companyId,
      user?.role as any,
      (data) => {
        // if (data?.assetUnderManagement || company) {
        let mailingAddress = false;
        let billingAddress = false;
        if (data?.addresses?.length) {
          fetchProvinces(data?.addresses[0]?.country);
          fetchCities(data?.addresses[0]?.country, data?.addresses[0]?.state);
          if (
            data?.addresses[0]?.street == data?.addresses[1]?.street &&
            data?.addresses[0]?.addressLine2 ==
            data?.addresses[1]?.addressLine2 &&
            data?.addresses[0]?.country == data?.addresses[1]?.country &&
            data?.addresses[0]?.state == data?.addresses[1]?.state &&
            data?.addresses[0]?.city == data?.addresses[1]?.city &&
            data?.addresses[0]?.zipCode == data?.addresses[1]?.zipCode
          ) {
            mailingAddress = true;
          }
          if (
            data?.addresses[0]?.street == data?.addresses[2]?.street &&
            data?.addresses[0]?.addressLine2 ==
            data?.addresses[2]?.addressLine2 &&
            data?.addresses[0]?.country == data?.addresses[2]?.country &&
            data?.addresses[0]?.state == data?.addresses[2]?.state &&
            data?.addresses[0]?.city == data?.addresses[2]?.city &&
            data?.addresses[0]?.zipCode == data?.addresses[2]?.zipCode
          ) {
            billingAddress = true;
          }
        }
        setInitialValues({
          companyLogo: data?.companyLogo,
          logoUrl: data?.logoUrl,
          status: data?.status,
          companyName: data?.companyName,
          description: data?.description,
          assetUnderManagement: data?.assetUnderManagement,
          strategy: data?.strategy,
          additionalStrategies: data?.additionalStrategies,
          firstName: data?.admin?.firstName,
          lastName: data?.admin?.lastName,
          companyRoleName: data?.admin?.companyRoleName,
          email: data?.admin?.email,
          mobileNumber: data?.admin?.mobileNumber,
          street: data?.addresses[0]?.street,
          address2: data?.addresses[0]?.addressLine2,
          city: data?.addresses[0]?.city,
          state: data?.addresses[0]?.state,
          zipCode: data?.addresses[0]?.zipCode,
          country: data?.addresses[0]?.country,
          mailingAddress: mailingAddress,
          billingAddress: billingAddress,
          isAgreed: null,
          mailingStreet: !mailingAddress ? data?.addresses[1]?.street : null,
          mailingAddress2: !mailingAddress
            ? data?.addresses[1]?.addressLine2
            : null,
          mailingCity: !mailingAddress ? data?.addresses[1]?.city : null,
          mailingState: !mailingAddress ? data?.addresses[1]?.state : null,
          mailingZipCode: !mailingAddress
            ? data?.addresses[1]?.zipCode
            : null,
          mailingCountry: !mailingAddress
            ? data?.addresses[1]?.country
            : null,

          billingStreet: !billingAddress ? data?.addresses[2]?.street : null,
          billingAddress2: !billingAddress
            ? data?.addresses[2]?.addressLine2
            : null,
          billingCity: !billingAddress ? data?.addresses[2]?.city : undefined,
          billingState: !billingAddress
            ? data?.addresses[2]?.state
            : undefined,
          billingZipCode: !billingAddress
            ? data?.addresses[2]?.zipCode
            : null,
          billingCountry: !billingAddress
            ? data?.addresses[2]?.country
            : undefined,

          contactPersonName: data?.contactPersonName,
          contactPersonTitle: data?.contactPersonTitle,
          contactPersonPhone: data?.contactPersonPhone,
          contactPersonEmail: data?.contactPersonEmail,
          password: data?.password,
          confirmPassword: data?.password,
        });
        // }
      },
      () => { }
    );
  };

  useEffect(() => {
    fetchCountries();
    fetchCompany();
  }, []);

  return (
    <div className="company-details__container">
      {!companyId && <h1>Complete your profile details</h1>}
      <Formik
        initialValues={initialValues}
        onSubmit={() => { }}
        enableReinitialize
        innerRef={formRef}
        validationSchema={CompanyValidation}
      >
        {({ setFieldValue, values, resetForm, isValid }) => {
          return (
            <Form>
              <div className="company-form__container">
                <div className="company-form__field">
                  <div className="company-form__label">Company Information</div>
                  <Field name="companyLogo" as="file-upload">
                    <AttachmentComponent
                      accept={"image/*"}
                      image={values?.companyLogo || values?.logoUrl}
                      onUpload={(file) => setFieldValue("companyLogo", file)}
                      title="Upload Company Logo"
                    />
                  </Field>
                </div>
                <div className="company-form__field">
                  <InputField
                    showLabel
                    placeholder={"Management Company Name *"}
                    name="companyName"
                    value={values?.companyName}
                    type="text"
                  />
                </div>
                <div className="company-form__field">
                  <InputField
                    showLabel
                    placeholder={"Description"}
                    name="description"
                    value={values?.description}
                    type="textarea"
                  />
                </div>
                <DropdownField
                  mode="single"
                  placeholder={"Assets Under Management (USD or equivalent) *"}
                  name="assetUnderManagement"
                  options={assets}
                  showSearch={false}
                  value={values.assetUnderManagement}
                  onChange={(value) =>
                    setFieldValue("assetUnderManagement", value)
                  }
                />

                <div className="company-form__field">
                  <DropdownField
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"Strategy *"}
                    name="strategy"
                    options={strategies}
                    showSearch={false}
                    value={values.strategy}
                    onChange={(value) => setFieldValue("strategy", value)}
                  />
                </div>
                <div className="company-form__field">
                  <InputField
                    showLabel
                    placeholder={"Additional Strategies (Optional)"}
                    name="additionalStrategies"
                    value={values?.additionalStrategies.join(" ")?.replaceAll("_", " ")}
                    type="text"
                    onChange={e => setFieldValue("additionalStrategies", [e.target.value])}
                  />
                </div>
                {/* <div className="company-form__field">
                  <DropdownField
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"Fund Structure"}
                    name="fundStructure"
                    options={[]}
                    showSearch={false}
                    value={values.fundStructure}
                    onChange={(value) => setFieldValue("fundStructure", value)}
                  />
                </div> */}
                <div className="company-form__label">Admin Information</div>

                <Row justify="space-between" className="mtp-4">
                  <Col span={11}>
                    <InputField
                      showLabel
                      placeholder={"First Name"}
                      name="firstName"
                      value={values?.firstName}
                      type="text"
                    />
                  </Col>
                  <Col span={11}>
                    <InputField
                      showLabel
                      placeholder={"Last Name"}
                      name="lastName"
                      value={values?.lastName}
                      type="text"
                    />
                  </Col>
                </Row>

                <div className="company-form__field">
                  <InputField
                    showLabel
                    placeholder={"companyRoleName at Company *"}
                    name="companyRoleName"
                    value={values?.companyRoleName}
                    type="text"
                  />
                </div>
                <div className="company-form__field">
                  <InputField
                    showLabel
                    placeholder={"Email *"}
                    name="email"
                    value={values?.email}
                    type="text"
                  />
                </div>
                <div className="company-form__field">
                  <InputField
                    showLabel
                    placeholder={"Phone Number *"}
                    name="mobileNumber"
                    value={values?.mobileNumber}
                    type="text"
                  />
                </div>
                <div className="company-form__label">Registered Address</div>
                <div className="company-form__field">
                  <InputField
                    showLabel
                    placeholder={"Street *"}
                    name="street"
                    value={values?.street}
                    type="text"
                  />
                </div>
                <div className="company-form__field">
                  <InputField
                    showLabel
                    placeholder={"Address 2"}
                    name="address2"
                    value={values?.address2}
                    type="text"
                  />
                </div>
                <div className="company-form__field">
                  <DropdownField
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"Country *"}
                    name="country"
                    options={countries}
                    showSearch={false}
                    value={values.country}
                    onChange={(value) => {
                      setFieldValue("country", value);
                      fetchProvinces(value);
                    }}
                  />
                </div>
                <div className="company-form__field">
                  <DropdownField
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"State/Province *"}
                    name="state"
                    options={provinces}
                    showSearch={false}
                    value={values.state}
                    onChange={(value) => {
                      setFieldValue("state", value);
                      fetchCities(values?.country, value);
                    }}
                  />
                  <div className="company-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"City *"}
                      name="city"
                      options={cities}
                      showSearch={false}
                      value={values.city}
                      onChange={(value) => {
                        setFieldValue("city", value);
                      }}
                    />
                  </div>
                </div>
                <div className="company-form__field">
                  <InputField
                    showLabel
                    placeholder={"Zip Code/Postal Code *"}
                    name="zipCode"
                    value={values?.zipCode}
                    type="text"
                  />
                </div>

                {/* <div className="company-form__label">Mailing Address</div>
                <div className="company-form__field">
                  <Checkbox
                    className="mtp-3"
                    checked={values?.mailingAddress}
                    onChange={(e) => {
                      setFieldValue("mailingAddress", e.target.checked);
                    }}
                  >
                    Same as registered address
                  </Checkbox>
                </div>
                {!values?.mailingAddress && (
                  <>
                    <div className="company-form__field">
                      <InputField
                        showLabel
                        placeholder={"Street *"}
                        name="mailingStreet"
                        value={values?.mailingStreet}
                        type="text"
                      />
                    </div>
                    <div className="company-form__field">
                      <InputField
                        showLabel
                        placeholder={"Address 2"}
                        name="mailingAddress2"
                        value={values?.mailingAddress2}
                        type="text"
                      />
                    </div>
                    <div className="company-form__field">
                      <DropdownField
                        mode="single"
                        optionFilterProp="label"
                        placeholder={"Country *"}
                        name="mailingCountry"
                        options={countries}
                        showSearch={false}
                        value={values.mailingCountry}
                        onChange={(value) => {
                          setFieldValue("mailingCountry", value);
                          fetchProvinces(value);
                        }}
                      />
                    </div>
                    <div className="company-form__field">
                      <DropdownField
                        mode="single"
                        optionFilterProp="label"
                        placeholder={"State/Province *"}
                        name="mailingState"
                        options={provinces}
                        showSearch={false}
                        value={values.mailingState}
                        onChange={(value) => {
                          setFieldValue("mailingState", value);
                          fetchCities(values?.country, value);
                        }}
                      />
                      <div className="company-form__field">
                        <DropdownField
                          mode="single"
                          optionFilterProp="label"
                          placeholder={"City *"}
                          name="mailingCity"
                          options={cities}
                          showSearch={false}
                          value={values.mailingCity}
                          onChange={(value) => {
                            setFieldValue("mailingCity", value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="company-form__field">
                      <InputField
                        showLabel
                        placeholder={"Zip Code/Postal Code *"}
                        name="mailingZipCode"
                        value={values?.mailingZipCode}
                        type="text"
                      />
                    </div>
                  </>
                )}
                <div className="company-form__label">Billing Address</div>
                <div className="company-form__field">
                  <Checkbox
                    checked={values?.billingAddress}
                    className="mtp-3"
                    onChange={(e) =>
                      setFieldValue("billingAddress", e.target.checked)
                    }
                  >
                    Same as registered address
                  </Checkbox>
                </div>
                {!values?.billingAddress && (
                  <>
                    <div className="company-form__label">Billing Address</div>
                    <div className="company-form__field">
                      <InputField
                        showLabel
                        placeholder={"Street *"}
                        name="billingStreet"
                        value={values?.billingStreet}
                        type="text"
                      />
                    </div>
                    <div className="company-form__field">
                      <InputField
                        showLabel
                        placeholder={"Address 2"}
                        name="billingAddress2"
                        value={values?.billingAddress2}
                        type="text"
                      />
                    </div>
                    <div className="company-form__field">
                      <DropdownField
                        mode="single"
                        optionFilterProp="label"
                        placeholder={"Country *"}
                        name="billingCountry"
                        options={countries}
                        showSearch={false}
                        value={values.billingCountry}
                        onChange={(value) => {
                          setFieldValue("billingCountry", value);
                          fetchProvinces(value);
                        }}
                      />
                    </div>
                    <div className="company-form__field">
                      <DropdownField
                        mode="single"
                        optionFilterProp="label"
                        placeholder={"State/Province *"}
                        name="billingState"
                        options={provinces}
                        showSearch={false}
                        value={values.billingState}
                        onChange={(value) => {
                          setFieldValue("billingState", value);
                          fetchCities(values?.country, value);
                        }}
                      />
                      <div className="company-form__field">
                        <DropdownField
                          mode="single"
                          optionFilterProp="label"
                          placeholder={"City *"}
                          name="billingCity"
                          options={cities}
                          showSearch={false}
                          value={values.billingCity}
                          onChange={(value) => {
                            setFieldValue("billingCity", value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="company-form__field">
                      <InputField
                        showLabel
                        placeholder={"Zip Code/Postal Code"}
                        name="billingZipCode"
                        value={values?.billingZipCode}
                        type="text"
                      />
                    </div>
                  </>
                )} */}
                <div className="company-form__label">
                  Contact Person Information
                </div>
                <div className="company-form__field">
                  <InputField
                    showLabel
                    placeholder={"Contact Person Name *"}
                    name="contactPersonName"
                    value={values?.contactPersonName}
                    type="text"
                  />
                </div>
                <div className="company-form__field">
                  <InputField
                    showLabel
                    placeholder={"Contact Person Title *"}
                    name="contactPersonTitle"
                    value={values?.contactPersonTitle}
                    type="text"
                  />
                </div>
                <div className="company-form__field">
                  <InputField
                    showLabel
                    placeholder={"Contact Person Telephone *"}
                    name="contactPersonPhone"
                    value={values?.contactPersonPhone}
                    type="text"
                  />
                </div>
                <div className="company-form__field">
                  <InputField
                    showLabel
                    placeholder={"Contact Person Email *"}
                    name="contactPersonEmail"
                    value={values?.contactPersonEmail}
                    type="text"
                  />
                </div>

                <div className="company-form__field text-left">
                  <Checkbox
                    className="mr-5"
                    name="isAgreed"
                    onChange={e => setFieldValue("isAgreed", e.target.checked ? true : null)}
                  />
                  <span>I agree to the
                    <Link target={"_blank"} className="mr-5 ml-5" to={appRoutes.TERMS_OF_USE}>Terms of use</Link>
                    and
                    <Link target={"_blank"} className="mr-5 ml-5" to={appRoutes.PRIVACY_POLICY}>Privacy policy</Link>
                  </span>
                </div>
                {/* <div className="company-form__label">Create Password</div>
                 <div className="company-form__field">
                  <InputField
                  showLabel
                    placeholder={"Password *"}
                    name="password"
                    value={values?.password}
                    type="password"
                  />
                </div>
                <div className="company-form__field">
                  <InputField
                  showLabel
                    placeholder={"Confirm Password *"}
                    name="confirmPassword"
                    value={values?.confirmPassword}
                    type="password"
                  />
                </div> */}
                <div className="btn__wrapper">
                  <ButtonComponent
                    type={"primary"}
                    onClick={() => isValid && handleSubmit(values)}
                    iconRight={closeHandler ? null : loading ? <LoadingOutlined /> : <ArrowRightOutlined />}
                    displayType={closeHandler ? "default" : "space-between"}
                    loading={closeHandler ? loading : false}
                    disabled={!values.isAgreed}
                  >
                    {closeHandler ? "Update" : "Proceed"}
                  </ButtonComponent>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default AuthContainer(CompanyDetails);