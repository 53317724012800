import { alias, list, object, primitive, serializable } from "serializr";

export class SubscriptionPlanServicesModel {
  @serializable(alias("service_id", primitive()))
  serviceId?: number;

  @serializable(alias("service_name", primitive()))
  serviceName?: string;
}
export class SubscriptionModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("no_of_users", primitive()))
  noOfUsers?: number;

  @serializable(alias("no_of_funds", primitive()))
  noOfFunds?: number;

  @serializable(alias("min_no_of_agreements", primitive()))
  minNoOfAgreements?: number;

  @serializable(alias("max_no_of_agreements", primitive()))
  maxNoOfAgreements?: number;

  @serializable(alias("subscription_plan_services", list(object(SubscriptionPlanServicesModel))))
  subscriptionPlanServices?: SubscriptionPlanServicesModel[];
  
  @serializable(alias("cost_per_month", primitive()))
  costPerMonth?: number;


}
export class SignUpModel {
  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("company_name", primitive()))
  companyName?: string;

  @serializable(alias("company_role_name", primitive()))
  companyRoleName?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("mobile_number", primitive()))
  mobileNumber?: string;

  @serializable(alias("password", primitive()))
  password?: string;

  @serializable(alias("referral_code", primitive()))
  referralCode?: number;
}
export class OtpModel {
  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("otp", primitive()))
  otp?: string;
}
export class LoginModel {
  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("password", primitive()))
  password?: string; 

  @serializable(alias("grant_type", primitive()))
  grantType?: string;
}