
import { serializable, alias, object, list, primitive } from 'serializr';

export class Service {

	@serializable(alias('id', primitive()))
	id?: number | string;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('required_no_of_coins', primitive()))
	requiredNoOfCoins?: number;

	@serializable(alias('deletable', primitive()))
	deletable: boolean = true;
}

