import { Tabs } from "antd";
import React, { FC, useEffect, useState } from "react";
import { PageHeaderComponent } from "../../../../../shared/components/PageHeader";
import faker from "faker";
import HFCPInfo from "./CPInfo";
import HFAgreementsList from "../../Agreements/ListAgreements";
import { useHistory, useLocation, useParams } from "react-router";
import { deserialize } from "serializr";
import {
  Agreement,
  AgreementStatus,
} from "../../../../../models/Agreements/agreement.model";
import { MetaService } from "../../../../../services/MetaService/meta.service";
import { NotificationTypes } from "../../../../../enums/notificationTypes";
import { AgreementTypes } from "../../../../../models/Agreements/agreementTypes.model";
import { CounterParties } from "../../../../../models/CounterParties/counterParties.model";
import { Fund } from "../../../../../models/Fund/fund.model";
import { HFCounterPartiesService } from "../../../../../services/CounterPartiesService/CounterParties.service";
import { FundsService } from "../../../../../services/FundService/Fund.service";
import AgreementTypesService from "../../../../../services/KeyTermsService/AgreementTypes.service";
import { HFAgreementService } from "../../../../../services/AgreementService/agreement.service";
import Notification from "../../../../../shared/components/Notification";
import { PaginationMeta } from "../../../../../models/Meta/pagination-meta.model";

export const HFCPDetails: FC = () => {
  const [tabKey, setTabKey] = useState("1");
  const params = useParams<{ cpId: string }>();
  const location = useLocation<{ name: string, id: string }>();

  const [agreements, setAgreements] = useState<Agreement[]>([]);
  const [agreementStatus, setAgreementStatus] = useState<any[]>([]);
  const [counterParties, setCounterParties] = useState<any[]>([]);
  const [funds, setFunds] = useState<any[]>([]);
  const [agreementTypes, setAgreementTypes] = useState<any[]>([]);
  const [pagination, setPagination] = useState<PaginationMeta>();
  const [loading, setLoading] = useState<boolean>(false)
  const [filterInfo, setFilterInfo] = useState<{ key: string; value: any }[]>();


  useEffect(() => {
    fetchAgreements();
    fetchFundsList();
    fetchCPList();
    fetchAgreementTypes();
    fetchAgreementStatus();
  }, []);

  let timer;
  useEffect(() => {
    timer = setTimeout(() => {
      fetchAgreements(filterInfo);
    }, 800);

    return () => {
      if (timer)
        clearTimeout(timer)
    }
  }, [filterInfo]);

  const fetchAgreementStatus = () => {
    MetaService.agreementStatus(
      (response) => {
        const data = deserialize(
          AgreementStatus,
          response.data["agreement_statuses"]
        ) as AgreementStatus[];
        const formattedData = data.map((sts) => {
          return {
            label: sts.name.replaceAll("_", " "),
            value: sts.id,
            color: sts.color,
          };
        });
        setAgreementStatus(formattedData);
      },
      (error) => {
        //console.log(error);
      }
    );
  };

  const fetchCPList = () => {
    HFCounterPartiesService.fetchCounterParties(
      { is_suggested: true },
      (response: CounterParties[]) => {
        const foramattedData = response.map((cp) => {
          return { label: cp.name, value: cp.id };
        });
        setCounterParties(foramattedData);
      },
      () => { },
      () => { }
    );
  };

  const fetchFundsList = () => {
    FundsService.fetchFunds(
      {},
      (response: Fund[]) => {
        const foramattedData = response.map((fund) => {
          return { label: fund.name, value: fund.id };
        });
        setFunds(foramattedData);
      },
      () => { },
      () => { }
    );
  };

  const fetchAgreementTypes = () => {
    AgreementTypesService.fetchAgreementTypes(
      (response: AgreementTypes[]) => {
        const foramattedData = response.map((agreement) => {
          return { label: agreement.name, value: agreement.id };
        });
        setAgreementTypes(foramattedData);
      },
      () => { },
      () => { }
    );
  };

  const fetchAgreements = (
    filters: any = {},
  ) => {
    setLoading(true)
    HFAgreementService.fetchAgreements(
      {
        counter_party_ids: [params?.cpId ?? location?.state?.id],
        ...filters
      },
      (response, pagination) => {
        setAgreements(response);
        setPagination(pagination)
      },
      (error) => {
        //console.log(error);
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to fetch agreements",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => {
        setLoading(false)
      }
    );
  };
  return (
    <>
      <PageHeaderComponent
        title={location?.state?.name}
        showTabs
        showBack
        tabs={["CP Info", "Agreements with this CP"]}
        onChangeTab={(key) => {
          setTabKey(key);
        }}
      />
      <div className="page-body">
        {tabKey == "1" ? (
          <HFCPInfo />
        ) : (
          <HFAgreementsList
            data={agreements}
            funds={funds}
            pagination={pagination}
            loading={loading}
            updateFilter={setFilterInfo}
            counterParties={counterParties}
            agreementStatus={agreementStatus}
            agreementTypes={agreementTypes}
          />
        )}
      </div>
    </>
  );
};
