
import {serializable, alias, object, list, primitive} from 'serializr';

export class Company { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('dox_coins', primitive()))
	doxCoins?: number;

	@serializable(alias('reason', primitive()))
	reason?: string;

	@serializable(alias('logo_url', primitive()))
	logoUrl?: string;
}