import { Divider } from "antd";
import React, { FC, Fragment, useEffect, useState } from "react";
import DiscussionBoardService from "../../../../../services/DiscussionBoardService/DiscussionBoard.service";
import DiscussionComment from "../DiscussionComment";
import "./listReply.scss";

interface ListReplyProps {
  topicId: number;
  reload?: number;
}

const ListReply: FC<ListReplyProps> = (props) => {
  const { topicId, reload } = props;

  const { comments, getComments } = DiscussionBoardService();

  useEffect(() => {
    getComments(topicId);
  }, [reload, topicId]);

  return (
    <div className="list-reply">
      {comments.map((reply) => (
        <Fragment>
          <DiscussionComment
            comment={reply}
            disableDivider
            reload={() => {
              getComments(topicId);
            }}
          />
          <Divider />
        </Fragment>
      ))}
    </div>
  );
};

export default ListReply;
