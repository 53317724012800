import moment from "moment";
import React, { FC, useEffect } from "react";
import { PaymentStatusTypes } from "../../../../../../enums/PaymentStatus.enum";
import SubscriptionPlanService from "../../../../../../services/SubscriptionPlan/subscriptionPlan.service";
import { TableComponent } from "../../../../../../shared/components/TableComponent";
import "./paymentLogs.scss";

interface PaymentLogsProps {}

const PaymentLogs: FC<PaymentLogsProps> = (props) => {
  const {} = props;

  const paymentLogsColumns = [
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
      render: (date: string) => moment(date).format("MMM D, YYYY")
    },
    {
      title: "HFUND COMPANY",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "SUBSCRIPTION TYPE",
      dataIndex: "subscriptionType",
      key: "subscriptionType",
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => `$${amount}`
    },
    {
      title: "PAYMENT STATUS",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (status: string) => <div className={status === PaymentStatusTypes.SUCCESS ? "status-success" : status === PaymentStatusTypes.FAILED ? "status-failed" : "status-warn"}>{status}</div>
    },
  ];

  const { payments, getPaymentLogs } = SubscriptionPlanService();

  useEffect(() => {
    getPaymentLogs();
  }, []);

  return (
    <div className="payment-logs">
      <TableComponent
        title="Payment logs"
        data={payments}
        columns={paymentLogsColumns}
      />
    </div>
  );
};

export default PaymentLogs;
