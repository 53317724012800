import React, { FC } from "react";
import "./numberInput.scss";

interface NumberInputProps {
	index: number;
	refs: any;
	setFieldValue: (key: string, value: any) => void;
	values: any;
}

const NumberInput: FC<NumberInputProps> = ({
	index,
	refs,
	setFieldValue,
	values,
}) => {

    const moveNext = () => {
        if (values[index]) {
			if (index === (refs?.length - 1)) {
				refs[index]?.current?.blur();
				return;
			}
			refs[index + 1]?.current?.focus();
		}
    }
	const handleOnChange = ({ target: { value } }: any) => {
        setFieldValue(index.toString(), value);
	};

	const handleKeyUp = ({ keyCode }: { keyCode: number }) => {
        moveNext();
		if (keyCode === 8) {
			if (index === 0) {
				return;
			}
			refs[index - 1]?.current?.focus();
		}
    };

	return (
		<input
			type="text"
			className="otp-input"
			ref={refs[index]}
            onChange={handleOnChange}
            onKeyUp={handleKeyUp}
            value={values[index]}
		/>
	);
};

export default NumberInput;
