
import {serializable, alias, object, list, primitive} from 'serializr';

export class OnboardingNotification { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('company_name', primitive()))
	companyName?: string;

	@serializable(alias('fund_name', primitive()))
	fundName?: string;

	@serializable(alias('onboarding_item_name', primitive()))
	onboardingItemName?: string;

	@serializable(alias('due_time', primitive()))
	dueTime?: string;

	@serializable(alias('description', primitive()))
	description?: string;

	@serializable(alias('notified', primitive()))
	notified?: string;

	@serializable(alias('actionable_id', primitive()))
	actionableId?: string;
}