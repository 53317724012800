import { useState } from "react";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { Payment } from "../../models/Payment/payment.model";
import { SubscriptionPlan, SubscriptionPlanCreate } from "../../models/SubscriptionPlan/subscriptionPlan.model";
import { HL_PAYMENT_LOGS, HL_SUBSCRIPTION_PLANS } from "../../routes/apiRoutes";

const SubscriptionPlanService = () => {

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState<Error>();

    const [payments, setPayments] = useState<Payment[]>([])

    const createSubscriptionPlan = async (subscriptionPlan: SubscriptionPlanCreate) => {
        try {
            setLoading(true);
            const subscriptionPlanJSON = {
                subscription_plan: serialize(subscriptionPlan), 
            }
            const { data } = await axiosInstance.post(HL_SUBSCRIPTION_PLANS, subscriptionPlanJSON);
            const createdSubscriptionPlan = deserialize(SubscriptionPlan, data?.subscription_plan);
            return createdSubscriptionPlan;
        } catch (error) {
            setError(error);
            console.log(error?.message);
            
        } finally {
            setLoading(false);
        }
    }

    const updateSubscriptionPlan = async (subscriptionPlan: SubscriptionPlanCreate) => {
        try {
            setLoading(true);
            const subscriptionPlanJSON = {
                subscription_plan: serialize(subscriptionPlan), 
            }
            const API_URL = HL_SUBSCRIPTION_PLANS + "/" + subscriptionPlan?.id;
            const { data } = await axiosInstance.put(API_URL, subscriptionPlanJSON);
            const updatedSubscriptionPlan = deserialize(SubscriptionPlan, data?.subscription_plan);
            return updatedSubscriptionPlan;
        } catch (error) {
            console.log(error?.message);
            
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    const deleteSubscriptionPlan = async (subscriptionPlan: SubscriptionPlan) => {
        try {
            setLoading(true);
            const API_URL = HL_SUBSCRIPTION_PLANS + "/" + subscriptionPlan?.id;
            await axiosInstance.delete(API_URL);
            return subscriptionPlan;
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    const getPaymentLogs = async () => {
        try {
            setLoading(true);
            const { data }  = await axiosInstance.get(HL_PAYMENT_LOGS);
            const payments = deserialize(Payment, data?.payments as any[]);
            setPayments(payments);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    return {
        error,
        loading,
        payments,
        createSubscriptionPlan,
        updateSubscriptionPlan,
        deleteSubscriptionPlan,
        getPaymentLogs
    }
}

export default SubscriptionPlanService;