import { serializable, alias, primitive, object, list } from 'serializr';


export class FundAddress {
	@serializable(alias('address_type', primitive()))
	addressType?: string;
	@serializable(alias('street', primitive()))
	street?: string;
	@serializable(alias('address_line_2', primitive()))
	addressLine?: string;
	@serializable(alias('city_id', primitive()))
	cityId?: number;
	@serializable(alias('zipcode', primitive()))
	zipcode?: string;
	@serializable(alias('addressable_id', primitive()))
	addressId?: number;
	@serializable(alias('addressable_type', primitive()))
	addressableType?: string;
	@serializable(alias('city_name', primitive()))
	cityName?: string;
	@serializable(alias('country_id', primitive()))
	countryId?: number;
	@serializable(alias('country_name', primitive()))
	countryName?: string;
	@serializable(alias('province_id', primitive()))
	provinceId?: number;
	@serializable(alias('province_name', primitive()))
	provinceName?: string;
}

export class Fund {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('domicile', primitive()))
	domicile?: string;

	@serializable(alias('strategy', primitive()))
	strategy?: string;

	@serializable(alias('feeder_fund', primitive()))
	feederFund?: boolean;

	@serializable(alias('launch_progress', primitive()))
	launchProgress?: string;

	@serializable(alias('state', primitive()))
	state?: string;

	@serializable(alias('country_id', primitive()))
	countryId?: string;

	@serializable(alias('launch_date', primitive()))
	launchDate?: string;

	@serializable(alias('base_currency', primitive()))
	baseCurrency?: string;

	@serializable(alias('target_launch_date', primitive()))
	targetLaunchDate?: string;

	@serializable(alias('aum', primitive()))
	aum?: number;

	@serializable(alias('aum_at_launch', primitive()))
	aumAtLaunch?: number;

	@serializable(alias('progress_percent', primitive()))
	progress?: number;

	@serializable(alias('target_aum', primitive()))
	targetAum?: number;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

	@serializable(alias('addresses', list(object(FundAddress))))
	addresses?: FundAddress[] = [];

	@serializable(alias('fund_administrator_name', primitive()))
	fundAdministrator?: string;

	@serializable(alias('lei', primitive()))
	lei?: string;


	@serializable(alias('general_partner', primitive()))
	generalPartner?: string;

	@serializable(alias('partner_name', primitive()))
	partnerName?: string;

	@serializable(alias('tier', primitive()))
	tier?: string;

	@serializable(alias('leverage_long', primitive()))
	leverageLong?: number;

	@serializable(alias('leverage_short', primitive()))
	leverageShort?: number;

	@serializable(alias('leverage_net', primitive()))
	leverageNet?: number;

	@serializable(alias('terminated_at', primitive()))
	terminatedAt?: string;

	@serializable(alias('days_to_launch', primitive()))
	daysToLaunch?: number;

	@serializable(alias('geographic_focus', list(primitive())))
	geographicFocus?: string[];

	@serializable(alias('products_traded', list(primitive())))
	productsTraded?: string[];

	@serializable(alias('sub_strategies', list(primitive())))
	subStrategy?: string[];

	@serializable(alias('fund_structure', primitive()))
	fundStructure?: string;

	@serializable(alias('feeder_fund_name', primitive()))
	feederFundName?: string;

	@serializable(alias('investment_manager_name', primitive()))
	investmentManagerName?: string;

	@serializable(alias('company_name', primitive()))
	companyName?: string;

	@serializable(alias('company_id', primitive()))
	companyId?: number;

	@serializable(alias('sub_advisor_name', primitive()))
	subAdvisorName?: string;

}
