export const AUTH = "/auth";
export const LOGIN = AUTH + "/login";
export const REGISTER = AUTH + "/register";
export const VERIFY_OTP = REGISTER + "/verify";
export const SUBSCRIPTION = AUTH + "/subscription/:companyId";
export const PAYMENT_INFORMATION = AUTH + "/payment-information/:companyId/:subscriptionId";
export const RESET_PASSWORD = AUTH + "/reset-password";
export const FORGOT_PASSWORD = AUTH + "/forgot-password";
export const FORGOT_PASSWORD_SUCCESS = AUTH + "/link-sent";
export const HOME = "/";
// HF View
export const HFUND = "/hfund";
export const HFUND_COMPANY_DETAILS = "/hfund/:id";
export const HFUND_DASHBOARD = HFUND_COMPANY_DETAILS + "/dashboard";
export const HFUND_FUNDS = HFUND_COMPANY_DETAILS + "/funds";
export const HFUND_AGREEMENTS = HFUND_COMPANY_DETAILS + "/agreements";
export const HFUND_MANAGE_USERS = HFUND_COMPANY_DETAILS + "/manage-users";
export const HFUND_DOX_COINS = HFUND_COMPANY_DETAILS + "/dox-coins";
export const HFUND_ACCOUNT_INFO = HFUND_COMPANY_DETAILS + "/account-info";

// HL View
export const HL_USERS = "/hl-users";
export const CONFIGURATION = "/config"
export const KT = CONFIGURATION + "/key-terms-list"
export const ONBOARDING_CONFIG = CONFIGURATION + "/onboarding-config"
export const HL_SUBSCRIPTION = CONFIGURATION + "/subscription";
export const NOTIFICATION_CONFIGURATION = CONFIGURATION + "/notification";

export const DASHBOARD = "/dashboard";


export const PROFILE_VERIFICATION = VERIFY_OTP + "/profile-verification";
export const COMPANY_DETAILS = LOGIN + "/company-details/:companyId";
export const MFA_VERIFICATION = VERIFY_OTP + "/mfa";

//Common Routes
export const FUNDS = "/funds";
export const FUNDS_SPECIFIC = FUNDS + "/:fundId";

export const AGREEMENTS = "/agreements";
export const AGREEMENTS_SPECIFIC = "/agreements/:agreementId";

export const CP = "/cp";
export const CP_DETAILS = CP + "/:cpId";
export const CP_GROUP = CP + "/details/cp-group/:cpGroupId";

export const TICKETS = "/tickets";
export const HELP = "/help";
export const PARTNERS = "/partners";
export const DOX = "/dox";
export const MANAGE_USERS = "/manage-users"

export const REPORTS = "/reports";
export const REPORTS_SPECIFIC = "/reports/:reportId";

export const TERMS_OF_USE = "/termsofuse";
export const PRIVACY_POLICY = "/privacypolicy";

// Discussion route
export const DISCUSSION_BOARD = "/discussion-board";

// Basics Routes
export const ACCOUNT_DETAILS = "/account-details";