
import {SubscriptionPlanService} from '../SubscriptionPlanService/subscriptionPlanService.model';

import {serializable, alias, object, list, primitive} from 'serializr';

export class SubscriptionPlan { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('cost_per_month', primitive()))
	costPerMonth?: number;

	@serializable(alias('no_of_funds', primitive()))
	noOfFunds?: number;

	@serializable(alias('no_of_users', primitive()))
	noOfUsers?: number;

	@serializable(alias('min_no_of_agreements', primitive()))
	minNoOfAgreements?: number;

	@serializable(alias('max_no_of_agreements', primitive()))
	maxNoOfAgreements?: number;

	@serializable(alias('subscription_plan_services', list(object(SubscriptionPlanService))))
	subscriptionPlanServices?: SubscriptionPlanService[] = []
}

export class SubscriptionPlanCreate extends SubscriptionPlan{ 
	@serializable(alias('subscription_plan_services', list(primitive())))
	subscriptionPlanServiceIds?: number[] = [];
}