export const fileDownloader = (data: Blob, filename: string) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([data]));
    downloadActionSimulator(link, filename);
}

export const fileDownloadFromUrl = (data: any) => {
    if (!data?.documentUrl) { return }
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = data?.documentUrl;
    downloadActionSimulator(link, data?.name);
}

const downloadActionSimulator = (link: any, filename: string) => {
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
