import FileSearchOutlined from "@ant-design/icons/lib/icons/FileSearchOutlined";
import { Input, Space } from "antd";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import {
  Agreement,
  AgreementStatus,
} from "../../../../../../models/Agreements/agreement.model";
import ButtonComponent from "../../../../../../shared/components/ButtonComponent";
import KeyContacts from "../../../../../../shared/components/KeyContacts";
import PopoverComponent from "../../../../../../shared/components/PopoverComponent";
import TableNotes from "../../../../../../shared/components/TableNotes";
import TableNotifications from "../../../../../../shared/components/TableNotifications";
import "./view-agreement.scss";
import {
  ArrowLeftOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import { HFAgreementService } from "../../../../../../services/AgreementService/agreement.service";
import { fileDownloadFromUrl } from "../../../../../../shared/utils/fileDownloader";
import ViewSDKClient from "../../../../../../shared/utils/viewSDKClient";
import KeyTermModal from "../../../../../../shared/components/KeyTermModal";
import { ConfirmationAlert } from "../../../../../../shared/components/ConfirmationAlert";
import AgreementKeyTermService from "./../../../../../../services/AgreementService/agreementKeyTerm.service";
import { LocalStorage } from "../../../../../../shared/utils/localStorageHelpers";
import { AgreementKeyTermStatus } from "../../../../../../enums/agreementKeyTermStatus";
import { NotificationTypes } from "../../../../../../enums/notificationTypes";
import Notification from "../../../../../../shared/components/Notification";
import { deserialize } from "serializr";
import { AgreementTypes } from "../../../../../../models/Agreements/agreementTypes.model";
import { CounterParties } from "../../../../../../models/CounterParties/counterParties.model";
import { Fund } from "../../../../../../models/Fund/fund.model";
import { HFCounterPartiesService } from "../../../../../../services/CounterPartiesService/CounterParties.service";
import { FundsService } from "../../../../../../services/FundService/Fund.service";
import AgreementTypesService from "../../../../../../services/KeyTermsService/AgreementTypes.service";
import { MetaService } from "../../../../../../services/MetaService/meta.service";
import AgreementForm from "../../AgreementForm";
import { useHistory, useParams } from "react-router-dom";
import * as AppRoutes from "./../../../../../../routes/appRoutes";
import { NotificationType } from "../../../../../../enums/notificationType";
import ScorecardModal from "../../../../../HL/Home/Agreements/AgreementDetails/ViewAgreement/ScorecardModal";
import { OnboardingItems } from "../../../../../../models/Meta/meta.model";

interface ViewAgreementProps {
  agreement: Agreement;
  fetchSingleAgreement: Function;
  onAgreementUpdate?: Function;
}
const ViewAgreement = (props: ViewAgreementProps) => {
  const { agreement, fetchSingleAgreement } = props;
  const history = useHistory();
  const params = useParams<{ agreementId: string }>();

  const token = LocalStorage.getItem("ACCESS_TOKEN");

  const [requestModal, setRequestModal] = useState<boolean>(false);
  const [viewDetailsPage, setViewDetailsPage] = useState<boolean>(false);
  const [scoreRequestModal, setScoreRequestModal] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [showDelete, setDeleteVisibility] = useState(false);
  const [agreementStatus, setAgreementStatus] = useState<any[]>([]);
  const [counterParties, setCounterParties] = useState<any[]>([]);
  const [funds, setFunds] = useState<any[]>([]);
  const [agreementTypes, setAgreementTypes] = useState<any[]>([]);
  const [scorecardShowModal, setScorecardShowModal] = useState<boolean>(false);

  const { requestAgreementKeyTerms, loading } = AgreementKeyTermService();

  useEffect(() => {
    fetchFundsList();
    fetchCPList();
    fetchAgreementTypes();
    fetchAgreementStatus();
  }, []);

  useEffect(() => {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile(
        "pdf-div",
        {
          url: agreement.documentUrl,
          fileName: agreement.fundName,
        },
        {
          showAnnotationTools: false,
          showLeftHandPanel: false,
          showPageControls: false,
          showDownloadPDF: false,
          showPrintPDF: false,
        }
      );
    });
  }, []);

  const fetchAgreementStatus = () => {
    MetaService.agreementStatus(
      (response) => {
        const data = deserialize(
          AgreementStatus,
          response.data["agreement_statuses"]
        ) as AgreementStatus[];
        const formattedData = data.map((sts) => {
          return {
            label: sts.name.replaceAll("_", " "),
            value: sts.id,
            color: sts.color,
          };
        });
        setAgreementStatus(formattedData);
      },
      (error) => {
        //console.log(error);
      }
    );
  };

  const fetchCPList = () => {
    HFCounterPartiesService.fetchCounterParties(
      { meta: true },
      (response: CounterParties[]) => {
        setCounterParties(deserialize(OnboardingItems, response));
      },
      () => { },
      () => { }
    );
  };

  const fetchFundsList = () => {
    FundsService.fetchFunds(
      { meta: true },
      (response: Fund[]) => {
        setFunds(deserialize(OnboardingItems, response));

      },
      (error) => {
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to fetch funds",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  const fetchAgreementTypes = () => {
    AgreementTypesService.fetchAgreementTypes(
      (response: AgreementTypes[]) => {
        const foramattedData = response.map((agreement) => {
          return { label: agreement.name, value: agreement.id };
        });
        setAgreementTypes(foramattedData);
      },
      () => { },
      () => { }
    );
  };

  const requestKeyTermsHandler = async () => {
    setRequestModal(true);
    try {
      const response = await requestAgreementKeyTerms(
        agreement?.id?.toString()
      );
      if (response) {
        setRequestModal(false);
        setScoreRequestModal(true);
      }
    } catch (error) {
      setRequestModal(false);
    }
  };

  const deleteHandler = (agreement: Agreement) => {
    HFAgreementService.deleteAgreement(
      agreement,
      (res) => {
        setDeleteVisibility(false);
        history.push(AppRoutes.AGREEMENTS);
        Notification({
          message: "Hedge Fund",
          description: "Agreement deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
      },
      (err) => {
        // ;
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Agreement deletion failed",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  return (
    <div className="view-agreement__container">
      <div className="view-agreement__header">
        <h2 className="view-agreement__header--title">{agreement?.name}</h2>
        <div className="view-agreement__header--details">
          {agreement?.fundName && (
            <div className="view-agreement__header--details__container">
              <p className="view-agreement__header--details__container--title">
                Fund Name
              </p>
              <p className="view-agreement__header--details__container--value">
                {agreement?.fundName}
              </p>
            </div>
          )}
          {agreement?.cpName && (
            <div className="view-agreement__header--details__container">
              <p className="view-agreement__header--details__container--title">
                CP Name
              </p>
              <p className="view-agreement__header--details__container--value">
                {agreement?.cpName}
              </p>
            </div>
          )}
          {agreement?.agreementTypeName && (
            <div className="view-agreement__header--details__container">
              <p className="view-agreement__header--details__container--title">
                Type
              </p>
              <p className="view-agreement__header--details__container--value">
                {agreement?.agreementTypeName?.replaceAll("_", " ")}
              </p>
            </div>
          )}
          {agreement?.date && (
            <div className="view-agreement__header--details__container">
              <p className="view-agreement__header--details__container--title">
                Date
              </p>
              <p className="view-agreement__header--details__container--value">
                {moment(agreement?.date).format("DD MMM, YYYY")}
              </p>
            </div>
          )}
          {agreement?.statusName && (
            <div className="view-agreement__header--details__container">
              <p className="view-agreement__header--details__container--title">
                Status
              </p>
              <p className="view-agreement__header--details__container--value">
                {agreement?.statusName?.replaceAll("_", " ")}
              </p>
            </div>
          )}
          {agreement?.sittingWith && (
            <div className="view-agreement__header--details__container">
              <p className="view-agreement__header--details__container--title">
                Sitting with
              </p>
              <p className="view-agreement__header--details__container--value">
                {agreement?.sittingWith?.replaceAll("_", " ")}
              </p>
            </div>
          )}
          {agreement?.tier && (
            <div className="view-agreement__header--details__container">
              <p className="view-agreement__header--details__container--title">
                Tier
              </p>
              <p className="view-agreement__header--details__container--value">
                {agreement?.tier}
              </p>
            </div>
          )}
          <div className="view-agreement__header--details__container">
            <p className="view-agreement__header--details__container--title">
              Progress
            </p>
            <p className="view-agreement__header--details__container--value">
              {agreement?.statusProgress + " %"}
            </p>
          </div>
          {agreement?.responsibleFirstName && (
            <div className="view-agreement__header--details__container">
              <p className="view-agreement__header--details__container--title">
                User responsible
              </p>
              <p className="view-agreement__header--details__container--value">
                {agreement?.responsibleFirstName +
                  " " +
                  agreement?.responsibleLastName}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="view-agreement__actions">
        {agreement?.keyTermStatus !== AgreementKeyTermStatus.VIEW ? (
          <div className="request-key-terms">
            <ButtonComponent
              type="primary"
              htmlType="button"
              onClick={() => setRequestModal(true)}
              icon={<FileSearchOutlined />}
              size="small"
            >
              Request Key Terms
            </ButtonComponent>
          </div>
        ) : (
          <div className="view-key-terms">
            <ButtonComponent
              type="primary"
              htmlType="button"
              onClick={() => {
                setViewDetailsPage(true);
              }}
              icon={<FileSearchOutlined />}
              size="small"
            >
              View Key Terms
            </ButtonComponent>
            {/* <ButtonComponent
              type="primary"
              htmlType="button"
              onClick={() => { }}
              icon={<CopyOutlined />}
              size="small"
            >
              Compare Agreement
            </ButtonComponent> */}
            {agreement?.scorecardUrl && (
              <ButtonComponent
                type="primary"
                htmlType="button"
                onClick={() => {
                  setScorecardShowModal(true);
                }}
                icon={<ContainerOutlined />}
                size="small"
              >
                View Scorecard
              </ButtonComponent>
            )}
          </div>
        )}
        <div className="view-agreement__actions--btns">
          <Space>
            <PopoverComponent
              showTitleButton={true}
              title="Notes"
              content={<TableNotes id={agreement?.id} category="Agreement" />}
            >
              <i className="icon-notes hoverable-icon" />
            </PopoverComponent>
            <PopoverComponent
              showTitleButton={true}
              title="Key contacts"
              content={
                <KeyContacts
                  contactableType="Agreement"
                  contactableId={agreement?.id?.toString()}
                />
              }
            >
              <i className="icon-key-contacts hoverable-icon" />
            </PopoverComponent>
            <PopoverComponent
              showTitleButton={true}
              title="Notifiations"
              content={
                <TableNotifications
                  id={agreement?.id}
                  category={NotificationType.AGREEMENT}
                  user="Hedge Fund"
                />
              }
            >
              <i className="icon-notification-1 hoverable-icon" />
            </PopoverComponent>
            <i
              className="icon-download hoverable-icon"
              onClick={() => {
                HFAgreementService.fetchSingleAgreements(
                  agreement?.id,
                  (data) => {
                    fileDownloadFromUrl(data);
                  },
                  () => {},
                  () => {}
                );
              }}
            />
            <i
              className="icon-edit"
              onClick={() => {
                setVisible(true);
              }}
            />
            <i
              className="icon-delete"
              onClick={() => {
                setDeleteVisibility(true);
              }}
            />
          </Space>
        </div>
      </div>
      <div className="view-agreement__body">
        {/* <div className="search-bar">
          <Input
            size="large"
            placeholder="Search.."
            prefix={<SearchOutlined />}
          />
        </div> */}
        {agreement?.documentUrl && (
          // <embed
          //   className="view-agreement__body--document"
          //   src={
          //     agreement?.documentUrl +
          //     "#statusbar=0&navpanes=0&toolbar=0&scrollbar=0&view=fitH"
          //   }
          //   type="application/pdf"
          // />
          <div className="view-agreement__body--document">
            <div id="pdf-div" className="full-window-div" />
          </div>
        )}
        {/* <PDFViewer agreement={agreement}/> */}
      </div>
      <KeyTermModal
        title={agreement?.name}
        visible={requestModal}
        loading={loading}
        onCancel={() => setRequestModal(false)}
        onConfirm={requestKeyTermsHandler}
      ></KeyTermModal>
      <ConfirmationAlert
        type="success"
        visible={scoreRequestModal}
        title="Key Term Request Submitted"
        description="Add a Scorecard to get advanced analytics on strengths and weaknesses of your agreement"
        customButtons={
          <div className="score-request--buttons">
            <ButtonComponent
              onClick={() => {
                setScoreRequestModal(false);
              }}
              type="ghost"
            >
              Close
            </ButtonComponent>
            <ButtonComponent
              onClick={() => {
                setScoreRequestModal(false);
              }}
              type="primary"
            >
              Request Scorecard
            </ButtonComponent>
          </div>
        }
        closeHandler={() => setScoreRequestModal(false)}
      ></ConfirmationAlert>
      {/* {viewDetailsPage &&
        <DocumentDetails
          isAdmin={false}
          closeWindow={() => setViewDetailsPage(false)}
          agreement={agreement}
        />} */}
      {/* PDF comes here */}

      {viewDetailsPage && (
        <Fragment>
          <ArrowLeftOutlined
            className="back-btn-pdf-hf"
            onClick={() => {
              setViewDetailsPage(false);
            }}
          />
         <iframe
            className="pdf-viewer"
            src={`https://pdf.tradingdox.com?agreement=${params?.agreementId}&token=${token}&isAdmin=0`}
          />
        </Fragment>
      )}

      <ConfirmationAlert
        closeHandler={() => setDeleteVisibility(false)}
        visible={showDelete}
        type="delete"
        deleteHandler={() => deleteHandler(agreement)}
        cancelHandler={() => setDeleteVisibility(false)}
        title={"Delete Agreement"}
        description={agreement?.name}
      />

      <ScorecardModal
        scorecardUrl={agreement?.scorecardUrl}
        visible={scorecardShowModal}
        closeHandler={() => setScorecardShowModal(false)}
      />

      <AgreementForm
        agreement={agreement}
        visible={visible}
        funds={funds}
        counterParties={counterParties}
        agreementStatus={agreementStatus}
        agreementTypes={agreementTypes}
        closeHandler={(agreement?: Agreement) => {
          if (agreement?.id) {
            fetchSingleAgreement();
          }
          setVisible(false);
        }}
      />
    </div>
  );
};

export default ViewAgreement;
