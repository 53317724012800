import { Dropdown, Menu, Space } from "antd";
import React, { useEffect, useState } from "react";
import { deserialize } from "serializr";
import { NotificationTypes } from "../../../../../enums/notificationTypes";
import {
  OtherItemStatus,
  OtherItem,
} from "../../../../../models/Fund/otherItems.model";
import { HLFundsService } from "../../../../../services/FundService/Fund.service";
import { MetaService } from "../../../../../services/MetaService/meta.service";
import { ConfirmationAlert } from "../../../../../shared/components/ConfirmationAlert";
import Notification from "../../../../../shared/components/Notification";
import { TableComponent } from "../../../../../shared/components/TableComponent";
import NotebookIcon from "../../../../../assets/icons/notebook.svg";
import EditIcon from "../../../../../assets/icons/edit.svg";
import DeleteIcon from "../../../../../assets/icons/delete.svg";
import BellIcon from "../../../../../assets/icons/bell-2.svg";
import { Fund } from "../../../../../models/Fund/fund.model";
import OtherItemForm from "./OtherItemForm";
import { sorter } from "../../../../../shared/utils/tableSorter";
import PopoverComponent from "../../../../../shared/components/PopoverComponent";
import OtherItemNotes from "./OtherItemNotes";
import OtherNotification from "./OtherNotifications";
import { CaretDownOutlined } from "@ant-design/icons";

interface OtherItemsProps {
  id: number;
  fund?: Fund;
}

const OtherItems = (props: OtherItemsProps) => {
  const { id } = props;

  const [otherItems, setOtherItems] = useState<OtherItem[]>([]);
  const [otherItemStatuses, setOtherItemStatuses] = useState<OtherItemStatus[]>(
    []
  );
  const [selectedItem, setSelectedItem] = useState<OtherItem>();
  const [showDelete, setDeleteVisibility] = useState(false);
  const [formVisibility, setFormVisibility] = useState(false);
  const [search, setSearch] = useState<string>("");

  const fetchItems = () => {
    HLFundsService.fetchOtherItemsList(
      id.toString(),
      { search_text: search },
      (response) => {
        //console.log(response);

        setOtherItems(response);
      },
      (err) => {
        // ;
      },
      () => { }
    );
  };
  useEffect(() => {
    fetchItems();
  }, [search]);

  useEffect(() => {
    MetaService.otherItemStatus(
      (res) => {
        const data = deserialize(
          OtherItemStatus,
          res.data["other_item_statuses"]
        ) as OtherItemStatus[];
        setOtherItemStatuses(
          data.map((data) => {
            return { label: data.label.replaceAll("_", " "), value: data.value };
          })
        );
      },
      (err) => {
        // ;
      }
    );
  }, []);

  const deleteHandler = (itemId: number) => {
    HLFundsService.deleteOtherItemsList(
      { id, itemId },
      (res) => {
        fetchItems()
        Notification({
          message: "Hedge Legal",
          description: "Item deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
      },
      (err) => {
        // // ;
        // Notification({
        //   message: "Hedge Legal",
        //   description: "Item deletion failed",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => {
        //console.log("Finally");
        setSelectedItem(null);
        setDeleteVisibility(false);
      }
    );
  };

  const statusUpdateMenu = (item: OtherItem) => {
    //console.log(otherItemStatuses);

    const availableSts = otherItemStatuses.filter(
      (sts) => sts.value !== item.otherItemStatusId
    );
    return (
      <Menu>
        {availableSts?.map((sts) => (
          <Menu.Item key={sts.value}
            onClick={() => changeStatus(item, sts.value)}
          >{sts.label}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const changeStatus = (item: OtherItem, toStatusId: number) => {
    const updatedItem = { ...item, otherItemStatusId: toStatusId }
    HLFundsService.updateOtherItemsList(
      id,
      updatedItem,
      (res) => {
        fetchItems()
      },
      (err) => {
        // ;
        // Notification({
        //   message: "Hedge Legal",
        //   description: "Unable to edit other item",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  }

  return (
    <>
      <TableComponent
        onRow={(index) => {
        }}
        onAdd={() => {
          setSelectedItem(null);
          setFormVisibility(true);
        }}
        onSearch={setSearch}
        showAdd={true}
        data={otherItems}
        pagination={false}
        scroll={{ x: 750 }}
        title={"Other Items"}
        columns={[
          {
            title: "ITEM",
            dataIndex: "name",
            key: "name",
            width: 300,
            sorter: (a, b) => sorter(a.name, b.name),
            sortDirections: ["ascend", "descend"],
          },
          {
            title: "USER",
            dataIndex: "responsibleUserFirstName",
            key: "responsibleUserFirstName",
            width: 150,
            sorter: (a, b) =>
              sorter(a.responsibleUserFirstName, b.responsibleUserFirstName),
            sortDirections: ["ascend", "descend"],
            render: (text, record) => (
              <span>{(text ? text + " " : '') + (record.responsibleUserLastName ? record.responsibleUserLastName : "")}</span>
            ),
          },
          {
            title: "STATUS",
            key: "otherItemStatus",
            dataIndex: "otherItemStatus",
            width: 150,
            sorter: (a, b) => sorter(a.otherItemStatus, b.otherItemStatus),
            sortDirections: ["ascend", "descend"],
            render: (text, record) => (
              <Dropdown
                overlayClassName="status-update--dropdown"
                overlay={() => statusUpdateMenu(record)}
                trigger={["click"]}
              >
                <p className={"other-items-status " + text}>
                  {text.replaceAll("_", " ")} <CaretDownOutlined />
                </p>
              </Dropdown>
            ),
          },
          {
            title: "",
            key: "action",
            width: 150,
            render: (text, record) => (
              <Space size="large">
                <PopoverComponent
                  trigger={"click"}
                  title="Notes"
                  content={<OtherItemNotes id={record.id} />}
                >
                  <img className="table-action-icon" src={NotebookIcon} alt=''
                    title="Notes" />
                </PopoverComponent>
                <PopoverComponent
                  className="notification-popover"
                  trigger={"click"}
                  title="Notifications"
                  content={<OtherNotification id={record.id} />}
                >
                  <img
                    className="table-action-icon"
                    src={BellIcon}
                    onClick={() => {
                    }}
                    alt=''
                    title="Notifications"
                  />
                </PopoverComponent>
                <img
                  className="table-action-icon"
                  src={EditIcon}
                  onClick={() => {
                    setSelectedItem(record);
                    setFormVisibility(true);
                  }}
                  alt=''
                  title="Edit"
                />
                <img
                  className="table-action-icon"
                  src={DeleteIcon}
                  onClick={() => {
                    setSelectedItem(record);
                    setDeleteVisibility(true);
                  }}
                  alt=''
                  title="Delete"
                />
              </Space>
            ),
          },
        ]}
      />
      <ConfirmationAlert
        closeHandler={() => {
          setSelectedItem(null);
          setDeleteVisibility(false);
        }}
        visible={showDelete}
        type="delete"
        deleteHandler={() => deleteHandler(selectedItem.id)}
        cancelHandler={() => {
          setSelectedItem(null);
          setDeleteVisibility(false);
        }}
        title={"Delete Onboarding Item?"}
        description={selectedItem?.name}
      />
      <OtherItemForm
        title={"Add new fund"}
        visible={formVisibility}
        id={id}
        data={selectedItem}
        closeHandler={() => {
          fetchItems();
          setFormVisibility(false);
        }}
      ></OtherItemForm>
    </>
  );
};

export default OtherItems;
