import React, { FC, useEffect, useState } from "react";
import { UIModal } from "../../../../../shared/components/UIModal";
import "./cpSuggestion.scss";
import { CPSuggestionCard } from "./CPSuggestionCard";
import faker, { fake } from "faker";
import { CounterParties } from "../../../../../models/CounterParties/counterParties.model";
import { HFCounterPartiesService, HLCounterPartiesService } from "../../../../../services/CounterPartiesService/CounterParties.service";
import { Status } from "../../../../../enums/counterParties";
interface CPSuggestionsProps {
  visible: boolean;
  closeHandler: () => void;
  onApprove: (value: CounterParties) => void
}
export const CPSuggestions: FC<CPSuggestionsProps> = ({
  visible,
  closeHandler,
  onApprove,
}) => {
  const [counterPartySuggestions, setCounterPartySuggestions] = useState<
    CounterParties[]
  >([]);
  const fetchCPList = () => {
    HLCounterPartiesService.fetchCounterParties(
      { is_suggested: true },
      (response: CounterParties[]) => {
        setCounterPartySuggestions(response);
      },
      () => { },
      () => { }
    );
  };

  useEffect(() => {
    visible && fetchCPList();
  }, [visible]);

  const deleteUserCounterParty = (id: any) => {
    HLCounterPartiesService.deleteCounterParty(
      id,
      () => {
        fetchCPList()
        closeHandler()
      },
      () => { },
      () => { }
    )
  }

  return (
    <UIModal
      visible={visible}
      closeModal={closeHandler}
      className="cp-suggestions__container"
      title={"CP Suggestions"}
    >
      <>
        {counterPartySuggestions.map((value, index) => {
          return (
            <CPSuggestionCard
              onClickStatus={(status) => {
                if (status == "accept") {
                  onApprove(value)
                } else {
                  deleteUserCounterParty(value?.id)
                }
              }}
              data={{
                cpName: value?.name,
                cpAddress: `${value.address?.street}, ${value.address?.addressLine2}, ${value.address?.cityName}, ${value.address?.provinceName}, ${value.address?.countryName}`,
                cpProducts: value?.productsAvailable,
                companyLogo: value?.logoUrl,
                companyName: value?.suggestedBy?.name,
                companyDescription: value?.suggestedBy?.description,
                city: value?.suggestedBy?.registeredCityName,
                asset: value?.suggestedBy?.assetsUnderManagement
              }}
            />
          );
        })}
      </>
    </UIModal>
  );
};
