
import {serializable, alias, object, list, primitive} from 'serializr';

export class SubscriptionPlanService { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('subscription_plan_id', primitive()))
	subscriptionPlanId?: number;

	@serializable(alias('service_id', primitive()))
	serviceId?: number;

	@serializable(alias('service_name', primitive()))
	serviceName?: string;

	@serializable(alias('subscription_plan_name', primitive()))
	subscriptionPlanName?: string;

}