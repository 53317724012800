import { Col, Row, Table } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import moment from "moment";
import React, { FC, useEffect } from "react";
import { NotificationType } from "../../../../enums/notificationType";
import { AgreementNotification } from "../../../../models/AgreementNotification/agreementNotification.model";
import { AncillaryNotification } from "../../../../models/AncillaryNotification/ancillaryNotification.model";
import { NotificationModel } from "../../../../models/Meta/notification.model";
import { OnboardingNotification } from "../../../../models/OnboardingNotification/onboardingNotification.model";
import { FundsService } from "../../../../services/FundService/Fund.service";
import NotificationService from "../../../../services/NotificationService/notification.service";
import { TableComponent } from "../../../../shared/components/TableComponent";
import "./agreementsAndOnboarding.scss";

interface AgreementAndOnboardingProps {}

type Notification = "Agreement" | "OnboardingItem"

const AgreementAndOnboarding: FC<AgreementAndOnboardingProps> = (props) => {
  const {} = props;

  const {
    agreementNotifications,
    ancillaryNotifications,
    onboardingNotifications,
    getAgreementNotifications,
    getAncillaryNotifications,
    getOnboardingNotifications,
    updateNotificationCompletion
  } = NotificationService();

  useEffect(() => {
    getAgreementNotifications();
    getAncillaryNotifications();
    getOnboardingNotifications();
  }, []);

  const renderDate = (date: string) => {
    return moment(date).format("MMM D, YYYY")
  }

  const completeNotification = async (
    bool: boolean,
    notificationType: NotificationType,
    notification?: AgreementNotification | AncillaryNotification |  OnboardingNotification,
  ) => {
    if (!bool) {
      return;
    }
    updateNotificationCompletion(
      { notificationType, notificationId: notification?.actionableId?.toString() },
      notification,
    );
  };

  const AgreementColumns = [
    { title: "FUND", dataIndex: "fundName", key: "fundName" },
    { title: "CP NAME", dataIndex: "cpName", key: "cpName" },
    {
      title: "AGREEMENT NAME",
      dataIndex: "agreementName",
      key: "agreementName",
    },
    { title: "TYPE", dataIndex: "agreementType", key: "agreementType" },
    { title: "REMIND ME ON", dataIndex: "dueTime", key: "dueTime", render: renderDate },
    { title: "DESCRIPTION", dataIndex: "description", key: "description" },
    { title: "", dataIndex: "", key: "", render: (_, notification: AgreementNotification) => <Checkbox onChange={e => completeNotification(e.target.checked, NotificationType.AGREEMENT ,notification)}/> },
  ];

  const AncillaryDocumentColumns = [
    { title: "FUND", dataIndex: "fundName", key: "fundName" },
    { title: "CP NAME", dataIndex: "cpName", key: "cpName" },
    {
      title: "AGREEMENT NAME",
      dataIndex: "agreementName",
      key: "agreementName",
    },
    {
      title: "AGREEMENT TYPE",
      dataIndex: "agreementType",
      key: "agreementType",
    },
    {
      title: "ANCILLARY DOCUMENT",
      dataIndex: "documentType",
      key: "documentType",
    },
    { title: "REMIND ME ON", dataIndex: "dueTime", key: "dueTime", render: renderDate },
    { title: "DESCRIPTION", dataIndex: "description", key: "description" },
    { title: "", dataIndex: "", key: "", render: (_, notification: AncillaryNotification) => <Checkbox onChange={e => completeNotification(e.target.checked, NotificationType.ANCILLARY_DOCUMENT ,notification)}/> },

  ];

  const OnboardingColumns = [
    { title: "FUND", dataIndex: "fundName", key: "fundName" },
    {
      title: "ONBOARDING ITEM",
      dataIndex: "onboardingItemName",
      key: "onboardingItemName",
    },
    { title: "REMIND ME ON", dataIndex: "dueTime", key: "dueTime", render: renderDate },
    { title: "DESCRIPTION", dataIndex: "description", key: "description" },
    { title: "", dataIndex: "", key: "", render: (_, notification: OnboardingNotification) => <Checkbox onChange={e => completeNotification(e.target.checked, NotificationType.ONBOARDING ,notification)}/> },

  ];

  return (
    <div className="agreement-and-onboarding">
      <TableComponent
        title="Agreement Notifications"
        data={agreementNotifications}
        columns={AgreementColumns}
        showSearch={false}
      />
      <TableComponent
        title="Ancillary Document Notifications"
        data={ancillaryNotifications}
        columns={AncillaryDocumentColumns}
        showSearch={false}
      />
      <TableComponent
        title="Onboarding Notifications"
        data={onboardingNotifications}
        columns={OnboardingColumns}
        showSearch={false}
      />
    </div>
  );
};

export default AgreementAndOnboarding;
