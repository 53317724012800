import { serializable, alias, primitive } from "serializr";

export class KeyContact{
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("phone", primitive()))
  phone?: string;

  @serializable(alias("other_details", primitive()))
  otherDetails?: string;
}
