import { Button, Col, Radio, Row, SelectProps } from "antd";
import { Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { Service } from "../../../../../../models/Service/service.model";
import {
  SubscriptionPlan,
  SubscriptionPlanCreate,
} from "../../../../../../models/SubscriptionPlan/subscriptionPlan.model";
import SubscriptionPlanService from "../../../../../../services/SubscriptionPlan/subscriptionPlan.service";
import InputField from "../../../../../../shared/components/InputField";

interface SubscriptionFormProps {
  subscriptionPlan?: SubscriptionPlan;
  successHandler: (subscriptionPlan: SubscriptionPlan) => void;
  closeHandler: () => void;
  services: Service[];
}

const radioGroup = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const SubscriptionForm: FC<SubscriptionFormProps> = (props) => {
  const { subscriptionPlan, services, successHandler, closeHandler } = props;

  const [formValues, setFormValues] = useState(new SubscriptionPlanCreate());

  const { loading, createSubscriptionPlan, updateSubscriptionPlan } =
    SubscriptionPlanService();

  useEffect(() => {
    if (subscriptionPlan) {
      setFormValues({
        ...subscriptionPlan,
        subscriptionPlanServiceIds:
          subscriptionPlan?.subscriptionPlanServices?.map(
            ({ serviceId }) => serviceId
          ),
      });
    }
  }, [subscriptionPlan]);

  const handleSuccess = (subscriptionPlan?: SubscriptionPlan) => {
    if (subscriptionPlan) {
      successHandler(subscriptionPlan);
    }
  };

  const handleSubmit = async (values: SubscriptionPlanCreate) => {
    const subscription = Object.assign(new SubscriptionPlanCreate(), values);
    if (subscriptionPlan) {
      const updatedSubscriptionPlan = await updateSubscriptionPlan(
        subscription
      );
      handleSuccess(updatedSubscriptionPlan);
    } else {
      const updatedSubscriptionPlan = await createSubscriptionPlan(
        subscription
      );
      handleSuccess(updatedSubscriptionPlan);
    }
  };

  const formikProps = {
    initialValues: formValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  };

  return (
    <div className="subscription-form">
      <Formik {...formikProps}>
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Row>
                <Col span={24} className="position-relative">
                  <InputField
                    type="text"
                    name="name"
                    showLabel
                    placeholder="Subscription Package Name"
                    value={values.name}
                  />
                </Col>
                <Col span={24} className="position-relative">
                  <InputField
                    type="number"
                    name="costPerMonth"
                    placeholder="Cost per annum"
                    value={values.costPerMonth}
                  />
                </Col>
                <Col span={24} className="position-relative">
                  <InputField
                    type="number"
                    showLabel
                    name="maxNoOfAgreements"
                    placeholder="# of Agreement"
                    value={values.maxNoOfAgreements}
                  />
                </Col>
                <Col span={24} className="position-relative">
                  <InputField
                    type="number"
                    showLabel
                    name="noOfFunds"
                    placeholder="# of Funds"
                    value={values.noOfFunds}
                  />
                </Col>
                <Col span={24} className="position-relative">
                  <InputField
                    type="number"
                    showLabel
                    name="noOfUsers"
                    placeholder="# of Users"
                    value={values.noOfUsers}
                  />
                </Col>
              </Row>
              <div className="mt-2" />
              {services?.map((service, index) => {
                return (
                  <div className="radio-container mb-3" key={index}>
                    <label htmlFor="#">{service?.name}</label>
                    <Radio.Group
                      className="block mt-2"
                      options={radioGroup}
                      value={values?.subscriptionPlanServiceIds?.includes(
                        service?.id as number
                      )}
                      onChange={({ target: { value } }) => {
                        if (value) {
                          setFieldValue("subscriptionPlanServiceIds", [
                            ...values?.subscriptionPlanServiceIds,
                            service?.id,
                          ]);
                          return;
                        }
                        const index =
                          values.subscriptionPlanServiceIds?.findIndex(
                            (id) => id === service.id
                          );
                        const subscriptionPlanServiceIds =
                          values.subscriptionPlanServiceIds;
                        subscriptionPlanServiceIds?.splice(index, 1);
                        setFieldValue("subscriptionPlanServiceIds", [
                          ...subscriptionPlanServiceIds,
                        ]);
                      }}
                    />
                  </div>
                );
              })}
              <Row justify="space-between" gutter={[20, 0]} className="mt-5">
                <Col span={12}>
                  <Button
                    type="text"
                    className="full-width"
                    onClick={closeHandler}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="full-width"
                    loading={loading}
                  >
                    {subscriptionPlan ? "Edit" : "Create"}
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SubscriptionForm;
