import { Form, Row, Col, Checkbox } from "antd";
import { Field, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Notification from "../../../../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../../../../enums/notificationTypes";
import { Agreement } from "../../../../../../../models/Agreements/agreement.model";
import { HFAgreementService, HLAgreementService } from "../../../../../../../services/AgreementService/agreement.service";
import DatePickerComponent from "../../../../../../../shared/components/DatePickerComponent";
import DropdownField from "../../../../../../../shared/components/DropdownField";
import InputField from "../../../../../../../shared/components/InputField";
import { UIModal } from "../../../../../../../shared/components/UIModal";
import { agreementTiers } from "../../../../../../HF/Home/Agreements/agreementsDefinitions";
import { AgreementValidation } from "../../../AgreementForm/validationSchema";
import { AttachmentComponent } from "../../../../../../../shared/components/AttachmentComponent";
import UppyFileUploader from "../../../../../../../shared/components/UppyFileUploader";
import AuthContainer from "../../../../../../../store/containers/AuthContainer";
import { AuthReducerProps } from "../../../../../../../store/reducers/authReducer";

interface ScorecardFormProps extends AuthReducerProps {
    visible: boolean;
    agreement: Agreement
    closeHandler: (agreement?: Agreement) => void;
}

const ScorecardForm = (props: ScorecardFormProps) => {
    const {
        visible,
        agreement,
        user,
        closeHandler,
    } = props;

    const [scorecardFileName, setScorecardFileName] = useState<string>('');
    const [scorecardUrl, setScorecardUrl] = useState<string>('');
    const [agreementFormData, setScorecardFormData] = useState<{
        scoreCard: any
    }>({
        scoreCard: ''
    });
    const [buttonLoader, setButtonLoader] = useState<boolean>(false);
    const onSubmit = (values: any, resetForm: Function) => {
        if (!scorecardFileName) return;

        (user.role.includes("hl") ? HLAgreementService : HFAgreementService).updateAgreement(
            {
                ...agreement,
                scorecard: scorecardFileName
            },
            (response) => {
                Notification({
                    message: "",
                    description: "Scorecard uploaded successfully",
                    type: NotificationTypes.SUCCESS,
                });
                setScorecardFileName("")
                setScorecardUrl("")
                closeHandler(response);
            },
            (error) => {
                // Notification({
                //     message: "",
                //     description: "Unable to upload scorecard",
                //     type: NotificationTypes.ERROR,
                // });
            },
            () => {
                setButtonLoader(false);
            }
        );
    };

    const handleSuccess = (resetForm: Function, response) => {
        resetForm();
        closeHandler(response);
    }


    return (
        <Formik
            enableReinitialize
            initialValues={agreementFormData}
            onSubmit={() => { }}
        >
            {({ setFieldValue, values, resetForm, isValid, dirty }) => {
                return (
                    <UIModal
                        className="agreement-form__modal"
                        visible={visible}
                        closeModal={closeHandler}
                        title="Upload Scorecard"
                        submitProps={{
                            text: "Submit",
                            clickHandler: () => {
                                onSubmit(values, resetForm);
                            },
                            disabled: !scorecardFileName,
                            loading: buttonLoader
                        }}
                        cancelProps={{
                            text: "Cancel",
                            clickHandler: () => {
                                resetForm();
                                closeHandler();
                            },
                        }}
                    >
                        <Form>
                            <Row className="scorecard-field" justify='center' align="middle">
                                <Field name="scoreCard" as="file-upload">
                                    <UppyFileUploader setFileUploadUrl={setScorecardUrl}
                                        setFileUploadName={setScorecardFileName}
                                        fileUploadUrl={scorecardUrl}
                                    />
                                    {/* <AttachmentComponent
                                        accept={"application/pdf"}
                                        image={values?.scoreCard}
                                        onUpload={(file) => {
                                            setFieldValue("scoreCard", file)

                                        }}
                                        title="Drag & Drop / Click here to Upload PDF File"
                                    /> */}
                                </Field>
                            </Row>
                        </Form>
                    </UIModal>
                );
            }}
        </Formik>
    );
};

export default AuthContainer(ScorecardForm);
