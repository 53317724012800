import { PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import { PageHeaderComponent } from "../../../../shared/components/PageHeader";
import PreLaunchFunds from "./PreLaunchFunds";
import { FundStatusBE, UIFundStatus } from "../../../../enums/fundStatuses";
import TerminatedFunds from "./TerminatedFunds";
import ActiveFunds from "./ActiveFunds";
import FundForm from "./FundForm";
import { Fund } from "../../../../models/Fund/fund.model";
import { fundStatuses } from "../../../HF/Home/Funds/FundForm/fundDefinitions";
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";
import { useLocation, useParams } from "react-router";
import Notification from "../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../enums/notificationTypes";

const HLFunds = () => {
  const [formVisibility, setFormVisibility] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(1);
  const [newPreLaunchFund, setNewPreLaunchFund] = useState<Fund>();
  const [newActiveFund, setNewActiveFund] = useState<Fund>();
  const [newTerminatedFund, setNewTerminatedFund] = useState<Fund>();
  const user = LocalStorage.getItem("USER");
  const { id: companyId } = useParams<{ id: string }>()

  const tabChangeHandler = (e: string) => setActiveTab(+e);

  const onFundCreation = (fund: Fund) => {
    if (!fund) { return }
    if (fund.status === FundStatusBE.ACTIVE) {
      setNewActiveFund(fund)
    } else if (fund.status === FundStatusBE.PRELAUNCH) {
      setNewPreLaunchFund(fund)
    } else if (fund.status === FundStatusBE.TERMINATED) {
      setNewTerminatedFund(fund)
    }
  }

  return (
    <div className="hf-funds">
      <PageHeaderComponent
        onChangeTab={tabChangeHandler}
        title="Funds"
        showTabs
        tabs={fundStatuses.map(status => status.label + " Fund")}
        buttons={
          <>
            <ButtonComponent
              className="page-header-title__btn"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == parseInt(companyId))?.length == 0)) {
                  Notification({
                    message: "Hedge Legal",
                    description: "Access Restricted",
                    type: NotificationTypes.ERROR,
                  });
                } else {
                  setFormVisibility(true)
                }
              }}
            >
              Add New Fund
            </ButtonComponent>
          </>
        }
      />
      <div className="funds-list">
        {activeTab === 1 && (
          <PreLaunchFunds newFund={newPreLaunchFund} title={UIFundStatus.PRELAUNCH}></PreLaunchFunds>
        )}
        {activeTab === 2 && (
          <ActiveFunds newFund={newActiveFund} title={UIFundStatus.ACTIVE}></ActiveFunds>
        )}
        {activeTab === 3 && (
          <TerminatedFunds newFund={newTerminatedFund} title={UIFundStatus.TERMINATED}></TerminatedFunds>
        )}
      </div>
      <FundForm
        title={"Add new fund"}
        visible={formVisibility}
        closeHandler={(newFund?: Fund) => {
          onFundCreation(newFund);
          setFormVisibility(false);
        }}
      ></FundForm>
    </div>
  );
};

export default HLFunds;
