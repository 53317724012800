export const useDebouncer = (func: Function, delay?: number) => {
    // NodeJS.Timeout to any because of compilation issue at server
    let timer: any;

    return function () {
        timer && clearTimeout(timer);
        const self = window as any
        const args = arguments
        timer = setTimeout(() => {
            func.apply(self, args)
        }, delay) as unknown as any
    }
}