import * as Yup from "yup";

export const CompanyValidation = Yup.object().shape({
  companyName: Yup.string().required("Company Name is required!"),
  assetUnderManagement: Yup.string().required("Asset is required!"),
  strategy: Yup.string().required("Strategy is required!"),
  street: Yup.string().required("Street is required!"),
  country: Yup.string().required("Country is required!"),
  city: Yup.number().required("City is required!"),
  state: Yup.number().required("State is required!"),
  zipCode: Yup.string().required("Zip Code/Postal Code is required!"),
  contactPersonName: Yup.string().required("Contact Person Name is required!"),
  contactPersonTitle: Yup.string().required("Contact Person Title is required!"),
  contactPersonPhone: Yup.string()
    // .matches(/^[0-9]{10}$/, "Mobile number should be valid!")
    .required("Contact Person Phone is required!"),
  contactPersonEmail: Yup.string().email("E-mail is not valid!").required("Contact Person Email is required!"),
  isAgreed: Yup.boolean()
    .typeError("Please agree terms of use and privacy policy to proceed")
    .required("Please agree terms of use and privacy policy to proceed"),
});
