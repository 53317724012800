import * as Yup from 'yup'

export const FundFormValidation = Yup.object({
    name: Yup.string().required("Fund Name is required!"),
    countryId: Yup.string().required("Fund Domicile is required!"),
    status: Yup.string().required("Status is required!"),
    investmentManagerName: Yup.string().required("Manager Name is required!"),
    companyName: Yup.string().required("Management company name is required!"),
    addresses: Yup.array(
        Yup.object({
            countryId: Yup.number().required("Country is required"),
            provinceId: Yup.number().required("Provice is required"),
            cityId: Yup.number().required("City is required"),
            // addressLine: Yup.string().required("Address 2 is required"),
            street: Yup.string().required("Registered address is required"),
            zipcode: Yup.string().required("Zipcode is required"),
        })
    ).min(1)
})

export const HLFundFormValidation = Yup.object({
    name: Yup.string().required("Fund Name is required!"),
    countryId: Yup.string().required("Fund Domicile is required!"),
    status: Yup.string().required("Status is required!"),
    investmentManagerName: Yup.string().required("Manager Name is required!"),
    companyId: Yup.string().required("Company Name is required!"),
    companyName: Yup.string().required("Management company name is required!"),
    addresses: Yup.array(
        Yup.object({
            countryId: Yup.number().required("Country is required"),
            provinceId: Yup.number().required("Provice is required"),
            cityId: Yup.number().required("City is required"),
            // addressLine: Yup.string().required("Address 2 is required"),
            street: Yup.string().required("Registered address is required"),
            zipcode: Yup.string().required("Zipcode is required"),
        })
    ).min(1)
})