import { Col, Dropdown, Input, Menu, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import faker from "faker";
import DeleteIcon from "../../../../../../assets/icons/deleteWhite.svg";
import ButtonComponent from "../../../../../../shared/components/ButtonComponent";
import "../GeneralItem/generalItem.scss";
import {
  PlusOutlined,
  EllipsisOutlined,
  CaretRightFilled,
} from "@ant-design/icons";
import { ItemCell } from "../GeneralItem";
import OnboardingItemMetasService from "../../../../../../services/OnboardingConfigService/OnboardingItemMetas.service";
import { OnboardingItemMetas } from "../../../../../../models/OnboardingConfig/onboardingItemMetas.model";
import { Category } from "../../../../../../enums/onboardingItems";
import { AddItemForm } from "../AddItemForm";

export const ISDAProtocols: FC = () => {
  const [ISDAProtocols, setISDAProtocols] = useState<OnboardingItemMetas[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showControls, setControlsVisibility] = useState(false);
  const [showAddItemForm, setAddFormVisibility] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const fetchISDAProtocols = () => {
    OnboardingItemMetasService.fetchOnboardingItemMetas(
      {},
      (response: OnboardingItemMetas[]) => {
        setISDAProtocols(
          response.filter((data) => data.category == Category.ISDA)
        );
      },
      () => {
        setControlsVisibility(false);
        fetchISDAProtocols();
      },
      () => { }
    );
  };
  useEffect(() => {
    fetchISDAProtocols();
  }, []);

  useEffect(() => {
    setCurrentItem(ISDAProtocols && ISDAProtocols[0]);
  }, [ISDAProtocols]);

  const onSave = () => {
    setLoading(true)
    OnboardingItemMetasService.updateOnboardingItemMetas(
      {
        description: currentItem?.description,
        onboardingItemMetaId: currentItem?.id,
      },
      () => {
        fetchISDAProtocols()
        setControlsVisibility(false);
      },
      () => { },
      () => setLoading(false)
    );
  };

  const onDelete = () => {
    setLoading(true)
    OnboardingItemMetasService.deleteOnboardingItemMetas(
      {
        onboardingItemMetaId: currentItem?.id,
      },
      () => {
        fetchISDAProtocols()
        setControlsVisibility(false);
      },
      () => { },
      () => setLoading(false)
    );
  }
  return (
    <div className="general-items__container">
      <AddItemForm
        visible={showAddItemForm}
        category={Category.ISDA}
        closeHandler={() => {
          setAddFormVisibility(false);
          fetchISDAProtocols();
        }}
      />
      <Row className="general-items__table">
        <Col span={8}>
          <div className="item__wrapper">
            <div className="title first">ITEM</div>
            <div className="table__cell">
              <p
                className="table-cell__action"
                onClick={() => {
                  setAddFormVisibility(true);
                }}
              >
                <span>
                  <PlusOutlined />
                </span>
                Add Item
              </p>
            </div>
            {ISDAProtocols.map((value, index) => {
              return (
                <ItemCell
                  title={value.name}
                  onClick={() => setCurrentItem(value)}
                  active={currentItem?.id == value.id}
                  onClickDelete={() => {
                    let data = currentItem;
                    data.description = null;
                    setCurrentItem({ ...data });
                    // onSave()
                    onDelete()
                  }}
                />
              );
            })}
          </div>
        </Col>
        <Col span={16}>
          <div className="description__wrapper">
            <div className="title last">DESCRIPTION</div>
            {currentItem && (
              <div className="description__content">
                <p>{currentItem?.name}</p>
                <Input.TextArea
                  value={currentItem?.description}
                  className="description__input"
                  onChange={(e) => {
                    setControlsVisibility(true);
                    let data = currentItem;
                    data.description = e.target.value;
                    setCurrentItem({ ...data });
                  }}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
      {showControls && (
        <div className="general-items__controllers">
          <ButtonComponent
            type="default"
            onClick={() => {
              setControlsVisibility(false);
            }}
          >
            Discard
          </ButtonComponent>
          <ButtonComponent type="primary" onClick={onSave} loading={loading}>
            Save
          </ButtonComponent>
        </div>
      )}
    </div>
  );
};
