import { alias, custom, list, object, primitive, serializable } from "serializr";
import { SubscriptionModel } from "../Auth/auth.model";
import { User } from "../User/user.model";

export class UserCompaniesModel {
  @serializable(alias("company_name", primitive()))
  companyName?: string;

  @serializable(alias("company_id", primitive()))
  companyId?: string;

  @serializable(alias("access_level", primitive()))
  accessLevel?: string;

  @serializable(alias("company_role_name", primitive()))
  companyRoleName?: string;

  @serializable(alias("company_status", primitive()))
  companyStatus?: string;
}
export class ProfileModel {
  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("full_name", primitive()))
  fullName?: string;

  @serializable(alias("company_name", primitive()))
  companyName?: string;

  @serializable(
    alias(
      "logo",
      custom(
        (file) => file,
        (file) => file
      )
    )
  )
  companyLogo?: File;

  @serializable(alias("company_logo", primitive()))
  logoUrl?: string;

  @serializable(alias("company_role_name", primitive()))
  companyRoleName?: string;

  @serializable(alias("company_id", primitive()))
  companyId?: string;

  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("mobile_number", primitive()))
  mobileNumber?: string;

  @serializable(alias("company_status", primitive()))
  companyStatus?: string;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("role", primitive()))
  role?: string;

  @serializable(alias("password", primitive()))
  password?: string;

  @serializable(alias("referral_code", primitive()))
  referralCode?: string;

  @serializable(alias("has_access_to_all_hfs", primitive()))
  hasAccessToAllHfs?: boolean;

  @serializable(alias("user_companies", list(object(UserCompaniesModel))))
  userCompanies?: UserCompaniesModel[];

  @serializable(alias("otp_module", primitive()))
  otpModule?: string;

  @serializable(alias("otp_verified", primitive()))
  otpVerified?: string;

  @serializable(alias("restricted_pages", primitive()))
  restrictedPages?: string;

  @serializable(alias("access_to_read", primitive()))
  accessToRead?: boolean;
}

export class AddressModel {
  @serializable(alias("address_type", primitive()))
  addressType?: string;

  @serializable(alias("street", primitive()))
  street?: string;

  @serializable(alias("address_line_2", primitive()))
  addressLine2?: string;

  @serializable(alias("city_id", primitive()))
  city?: string;

  @serializable(alias("country_id", primitive()))
  country?: string;

  @serializable(alias("province_id", primitive()))
  state?: string;

  @serializable(alias("city_name", primitive()))
  cityName?: string;

  @serializable(alias("province_name", primitive()))
  stateName?: string;

  @serializable(alias("zipcode", primitive()))
  zipCode?: string;
}
export class CompanyModel {
  @serializable(alias("id", primitive()))
  companyId?: string;

  @serializable(alias("name", primitive()))
  companyName?: string;

  @serializable(
    alias(
      "logo",
      custom(
        (file) => file,
        (file) => file
      )
    )
  )
  companyLogo?: File;


  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("asset_under_management", primitive()))
  assetUnderManagement?: string;

  @serializable(alias("additional_strategies", list(primitive())))
  additionalStrategies?: string[];

  @serializable(alias("strategy", primitive()))
  strategy?: string;

  @serializable(alias("contact_person_name", primitive()))
  contactPersonName?: string;

  @serializable(alias("contact_person_title", primitive()))
  contactPersonTitle?: string;

  @serializable(alias("contact_person_phone", primitive()))
  contactPersonPhone?: string;

  @serializable(alias("contact_person_email", primitive()))
  contactPersonEmail?: string;

  @serializable(alias("subscription_plan_id", primitive()))
  subscriptionPlanId?: string;

  @serializable(alias("admin_id", primitive()))
  adminId?: number;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("password", primitive()))
  password?: string;

  @serializable(alias("logo_url", primitive()))
  logoUrl?: string;

  @serializable(alias("admin", object(ProfileModel)))
  admin?: ProfileModel;

  @serializable(alias("addresses", list(object(AddressModel))))
  addresses: AddressModel[] = [];

  @serializable(alias("subscription_plan", object(SubscriptionModel)))
  subscriptionPlan?: SubscriptionModel;
  
  @serializable(object(User))
  user?: ProfileModel;
}
