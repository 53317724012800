import { OrderEnum } from './../../enums/orderEnum';

import { serializable, alias, object, list, primitive } from 'serializr';

export class KeyTermGroupsKeyTerm {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('key_term_type', primitive()))
	keyTermType?: string;
}
export class KeyTermGroups {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('order', primitive()))
	order?: OrderEnum;

	@serializable(alias('agreement_key_terms', list(object(KeyTermGroupsKeyTerm))))
	keyTerms?: KeyTermGroupsKeyTerm[];

}