import { Col, Dropdown, Input, Menu, Radio, Row, Switch } from "antd";
import React, { FC, useEffect, useState } from "react";
import { PageHeaderComponent } from "../../../../../shared/components/PageHeader";
import "./kt.scss";
import {
  PlusOutlined,
  EllipsisOutlined,
  CaretRightFilled,
} from "@ant-design/icons";
import faker from "faker";
import EditIcon from "../../../../../assets/icons/editWhite.svg";
import ReplicateIcon from "../../../../../assets/icons/replicate.svg";
import ButtonComponent from "../../../../../shared/components/ButtonComponent";
import { KTReplicateForm } from "./KTReplicateForm";
import { AgreementTypes } from "../../../../../models/Agreements/agreementTypes.model";
import { KeyTermGroups } from "../../../../../models/KeyTerms/keyTermGroups.model";
import { KeyTerms } from "../../../../../models/KeyTerms/keyTerms.model";
import AgreementTypesService from "../../../../../services/KeyTermsService/AgreementTypes.service";
import KeyTermGroupsService from "../../../../../services/KeyTermsService/KeyTermGroups.service";
import KeyTermsService from "../../../../../services/KeyTermsService/KeyTerms.service";
import { KeyTermTypes } from "../../../../../enums/keyTermsList";
import { KeyTermStatus } from "../../../../../enums/keyTermsList";
import { KTEditForm } from "./KTEditForm";
import CaretUpIcon from "../../../../../assets/icons/caretUp.svg";
import CaretDownIcon from "../../../../../assets/icons/caretDown.svg";
import DoubleCaretUpIcon from "../../../../../assets/icons/doubleCaretUp.svg";
import DoubleCaretDownIcon from "../../../../../assets/icons/doubleCaretDown.svg";
import { OrderEnum } from "../../../../../enums/orderEnum";

interface KTCellProps {
  title: string;
  showEdit?: boolean;
  showReplicate?: boolean;
  showOrder?: boolean;
  onClickEdit?: () => void;
  onClickReplicate?: () => void;
  onClickOrder?: (order: OrderEnum) => void;
  onToggle?: (value: string) => void;
  status: boolean;
  onClick?: () => void;
  active?: boolean;
}
const KTCell: FC<KTCellProps> = ({
  title,
  showEdit,
  showReplicate,
  showOrder,
  onClickEdit,
  onClickReplicate,
  onClickOrder,
  onClick,
  onToggle,
  active,
  status,
}) => {
  return (
    <div
      className={`table__cell table-cell__wrapper ${active && "table-cell__active"
        }`}
      onClick={onClick}
    >
      <p>{title}</p>
      <div className="table-cell__actions">
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu className="cp__menu">
              <Menu.Item>
                <p>
                  <span>
                    <Switch
                      checked={status}
                      size={"small"}
                      onChange={(value) =>
                        onToggle(
                          value ? KeyTermStatus.ENABLED : KeyTermStatus.DISABLED
                        )
                      }
                    />
                  </span>
                  {status ? "Enabled" : "Disabled"}
                </p>
              </Menu.Item>
              {showEdit && (
                <Menu.Item onClick={onClickEdit}>
                  <p>
                    <span>
                      <img src={EditIcon} />
                    </span>
                    Edit
                  </p>
                </Menu.Item>
              )}
              {showReplicate && (
                <Menu.Item onClick={onClickReplicate}>
                  <p>
                    <span>
                      <img src={ReplicateIcon} />
                    </span>
                    Replicate
                  </p>
                </Menu.Item>
              )}
              {showOrder && <Menu.Item
                onClick={() => onClickOrder(OrderEnum.MOVE_UP)}
              >
                <p>
                  <span>
                    <img src={CaretUpIcon} />
                  </span>
                  Move Up
                </p>
              </Menu.Item>}
              {showOrder && <Menu.Item
                onClick={() => onClickOrder(OrderEnum.MOVE_TOP)}
              >
                <p>
                  <span>
                    <img src={DoubleCaretUpIcon} />
                  </span>
                  Move to Top
                </p>
              </Menu.Item>}
              {showOrder && <Menu.Item
                onClick={() => onClickOrder(OrderEnum.MOVE_DOWN)}
              >
                <p>
                  <span>
                    <img src={CaretDownIcon} />
                  </span>
                  Move Down
                </p>
              </Menu.Item>}
              {showOrder && <Menu.Item
                onClick={() => onClickOrder(OrderEnum.MOVE_BOTTOM)}
              >
                <p>
                  <span>
                    <img src={DoubleCaretDownIcon} />
                  </span>
                  Move to Bottom
                </p>
              </Menu.Item>}
            </Menu>
          }
        >
          <span className="cell-action__icon">
            <EllipsisOutlined rotate={90} />
          </span>
        </Dropdown>
        <span className="cell-action__icon">
          <CaretRightFilled />
        </span>
      </div>
    </div>
  );
};
export const KT: FC = () => {
  const [agreementTypes, setAgreementTypes] = useState<AgreementTypes[]>([]);
  const [groups, setGroups] = useState<KeyTermGroups[]>([]);
  const [names, setNames] = useState<KeyTerms[]>([]);
  const [showAddType, setAddType] = useState(false);
  const [showAddGroup, setAddGroup] = useState(false);
  const [showAddKT, setAddKT] = useState(false);
  const [selectedType, setSelectedType] = useState<AgreementTypes>(null);
  const [selectedGroup, setSelectedGroup] = useState<KeyTermGroups>(null);
  const [selectedKT, setSelectedKT] = useState<KeyTerms>(null);
  const [showReplicateForm, setReplicateFormVisibility] = useState(false);
  const [showEdit, setEditFormVisibility] = useState(false);
  const [showAdd, setAddFormVisibility] = useState(false);
  const [dataForEdit, setdataForEdit] = useState<any>();
  const [editType, setEditType] = useState<any>();
  const fetchAgreementTypes = () => {
    AgreementTypesService.fetchAgreementTypes(
      (response: AgreementTypes[]) => {
        setAgreementTypes(response);
        if (response?.length) setSelectedType(response[0]);
      },
      () => { },
      () => { }
    );
  };

  const fetchKeyTermGroups = () => {
    KeyTermGroupsService.fetchKeyTermGroups(
      selectedType.id,
      (response: KeyTermGroups[]) => {
        setGroups(response);
        if (response?.length) setSelectedGroup(response[0]);
      },
      () => { },
      () => { }
    );
  };

  const keyTermOrderingHandler = (
    order: OrderEnum,
    type: "key_term" | "key_term_group",
    value: KeyTermGroups | KeyTerms
  ) => {
    if (type === "key_term") {
      updateKeyTerm({ ...value, order })
    } else {
      updateKeyTermGroup({ ...value, order })
    }

  }


  const fetchKeyTerms = () => {
    KeyTermsService.fetchKeyTerms(
      selectedType.id,
      selectedGroup.id,
      (response: KeyTermGroups[]) => {
        setNames(response);
        if (response?.length) setSelectedKT(response[0]);
      },
      () => { },
      () => { }
    );
  };
  const updateAgreementType = (values) => {
    AgreementTypesService.updateAgreementTypes(
      values,
      () => {
        fetchAgreementTypes();
      },
      () => { },
      () => { }
    );
  };
  const updateKeyTermGroup = (values) => {
    KeyTermGroupsService.updateKeyTermGroups(
      selectedType.id,
      values,
      () => {
        fetchKeyTermGroups();
      },
      () => { },
      () => { }
    );
  };

  const updateKeyTerm = (values) => {
    KeyTermsService.updateKeyTerms(
      selectedType.id,
      selectedGroup.id,
      values,
      () => {
        fetchKeyTerms();
      },
      () => { },
      () => { }
    );
  };

  const createAgreementType = (values: any) => {
    AgreementTypesService.createAgreementTypes(
      values,
      () => {
        fetchAgreementTypes()
      },
      () => { },
      () => { }
    );
  };
  const createKeyTermGroup = (values) => {
    KeyTermGroupsService.createKeyTermGroups(
      selectedType.id,
      values,
      () => {
        fetchKeyTermGroups();
      },
      () => { },
      () => { }
    );
  };

  const createKeyTerm = (values) => {
    KeyTermsService.createKeyTerm(
      selectedType.id,
      selectedGroup.id,
      values,
      () => {
        fetchKeyTerms();
      },
      () => { },
      () => { }
    );
  };

  useEffect(() => {
    setAgreementTypes([]);
    setGroups([]);
    setNames([]);
    setSelectedType(null);
    setSelectedGroup(null);
    setSelectedKT(null);
    fetchAgreementTypes();
  }, []);
  useEffect(() => {
    setGroups([]);
    setNames([]);
    setSelectedGroup(null);
    setSelectedKT(null);
    if (selectedType) fetchKeyTermGroups();
  }, [selectedType]);
  useEffect(() => {
    setNames([]);
    setSelectedKT(null);
    if (selectedGroup) fetchKeyTerms();
  }, [selectedGroup]);

  return (
    <>
      <PageHeaderComponent title="Key Terms List" />

      <div className="kt-container">
        <KTEditForm
          visible={showEdit}
          data={dataForEdit}
          item={editType}
          refreshGroup={fetchKeyTermGroups}
          refreshKT={fetchKeyTerms}
          closeHandler={() => setEditFormVisibility(false)}
        />
        <KTReplicateForm
          visible={showReplicateForm}
          agreementTypes={agreementTypes}
          closeHandler={() => {
            setReplicateFormVisibility(false);
            fetchAgreementTypes();
          }}
        />
        <Row className="kt-list__table">
          <Col span={6}>
            <div className="kt-agreement__wrapper">
              <div className="table__title first">Agreement Type</div>
              <div className="table__cell">
                {showAddType ? (
                  <Input
                    placeholder="Enter Agreement Type"
                    onPressEnter={(e: any) => {
                      createAgreementType({ name: e.target.value });
                      setAddType(false);
                    }}
                  />
                ) : (
                  <p
                    className="table-cell__action"
                    onClick={() => {
                      setAddType(true)
                    }}
                  >
                    <span>
                      <PlusOutlined />
                    </span>
                    Add Agreement Type
                  </p>
                )}
              </div>
            </div>
            {agreementTypes.map((value, index) => {
              return (
                <KTCell
                  title={value?.name}
                  active={selectedType?.id == value?.id}
                  showReplicate
                  onToggle={(value) => {
                    updateAgreementType({ ...selectedType, status: value });
                  }}
                  onClick={() => {
                    setSelectedType(value);
                  }}
                  onClickReplicate={() => {
                    setReplicateFormVisibility(true);
                  }}
                  status={value.status == KeyTermStatus.ENABLED}
                />
              );
            })}
          </Col>
          <Col span={6}>
            <div className="kt-group__wrapper">
              <div className="table__title">Key Term Group</div>
              <div className="table__cell">
                {showAddGroup ? (
                  <Input
                    placeholder="Enter Key Term Group"
                    onPressEnter={(e: any) => {
                      createKeyTermGroup({ name: e.target.value });
                      setAddGroup(false);
                    }}
                  />
                ) : (
                  <p
                    className="table-cell__action"
                    onClick={() => {
                      setAddGroup(true);
                    }}
                  >
                    <span>
                      <PlusOutlined />
                    </span>
                    Add Key Term Group
                  </p>
                )}
              </div>
              {groups.map((value, index) => {
                return (
                  <KTCell
                    title={value?.name}
                    active={selectedGroup?.id == value?.id}
                    showEdit
                    showOrder
                    onToggle={(value) => {
                      updateKeyTermGroup({ ...selectedGroup, status: value });
                    }}
                    onClick={() => {
                      setSelectedGroup(value);
                    }}
                    onClickOrder={(order) => keyTermOrderingHandler(order, "key_term_group", value)}
                    onClickEdit={() => {
                      setdataForEdit({ ...value, selectedType });
                      setEditType("key_term_group");
                      setEditFormVisibility(true);
                    }}
                    status={value.status == KeyTermStatus.ENABLED}
                  />
                );
              })}
            </div>
          </Col>

          <Col span={6}>
            <div className="kt-name__wrapper">
              <div className="table__title">Key Term Name</div>
              <div className="table__cell">
                {showAddKT ? (
                  <Input
                    placeholder="Enter Key Term Name"
                    onPressEnter={(e: any) => {
                      createKeyTerm({
                        name: e.target.value,
                        keyTermType: KeyTermTypes.FREE_TEXT,
                      });
                      setAddKT(false);
                    }}
                  />
                ) : (
                  <p
                    className="table-cell__action"
                    onClick={() => {
                      setAddKT(true);
                    }}
                  >
                    <span>
                      <PlusOutlined />
                    </span>
                    Add Key Term
                  </p>
                )}
              </div>
              {names.map((value, index) => {
                return (
                  <KTCell
                    active={selectedKT?.id == value?.id}
                    title={value?.name}
                    showEdit
                    showOrder
                    onClick={() => {
                      setSelectedKT(value);
                    }}
                    onClickOrder={(order) => keyTermOrderingHandler(order, "key_term", value)}
                    onToggle={(value) => {
                      updateKeyTerm({ ...selectedKT, status: value });
                    }}
                    onClickEdit={() => {
                      setdataForEdit({ ...value, selectedType, selectedGroup });
                      setEditType("key_term");
                      setEditFormVisibility(true);
                    }}
                    status={value.status == KeyTermStatus.ENABLED}
                  />
                );
              })}
            </div>
          </Col>

          <Col span={6}>
            <div className="kt-type__wrapper">
              <div className="table__title last">Key Term Type</div>
              <Radio.Group
                className="kt-type__radio"
                defaultValue={selectedKT?.keyTermType}
                value={selectedKT?.keyTermType}
                onChange={(e) =>
                  updateKeyTerm({ ...selectedKT, keyTermType: e.target.value })
                }
              >
                <div className="table__cell">
                  <Radio value={KeyTermTypes.BOOLEAN}>Yes / No</Radio>
                </div>
                <div className="table__cell">
                  <Radio value={KeyTermTypes.PERCENTAGE}>Percentage %</Radio>
                </div>
                <div className="table__cell">
                  <Radio value={KeyTermTypes.CURRENCY}>Currency</Radio>
                </div>
                <div className="table__cell">
                  <Radio value={KeyTermTypes.DATE}>Date</Radio>
                </div>
                <div className="table__cell">
                  <Radio value={KeyTermTypes.TIME}>Time</Radio>
                </div>
                <div className="table__cell">
                  <Radio value={KeyTermTypes.DAY_COUNT}>Day Count</Radio>
                </div>
                <div className="table__cell">
                  <Radio value={KeyTermTypes.FREE_TEXT}>Free Text</Radio>
                </div>
              </Radio.Group>
            </div>
          </Col>
        </Row>
        {/* <div className="kt-controllers">
          <ButtonComponent type="default">Discard</ButtonComponent>
          <ButtonComponent type="primary">Save</ButtonComponent>
        </div> */}
      </div>
    </>
  );
};
