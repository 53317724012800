export enum Status {
    ENABLED = "enabled",
    DISABLED = "disabled",
    SUGGESTED = "suggested",
    PENDING_INVITATION = "pending_invitation"
}
export enum CPUsersAccessLevel {
    READ_ONLY = "read_only",
    RESPOND_TO_REVIEWS = "respond_to_reviews",
    FULL_ACCESS = "full_access"
}