import React, { FC, useState, useEffect} from 'react';
import { UIModal } from '../../../../../../../shared/components/UIModal';
import "./removeModal.scss";
import RemoveAmendmentForm from '../RemoveForm';
import { HLAgreementService } from '../../../../../../../services/AgreementService/agreement.service';
import { NotificationTypes } from '../../../../../../../enums/notificationTypes';
import Notification from '../../../../../../../shared/components/Notification';
import { Agreement } from '../../../../../../../models/Agreements/agreement.model';


interface RemoveModalProps {
    visible: boolean;
    closeHandler: () => void;
    agreement: Agreement;
    amendmentNames: string[];
    amendmentId: string[];
}

const RemoveModal: FC<RemoveModalProps> = (props) => {
    const { visible, closeHandler, agreement, amendmentNames, amendmentId } = props;
    const [formVisible, setFormVisible] = useState(false);

    const handleCloseForm = () => {
        setFormVisible(false);
        closeHandler();
    };

    const handleRemove = (values) => {
        HLAgreementService.deleteAmendment(
            values.selectedAmendments,
            agreement?.id?.toString(),
            (response) => {
              Notification({
                message: "Hedge Legal",
                description: "Amendment deleted successfully",
                type: NotificationTypes.SUCCESS,
              });
              closeHandler();
            },
            (error) => {
              Notification({
                message: "Hedge Legal",
                description: "Unable to delete Amendment",
                type: NotificationTypes.ERROR,
              });
            },
            () => {
            }
          );
    };

    return (
        <UIModal
            visible={visible}
            closeModal={handleCloseForm}
            className='remove-modal'
            title="Remove Amendments"
            cancelProps={{
                text: "Cancel",
                clickHandler: () => {
                    // closeHandler();
                },
            }}
        >
            <div className='remove-amendments-container'>
            <RemoveAmendmentForm
            visible={formVisible}
            closeHandler={closeHandler}
            onSubmit={handleRemove}
            amendmentNames={amendmentNames}  
            amendmentId={amendmentId}  
            />
            </div>
        </UIModal>
    );
};

export default RemoveModal;
