import { Form, Row, Col, Checkbox } from "antd";
import { Field, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NotificationTypes } from "../../../../../../../enums/notificationTypes";
import {
  Agreement,
  AgreementStatus,
  AncillaryDocuments,
} from "../../../../../../../models/Agreements/agreement.model";
import { HLAgreementService } from "../../../../../../../services/AgreementService/agreement.service";
import DropdownField from "../../../../../../../shared/components/DropdownField";
import { UIModal } from "../../../../../../../shared/components/UIModal";
import Notification from "../../../../../../../shared/components/Notification";
import { MetaService } from "../../../../../../../services/MetaService/meta.service";
import { deserialize } from "serializr";
import UppyFileUploader from "../../../../../../../shared/components/UppyFileUploader";
import { ancillaryDocmentTypes } from "../../../../../../HF/Home/Agreements/AgreementDetails/AncillaryDocuments/AncillaryForm/ancillaryDefinitions";
import { AncillaryValidation } from "../../../../../../HF/Home/Agreements/AgreementDetails/AncillaryDocuments/AncillaryForm/validationSchema";
import { useParams } from "react-router-dom";
interface AncillaryFormProps {
  visible: boolean;
  agreement: Agreement;
  closeHandler: (document?: AncillaryDocuments) => void;
  document?: AncillaryDocuments;
}

const AncillaryForm = (props: AncillaryFormProps) => {
  const { document, visible, closeHandler, agreement } = props;
  const params = useParams<{ agreementId: string }>();
  const [ancillaryFormData, setAncillaryFormData] =
    useState<AncillaryDocuments>(new AncillaryDocuments());
  const [ancillaryStatus, setAncillaryStatus] = useState<any[]>([]);
  const [ancillaryDocumentTypes, setAncillaryDocumentTypes] = useState<string[]>([]);
  const [ancillaryFileName, setAncillaryFileName] = useState<string>("");
  const [ancillaryUploadUrl, setAncillaryUploadUrl] = useState<string>("");
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);

  const fetchAncillaryStatus = () => {
    MetaService.ancillaryStatus(
      (response) => {
        const data = deserialize(
          AgreementStatus,
          response.data["ancillary_document_statuses"]
        ) as AgreementStatus[];
        const formattedData = data.map((sts) => {
          return { label: (sts?.name ?? "")?.replaceAll("_", " "), value: sts.id };
        });
        setAncillaryStatus(formattedData);
      },
      (error) => { }
    );
  };

  const fetchDocuments = () => {
    HLAgreementService.fetchAncillaryDocuments(
      agreement?.id?.toString() ?? params?.agreementId,
      {},
      (documents) => setAncillaryDocumentTypes(documents.map(doc => doc.documentType)),
      () => { },
      () => { }
    );
  };

  const onSubmit = (values: AncillaryDocuments, resetForm: Function) => {
    setButtonLoader(true);
    if (ancillaryFileName) {
      values.document = ancillaryFileName;
    }

    if (values.id) {
      HLAgreementService.updateAncillaryDocument(
        values,
        agreement?.id?.toString(),
        (response) => {
          Notification({
            message: "Hedge Legal",
            description: "Document updated successfully",
            type: NotificationTypes.SUCCESS,
          });
          handleSuccess(resetForm, response);
        },
        (error) => {
          //console.log(error);
          // Notification({
          //   message: "Hedge Legal",
          //   description: "Unable to update document",
          //   type: NotificationTypes.ERROR,
          // });
        },
        () => {
          setButtonLoader(false);
        }
      );
    } else {
      HLAgreementService.createAncillaryDocument(
        values,
        agreement?.id?.toString(),
        (response) => {
          Notification({
            message: "Hedge Legal",
            description: "Document created successfully",
            type: NotificationTypes.SUCCESS,
          });
          handleSuccess(resetForm, response);
        },
        (error) => {
          //console.log(error);
          // Notification({
          //   message: "Hedge Legal",
          //   description: "Unable to add document",
          //   type: NotificationTypes.ERROR,
          // });
        },
        () => {
          setButtonLoader(false);
        }
      );
    }
  };

  const handleSuccess = (resetForm: Function, response) => {
    resetForm();
    closeHandler(response);
    setAncillaryUploadUrl("");
    setAncillaryFileName("");
  };

  useEffect(() => {
    setAncillaryFormData(new AncillaryDocuments());
    setAncillaryUploadUrl("");
    setAncillaryFileName("");
    if (document?.id) {
      HLAgreementService.fetchAncillaryDocumentDetails(
        agreement?.id,
        document?.id,
        (document: AncillaryDocuments) => {
          setAncillaryFormData(document);
          setAncillaryUploadUrl(document?.documentUrl);
        },
        () => { },
        () => { }
      );
    }
  }, [document]);

  useEffect(() => {
    fetchAncillaryStatus();
  }, []);

  useEffect(() => {
    fetchDocuments()
  }, [visible])

  return (
    <Formik
      enableReinitialize
      initialValues={ancillaryFormData}
      onSubmit={() => { }}
      validationSchema={AncillaryValidation}
    >
      {({ setFieldValue, values, resetForm, isValid, dirty }) => {
        return (
          <UIModal
            className="ancillary-form__modal"
            visible={visible}
            closeModal={closeHandler}
            title={
              (ancillaryFormData?.id ? "Edit " : "Add New ") +
              " Ancillary Document"
            }
            submitProps={{
              text: ancillaryFormData?.id ? "Update " : "Create",
              clickHandler: () => {
                onSubmit(values, resetForm);
              },
              disabled: !isValid || !dirty,
              loading: buttonLoader,
            }}
            cancelProps={{
              text: "Cancel",
              clickHandler: () => {
                resetForm();
                closeHandler();
                setAncillaryUploadUrl("");
                setAncillaryFileName("");
              },
            }}
          >
            <Form>
              <div className="ancillary-form__container">
                <Row>
                  <Col sm={24} className="ancillary-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"Type"}
                      name="documentType"
                      options={[...ancillaryDocmentTypes.filter(type => !ancillaryDocumentTypes.includes(type.value) || ancillaryFormData.documentType === type.value)]}
                      showSearch={false}
                      value={values.documentType}
                      onChange={(value) => {
                        setFieldValue("documentType", value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} className="ancillary-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"Status"}
                      name="ancillaryStatusId"
                      options={ancillaryStatus}
                      showSearch={false}
                      value={values.ancillaryStatusId}
                      onChange={(value) => {
                        setFieldValue("ancillaryStatusId", value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm={24}
                    className="ancillary-form__field ancillary-form__field--file-upload"
                  >
                    <Field name="document">
                      {({ field }) => (
                        <UppyFileUploader
                          {...field}
                          setFileUploadUrl={setAncillaryUploadUrl}
                          setFileUploadName={setAncillaryFileName}
                          fileUploadUrl={ancillaryUploadUrl}
                          onComplete={(url) => setFieldValue('documentUrl', url)}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </div>
            </Form>
          </UIModal>
        );
      }}
    </Formik>
  );
};

export default AncillaryForm;
