import { Col, Row } from "antd";
import Form from "antd/lib/form/Form";
import { Field, Formik, FormikHelpers } from "formik";
import { any, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { deserialize } from "serializr";
import { CompanyModel, ProfileModel } from "../../../../../models/HFund/hfund.model";
import { CompanyMetaModel } from "../../../../../models/Meta/meta.model";
import { User } from "../../../../../models/User/user.model";

import { CompanyService } from "../../../../../services/CompanyService/company.service";
import { MetaService } from "../../../../../services/MetaService/meta.service";
import { UserService } from "../../../../../services/UserService/user.service";
import { AttachmentComponent } from "../../../../../shared/components/AttachmentComponent";
import ButtonComponent from "../../../../../shared/components/ButtonComponent";
import DropdownField from "../../../../../shared/components/DropdownField";
import InputField from "../../../../../shared/components/InputField";
import RadioGroup from "../../../../../shared/components/RadioGroup";
import { UIModal } from "../../../../../shared/components/UIModal";
import userRoles from "../../../../../shared/utils/userRoles";
import AuthContainer from "../../../../../store/containers/AuthContainer";
import { AuthReducerProps } from "../../../../../store/reducers/authReducer";
import { RegisterValidation } from "../../../../Auth/Register/registerValidation";
import "./hlUsersForm.scss";
import { HlUsersValidation } from "./hlUsersValidation";

interface HLUsersFormProps extends AuthReducerProps {
  visible: boolean;
  data?: ProfileModel;
  closeHandler: () => void;
}
interface FormProps {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  role: string;
  hasAccessToAllHfs: any;
  userCompanies: string;
  allowRead: string;
  status: string;
  password: string;
  confirmPassword: string;
}
const HLUsersForm = ({
  visible,
  data,
  userType,
  closeHandler,
}: HLUsersFormProps) => {
  const formRef = React.useRef<any>();
  const [companies, setCompanies] = useState<any>();
  const [roles, setRoles] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [initialValues, setInitialValues] = React.useState<ProfileModel>(new ProfileModel());
  const fetchCompanies = () => {
    CompanyService.listAllCompanies(
      {},
      (companies) => {
        const formatted = deserialize(CompanyMetaModel, companies);
        setCompanies(formatted);
      },
      () => { }
    );
  };

  useEffect(() => {
    fetchUserRoles()
  }, [])

  useEffect(() => {
    fetchCompanies();
    if (data) {
      data["allowRead"] = "allow"
      if (!data?.hasAccessToAllHfs && data?.userCompanies?.length) {
        let uc = [];
        data?.userCompanies.map((v, i) => {
          uc.push(v?.companyId);
        });
        data["allowRead"] = (data?.userCompanies[0].accessLevel == 'read_only') ? 'allow' : 'deny'
        data["userCompanies"] = uc;
      }
      // data["allowRead"] = "allow"
      setInitialValues(data);
    }
  }, [data]);

  const fetchUserRoles = () => {
    MetaService.rolesService(
      userType === "HL" ? "hl" : "hf",
      (data) => setRoles(data?.map((value) => ({ value, label: userRoles?.[value] || value?.replace("_", " ") }))),
      () => { },
      () => { }
    )
  }

  const handleSubmit = (values: any, helpers: FormikHelpers<any>) => {
    let uc = [];
    setLoading(true)
    if (!values["hasAccessToAllHfs"]) {
      values["userCompanies"]?.map((v, i) => {
        if (values["allowRead"] == "allow") {
          uc.push({
            companyId: v,
            accessLevel: "read_only",
          });
        } else {
          uc.push({
            companyId: v,
            accessLevel: "read_write",
          });
        }
      });
    }
    // values["role"] = "hl_admin";
    console.log(data)
    if (data) {
      UserService.updateUser(
        data?.id,
        { ...values, userCompanies: uc },
        () => {
          helpers.resetForm();
          setInitialValues(new ProfileModel())
          setLoading(false)
          closeHandler();
        },
        () => {
          setLoading(false)
        }
      );
    } else {
      UserService.createUser(
        { ...values, userCompanies: uc },
        () => {
          helpers.resetForm();
          setInitialValues(new ProfileModel())
          setLoading(false)
          closeHandler();
        },
        () => {
          setLoading(false)
        }
      );
    }
  };
  return (
    <UIModal
      visible={visible}
      title={data ? "Update New Hedge Legal User" : "Add New Hedge Legal User"}
      closeModal={() => {
        if (!loading) {
          formRef.current?.resetForm();
          setInitialValues(new ProfileModel())
          closeHandler()
        }
      }}
      noControl
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        innerRef={formRef}
        validationSchema={HlUsersValidation}
      >
        {({ setFieldValue, values, dirty, isValid, submitForm }) => {
          return (
            <Form>
              <div className="hlusers-form__container">
                <Row justify="space-between" className="mtp-4">
                  <Col span={11}>
                    <InputField
                      showLabel
                      placeholder={"First Name *"}
                      name="firstName"
                      value={values.firstName}
                      type="text"
                    />
                  </Col>
                  <Col span={11}>
                    <InputField
                      showLabel
                      placeholder={"Last Name *"}
                      name="lastName"
                      value={values.lastName}
                      type="text"
                    />
                  </Col>
                </Row>
                <div className="hlusers-form__field">
                  <InputField
                    showLabel
                    placeholder={"Email ID *"}
                    name="email"
                    value={values.email}
                    type="text"
                  />
                </div>
                <div className="hlusers-form__field">
                  <InputField
                    showLabel
                    placeholder={"Phone Number *"}
                    name="mobileNumber"
                    value={values.mobileNumber}
                    type="text"
                  />
                </div>
                <div className="hlusers-form__field">
                  <DropdownField
                    showLabel
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"Role"}
                    name="role"
                    options={roles}
                    showSearch={false}
                    value={values.role}
                    onChange={(value) => setFieldValue("role", value)}
                  />
                </div>
                {values.role !== "hl_admin" && <div className="hlusers-form__field">
                  <DropdownField
                    showLabel
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"Access Control"}
                    name="hasAccessToAllHfs"
                    options={[
                      { value: false, label: "Restricted Access" },
                      { value: true, label: "Full Access" },
                    ]}
                    showSearch={false}
                    value={values.hasAccessToAllHfs}
                    onChange={(value) =>
                      setFieldValue("hasAccessToAllHfs", value)
                    }
                  />
                </div>}
                {values.role !== "hl_admin" && !values?.hasAccessToAllHfs && (
                  <>
                    <div className="hlusers-form__field">
                      <DropdownField
                        showLabel
                        mode="multiple"
                        optionFilterProp="label"
                        placeholder={"Accessible HFund Companies"}
                        name="userCompanies"
                        options={companies}
                        showSearch={false}
                        value={values.userCompanies}
                        onChange={(value) => {
                          // let selected = []
                          // value.map((value, index)=> {
                          //   selected.push({
                          //     companyId: value,
                          //     accessLevel: "read_only"
                          //   })
                          // })
                          setFieldValue("userCompanies", value);
                        }}
                      />
                    </div>
                    {values.role !== "hl_admin" && <div className="hlusers-form__field">
                      {/* <div>Allow to read data from restricted companies</div> */}
                      <RadioGroup
                        options={[
                          { value: "allow", label: "Allow" },
                          { value: "deny", label: "Deny" },
                        ]}
                        title={"Allow to read data from restricted companies"}
                        name="allowRead"
                        value={values?.allowRead}
                        selectedHandler={(value) =>
                          setFieldValue("allowRead", value)
                        }
                      />
                    </div>}
                  </>
                )}

                <div className="hlusers-form__field">
                  <DropdownField
                    showLabel
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"Status"}
                    name="Status"
                    options={[
                      { value: "active", label: "Active" },
                      { value: "inactive", label: "Inactive" },
                    ]}
                    showSearch={false}
                    value={values.status}
                    onChange={(value) => setFieldValue("status", value)}
                  />
                </div>

                <div className="hlusers-form__field">
                  <InputField
                    showLabel
                    placeholder={"Password *"}
                    name="password"
                    value={values.password}
                    type="password"
                  />
                </div>
                <div className="hlusers-form__field">
                  <InputField
                    showLabel
                    placeholder={"Confirm Password *"}
                    name="confirmPassword"
                    value={values.confirmPassword}
                    type="password"
                  />
                </div>
                <div className="ui-modal__controllers">
                  <ButtonComponent onClick={() => {
                    setInitialValues(new ProfileModel());
                    closeHandler()
                  }} type="ghost">
                    Cancel
                  </ButtonComponent>
                  <ButtonComponent
                    htmlType={"submit"}
                    disabled={!isValid || !dirty}
                    loading={loading}
                    type="primary"
                    onClick={submitForm}
                  >
                    {data ? "Update" : "Create"}
                  </ButtonComponent>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </UIModal>
  );
};

export default AuthContainer(HLUsersForm)