import React, { FC, Fragment, useEffect, useState } from "react";
import { Topic } from "../../../../models/Topic/topic.model";
import CompanyPlaceholder from "../../../../assets/background-images/auth-bg.png";
import { Button, Col, Input, Popover, Row } from "antd";
import "./discussionCard.scss";
import moment from "moment";
import DiscussionComment from "./DiscussionComment";
import ListReply from "./ListReply";
import DiscussionBoardService from "../../../../services/DiscussionBoardService/DiscussionBoard.service";
import { Comment } from "../../../../models/Comment/comment.model";
import { Modal } from "antd";
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";
import { MoreOutlined } from "@ant-design/icons";
import DoxCoinsService from "../../../../services/DoxCoinsService/DoxCoins.service";
import LayoutContainer from "../../../../store/containers/layoutContainer";
import { LayoutReducerProps } from "../../../../store/reducers/layoutReducer";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import AuthContainer from "../../../../store/containers/AuthContainer";

interface DiscussionCardProps extends LayoutReducerProps, AuthReducerProps {
  topicId?: number | string;
  topic?: Topic;
  setTopic?: (topic: Topic) => void;
  showComments?: boolean;
  fetchTopics?: () => void;
  noUpdate?: boolean;
  searchResult?: boolean;
  type?: "explore" | "myTopic" | "myCompanyTopic";
}

const DiscussionCard: FC<DiscussionCardProps> = (props) => {
  const {
    topic,
    setTopic,
    showComments = false,
    fetchTopics,
    searchResult,
    topicId,
    noUpdate,
    type,
    user,
    setDoxCoin,
  } = props;

  const isHF = user?.role?.includes("hf");

  const { currentTopic, getTopic, TopicLikeOrDisLike, deleteTopics } =
    DiscussionBoardService();

  useEffect(() => {
    if (topicId) {
      getTopic(topicId?.toString());
    }
  }, [topicId]);

  const [comment, setComment] = useState("");

  const [reload, setReload] = useState(0);

  const { getTotalDoxCoins } = DoxCoinsService();

  const handleReload = () => {
    if (fetchTopics) {
      return fetchTopics();
    }
    setReload((cur) => cur + 1);
  };

  const { postComment, loading } = DiscussionBoardService();

  const handleShowComment = () => {
    setTopic && setTopic(topic);
  };

  const handleAddComment = async () => {
    if (!comment) return
    const data = Object.assign(new Comment(), {
      description: comment,
    });

    if (await postComment(topicId?.toString() ?? topic?.id?.toString(), data)) {
      setComment("");
      getTotalDoxCoins(setDoxCoin);
      handleReload();
    }
  };

  const handleUpdate = async (type: "like" | "dislike") => {
    if (await TopicLikeOrDisLike(topic?.id?.toString(), type)) {
      handleReload();
    }
  };

  const handleRemoveTopic = async (e) => {
    e.stopPropagation();
    // Modal.warning({
    //   title: `Are you sure you want to delete.`,
    //   okText: "Delete",
    //   closable: true,
    //   onOk: async (close) => {
    //     if (await deleteTopics(topic?.id)) {
    //       close();
    //       handleReload();
    //     }
    //   },
    // });
    if (await deleteTopics(currentTopic?.id)) {
      // close();
      setTopic(new Topic())
      handleReload();
    }
  };

  return (
    <div
      className={`discussion-card cursor-pointer`}
      onClick={() => {
        setTopic(topic);
      }}
    >
      {!searchResult && (
        <Row
          className="company-details"
          justify="space-between"
          gutter={[10, 0]}
        >
          <Col>
            <Row>
              <Col className="mr-5">
                <img src={CompanyPlaceholder} alt="" />
              </Col>
              <Col className="mr-5">
                <p className="company-name">
                  {topic?.companyName ?? currentTopic?.companyName}
                </p>
                <div className="date">
                  {moment(topic?.date).format("DD MMM, YYYY")}
                </div>
              </Col>
              <Col className="dot-container mr-5">
                <div className="dot" />
              </Col>
              <Col className="name">
                {topic?.userName ?? currentTopic?.userName}
              </Col>
            </Row>
          </Col>
          <Col onClick={(e) => e.stopPropagation()}>
            {/* {isHF && <i className="icon-close" onClick={handleRemoveTopic} />} */}
            {(!isHF || (isHF && (currentTopic)?.createdById === user?.id)) && type !== "explore" && (
              <Popover
                overlayClassName="delete-popover"
                placement="bottomRight"
                content={
                  <div
                    className="delete-topic cursor-pointer"
                    onClick={handleRemoveTopic}
                  >
                    <i className="icon-delete" /> Delete
                  </div>
                }
                trigger="click"
              >
                <MoreOutlined />
              </Popover>
            )}
          </Col>
        </Row>
      )}
      <p className="topic mb-2 text-underline">
        {topic?.description ?? currentTopic?.description}
      </p>
      {showComments ? (
        <ListReply topicId={topic?.id ?? currentTopic?.id} reload={reload} />
      ) : (
        <DiscussionComment
          topic={topic}
          comment={topic?.topComment}
          onShowCommentList={handleShowComment}
          reload={handleReload}
          searchResult={searchResult}
          noUpdate
        />
      )}
      {!noUpdate && (
        <Row
          justify="space-between"
        // className={disableDivider ? "mt-2" }
        >
          <Col span={12} className="control">
            <i
              className={`icon-like cursor-pointer ${topic?.youLike && "icon-selected"
                } mr-15`}
              onClick={(e) => {
                e.stopPropagation();
                handleUpdate("like");
              }}
            >
              &nbsp;{topic?.likes}
            </i>
            <i
              className={`icon-dislike cursor-pointer ${topic?.youDislike && "icon-selected"
                } `}
              onClick={(e) => {
                e.stopPropagation();
                handleUpdate("dislike");
              }}
            >
              &nbsp;{topic?.dislikes}
            </i>
          </Col>
          <Col span={12} className="comments text-right">
            {topic?.noOfComments} Comments
          </Col>
        </Row>
      )}
      {!searchResult && (
        <div className="post-comment" onClick={(e) => e.stopPropagation()}>
          <Input
            placeholder="Post your comment"
            value={comment}
            onChange={(e) => setComment(e?.target?.value)}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleAddComment();
              }
            }}
            suffix={
              <Fragment>
                {loading && <span
                  className={`posting-loader ${loading && "posting-loading"}`}
                >
                  Posting
                </span>}
                &nbsp;
                <i className="icon-Send" onClick={handleAddComment} />
              </Fragment>
            }
            className="post-comment mt-5"
          />
        </div>
      )}
    </div>
  );
};

export default AuthContainer(LayoutContainer(DiscussionCard));
