import { FileSearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Modal, Row } from "antd";
import { LayoutContextProps } from "antd/lib/layout/layout";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import AgreementKeyTermService from "../../../services/AgreementService/agreementKeyTerm.service";
import DoxCoinsService from "../../../services/DoxCoinsService/DoxCoins.service";
import { FundsService } from "../../../services/FundService/Fund.service";
import LayoutContainer from "../../../store/containers/layoutContainer";
import { LayoutReducerProps } from "../../../store/reducers/layoutReducer";
import "./keyTermModal.scss";

interface KeyTermModalProps extends LayoutReducerProps {
  title: string;
  visible: boolean;
  availDoxPoints?: boolean;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const KeyTermModal: FC<KeyTermModalProps> = (props) => {
  const {
    title,
    visible,
    availDoxPoints = false,
    onCancel,
    onConfirm,
    loading,
    setDoxCoin,
  } = props;

  const [useDoxCoin, setUseDoxCoin] = useState(false);

  const [totalDoxCoin, setTotalDoxCoin] = useState<number>(0);

  const [doxCoins, setDoxCoins] = useState(0);

  const { getKeyTermRequestCoins } = AgreementKeyTermService();

  const { getTotalDoxCoins } = DoxCoinsService()

  const getRequestKeyTermCoins = async () => {
    const service = await getKeyTermRequestCoins();
    if (service) {
      setDoxCoins(service?.requiredNoOfCoins);
    }
  };
  useEffect(() => {
    // getRequestKeyTermCoins();
    // getTotalDoxCoins(setTotalDoxCoin);
  }, []);

  const params = useParams<{ agreementId: string }>();

  const handleConfirm = () => {
    FundsService.requestKeyTerm(
      { redeem_dox_coins: useDoxCoin },
      params?.agreementId,
      (doxCoin) => {
        setDoxCoin(doxCoin);
        onCancel();
      }
    );
  };

  return (
    <Modal
      className="key-term-modal"
      title="Key Term Request"
      visible={visible}
      okText="Confirm"
      onCancel={onCancel}
      onOk={onConfirm}
      confirmLoading={loading}
    >
      <p className="key-term-modal--title-icon">
        <FileSearchOutlined />
      </p>
      <p className="key-term-modal--text">
        Request Key Terms for the agreement
      </p>
      <p className="key-term-modal--title">{title}</p>
      {/* {availDoxPoints && <div> */}
      {totalDoxCoin >= doxCoins && <Checkbox
        className="key-term-modal--check-box"
        checked={useDoxCoin}
        onChange={(e) => {
          setUseDoxCoin(e.target.checked);
        }}
      >
        Redeem {50} DoX coins to obtain this for free
      </Checkbox>}
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Button type="text" className="full-width" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col span={12}>
          <Button type="primary" className="full-width" onClick={handleConfirm}>
            Confirm
          </Button>
        </Col>
      </Row>
      {/* </div>
            } */}
    </Modal>
  );
};

export default LayoutContainer(KeyTermModal);
