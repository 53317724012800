import { Button, Col, Row } from "antd";
import { Formik, Form, FormikValues } from "formik";
import React, { FC } from "react";
import { Service } from "../../../../models/Service/service.model";
import DoxCoinsService from "../../../../services/DoxCoinsService/DoxCoins.service";
import InputField from "../../../../shared/components/InputField";

interface RedemptionFormProps {
  formValues?: Service;
  closeModalHandler: () => void;
  successModalHandler: (service: Service) => void;
}

const RedemptionForm: FC<RedemptionFormProps> = (props) => {
  const { formValues, successModalHandler, closeModalHandler } = props;

  const { loading, createRedeemedService, updateRedeemedService } =
    DoxCoinsService();

  const handleSubmit = async (values: Service) => {
    const service = Object.assign(new Service(), values);
    let updatedService;
    if (formValues) {
      updatedService = await updateRedeemedService(service);
    } else {
      updatedService = await createRedeemedService(service);
    }
    if (updatedService) {
      successModalHandler(updatedService);
    }
  };

  const formilProps = {
    initialValues: formValues ?? new Service(),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  };

  return (
    <Formik {...formilProps}>
      {({ values }) => (
        <Form className="redemption-form">
          <InputField
            showLabel
            value={values.name}
            name="name"
            type="text"
            placeholder="Action"
            disabled={formValues ? !formValues?.deletable : false}
          />
          <InputField
            showLabel
            value={values.requiredNoOfCoins}
            name="requiredNoOfCoins"
            type="text"
            placeholder="Dox Coins"
          />
          <Row className="mtp-6" gutter={[20, 20]}>
            <Col span={12}>
              <Button
                onClick={closeModalHandler}
                className="full-width"
                type="text"
              >
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button
                htmlType="submit"
                loading={loading}
                className="full-width"
                type="primary"
              >
                {formValues
                  ? loading
                    ? "Updating . . ."
                    : "Update"
                  : loading
                    ? "Create"
                    : "Create"}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default RedemptionForm;
