import { ProfileModel } from "../../models/HFund/hfund.model";
import { AUTHENTICATED, UNAUTHENTICATED, USER_TYPE, USER } from "../definitions/authConstants";

export const setAuthenticated = (user: ProfileModel) => {
    return {
        type: AUTHENTICATED,
        payload: {
            authenticated: true,
            user
        }
    }
}
export const setUnAuthenticated = () => {
    return {
        type: UNAUTHENTICATED,
        payload: {
            authenticated: false,
        }
    }
}
export const setUserType = (userType: string) => {
    return {
        type: USER_TYPE,
        payload: {
            userType: userType,
        }
    }
}
export const setUser = (user: ProfileModel) => {
    return {
        type: USER,
        payload: {
            user
        }
    }
}