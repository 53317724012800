import { HF_REPORTS_SPECIFIC_DOWNLOAD, HF_REPORTS_SPECIFIC_EXCEL, HL_REPORTS_SPECIFIC_EXCEL, HL_REPORTS_SPECIFIC_DOWNLOAD } from './../../routes/apiRoutes';
import { Report, ReportHistory, ReportResults, ReportsAgreement } from './../../models/Report/report.model';
import { deserialize } from "serializr"
import axiosInstance from "../../interceptor/axiosInstance"
import { HF_REPORTS, HF_REPORTS_AGREEMENTS, HF_REPORTS_HISTORY, HF_REPORTS_SPECIFIC, HF_REPORTS_SPECIFIC_RESULTS, HL_REPORTS, HL_REPORTS_AGREEMENTS, HL_REPORTS_COMPANIES, HL_REPORTS_HISTORY, HL_REPORTS_SPECIFIC, HL_REPORTS_SPECIFIC_RESULTS, } from "../../routes/apiRoutes"
import { CompanyModel } from '../../models/HFund/hfund.model';
import { Agreement } from '../../models/Agreements/agreement.model';
import { fileDownloader, fileDownloadFromUrl } from '../../shared/utils/fileDownloader';
import Notification from './../../shared/components/Notification';
import { NotificationTypes } from '../../enums/notificationTypes';
export class ReportsService {

    static fetchCompanies = async (
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const response = await axiosInstance.get(HL_REPORTS_COMPANIES)
            const data = deserialize(CompanyModel, response.data["companies"])
            onSuccess(data)
        } catch (error) {
            onError(error)
        } finally {
            onFinal()
        }
    }

    static fetchHFAgreements = async (
        params: {
            agreement_type_id: number | string,
            company_ids?: number[] | string[],
            name?: string,
            fund_ids?: number[],
            counter_party_ids?: number[],
            sortInfo?: {}
        },
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const response = await axiosInstance.get(HF_REPORTS_AGREEMENTS, { params })
            const data = deserialize(Agreement, response.data["agreements"]);
            onSuccess(data)
        } catch (error) {
            onError(error)
        } finally {
            onFinal()
        }
    }

    static fetchHLAgreements = async (
        params: {
            agreement_type_id: number | string,
            company_ids?: number[] | string[],
            name?: string,
            fund_ids?: number[],
            counter_party_ids?: number[],
            sortInfo?: {}
        },
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const response = await axiosInstance.get(HL_REPORTS_AGREEMENTS, { params })
            const data = deserialize(Agreement, response.data["agreements"]);
            onSuccess(data)
        } catch (error) {
            onError(error)
        } finally {
            onFinal()
        }
    }

    static createHFReports = async (
        params: {
            agreement_type_id: number,
            agreements?: "all",
            key_terms?: "all",
            agreement_ids?: number[],
            key_term_ids?: number[],
        },
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const payload = {
                report: params
            }
            const response = await axiosInstance.post(HF_REPORTS, payload)
            const data = response.data['report']
            onSuccess(data?.id)
        } catch (error) {
            onError(error)
        } finally {
            onFinal()
        }
    }

    static fetchHFReport = async (
        reportId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const response = await axiosInstance.get(HF_REPORTS_SPECIFIC.replace(":reportId", reportId))
            const data = deserialize(Report, response.data['report']) as Report[]
            onSuccess(data)
        } catch (error) {
            onError(error)
        } finally {
            onFinal()
        }
    }

    static fetchHLReport = async (
        reportId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const response = await axiosInstance.get(HL_REPORTS_SPECIFIC.replace(":reportId", reportId))
            const data = deserialize(Report, response.data['report']) as Report[]
            onSuccess(data)
        } catch (error) {
            onError(error)
        } finally {
            onFinal()
        }
    }


    static fetchHFReportData = async (
        key_term_group_id: string,
        reportId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const response = await axiosInstance.get(HF_REPORTS_SPECIFIC_RESULTS.replace(":reportId", reportId), {
                params: {
                    key_term_group_id
                }
            })
            const data = deserialize(ReportResults, response.data['key_terms']) as ReportResults[]
            onSuccess(data)
        } catch (error) {
            onError(error)
        } finally {
            onFinal()
        }
    }

    static fetchHLReportData = async (
        key_term_group_id: string,
        reportId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const response = await axiosInstance.get(HL_REPORTS_SPECIFIC_RESULTS.replace(":reportId", reportId), {
                params: {
                    key_term_group_id
                }
            })
            const data = deserialize(ReportResults, response.data['key_terms']) as ReportResults[]
            onSuccess(data)
        } catch (error) {
            onError(error)
        } finally {
            onFinal()
        }
    }

    static fetchHFReportHistory = async (
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const response = await axiosInstance.get(HF_REPORTS)
            const data = deserialize(ReportHistory, response.data['reports'])
            onSuccess(data)
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

    static createHLReports = async (
        params: {
            agreement_type_id: number,
            company_ids: number[],
            agreements?: "all",
            key_terms?: "all",
            companies?: "all",
            agreement_ids?: number[],
            key_term_ids?: number[],
        },
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const payload = {
                report: params
            }
            const response = await axiosInstance.post(HL_REPORTS, payload)
            const data = response.data['report']
            onSuccess(data?.id)
        } catch (error) {
            onError(error)
        } finally {
            onFinal()
        }
    }
    static fetchHLReportHistory = async (
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const response = await axiosInstance.get(HL_REPORTS)
            const data = deserialize(ReportHistory, response.data['reports'])
            onSuccess(data)
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

    static fetchHLReportNestedHistory = async (
        reportId: number,
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const response = await axiosInstance.get(HL_REPORTS_HISTORY.replace(':reportId', String(reportId)))
            const data = deserialize(ReportsAgreement, response.data['agreements'])
            onSuccess(data)
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

    static fetchHFReportNestedHistory = async (
        reportId: number,
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const response = await axiosInstance.get(HF_REPORTS_HISTORY.replace(':reportId', String(reportId)))
            const data = deserialize(ReportsAgreement, response.data['agreements'])
            onSuccess(data)
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

    static excelDownloadHF = async (
        reportId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const response = await axiosInstance.get(HF_REPORTS_SPECIFIC_EXCEL.replace(':reportId', String(reportId)))
            const url = response.data.url
            onSuccess(url)
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

    static excelDownloadHL = async (
        reportId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const response = await axiosInstance.get(HL_REPORTS_SPECIFIC_EXCEL.replace(':reportId', String(reportId)))
            const url = response.data.url
            onSuccess(url)
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

    static downloadHFReports = async (
        reportId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const response = await axiosInstance.get(HF_REPORTS_SPECIFIC_DOWNLOAD.replace(':reportId', String(reportId)))
            Notification({
                message: "Hedge Fund",
                description: "Download link sent to your email",
                type: NotificationTypes.SUCCESS,
            });
            onSuccess()
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

    static downloadHLReports = async (
        reportId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: Function
    ) => {
        try {
            const response = await axiosInstance.get(HL_REPORTS_SPECIFIC_DOWNLOAD.replace(':reportId', String(reportId)))
            Notification({
                message: "Hedge Legal",
                description: "Download link sent to your email",
                type: NotificationTypes.SUCCESS,
            });
            onSuccess()
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

}

