import { serializable, alias, object, list, primitive } from "serializr";
export class User {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("mobile_number", primitive()))
  mobileNumber?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("password", primitive()))
  password?: string;
}
export class UserCounterParties {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("user_id", primitive()))
  userId?: number;

  @serializable(alias("counter_party_id", primitive()))
  counterPartyId?: number;

  @serializable(alias("access_level", primitive()))
  accessLevel?: string;

  @serializable(alias("user", object(User)))
  user?: User;
}
