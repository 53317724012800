import { Divider } from "antd";
import React, { FC } from "react";
import { useHistory } from "react-router";
import MarkerIcon from "../../../../../assets/icons/marker.svg";
import StarIcon from "../../../../../assets/icons/star.svg";
import { NumberToFixed } from "../../../../../shared/utils/NumberUtil";
import "./cpCard.scss"
interface CPCardProps {
  cpLogo?: string;
  cpName: string;
  cpAddress: string;
  cpRating: any;
  cpReviews: number;
  cpProducts: any;
  onClick: () => void
}
const CPCard: FC<CPCardProps> = ({
  cpLogo,
  cpAddress,
  cpName,
  cpProducts,
  cpRating,
  cpReviews,
  onClick
}) => {
  const history = useHistory()
  return (
    <div className="cp-card__container" onClick={onClick}>
      <div className="cp-card-image__wrapper">
        <img src={cpLogo} />
      </div>
      <div className="cp-card-details__wrapper">
        <p className="cp__name">{cpName}</p>
        <p className="cp__address">
          <span>
            <img src={MarkerIcon} />
          </span>
          {cpAddress}
        </p>
        <div className="cp-rating-products__wrapper">
          {/* <div className="cp-rating-review_container">
            <p className="cp__rating">
              {NumberToFixed(cpRating)}
              <span>
                <img src={StarIcon} />
              </span>
            </p>
            <p className="cp__reviews">{cpReviews} Reviews</p>
          </div> */}
          {/* <Divider type="vertical" /> */}
          <div className="cp-products_container">
            <p>Products Available</p>
            <p className="cp-product">
              {cpProducts?.map((value, index) => (
                <span>
                  {value?.replace(/_/g, " ")}
                  {index !== cpProducts?.length - 1 && ", "}
                </span>
              ))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CPCard