import React, { FC, useState } from "react";
import "./cp.scss";
import { PlusOutlined } from "@ant-design/icons";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import { PageHeaderComponent } from "../../../../shared/components/PageHeader";
import { Col, Row } from "antd";
import faker from "faker";
import CPCard from "../../../HL/Home/CP";
import CPForm from "./CPForm";
import AuthContainer from "../../../../store/containers/AuthContainer";
import HFCPList from "../CP/CPList";
import { roles } from "../../../../shared/components/HOC/roles";
const HFCP: FC = (props: any) => {
  const [showCPForm, setFormVisibility] = useState(false);
  const [currentCP, setCurrentCP] = useState<any>(null);
  const [tabKey, setTabKey] = useState("1");
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState<string>('')

  return (
    <div className="hfcp-container">
      <PageHeaderComponent
        title="CPs / Brokers"
        showSearch
        onSearch={setSearchText}
        buttons={
          <>
            <ButtonComponent
              className="page-header-title__btn"
              type="primary"
              onClick={() => setFormVisibility(true)}
            >
              Suggest New CP
            </ButtonComponent>
          </>
        }
      />

      <div className="page-body">
        <CPForm
          visible={showCPForm}
          closeHandler={() => {
            setFormVisibility(false);
            setCurrentCP(undefined)
          }}
          data={currentCP}
        />
        <HFCPList searchText={searchText} />
      </div>
    </div>
  );
};
export default AuthContainer(HFCP);
