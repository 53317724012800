import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Radio, Row, Col, Checkbox } from "antd";
import Search from "antd/lib/input/Search";
import React, { useEffect, useState } from "react";
import ButtonComponent from "../ButtonComponent";
import "./table-filter-dropdown.scss";

interface TableFilterDropDownProps {
  checkboxValues: {
    label: string;
    value: number | string;
  }[];
  defaultSelected: any[];
  dataIndex: string;
  sortIndex: string
  confirm: Function;
  clearFilters: Function;
  onApply: Function;
  // setSelectedKeys: Function;
  // filters?: any;
  prefixCls?: string;
  onSort?: Function;
}

const TableFilterDropDown = (props: TableFilterDropDownProps) => {
  const {
    defaultSelected,
    clearFilters,
    checkboxValues,
    dataIndex,
    onSort,
    onApply,
    sortIndex,
    confirm
  } = props;

  const [isAscending, setIsAscending] = useState<boolean>();
  const [indeterminate, setIndeterminate] = useState<boolean>(true);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] = useState<any[]>([])

  useEffect(() => {
    // console.log(defaultSelected);
    setSelectedKeys(defaultSelected)
  }, [defaultSelected])

  const handleSortChange = (e) => {
    setIsAscending(e.target?.value);
  };

  console.log(isAscending)

  const onCheckboxChange = (list) => {
    setSelectedKeys(list);
    setIndeterminate(!!list?.length && list?.length < selectedKeys?.length);
    setCheckAll(list?.length === selectedKeys?.length);
  };

  const onCheckAllChange = (e) => {
    setSelectedKeys(
      e.target.checked ? checkboxValues?.map((val) => val?.value) : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  return (
    <div className="table-filters__container">
      <Row>
        <Radio.Group value={isAscending} onChange={handleSortChange}>
          <Radio.Button value={true}>
            <ArrowDownOutlined /> Sort A - Z
          </Radio.Button>
          <Radio.Button value={false}>
            <ArrowUpOutlined /> Sort Z - A
          </Radio.Button>
        </Radio.Group>
      </Row>
      {/* <Row>
        <Search
          placeholder="Search"
          onChange={(e) => setSearchText(e.target?.value)}
          enterButton
        />
      </Row> */}
      {checkboxValues?.length ? (
        <Row className="check-box__container">
          <Col className="check-box--groups">
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              Check all
            </Checkbox>
            <Checkbox.Group
              value={selectedKeys}
              options={checkboxValues?.map(val => { return { ...val, label: val?.label?.replaceAll("_", " ") } })}
              onChange={onCheckboxChange}
            />
          </Col>
        </Row>
      ) : null}
      <Row justify="space-between" className="table-filter__container">
        <Col>
          <ButtonComponent
            type={"default"}
            onClick={() => {
              onSort(sortIndex, null);
              onApply(dataIndex, null);
              onCheckboxChange(null)
              confirm()
            }}
            className="table-filter--action"
          >
            Clear
          </ButtonComponent>
        </Col>
        <Col>
          <ButtonComponent
            type={"primary"}
            onClick={() => {
              const order =
                isAscending === true
                  ? "asc"
                  : isAscending === false
                    ? "desc"
                    : undefined;
              onSort && onSort(sortIndex, order);
              onApply(dataIndex, selectedKeys, { key: sortIndex, order })
              confirm()
            }}
            className="table-filter--action"
          >
            Apply
          </ButtonComponent>
        </Col>
      </Row>
    </div>
  );
};

export default TableFilterDropDown;
