import { Form, Row, Col, Checkbox } from "antd";
import { Formik, Field } from "formik";
import React, { useEffect, useState } from "react";
import { AttachmentComponent } from "../../../../../../../shared/components/AttachmentComponent";
import { UIModal } from "../../../../../../../shared/components/UIModal";
import Notification from "../../../../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../../../../enums/notificationTypes";
import { HFAgreementService } from "../../../../../../../services/AgreementService/agreement.service";
import {
  Agreement,
  Amendment,
} from "../../../../../../../models/Agreements/agreement.model";
import moment from "moment";
import DatePickerComponent from "../../../../../../../shared/components/DatePickerComponent";
import InputField from "../../../../../../../shared/components/InputField";
import { ConfirmationAlert } from "../../../../../../../shared/components/ConfirmationAlert";
import ButtonComponent from "../../../../../../../shared/components/ButtonComponent";
import UppyFileUploader from "../../../../../../../shared/components/UppyFileUploader";

interface SwapAgreementForm {
  visible: boolean;
  agreement: Agreement;
  closeHandler: (agreement?: any) => void;
  onAgreementUpdate: Function
}

const SwapAgreementForm = (props: SwapAgreementForm) => {
  const { visible, closeHandler, agreement, onAgreementUpdate } = props;

  const [showDelete, setDeleteVisibility] = useState(false);
  const [swapAgreementForm, setSwapAgreementForm] = useState<Amendment>(
    new Amendment()
  );
  const [amendmentFileName, setAmendmentFileName] = useState<string>('');
  const [amendmentUploadUrl, setAmendmentUploadUrl] = useState<string>('');

  const onSubmit = (values: any, resetForm: Function) => {
    if (amendmentFileName) {
      values.document = amendmentFileName;
      values.isOriginal = true;
    }
    HFAgreementService.createAmendment(
      values,
      agreement?.id?.toString(),
      (response) => {
        setDeleteVisibility(true)
        onAgreementUpdate()
        resetForm();
        setAmendmentFileName('');
        setAmendmentUploadUrl('');
      },
      (error) => {
        //console.log(error);
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to add document",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={swapAgreementForm}
        onSubmit={() => { }}
      >
        {({ setFieldValue, values, resetForm, isValid }) => {
          return (
            <UIModal
              className="amendment-form__modal"
              visible={visible}
              closeModal={closeHandler}
              title={
                "Are your sure you want to replace the current version of the agreement?"
              }
              submitProps={{
                text: "Proceed",
                clickHandler: () => {
                  onSubmit(values, resetForm);
                },
              }}
              cancelProps={{
                text: "Cancel",
                clickHandler: () => {
                  resetForm();
                  closeHandler();
                  setAmendmentFileName('');
                  setAmendmentUploadUrl('');
                },
              }}
            >
              <Form>
                <div className="amendment-form__container">
                  <Row>
                    <Col sm={24} className="amendment-form__field">
                      <span>
                        By doing so, any key term tags to the existing PDF will
                        be deleted, The key terms however will still be stored
                        and should be updated accordingly and tagged again
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      sm={24}
                      className="amendment-form__field is-fully-executed"
                    >
                      <Field name="isFullyExecuted">
                        {({ field }) => (
                          <Checkbox {...field}>
                            Agreement is fully executed
                          </Checkbox>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      sm={24}
                      className="amendment-form__field amendment-form__field--file-upload"
                    >
                      <Field name="document" as="file-upload">
                        {/*<AttachmentComponent
                          accept="application/pdf"
                          onUpload={(file) => {
                            setFieldValue("isOriginal", true);
                            setFieldValue("document", file);
                          }}
                          title="Drag & Drop / Click here to upload PDF File"
                        />*/}
                        <UppyFileUploader setFileUploadUrl={setAmendmentUploadUrl}
                          setFileUploadName={setAmendmentFileName}
                          fileUploadUrl={amendmentUploadUrl}
                        />
                      </Field>
                    </Col>
                  </Row>
                </div>
              </Form>
            </UIModal>
          );
        }}
      </Formik>

      <ConfirmationAlert
        closeHandler={() => {
          setDeleteVisibility(false)
          closeHandler();
        }}
        visible={showDelete}
        type="success"
        customButtons={
          <div className="swap-amendment--buttons">
            <ButtonComponent
              onClick={() => {
                setDeleteVisibility(false)
                closeHandler();
              }}
              type="ghost"
            >
              No
            </ButtonComponent>

            <ButtonComponent
              onClick={() => {
                setDeleteVisibility(false)
                closeHandler(true);
              }}
              type="primary"
            >
              Yes
            </ButtonComponent>
          </div>
        }
        cancelHandler={() => {
          setDeleteVisibility(false)
          closeHandler();
        }}
        title={"Agreement replaced successfully"}
        description="Would you like to edit the Agreement information (Date of Agreement, Name of Agreement, etc) or Key terms?"
      />
    </>
  );
};

export default SwapAgreementForm;
