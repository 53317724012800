import { serializable, alias, primitive, object } from 'serializr';

export class Faq {

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('title', primitive()))
    title?: string;

    @serializable(alias('description', primitive()))
    descrption?: string;

}