import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { setAuthenticated, setUnAuthenticated, setUser, setUserType } from "../actions/auth";

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        authenticated: state.auth.authenticated,
        user: state.auth.user,
        userType: state.auth.userType,
        ...ownProps
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    setAuthenticated,
    setUnAuthenticated,
    setUserType,
    setUser,
}, dispatch);

const AuthContainer = (component: any) => {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}

export default AuthContainer;