import { PaginationMeta } from './../../models/Meta/pagination-meta.model';
import {
  AGREEMENT_TYPES,
  APP_AGREEMENT_STATUS,
  HL_AGREEMENTS_AMENDMENT,
  HL_AGREEMENTS_ANCILLARY,
  HL_AGREEMENTS_ANCILLARY_EXPORT,
  HL_AGREEMENTS_DOWNLOAD_AMENDMENTS,
  HL_AGREEMENTS_EXPORT,
  HL_AGREEMENTS_RELATED,
  HL_AGREEMENTS_RELATED_EXPORT,
} from "./../../routes/apiRoutes";
import { KeyContact } from "../../models/Meta/key-contact.model";
import {
  HF_AGREEMENTS_AMENDMENT,
  HF_AGREEMENTS_ANCILLARY,
  STUB_BASE_URL,
  HF_AGREEMENTS_RELATED,
  KEY_CONTACT_TYPE_AND_ID,
  HF_AGREEMENTS_EXPORT,
  HF_AGREEMENTS_ANCILLARY_EXPORT,
  HF_AGREEMENTS_RELATED_EXPORT,
  HL_AGREEMENTS,
} from "../../routes/apiRoutes";
import { deserialize, serialize } from "serializr";
import { generatePath } from "react-router";
import axiosInstance from "../../interceptor/axiosInstance";
import { HFUND_COMPANIES, HF_AGREEMENTS } from "../../routes/apiRoutes";
import {
  Agreement,
  AncillaryDocuments,
  Amendment,
  RelatedAgreements,
  AgreementType,
} from "../../models/Agreements/agreement.model";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
import moment from "moment";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { fileDownloader } from "../../shared/utils/fileDownloader";
import { LocalStorage } from "../../shared/utils/localStorageHelpers";

export class HFAgreementService {
  static fetchAgreements(
    params: {
      search_text?: string;
      fund_ids?: string[];
      counter_party_ids?: string[] | number[];
      agreement_type_ids?: string[];
      dates?: string[];
      tiers?: string[];
      agreement_status_ids?: string[];
      progress_percents?: string[];
      sitting_with?: string[];
      order_by?: string;
      company_id?: string;
      order_direction?: string;
      page?: number;
      per_page?: number;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(HF_AGREEMENTS);
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        const data = deserialize(Agreement, response.data["agreements"]);
        const pagination = deserialize(PaginationMeta, response.data["meta"]);
        onSuccess(data, pagination);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchSingleAgreements(
    agreementId: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(HF_AGREEMENTS + "/" + agreementId);
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(Agreement, response.data["agreement"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createAgreement(
    agreement: Agreement,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(HF_AGREEMENTS);
    const payload = {
      agreement: serialize(Agreement, agreement),
    };
    axiosInstance
      .post(API_URL, payload)
      .then((response) => {
        const data = deserialize(Agreement, response.data["agreement"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateAgreement(
    agreement: Agreement,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(HF_AGREEMENTS + "/" + agreement.id);
    const payload = {
      agreement: serialize(Agreement, agreement),
    };
    //console.log("Sending update req");

    axiosInstance
      .put(API_URL, payload)
      .then((response) => {
        const data = deserialize(Agreement, response.data["agreement"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteAgreement(
    agreement: Agreement,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(HF_AGREEMENTS + "/" + agreement.id);
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchAncillaryDocuments(
    agreementId,
    params: {
      search_text?: string;
      ancillary_document_status_ids?: string[];
      ancillary_document_status_progress_percents?: string[];
      order_by?: string;
      order_direction?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HF_AGREEMENTS_ANCILLARY.replace(":agreementId", agreementId)
    );
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        const data = deserialize(
          AncillaryDocuments,
          response.data["ancillary_documents"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchAncillaryDocumentDetails(
    agreementId,
    ancillaryId,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HF_AGREEMENTS_ANCILLARY.replace(":agreementId", agreementId)
    );
    axiosInstance
      .get(API_URL + `/${ancillaryId}`)
      .then((response) => {
        const data = deserialize(
          AncillaryDocuments,
          response.data["ancillary_document"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createAncillaryDocument(
    document: AncillaryDocuments,
    agreementId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HF_AGREEMENTS_ANCILLARY.replace(":agreementId", agreementId)
    );
    //console.log(document);

    const payload = {
      ancillary_document: serialize(AncillaryDocuments, document),
    };
    //console.log(payload);

    axiosInstance
      .post(API_URL, convertJSONToFormData(payload))
      .then((response) => {
        const data = deserialize(
          AncillaryDocuments,
          response.data["ancillary_document"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateAncillaryDocument(
    document: AncillaryDocuments,
    agreementId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HF_AGREEMENTS_ANCILLARY.replace(":agreementId", agreementId) +
      "/" +
      document.id
    );
    const payload = {
      ancillary_document: serialize(AncillaryDocuments, document),
    };
    axiosInstance
      .put(API_URL, payload)
      .then((response) => {
        const data = deserialize(
          AncillaryDocuments,
          response.data["ancillary_document"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteAncillaryDocument(
    document: AncillaryDocuments,
    agreement: Agreement,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HF_AGREEMENTS_ANCILLARY?.replace(
        ":agreementId",
        agreement?.id?.toString()
      ) +
      "/" +
      document?.id
    );
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchAmendments(
    agreementId,
    params: {
      is_archived: boolean;
      dates?: string[];
      order_by?: string;
      order_direction?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HF_AGREEMENTS_AMENDMENT.replace(":agreementId", agreementId)
    );
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        const data = deserialize(Amendment, response.data["amendments"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createAmendment(
    document: Amendment,
    agreementId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HF_AGREEMENTS_AMENDMENT.replace(":agreementId", agreementId)
    );
    const payload = {
      amendment: serialize(Amendment, document),
    };
    axiosInstance
      .post(API_URL, convertJSONToFormData(payload))
      .then((response) => {
        const data = deserialize(Amendment, response.data["amendment"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchRelatedAgreements(
    agreement: Agreement,
    params: {
      unrelated?: boolean;
      search_text?: string;
      funds_ids?: string[];
      counter_party_ids?: string[];
      agreement_type_ids?: string[];
      agreement_dates?: string[];
      order_by?: string;
      order_direction?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HF_AGREEMENTS_RELATED.replace(":agreementId", agreement?.id?.toString())
    );
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        const data = deserialize(
          RelatedAgreements,
          response.data["related_agreements"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createRelatedAgreements(
    agreement: Agreement,
    relatedAgreementIds: number[],
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HF_AGREEMENTS_RELATED.replace(":agreementId", agreement?.id?.toString())
    );
    const payload = {
      agreement_ids: relatedAgreementIds,
    };
    axiosInstance
      .post(API_URL, payload)
      .then((response) => {
        Notification({
          message: "Hedge Fund",
          description: "Agreement(s) added",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteRelatedAgreements(
    agreement: Agreement,
    relatedAgreementId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HF_AGREEMENTS_RELATED.replace(":agreementId", agreement?.id?.toString()) +
      "/" +
      relatedAgreementId
    );
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadAgreements(
    params: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    if (params?.all)
      delete params.all
    const API_URL = generatePath(
      // STUB_BASE_URL +
      HF_AGREEMENTS + "/download"
    );
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadAmendments(
    agreementId,
    {
      is_archived,
      date = moment(new Date()).format("YYYY-MM-DD"),
    }: {
      is_archived: boolean;
      date?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      // STUB_BASE_URL +
      HF_AGREEMENTS_AMENDMENT.replace(":agreementId", agreementId) + "/download"
    );
    axiosInstance
      .get(API_URL, { params: { is_archived, date } })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadAncillaryDocuments(
    agreementId,
    {
      date = moment(new Date()).format("YYYY-MM-DD"),
    }: {
      date?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      // STUB_BASE_URL +
      HF_AGREEMENTS_ANCILLARY.replace(":agreementId", agreementId) + "/download"
    );
    axiosInstance
      .get(API_URL, { params: { date } })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadRelatedDocuments(
    agreementId,
    params: {
      search_text?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      // STUB_BASE_URL +
      HF_AGREEMENTS_RELATED.replace(":agreementId", agreementId) + "/download"
    );
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchKeyConatcts(
    params: {
      contactableType: "Agreement" | "AncillaryDocument";
      contactableId: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const { contactableType, contactableId } = params;
    const API_URL = generatePath(
      // STUB_BASE_URL +
      KEY_CONTACT_TYPE_AND_ID.replace(
        ":contactableType",
        contactableType
      ).replace(":contactableId", contactableId)
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(KeyContact, response.data["key_contacts"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createKeyConatct(
    params: {
      contactableType: "Agreement" | "AncillaryDocument";
      contactableId: string;
    },
    keyContact: KeyContact,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const { contactableType, contactableId } = params;
    const API_URL = generatePath(
      // STUB_BASE_URL +
      KEY_CONTACT_TYPE_AND_ID.replace(
        ":contactableType",
        contactableType
      ).replace(":contactableId", contactableId)
    );
    const payload = {
      key_contact: serialize(KeyContact, keyContact),
    };
    axiosInstance
      .post(API_URL, payload)
      .then((response) => {
        const data = deserialize(KeyContact, response.data["key_contacts"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteKeyConatcts(
    params: {
      contactableType: "Agreement" | "AncillaryDocument";
      contactableId: string;
      keyContactId: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const { contactableType, contactableId, keyContactId } = params;
    const API_URL = generatePath(
      // STUB_BASE_URL +
      KEY_CONTACT_TYPE_AND_ID.replace(
        ":contactableType",
        contactableType
      ).replace(":contactableId", contactableId) +
      "/" +
      keyContactId
    );
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static exportAgreements(
    params: {
      search_text?: string;
      fund_ids?: string[];
      counter_party_ids?: string[];
      agreement_type_ids?: string[];
      dates?: string[];
      tiers?: string[];
      agreement_status_ids?: string[];
      progress_percents?: string[];
      sitting_with?: string[];
      order_by?: string;
      order_direction?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(HF_AGREEMENTS_EXPORT, { params, responseType: "arraybuffer" })
      .then((response) => {
        fileDownloader(response?.data, "Agreements.xlsx");
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static exportAncillaryDocuments(
    agreementId,
    agreementName,
    params: {
      search_text?: string;
      ancillary_document_status_ids?: string[];
      ancillary_document_status_progress_percents?: string[];
      order_by?: string;
      order_direction?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HF_AGREEMENTS_ANCILLARY_EXPORT.replace(":agreementId", agreementId)
    );
    axiosInstance
      .get(API_URL, { params, responseType: "arraybuffer" })
      .then((response) => {
        fileDownloader(
          response?.data,
          `${agreementName}_ancillary_documents.xlsx`
        );
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static exportRelatedAgreements(
    agreement: Agreement,
    params: {
      unrelated?: boolean;
      search_text?: string;
      funds_ids?: string[];
      counter_party_ids?: string[];
      agreement_type_ids?: string[];
      agreement_dates?: string[];
      order_by?: string;
      order_direction?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HF_AGREEMENTS_RELATED_EXPORT.replace(
        ":agreementId",
        agreement?.id?.toString()
      )
    );
    axiosInstance
      .get(API_URL, { params, responseType: "arraybuffer" })
      .then((response) => {
        fileDownloader(
          response?.data,
          `${agreement?.name}_related_agreements.xlsx`
        );
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadSingleAmendment(
    agreementId,
    amendmentId,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HF_AGREEMENTS_AMENDMENT.replace(":agreementId", agreementId)
    );
    axiosInstance
      .get(API_URL + `/${amendmentId}`)
      .then((response) => {
        const data = deserialize(Amendment, response.data["amendment"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
}

export class HLAgreementService {
  static fetchAgreements(
    params: {
      search_text?: string;
      fund_ids?: string[];
      counter_party_ids?: string[];
      agreement_type_ids?: string[];
      dates?: string[];
      tiers?: string[];
      agreement_status_ids?: string[];
      progress_percents?: string[];
      sitting_with?: string[];
      is_complete?: boolean;
      order_by?: string;
      order_direction?: string;
      company_id?: string;
      page?: number;
      per_page?: number;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(HL_AGREEMENTS);
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        const data = deserialize(Agreement, response.data["agreements"]);
        const pagination = deserialize(PaginationMeta, response.data["meta"]);
        onSuccess(data, pagination);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchSingleAgreements(
    agreementId: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(HL_AGREEMENTS + "/" + agreementId);
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(Agreement, response.data["agreement"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }



  static createAgreement(
    agreement: Agreement,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(HL_AGREEMENTS);
    const payload = {
      agreement: serialize(Agreement, agreement),
    };
    axiosInstance
      .post(API_URL, payload)
      .then((response) => {
        //console.log(response);

        const data = deserialize(Agreement, response.data["agreement"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateAgreement(
    agreement: Agreement,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(HL_AGREEMENTS + "/" + agreement.id);
    const payload = {
      agreement: serialize(Agreement, agreement),
    };
    //console.log("Sending update req");

    axiosInstance
      .put(API_URL, payload)
      .then((response) => {
        const data = deserialize(Agreement, response.data["agreement"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteAgreement(
    agreement: Agreement,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(HL_AGREEMENTS + "/" + agreement.id);
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchAncillaryDocuments(
    agreementId,
    params: {
      search_text?: string;
      ancillary_document_status_ids?: string[];
      ancillary_document_status_progress_percents?: string[];
      order_by?: string;
      order_direction?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HL_AGREEMENTS_ANCILLARY.replace(":agreementId", agreementId)
    );
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        const data = deserialize(
          AncillaryDocuments,
          response.data["ancillary_documents"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchAncillaryDocumentDetails(
    agreementId,
    ancillaryId,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HL_AGREEMENTS_ANCILLARY.replace(":agreementId", agreementId)
    );
    axiosInstance
      .get(API_URL + `/${ancillaryId}`)
      .then((response) => {
        const data = deserialize(
          AncillaryDocuments,
          response.data["ancillary_document"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createAncillaryDocument(
    document: AncillaryDocuments,
    agreementId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HL_AGREEMENTS_ANCILLARY.replace(":agreementId", agreementId)
    );
    //console.log(document);

    const payload = {
      ancillary_document: serialize(AncillaryDocuments, document),
    };
    //console.log(payload);

    axiosInstance
      .post(API_URL, convertJSONToFormData(payload))
      .then((response) => {
        const data = deserialize(
          AncillaryDocuments,
          response.data["ancillary_document"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateAncillaryDocument(
    document: AncillaryDocuments,
    agreementId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HL_AGREEMENTS_ANCILLARY.replace(":agreementId", agreementId) +
      "/" +
      document.id
    );
    const payload = {
      ancillary_document: serialize(AncillaryDocuments, document),
    };
    axiosInstance
      .put(API_URL, payload)
      .then((response) => {
        const data = deserialize(
          AncillaryDocuments,
          response.data["ancillary_document"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteAncillaryDocument(
    document: AncillaryDocuments,
    agreement: Agreement,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HL_AGREEMENTS_ANCILLARY?.replace(
        ":agreementId",
        agreement?.id?.toString()
      ) +
      "/" +
      document?.id
    );
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchAmendments(
    agreementId,
    params: {
      is_archived: boolean;
      dates?: string[];
      order_by?: string;
      order_direction?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HL_AGREEMENTS_AMENDMENT.replace(":agreementId", agreementId)
    );
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        const data = deserialize(Amendment, response.data["amendments"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createAmendment(
    document: Amendment,
    agreementId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HL_AGREEMENTS_AMENDMENT.replace(":agreementId", agreementId)
    );
    const payload = {
      amendment: serialize(Amendment, document),
    };
    axiosInstance
      .post(API_URL, convertJSONToFormData(payload))
      .then((response) => {
        const data = deserialize(Amendment, response.data["amendment"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchRelatedAgreements(
    agreement: Agreement,
    params: {
      unrelated?: boolean;
      search_text?: string;
      funds_ids?: string[];
      counter_party_ids?: string[];
      agreement_type_ids?: string[];
      agreement_dates?: string[];
      order_by?: string;
      order_direction?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HL_AGREEMENTS_RELATED.replace(":agreementId", agreement?.id?.toString())
    );
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        const data = deserialize(
          RelatedAgreements,
          response.data["related_agreements"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createRelatedAgreements(
    agreement: Agreement,
    relatedAgreementIds: number[],
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HL_AGREEMENTS_RELATED.replace(":agreementId", agreement?.id?.toString())
    );
    const payload = {
      agreement_ids: relatedAgreementIds,
    };
    axiosInstance
      .post(API_URL, payload)
      .then((response) => {
        Notification({
          message: "Hedge Fund",
          description: "Agreement(s) added",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteRelatedAgreements(
    agreement: Agreement,
    relatedAgreementId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HL_AGREEMENTS_RELATED.replace(":agreementId", agreement?.id?.toString()) +
      "/" +
      relatedAgreementId
    );
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadAgreements(
    params: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    if (params?.all)
      delete params.all
    const API_URL = generatePath(
      // STUB_BASE_URL +
      HL_AGREEMENTS + "/download"
    );
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadAmendments(
    agreementId,
   
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      // STUB_BASE_URL +
      HL_AGREEMENTS_DOWNLOAD_AMENDMENTS.replace(":agreementId", agreementId)
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadAncillaryDocuments(
    agreementId,
    {
      date = moment(new Date()).format("YYYY-MM-DD"),
    }: {
      date?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      // STUB_BASE_URL +
      HL_AGREEMENTS_ANCILLARY.replace(":agreementId", agreementId) + "/download"
    );
    axiosInstance
      .get(API_URL, { params: { date } })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadRelatedDocuments(
    agreementId,
    params: {
      search_text?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      // STUB_BASE_URL +
      HL_AGREEMENTS_RELATED.replace(":agreementId", agreementId) + "/download"
    );
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchKeyConatcts(
    params: {
      contactableType: "Agreement" | "AncillaryDocument";
      contactableId: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const { contactableType, contactableId } = params;
    const API_URL = generatePath(
      // STUB_BASE_URL +
      KEY_CONTACT_TYPE_AND_ID.replace(
        ":contactableType",
        contactableType
      ).replace(":contactableId", contactableId)
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(KeyContact, response.data["key_contacts"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createKeyConatct(
    params: {
      contactableType: "Agreement" | "AncillaryDocument";
      contactableId: string;
    },
    keyContact: KeyContact,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const { contactableType, contactableId } = params;
    const API_URL = generatePath(
      // STUB_BASE_URL +
      KEY_CONTACT_TYPE_AND_ID.replace(
        ":contactableType",
        contactableType
      ).replace(":contactableId", contactableId)
    );
    const payload = {
      key_contact: keyContact,
    };
    axiosInstance
      .post(API_URL, payload)
      .then((response) => {
        const data = deserialize(KeyContact, response.data["key_contacts"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteKeyConatcts(
    params: {
      contactableType: "Agreement" | "AncillaryDocument";
      contactableId: string;
      keyContactId: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const { contactableType, contactableId, keyContactId } = params;
    const API_URL = generatePath(
      // STUB_BASE_URL +
      KEY_CONTACT_TYPE_AND_ID.replace(
        ":contactableType",
        contactableType
      ).replace(":contactableId", contactableId) +
      "/" +
      keyContactId
    );
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static exportAgreements(
    params: {
      search_text?: string;
      fund_ids?: string[];
      counter_party_ids?: string[];
      agreement_type_ids?: string[];
      dates?: string[];
      tiers?: string[];
      agreement_status_ids?: string[];
      progress_percents?: string[];
      sitting_with?: string[];
      order_by?: string;
      order_direction?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(HL_AGREEMENTS_EXPORT, { params, responseType: "arraybuffer" })
      .then((response) => {
        fileDownloader(response?.data, "Agreements.xlsx");
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static exportAncillaryDocuments(
    agreementId,
    agreementName,
    params: {
      search_text?: string;
      ancillary_document_status_ids?: string[];
      ancillary_document_status_progress_percents?: string[];
      order_by?: string;
      order_direction?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HL_AGREEMENTS_ANCILLARY_EXPORT.replace(":agreementId", agreementId)
    );
    axiosInstance
      .get(API_URL, { params, responseType: "arraybuffer" })
      .then((response) => {
        fileDownloader(
          response?.data,
          `${agreementName}_ancillary_documents.xlsx`
        );
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static exportRelatedAgreements(
    agreement: Agreement,
    params: {
      unrelated?: boolean;
      search_text?: string;
      funds_ids?: string[];
      counter_party_ids?: string[];
      agreement_type_ids?: string[];
      agreement_dates?: string[];
      order_by?: string;
      order_direction?: string;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HL_AGREEMENTS_RELATED_EXPORT.replace(
        ":agreementId",
        agreement?.id?.toString()
      )
    );
    axiosInstance
      .get(API_URL, { params, responseType: "arraybuffer" })
      .then((response) => {
        fileDownloader(
          response?.data,
          `${agreement?.name}_related_agreements.xlsx`
        );
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadSingleAmendment(
    agreementId,
    amendmentId,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      HL_AGREEMENTS_AMENDMENT.replace(":agreementId", agreementId)
    );
    axiosInstance
      .get(API_URL + `/${amendmentId}`)
      .then((response) => {
        const data = deserialize(Amendment, response.data["amendment"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteAmendment(
    amendmentIds: string[],
    agreementId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
     const API_URL = generatePath(
      HL_AGREEMENTS_AMENDMENT.replace(':agreementId', agreementId));
    axiosInstance
      .delete(API_URL, { data:{amendment: { amendment_ids: amendmentIds} } })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
}

const isAdmin = () => {
  const user = LocalStorage.getItem("USER");
  return user?.role?.includes("hl");
};

export default class AgreementService {
  static async getAgreementTypes(
    params: {
      company_id?: string | number
      order_by?: "recently_viewed";
      order_direction?: "asc" | "dsc";
      page?: number;
      per_page?: number;
    },
    success: (agreementTypes: AgreementType[]) => void
  ) {
    try {
      const { data } = await axiosInstance.get(
        AGREEMENT_TYPES?.replace(":userRole", isAdmin() ? "hl" : "hf"),
        {
          params,
        }
      );
      const agreementTypes = deserialize(
        AgreementType,
        data?.agreement_types as any[]
      );
      success(agreementTypes);
    } catch (error) { }
  }

  static async getAgreementStatus(
    success: (agreementTypes: AgreementType[]) => void,
    params?: {}
  ) {
    try {
      const { data } = await axiosInstance.get(
        APP_AGREEMENT_STATUS?.replace(":userRole", isAdmin() ? "hl" : "hf"),
        { params }
      );
      const agreementTypes = deserialize(
        AgreementType,
        data?.agreement_statuses as any[]
      );
      success(agreementTypes);
    } catch (error) { }
  }
  
}
