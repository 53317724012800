
import {serializable, alias, object, list, primitive} from 'serializr';

export class DoxCoinTransaction { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('action', primitive()))
	action?: string;

	@serializable(alias('action_type', primitive()))
	actionType?: string;

	@serializable(alias('points', primitive()))
	points?: string;

	@serializable(alias('dox_coins', primitive()))
	doxCoins?: number;

	@serializable(alias('date', primitive()))
	date?: string;

}