import React, { Fragment, useEffect, useState } from "react";
import { VideoModel } from "../../../../models/Video/video.model";
import { HelpService } from "../../../../services/HelpService/helpService.service";
import { ConfirmationAlert } from "../../../../shared/components/ConfirmationAlert";
import EmptyState from "../../../../shared/components/EmptyState";
import FaqVideo from "../../../../shared/components/FaqVideo";
import Loader from "../../../../shared/components/Loader";
import FaqForm from "../Faq/FaqForm";
import "./video.scss"
import VideoForm from "./VideoForm";

interface VideoProps {
    videoFormVisible: boolean
    isAdmin: boolean
    setVideoFormVisible: (visiblity: boolean, video?: VideoModel) => void
}

const Video = (props: VideoProps) => {

    const {
        isAdmin,
        videoFormVisible,
        setVideoFormVisible,
    } = props

    const [selectedVideoData, setSelectedVideoData] = useState<VideoModel>(new VideoModel())
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false)
    const [videos, setVideos] = useState<VideoModel[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)

    useEffect(() => {
        fetchVideos()
    }, [])

    const fetchVideos = () => {
        if (isAdmin) {
            fetchHLVideos()
        } else {
            fetchHFVideos()
        }
    }

    const fetchHLVideos = () => {
        setLoading(true)
        HelpService.fetchHLVideos(
            (videos: VideoModel[]) => {
                setVideos(videos)
                setLoading(false)
            },
            () => { },
            () => { }
        )
    }

    const fetchHFVideos = () => {
        setLoading(true)
        HelpService.fetchHFVideos(
            (videos: VideoModel[]) => {
                setVideos(videos)
                setLoading(false)
            },
            () => { },
            () => { }
        )
    }

    const submitHandler = (values: VideoModel, resetForm: Function) => {
        if (!isAdmin) { return }
        setSubmitting(true)
        if (values.id) {
            HelpService.editHLVideos(
                values,
                () => {
                    fetchHLVideos()
                    resetForm()
                    setVideoFormVisible(false)
                    setSelectedVideoData(null)
                },
                () => { },
                () => {
                    setSubmitting(false)
                },
            )

        } else {
            HelpService.createHLVideos(
                values,
                () => {
                    fetchHLVideos()
                    resetForm()
                    setVideoFormVisible(false)
                },
                () => { },
                () => {
                    setSubmitting(false)
                },
            )
        }
    }

    const closeFormHandler = () => {
        setSelectedVideoData(undefined)
        setVideoFormVisible(false)
    }

    const closeDeleteHandler = () => {
        setDeleteVisible(false)
    }

    const deleteHandler = (videoId: number) => {
        HelpService.deleteHLVideo(
            videoId,
            () => {
                fetchVideos()
                setDeleteVisible(false)
                setSelectedVideoData(null)
            },
            () => { },
            () => { }
        )
    }

    return <Fragment>
        <div className={"video__container " + (isAdmin && 'bg-white')}>
            {loading
                ? <Loader />
                : videos.length
                    ? videos.map(video => <FaqVideo
                        video={video}
                        isAdmin={isAdmin}
                        setVideoFormVisible={setVideoFormVisible}
                        setVideoDeleteVisible={setDeleteVisible}
                        setSelectedVideoData={setSelectedVideoData}
                    />)
                    : <EmptyState centerAlign={true} />
            }
        </div>
        <VideoForm
            videoData={selectedVideoData}
            visible={videoFormVisible}
            loading={submitting}
            closeHandler={closeFormHandler}
            submitHandler={submitHandler}
        />
        <ConfirmationAlert
            type="delete"
            visible={deleteVisible}
            title="Are your sure to delete the FAQ"
            description={selectedVideoData?.title}
            deleteHandler={() => deleteHandler(selectedVideoData?.id)}
            closeHandler={closeDeleteHandler}
            cancelHandler={closeDeleteHandler}
        ></ConfirmationAlert>
    </Fragment>
}

export default Video