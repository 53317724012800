import { Col, Row } from "antd";
import React, { FC, useEffect } from "react";
import { OtherNotification } from "../../../../models/OtherNotification/otherNotification.model";
import NotificationService from "../../../../services/NotificationService/notification.service";
import "./otherNotifications.scss";

interface OtherNotificationProps {
  otherNotifications: OtherNotification[];
  clearNotification: (id?: number) => void;
}

const OtherNotifications: FC<OtherNotificationProps> = (props) => {
  const { clearNotification, otherNotifications } = props;

  const handleClear = (id?: number) => {
    clearNotification(id);
  };

  return (
    <div className="other-notifications">
      <Row justify="space-between" className="mb-3">
        <Col className="title">Other Notifications</Col>
        <Col className="clear-all" onClick={() => handleClear()}>
          Clear all
        </Col>
      </Row>
      {otherNotifications?.map((notification) => (
        <Row
          className="notification mb-3"
          justify="space-between"
          align="middle"
          key={notification?.id}
        >
          <Col>
            <p className="head">{notification?.title}</p>
            <div className="content">{notification?.description}</div>
          </Col>
          <Col>
            <i
              className="icon-close"
              onClick={() => handleClear(notification?.id)}
            />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default OtherNotifications;
