import FileSearchOutlined from "@ant-design/icons/lib/icons/FileSearchOutlined";
import { Button, Input, Space, Tabs } from "antd";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import {
  Agreement,
  AgreementStatus,
  Amendment,
} from "../../../../../../models/Agreements/agreement.model";
import ButtonComponent from "../../../../../../shared/components/ButtonComponent";
import KeyContacts from "../../../../../../shared/components/KeyContacts";
import PopoverComponent from "../../../../../../shared/components/PopoverComponent";
import TableNotes from "../../../../../../shared/components/TableNotes";
import TableNotifications from "../../../../../../shared/components/TableNotifications";
import { ContainerOutlined, ArrowLeftOutlined, PlusOutlined, DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { NotificationTypes } from "../../../../../../enums/notificationTypes";
import Notification from "../../../../../../shared/components/Notification";
import { HLAgreementService } from "../../../../../../services/AgreementService/agreement.service";
import { fileDownloadFromUrl } from "../../../../../../shared/utils/fileDownloader";
import ViewSDKClient from "../../../../../../shared/utils/viewSDKClient";
import ScorecardForm from "./ScorecardForm";
import { ConfirmationAlert } from "../../../../../../shared/components/ConfirmationAlert";
import { useHistory, useParams } from "react-router-dom";
import * as AppRoutes from "./../../../../../../routes/appRoutes";
import AgreementForm from "../../AgreementForm";
import AgreementTypesService from "../../../../../../services/KeyTermsService/AgreementTypes.service";
import { deserialize } from "serializr";
import { AgreementTypes } from "../../../../../../models/Agreements/agreementTypes.model";
import { CounterParties } from "../../../../../../models/CounterParties/counterParties.model";
import { Fund } from "../../../../../../models/Fund/fund.model";
import { HLCounterPartiesService } from "../../../../../../services/CounterPartiesService/CounterParties.service";
import { HLFundsService } from "../../../../../../services/FundService/Fund.service";
import { MetaService } from "../../../../../../services/MetaService/meta.service";
import { NotificationType } from "../../../../../../enums/notificationType";
import { LocalStorage } from "../../../../../../shared/utils/localStorageHelpers";
import AgreementKeyTermService from "../../../../../../services/AgreementService/agreementKeyTerm.service";
import Loader from "../../../../../../shared/components/Loader";
import ScorecardModal from "./ScorecardModal";
import { OnboardingItems } from "../../../../../../models/Meta/meta.model";
import AmendmentForm from "../Amendments/AmendmentForm";
import RemoveModal from "./RemoveModal";
interface ViewAgreementProps {
  agreement: Agreement;
  fetchSingleAgreement: Function;
  onAgreementUpdate?: Function;
}

const ViewAgreement = (props: ViewAgreementProps) => {
  const { agreement, fetchSingleAgreement } = props;

  const token = LocalStorage.getItem("ACCESS_TOKEN");

  const user = LocalStorage.getItem("USER");

  const history = useHistory();
  const params = useParams<{ agreementId: string }>();
  const [formVisible, setFormVisible] = useState<boolean>();
  const [visible, setVisible] = useState<boolean>(false);
  const [scorecardModal, setScorecardModal] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [scorecardShowModal, setScorecardShowModal] = useState<boolean>(false);
  const [viewDetailsPage, setViewDetailsPage] = useState<boolean>(false);
  const [showDelete, setDeleteVisibility] = useState(false);
  const [agreementStatus, setAgreementStatus] = useState<any[]>([]);
  const [counterParties, setCounterParties] = useState<any[]>([]);
  const [funds, setFunds] = useState<any[]>([]);
  const [agreementTypes, setAgreementTypes] = useState<any[]>([]);
  const [amendments, setAmendments] = useState<Amendment[]>();
  const { updateAgreementKeyTermsCompleted } = AgreementKeyTermService();
  const [tableLoading, setTableLoading] = useState(true);
  const [activeTabKey, setActiveTabKey] = useState<string>("1");
  const [successVisible, setSuccessVisibility] = useState(false);
  const [amendmentNames, setAmendmentNames] = useState<string[]>([]);
  const [amendmentId, setAmendmentId] = useState<string[]>([]);

  useEffect(() => {
    fetchFundsList();
    fetchCPList();
    fetchAgreementTypes();
    fetchAgreementStatus();
    if (agreement?.id) {
      fetchAmendments(false); 
    }
  }, [agreement?.id]);

  useEffect(() => {
    if (agreement) {
      loadPdf(
        agreement.documentUrl,
        agreement.fundName
      );
    }
  }, [agreement]);

  const loadPdf = (url, fileName) => {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile(
        "pdf-div",
        {
          url,
          fileName,
        },
        {
          showAnnotationTools: false,
          showLeftHandPanel: false,
          showPageControls: false,
          showDownloadPDF: false,
          showPrintPDF: false,
        }
      );
    });
  }
  const fetchAgreementStatus = () => {
    MetaService.agreementStatus(
      (response) => {
        const data = deserialize(
          AgreementStatus,
          response.data["agreement_statuses"]
        ) as AgreementStatus[];
        const formattedData = data.map((sts) => {
          return {
            label: sts.name.replaceAll("_", " "),
            value: sts.id,
            color: sts.color,
          };
        });
        setAgreementStatus(formattedData);
      },
      (error) => {
      }
    );
  };

  const fetchCPList = () => {
    HLCounterPartiesService.fetchCounterParties(
      { meta: true },
      (response: CounterParties[]) => {
        setCounterParties(deserialize(OnboardingItems, response));
      },
      () => { },
      () => { }
    );
  };

  const fetchFundsList = () => {
    HLFundsService.fetchFunds(
      { meta: true },
      (response: Fund[]) => {
        setFunds(deserialize(OnboardingItems, response));
      },
      (error) => {
        Notification({
          message: "Hedge Fund",
          description: "Unable to fetch funds",
          type: NotificationTypes.ERROR,
        });
      },
      () => { }
    );
  };

  const fetchAgreementTypes = () => {
    AgreementTypesService.fetchAgreementTypes(
      (response: AgreementTypes[]) => {
        const foramattedData = response.map((agreement) => {
          return { label: agreement.name, value: agreement.id };
        });
        setAgreementTypes(foramattedData);
      },
      () => { },
      () => { }
    );
  };

  const deleteHandler = (agreement: Agreement) => {
    HLAgreementService.deleteAgreement(
      agreement,
      (res) => {
        setDeleteVisibility(false);
        history.push(AppRoutes.AGREEMENTS);
        Notification({
          message: "Hedge Fund",
          description: "Agreement deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
      },
      (err) => {
        Notification({
          message: "Hedge Fund",
          description: "Agreement deletion failed",
          type: NotificationTypes.ERROR,
        });
      },
      () => { }
    );
  };

  const markAgreementAsCompleted = async () => {
    try {
      await updateAgreementKeyTermsCompleted(agreement?.id?.toString() || "");
      setViewDetailsPage(false);
    } catch (error) { }
  };
  
  const downloadAmendments = () => {
    HLAgreementService.downloadAmendments(
      agreement?.id,
      
      (response) => {
        fileDownloadFromUrl({documentUrl: response.data.download_url , name:"amendments.zip"})
      },
      (error) => {
        Notification({
          message: "Hedge Legal",
          description:
            "Unable to download all the " +
            "amendments",
          type: NotificationTypes.ERROR,
        });
      },
      () => { }
    );
  };
  
    const handleTabChange = (key) => {
      setActiveTabKey(key);
      if (key === "1") {
        loadPdf(agreement.documentUrl, agreement.fundName)
      } else {
        const amendment = amendments.find((element) => element.id.toString() === key);
        if (amendment){
          loadPdf(amendment.documentUrl, amendment.name)
        }
      }
    };

    const fetchAmendments = (isArchived: boolean) => {
      HLAgreementService.fetchAmendments(
        agreement?.id,
        { is_archived: isArchived },
        (response) => {
          const amendmentName = response.map((amendment) => amendment.name);
          const amendmentId = response.map((amendment) => amendment.id);
          setAmendments(response);
          setAmendmentNames(amendmentName);
          setAmendmentId(amendmentId);
        },
        (error) => {
          Notification({
            message: "Hedge Legal",
            description:
              "Unable to fetch " + (isArchived ? "archived " : "") + "amendments",
            type: NotificationTypes.ERROR,
          });
        },
        () => {
          setTableLoading(false);
        }
      );
    };

    const downloadAgreement = () => {
      HLAgreementService.fetchSingleAgreements(
        agreement?.id,
        (data) => {
          fileDownloadFromUrl(data);
        },
        () => { },
        () => { }
      );
    }

  return (
    <div className="view-agreement__container">
      <div className="view-agreement__header">
        <h2 className="view-agreement__header--title">{agreement?.name}</h2>
        <div className="view-agreement__header--details">
          {agreement?.fundName && (
            <div className="view-agreement__header--details__container">
              <p className="view-agreement__header--details__container--title">
                Fund Name
              </p>
              <p className="view-agreement__header--details__container--value">
                {agreement?.fundName}
              </p>
            </div>
          )}
          {agreement?.cpName && (
            <div className="view-agreement__header--details__container">
              <p className="view-agreement__header--details__container--title">
                CP Name
              </p>
              <p className="view-agreement__header--details__container--value">
                {agreement?.cpName}
              </p>
            </div>
          )}
          {agreement?.agreementTypeName && (
            <div className="view-agreement__header--details__container">
              <p className="view-agreement__header--details__container--title">
                Type
              </p>
              <p className="view-agreement__header--details__container--value">
                {agreement?.agreementTypeName?.replaceAll("_", " ")}
              </p>
            </div>
          )}
          {agreement?.date && (
            <div className="view-agreement__header--details__container">
              <p className="view-agreement__header--details__container--title">
                Date
              </p>
              <p className="view-agreement__header--details__container--value">
                {moment(agreement?.date).format("DD MMM, YYYY")}
              </p>
            </div>
          )}
          {agreement?.statusName && (
            <div className="view-agreement__header--details__container">
              <p className="view-agreement__header--details__container--title">
                Status
              </p>
              <p className="view-agreement__header--details__container--value">
                {agreement?.statusName?.replaceAll("_", " ")}
              </p>
            </div>
          )}
          {agreement?.sittingWith && (
            <div className="view-agreement__header--details__container">
              <p className="view-agreement__header--details__container--title">
                Sitting with
              </p>
              <p className="view-agreement__header--details__container--value">
                {agreement?.sittingWith?.replaceAll("_", " ")}
              </p>
            </div>
          )}
          {agreement?.tier && (
            <div className="view-agreement__header--details__container">
              <p className="view-agreement__header--details__container--title">
                Tier
              </p>
              <p className="view-agreement__header--details__container--value">
                {agreement?.tier}
              </p>
            </div>
          )}
          <div className="view-agreement__header--details__container">
            <p className="view-agreement__header--details__container--title">
              Progress
            </p>
            <p className="view-agreement__header--details__container--value">
              {agreement?.statusProgress + " %"}
            </p>
          </div>
          {agreement?.responsibleFirstName && (
            <div className="view-agreement__header--details__container">
              <p className="view-agreement__header--details__container--title">
                User responsible
              </p>
              <p className="view-agreement__header--details__container--value">
                {agreement?.responsibleFirstName +
                  " " +
                  agreement?.responsibleLastName}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="view-agreement__actions">
        {/* <div className="request-key-terms">
          <ButtonComponent
            type="primary"
            htmlType="button"
            onClick={() => {}}
            icon={<FileSearchOutlined />}
            size="small"
          >
            Request Key Terms
          </ButtonComponent>
        </div> */}
        <div className="view-key-terms">
          <ButtonComponent
            type="primary"
            htmlType="button"
            onClick={() => {
              setViewDetailsPage(true);
            }}
            icon={<FileSearchOutlined />}
            size="small"
          >
            Extract Key Terms
          </ButtonComponent>
          <ButtonComponent
            type="primary"
            htmlType="button"
            onClick={() => {
              setScorecardModal(true);
            }}
            icon={<ContainerOutlined />}
            size="small"
          >
            {agreement?.scorecardUrl ? "Edit " : ""}Scorecard
          </ButtonComponent>
          {agreement?.scorecardUrl && (
            <ButtonComponent
              type="primary"
              htmlType="button"
              onClick={() => {
                setScorecardShowModal(true);
              }}
              icon={<ContainerOutlined />}
              size="small"
            >
              View Scorecard
            </ButtonComponent>
          )}
          
          <ButtonComponent
            type="primary"
            htmlType="button"
            onClick={() => {
              setFormVisible(true);
            }}
            icon={<PlusOutlined />}
            size="small"
          >
           Add Amendment
          </ButtonComponent>

          <ButtonComponent
            type="primary"
            htmlType="button"
            onClick={() => {
              setRemoveModal(true);
            }}
            icon={<DeleteOutlined />}
            size="small"
          >
            Remove Amendment
          </ButtonComponent>
        </div>
       
        <div className="view-agreement__actions--btns">
          <Space>
            <PopoverComponent
              showTitleButton={true}
              title="Notes"
              content={<TableNotes id={agreement?.id} category="Agreement" />}
            >
              <i className="icon-notes hoverable-icon" />
            </PopoverComponent>
            <PopoverComponent
              showTitleButton={true}
              title="Key contacts"
              content={
                <KeyContacts
                  contactableType="Agreement"
                  contactableId={agreement?.id?.toString()}
                />
              }
            >
              <i className="icon-key-contacts hoverable-icon" />
            </PopoverComponent>
            <PopoverComponent
              showTitleButton={true}
              title="Notifiations"
              content={
                <TableNotifications
                  id={agreement?.id}
                  category={NotificationType.AGREEMENT}
                  user="Hedge Legal"
                />
              }
            >
              <i className="icon-notification-1 hoverable-icon" />
            </PopoverComponent>
            <i
              className="icon-download hoverable-icon"
              onClick={downloadAgreement}
            />
            <i
              className="icon-edit"
              onClick={() => {
                setVisible(true);
              }}
            />
            <i
              className="icon-delete"
              onClick={() => {
                setDeleteVisibility(true);
              }}
            />
          </Space>
        </div>
      
      </div>
      <div className="view-amendment-tab__actions">
      <Tabs 
        activeKey={activeTabKey} 
        onChange={handleTabChange}
      >
          <Tabs.TabPane 
            tab="Original Agreement" 
            key="1"
          >
          </Tabs.TabPane>
            {amendments?.map(({id,name}, index) => (
              <Tabs.TabPane
                key={id}
                tab={name}
              />
            ))}
      </Tabs>
      
    <Button
      className="download-button"
      type="default"  
      size="small"
      icon={<DownloadOutlined />}
      onClick={ () => downloadAmendments()}   
    >
    </Button>
    </div>
      <div className="view-agreement__body">
        {/* <div className="search-bar">
          <Input
            size="large"
            placeholder="Search.."
            prefix={<SearchOutlined />}
          />
        </div> */}
        {agreement?.documentUrl && (
          // <embed
          //   className="view-agreement__body--document"
          //   src={
          //     agreement?.documentUrl +
          //     "#statusbar=0&navpanes=0&toolbar=0&scrollbar=0&view=fitH"
          //   }
          //   type="application/pdf"
          // />
          <div className="view-agreement__body--document">
            <div id="pdf-div" className="full-window-div" />
          </div>
          //  <PDFViewer url={agreement.htmlDocument}/>
        )}
      </div>
      <ScorecardForm
        agreement={agreement}
        visible={scorecardModal}
        closeHandler={(agreement) => {
          setScorecardModal(false);
          agreement?.id && fetchSingleAgreement();
        }}
      />
      <ScorecardModal
        scorecardUrl={agreement?.scorecardUrl}
        visible={scorecardShowModal}
        closeHandler={() => setScorecardShowModal(false)}
      />
      <RemoveModal
        visible={removeModal}
        closeHandler={() => {
          setRemoveModal(false);
          fetchAmendments(false)
        }}
        agreement={agreement}
        amendmentNames={amendmentNames}  
        amendmentId={amendmentId} 
      />

      {/* {viewDetailsPage &&
        <DocumentDetails
          isAdmin={true}
          closeWindow={() => setViewDetailsPage(false)}
          agreement={agreement}
        />} */}

      {viewDetailsPage && (
        <Fragment>
          <div
            style={{
              position: "fixed",
              top: 8,
              left: 5,
              zIndex: 1000,
              display: "flex",
              alignItems: "center",
            }}
          >
            <ArrowLeftOutlined
              className="back-btn-pdf"
              onClick={() => {
                setViewDetailsPage(false);
              }}
            />
            <span
              style={{
                marginLeft: 24,
              }}
            >
              <ButtonComponent
                type="primary"
                onClick={markAgreementAsCompleted}
              >
                Marked Completed
              </ButtonComponent>
            </span>
          </div>
          <div className="pdf-viewer-loader">
            <Loader />
          </div>
          <iframe
            className="pdf-viewer"
            src={`https://pdf.tradingdox.com?agreement=${params?.agreementId
              }&token=${token}&isAdmin=${user?.role?.includes("hl") ? 1 : 0}`}
          />
        </Fragment>
      )}
      <ConfirmationAlert
        closeHandler={() => setDeleteVisibility(false)}
        visible={showDelete}
        type="delete"
        deleteHandler={() => deleteHandler(agreement)}
        cancelHandler={() => setDeleteVisibility(false)}
        title={"Delete Agreement"}
        description={agreement?.name}
      />
          <ConfirmationAlert
        closeHandler={() => {
          setSuccessVisibility(false);
        }}
        visible={successVisible}
        type="success"
        customButtons={
          <div className="download-success--buttons">
            <ButtonComponent
              onClick={() => {
                setSuccessVisibility(false);
              }}
              type="ghost"
            >
              Close
            </ButtonComponent>
          </div>
        }
        cancelHandler={() => {
          setSuccessVisibility(false);
        }}
        title={"Download request successful"}
        description="Link to the documents will be shared with your mail id shortly.."
      />
      <AgreementForm
        agreement={agreement}
        visible={visible}
        funds={funds}
        counterParties={counterParties}
        agreementStatus={agreementStatus}
        agreementTypes={agreementTypes}
        closeHandler={(agreement?: Agreement) => {
          if (agreement?.id) {
            fetchSingleAgreement();
          }
          setVisible(false);
        }}
      />
      <AmendmentForm
        agreement={agreement}
        visible={formVisible}
        closeHandler={(document?: any) => {
          if (document?.id) {
            fetchAmendments(false);
          }
          setFormVisible(false);
        }}
      />
    </div>
  );
};

export default ViewAgreement;





