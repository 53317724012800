import { Row, Col } from "antd";
import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Partner } from "../../../../models/Partner/partner.model";
import { MetaService } from "../../../../services/MetaService/meta.service";
import { AttachmentComponent } from "../../../../shared/components/AttachmentComponent";
import DropdownField from "../../../../shared/components/DropdownField";
import InputField from "../../../../shared/components/InputField";
import { UIModal } from "../../../../shared/components/UIModal";
import { PartnerValidation } from "./validationSchema";

interface PartnerFormProps {
    closeHandler: () => void
    data?: Partner
    loading: boolean
    visible: boolean
    submitHandler: (values: Partner, helpers: FormikHelpers<any>) => void
}

const PartnerForm = (props: PartnerFormProps) => {

    const {
        closeHandler,
        loading,
        visible,
        submitHandler,
        data,
    } = props

    const [partnerData, setPartnerData] = useState<Partner>(new Partner())
    const [countries, setCountries] = useState<any>();
    const [provinces, setProvinces] = useState<any>();
    const [cities, setCities] = useState<any>();

    useEffect(() => {
        fetchCountries()
    }, [])

    useEffect(() => {
        if (data) {
            const services = data.services?.length ? data?.services.join(", ") as any : ''
            setPartnerData({
                ...data,
                services
            })
            if (data.address.countryId) {
                fetchProvinces(data.address.countryId)
                data.address.provinceId && fetchCities(data.address.countryId, data.address.provinceId)
            }
        } else
            setPartnerData(new Partner())
    }, [data])

    const fetchCountries = () => {
        MetaService.listCountries(
            (countries) => {
                setCountries(countries);
            },
            () => { }
        );
    };
    const fetchProvinces = (countryId: any) => {
        MetaService.listProvinces(
            countryId,
            (provinces) => {
                setProvinces(provinces);
            },
            () => { }
        );
    };
    const fetchCities = (countryId: any, provinceId: any) => {
        MetaService.listCities(
            countryId,
            provinceId,
            (cities) => {
                setCities(cities);
            },
            () => { }
        );
    };
    return (
        <Formik enableReinitialize
            initialValues={partnerData}
            onSubmit={submitHandler}
            validationSchema={PartnerValidation}
        >
            {({ setFieldValue, setFieldError, validateField, validateOnBlur, errors, handleBlur, values, dirty, submitForm, resetForm, isValid, setFieldTouched }) => {
                return (
                    <UIModal
                        className="Partner-form__modal"
                        visible={visible}
                        closeModal={() => {
                            resetForm()
                            closeHandler()
                        }}
                        title={(partnerData.id ? "Edit " : "Add New ") + " Partner"}
                        submitProps={{
                            text: partnerData.id ? "Update " : "Create",
                            clickHandler: submitForm,
                            disabled: !isValid || !dirty,
                            loading
                        }}
                        cancelProps={{
                            text: "Cancel",
                            clickHandler: () => {
                                resetForm();
                                closeHandler();
                            },
                        }}
                    >
                        <Form>
                            <Row>
                                <Col sm={24} className="partner-form__field">
                                    <Field name="logo" as="file-upload">
                                        <AttachmentComponent
                                            accept={"image/*"}
                                            image={values?.logoUrl}
                                            onUpload={(file) => setFieldValue("logo", file)}
                                            title="Upload Logo"
                                        />
                                    </Field>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="partner-form__field">
                                    <InputField
                                        showLabel
                                        placeholder={"Partner Company Name *"}
                                        name="name"
                                        value={values?.name}
                                        type="text"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="partner-form__field">
                                    <InputField
                                        showLabel
                                        placeholder={"Description *"}
                                        name="description"
                                        value={values?.description}
                                        type="textarea"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="partner-form__field">
                                    <InputField
                                        showLabel
                                        placeholder={"Service Provided *"}
                                        name="services"
                                        value={values?.services}
                                        type="text"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="partner-form__field">
                                    <InputField
                                        showLabel
                                        placeholder={"Address - Street *"}
                                        name="address.street"
                                        value={values?.address?.street}
                                        type="text"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="partner-form__field">
                                    <InputField
                                        showLabel
                                        placeholder={"Address 2 *"}
                                        name="address.addressLine"
                                        value={values?.address?.addressLine}
                                        type="text"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="partner-form__field">
                                    <DropdownField
                                        mode="single"
                                        showLabel={true}
                                        optionFilterProp="label"
                                        placeholder={"Country *"}
                                        name="address.countryId"
                                        options={countries}
                                        showSearch={false}
                                        value={values.address?.countryId}
                                        onChange={(value) => {
                                            setFieldValue("address.countryId", value);
                                            setFieldValue("address.provinceId", undefined);
                                            setFieldValue("address.cityId", undefined);
                                            fetchProvinces(value);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="partner-form__field">
                                    <DropdownField
                                        showLabel={true}
                                        mode="single"
                                        optionFilterProp="label"
                                        placeholder={"State/Province *"}
                                        name="address.provinceId"
                                        options={provinces}
                                        showSearch={false}
                                        value={values.address?.provinceId}
                                        onChange={(value) => {
                                            setFieldValue("address.provinceId", value);
                                            setFieldValue("address.cityId", undefined);
                                            fetchCities(values?.address?.countryId, value);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="partner-form__field">
                                    <DropdownField
                                        showLabel={true}
                                        mode="single"
                                        optionFilterProp="label"
                                        placeholder={"City *"}
                                        name="address.cityId"
                                        options={cities}
                                        showSearch={false}
                                        value={values.address?.cityId}
                                        onChange={(value) => {
                                            setFieldValue("address.cityId", value);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </UIModal>
                );
            }}
        </Formik>
    );
}

export default PartnerForm