import { Card, Col, Row, Table, Tooltip as AntdTooltip } from "antd";
import React, { FC, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "./agreement.scss";
import AgreementService, {
  HFAgreementService,
  HLAgreementService,
} from "../../../../../services/AgreementService/agreement.service";
import {
  Agreement as AgreementModal,
  AgreementStatus,
  AgreementType,
} from "../../../../../models/Agreements/agreement.model";
import Text from "../../../../../shared/utils/text";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";
import HLAgreementForm from "../../../../HL/Home/Agreements/AgreementForm";
import HFAgreementForm from "../../../../HF/Home/Agreements/AgreementForm";
import {
  FundsService,
  HLFundsService,
} from "../../../../../services/FundService/Fund.service";
import { Fund } from "../../../../../models/Fund/fund.model";
import { CounterParties } from "../../../../../models/CounterParties/counterParties.model";
import {
  HFCounterPartiesService,
  HLCounterPartiesService,
} from "../../../../../services/CounterPartiesService/CounterParties.service";
import AgreementTypesService from "../../../../../services/KeyTermsService/AgreementTypes.service";
import { AgreementTypes } from "../../../../../models/Agreements/agreementTypes.model";
import { MetaService } from "../../../../../services/MetaService/meta.service";
import { deserialize } from "serializr";
import { AGREEMENTS, AGREEMENTS_SPECIFIC } from "../../../../../routes/appRoutes";
import { generatePath, useHistory } from "react-router";
import { toTitleCase } from "../../../../../shared/utils/toTitleCase";
import { generateStatusStyle } from "../../../../../shared/utils/statusStyleGenerator";
import EmptyState from "../../../../../shared/components/EmptyState";
import TableLoader from "../../../../../shared/components/TableLoader";
import Notification from "../../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../../enums/notificationTypes";

ChartJS.register(ArcElement, Tooltip, Legend);

interface DashboardAgreementProps {
  companyId?: string
}

const Agreement: FC<DashboardAgreementProps> = (props) => {

  const { companyId } = props

  const user = LocalStorage.getItem("USER");

  const isAdmin = user?.role?.includes("hl");

  const history = useHistory();

  const [visibility, setVisibility] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [agreementStatus, setAgreementStatus] = useState<any[]>([]);

  const [counterParties, setCounterParties] = useState<any[]>([]);

  const [funds, setFunds] = useState<any[]>([]);

  const [agreementTypes, setAgreementTypes] = useState<any[]>([]);

  const [types, setTypes] = useState<AgreementType[]>([]);

  const [statuses, setStatuses] = useState<AgreementType[]>([]);

  const fetchFundsList = () => {
    (isAdmin ? HLFundsService : FundsService).fetchFunds(
      { company_ids: companyId ? [companyId] : [] ,meta:true},
      (response: Fund[]) => {
        const foramattedData = response.map((fund) => {
          return { label: fund.name, value: fund.id };
        });
        setFunds(foramattedData);
      },
      (error) => { },
      () => { }
    );
  };

  const fetchCPList = () => {
    (isAdmin
      ? HLCounterPartiesService
      : HFCounterPartiesService
    ).fetchCounterParties(
      { company_id: companyId ,meta: true},
      (response: CounterParties[]) => {
        const foramattedData = response.map((cp) => {
          return { label: cp.name, value: cp.id };
        });
        setCounterParties(foramattedData);
      },
      () => { },
      () => { }
    );
  };

  const fetchAgreementTypes = () => {
    AgreementTypesService.fetchAgreementTypes(
      (response: AgreementTypes[]) => {
        const foramattedData = response.map((agreement) => {
          return { label: agreement.name, value: agreement.id };
        });
        setAgreementTypes(foramattedData);
      },
      () => { },
      () => { }
    );
  };

  const fetchAgreementStatus = () => {
    MetaService.agreementStatus(
      (response) => {
        const data = deserialize(
          AgreementStatus,
          response.data["agreement_statuses"]
        ) as AgreementStatus[];
        const formattedData = data.map((sts) => {
          return {
            label: sts.name.replaceAll("_", " "),
            value: sts.id,
            color: sts.color,
          };
        });
        setAgreementStatus(formattedData);
      },
      (error) => {
        //console.log(error);
      }
    );
  };

  useEffect(() => {
    fetchFundsList();
    fetchCPList();
    fetchAgreementTypes();
    fetchAgreementStatus();

    AgreementService.getAgreementTypes(
      {
        company_id: companyId,
        order_by: "recently_viewed",
        page: 1,
        per_page: 3,
        order_direction: "dsc",
      },
      (agreementTypes) => {
        setTypes(agreementTypes);
      }
    );

    AgreementService.getAgreementStatus((statuses) => {
      setStatuses(statuses);
    }, { company_id: companyId });
  }, []);

  const RecentlyViewedColumn = [
    ...(isAdmin && [{
      title: "HF Name",
      dataIndex: "companyName",
      key: "companyName",
      width: 100,
      ellipsis: { showTitle: false },
      render: text => <AntdTooltip placement="topLeft" title={text || ""}>
        {text || ""}
      </AntdTooltip>,
    }]),
    {
      title: "Fund Name",
      dataIndex: "fundName",
      key: "fundName",
      width: 100,
      ellipsis: { showTitle: false },
      render: text => <AntdTooltip placement="topLeft" title={text || ""}>
        {text || ""}
      </AntdTooltip>,

    },
    {
      title: "CP Name",
      dataIndex: "cpName",
      key: "cpName",
      width: 100,
      ellipsis: { showTitle: false },
      render: text => <AntdTooltip placement="topLeft" title={text || ""}>
        {text || ""}
      </AntdTooltip>,

    },
    {
      title: "Type",
      dataIndex: "agreementTypeName",
      key: "agreementTypeName",
      width: 100,
      ellipsis: { showTitle: false },
      render: text => <AntdTooltip placement="topLeft" title={text || ""}>
        {text || ""}
      </AntdTooltip>,

    },
    {
      title: "Status",
      dataIndex: "statusName",
      key: "statusName",
      width: 100,
      render: (status, record) => <span
        style={generateStatusStyle(agreementStatus, record?.statusId)}
        className={
          "table-selection-dropdown"
        }>
        {status ? Text.snakeToCamel(status) : ""}
      </span>
    },
    {
      title: "Progress",
      dataIndex: "statusProgress",
      key: "statusProgress",
      width: 70,
      ellipsis: { showTitle: false },
      render: (progress) => (
        <div className="text-right">{progress} % &nbsp;&nbsp;</div>
      ),
    },
  ];

  const typesColumns = [
    {
      title: "Type",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a?.name?.localeCompare?.(b?.name),
      ellipsis: { showTitle: false },
      render: text => <AntdTooltip placement="topLeft" title={text || ""}>
        {text || ""}
      </AntdTooltip>,
    },
    {
      title: "No",
      dataIndex: "count",
      key: "count",
      render: (count: string) => {
        return parseInt(count).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
        });
      },
      sorter: (a, b) => a.count - b.count,
    },
  ];

  const [agreements, setAgreements] = useState<AgreementModal[]>([]);

  useEffect(() => {
    const user = LocalStorage.getItem("USER");
    const isAdmin = user?.role?.includes("hl");
    setLoading(true);
    (isAdmin ? HLAgreementService : HFAgreementService).fetchAgreements(
      {
        company_id: companyId,
        order_by: "recently_viewed",
        page: 1,
        per_page: 7,
        order_direction: "dsc",
      },
      (agreements: AgreementModal[]) => {
        setAgreements(agreements);
      },
      () => { },
      () => setLoading(false)
    );
  }, []);

  const handleRedirectToAgreements = (agreement: AgreementModal) => {
    if (agreement.id)
      return {
        onClick: () => {
          history.push({
            pathname: generatePath(AGREEMENTS),
            search: new URLSearchParams({ type: agreement.id + "" }).toString()
          });
        },
      };
  };
  const handleRedirectToAgreement = (agreement: AgreementModal) => {
    if (agreement.id)
      return {
        onClick: () => {
          history.push(generatePath(AGREEMENTS_SPECIFIC, { agreementId: agreement.id }));
        },
      };
  };

  return (
    <Card
      title="Agreement"
      extra={
        <span onClick={() => {
          if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == parseInt(companyId))?.length == 0)) {
            Notification({
              message: "Hedge Legal",
              description: "Access Restricted",
              type: NotificationTypes.ERROR,
            });
          } else {
            setVisibility(true)
          }
        }
        }>
          <i className="icon-add" /> Add New{" "}
        </span>
      }
      className="agreement"
    >
      <Row gutter={[14, 14]}>
        <Col span={5}>
          <h4 className="mb-1">Status of Agreements</h4>
          <p className="mb-3">{statuses.reduce((acc, status) => parseInt(status.count ?? "0") + acc, 0)} Agreements</p>
          <Doughnut
            data={{
              labels: statuses.map((status) => toTitleCase(status?.name?.replaceAll("_", " "))),
              datasets: [
                {
                  label: "# of Votes",
                  data: statuses.map((status) => status.count),
                  backgroundColor: statuses.map((status) => status.color),
                  borderColor: ["#EEEEF3", "#EEEEF3"],
                  borderWidth: 3,
                  hoverBorderWidth: 0,
                  spacing: -4,
                },
              ],
            }}
            onChange={(e) => { }}
            options={{
              onClick: function (evt, elements) {
                const status = statuses[elements[0].index].name
                const search = new URLSearchParams({ status: agreementStatus.find(sts => sts.label === status.replaceAll("_", " "))?.value || 0 }).toString();
                history.push({
                  pathname: AGREEMENTS,
                  search
                })
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </Col>
        <Col span={5}>
          <Table
            showSorterTooltip
            pagination={false}
            bordered
            columns={typesColumns}
            dataSource={types}
            onRow={handleRedirectToAgreements}
            className="naked-table"
          />
        </Col>
        <Col span={14}>
          <h4 className="recently-viewed">Recently Viewed Agreements</h4>
          <Table
            pagination={false}
            bordered
            columns={RecentlyViewedColumn}
            onRow={handleRedirectToAgreement}
            dataSource={loading ? [] : agreements.slice(0, 9)}
            locale={{
              emptyText: loading ? <TableLoader size="small" length={9} /> : <EmptyState />,
            }}
          />
        </Col>
      </Row>
      {isAdmin ? (
        <HLAgreementForm
          visible={visibility}
          funds={funds}
          counterParties={counterParties}
          agreementStatus={agreementStatus}
          agreementTypes={agreementTypes}
          closeHandler={(agreement?: AgreementModal) => {
            if (agreement?.id) {
              setAgreements((agreements) => [agreement, ...agreements]);
            }
            setVisibility(false);
          }}
        />
      ) : (
        <HFAgreementForm
          visible={visibility}
          funds={funds}
          counterParties={counterParties}
          agreementStatus={agreementStatus}
          agreementTypes={agreementTypes}
          closeHandler={(agreement?: AgreementModal) => {
            if (agreement?.id) {
              setAgreements((agreements) => [agreement, ...agreements]);
            }
            setVisibility(false);
          }}
        />
      )}
    </Card>
  );
};

export default Agreement;
