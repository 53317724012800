import { Button, Col, Row } from "antd";
import { Formik, Form } from "formik";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { User } from "../../../../../models/User/user.model";
import { FundsService, HLFundsService } from "../../../../../services/FundService/Fund.service";
import UserManagementService from "../../../../../services/UserManagementService";
import DropdownField from "../../../../../shared/components/DropdownField";
import InputField from "../../../../../shared/components/InputField";
import AuthContainer from "../../../../../store/containers/AuthContainer";
import { AuthReducerProps } from "../../../../../store/reducers/authReducer";
import "./manageUserForm.scss";
import { validateManageUser } from "./validation";

const statusOptions = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
];

const roleOptions = [
  { value: "hf_admin", label: "Admin" },
  { value: "hf_user", label: "User" },
];

interface ManageUsersFormProps extends AuthReducerProps {
  fromValues: User;
  createUserHandler?: (user: User) => void;
  updateUserHandler?: (user: User) => void;
  closeHandler: () => void;
}

interface OptionProps {
  label: string | Element;
  value: string | number;
}

const ManageUsersForm: FC<ManageUsersFormProps> = ({
  fromValues,
  createUserHandler,
  updateUserHandler,
  closeHandler,
  userType,
}) => {
  const [funds, setFunds] = useState<OptionProps[]>([]);

  const params = useParams<{ id: string }>();

  const { createUser, updateUser } = UserManagementService();

  useEffect(() => {
    (userType === "HL" ? HLFundsService : FundsService).fetchFunds(
      {
        company_ids: params.id ? [params.id] : [],
      },
      (funds) => {
        setFunds(
          funds.map((fund) => ({
            label: fund?.name,
            value: fund?.id,
          }))
        );
      },
      () => { },
      () => { }
    );
  }, []);

  const handleSubmit = async (values: User) => {
    let response;
    if (fromValues?.id)
      response = updateUserHandler ? await updateUserHandler(values) : await updateUser(values, params.id);
    else
      response = createUserHandler ? await createUserHandler(values) : await createUser(values, params.id);
    response && closeHandler()
  };

  return (
    <Formik
      initialValues={{
        ...fromValues,
        fundIds: fromValues.funds?.map((fund) => fund?.id),
      }}
      validationSchema={validateManageUser}
      enableReinitialize
      onSubmit={handleSubmit}
      className="manage-user-form"
    >
      {({ values, setFieldValue, isValid, dirty }) => (
        <Form className="manage-user-form">
          <InputField
            name="firstName"
            showLabel
            value={values.firstName}
            type="text" placeholder="First Name" />
          <InputField
            name="lastName"
            showLabel
            value={values.lastName}
            type="text" placeholder="Last Name" />
          <InputField
            name="email"
            showLabel
            value={values.email}
            type="text" placeholder="E-mail" />
          <InputField
            name="phoneNumber"
            showLabel
            value={values.phoneNumber}
            type="text" placeholder="Phone Number" />
          <DropdownField
            options={roleOptions}
            name="role"
            showLabel
            placeholder="Role"
            value={values.role}
            onChange={(role) => setFieldValue("role", role)}
          />
          <DropdownField
            options={funds}
            mode="multiple"
            name="fundIds"
            showLabel
            placeholder="Managing Funds"
            value={values.fundIds}
            showSearch
            onChange={(managingFunds) => {
              setFieldValue("fundIds", managingFunds);
            }}
          />
          <DropdownField
            options={statusOptions}
            name="status"
            showLabel
            value={values.status}
            placeholder="Status"
            onChange={(status) => setFieldValue("status", status)}
          />
          {/* {fromValues ? null : ( */}
          <Fragment>
            <InputField
              name="password"
              showLabel
              value={values.password}
              type="password"
              placeholder="password"
            />
            <InputField
              name="confirmPassword"
              showLabel
              value={values.confirmPassword}
              type="password"
              placeholder="Confirm Password"
            />
          </Fragment>
          {/* )} */}
          <Row className="mt-5" gutter={[15, 15]}>
            <Col span={12}>
              <Button className="full-width" type="text" onClick={closeHandler}>
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="full-width"
                type="primary"
                htmlType="submit"
                disabled={!isValid || !dirty}
              >
                {fromValues?.id ? "Edit" : "Save"}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default AuthContainer(ManageUsersForm);
