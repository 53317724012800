import { Tabs } from "antd";
import React, { useState } from "react";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import { PageHeaderComponent } from "../../../../shared/components/PageHeader";
import { HFundCompanies } from "./HFundCompanies";
import { HFundForm } from "./HFundForm";
import { HFundRequests } from "./HFundRequests";
import { PlusOutlined } from "@ant-design/icons";
import { CompanyModel } from "../../../../models/HFund/hfund.model";
const { TabPane } = Tabs;
export const
  HFund = () => {
    const [tabKey, setTabKey] = React.useState("1");
    const [showForm, setFormVisibility] = React.useState(false);
    const [currentCompany, setCurrentCompany] = useState<CompanyModel>(null);
    const [refresh, setRefresh] = useState(false)
    return (
      <div className="hfund-container">
        <PageHeaderComponent
          title="HFund Management"
          buttons={
            <ButtonComponent
              className="page-header-title__btn"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setFormVisibility(true)}
            >
              New HFund
            </ButtonComponent>
          }
          showTabs
          tabs={["HFund Companies", "HFund Requests"]}
          onChangeTab={key => setTabKey(key)}
        />

        <div className="page-body">
          <HFundForm
            data={currentCompany}
            visible={showForm}
            closeHandler={() => {
              setCurrentCompany(null)
              setRefresh(prev => !prev)
              setFormVisibility(false)
            }}
          />
          {tabKey == "1" ? <HFundCompanies refresh={refresh} editHandler={(company) => {
            setCurrentCompany(company)
            setFormVisibility(true)
          }} /> : <HFundRequests />}
        </div>
      </div>
    );
  };
