import { Space, Tag } from "antd";
import React, { FC, useEffect, useState } from "react";
import EditIcon from "../../../../../../assets/icons/edit.svg";
import DeleteIcon from "../../../../../../assets/icons/delete.svg";
import "./cpUsers.scss";
import { TableComponent } from "../../../../../../shared/components/TableComponent";
import { CPUsersForm } from "./CPUsersForm";
import { UserCounterParties } from "../../../../../../models/CounterParties/userCounterParties.model";
import UserCounterPartiesService from "../../../../../../services/CounterPartiesService/UserCounterParties.service";
import { useHistory, useLocation } from "react-router-dom";
export const CPUsers: FC = () => {
  const [showCPUsersForm, setFormVisibility] = useState(false);
  const [currentUser, setCurrentUser] = useState<UserCounterParties>(null);
  const [cpUsers, setCpUsers] = useState<UserCounterParties[]>([]);
  const { state }: { state: any } = useLocation();
  const history = useHistory()
  const urlParts = history.location.pathname.split('/')
  const id = urlParts[urlParts.length - 1]
  const fetchUserCounterParties = () => {
    UserCounterPartiesService.fetchUserCounterParties(
      id,
      (response) => {
        setCpUsers(response);
        //console.log(response);
      },
      () => { },
      () => { }
    );
  };
  const deleteUserCounterParties = () => {
    UserCounterPartiesService.deleteUserCounterParties(
      id,
      currentUser.id,
      () => { },
      () => { },
      () => { }
    );
  };
  useEffect(() => {
    fetchUserCounterParties();
  }, []);
  return (
    <>
      <CPUsersForm
        data={currentUser}
        cpId={id}
        visible={showCPUsersForm}
        closeHandler={() => {
          fetchUserCounterParties()
          setFormVisibility(false)
        }}
      />
      <TableComponent
        title={"CP Users List"}
        showAdd
        onAdd={() => setFormVisibility(true)}
        onRow={(index) => setCurrentUser(cpUsers[index])}
        columns={[
          {
            title: "NAME",
            dataIndex: "user",
            key: "user",
            render: (user) => <p>{user?.firstName + " " + user?.lastName}</p>,
          },
          {
            title: "PHONE NUMBER",
            key: "user",
            dataIndex: "user",
            render: (user) => <p>{user?.mobileNumber}</p>,
          },
          {
            title: "EMAIL",
            key: "user",
            dataIndex: "user",
            render: (user) => <p>{user?.email}</p>,
          },
          {
            title: "ACCESS",
            dataIndex: "accessLevel",
            key: "accessLevel",
            render: (text) => (
              <p style={{ textTransform: "capitalize" }}>
                {text?.replace(/_/g, " ")}
              </p>
            ),
          },
          {
            title: "STATUS",
            dataIndex: "user",
            key: "user",
            render: (user) => (
              <Tag
                style={{
                  border: "none",
                  borderRadius: "10px",
                  fontStyle: "italic",
                  margin: "5px 0",
                  textTransform: "capitalize",
                }}
                color={user?.status.toLowerCase() == "active" ? "green" : "red"}
              >
                {user?.status?.replace(/_/g, " ")}
              </Tag>
            ),
          },
          {
            title: "",
            key: "action",
            render: (text, record) => (
              <Space size="middle">
                <img
                  className="table-action"
                  src={EditIcon}
                  onClick={() => setFormVisibility(true)}
                  alt=''
                  title="Edit"
                />
                <img
                  className="table-action"
                  src={DeleteIcon}
                  onClick={() => deleteUserCounterParties()}
                  alt=''
                  title="Delete"
                />
              </Space>
            ),
          },
        ]}
        data={cpUsers}
      />
    </>
  );
};
