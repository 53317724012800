import { Form } from "antd";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FundsService } from "../../../services/FundService/Fund.service";
import { useDebouncer } from "../../hooks/useDebouncer";
import InputField from "../InputField";

interface TableNotesProps {
  id: number;
  category: string;
}

const TableNotes = (props: TableNotesProps) => {
  const { id, category } = props;
  const [notes, setNotes] = useState<{ description: string }>();

  const fetchNotes = () => {
    FundsService.fetchNotes(
      category,
      id.toString(),
      (response) => {
        setNotes(response);
      },
      (err) => {
        // ;
      },
      () => { }
    );
  };

  const updateNotes = (notes) => {
    FundsService.updateNotes(
      category,
      id.toString(),
      notes,
      () => { },
      () => { },
      () => { }
    );
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  const handleUpdate = useDebouncer((description) => {
    updateNotes({ description });
  }, 1000)

  return (
    <Formik enableReinitialize initialValues={notes} onSubmit={() => { }}>
      {({ setFieldValue, values, resetForm, isValid }) => {
        return (
          <Form
            onClick={e => e.stopPropagation()}
          >
            <div className="onboarding-notes" onClick={e => e.preventDefault()}>
              <InputField
                onClick={e => e.stopPropagation()}
                onChange={(event) => {
                  setFieldValue("description", event.target.value)
                  //@ts-ignore
                  handleUpdate(event.target.value)
                }}
                showLabel={false}
                placeholder={"Add your notes..."}
                name="description"
                value={values?.description}
                type="textarea"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TableNotes;
