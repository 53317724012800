import axios from "axios";
import * as ApiRoutes from "../routes/apiRoutes";
import { deserialize } from "serializr";
import { ProfileModel } from "../models/HFund/hfund.model";
import { LocalStorage } from "../shared/utils/localStorageHelpers";
import { LOGIN } from "../routes/appRoutes";
import { store } from "../components/store";
import { UNAUTHENTICATED } from "../store/definitions/authConstants";
import { NotificationTypes } from "../enums/notificationTypes";
import Notification from "../shared/components/Notification";

export const getHeaders = (): any => {
  let headers;
  const accessToken = LocalStorage.getItem("ACCESS_TOKEN");
  headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken ? accessToken : ""}`,
  };

  return headers;
};

const axiosInstance = axios.create({
  baseURL: ApiRoutes.LOCAL_BASE_URL,
  timeout: 20000,
});

axiosInstance.interceptors.request.use(function (config) {
  try {
    config.headers = getHeaders();
    if (
      config.headers?.Authorization?.length === 7 &&
      !config.url?.includes("auth")
    ) {
      throw new axios.Cancel("Token is not available. Do login, please.");
    }
    return config;
  } catch (error) {
    return Promise.reject(error);
  }
});
axiosInstance.interceptors.response.use(
  (response): any => {
    return {
      data: response.data,
      message: response.statusText,
      status: response.status,
    };
  },
  (error) => {
    const { response } = error;
    // Notification({
    //   message: "",
    //   description: response.data.error,
    //   type: NotificationTypes.ERROR,
    // });
    if (response?.status === 401) {
      const originalRequest = error?.config;
      originalRequest._retry = true;
      const refreshToken = "";
      const payload = {
        student: {
          grant_type: "refresh_token",
          refresh_token: refreshToken,
        },
      };
      return axios
        .post(`${ApiRoutes.LOCAL_BASE_URL}${ApiRoutes.LOGIN}`, payload)
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            const userDetails = deserialize(
              ProfileModel,
              response.data["user"]
            );
            const { token } = response.data;
            LocalStorage.setItem("USER", userDetails);
            LocalStorage.setItem("ACCESS_TOKEN", token.access_token);
            LocalStorage.setItem("REFRESH_TOKEN", token.refresh_token);
            axios.defaults.headers.common["Authorization"] =
              "bearer " + token.access_token;
            originalRequest.headers["Authorization"] =
              "bearer " + token.access_token;
            return axios(originalRequest);
          }
        })
        .catch((error) => {
          localStorage.clear();
          window.location.replace(LOGIN);
          store.dispatch({
            type: UNAUTHENTICATED,
            payload: "Request for logout",
          });
        });
    }
    const errorMessage = response?.data?.message || response.data.error
    if (typeof errorMessage === "object") {
      const errors = Object.values(errorMessage ? errorMessage : {}) as string[]
      const finalError = typeof errors === "object" ? errors[0] : errorMessage
      Notification({
        message: LocalStorage.getItem("USER")?.role?.includes("hl") ? "Hedge Legal" : "Hedge Fund",
        description: finalError,
        type: NotificationTypes.ERROR,
      });
    } else {
      errorMessage && Notification({
        message: LocalStorage.getItem("USER")?.role?.includes("hl") ? "Hedge Legal" : "Hedge Fund",
        description: errorMessage,
        type: NotificationTypes.ERROR,
      });
    }
    return Promise.reject(response);
  }
);

export default axiosInstance;
