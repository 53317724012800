import { serializable, alias, primitive, list, object, custom } from 'serializr';

class PartnerAddress {

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('addressable_type', primitive()))
    addressableType?: string;

    @serializable(alias('addressable_id', primitive()))
    addressableId?: number;

    @serializable(alias('address_type', primitive()))
    addressType?: number;

    @serializable(alias('street', primitive()))
    street?: string;

    @serializable(alias('address_line_2', primitive()))
    addressLine?: string;

    @serializable(alias('city_id', primitive()))
    cityId?: number;

    @serializable(alias('city_name', primitive()))
    city?: string;

    @serializable(alias('zipcode', primitive()))
    zipcode?: string;

    @serializable(alias('province_id', primitive()))
    provinceId?: number;

    @serializable(alias('country_id', primitive()))
    countryId?: number;

    @serializable(alias('country_name', primitive()))
    country?: string;

    @serializable(alias('province_name', primitive()))
    province?: string;

}
export class Partner {

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('description', primitive()))
    description?: string;
    @serializable(
        alias(
            "logo",
            custom(
                (file) => file,
                (file) => file
            )
        )
    )
    logo?: File;

    @serializable(alias('logo_url', primitive()))
    logoUrl?: string;

    @serializable(alias('services_provided', list(primitive())))
    services?: string[];

    @serializable(alias('address', object(PartnerAddress)))
    address?: PartnerAddress;


}
