
import { serializable, alias, object, list, primitive } from 'serializr';

export class OnboardingItemMetas {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('id', primitive()))
	value?: number;

	@serializable(alias('category', primitive()))
	category?: string;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('description', primitive()))
	description?: string;

}