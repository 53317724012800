import React, { useState } from 'react';
import { Form, Button, Select } from 'antd';
import '../RemoveModal/removeModal.scss'
import { DownOutlined } from '@ant-design/icons';


interface RemoveAmendmentFormProps {
  visible: boolean;
  closeHandler: () => void;
  onSubmit: (values: RemoveAmendmentFormData) => void;
  amendmentNames: string[];
  amendmentId: string[];
}

export interface RemoveAmendmentFormData {
  selectedAmendments: string[]; 
}

const RemoveAmendmentForm: React.FC<RemoveAmendmentFormProps> = ({
  visible,
  closeHandler,
  onSubmit,
  amendmentNames,
  amendmentId
}) => {
  const [form] = Form.useForm();
  const [selectedAmendmentIds, setSelectedAmendmentIds] = useState<string[]>([]);

  
  const handleRemove = async () => {
      const values = await form.validateFields();
      const selectedIds = values.selectedAmendments;
      setSelectedAmendmentIds(selectedIds);
      onSubmit(values as RemoveAmendmentFormData);
      // closeHandler();
  };

  return (
    <Form
      form={form}
      onFinish={onsubmit}
    >
      <h4>Select the Amendments you want to remove</h4>
      <Form.Item name="selectedAmendments" 
      rules={[{ required: true, message: 'Please select amendments' }]}
      className='selectWrapper'
      >
        <Select
          mode="multiple"
          placeholder="Select Amendments"
          className='select'
          dropdownClassName='selectDropdown'
          showArrow={true}
          onClick={() => form.setFieldsValue({ selectedAmendments: form.getFieldValue('selectedAmendments') })}
          suffixIcon={<DownOutlined className='ant-select-suffix dropdownIcon'/>}
        >
            {amendmentNames.map((amendment, index) => (
            <Select.Option key={index} value={amendmentId[index]}>
              {amendment}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <div className='button'>
        <Button type="default" onClick={closeHandler}  className='cancel-button'>
          Cancel
        </Button>
        <Button type="primary" onClick={handleRemove} htmlType="submit" className='remove-button'>
          Remove
        </Button>
        </div>   
      </Form.Item>
    </Form>
  );
};

export default RemoveAmendmentForm;
