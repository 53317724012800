import React, { useEffect, useState } from "react";
import { TableComponent } from "../../../../../../../shared/components/TableComponent";
import { UIModal } from "../../../../../../../shared/components/UIModal";
import { Table, Radio, Divider, Space } from "antd";
import {
  Agreement,
  RelatedAgreements,
} from "../../../../../../../models/Agreements/agreement.model";
import { SearchInput } from "../../../../../../../shared/components/SearchInput";
import { HLAgreementService } from "../../../../../../../services/AgreementService/agreement.service";
import { NotificationTypes } from "../../../../../../../enums/notificationTypes";
import Notification from "../../../../../../../shared/components/Notification";

interface RelatedDocumentsFormProps {
  visible: boolean;
  closeHandler: (success?: boolean) => void;
  agreement: Agreement;
}
const RelatedDocumentsForm = (props: RelatedDocumentsFormProps) => {
  const { visible, closeHandler, agreement } = props;
  const [documents, setDocuments] = useState<RelatedAgreements[]>([]);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<number[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [tableLoading, setTableLoading] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setTableLoading(true);
      fetchUnrealatedDocuments();
    }, 500);
    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchText]);
  const onChange = (selectedRowKeys, selectedRows: RelatedAgreements[]) => {
    setSelectedDocumentIds(selectedRowKeys);
  };

  const onSubmit = () => {
    setButtonLoader(true);
    HLAgreementService.createRelatedAgreements(
      agreement,
      selectedDocumentIds,
      (response) => {
        closeHandler(true);
        fetchUnrealatedDocuments()
      },
      (error) => {
        // Notification({
        //   message: "Hedge Legal",
        //   description: "Unable to add documents",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => {
        setButtonLoader(false)
      }
    );
  };

  const fetchUnrealatedDocuments = () => {
    HLAgreementService.fetchRelatedAgreements(
      agreement,
      {
        search_text: searchText,
        unrelated: true,
      },
      (response: RelatedAgreements[]) => {
        setDocuments(response);
      },
      (error) => {
        //console.log(error);
        // Notification({
        //   message: "Hedge Legal",
        //   description: "Unable to fetch documents",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => {
        setTableLoading(false);
      }
    );
  };
  return (
    <UIModal
      visible={visible}
      className="related-agreements-modal"
      title="Add new related agreement"
      closeModal={() => {
        closeHandler();
      }}
      submitProps={{
        text: "Add",
        clickHandler: () => {
          onSubmit();
        },
        disabled: !selectedDocumentIds.length,
        loading: buttonLoader
      }}
      cancelProps={{
        text: "Cancel",
        clickHandler: () => {
          closeHandler();
        },
      }}
    >
      <>
        <div className="table-title__container">
          <p>Select agreements to add</p>
          <Space className="table-title__container--btn-list">
            <SearchInput onSearch={setSearchText} />
          </Space>
        </div>
        <Table
          loading={tableLoading}
          className="table-container"
          rowSelection={{
            type: "checkbox",
            onChange,
          }}
          columns={[
            {
              title: "FUND",
              dataIndex: "fundName",
              key: "fundName",
              render: (text: string) => <a>{text}</a>,
            },
            {
              title: "CP NAME",
              dataIndex: "counterPartyName",
              key: "counterPartyName",
            },
            {
              title: "AGREEMENT NAME",
              dataIndex: "agreementName",
              key: "agreementName",
            },
            {
              title: "TYPE",
              dataIndex: "agreementTypeName",
              key: "agreementTypeName",
            },
          ]}
          dataSource={documents}
        />
      </>
    </UIModal>
  );
};

export default RelatedDocumentsForm;
