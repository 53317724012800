import { Col, Row } from "antd";
import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import React, { FC, useState } from "react";
import { generatePath, useHistory } from "react-router";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import InputField from "../../../shared/components/InputField";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import "./login.scss";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import AuthContainer from "../../../store/containers/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import {
  COMPANY_DETAILS,
  FORGOT_PASSWORD,
  HOME,
  PROFILE_VERIFICATION,
  SUBSCRIPTION,
  VERIFY_OTP,
  MFA_VERIFICATION
} from "../../../routes/appRoutes";
import { AuthService } from "../../../services/AuthService/auth.service";
import { ProfileModel } from "../../../models/HFund/hfund.model";
interface LoginProps extends AuthReducerProps { }
const Login: FC<LoginProps> = ({
  setAuthenticated,
  setUnAuthenticated,
  setUserType,
}) => {
  const history = useHistory();
  const initialValues = {
    email: "",
    password: null,
  };
  const [loading, setLoading] = useState<boolean>(false);

  const handleLogin = (values) => {
    setLoading(true);
    AuthService.login(
      { ...values, grantType: "password" },
      (response: ProfileModel) => {
        // if (response?.companyStatus !== "pending") {
        //   history.push(PROFILE_VERIFICATION, response);
        // } else {
        if (
          response?.role == "hf_superadmin" ||
          response?.role == "hf_admin" ||
          response?.role == "hf_user"
        ) {
          if (response?.status == "email_verification_pending") {
            history.push(VERIFY_OTP + '?email=' + response?.email, {
              email: response?.email,
            });
          } else if (response?.otpModule == "enabled") {
            setAuthenticated(response);
            setUserType("HF");
            history.push(MFA_VERIFICATION)
          }
          else if (response?.companyStatus == "pending") {
            setAuthenticated(response);
            setUserType("HF");
            history.push(PROFILE_VERIFICATION, response);
          } else if (response?.companyStatus == "setup_pending") {
            setAuthenticated(response);
            setUserType("HF");
            history.push(
              generatePath(COMPANY_DETAILS, {
                companyId: response?.companyId,
              }),
              response
            );
          } else if (response?.companyStatus == "subscription_pending") {
            setAuthenticated(response);
            setUserType("HF");
            history.push(
              generatePath(SUBSCRIPTION, {
                companyId: response?.companyId,
              }),
              response
            );
          } else {
            setAuthenticated(response);
            setUserType("HF");
            Notification({
              message: "Hedge Fund",
              description: "Logged in successfully",
              type: NotificationTypes.SUCCESS,
            });
            history.push(HOME);
          }
        } else {
          setAuthenticated(response);
          setUserType("HL");
          if (response?.otpModule == "enabled") {
            history.push(MFA_VERIFICATION)
          } else {
            Notification({
              message: "Hedge Legal",
              description: "Logged in successfully",
              type: NotificationTypes.SUCCESS,
            });
            history.push(HOME);
          }

        }
        // }
      },
      () => {
        setLoading(false);
      }
    );
  };
  return (
    <div className="login-form__container">
      <h1>Login to TradingDoX</h1>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          //console.log(values);
        }}
      >
        {({ values }) => (
          <Form
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleLogin(values);
              }
            }}
          >
            <Row>
              <Col span={24}>
                <InputField type="email" name="email" placeholder="Email" value={values?.email} />
              </Col>
              <Col span={24}>
                <InputField
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={values?.password}
                  showEye
                />
              </Col>
              <Col span={24}>
                <div className="login-form__forgot">
                  <p
                    className="pointer"
                    onClick={() => {
                      history.push(FORGOT_PASSWORD);
                    }}
                  >
                    Forgot Password?
                  </p>
                </div>
              </Col>
              <Col span={24}>
                <div className="btn__wrapper">
                  <ButtonComponent
                    type={"primary"}
                    onClick={() => {
                      // history.push(SUBSCRIPTION)
                      handleLogin(values);
                    }}
                    iconRight={
                      loading ? <LoadingOutlined /> : <ArrowRightOutlined />
                    }
                    displayType="space-between"
                    disabled={loading}
                  >
                    Login
                  </ButtonComponent>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>

      <p className="info">
        Please contact <a href="mailto: support@TradingDoX.com" target="_blank">support@TradingDoX.com</a> for any questions
      </p>
    </div>
  );
};
export default AuthContainer(Login);
