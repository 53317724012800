import React, { useEffect } from "react";
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop, StatusBar, useUppy } from '@uppy/react'
import { BASE_URL } from "../../../routes/apiRoutes";
import PlaceholderIcon from "../../../assets/icons/upload logo.svg";
import { CloseCircleOutlined } from "@ant-design/icons";
import '@uppy/core/dist/style.css';
import '@uppy/status-bar/dist/style.css';

interface UppyFileUploaderProps {
    setFileUploadUrl: Function;
    setFileUploadName: Function;
    fileUploadUrl: string;
    onComplete?: Function
}

const UppyFileUploader = (props: UppyFileUploaderProps) => {

    const { setFileUploadName, setFileUploadUrl, fileUploadUrl, onComplete = () => { } } = props;

    const uppy = useUppy(() => {
        // @ts-ignore
        return new Uppy(
            {
                meta: { type: 'avatar' },
                restrictions: { maxNumberOfFiles: 1 },
                autoProceed: true
            }
        ).use(Tus, {
            endpoint: '/files',
            chunkSize: 5 * 1024 * 1024,
            autoRetry: true,
            resume: true
        })
    })

    uppy.on('complete', (result) => {
        const uploadUrl = result?.successful[0]?.uploadURL;
        setFileUploadUrl(uploadUrl);
        const responseSplit = uploadUrl.split('/');
        const fileName = responseSplit.length ? responseSplit[responseSplit.length - 1] : '';
        setFileUploadName(fileName);
        onComplete(uploadUrl)
    });

    return (
        !fileUploadUrl ?
            <>
                <DragDrop uppy={uppy}>
                    <div className="attachment-container">
                        <div className="attachment__placholder">
                            <img src={PlaceholderIcon} alt='' />
                        </div>
                        <p className="attachment__title">Drag & Drop / Click here to upload PDF File</p>
                    </div>
                </DragDrop>
                <StatusBar
                    uppy={uppy}
                    hideUploadButton
                    hideAfterFinish={false}
                    showProgressDetails
                />
            </>
            :
            <div className="attachment-container">
                <div className="attachment__pdf--container">
                    <span className="attachment__pdf--remove"
                        onClick={() => {
                            setFileUploadName('');
                            setFileUploadUrl('')
                        }
                        }>
                        < CloseCircleOutlined />
                    </span>
                    <embed src={fileUploadUrl + "#view=FitH"} className="attachment__pdf" />
                </div>
            </div>
    )
}

export default UppyFileUploader;
