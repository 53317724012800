import { DownOutlined } from "@ant-design/icons";
import { Badge, Col, Collapse, Dropdown, Menu, Row, Space, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Report, ReportResults, ReportsAgreement, ReportsKeyTermGroup } from "../../../../models/Report/report.model";
import { ReportsService } from "../../../../services/ReportsService/reports.service";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import EmptyState from "../../../../shared/components/EmptyState";
import Loader from "../../../../shared/components/Loader";
import { TableComponent } from "../../../../shared/components/TableComponent";
import TableLoader from "../../../../shared/components/TableLoader";
import AuthContainer from "../../../../store/containers/AuthContainer";
import { IAuthState } from "../../../../store/reducers/authReducer";
import NestedReportTable from "../NestedReportTable";
import * as AppRoutes from "./../../../../routes/appRoutes"
import "./reportList.scss"
import mailTo from 'mailto-link'
import moment from "moment";

const { Panel } = Collapse
const { Column } = Table

const columns = [{
    title: "FUND",
    dataIndex: "fundName",
    key: "fundName",
    width: 120,
    ellipsis: { showTitle: false },
    render: text => <Tooltip placement="topLeft" title={text || ""}>
        {text || ""}
    </Tooltip>
},
{
    title: "CP NAME",
    dataIndex: "cpName",
    key: "cpName",
    width: 120,
    ellipsis: { showTitle: false },
    render: text => <Tooltip placement="topLeft" title={text || ""}>
        {text || ""}
    </Tooltip>
},
{
    title: "AGREEMENT NAME",
    dataIndex: "name",
    key: "name",
    width: 120,
    ellipsis: { showTitle: false },
    render: text => <Tooltip placement="topLeft" title={text || ""}>
        {text || ""}
    </Tooltip>
},
{
    title: "DATE",
    dataIndex: "date",
    key: "date",
    width: 100,
    ellipsis: { showTitle: false },
    render: date => <Tooltip placement="topLeft" title={date ? moment(date).format("MMM D, YYYY") : ""}>
        {date ? moment(date).format("MMM D, YYYY") : ""}
    </Tooltip>
}]

interface ReportListProps extends IAuthState {
}

const ReportList = (props: ReportListProps) => {
    const {
        userType,
    } = props
    const isAdmin = userType === 'HL'

    const history = useHistory()
    const reportId = history.location.pathname.split('/').pop()
    const [reports, setReports] = useState<Report>()
    const [alteredReports, setAlteredReports] = useState<Report>()
    const [tableLoading, setTableLoading] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        fetchReports()
    }, [])

    const fetchExcelData = () => {
        if (isAdmin) {
            ReportsService.excelDownloadHL(
                reportId,
                (url: string) => {
                    window.open(url, '_blank').focus();
                },
                () => { },
                () => { })
        } else {
            ReportsService.excelDownloadHF(
                reportId,
                (url: string) => {
                    window.open(url, '_blank').focus();
                },
                () => { },
                () => { })
        }
    }

    const mailHandler = () => {
        if (isAdmin) {
            ReportsService.excelDownloadHL(
                reportId,
                (url: string) => {
                    window.open(mailTo({
                        subject: "Hedge Legal Reports",
                        body: 'You can download the report from the below given link  : \n\n' + url
                    }))
                },
                () => { },
                () => { })
        } else {
            ReportsService.excelDownloadHF(
                reportId,
                (url: string) => {

                    window.open(mailTo({
                        subject: "Hedge Fund Reports",
                        body: 'You can download the report from the below given link  : \n\n' + url
                    }))
                },
                () => { },
                () => { })
        }

    }

    const downloadReportsList = () => {
        if (isAdmin) {
            ReportsService.downloadHLReports(
                reportId,
                () => { },
                () => { },
                () => { })
        } else {
            ReportsService.downloadHFReports(
                reportId,
                () => { },
                () => { },
                () => { })
        }
    }

    const fetchReports = () => {
        setLoading(true)
        if (isAdmin) {
            ReportsService.fetchHLReport(
                reportId,
                (data: Report) => {
                    setReports(data)
                    setAlteredReports(data)
                },
                () => { },
                () => {
                    setLoading(false)
                }
            )
        } else {
            ReportsService.fetchHFReport(
                reportId,
                (data: Report) => {
                    setReports(data)
                    setAlteredReports(data)
                },
                () => { },
                () => {
                    setLoading(false)
                }
            )
        }
    }

    const fetchReportResults = (keyTermGroupId: number, record: any) => {
        if (record.agreementKeyTerms) { return }
        setTableLoading(true)
        // setAlteredReports({ ...reports })
        if (isAdmin) {
            ReportsService.fetchHLReportData(
                String(keyTermGroupId),
                reportId,
                (data) => {
                    const newReport = { ...reports }
                    const i = reports.keyTermGroups.findIndex(keyTermGroup => keyTermGroup.id === keyTermGroupId)
                    const length = newReport.keyTermGroups.length
                    const [firstHalf, secondHalf] = [newReport.keyTermGroups.slice(0, i + 1), newReport.keyTermGroups.slice(i + 1, length)]
                    newReport.keyTermGroups = [...firstHalf, ...data, ...secondHalf]
                    setAlteredReports(newReport)
                },
                () => { },
                () => {
                    setTableLoading(false)
                }
            )
        } else {
            ReportsService.fetchHFReportData(
                String(keyTermGroupId),
                reportId,
                (data) => {
                    const newReport = { ...reports }
                    const i = reports.keyTermGroups.findIndex(keyTermGroup => keyTermGroup.id === keyTermGroupId)
                    const length = newReport.keyTermGroups.length
                    const [firstHalf, secondHalf] = [newReport.keyTermGroups.slice(0, i + 1), newReport.keyTermGroups.slice(i + 1, length)]
                    newReport.keyTermGroups = [...firstHalf, ...data, ...secondHalf]
                    setAlteredReports(newReport)
                },
                () => { },
                () => {
                    setTableLoading(false)
                }
            )
        }

    }

    const PanelData = (
        <Row className="report-list__panel-header" gutter={30} align="middle">
            <Col span={16}>
                <Row>
                    <Col className="report-list__panel-header--content">
                        <div className="title">Date of report</div>
                        <div className="value">{reports?.date}</div>
                    </Col>
                    <Col className="report-list__panel-header--content">
                        <div className="title">Type</div>
                        <div className="value">{reports?.agreementType}</div>
                    </Col>
                    <Col className="report-list__panel-header--content">
                        <div className="title">#Agreements</div>
                        {
                            reports?.agreementSelection !== 'all'
                                ? <div className="value panel-opener">{reports?.agreementSelection}</div>
                                : <div className="value">{reports?.agreementSelection}</div>
                        }

                    </Col>
                    <Col className="report-list__panel-header--content">
                        <div className="title">Key Terms</div>
                        <div className="value">{reports?.keyTerms}</div>
                    </Col>
                </Row>
            </Col>
            <Col span={8}>
                <Row align="middle" justify="end" gutter={20}>
                    <Col >
                        <ButtonComponent
                            type="default"
                            size="large"
                            onClick={e => {
                                e.stopPropagation();
                                fetchExcelData()
                            }}
                            icon={<i className="icon-export" />}
                        />
                    </Col>
                    <Col>
                        <ButtonComponent
                            type="default"
                            size="large"
                            onClick={e => {
                                e.stopPropagation();
                                mailHandler()
                            }}
                            icon={<i className="icon-email" />}
                        />
                    </Col>
                    <Col>
                        <ButtonComponent
                            type="default"
                            size="large"
                            onClick={e => {
                                e.stopPropagation();
                                downloadReportsList()
                            }}
                            icon={<i className="icon-download" />}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )

    const reportHeader = (agreement: ReportsAgreement) => {
        return (
            <div className="report-table--header">
                <div className="first-line">
                    <p className="agreement-name">{agreement?.fundName}</p>
                    <hr />
                    <p className="cp-name">{agreement?.cpName}</p>
                </div>
                <p className="report-table--subtitle">{agreement?.name}</p>
                <p className="report-table--subtitle">{agreement?.date}</p>
            </div>
        )
    }

    return loading
        ? <Loader />
        : <div className="report-list__container">
            <div className="report-list__header">
                <div className="report-list--title">
                    <i className="icon-back" onClick={() => history.push(AppRoutes.REPORTS)}></i>
                    Report Results
                </div>
                <Collapse
                >
                    <Panel
                        showArrow={false}
                        key="2"
                        header={PanelData}
                    >
                        <TableComponent
                            showSearch={false}
                            columns={columns}
                            scroll={{ y: '50vh' }}
                            data={reports?.agreements}
                        />
                    </Panel>

                </Collapse>
            </div>
            <div className="report-list__body">
                <Table
                    className="table-container row-clickable report-table"
                    pagination={false}
                    size="middle"
                    onRow={(data, index) => {
                        return {
                            onClick: (event) => fetchReportResults(data.id, data)
                        };
                    }}
                    dataSource={alteredReports?.keyTermGroups || []}
                    scroll={{ x: 1000, y: "85vh" }}
                    loading={tableLoading}
                    locale={{
                        emptyText: <EmptyState />
                    }}
                >
                    <Column
                        fixed='left'
                        width={300}
                        title="Key Terms"
                        dataIndex="name"
                        key="name"
                        render={(text, record: any) => {
                            const result = record.agreementKeyTerms
                                ? <Tooltip placement="top" title={text || ""}>
                                    <p className="nested-data">{text || ""}</p>
                                </Tooltip>
                                : <p className="icon-span">
                                    <i className="icon-caret-down" />
                                    <span>{text}</span>
                                </p>
                            return result
                        }}
                    />
                    {alteredReports?.agreements?.map((agreement, index) => <Column
                        dataIndex="agreementKeyTerms"
                        key="agreementKeyTerms"
                        width={300}
                        ellipsis={true}
                        title={reportHeader(agreement)}
                        render={((text, record: any) => {
                           
                            return (
                              <Tooltip
                                placement="topLeft"
                                title={text && (text[index]?.value || "")}
                              >
                                <span className={text?.[index]?.outlierStatus ? "text-bold text-danger":""}>
                                  {(text && (text[index]?.value || "-")) || ""}
                                </span>
                              </Tooltip>
                            );
                        })}
                    />)}
                </Table>
            </div>
        </div >
}

export default AuthContainer(ReportList)