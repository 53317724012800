import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { TableComponent } from "../../../../../shared/components/TableComponent";
import ApproveIcon from "../../../../../assets/icons/approve.svg";
import DeclineIcon from "../../../../../assets/icons/decline.svg";
import { CompanyService } from "../../../../../services/CompanyService/company.service";
import { CompanyModel } from "../../../../../models/HFund/hfund.model";
import { ConfirmationAlert } from "../../../../../shared/components/ConfirmationAlert";
import PlaceholderIcon from "../../../../../assets/placeholder/avatar_placeholder.png";

export const HFundRequests = () => {
  const [companies, setCompanies] = useState<CompanyModel[]>([]);
  const [showApprove, setApproveVisibility] = useState(false);
  const [showReject, setRejectVisibility] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentCompany, setCurrentCompany] = useState<CompanyModel>(null);

  const fetchCompanies = () => {
    setLoading(true)
    CompanyService.listAllCompanies(
      {},
      (companies: CompanyModel[]) => {
        setLoading(false)
        setCompanies(companies.filter((data) => data.status == "pending"));
      },
      () => {
        setLoading(false)
      }
    );
  };
  // const updateCompany = (companyId: any, value: any) => {
  //   CompanyService.updateCompany(
  //     companyId,
  //     {
  //       ...currentCompany, status: value,
  //     },
  //     (companies: CompanyModel[]) => {
  //       setCompanies(companies.filter((data) => data.status == "pending"));
  //     },
  //     () => {}
  //   );
  // };
  const handleStatus = (company: any, status: string) => {
    CompanyService.updateCompany(
      company?.companyId,
      {
        ...company,
        status: status,
      },
      () => {
        if (status == "setup_pending") {
          setApproveVisibility(true);
        } else if (status == "rejected") {
          setRejectVisibility(true);
        } else {
          setApproveVisibility(false);
          setRejectVisibility(false);
        }
        fetchCompanies();
      },
      () => { }
    );
  };
  useEffect(() => {
    fetchCompanies();
  }, []);
  return (
    <>
      <ConfirmationAlert
        closeHandler={() => setApproveVisibility(false)}
        visible={showApprove}
        type="success"
        logo={PlaceholderIcon}
        title={"Successfully approved the HFund Company"}
        description={currentCompany?.companyName}
        undoHandler={() => handleStatus(currentCompany, "pending")}
      />
      <ConfirmationAlert
        closeHandler={() => setRejectVisibility(false)}
        visible={showReject}
        type="reject"
        logo={PlaceholderIcon}
        title={"Rejected the HFund Company request"}
        description={currentCompany?.companyName}
        undoHandler={() => handleStatus(currentCompany, "pending")}
      />
      <TableComponent
        title={"HFund Requests"}
        data={companies}
        loading={loading}
        pagination={false}
        onRow={(index) => {
          setCurrentCompany(companies[index]);
        }}
        columns={[
          {
            title: "HFUND COMPANY",
            dataIndex: "companyName",
            key: "companyName",
          },
          {
            title: "ADMIN NAME",
            dataIndex: "admin",
            key: "admin",
            render: (text) => <p>{text?.firstName + " " + text?.lastName}</p>,
          },
          {
            title: "POSITION AT COMPANY",
            dataIndex: "admin",
            key: "admin",
            render: (text) => <p>{text?.role}</p>,
          },
          {
            title: "EMAIL",
            key: "admin",
            dataIndex: "admin",
            render: (text) => <p>{text?.email}</p>,
          },
          {
            title: "PHONE NUMBER",
            key: "admin",
            dataIndex: "admin",
            render: (text) => <p>{text?.mobileNumber}</p>,
          },
          {
            title: "",
            key: "action",
            render: (text, record) => (
              <Space size="middle"
                className="table-actions"
                onClick={e => e.stopPropagation()}
              >
                <i
                  className="icon-accept hoverable-icon success"
                  onClick={() => {
                    handleStatus(record, "setup_pending")
                  }}
                  title="Accept"
                />
                <i
                  className="icon-reject hoverable-icon danger"
                  onClick={() => {
                    handleStatus(record, "rejected")
                  }}
                  title="Reject"
                />
              </Space>
            ),
          },
        ]}
      />
    </>
  );
};
