import * as Yup from "yup";

export const CPUsersFormValidation = Yup.object().shape({
  email: Yup.string()
    .email("E-mail is not valid!")
    .required("E-mail is required!"),
  mobileNumber: Yup.string(),
  // .matches(/^[0-9]{10}$/, "Mobile number should be valid!"),
  password: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password should be alphanumeric and contain minimum 8 characters!"
    ),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Both passwords need to be the same"
  ),
  firstName: Yup.string()
    .required("First Name is required!"),
  lastName: Yup.string()
    .required("Last Name is required!"),
  status: Yup.string()
    .required("Status is required!"),
});
