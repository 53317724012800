import React, { Dispatch, useRef, useState } from "react";
import "./faqVideo.scss"
import PlayIcon from "./../../../assets/icons/play.svg"
import { VideoModel } from "../../../models/Video/video.model";

interface FaqVideoProps {
    isAdmin: boolean
    video: VideoModel
    setVideoFormVisible: Dispatch<boolean>
    setVideoDeleteVisible: Dispatch<boolean>
    setSelectedVideoData: Dispatch<VideoModel>
}

const FaqVideo = (props: FaqVideoProps) => {

    const {
        isAdmin,
        video,
        setVideoFormVisible,
        setVideoDeleteVisible,
        setSelectedVideoData,
    } = props

    const panelActionsGenerator = (video: VideoModel) => {
        if (isAdmin) {
            return <div className="video--actions">
                <i className="icon-edit" onClick={(e) => {
                    e.stopPropagation()
                    setSelectedVideoData(video)
                    setVideoFormVisible(true)
                }}></i>
                <i className="icon-delete" onClick={(e) => {
                    e.stopPropagation()
                    setSelectedVideoData(video)
                    setVideoDeleteVisible(true)
                }}></i>
            </div>
        }
        return null
    }

    return <div className="faq-video__container">
        <div className="faq-video--title">{video?.title}
            {panelActionsGenerator(video)}
        </div>
        <p className="faq-video--description">
            {video?.description}
        </p>
        <div className="faq-video">
            <iframe src={`https://player.vimeo.com/video/${video?.link}`}></iframe>
        </div>
    </div>
}

export default FaqVideo