import { Col, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import "./termsOfUse.scss"
import * as appRoutes from "./../../../routes/appRoutes"

const TermsOfUse = () => {
    return (
        <Row className='terms-of-use'>
            <Col span={24} className="text-center"><h2>TERMS OF USE</h2></Col>
            <Col span={24} className="text-center subtitle">(Last modified on June 28, 2022)</Col>

            <Col span={24}>
                <Row>
                    <Col span={24}>
                        <p className='title'>
                            1. Introduction, Definitions, Modifications and Acceptance
                        </p>
                        <p>
                            Welcome to the TradingDoX Platform. We are confident that you will find our Platform useful. These Terms of Use (the <span className='text-bold'>“TOU”</span>) cover a variety of topics, so please refer to the following definitions for clarification:
                        </p>
                        <p>
                            <span className='text-bold'>“Platform”</span> is the proprietary Platform that TradingDoX Technologies Inc. (<span className='text-bold'>“TDX Inc.”</span>) has developed that allows you to manage your documents related to hedge funds, and various related functionalities. You may purchase a <span className='text-bold'> “Subscription”</span> to access additional functions of the Platform.
                        </p>
                        <p>
                            <span className='text-bold'> “TradingDoX”</span> refers to the business which includes the Platform, and all related business processes and entities, and which is owned and operated by TDX Inc., a duly incorporated company in the Province of Quebec, Canada at the address listed below. Where the present TOU refer to <span className='text-bold'>“TradingDoX”</span>, they may refer to TDX Inc. and / or its affiliates, and their respective shareholders, officers, directors, employees, agents, partners, principals, representatives, successors and assigns (collectively <span className='text-bold'> “Representatives”</span> ), depending on the context. Any reference to <span className='text-bold'> “we”</span>  , <span className='text-bold'> “our”</span> , or <span className='text-bold'> “us”</span>  in these TOU shall also refer to TradingDoX or TDX Inc.  In these TOU, a Website visitor or Platform user may be referred to as “you”. When a Platform user has created an account and is logged in, they may be referred to as a  <span className='text-bold'> “Logged-in User”</span>.
                        </p>
                        <p>
                            <span className='text-bold'>“Website”</span> is the website located at <Link target={"_blank"} to={"/"}>https://www.tradingdox.com</Link> which is owned and operated by TDX Inc. and includes all subdomains and subpages, present and future, including but not limited to the subpages where you can access the Platform.
                        </p>
                        <p>
                            Additional definitions shall be made throughout these TOU, but they will be recognizable as they will be capitalized, bolded, and in quotation marks. Additional definitions may also be found in the <Link to={appRoutes.PRIVACY_POLICY}>Privacy Policy</Link>, and will have the same meaning in these TOU as they do there. The definitions found in these TOU shall also apply to the Privacy Policy.
                        </p>
                        <p className='text-bold'>
                            By clicking or tapping the acceptance box upon signing up for an account, you hereby accept to be bound by these TOU without any reservations, modifications, additions or deletions. If you do not agree to all the provisions contained in the TOU, you are not authorized to use the Platform. The TOU are a legal and binding agreement between you and us.
                        </p>
                        <p className='text-bold'>
                            If you are an individual using the Platform on behalf of a corporation, partnership, association or other registered organization (“Legal Entity”), you represent and warrant that you have the capacity and authority to enter into the TOU on behalf of the Legal Entity, and hereby agree to be bound by the terms of the TOU on behalf of that Legal Entity. In such case, any reference to “you” in these TOU may also refer to the Legal Entity, as applicable.
                        </p>
                        <p>
                            TradingDoX reserves the right, at any time and without prior notice, to modify or replace any of the TOU. Any changes to the TOU can be found at this URL or the relevant URL available through <Link target={"_blank"} to={"/"}>https://www.tradingdox.com</Link>. It is your responsibility to check the TOU periodically for changes. Your use of the Platform following the posting of any changes to the TOU constitutes acceptance of those changes. If there are any significant changes to the TOU that materially affect your relationship with us, we will use commercially reasonable efforts to notify you by sending a notice to the primary email address specified in your account, by posting a prominent notice when you log in to your account for the first time following those changes, or by posting a prominent notice upon your first visit to the Platform following those changes.
                        </p>
                        <p>
                            The TOU should be read in conjunction with the  <Link to={appRoutes.PRIVACY_POLICY}>Privacy Policy</Link>, as both these documents govern your use of the Platform.
                        </p>
                        <p className='title'>
                            2. Contacting Us
                        </p>
                        <p>
                            If you have any questions about the TOU, the Platform itself or any issues related to your Subscription, please contact:
                            <a target={"_blank"} href="mailto:support@tradingdox.com">support@tradingdox.com</a>
                        </p>
                        <p className='title'>3. General Code of Conduct for Use of the Platform
                        </p>
                        <p>
                            In addition to the more specific rules found elsewhere in these TOU, you agree that by visiting the Website and / or using the Platform, you will:
                        </p>
                        <ol>
                            <li>Not use the Platform in any manner that in any way violates these TOU or any other applicable policy posted by TradingDoX;</li>
                            <li>Not use the Platform in any manner that violates any intellectual property rights of TradingDoX or any third party;</li>
                            <li>Not use the Platform in any manner to propagate spam, including but not limited to unsolicited advertising or bulk electronic mail or messages, including to link to a spam or phishing website;</li>
                            <li>Not use the Platform in any manner to propagate software viruses, Trojan horses, worms, or any other malicious or non-malicious computer code, files, or programs that are designed or intended to disrupt, damage, limit or interfere with the proper function of any software, hardware, or telecommunications equipment in any form whether belonging to TradingDoX or a third party, or to damage or obtain unauthorized access to any system, data, or other information (whether Personal Information or not) of TradingDoX, other Platform users, or any other third party;</li>
                            <li>Not: (1) take any action that imposes or may impose (as determined by TradingDoX in its sole discretion) an unreasonable or disproportionately large load on TradingDoX’ (or its third party providers’) infrastructures; (2) interfere or attempt to interfere with the proper functioning of the Platform or any activities conducted through the Platform; (3) bypass any measures TradingDoX may use to prevent or restrict access to the Platform or any element thereof; (4) use manual or automated software, devices, or other processes to “crawl” or “spider” any page of the Platform; or (5) harvest or scrape any content from the Platform in an unreasonable manner;</li>
                            <li>Not take any action or use any process that removes, modifies, disables, blocks, obscures or otherwise impairs any advertising in connection with the Platform;</li>
                            <li>Not use the Platform to advertise or promote the Platform where not expressly approved in advance in writing by TradingDoX, or as permitted by these TOU;</li>
                            <li>Not use the Platform to in any way collect information (whether Personal or not) of any third party or in violation of TradingDoX’ Privacy Policy;</li>
                            <li>Not interfere with any third party’s use or enjoyment of the Platform;</li>
                            <li>Not encourage conduct that would constitute a criminal offense or give rise to civil liability;</li>
                            <li>Not attempt to do any of the foregoing prohibitions;</li>
                            <li>Use the Platform in good faith, and in compliance with all applicable local, provincial, state, national, and international laws.</li>
                        </ol>
                        <p className='title'>
                            4. Accounts, Passwords and Users
                        </p>
                        <p className='mb-0'>
                            <span className='text-bold'>a. Accounts and Passwords</span>
                        </p>
                        <p>
                            In order to be able to use the Platform, you will be required to create an account. Accounts are available to anyone who provides the requisite information as described below, subject to the restrictions and conditions as outlined elsewhere in these TOU, and subject to approval by TradingDoX. TradingDoX retains absolute discretion to determine who may have an account on the Platform. Without limiting the generality of the Disclaimer of Warranties further in these TOU, TradingDoX and / or its Representatives shall not be responsible for any losses or damages, pecuniary or otherwise, to you resulting from our refusal to give you an account.
                        </p>
                        <p>
                            As part of the account registration, you will be asked to choose a password. It is your responsibility to create a password of sufficient strength that cannot easily be discovered by third parties, and that meets the requirements as presented upon signing up. You are responsible for maintaining the confidentiality of your password, and are responsible for all activities that occur under your account whether by you or any third party (including but not limited to Authorized Users as described further below in these TOU). You agree to immediately notify us of any unauthorized use of your account or any other breach of security regarding your account.
                        </p>
                        <p>
                            If you are a Logged-in User, it is strongly suggested that you log out of your account at the end of every session, or not leave a logged-in account unattended for any period of time. TradingDoX and its Representatives will not be held liable for any losses or damages, direct or indirect, pecuniary or non-pecuniary, resulting from your failure to log out at the end of the session, an unattended logged-in session, or a third party using the Platform with your Account Information and accessing your account through any means, and disclaims any responsibility in this regard.
                        </p>
                        <p>
                            TradingDoX reserves the right to suspend or terminate your account, at its sole discretion, at any time and for any reason, including but not limited to whether you have violated the letter or spirit of the TOU, as more fully described hereinbelow.
                        </p>
                        <p className='mb-0'>
                            <span className='text-bold'>b. Age Requirements</span>
                        </p>
                        <p>
                            In order to create an account on the Platform, you affirm that you are at least eighteen (18) years of age or over, or the age of majority in the jurisdiction you reside and from which you access the Platform where the age of majority is greater than eighteen (18) years of age.
                        </p>
                        <p className='mb-0'>
                            <span className='text-bold'>c. Account Information and Profile Information </span>
                        </p>
                        <p>
                            The information required to create an account to begin using the Platform is the following: your company, first and last name, title, your email address, and phone number. This information you submit as part of the sign-up process may be referred to in the present TOU or the Privacy Policy as <span className='text-bold'>“Account Information”</span>.
                        </p>
                        <p>
                            After entering your Account Information, you will be required to enter certain information about your company including any funds it manages or advises, including without limitation its registered address, mailing address and billing address if different from your registered address, as well as contact information (name, phone and email address) for a contact person, which may also be you.  This information you submit as part of the sign-up process may be referred to in the present TOU or the Privacy Policy as <span className='text-bold'>“Profile Information”</span>
                        </p>
                        <p>
                            By submitting Account Information and Profile Information, you represent and warrant that the information is true and accurate to the best of your knowledge. Submitting false or misleading Account Information or Profile Information may result in you being banned from using the Platform, at our sole discretion.
                        </p>
                        <p className='mb-0'>
                            <span className='text-bold'>d. Authorized Users</span>
                        </p>
                        <p>
                            Depending on your Subscription Tier, you may be authorized to add additional users under your account (<span className='text-bold'>“Authorized Users”</span>). To do so, when you are a Logged-in User you will be required to enter the following information about such users: first and last name, phone number, email address, as well as assigning them a role and password (<span className='text-bold'>“Authorized Users Information”</span>). By submitting Authorized User Information, you represent and warrant that you have the legal right to submit this information and are authorizing the Authorized User to use your account.
                        </p>
                        <p>
                            Without limiting the generality of the Disclaimer of Warranties further in these TOU, TradingDoX and / or its Representatives shall not be responsible for any losses or damages, pecuniary or otherwise, to you or any of your Authorized Users resulting from your submission of any Authorized User Information, and TradingDoX disclaims any responsibility thereto.
                        </p>
                        <p>
                            You shall be responsible for any violation of these Terms of Use or any violation of any other posted TradingDoX policy regarding the Platform by any of your Authorized Users.
                        </p>
                        <p className='title'>
                            5. Subscription Fees
                        </p>
                        <p className='mb-0'>
                            <span className='text-bold'>a. Payment of Subscription Fees and Automatic Renewal; Cancelling your Subscription</span>
                        </p>
                        <p>
                            The amount of the <b>“Subscription Fees”</b> for each level available are firm and are displayed to you upon your logging in to the Platform for the first time. The Subscription Fees may vary depending on the <b>“Subscription Tier”</b> chosen through the Platform, or by agreement between you and TradingDoX (which may be agreed via e-mail). The Subscription Fees are firm and charged in Canadian or American Dollars, which may be converted to other currencies upon payment depending on your location and / or credit card agreement. By signing up for a Subscription Tier you agree to pay the Subscription Fees presented to you upon signing up, plus any applicable taxes.
                        </p>
                        <p>
                            You will be charged the Subscription Fees on the day you agree to pay for your Subscription (the <b>“Initial Billing Date”</b> ). The Subscription Fees shall subsequently be charged on the annual anniversary of the Initial Billing Date or at such other frequency as may be agreed between you and TradingDoX. The annual payment period of your subscription shall be referred to in the present TOU as a <b>“Billing Period”</b>.
                        </p>
                        <p>
                            <b>Your Subscription automatically renews at the end of every Billing Period.</b> If you wish to cancel your Subscription and avoid paying the Subscription Fees for the next Billing Period, you must do so prior to the end of the current Billing Period, no later than 30 days prior to the end of the Billing Period. You can cancel your Subscription and avoid paying the Subscription Fees for the next Billing Period by emailing <a target={"_blank"} href="mailto:support@tradingdox.com">support@tradingdox.com</a>.
                        </p>
                        <p>
                            At any time during your Billing Period, you may modify your Subscription Tier, by choosing the available option in the Account Details section of the TradingDoX dashboard when you are a Logged-In User. In the event you choose a Subscription Tier with higher Subscription Fees than your previous Subscription Tier, you shall be invoiced for the difference on a prorated basis for the balance of the Billing Period. In the event you choose a Subscription Tier with lower Subscription Fees than your previous Subscription Tier, you shall not be entitled to any prorated refund of any Subscription Fees already paid.
                        </p>
                        <p>
                            You may cancel your Subscription at any time by emailing <a target={"_blank"} href="mailto:support@tradingdox.com">support@tradingdox.com</a>. In the event you cancel your Subscription, you shall not be entitled to any refund, prorated or otherwise, of Subscription Fees already paid.
                        </p>
                        <p className='mb-0'>
                            <span className='text-bold'>b. Payments and Billing</span>
                        </p>
                        <p>
                            Payments for Subscription Fees are made offline, via invoices sent to you by TradingDoX via email. Payment methods and instructions shall be indicated on your invoice.
                        </p>
                        <p className='mb-0'>
                            <span className='text-bold'>c. Referral Codes</span>
                        </p>
                        <p>
                            If you have a valid referral code when creating an account, it may entitle you to a discount on your Subscription Fees, which shall be applied on your Invoice. In the event that an incorrect discount is applied, TradingDoX reserves the right to refuse or cancel Subscriptions listed at an incorrect discount.
                        </p>
                        <p className='mb-0'>
                            <span className='text-bold'>6. DoXCoins</span>
                        </p>
                        <p>
                            TradingDoX, through the Platform may offer you the ability to accumulate <b>“DoXCoins”</b>.  DoXCoins are a form of loyalty reward program that can be redeemed against certain services offer by TradingDoX or other third parties as specified in the Platform.  DoXCoins will be accumulated based on certain actions in the Platform.
                        </p>
                        <p>
                            You acknowledge and agree that any misuse and /or abuse of DoXCoins or their accumulations is strictly prohibited.  Under all circumstances TradingDoX reserves the right to add or remove DoXCoins from your account at any time at its sole discretion.  DoXCoins bear no monetary value and are not exchangeable with respect to any cash value.
                        </p>
                        <p>
                            You acknowledge and agree that TradingDoX and any third party offering services in exchange for the redemption of DoXCoins will have no liability whatever, and no obligation to honour the use of DoXCoins.  Moreover, you agree not to take recourse against TradingDoX or any third party with respect to the foregoing.
                        </p>
                        <p>
                            Without limiting the generality of the Disclaimer of Warranties further in these TOU, TradingDoX and / or its Representatives shall not be responsible for any losses or damages, pecuniary or otherwise, to you or any third party resulting from your use of DoXCoins or TradingDoX granting or removal of your DoXCoins, and TradingDoX explicitly disclaims any responsibility thereto.
                        </p>
                        <p>
                            <span className='text-bold'>7. Disclaimers Regarding Financial Information and Platform Output</span>
                        </p>
                        <p>
                            You acknowledge that from time to time, TradingDoX or other Logged-in Users may include on the Platform information of a financial nature ( <b>“Financial Information”</b> ). This Financial Information is intended for information purposes only, and is in no way intended to be acted upon by you without consulting a professional financial or legal advisor.
                        </p>
                        <p>
                            Without limiting the generality of the Disclaimer of Warranties further in these TOU, TradingDoX and / or its Representatives shall not be responsible for any losses or damages, pecuniary or otherwise, to you or any third party resulting from your use of, or any reliance upon, Financial Information, and TradingDoX explicitly disclaims any responsibility thereto.
                        </p>
                        <p>
                            When you are using the Platform to create or edit various agreements and schedules, and are using the Platform to generate reports and key terms of agreements (all of the foregoing collectively the <b>“TradingDoX Output”</b> ), you acknowledge and agree that the TradingDoX Output may be automatically generated by the Platform and TradingDoX shall not be responsible for any errors in the TradingDoX Output.
                        </p>
                        <p>
                            Without limiting the generality of the Disclaimer of Warranties further in these TOU, TradingDoX and / or its Representatives shall not be responsible for any losses or damages, pecuniary or otherwise, to you or any third party resulting from your use of, or any reliance upon, any TradingDoX Output, and TradingDoX explicitly disclaims any responsibility thereto.
                        </p>
                        <p>
                            <span className='text-bold'>8. Proprietary and Intellectual Property Rights; Your User Data; User Content Policy</span>
                        </p>
                        <p className='mb-0'>
                            <span className='text-bold'>a. TradingDoX’ Rights</span>
                        </p>
                        <p>
                            You acknowledge that: (a) the Platform contains proprietary and confidential information that is protected by applicable intellectual property and other laws, and (b) TradingDoX and/or third parties own all right, title and interest in and to the Platform and content that may be presented or accessed through the Platform (except for User Data and User Content as defined below), including without limitation all Intellectual Property Rights therein and thereto. All rights not specifically granted under these TOU are reserved to TradingDoX and its licensors. <b>“Intellectual Property Rights”</b> means any and all rights existing from time to time under patent law, copyright law, trade secret law, trademark law, unfair competition law, and any and all other proprietary rights, whether registered or not, and any and all applications, renewals, extensions and restorations thereof, now or hereafter in force and effect worldwide.
                        </p>
                        <p>
                            You agree that you will not, and will not allow any third party, to (i) copy, sell, license, distribute, transfer, modify, adapt, translate, prepare derivative works from, decompile, reverse engineer, disassemble or otherwise attempt to derive source code from, the Platform, or content that may be presented or accessed through the Platform for any purpose, unless otherwise permitted by these TOU or the functionalities of the Platform; (ii) take any action to circumvent or defeat the security deployed or enforced by any functionality contained in the Platform; or (iii) remove, obscure, or alter TradingDoX’ or any third party’s copyright notices, trademarks, or other proprietary rights notices affixed to or contained within or accessed in conjunction with or through the Platform.
                        </p>
                        <p>
                            The content, arrangement and layout of the Website and Platform, including but not limited to the trademarks, photos, logos, videos, audio, images, text (in the form of plain text, HTML, or PDFs) and Computer Code are proprietary to TradingDoX, either owned or under license, and may not be copied, imitated, reproduced, displayed, distributed, transmitted, decompiled or otherwise used without the express permission of TradingDoX, or as permitted by the functionality of the Platform or these TOU. Any unauthorized use of the content, arrangement or layout of the Website and Platform, Computer Code, images, logos, videos, audio or trademarks found on the Website or Platform or any portion of or derivative works thereof may violate civil or criminal laws, including but not limited to intellectual property laws, and TradingDoX may take action accordingly.
                        </p>
                        <p>
                            The above paragraph further applies to third party property used as part of the Website and Platform, including but not limited to third party Computer Code. For the purposes of the present section, <b>“Computer Code”</b> includes but is not limited to source code in any programming language, object code, frameworks, CSS, PHP, JavaScript or similar files, templates, modules, or any similar files, and related documentation.
                        </p>
                        <p className='mb-0'>
                            <span className='text-bold'>b. Your User Data</span>
                        </p>
                        <p>
                            All data you submit through the Platform as a Logged-in User whether in the form of a text, full documents, financial information, graphics, images, or any other form of inputted information, and all their associated Intellectual Property Rights (collectively <b>“User Data”</b> ) remain your property. Any documents that form part of the TradingDoX Output created from your inputted User Data, and their related Intellectual Property Rights, are the property of TradingDoX; however, TradingDoX grants you a worldwide, exclusive, royalty-free, sublicensable and transferable license to use, edit, translate, reproduce, distribute, and display those documents.
                        </p>
                        <p>
                            Your access to the inputted User Data is contingent upon full payment of the Subscription Fees. You are expected to maintain copies of all User Data inputted into the Platform. TradingDoX adheres to its best practice policies and procedures to prevent data loss, including a daily system data back-up regime, but does not make any guarantees that there will be no loss of User Data. Furthermore, TradingDoX is under no obligation to retain copies of your User Data following termination of your Subscription or account for any reason, but may retain such information in its sole discretion.
                        </p>
                        <p>
                            Without limiting the generality of the disclaimers found further in these TOU, TradingDoX and / or its Representatives shall in no way be held responsible for any losses or damages, direct or indirect, pecuniary or otherwise, resulting from any loss of User Data no matter how caused, or TradingDoX’s deletion of your User Data following the termination of your Subscription or account, and TradingDoX disclaims any responsibility thereto.
                        </p>
                        <p className='mb-0'>
                            <span className='text-bold'>c. Submitted Information</span>
                        </p>
                        <p>
                            If you choose to communicate to TradingDoX (via any means, included but not limited to by email) suggestions for improvements to the Platform or any idea or proposal related to TradingDoX or its businesses or properties generally (collectively, <b>“Feedback”</b> ), TradingDoX shall own all right, title, and interest in and to the Feedback and will be entitled to use the Feedback without restriction. You hereby irrevocably assign all right, title and interest in and to the Feedback to TradingDoX and waive in favor of TradingDoX, its successors and assigns all your moral rights in the Feedback, and agree to provide TradingDoX such assistance as TradingDoX may require to document, perfect, and maintain TradingDoX’ rights to the Feedback. You acknowledge and agree that, by providing any Feedback to TradingDoX, you are not entitled to any compensation or reimbursement of any kind from TradingDoX under any circumstances.
                        </p>
                        <p className='mb-0'>
                            <span className='text-bold'>d. User Content Policy</span>
                        </p>
                        <p>
                            As a Platform user, you can create and submit text in the form of comments on the Discussion Board and reviews of Brokers / Counterparties ( <b>“CPs”</b> ), which may be visible to other users or third parties in various ways, which shall be deemed <b>“User Content”</b>. By creating, submitting and / or uploading User Content on the Platform, you agree to be bound by the TradingDoX policy for User Content as described in this section (the <b>“User Content Policy”</b> ).
                        </p>
                        <p>When you submit User Content, you acknowledge and agree to the following:</p>
                        <ol>
                            <li>User Content submitted by you does not violate any intellectual property right or other proprietary right of a third party. Without limiting the generality of the foregoing, you acknowledge that any User Content submitted are your own original works, or works for which you have been granted a valid license to reproduce or display. You affirm, represent and warrant that: (1) you own or have the necessary licenses, rights, consents, and permissions to use and authorize TradingDoX to use all intellectual property rights and other proprietary rights in and to the User Content (or the individual constituent elements thereof) as set out herein; and (2) have all necessary consents to collect, use and disclose any personally identifiable information (of yourself or third parties) contained or displayed in the User Content to enable inclusion and use of the User Content in the manner contemplated by the Platform and these TOU;</li>
                            <li>You retain all of your ownership rights in the User Content you submit. However, by submitting User Content to TradingDoX, you hereby grant TradingDoX a non-exclusive, royalty-free, sublicenseable and transferable license to use, edit, translate, reproduce, distribute, display, and / or perform and otherwise fully exploit the User Content in connection with the Platform and / or TradingDoX' (and its successors' and affiliates') business or activities, including without limitation for promoting and redistributing part or all of the Platform (and derivative works thereof) in any media formats and through any media channels without any compensation to you. You understand and agree that TradingDoX may retain, but not display or distribute, server copies of User Content that have been removed or deleted;</li>
                            <li>You also hereby waive any moral rights you may have in your User Content and grant other users of the Platform a non-exclusive license to access your User Content, and to use, reproduce, distribute, display and / or perform such User Content as permitted through the functionality of the Platform and under these TOU;</li>
                            <li>The licenses granted by you in the previous two paragraphs in regard the User Content are perpetual and irrevocable, and apply throughout the world and in any and all media as applicable;</li>
                            <li>You will not upload any User Content that is unlawful, such as User Content that is threatening, abusive, harassing, defamatory, libelous, fraudulent, invasive of another’s privacy, or otherwise tortuous. Furthermore, you will not upload any User Content that is racist, sexist, homophobic, or otherwise offensive to any minority or group; contains any adult content or political militancy; or upload any User Content that contains any viruses, commercial solicitations, mass mailing, chain mailing or any other form of spam; and</li>
                            <li>You will not include in User Content links to any third-party website that is a spam or phishing website, to any website that sells goods or services, or to any website that may violate any applicable law.</li>
                        </ol>
                        <p>Where you are accessing or downloading User Content of a Platform user, you acknowledge that any use of User Content accessed or downloaded by you while using the Platform is at your own risk and you will be solely responsible for any damage or loss to you or any third party arising therefrom, and TradingDoX disclaims any responsibility in this regard.</p>
                        <p>If you as a Platform visitor believe that a user has posted User Content that violates this User Content Policy, please contact us at <a href="mailto:support@tradingdox.com">support@tradingdox.com</a> so that we may conduct an inquiry. TradingDoX retains absolute discretion whether to remove or modify any User Content or take any other action pursuant to your report.</p>
                        <p>Depending on the User Content, User Content may or may not be edited, curated or otherwise modified or checked by TradingDoX prior to posting. TradingDoX reserves the right, in its sole discretion, to refuse to display any User Content, or to remove or modify immediately and without notice any User Content that violates the User Content Policy, or for any other legitimate reason.</p>
                        <p>TradingDoX and its Representatives will not be held liable for any damages, pecuniary or otherwise, to you or any third party arising from your submission or use of User Content, whether it violates the User Content Policy or not, or our removal or modification thereof, and disclaims any responsibility in this regard. To the extent permitted by law, TradingDoX assumes no liability or responsibility to any person with respect to any User Content including, but not limited to, defamation, errors, obscenity, falsehoods or omissions that may be encountered within any User Content.</p>
                        <p>You acknowledge and agree that your reliance on User Content may have a financial impact. Without limiting the generality of the Disclaimer of Warranties further in these TOU, TradingDoX and / or its Representatives shall not be responsible for any losses or damages, pecuniary or otherwise, to you or any third party resulting from your reliance on any User Content, and TradingDoX explicitly disclaims any responsibility thereto.</p>
                        <p className='title'>
                            9. Interruption of Service
                        </p>
                        <p>From time to time, the Platform may be unavailable for brief periods of time for maintenance and / or modifications to the Platform. While we will endeavour to make this unavailability as brief as possible, TradingDoX and its Representatives shall not be held liable for any losses or damages, direct or indirect, pecuniary or non-pecuniary, resulting from the interruption of the normal functioning of the Platform, and disclaims any responsibility thereto. </p>
                        <p className='title'>
                            10. Termination of the Platform and the TOU
                        </p>
                        <p>You agree that TradingDoX, in their sole discretion, with or without cause, has the right (but not the obligation) to block your IP address, revoke your account credentials, or otherwise terminate your access to or use of the Platform (or any part thereof), immediately and without notice, for any reason, including, without limitation, if TradingDoX believes that you have acted inconsistently with the letter or spirit of the TOU or the Privacy Policy.</p>
                        <p>TradingDoX may also, in their sole discretion and at any time, discontinue providing the Platform, or any part thereof, with or without notice. You agree that TradingDoX shall not be liable to you or any third party for any losses or damages, pecuniary or non-pecuniary, resulting from termination of your access to the Platform, or from TradingDoX’ termination of the Platform or any part thereof.</p>
                        <p>You may also choose to terminate your access to certain of the Platform by cancelling your Subscription. If you wish to cancel your Subscription, please contact as at <a href="mailto:support@tradingdox.com">support@tradingdox.com</a> .</p>
                        <p>Termination of the Platform or your access to the Platform shall terminate the present TOU as between you and TradingDoX. All provisions of these TOU which by their nature should survive termination of these TOU shall survive termination, including without limitation, intellectual property provisions, disclaimers, indemnity and limitations of liability.</p>
                        <p className='title'>
                            11. External Links
                        </p>
                        <p>From time to time TradingDoX may provide links to other websites or se rvices. Links from the Platform may take you to websites or Platform not covered by these TOU. When you access third party resources on the Internet in this manner, you do so at your own risk. TradingDoX provides those links as a convenience to you and TradingDoX takes no responsibility for your use of those other websites or services or protection of your privacy (including collection of your personal information) on those other websites or services. We encourage you to check the Terms of Use and / or Privacy Policy of any website or service you visit. TradingDoX does not make any claim or warranty whatsoever about the content of those websites or Platform to which we link, or any products or services available through those websites or the third parties operating those websites. </p>
                        <p>In no way will TradingDoX or its Representatives be held responsible for any damages, direct or indirect, pecuniary or non-pecuniary: (1) for your use of websites or other services that may be linked to from the Platform or the information thereon; (2) for any virus, Trojan horse, worm or other similar destructive file received as a result of your use of those websites or services; (3) caused by or in connection with, use of or reliance upon on any content, or products or services (whether free or for purchase) available on or through any linked-to website or service; or (4) for the actions of the operators of any such website or service</p>
                        <p className='title'>12. Disclaimer of Warranties </p>
                        <p>You expressly understand and agree that your use of the Platform, the information or material thereon (whether provided by TradingDoX or third parties) or any activity arising from your use of the Platform (including but not limited to your use of or reliance upon any Financial Information or TradingDoX Output) or the information thereon or the materials streamed or downloaded therefrom is at your sole risk. The Platform, any materials downloaded or streamed therefrom, or any third party materials, are provided on an "as is" and "as available" basis, and you will be solely responsible for any damage to your computer or device or loss of data that results from the download, stream or access of any material obtained through the use of the Platform or any other functionalities of the Platform, or losses or damages (pecuniary or otherwise) resulting from (i) your use of the Platform, the information thereon, any materials downloaded or streamed therefrom, or (ii) any activity arising from the use of the Platform (including but not limited to your use of or reliance upon any Financial Information or TradingDoX Output) or the information or any materials downloaded or streamed therefrom.</p>
                        <p>The information or resources provided through the Platform, written or produced by TradingDoX staff, freelance writers or other subcontractors are known to be as accurate as possible at the time of writing or production, and every effort has been made to ensure that the information from the Platform is as accurate and up-to-date as possible. However, certain information may change, and errors or omissions may occur, and TradingDoX shall not be responsible for any loss or damage, financial or otherwise, resulting from changes or errors in information, or any omission, on the Platform or through the Platform.</p>
                        <p>TradingDoX expressly disclaims all warranties of any kind, whether express or implied, including but not limited to: warranties of title and non-infringement; warranties that the Platform, the information thereon or any materials downloaded or streamed therefrom, and any third-party materials will be uninterrupted, error-free, accurate, reliable and free from virus and other harmful components; and the implied warranties of merchantability and fitness for a particular purpose. TradingDoX, and its Representatives do not warrant that: (i) the Platform will be secure or available at any particular time or location; (ii) any defects or errors or omissions will be corrected; (iii) any content or software available at or through the Platform is free of viruses or other harmful components; or (iv) the results of using the Platform, or any activity arising therefrom (including but not limited to your use of or reliance upon any Financial Information or TradingDoX Output), or any content downloaded or streamed therefrom will meet your requirements. </p>
                        <p className='title'>13. Limitation of Liability</p>
                        <p>In no case will TradingDoX or its Representatives be liable for any indirect, special, consequential, exemplary, punitive damages or other damages, or for any losses, damages, liabilities, costs and expenses arising out of or relating to (i) your access, use, misuse or inability to access or use the Platform, or (ii) the interruption, suspension or termination of any part of or all the Platform; and in both cases (i) and (ii) regardless of the cause of action (whether in contract, warranty, delict, quasi-delict, tort, negligence, strict liability or any other theory of liability) and even if we have been advised of the possibility of such damages. Notwithstanding anything to the contrary in the TOU, and to the maximum extent permitted by law, in no event will our aggregate liability for any claims in connection with your use of the Platform and exceed the amount of Subscription Fees paid in the previous 12 months.</p>
                        <p>You expressly understand and agree that TradingDoX or its Representatives shall not be liable for any direct, indirect, incidental, special, consequential, punitive or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses, in contract, tort, strict liability, negligence, general civil liability or any other cause of action under legal or equitable theory, relating to the Platform, the information on the Platform, your use of the Platform, activities arising from your use of the Platform (including but not limited to your use of or reliance upon any Financial Information or TradingDoX Output), any third party materials on the Platform, or any materials downloaded from the Platform. This limitation of liability applies, without limitation, to any damages or injury caused by any error, omission or other failure of performance by TradingDoX or its Representatives, or any Business User; any interruption, defect or delay in operation or transmission, including communication line failure; any computer virus; and any theft, destruction or alteration of, or unauthorized access to or use of, any electronic records. </p>
                        <p>Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above exclusions and limitations may or may not apply to you.</p>
                        <p className='title'> 14. Indemnity</p>
                        <p>Notwithstanding any other term of the TOU or any act or failure to act by TradingDoX or its Representatives, you agree to indemnify, defend and hold harmless TradingDoX and its Representatives, or its affiliates and their Representatives, from and against any damages, liabilities, costs and expenses (including attorneys’ fees), claims or demands, arising out of (i) your use of or connection to the Platform or the information thereon or information or materials downloaded or streamed therefrom (including but not limited to Financial Information or TradingDoX Output); (ii) your participation in any activities arising from the Platform or the information thereon or information or materials downloaded or streamed therefrom (including but not limited to your use of or reliance on any Financial Information or TradingDoX Output); (iii) your violation of, or failure to perform your obligations under the TOU or the Privacy Policy; or (iv) your violation of any rights of a third party.</p>
                        <p className='title'>
                            15. Governing Laws and Jurisdiction
                        </p>
                        <p>These TOU, and your use of the Platform shall be governed by and construed in accordance with the laws of the Province of Quebec, Canada in force at the time without regard to conflict of law provisions. You agree that any legal action or proceeding between you and TradingDoX shall be brought exclusively in the courts located in the judicial district of Montreal, Quebec, Canada. Notwithstanding the foregoing, TradingDoX shall have the right to bring action against you in courts of competent jurisdiction in the jurisdiction in which you reside or are located: (i) to seek injunctive relief against you; (ii) to obtain a judgment against you where a judgment by the Montreal court will, or may not be, enforced by the jurisdiction in which you reside or are located; or (iii) to enforce in your jurisdiction a judgment obtained against you in the Montreal court.</p>
                        <p className='title'>
                            16. Miscellaneous Provisions
                        </p>
                        <ol>
                            <li>The TOU, in conjunction with the Privacy Policy, constitute the entire agreement between you and TradingDoX with respect to your use of the Website and Platform, superseding any prior agreements between you and TradingDoX, its predecessors, affiliates or subsidiaries. </li>
                            <li>TradingDoX shall not be liable for any failure to perform its obligations under the present TOU
                                where such failure results from any cause beyond TradingDoX’ reasonable control, including, but not limited to, mechanical, electronic or communications failure or degradation, or any force majeure event.</li>
                            <li>If any provision of the TOU or Privacy Policy is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavour to give effect to the parties' intentions as reflected in the provision, and the other provisions of the TOU or Privacy Policy, as the case may be, shall remain in full force and effect.</li>
                            <li>The failure of TradingDoX to exercise or enforce any right or provision of these TOU does not constitute a waiver of such right or provision, which will still be available to TradingDoX.</li>
                            <li>The section titles in the TOU and Privacy Policy are for convenience only and have no legal or contractual effect.</li>
                            <li>The parties acknowledge having expressly required that these TOU and the Privacy Policy are to be drawn up in the English language. <i>Les parties aux présentes reconnaissent avoir expressément exigé que les présentes Conditions d’utilisation et la Politique de confidentialité soient rédigées en anglais.</i> </li>
                        </ol>
                        <p>© TradingDoX Technologies Inc. 2022</p>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default TermsOfUse