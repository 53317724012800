import { Form, Row, Col } from "antd";
import { Formik, FormikValues } from "formik";
import React, { useEffect } from "react";
import { Faq } from "../../../../../models/Faq/faq.model";
import InputField from "../../../../../shared/components/InputField";
import { UIModal } from "../../../../../shared/components/UIModal";
import { FaqFormValidation } from "./faqFormValidation";
import "./faqForm.scss"

interface FaqFormProps {
    visible: boolean
    loading: boolean
    closeHandler: () => void
    submitHandler: (values: FormikValues, resetForm: Function) => void
    faqData?: Faq
}

const FaqForm = (props: FaqFormProps) => {

    const {
        visible,
        closeHandler,
        submitHandler,
        loading,
        faqData = new Faq()
    } = props

    return (
        <Formik enableReinitialize
            initialValues={faqData}
            onSubmit={() => { }}
            validationSchema={FaqFormValidation}
        >
            {({ setFieldValue, errors, values, dirty, resetForm, isValid, setFieldTouched }) => {
                return (
                    <UIModal

                        className="faq-form__modal"
                        visible={visible}
                        closeModal={closeHandler}
                        title={(faqData.id ? "Edit " : "Add New ") + " FAQ"}
                        submitProps={{
                            text: faqData.id ? "Update " : "Create",
                            clickHandler: () => {
                                submitHandler(values, resetForm);
                            },
                            disabled: !isValid || !dirty,
                            loading
                        }}
                        cancelProps={{
                            text: "Cancel",
                            clickHandler: () => {
                                resetForm();
                                closeHandler();
                            },
                        }}
                    >
                        <Form>
                            <Row>
                                <Col sm={24} className="faq-form__field">
                                    <InputField
                                        placeholder={"FAQ Title *"}
                                        name="title"
                                        type="text"
                                        value={values?.title}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="faq-form__field">
                                    <InputField
                                        placeholder={"Description *"}
                                        name="descrption"
                                        type="textarea"
                                        value={values?.descrption}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </UIModal>
                );
            }}
        </Formik>
    );
}

export default FaqForm