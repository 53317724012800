import moment from "moment";
import React, { FC, Fragment, useEffect, useState } from "react";
import DoxCoinsService from "../../../services/DoxCoinsService/DoxCoins.service";
import { PageHeaderComponent } from "../../../shared/components/PageHeader";
import { TableComponent } from "../../../shared/components/TableComponent";
import DoXDashboard from "./DoXDashboard";
import "./dox.scss";
import { LocalStorage } from "../../../shared/utils/localStorageHelpers";
import { roles } from "../../../shared/components/HOC/roles";
import AppModal from "../../../shared/components/AppModal";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import RedemptionForm from "./RedempetionForm";
import CoinAllocationForm from "./CoinAllocationForm";
import { Modal, Tooltip } from "antd";
import RedeemDoxConfirmation from "./RedeemDoxConfirmation";
import LayoutContainer from "../../../store/containers/layoutContainer";
import { LayoutReducerProps } from "../../../store/reducers/layoutReducer";
import DoxCoinsForm from "./DoXCoinsForm";
import { useLocation } from "react-router";
import { Service } from "../../../models/Service/service.model";

const historyColumns = [
  {
    title: "DATE",
    dataIndex: "date",
    key: "date",
    width: 250,
    ellipsis: { showTitle: false },
    render: text => <Tooltip placement="topLeft" title={text ? moment(text).format("MMM D, YYYY") : ""}>
      {text ? moment(text).format("MMM D, YYYY") : ""}
    </Tooltip>
    // render: (date: string) => moment(date).format("MMM D, YYYY"),
  },
  {
    title: "ACTIONS",
    dataIndex: "actionType",
    key: "actionType",
    width: 300,
  },
  {
    title: "DOX COINS",
    dataIndex: "points",
    key: "points",
    width: 70,
    render: (text) => <p className="text-right">{text}</p>,
  },
];

interface DoxProps extends LayoutReducerProps { }

const Dox: FC<DoxProps> = ({ setDoxCoin }) => {
  const {
    company,
    companies,
    doxHistories,
    redeemedServices,
    allocations,
    getPointHistories,
    getRedeemedServices,
    getAllocation,
    getTotalDoxCoins,
    getDoxCoinCirculation,
    setRedeemedServices,
    deleteRedeemedService,
    getRedeemDoXCoin,
    updateCompanyDoxCoin,
  } = DoxCoinsService();

  const redeemDox = [
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: 350,
      render: (text, record) => (
        <AppModal
          triggerComponent={(openModal) => (
            <div
              hidden={company?.doxCoins < record?.requiredNoOfCoins}
              className="text-right text-primary cursor-pointer text-link"
              onClick={async () => {
                const company = await getRedeemDoXCoin(record?.id?.toString());
                if (company) {
                  setDoxCoin(company?.doxCoins);
                  openModal();
                }
              }}
            >
              Redeem DoX Coins
            </div>
          )}
          noPadding
          renderComponent={(closeModal) => (
            <RedeemDoxConfirmation closeHandler={closeModal} />
          )}
        />
      ),
    },
  ];

  const user = LocalStorage.getItem("USER");

  const isHl = user?.role?.includes("hl");

  const paths = useLocation()?.pathname?.split("/");

  const showOnlyHistory = paths?.length >= 3;

  useEffect(() => {
    if (!isHl || showOnlyHistory) {
      getPointHistories({
        company_id: paths[2],
      });
    }
    getRedeemedServices();
    getAllocation();
    getTotalDoxCoins();
    getDoxCoinCirculation();
  }, []);

  const serviceColumns = [
    {
      title: "SERVICE",
      dataIndex: "name",
      key: "name",
      width: 400,
      ellipsis: { showTitle: false },
      render: text => <Tooltip placement="topLeft" title={text || ""}>
        {text || ""}
      </Tooltip>
    },
    {
      title: "DOX COINS",
      dataIndex: "requiredNoOfCoins",
      key: "requiredNoOfCoins",
      width: 75,
      render: (text) => <p className="text-right">{text}</p>,
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: 350,
      render: (text, record: Service) => (
        <div className="text-right">
          <AppModal
            title="Edit Coins redemption offering"
            triggerComponent={(openModal) => (
              <i
                className="icon-edit"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal();
                }}
              />
            )}
            renderComponent={(closeModal) => (
              <RedemptionForm
                formValues={record}
                successModalHandler={(service) => {
                  const index = redeemedServices.findIndex(
                    (s) => s.id === service.id
                  );
                  if (index >= 0) {
                    const services = [...redeemedServices];
                    services.splice(index, 1, service);
                    setRedeemedServices(services);
                  }
                  closeModal();
                }}
                closeModalHandler={closeModal}
              />
            )}
          />
          {record?.deletable && (
            <i
              className="icon-delete"
              onClick={(e) => {
                e.stopPropagation();
                Modal.warning({
                  content: `Are you sure you want to delete ${record?.name}`,
                  okText: "Delete",
                  cancelText: "Cancel",
                  okButtonProps: {
                    type: "ghost",
                  },
                  onOk: async (close) => {
                    const isDeleted = await deleteRedeemedService(record);
                    if (isDeleted) {
                      const index = redeemedServices.findIndex(
                        (s) => s.id === record?.id
                      );
                      if (index) {
                        const services = [...redeemedServices];
                        services.splice(index, 1);
                        setRedeemedServices(services);
                      }
                      close();
                    }
                  },
                });
              }}
            ></i>
          )}
        </div>
      ),
    },
  ];

  const mechanismColumns = [
    {
      title: "ACTIONS",
      dataIndex: "actionsType",
      key: "actionsType",
      width: 500,
      ellipsis: { showTitle: false },
      render: text => <Tooltip placement="topLeft" title={text || ""}>
        {text || ""}
      </Tooltip>
    },
    {
      title: "DOX COINS",
      dataIndex: "allocatedNoOfCoins",
      key: "allocatedNoOfCoins",
      width: 65,
      render: (text) => <p className="text-right">{text}</p>,
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: 200,
      render: (text, record) => (
        <div className="text-right">
          <AppModal
            title="Edit Coins allocation mechanism"
            triggerComponent={(openModal) => (
              <i
                className="icon-edit"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal();
                }}
              />
            )}
            renderComponent={(closeModal) => (
              <CoinAllocationForm
                formValues={record}
                successHandler={async (updatedDoxCoinAllocation) => {
                  await getAllocation();
                  closeModal();
                }}
                closeModalHandler={closeModal}
              />
            )}
          />
        </div>
      ),
    },
  ];

  const circulationColumns = [
    {
      title: "HFUND COMPANY",
      dataIndex: "name",
      key: "name",
      width: 500,
      ellipsis: { showTitle: false },
      render: text => <Tooltip placement="topLeft" title={text || ""}>
        {text || ""}
      </Tooltip>
    },
    {
      title: "DOX COINS",
      dataIndex: "doxCoins",
      key: "doxCoins",
      width: 65,
      render: (text) => <p className="text-right">{text}</p>,
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: 200,
      render: (text, record) => (
        <div className="text-right">
          <AppModal
            title="Edit DoX Coins in circulation"
            triggerComponent={(openModal) => (
              <i
                className="icon-edit"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal();
                }}
              />
            )}
            renderComponent={(closeModal) => (
              <DoxCoinsForm
                company={record}
                submitHandler={async (company) => {
                  if (await updateCompanyDoxCoin(company)) {
                    closeModal();
                    getDoxCoinCirculation()
                  }
                }}
                closeHandler={closeModal}
              />
            )}
          />
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      <DoXDashboard company={company} />
      <div className="dox__container">

        {!showOnlyHistory && (
          <Fragment>
            <div className="dox-mechanism__container">
              <TableComponent
                title="Coins allocation mechanism"
                showSearch={false}
                columns={[...mechanismColumns].splice(0, isHl ? 3 : 2)}
                data={allocations}
              />
            </div>
            <div className="dox-services__container">
              <TableComponent
                title="DoX Coins can be redeemed for following services"
                showAdd={isHl}
                showSearch={false}
                addComponent={
                  <AppModal
                    title="Add New Coins redemption offering"
                    triggerComponent={(openModal) => (
                      <ButtonComponent
                        onClick={openModal}
                        type="primary"
                        icon={<i className="icon-add light" />}
                      >
                        {" "}
                      </ButtonComponent>
                    )}
                    renderComponent={(closeModal) => (
                      <RedemptionForm
                        successModalHandler={(service) => {
                          setRedeemedServices((services) => [
                            ...services,
                            service,
                          ]);
                          closeModal();
                        }}
                        closeModalHandler={closeModal}
                      />
                    )}
                  />
                }
                columns={[
                  ...[...serviceColumns].splice(0, isHl ? 3 : 2),
                  ...[...redeemDox].splice(0, isHl ? 0 : 1),
                ]}
                data={redeemedServices}
              />
            </div>
          </Fragment>
        )}
        {!showOnlyHistory && (
          <Fragment>
            {isHl && (
              <div className="dox-circulation__container">
                <TableComponent
                  title="DoX Coins in circulation"
                  showSearch={false}
                  columns={circulationColumns}
                  data={companies}
                />
              </div>
            )}
          </Fragment>
        )}
        {(user?.role?.includes("hf") || showOnlyHistory) && (
          <div className="dox-history__container">
            <TableComponent
              title="DoX Points History"
              showSearch={false}
              columns={historyColumns}
              data={doxHistories}
              showAdd={false}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default LayoutContainer(Dox);
