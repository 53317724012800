import { serializable, alias, primitive } from "serializr";

export class NotificationModel{
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("due_time", primitive()))
  dueTime?: string;

  @serializable(alias("completed_at", primitive()))
  completedAt?: string;
  
}
