import { Col, Form, Row } from "antd";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Notification from "../../../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../../../enums/notificationTypes";
import { Fund } from "../../../../../../models/Fund/fund.model";
import { ProfileModel } from "../../../../../../models/HFund/hfund.model";
import { FundsService } from "../../../../../../services/FundService/Fund.service";
import { MetaService } from "../../../../../../services/MetaService/meta.service";
import DropdownField from "../../../../../../shared/components/DropdownField";
import { UIModal } from "../../../../../../shared/components/UIModal";
import { LocalStorage } from "../../../../../../shared/utils/localStorageHelpers";
import "./onboarding-form.scss";
import { OnboardingTier } from "./onboardingDefinitions";
import {
  OnboardingItem,
  OnboardingItemMetum,
  OnboardingItemStatus,
  ResponsibleUser,
} from "../../../../../../models/Fund/onboarding.model";
import { deserialize } from "serializr";
import { OnboardingValidation } from "./onboardingValidation";
import DropdownAndInputField from "../../../../../../shared/components/DropdownAndInputField";
interface OnboardingFormProps {
  visible: boolean;
  title: string;
  closeHandler: (item?: OnboardingItem) => void;
  category: string;
  id: number;
  data?: OnboardingItem;
}

const OnboardingForm = (props: OnboardingFormProps) => {
  const { visible, title, closeHandler, category, id, data } = props;
  const { state = {} }: { state: any } = useLocation();

  const formRef = React.useRef<any>();
  const [onboardingFormData, setOnboardingFormData] = useState<OnboardingItem>(
    new OnboardingItem()
  );
  const [onboardingStatus, setOnboardingStatus] = useState<any[]>([]);
  const [onboardingItems, setOnboardingItems] = useState<any[]>([]);
  const [onboardingItemOptions, setOnboardingItemOptions] = useState<any[]>([]);
  const [availableOnboardingItems, setAvailableOnboardingItems] = useState<any[]>([]);
  const [responsibleUsers, setResponsibleUsers] = useState<any[]>([]);

  useEffect(() => {
    setOnboardingItemOptions(onboardingItems.filter(item => !availableOnboardingItems.some(availableItem => availableItem.onboardingItemMetumId === item.value)))
  }, [onboardingItems, availableOnboardingItems])

  useEffect(() => {
    visible && fetchItems()
  }, [visible])

  useEffect(() => {
    MetaService.onboardingItemStatus(
      (res) => {
        const data = deserialize(
          OnboardingItemStatus,
          res.data["onboarding_item_statuses"]
        ) as OnboardingItemStatus[];
        setOnboardingStatus(
          data.map((data) => {
            return { label: data.name.replaceAll("_", " "), value: data.id };
          })
        );
      },
      (err) => {
        // ;
      }
    );
    MetaService.responsibleHFUsers(
      id.toString(),
      (res) => {
        const data = deserialize(
          ResponsibleUser,
          res.data["users"]
        ) as ResponsibleUser[];
        setResponsibleUsers(
          data.map((data) => {
            return {
              value: data.id,
              label: data.firstName + " " + data.lastName,
            };
          })
        );
      },
      (err) => {
        // ;
      }
    );
  }, []);

  useEffect(() => {
    if (!category) {
      return;
    }
    MetaService.onboardingItemMeta(
      category.toLowerCase(),
      (res) => {
        const data = deserialize(
          OnboardingItemMetum,
          res.data["onboarding_item_meta"]
        ) as OnboardingItemMetum[];
        setOnboardingItems(
          data.map((data) => {
            return { value: data.id, label: data.name };
          })
        );
      },
      (err) => {
        // ;
      }
    );
  }, [category]);

  const onSubmit = (values: OnboardingItem, resetForm: Function) => {
    if (values.id) {
      FundsService.updateOnboardingItem(
        id,
        values,
        (res: OnboardingItem) => {
          Notification({
            message: "Hedge Fund",
            description: "Onboarding item edited successfully",
            type: NotificationTypes.SUCCESS,
          });
          resetForm();
          closeHandler(res);
        },
        (err) => {
          // ;
          // Notification({
          //   message: "Hedge Fund",
          //   description: "Unable to edit onboarding item",
          //   type: NotificationTypes.ERROR,
          // });
        },
        () => { }
      );
    } else {
      FundsService.createOnboardingItem(
        id,
        values,
        (res: OnboardingItem) => {
          Notification({
            message: "Hedge Fund",
            description: "New onboarding item added",
            type: NotificationTypes.SUCCESS,
          });
          resetForm();
          closeHandler(res);
        },
        (err) => {
          // ;
          // Notification({
          //   message: "Hedge Fund",
          //   description: "Unable to add new onboarding item",
          //   type: NotificationTypes.ERROR,
          // });
        },
        () => { }
      );
    }
  };

  useEffect(() => {
    if (data) {
      setOnboardingFormData({
        ...data,
        onboardingItemMetumName: data?.onboardingItemMetumName || data?.onboardingItemMetum?.name
      });
    } else {
      setOnboardingFormData(new OnboardingItem());
    }
  }, [data]);

  const fetchItems = () => {
    FundsService.fetchOnboardingItem(
      id.toString(),
      {
        search_text: "",
        category: category?.toLowerCase()
      },
      (items: OnboardingItem[]) => {
        setAvailableOnboardingItems(items)
      },
      (error) => {
        //console.log(error);
        setAvailableOnboardingItems([])
      },
      () => { }
    );
  };

  return (
    <Formik
      validationSchema={OnboardingValidation}
      enableReinitialize
      initialValues={onboardingFormData}
      onSubmit={() => { }}
    >
      {({
        setFieldValue,
        values,
        resetForm,
        isValid,
        dirty,
      }) => {
        return (
          <UIModal
            className="onboarding-form__modal"
            visible={visible}
            closeModal={() => {
              resetForm();
              closeHandler();
            }}
            title={
              (onboardingFormData.id ? "Edit " : "Add New ") +
              (category + " Onboarding Item")
            }
            submitProps={{
              text: onboardingFormData.id ? "Update" : "Create",
              clickHandler: () => {
                setFieldValue('onboardingItemMetumCategory', category.toLowerCase())
                onSubmit(values, resetForm);
              },
              disabled: !dirty || !isValid,
            }}
            cancelProps={{
              text: "Cancel",
              clickHandler: () => {
                resetForm();
                closeHandler();
              },
            }}
          >
            <Form>
              <div className="onboarding-form__container">
                <Row>
                  <Col sm={24} className="onboarding-form__field">
                    <DropdownAndInputField
                      value={values.onboardingItemMetumName || values?.onboardingItemMetum?.name}
                      options={onboardingItemOptions}
                      onSelect={(value, object) => {
                        setFieldValue("onboardingItemMetumId", value);
                        setFieldValue("onboardingItemMetumName", object.label);
                        setFieldValue('onboardingItemMetumCategory', category.toLowerCase())
                      }}
                      onSearch={(value) => {
                        // setFieldValue("onboardingItemMetumName", value);
                        // setFieldValue("onboardingItemMetumId", null);
                      }}
                      placeholder="Item"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} className="onboarding-form__field">
                    <DropdownField
                      mode="single"
                      className="captialize"
                      optionFilterProp="label"
                      placeholder={"Status"}
                      name="onboardingItemStatusId"
                      options={onboardingStatus}
                      showSearch={false}
                      value={values.onboardingItemStatusId}
                      onChange={(value) => {
                        setFieldValue("onboardingItemStatusId", value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} className="onboarding-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"Tier"}
                      name="tier"
                      options={OnboardingTier}
                      showSearch={false}
                      value={values.tier}
                      onChange={(value) => setFieldValue("tier", value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} className="onboarding-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"Responsible"}
                      name="responsibleUserId"
                      options={responsibleUsers}
                      showSearch={false}
                      value={values.responsibleUserId}
                      onChange={(value) =>
                        setFieldValue("responsibleUserId", value)
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Form>
          </UIModal>
        );
      }}
    </Formik>
  );
};

export default OnboardingForm;
