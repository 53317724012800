import * as Yup from "yup";

export const HFundValidation = Yup.object().shape({
  companyName: Yup.string().required("Company Name is required!"),
  assetUnderManagement: Yup.string().required("Asset is required!"),
  strategy: Yup.string().required("Strategy is required!"),
  street: Yup.string().required("Street is required!"),
  country: Yup.string().required("Country is required!"),
  city: Yup.number().required("City is required!"),
  state: Yup.number().required("State is required!"),
  zipCode: Yup.string()
    // .matches(/^[0-9]{1,}$/, "Zip Code/Postal Code should be valid!")
    .required("Zip Code/Postal Code is required!"),
  contactPersonName: Yup.string().required("Contact Person Name is required!"),
  contactPersonTitle: Yup.string().required(
    "Contact Person Title is required!"
  ),
  contactPersonPhone: Yup.string()
    // .matches(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/, "Mobile number should be valid!")
    .required("Contact Person Phone is required!"),
  contactPersonEmail: Yup.string()
    .email("E-mail is not valid!")
    .required("Contact Person Email is required!"),
  companyRoleName: Yup.string()
    .required("Position is required!"),
  email: Yup.string()
    .email("E-mail is not valid!")
    .required("E-mail is required!"),
  mobileNumber: Yup.string()
    // .matches(
    //   /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
    //   "Mobile number should be valid!"
    // )
    .required("Mobile Number is required!"),
  password: Yup.string()
    .when("id", {
      is: (id) => !id,
      then: (field: any) => field
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
          "Password should be alphanumeric and contain minimum 8 characters!"
        )
        .required("Password is required!"),
    }),
  confirmPassword: Yup.string()
    .when("id", {
      is: (id) => !id,
      then: (field: any) => field
        .required()
        .oneOf(
          [Yup.ref("password")],
          "Both passwords need to be the same"
        ),
    }),
});
