import { User } from "../User/user.model";

import { Fund } from "../Fund/fund.model";

import { serializable, alias, object, list, primitive } from "serializr";
import { Changeset } from "../Changeset/changeset.model";

export class AuditTrail {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("item_type", primitive()))
  itemType?: string;

  @serializable(alias("item_id", primitive()))
  itemId?: number;

  @serializable(alias("event", primitive()))
  event?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("fund", object(Fund)))
  fund?: Fund;

  @serializable(alias("user", object(User)))
  user?: User;

  @serializable(alias("changeset", object(Changeset)))
  changeset?: Changeset;
}
