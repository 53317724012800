import { Card, Col, Row, Skeleton, Spin } from "antd";
import React, { FC, useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router";
import { Topic } from "../../../../../models/Topic/topic.model";
import { DISCUSSION_BOARD } from "../../../../../routes/appRoutes";
import DiscussionBoardService from "../../../../../services/DiscussionBoardService/DiscussionBoard.service";
import AppModal from "../../../../../shared/components/AppModal";
import DiscussionForm from "../../../../DiscussionBoard/DiscussionList/DicussionForm";
import "./DiscussionBoard.scss";
import Notification from "../../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../../enums/notificationTypes";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";

interface DiscussionBoardProps {
  companyId?: string
}

const DiscussionBoard: FC<DiscussionBoardProps> = (props) => {
  const {
    companyId,
  } = props


  const { topics, getTopics, createTopic } = DiscussionBoardService();

  const [loader, setLoader] = useState(false);

  const history = useHistory();

  const user = LocalStorage.getItem("USER");

  const fetchTopics = async () => {
    setLoader(true);
    await getTopics("explore", {
      order_by: "recently_viewed",
      order_direction: "dsc",
      company_id: companyId,
      page: 1,
      per_page: 3
    });
    setLoader(false);
  };

  useEffect(() => {
    fetchTopics();
  }, []);

  const handleRedirectToDiscussionBoard = (topic: Topic) => {
    history.push(`${DISCUSSION_BOARD}?topicId=${topic.id}`);
  };

  return (
    <Card
      className="dashboard__discussion-board"
      title="Discussion Board"
      extra={
        <AppModal
          renderComponent={(closeModal) => (
            <DiscussionForm
              successHandler={(topic) => {
                createTopic(topic);
                closeModal();
              }}
              closeHandler={closeModal}
            />
          )}
          triggerComponent={(openModal) => (
            <span onClick={() => {
              if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == parseInt(companyId))?.length == 0)) {
                Notification({
                  message: "Hedge Legal",
                  description: "Access Restricted",
                  type: NotificationTypes.ERROR,
                });
              } else {
                openModal()
              }

            }}>
              <i className="icon-add" /> Add Topics
            </span>
          )}
        />
      }
    >
      {loader ? (
        <div className="loader">
          <Spin />
        </div>
      ) : (
        topics.slice(0, 8)?.map((topic) => (
          <Row
            justify="space-between"
            className="topic"
            onClick={() => handleRedirectToDiscussionBoard(topic)}
          >
            <Col span={22}>
              <span>{topic.description}</span>
            </Col>
            <Col span={2}>
              <span className="no-of-comments">
                {topic.noOfComments.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                })}
              </span>
            </Col>
          </Row>
        ))
      )}
    </Card>
  );
};

export default DiscussionBoard;
