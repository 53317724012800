import * as Yup from "yup";

export const AgreementValidation = Yup.object({
    fundId: Yup.string().required("Fund is required!"),
    cpId: Yup.number().required("CP is required!"),
    agreementTypeId: Yup.number().required("Agreement type is required!"),
    name: Yup.string().required("Name is required!"),
    statusId: Yup.number().required("Status is required!"),
    tier: Yup.string().required("Tier is required!"),
})
