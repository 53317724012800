import {
  Badge,
  Button,
  Col,
  Input,
  List,
  Pagination,
  Progress,
  Row,
  Spin,
  Tabs,
} from "antd";
import React, {
  Fragment,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  BellOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  SearchOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import "./globalSearch.scss";
import SearchIcon from "../../../assets/icons/search.svg";
import GoIcon from "../../../assets/icons/go.svg";
import Logo from "../../../assets/background-images/app-logo.png";
import ReviewStar from "../../../assets/icons/star.svg";
import LocationIcon from "../../../assets/icons/marker.svg";
import faker from "faker";
import { CounterParties } from "../../../models/CounterParties/counterParties.model";
import { Fund } from "../../../models/Fund/fund.model";
import { Agreement } from "../../../models/Agreements/agreement.model";
import { useHistory } from "react-router-dom";
import {
  AGREEMENTS_SPECIFIC,
  FUNDS_SPECIFIC,
  AGREEMENTS,
  CP_DETAILS,
} from "../../../routes/appRoutes";
import { LocalStorage } from "../../utils/localStorageHelpers";
import GlobalSearchService from "../../../services/GlobalSearch/globalSearch.service";
import AuthContainer from "../../../store/containers/AuthContainer";
import { IAuthState } from "../../../store/reducers/authReducer";
import * as AppRoutes from "../../../routes/appRoutes";
import DiscussionCard from "../../../components/DiscussionBoard/DiscussionList/DiscussionCard";
import { Topic } from "../../../models/Topic/topic.model";
import AppModal from "../AppModal";
import Notification from "../../../components/App/Notification";
import RevertChanges from "../RevertChanges";
import NotificationService from "../../../services/NotificationService/notification.service";
import { ACTION_CABLE_URL } from "../../../routes/apiRoutes";
import moment from "moment";
import { Document } from "../../../models/Document/document.model";
import { Pagination as PaginationMeta } from "../../../models/Pagination/pagination.model";
import EmptyState from "../EmptyState";
// import initActionCable from "../../../actionCable";

const tabs = [
  "Funds",
  "Agreements",
  "Agreement Text Search",
  // "Discussion Board",
  // "Key terms",
  // "Ancillary Documents",
  "CPs/Brokers",
];

interface GlobalSearchProps extends IAuthState { }

const GlobalSearch = (props: GlobalSearchProps) => {
  const { userType, user } = props;
  const { TabPane } = Tabs;
  const history = useHistory();

  const [searchMode, setSearchMode] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>();
  const [searchString, setSearchString] = useState<string>();
  const [selectedType, setSelectedType] = useState<string>("0");
  const [results, setResults] = useState<ReactElement>();
  const [auditId, setAuditId] = useState<boolean>();

  const searchActiveContainer = useRef(null);
  const searchInput = useRef(null);

  const {
    loading,
    searchTerms,
    searchResults,
    keyTerms,
    documents,
    fetchRecentSearches,
    fetchSearchResults,
    getKeyTerm,
    getDocuments,
    searchPagination,
    documentPagination,
  } = GlobalSearchService();

  const {
    otherNotifications,
    getOtherNotifications,
    clearNotification,
    initNotificationConnection,
    markAsRead,
  } = NotificationService();

  useEffect(() => {
    const user = LocalStorage.getItem("USER");
    setIsAdmin(user && user["hasAccessToAllHfs"]);
    getOtherNotifications();
    initNotificationConnection(user?.id);
  }, []);

  useEffect(() => {
    searchInput.current?.focus();
    fetchRecentSearches();
    // initActionCable();
  }, [searchMode]);

  useEffect(() => {
    let template;
    switch (selectedType) {
      case "0":
        template = fundsListTemplateGenerator(searchResults);
        break;
      case "1":
        template = agreementsListTemplateGenerator(searchResults);
        break;
      case "2":
        DocumentsList();
        break;
      case "3":
        template = cpListTemplateGenerator(searchResults);
        // template = DiscussionTabList(searchResults);
        // alert("Fetch ancillary docs pending")
        break;
      // case "4":
      //   template = cpListTemplateGenerator(searchResults);
      // break;
      default:
        break;
    }
    setResults(template);
  }, [searchResults, selectedType]);

  const DocumentsList = (docs?: Document[], page?: PaginationMeta) => {
    try {
      setResults(
        (docs ?? documents).length
          ? <div className="document-list mt-4 cursor-pointer">
            <Pagination
              className="mt-5 mb-5"
              showSizeChanger={false}
              pageSize={page.perPage || documentPagination?.perPage || 20}
              current={page.current || documentPagination?.current || 1}
              total={page.totalCount || documentPagination?.totalCount || 0}
              showTotal={(total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>}
              onChange={async page => {
                const documentData = await getDocuments(searchString, page || 1);
                setTimeout(() => {
                  documentData && DocumentsList(documentData.documents, documentData.pagination);
                }, 300)
              }}
            />
            {(docs ?? documents)?.map((document, index) => (
              <Row
                justify="space-between"
                key={index}
                onClick={() => {
                  let params = "";
                  if (document.agreementModel === "ancillary_documents") {
                    params = new URLSearchParams({
                      type: "ancillary",
                      id: document.ancillaryDocumentId
                    }).toString();
                  }
                  // handleClose();
                  const PATH =
                    AGREEMENTS + "/" + document?.agreementId;
                  // AGREEMENTS + "/" + document?.agreementId + "?" + params;
                  // history.push(PATH);
                  window.open(PATH)
                }}
              >
                {/* <Col>
                <p className="label">Ancillary Document</p>
                <p className="text">{document.counterPartyName}</p>
              </Col> */}
                <Col span={6}>
                  <p className="label">Agreement</p>
                  <p className="text">{document.agreementName}</p>
                </Col>
                <Col span={5}>
                  <p className="label">Fund Name</p>
                  <p className="text">{document.fundName}</p>
                </Col>
                <Col span={5}>
                  <p className="label">CP Name</p>
                  <p className="text">{document.counterPartyName}</p>
                </Col>
                <Col span={4}>
                  <p className="label">Type</p>
                  <p className="text">{document.agreementType}</p>
                </Col>
                <Col span={4}>
                  <p className="label">Date</p>
                  <p className="text">
                    {moment(document.agreementCreatedAt).format("MMM, DD YYYY")}
                  </p>
                </Col>
              </Row>
            ))}
            <Pagination
              className="mt-5 mb-5"
              showSizeChanger={false}
              pageSize={page.perPage || documentPagination?.perPage || 20}
              current={page.current || documentPagination?.current || 1}
              total={page.totalCount || documentPagination?.totalCount || 0}
              showTotal={(total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>}
              onChange={async page => {
                const documentData = await getDocuments(searchString, page || 1);
                setTimeout(() => {
                  documentData && DocumentsList(documentData.documents, documentData.pagination);
                }, 300)
              }}
            />
          </div>
          : <EmptyState />
      );
    } catch (error) {
    }
  };

  useEffect(() => {
    if (!searchMode) {
      return;
    }
    fetchResults(searchString);
  }, [selectedType]);

  const fetchResults = async (searchText?: string, page?: number) => {
    // if (selectedType === "4") {
    //   await getKeyTerm(searchText);
    //   setTimeout(() => {
    //     KeyTermsList();
    //   }, 300);
    // } else
    if (selectedType === "2") {
      const documentData = await getDocuments(searchText, 1);
      setTimeout(() => {
        documentData && DocumentsList(documentData.documents, documentData.pagination);
      }, 300);
    } else {
      const searchCategory =
        selectedType === "0"
          ? "funds"
          : selectedType === "1"
            ? "agreements"
            // : selectedType === "2"
            // ?
            : "cps"
      // : "discussion_board";
      fetchSearchResults(searchString || searchText, searchCategory, page || 1);
    }
  };

  const handleSearch = (e) => {
    if (e.charCode === 13) {
      fetchResults(searchString);
    }
  };

  const handleSearchFromRecent = (searchText: string) => {
    setSearchString(searchText);
    fetchResults(searchText);
  };

  const handleSearchInput = (e: any) => {
    const value = (e.target as HTMLInputElement).value;
    setSearchString(value);
  };

  const handleClearSearch = () => {
    setSearchString("");
  };

  const handleRoute = (
    route: string,
    replaceString: string,
    id: string,
    state = {}
  ) => {
    // handleClose();
    // history.push(route.replace(replaceString, id), state);
    window.open(route.replace(replaceString, id))
  };

  const handleClose = () => {
    const searchContainer = searchActiveContainer.current as HTMLDivElement;
    setSearchString(undefined);
    searchContainer.classList.add("slideOut");
    setTimeout(() => {
      setSearchMode(false);
      setSelectedType("0");
    }, 1000);
  };

  const handleTabChange = (activeKey: string) => {
    setSelectedType(activeKey);
  };

  const fundsListTemplateGenerator = (funds: Fund[]) => {
    if (!funds.length) return <EmptyState />
    return (
      <>
        <Pagination
          className="mt-5 mb-5"
          showSizeChanger={false}
          pageSize={searchPagination?.perPage || 20}
          current={searchPagination?.current || 1}
          total={searchPagination?.totalCount || 0}
          showTotal={(total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>}
          onChange={page => fetchResults(searchString, page)}
        />
        {funds?.map((fund) => (
          <div className="fund-card">
            <div className="fund-card--intro">
              <p className="fund-card--intro--title">
                {isAdmin ? fund?.companyName : fund?.name}
              </p>
              {isAdmin && (
                <p className="fund-card--intro--subtitle">{fund?.name}</p>
              )}
              <p className="fund-card--intro--details">
                <span className="fund-card--intro--details--city">
                  {fund.state}
                </span>
                <span className="fund-card--intro--details--type">
                  {fund.strategy}
                </span>
              </p>
            </div>
            <Row className="fund-card--progress__container">
              <Col>
                <div className="fund-card--progress">
                  <Progress
                    status="normal"
                    className="fund-card--progress-bar"
                    type="circle"
                    percent={fund?.progress || 0}
                    width={80}
                    strokeWidth={10}
                  ></Progress>
                  <div className="fund-card--progress-about">
                    <div className="fund-card--progress-about--title">
                      Active Fund
                    </div>
                    <div className="fund-card--progress-about--value">
                      {fund?.launchDate ? "launched on " : ""}{" "}
                      <b>{fund?.launchDate}</b>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                className="fund-card--progress-about__link"
                onClick={() => {
                  LocalStorage.setItem(
                    "HL_SELECTED_COMPANY_ID",
                    fund?.companyId
                  );
                  handleRoute(FUNDS_SPECIFIC, ":fundId", fund?.id?.toString());
                }}
              >
                <img src={GoIcon} alt="search" />
              </Col>
            </Row>
          </div>
        ))}
        <Pagination
          className="mt-5 mb-5"
          showSizeChanger={false}
          pageSize={searchPagination?.perPage || 20}
          current={searchPagination?.current || 1}
          total={searchPagination?.totalCount || 0}
          showTotal={(total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>}
          onChange={page => fetchResults(searchString, page)}
        />
      </>
    );
  };

  const agreementsListTemplateGenerator = (agreements: Agreement[]) => {
    if (!agreements.length) return <EmptyState />
    return (
      <>
        <Pagination
          className="mt-5 mb-5"
          showSizeChanger={false}
          pageSize={searchPagination?.perPage || 20}
          current={searchPagination?.current || 1}
          total={searchPagination?.totalCount || 0}
          showTotal={(total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>}
          onChange={page => fetchResults(searchString, page)}
        />
        {agreements?.map((agreement) => (
          <div className="agreement-card">
            <div className="agreement-card__details">
              <p className="agreement-card--title">{agreement?.name}</p>
              <Row wrap={false}>
                {agreement?.fundName && (
                  <Col className="agreement-card--details">
                    <p className="agreement-card--details--title">Fund Name</p>
                    <p className="agreement-card--details--value">
                      {agreement?.fundName}
                    </p>
                  </Col>
                )}
                {agreement?.cpName && (
                  <Col className="agreement-card--details">
                    <p className="agreement-card--details--title">CP Name</p>
                    <p className="agreement-card--details--value">
                      {agreement?.cpName}
                    </p>
                  </Col>
                )}
                {agreement?.agreementType && (
                  <Col className="agreement-card--details mini">
                    <p className="agreement-card--details--title">Type</p>
                    <p className="agreement-card--details--value">
                      {agreement?.agreementType}
                    </p>
                  </Col>
                )}
                {agreement?.date && (
                  <Col className="agreement-card--details">
                    <p className="agreement-card--details--title">Date</p>
                    <p className="agreement-card--details--value">
                      {agreement?.date}
                    </p>
                  </Col>
                )}
                {agreement?.statusName && (
                  <Col className="agreement-card--details large">
                    <p className="agreement-card--details--title">Status</p>
                    <p className="agreement-card--details--value">
                      {agreement?.statusName?.replaceAll("_", " ")}
                    </p>
                  </Col>
                )}
                {agreement?.sittingWith && (
                  <Col className="agreement-card--details">
                    <p className="agreement-card--details--title">
                      Sitting With
                    </p>
                    <p className="agreement-card--details--value">
                      {agreement?.sittingWith}
                    </p>
                  </Col>
                )}
                {agreement?.tier && (
                  <Col className="agreement-card--details mini">
                    <p className="agreement-card--details--title">Tier</p>
                    <p className="agreement-card--details--value">
                      {agreement?.tier}
                    </p>
                  </Col>
                )}
                {!!agreement?.statusProgress && (
                  <Col className="agreement-card--details mini">
                    <p className="agreement-card--details--title">Progress</p>
                    <p className="agreement-card--details--value">
                      {agreement?.statusProgress}%
                    </p>
                  </Col>
                )}
                {(agreement?.responsibleFirstName ||
                  agreement?.responsibleFirstName) && (
                    <Col className="agreement-card--details">
                      <p className="agreement-card--details--title">
                        User responsible
                      </p>
                      <p className="agreement-card--details--value">{`${agreement?.responsibleFirstName || ""
                        } ${agreement?.responsibleLastName || ""}`}</p>
                    </Col>
                  )}
              </Row>
            </div>
            <div
              className="agreement-card__link"
              onClick={() =>
                handleRoute(
                  AGREEMENTS_SPECIFIC,
                  ":agreementId",
                  agreement?.id?.toString()
                )
              }
            >
              <img src={GoIcon} alt="search" />
            </div>
          </div>
        ))}
        <Pagination
          className="mt-5 mb-5"
          showSizeChanger={false}
          pageSize={searchPagination?.perPage || 20}
          current={searchPagination?.current || 1}
          total={searchPagination?.totalCount || 0}
          showTotal={(total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>}
          onChange={page => fetchResults(searchString, page)}
        />
      </>
    );
  };

  const cpListTemplateGenerator = (cps: CounterParties[]) => {
    if (!cps.length) return <EmptyState />
    return (
      <>
        <Row>
          <Col span={24}>
            <Pagination
              className="mt-5 mb-5"
              showSizeChanger={false}
              pageSize={searchPagination?.perPage || 20}
              current={searchPagination?.current || 1}
              total={searchPagination?.totalCount || 0}
              showTotal={(total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>}
              onChange={page => fetchResults(searchString, page)}
            />
          </Col>
        </Row>
        <div className="cp-card--container">
          {cps?.map((cp) => (
            <Row
              wrap={false}
              className="cp-card"
              onClick={() =>
                handleRoute(CP_DETAILS, ":cpId", cp?.id?.toString(), {
                  name: cp?.name,
                })
              }
            >
              <Col className="cp-card--image__container">
                <img
                  className="cp-card--image"
                  src={cp.logoUrl}
                />
              </Col>
              <Col className="cp-card--details__container">
                <p className="cp-card--details--title">{cp?.name}</p>
                <p className="cp-card--details--subtitle">
                  <img src={LocationIcon} alt="star" />
                  {` ${cp?.address?.street || ""}, ${cp?.address?.cityName || ""
                    }, ${cp?.address?.provinceName || ""}, ${cp?.address?.countryName || ""
                    }`}
                </p>
                <Row className="cp-card--details--section__container">
                  {/* {!!(cp?.reviewCount || cp?.ratingCount) &&  */}
                  <Col className="cp-card--details--section-1">
                    {
                      <p className="cp-card--details--section-1--title">
                        {cp?.ratingCount || 5} <img src={ReviewStar} alt="star" />
                      </p>
                    }
                    {
                      <p className="cp-card--details--section-1--value">
                        {cp?.reviewCount || 25} Reviews
                      </p>
                    }
                  </Col>
                  {/* } */}
                  {!!cp?.productsAvailable?.length && (
                    <Col className="cp-card--details--section-2">
                      <p className="cp-card--details--section-2--title">
                        Products Available
                      </p>
                      <p className="cp-card--details--section-2--value">
                        {cp?.productsAvailable.join(", ")}
                      </p>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          ))}
        </div>
        <Row>
          <Col span={24}>
            <Pagination
              className="mt-5 mb-5"
              showSizeChanger={false}
              pageSize={searchPagination?.perPage || 20}
              current={searchPagination?.current || 1}
              total={searchPagination?.totalCount || 0}
              showTotal={(total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>}
              onChange={page => fetchResults(searchString, page)}
            />
          </Col>
        </Row>
      </>
    );
  };

  const DiscussionTabList = (topics: Topic[]) => {
    return (
      <div className="discussion-tab-list">
        {topics?.map((topic) => (
          <div
            className="discussion__tab__container cursor-pointer"
            onClick={() => {
              const search = new URLSearchParams({
                topicId: topic?.id?.toString(),
              })?.toString();
              history.push({
                pathname: AppRoutes.DISCUSSION_BOARD,
                search,
              });
              handleClose();
            }}
          >
            <DiscussionCard
              topic={topic}
              topicId={topic?.id}
              key={topic?.id}
              searchResult
            />
          </div>
        ))}
      </div>
    );
  };

  const KeyTermsList = () => {
    setResults(
      <div className="key-term-list">
        {keyTerms.map((keyTerm) => {
          return (
            <Row
              justify="space-between"
              className="margin-bottom-grey"
              key={keyTerm?.agreementKeyTermId}
            >
              <Col>
                <p className="label">Key Term</p>
                <p className="text">{keyTerm.keyTermName}</p>
              </Col>
              <Col>
                <p className="label">Key Term Entry</p>
                <p className="text">{keyTerm.keyTermValue}</p>
              </Col>
              <Col>
                <p className="label">Fund Name</p>
                <p className="text">{keyTerm.fundName}</p>
              </Col>
              <Col>
                <p className="label">CP Name</p>
                <p className="text">{keyTerm.counterPartyName}</p>
              </Col>
              <Col>
                <p className="label">Agreement</p>
                <p className="text">{keyTerm.agreementName}</p>
              </Col>
              <Col>
                <p className="label">Type</p>
                <p className="text">{keyTerm.agreementTypeName}</p>
              </Col>
              {/* <Col className="go-to" span={2}>
                  <i className="icon-go" />
                </Col> */}
            </Row>
          );
        })}
      </div>
    );
  };

  const searchTabList = (
    <Tabs defaultActiveKey="0" onChange={handleTabChange}>
      {tabs?.map((tab, index) => (
        <TabPane tab={tab} key={index.toString()} />
      ))}
    </Tabs>
  );

  return (
    <Fragment>
      <Row
        className="global-search__container"
        align="middle"
        justify="space-between"
      >
        {userType === "HF" && <Col className="hfund-company__container">
          {user?.logoUrl
            ? <img src={user.logoUrl} alt={user.companyName} />
            : <div className="empty-profile"></div>}
          <h2>{user.companyName}</h2>
        </Col>}
        <Col
          className="global-search__input-container"
          onClick={() => setSearchMode(true)}
        >
          <Input
            className="global-search__input"
            placeholder="Search for funds, agreements, CPs, etc..."
            prefix={<img src={SearchIcon} />}
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
        </Col>
        <Col className="global-search__options-container">
          <RevertChanges
            auditId={auditId}
            closeHandler={() => setAuditId(undefined)}
          />
          <div className="global-search__options">
            <i className="icon-undo" onClick={() => setAuditId(true)} />
            <i className="icon-refresh" onClick={() => location.reload()} />
            {userType === "HF" && (
              <i
                className="icon-help"
                onClick={() => history.push(AppRoutes.HELP)}
              />
            )}
            <AppModal
              width="85vw"
              title={
                <span>
                  <i className="icon-notification-1" />
                  &nbsp;Notification
                </span>
              }
              triggerComponent={(openModal) => (
                <Badge
                  count={
                    otherNotifications?.filter(
                      (notification) => !notification.isSeen
                    )?.length
                  }
                >
                  <i
                    className="icon-notification-1"
                    onClick={() => {
                      markAsRead();
                      openModal();
                    }}
                  />
                </Badge>
              )}
              renderComponent={(closeModal) => (
                <Notification
                  otherNotifications={otherNotifications}
                  clearNotification={clearNotification}
                  closeModal={closeModal}
                />
              )}
            />
          </div>
        </Col>
      </Row>
      {searchMode && (
        <div
          className="global-search-active__container"
          ref={searchActiveContainer}
        >
          <div className="global-search-active__header">
            <img src={Logo} alt="hedgelegal" />
          </div>
          <div className="global-search-active__body">
            <div className="global-search-active__body-form">
              <Input
                value={searchString}
                onChange={handleSearchInput}
                prefix={<SearchOutlined />}
                ref={searchInput}
                placeholder="Search for funds, agreements, CPs, etc..."
                suffix={<p onClick={handleClearSearch}>Clear</p>}
                onKeyPress={handleSearch}
              />
              <Button onClick={handleClose}>
                <CloseOutlined />
              </Button>
            </div>
            {searchString === undefined ? (
              <div className="global-search-active__body-recent">
                <p>Recent Searches</p>
                <List
                  size="small"
                  bordered={false}
                  dataSource={searchTerms}
                  renderItem={(item) => (
                    <List.Item onClick={() => handleSearchFromRecent(item)}>
                      <img src={GoIcon} alt="search" />
                      {item}
                    </List.Item>
                  )}
                />
              </div>
            ) : (
              <div className="global-search-active__body-results__container">
                {searchTabList}
                <div className="global-search-active__body-results">
                  {loading ? <Spin /> : results}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AuthContainer(GlobalSearch);
