import { Col, Row } from "antd";
import Form from "antd/lib/form/Form";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { deserialize } from "serializr";
import { CPUsersAccessLevel } from "../../../../../../../enums/counterParties";
import { UserCounterParties } from "../../../../../../../models/CounterParties/userCounterParties.model";
import UserCounterPartiesService from "../../../../../../../services/CounterPartiesService/UserCounterParties.service";
import DropdownField from "../../../../../../../shared/components/DropdownField";
import InputField from "../../../../../../../shared/components/InputField";
import { UIModal } from "../../../../../../../shared/components/UIModal";
import { CPUsersFormValidation } from "./cpUserFormValidation";
import "./cpUsersForm.scss";
interface CPUsersFormProps {
  visible: boolean;
  cpId: string | number
  data?: UserCounterParties;
  closeHandler: () => void;
}

export const CPUsersForm = ({
  visible,
  data,
  cpId,
  closeHandler,
}: CPUsersFormProps) => {
  const formRef = React.useRef<any>();
  const { state }: { state: any } = useLocation();
  const [initialValues, setInitialValues] = React.useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    access: undefined,
    status: undefined,
    password: "",
    confirmPassword: "",
  });

  const onSubmit = (values) => {
    const payload: UserCounterParties = {
      user: {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        mobileNumber: values?.mobileNumber,
        status: values?.status,
        password: values?.password,
      },
      accessLevel: values?.access,
    };

    if (data) {
      UserCounterPartiesService.updateUserCounterParties(
        cpId,
        data?.id,
        { ...payload },
        () => {
          closeHandler();
        },
        () => { },
        () => { }
      );
    } else {
      UserCounterPartiesService.createUserCounterParties(
        cpId,
        payload,
        () => {
          closeHandler();
        },
        () => { },
        () => { }
      );
    }
  };

  useEffect(() => {
    if (data) {
      setInitialValues({
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        email: data.user.email,
        mobileNumber: data.user.mobileNumber,
        status: data.user.status,
        access: data.accessLevel,
      });
    }
  }, [data]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      enableReinitialize
      innerRef={formRef}
      validationSchema={CPUsersFormValidation}
    >
      {({ setFieldValue, values, resetForm, isValid, errors }) => {
        return (
          <UIModal
            visible={visible}
            closeModal={closeHandler}
            title={data ? "Update CP User" : "Add New CP User"}
            submitProps={{
              text: data ? "Update" : "Create",
              clickHandler: () => {
                onSubmit(values);
              },
            }}
            cancelProps={{
              text: "Cancel",
              clickHandler: () => { },
            }}
          >
            <Form>
              <div className="cp-users-form__container">
                <Row justify="space-between" className="mtp-4">
                  <Col span={11}>
                    <InputField
                      placeholder={"First Name"}
                      name="firstName"
                      type="text"
                      value={values?.firstName}
                    />
                  </Col>
                  <Col span={11}>
                    <InputField
                      placeholder={"Last Name"}
                      name="lastName"
                      type="text"
                      value={values?.lastName}
                    />
                  </Col>
                </Row>

                <div className="cp-users-form__field">
                  <InputField
                    placeholder={"Email ID"}
                    name="email"
                    type="text"
                    value={values?.email}
                  />
                </div>
                <div className="cp-users-form__field">
                  <InputField
                    placeholder={"Phone Number *"}
                    name="mobileNumber"
                    type="text"
                    value={values?.mobileNumber}
                  />
                </div>
                {/* <div className="cp-users-form__field">
                  <DropdownField
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"Role"}
                    name="role"
                    options={roles}
                    showSearch={false}
                    value={values.role}
                    onChange={(value) => setFieldValue("role", value)}
                  />
                </div> */}
                <div className="cp-users-form__field">
                  <DropdownField
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"Access"}
                    name="access"
                    options={[
                      {
                        value: CPUsersAccessLevel.READ_ONLY,
                        label: "Read Only",
                      },
                      {
                        value: CPUsersAccessLevel.RESPOND_TO_REVIEWS,
                        label: "Respond To Reviews",
                      },
                      {
                        value: CPUsersAccessLevel.FULL_ACCESS,
                        label: "Full Access",
                      },
                    ]}
                    showSearch={false}
                    value={values.access}
                    onChange={(value) => setFieldValue("access", value)}
                  />
                </div>

                <div className="cp-users-form__field">
                  <DropdownField
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"Status"}
                    disabled={values?.status == "email_verification_pending"}
                    name="Status"
                    options={[
                      { value: "active", label: "Active" },
                      { value: "inactive", label: "Inactive" },
                      { value: "email_verification_pending", label: "Email Verification Pending" }
                    ]}
                    showSearch={false}
                    value={values.status}
                    onChange={(value) => setFieldValue("status", value)}
                  />
                </div>

                <div className="cp-users-form__field">
                  <InputField
                    placeholder={"Password *"}
                    name="password"
                    type="password"
                    value={values?.password}
                  />
                </div>
                <div className="cp-users-form__field">
                  <InputField
                    placeholder={"Confirm Password *"}
                    name="confirmPassword"
                    type="password"
                    value={values?.confirmPassword}
                  />
                </div>
              </div>
            </Form>
          </UIModal>
        );
      }}
    </Formik>
  );
};
