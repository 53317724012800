import {
  BellOutlined,
  CloseCircleOutlined,
  MailFilled,
  MailOutlined,
  ManOutlined,
  PhoneFilled,
  PhoneOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Checkbox, Col, Form, Input, List, Row, Select } from "antd";
import { Field, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { KeyContact } from "../../../models/Meta/key-contact.model";
import { NotificationModel } from "../../../models/Meta/notification.model";
import { HFAgreementService } from "../../../services/AgreementService/agreement.service";
import { FundsService } from "../../../services/FundService/Fund.service";
import ButtonComponent from "../ButtonComponent";
import DatePickerComponent from "../DatePickerComponent";
import DropdownField from "../DropdownField";
import InputField from "../InputField";
import Notification from "../Notification";
import "./key-contacts.scss";
import AppModal from "../../../shared/components/AppModal";

interface KeyContactsProps {
  contactableType: "Agreement" | "AncillaryDocument";
  contactableId: string;
}

const KeyContacts = (props: KeyContactsProps) => {
  const { contactableType, contactableId } = props;
  const [notifications, setNotifications] = useState<KeyContact[]>([]);
  const [notification, setNotification] = useState<KeyContact>(
    new KeyContact()
  );
  const [addFormMode, setAddFormMode] = useState<boolean>();

  useEffect(() => {
    fetchKeyContacts();
  }, []);

  const fetchKeyContacts = () => {
    HFAgreementService.fetchKeyConatcts(
      { contactableType, contactableId },
      (response) => {
        setNotifications(response);
      },
      (error) => {
        //console.log(error);
      },
      () => { }
    );
  };

  const onSubmit = (values: KeyContact, resetForm: Function) => {
    HFAgreementService.createKeyConatct(
      { contactableType, contactableId },
      values,
      (response) => {
        resetForm();
        setAddFormMode(false);
        fetchKeyContacts();
      },
      (error) => {
        //console.log(error);
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to add key contact",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  const deleteHandler = (keyContactId: string) => {
    HFAgreementService.deleteKeyConatcts(
      { contactableType, contactableId, keyContactId },
      (response) => {
        fetchKeyContacts();
      },
      (error) => {
        //console.log(error);
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to delete key contact",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  return (
    <>
      {/* {!addFormMode && ( */}
      <AppModal
        title="Add Key Contacts"
        triggerComponent={(openModal) => (
          <PlusOutlined
            className="popover-add-btn"
            onClick={openModal}
          />
        )}
        renderComponent={(closeModal) => (
          <Formik
            enableReinitialize
            initialValues={notification}
            onSubmit={() => { }}
          >
            {({ setFieldValue, values, resetForm, isValid }) => {
              return (
                <Form onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}>
                  <div className="onboarding-notification">
                    <Row>
                      <Col sm={24} className="fund-form__field">
                        <InputField
                          placeholder={"Name"}
                          name="name"
                          type="text"
                          value={values.name}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={24} className="fund-form__field">
                        <Field>
                          {({ form, field, meta }) => {
                            return (
                              <Input.Group compact>
                                {/* <DropdownField
                                  defaultValue="91"
                                  name="mobilePrefix"
                                  className="mobile-code"
                                  placeholder="+91"
                                  mode="single"
                                  showSearch={false}
                                  options={[
                                    { label: "+91", value: "91" },
                                    { label: "+95", value: "95" },
                                  ]}
                                  onChange={(value) => {
                                    setFieldValue('mobilePrefix', value)
                                  }}
                                  value="+91"
                                ></DropdownField> */}
                                <InputField
                                  placeholder={"Mobile Number"}
                                  name="phone"
                                  type="text"
                                  value={values.phone}
                                />
                              </Input.Group>
                            );
                          }}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={24} className="fund-form__field">
                        <InputField
                          placeholder={"E-mail"}
                          name="email"
                          type="text"
                          value={values.email}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={24} className="fund-form__field">
                        <InputField
                          onChange={(event) => {
                            setFieldValue("otherDetails", event.target?.value);
                          }}
                          placeholder={"Other Items"}
                          name="otherDetails"
                          type="textarea"
                          value={values.otherDetails}
                        />
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col sm={12}>
                        <ButtonComponent
                          type={"default"}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setAddFormMode(false);
                            closeModal();
                          }}
                          className="key-contacts--action"
                        >
                          Cancel
                        </ButtonComponent>
                      </Col>
                      <Col sm={12}>
                        <ButtonComponent
                          type={"primary"}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            onSubmit(values, resetForm);
                            closeModal();
                          }}
                          className="key-contacts--action"
                        >
                          Create
                        </ButtonComponent>
                      </Col>
                    </Row>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      />

      {/* )} */}
      <div className="onboarding-notification__container">
        {!addFormMode && (
          <List
            itemLayout="horizontal"
            dataSource={notifications}
            renderItem={(notification) => {
              return (
                <List.Item className="key-contacts__list" onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}>
                  <p className="key-contact--name">
                    {notification?.name}
                    <span className="key-contact-delete">
                      <CloseCircleOutlined
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          deleteHandler(notification?.id);
                          // /key_contact_management/shared/contactable_type/Agreement/contactable_id/6/key_contacts/1
                          //   key_contact_mangement/shared/contactable_type/contactable_type/contactable_id/contactable_id/key_contacts/id
                        }}
                      />
                    </span>
                  </p>
                  <p className="key-contact--phone">
                    <PhoneOutlined /> {notification?.phone}
                  </p>
                  <p className="key-contact--mail">
                    <MailOutlined /> {notification?.email}
                  </p>
                  <p className="key-contact--desc">
                    {notification?.otherDetails}
                  </p>
                </List.Item>
              );
            }}
          ></List>
        )}
      </div>
    </>
  );
};

export default KeyContacts;
