
import { serializable, alias, object, list, primitive } from 'serializr';
import { OnboardingItemMetas } from './onboardingItemMetas.model';

export class OnboardingTemplates {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('onboarding_item_metum_id', primitive()))
	onboardingItemMetumId?: number;

	@serializable(alias('onboarding_item_metum', object(OnboardingItemMetas)))
	onboardingItemMetum?: OnboardingItemMetas;

	@serializable(alias('order', primitive()))
	order?: number;

	@serializable(alias('position', primitive()))
	position?: number;

}