import { BellOutlined, PlusOutlined } from "@ant-design/icons";
import { Checkbox, Col, Form, List, Row } from "antd";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NotificationTypes } from "../../../../../../enums/notificationTypes";
import { NotificationModel } from "../../../../../../models/Meta/notification.model";
import { FundsService } from "../../../../../../services/FundService/Fund.service";
import ButtonComponent from "../../../../../../shared/components/ButtonComponent";
import DatePickerComponent from "../../../../../../shared/components/DatePickerComponent";
import Notification from "../../../../../../shared/components/Notification";
import InputField from "../../../../../../shared/components/InputField";
import { NotesAndNotificationServerItems } from "../../../../../../enums/fundDetails";

interface OtherNotificationProps {
  id: number;
}

const OtherNotification = (props: OtherNotificationProps) => {
  const { id } = props;
  const [notifications, setNotifications] = useState<NotificationModel[]>([]);
  const [notification, setNotification] = useState<NotificationModel>(
    new NotificationModel()
  );
  const [addFormMode, setAddFormMode] = useState<boolean>();

  const fetchNotifications = () => {
    FundsService.fetchNotifications(
      {
        notificationType: NotesAndNotificationServerItems.OTHER_ITEMS,
        notificationId: id.toString(),
      },
      (response) => {
        setNotifications(response);
      },
      (err) => {
        // ;
      },
      () => { }
    );
  };

  const completeNotification = (
    bool: boolean,
    notification: NotificationModel
  ) => {
    if (!bool) {
      return;
    }
    FundsService.updateNotificationCompletion(
      { notificationType: "OtherItem", notificationId: id.toString() },
      notification,
      (response) => {
        fetchNotifications();
      },
      (err) => {
        // ;
      },
      () => { }
    );
  };

  const createNotification = (values: NotificationModel) => {
    FundsService.createNotifications(
      { notificationType: "OtherItem", notificationId: id.toString() },
      values,
      (response) => {
        fetchNotifications();
        setAddFormMode(false);
        Notification({
          message: "Hedge Fund",
          description: "Notification created successfully",
          type: NotificationTypes.SUCCESS,
        });
      },
      (err) => {
        // ;
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to create notification",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const notificationsListGenerator = (notifications) =>
    notifications.map((notification) => (
      <List.Item key={notification.id}>
        <List.Item.Meta
          title={
            <>
              <BellOutlined />
              <p className="onboarding-notification--time">
                {moment(notification.dueTime).format("hh:mm - MMM DD, YYYY")}
              </p>
            </>
          }
          description={<p>{notification.description}</p>}
        />

        <div>
          <Checkbox
            onChange={() => {
              completeNotification(true, notification);
            }}
          ></Checkbox>
        </div>
      </List.Item>
    ));

  return (
    <>
      {!addFormMode && (
        <PlusOutlined
          className="popover-add-btn"
          onClick={() => setAddFormMode(true)}
        />
      )}
      <div className="onboarding-notification__container">
        {!addFormMode ? (
          <List
            itemLayout="horizontal"
            dataSource={[notifications]}
            renderItem={(notifications) => {
              let notificationList =
                notificationsListGenerator(notifications);
              if (!notificationList.length) {
                notificationList = (
                  <p className="empty-notification">No notifications found...</p>
                )
              }
              return notificationList;
            }}
          ></List>
        ) : (
          <Formik
            enableReinitialize
            initialValues={notification}
            onSubmit={() => { }}
          >
            {({ setFieldValue, values, resetForm, isValid }) => {
              return (
                <Form>
                  <div className="onboarding-notification">
                    <Row>
                      <Col sm={24}>
                        <InputField
                          placeholder={"Description"}
                          name="description"
                          type="textarea"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={24}>
                        <DatePickerComponent
                          placeholder={"Due date"}
                          name="dueTime"
                          className="input-field"
                          onChange={(value) => {
                            setFieldValue(
                              "dueTime",
                              moment(value).format("hh:mm - MMM DD, YYYY")
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <ButtonComponent
                          type={"default"}
                          onClick={() => {
                            setAddFormMode(false);
                          }}
                          className="onboarding-notification--action"
                        >
                          Cancel
                        </ButtonComponent>
                      </Col>
                      <Col sm={12}>
                        <ButtonComponent
                          type={"primary"}
                          onClick={() => {
                            createNotification(values);
                          }}
                          className="onboarding-notification--action"
                        >
                          Create
                        </ButtonComponent>
                      </Col>
                    </Row>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </>
  );
};

export default OtherNotification;
