import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { Category } from "../../../../../../../enums/onboardingItems";
import { OnboardingItemMetas } from "../../../../../../../models/OnboardingConfig/onboardingItemMetas.model";
import { OnboardingTemplates } from "../../../../../../../models/OnboardingConfig/onboardingTemplates.model";
import OnboardingItemMetasService from "../../../../../../../services/OnboardingConfigService/OnboardingItemMetas.service";
import OnboardingTemplatesService from "../../../../../../../services/OnboardingConfigService/OnboardingTemplates.service";
import DropdownField from "../../../../../../../shared/components/DropdownField";
import { UIModal } from "../../../../../../../shared/components/UIModal";
import "./onboardingItem.scss";
interface OnboardingItemFormProps {
  data: OnboardingTemplates[];
  visible: boolean;
  closeHandler: () => void;
  type: string;
}
export const OnboardingItemForm: FC<OnboardingItemFormProps> = ({
  visible,
  closeHandler,
  type,
  data,
}) => {
  const [initialValues, setInitialValues] = useState({
    onboardingItem: undefined,
  });

  const [onboardingItems, setOnboardingItems] = useState<any[]>([]);

  const fetchOnboardingItems = () => {
    OnboardingItemMetasService.fetchOnboardingItemMetas(
      {},
      (response: OnboardingItemMetas[]) => {
        if (type == Category.GENERAL) {
          // console.log(data);
          setOnboardingItems(
            response.filter((data) => data.category == Category.GENERAL)
              ?.map(({ name, id }) => ({ label: name, value: id }))
          );
        } else {
          setOnboardingItems(
            response.filter((data) => data.category == Category.ISDA)
              ?.map(({ name, id }) => ({ label: name, value: id }))
          );
        }
      },
      () => { },
      () => { }
    );
  };

  useEffect(() => {
    fetchOnboardingItems();
  }, [type]);

  const createOnboardingTemplate = (id) => {
    //console.log(data)
    OnboardingTemplatesService.createOnboardingTemplates(
      {
        onboardingItemMetumId: id,
        order: data.length ? data[data?.length - 1].order + 1 : 1,
      },
      () => {
        closeHandler();
      },
      () => { },
      () => { }
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      enableReinitialize
    >
      {({ setFieldValue, values, resetForm, isValid }) => {
        return (
          <UIModal
            visible={visible}
            closeModal={closeHandler}
            title={"Add Onboarding Item to Template"}
            submitProps={{
              text: "Add",
              clickHandler: () => {
                createOnboardingTemplate(values?.onboardingItem);
              },
            }}
            cancelProps={{
              text: "Cancel",
              clickHandler: () => {
                resetForm()
                closeHandler();
              },
            }}
          >
            <Form>
              <div className="onboarding-item-form__container">
                <div className="onboarding-item-form__field">
                  <DropdownField
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"Onboarding Item"}
                    name="onboardingItem"
                    options={onboardingItems.filter(item => !data.find(dataItem => dataItem.onboardingItemMetumId === item.value))}
                    showSearch={false}
                    value={values.onboardingItem}
                    onChange={(value) => setFieldValue("onboardingItem", value)}
                  />
                </div>
              </div>
            </Form>
          </UIModal>
        );
      }}
    </Formik>
  );
};
