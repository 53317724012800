
import {serializable, alias, object, list, primitive} from 'serializr';

export class KeyTerm { 

	@serializable(alias('agreement_key_term_id', primitive()))
	agreementKeyTermId?: number;

	@serializable(alias('agreement_type_name', primitive()))
	agreementTypeName?: string;

	@serializable(alias('agreement_name', primitive()))
	agreementName?: string;

	@serializable(alias('fund_name', primitive()))
	fundName?: string;

	@serializable(alias('key_term_name', primitive()))
	keyTermName?: string;

	@serializable(alias('key_term_value', primitive()))
	keyTermValue?: string;

	@serializable(alias('counter_party_name', primitive()))
	counterPartyName?: string;

}