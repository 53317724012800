import {
  CaretDownOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { Tag, Space, Checkbox, Divider } from "antd";
import React, { useEffect, useState } from "react";
import ButtonComponent from "../../../../../../shared/components/ButtonComponent";
import KeyContacts from "../../../../../../shared/components/KeyContacts";
import PopoverComponent from "../../../../../../shared/components/PopoverComponent";
import { TableComponent } from "../../../../../../shared/components/TableComponent";
import AmendmentForm from "./AmendmentForm";
import {
  Agreement,
  AgreementStatus,
  Amendment,
} from "../../../../../../models/Agreements/agreement.model";
import { HLAgreementService } from "../../../../../../services/AgreementService/agreement.service";
import moment from "moment";
import SwapAgreementForm from "./SwapAgreementForm";
import { ConfirmationAlert } from "../../../../../../shared/components/ConfirmationAlert";
import AgreementModal from "../../../../../../shared/components/AgreementModal";
import AgreementForm from "../../AgreementForm";
import { deserialize } from "serializr";
import { AgreementTypes } from "../../../../../../models/Agreements/agreementTypes.model";
import { CounterParties } from "../../../../../../models/CounterParties/counterParties.model";
import { Fund } from "../../../../../../models/Fund/fund.model";
import { HLCounterPartiesService } from "../../../../../../services/CounterPartiesService/CounterParties.service";
import { HLFundsService } from "../../../../../../services/FundService/Fund.service";
import AgreementTypesService from "../../../../../../services/KeyTermsService/AgreementTypes.service";
import { MetaService } from "../../../../../../services/MetaService/meta.service";
import TableFilterDropDown from "../../../../../../shared/components/TableFilterDropDown";
import { dateSorter } from "../../../../../../shared/utils/tableSorter";
import { fileDownloadFromUrl } from "../../../../../../shared/utils/fileDownloader";
import { OnboardingItems } from "../../../../../../models/Meta/meta.model";

interface AmendmentsProps {
  agreement: Agreement;
  onAgreementUpdate: Function;
}

const Amendments = (props: AmendmentsProps) => {
  const { agreement, onAgreementUpdate } = props;
  const [formVisible, setFormVisible] = useState<boolean>();
  const [swapVisible, setSwapVisible] = useState<boolean>();
  const [amendments, setAmendments] = useState<Amendment[]>();
  const [archivedAmendments, setArchivedAmendments] = useState<Amendment[]>();
  const [successVisible, setSuccessVisibility] = useState(false);
  const [agreementModalStatus, setAgreementModalStatus] =
    useState<{ amendment: Amendment; visibility: boolean }>();
  const [agreementEditStatus, setAgreementEditStatus] =
    useState<{ agreement: Agreement; visibility: boolean }>();
  const [agreementStatus, setAgreementStatus] = useState<any[]>([]);
  const [counterParties, setCounterParties] = useState<any[]>([]);
  const [funds, setFunds] = useState<any[]>([]);
  const [agreementTypes, setAgreementTypes] = useState<any[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [sortInfo, setSortInfo] = useState<{ key: string; order: string }>();
  const [filterInfo, setFilterInfo] = useState({});
  const [availableDates, setAvailableDates] = useState<any[]>([]);

  useEffect(() => {
    fetchFundsList();
    fetchCPList();
    fetchAgreementTypes();
    fetchAgreementStatus();
  }, []);

  useEffect(() => {
    fetchAmendments(false);
    fetchAmendments(true, filterInfo);
  }, []);

  useEffect(() => {
    setTableLoading(true);
    fetchAmendments(true, filterInfo);
  }, [filterInfo]);

  const fetchAgreementStatus = () => {
    MetaService.agreementStatus(
      (response) => {
        const data = deserialize(
          AgreementStatus,
          response.data["agreement_statuses"]
        ) as AgreementStatus[];
        const formattedData = data.map((sts) => {
          return {
            label: sts.name.replaceAll("_", " "),
            value: sts.id,
            color: sts.color,
          };
        });
        setAgreementStatus(formattedData);
      },
      (error) => {
      }
    );
  };

  const fetchCPList = () => {
    HLCounterPartiesService.fetchCounterParties(
      { meta: true },
      (response: CounterParties[]) => {
        setCounterParties(deserialize(OnboardingItems, response));
      },
      () => { },
      () => { }
    );
  };

  const fetchFundsList = () => {
    HLFundsService.fetchFunds(
      { meta: true },
      (response: Fund[]) => {
        setFunds(deserialize(OnboardingItems, response));
      },
      (error) => {
        // Notification({
        //   message: "Hedge Legal",
        //   description: "Unable to fetch funds",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  const fetchAgreementTypes = () => {
    AgreementTypesService.fetchAgreementTypes(
      (response: AgreementTypes[]) => {
        const foramattedData = response.map((agreement) => {
          return { label: agreement.name, value: agreement.id };
        });
        setAgreementTypes(foramattedData);
      },
      () => { },
      () => { }
    );
  };

  const fetchAmendments = (isArchived: boolean, filter = {}) => {
    HLAgreementService.fetchAmendments(
      agreement?.id,
      {
        ...filter,
        is_archived: isArchived,
      },
      (response: Amendment[]) => {
        if (isArchived) {
          setArchivedAmendments(response.reverse());
          const allAvailableDates = response.map((amendment) => amendment?.date);
          if (!availableDates?.length && allAvailableDates?.length) {
            const uniqueDates = [];
            allAvailableDates.forEach((date) => {
              if (!uniqueDates.includes(date) && !!date) {
                uniqueDates.push(date);
              }
            });
            setAvailableDates(
              uniqueDates.map((date) => {
                return {
                  label: moment(date).format("DD MMM, YYYY"),
                  value: date,
                };
              })
            );
          }
        } else {
          setAmendments(response);
        }
      },
      (error) => {
        // Notification({
        //   message: "Hedge Legal",
        //   description:
        //     "Unable to fetch " + (isArchived ? "archived " : "") + "amendments",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => {
        setTableLoading(false)
      }
    );
  };

  const sortHandler = (key: string, order: string) => {
    setSortInfo({
      key,
      order,
    });
  };

  const filterHandler = (key: string, value: any) => {
    const currentFilter = { ...filterInfo };
    currentFilter[key] = value;
    if (JSON.stringify(filterInfo) === JSON.stringify(currentFilter)) {
      return
    }
    setFilterInfo(currentFilter);
  };
  const filterProperties = (dataIndex: string, sortIndex: string, records) => {
    return {
      filterDropdown: (filterProps) => (
        <TableFilterDropDown
          {...filterProps}
          defaultSelected={filterInfo[dataIndex]}
          onApply={(key, value) => filterHandler(key, value)}
          sortIndex={sortIndex}
          dataIndex={dataIndex}
          checkboxValues={records}
          onSort={sortHandler}
        />
      ),
      filterIcon: () => <CaretDownOutlined />,
    };
  };
  const formatDate = (date: string) => (date ? moment(date).format("MMM D, YYYY") : "");

  return (
    <>
      <TableComponent
        className="sort-remover row-clickable"
        onRow={(index: number, amendment) => {
          setAgreementModalStatus({
            amendment,
            visibility: true,
          })
        }}
        pagination={false}
        title="Archived"
        columns={[
          {
            title: "NAME OF THE AGREEMENT / AMENDMENT",
            dataIndex: "name",
            key: "name",
            width: 400,
          },
          {
            title: "DATE",
            dataIndex: "date",
            key: "date",
            sorter: (a, b) => dateSorter(a.date, b.date),
            sortOrder: sortInfo?.key === "date" && sortInfo?.order,
            ...filterProperties("dates", "date", availableDates),
            width: 200,
            render: formatDate,
          },
          {
            title: "DATE ARCHIVED",
            dataIndex: "deletedAt",
            key: "deletedAt",
            width: 200,
            render: formatDate,
          },
          {
            title: "",
            key: "action",
            width: 50,
            render: (text, record) => (
              <Space size="middle" className="table-actions"
                onClick={e => e.stopPropagation()}>
                <i
                  className="icon-download hoverable-icon"
                  onClick={(e) => {
                    e.stopPropagation()
                    HLAgreementService.downloadSingleAmendment(
                      agreement?.id,
                      record?.id,
                      (data) => {
                        fileDownloadFromUrl(data)
                      },
                      () => { },
                      () => { },
                    )
                  }}
                  title="Download"
                />
              </Space>
            ),
          },
        ]}
        data={archivedAmendments}
        showSearch={false}
        showAdd={false}
        onAdd={() => {
          setFormVisible(true);
        }}
        additionalButtons={
          <>
            <ButtonComponent
              type="primary"
              size="large"
              icon={<SwapOutlined />}
              onClick={() => {
                setSwapVisible(true);
              }}
            />
          </>
        }
      />
      <AmendmentForm
        agreement={agreement}
        visible={formVisible}
        closeHandler={(document?: any) => {
          if (document?.id) {
            fetchAmendments(false);
            fetchAmendments(true, filterInfo);
          }
          setFormVisible(false);
        }}
      />
      <SwapAgreementForm
        visible={swapVisible}
        onAgreementUpdate={onAgreementUpdate}
        closeHandler={(editAgreement: boolean) => {
          setSwapVisible(false);
          if (editAgreement === true) {
            setAgreementEditStatus({
              agreement,
              visibility: true,
            });
          }
        }}
        agreement={agreement}
      />

      <ConfirmationAlert
        closeHandler={() => {
          setSuccessVisibility(false);
        }}
        visible={successVisible}
        type="success"
        customButtons={
          <div className="download-success--buttons">
            <ButtonComponent
              onClick={() => {
                setSuccessVisibility(false);
              }}
              type="ghost"
            >
              Close
            </ButtonComponent>
          </div>
        }
        cancelHandler={() => {
          setSuccessVisibility(false);
        }}
        title={"Download request successful"}
        description="Link to the documents will be shared with your mail id shortly.."
      />
      <AgreementForm
        funds={funds}
        counterParties={counterParties}
        agreementStatus={agreementStatus}
        agreementTypes={agreementTypes}
        agreement={agreementEditStatus?.agreement}
        visible={agreementEditStatus?.visibility}
        closeHandler={(agreement?: Agreement) => {
          if (agreement?.id) {
            onAgreementUpdate(agreement);
          }
          setAgreementEditStatus({
            agreement: null,
            visibility: false,
          });
        }}
      ></AgreementForm>
      <AgreementModal
        agreement={agreement}
        amendment={agreementModalStatus?.amendment}
        visible={agreementModalStatus?.visibility}
        closeHandler={() => {
          setAgreementModalStatus({
            amendment: null,
            visibility: false,
          });
        }}
      ></AgreementModal>
    </>
  );
};

export default Amendments;
