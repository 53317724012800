import React, { FC, useState, useEffect, Dispatch, SetStateAction } from "react";
import { Button, Col, Divider, Row } from "antd";
import { Formik, FormikValues, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { UserService } from "../../../services/UserService/user.service";
import { ProfileModel } from "../../../models/HFund/hfund.model";
import QRCode from "qrcode";
import { LocalStorage } from "../../../shared/utils/localStorageHelpers";
import { useHistory } from "react-router";
import { ACCOUNT_DETAILS, DASHBOARD } from "../../../routes/appRoutes";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";

import "./multiFactorAuthentication.scss"
// import { User } from "../../../models/User/user.model";
interface MFAProps {
    user?: ProfileModel;
    toggleMfa?: Boolean,
    setUser: (user: ProfileModel) => void;
    closeModalHandler: () => void;
    loggedIn?: Boolean
    userType: string;
    // setMfaEnabled: Dispatch<SetStateAction<boolean>>;
}


const MultiFactorAuthentication: FC<MFAProps> = (props) => {
    const { user, toggleMfa, setUser, closeModalHandler, loggedIn, userType } = props;
    const [codeScanned, setCodeScanned] = useState<boolean>(false);
    const [code, setCode] = useState({ code: '' });
    const [validOtp, setSetValidOtp] = useState<boolean>(false);
    const [qrcode, setQrCode] = useState('');
    const [loading, setLoading] = useState<boolean>(false);

    const history = useHistory();
    const toggleInputToken = () => {
        setCodeScanned(true);
    }

    const verifyToken = () => {
        setLoading(true);
        UserService.authenticateOtp(
            code?.code,
            (response) => {
                setLoading(false);
                setSetValidOtp(response.data.valid_otp)
                closeModalHandler()
                if (!loggedIn) {
                    if (response.data.valid_otp) {
                        Notification({
                            message: (userType == "HF" ? "Hedge Fund" : "Hedge Legal"),
                            description: "Logged in successfully",
                            type: NotificationTypes.SUCCESS,
                        });
                        user.otpVerified = "true"
                        setUser(user)
                        LocalStorage.setItem("USER", user)
                        history.push(DASHBOARD);
                    } else {
                        Notification({
                            message: (userType == "HF" ? "Hedge Fund" : "Hedge Legal"),
                            description: "Invalid OTP",
                            type: NotificationTypes.ERROR,
                        });
                    }
                } else {
                    user.otpModule = response.data.otp_module
                    user.otpVerified = "true"
                    setUser(user)
                    LocalStorage.setItem("USER", user)
                    Notification({
                        message: (userType == "HF" ? "Hedge Fund" : "Hedge Legal"),
                        description: "Enabled Multi factor Authentication",
                        type: NotificationTypes.SUCCESS,
                    });
                    history.push(ACCOUNT_DETAILS)
                }
            },
            (error) => {
                console.log(error)
                setLoading(false);
                // closeModalHandler()
            }
        )
    }

    const handleChange = (event) => {
        setCode({ code: event.target.value });
    }

    useEffect(() => {
        setLoading(true);
        UserService.setUpMultiFactorAuthentication(
            user?.id,
            (response) => {
                setLoading(false);
                QRCode.toDataURL(response.data.otpauth_url, function (err, data_url) {
                    setQrCode(data_url)
                });
            },
            () => {
                setLoading(false);
            }
        );
    }, [])

    useEffect(() => {
        if (toggleMfa) {
            setCodeScanned(true)
        }
    }, [])

    const toggleMultiFactorAuthentication = () => {
        UserService.enabled_disable_mfa(
            code?.code,
            (response) => {
                console.log(response)
                if (response.data.valid_otp) {
                    user.otpModule = response.data.otp_module
                    user.otpVerified = "true"
                    setUser(user)
                    LocalStorage.setItem("USER", user)
                    // setMfaEnabled(response.data.otp_module)
                    closeModalHandler()
                    Notification({
                        message: (userType == "HF" ? "Hedge Fund" : "Hedge Legal"),
                        description: (response.data.otp_module == "enabled" ?
                            "Enabled Multi factor Authentication" : "Disabled Multi factor Authentication"),
                        type: NotificationTypes.SUCCESS,
                    });
                    history.push(ACCOUNT_DETAILS)
                } else {
                    Notification({
                        message: (userType == "HF" ? "Hedge Fund" : "Hedge Legal"),
                        description: response.data.error,
                        type: NotificationTypes.ERROR,
                    });
                }
            },
            () => {
                // closeModalHandler()
            }
        )
    }

    return (
        <div className="multi-factor-authentication__container">
            {!codeScanned && (
                <div>
                    <p className="multi-factor-authentication__info">
                        Download Google authenticator on your device.
                        Tap on add new Scan QR code. Once you scan the QR code,
                        click Next and enter the OTP.
                    </p>

                    {qrcode && (<div>
                        <img src={qrcode}></img>
                    </div>)}

                    <Button type="primary" className="full-width" onClick={toggleInputToken}>
                        Next
                    </Button>
                </div>)}

            {codeScanned && (
                <div>
                    <Formik initialValues={code} enableReinitialize onSubmit={verifyToken}>
                        {({ values, setFieldValue }) => (
                            <Form>
                                <Col span={24}>
                                    <InputField
                                        name="code"
                                        placeholder="Enter token"
                                        type="text"
                                        value={code?.code}
                                        // onChange={e => setFieldValue("code", [e.target.value])}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Form>
                        )}
                    </Formik>
                    {loggedIn && <Button type="primary" className="full-width mt-5" onClick={(toggleMfa && loggedIn) ? toggleMultiFactorAuthentication : verifyToken}>
                        Confirm
                    </Button>}
                    {!loggedIn && <ButtonComponent
                        type={"primary"}
                        onClick={(toggleMfa && loggedIn) ? toggleMultiFactorAuthentication : verifyToken}
                        iconRight={
                            loading ? <LoadingOutlined /> : <ArrowRightOutlined />
                        }
                        displayType="space-between"
                        disabled={loading}
                    >
                        Verify
                    </ButtonComponent>}
                </div>
            )

            }
        </div>
    );
};

export default MultiFactorAuthentication;
