import { Col, Radio, Form, Row, Space, Switch } from "antd";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import Notification from "../../../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../../../enums/notificationTypes";
import { Fund } from "../../../../../../models/Fund/fund.model";
import { HLFundsService } from "../../../../../../services/FundService/Fund.service";
import { UIModal } from "../../../../../../shared/components/UIModal";
import DatePickerComponent from "../../../../../../shared/components/DatePickerComponent";
import moment from "moment";
import RadioGroup from "../../../../../../shared/components/RadioGroup";
import { LocalStorage } from "../../../../../../shared/utils/localStorageHelpers";
import { ProfileModel } from "../../../../../../models/HFund/hfund.model";
import { FundStatusBE } from "../../../../../../enums/fundStatuses";

interface TerminationFormProps {
  visible: boolean;
  closeHandler: (fund?: Fund) => void;
  id?: number;
  data: Fund;
}

const TerminationForm = (props: TerminationFormProps) => {
  const { visible, closeHandler, id, data } = props;

  const [selectedDateFormat, setSelectedDateFormat] = useState<number>(1);

  const terminateDate = {
    date: "",
    year: ""
  };

  const onSubmit = (values: { date: string, year: string }, resetForm: Function) => {

    const companyId = data.companyId || LocalStorage.getItem("USER");
    data.terminatedAt = selectedDateFormat === 1 ? values.date ?? values.year : values.year ?? values.date;
    data.status = FundStatusBE.TERMINATED;
    HLFundsService.updateFund(
      data,
      { companyId },
      (res) => {
        resetForm()
        closeHandler(res);
        Notification({
          message: "Hedge Legal",
          description: "Fund terminated successfully",
          type: NotificationTypes.SUCCESS,
        });
      },
      (err) => {
        // ;
        // Notification({
        //   message: "Hedge Legal",
        //   description: "Fund updation failed",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={terminateDate}
      onSubmit={() => { }}
    >
      {({ setFieldValue, values, resetForm }) => {

        return (
          <UIModal
            className="terminate-form__modal"
            visible={visible}
            closeModal={() => {
              resetForm();
              closeHandler();
            }}
            title={
              "Are you sure you would like to mark this fund as terminated?"
            }
            submitProps={{
              text: "Terminate",
              clickHandler: () => {
                onSubmit(values, resetForm);
              },
              disabled: !values.date && !values.year,
            }}
            cancelProps={{
              text: "Cancel",
              clickHandler: () => {
                resetForm();
                closeHandler();
              },
            }}
          >
            <Form>
              <div className="termination-form__container">
                <h1 className="text-center">{data.name}</h1>
                <Row align="middle">
                  <Col sm={4}>
                    <Switch defaultChecked checked={selectedDateFormat === 1} onClick={(selectionStatus: boolean) => {
                      if (selectionStatus) {
                        setSelectedDateFormat(1)
                        setFieldValue("year", undefined);
                      } else {
                        setSelectedDateFormat(2)
                      }
                    }} />
                  </Col>
                  <Col sm={20}>
                    <DatePickerComponent
                      name="date"
                      placeholder="Termination Date"
                      className="input-field"
                      value={values.date && moment(values.date)}
                      onChange={(value, string) => {
                        setSelectedDateFormat(1);
                        setFieldValue("date", string);
                        setFieldValue("year", undefined);
                      }}
                    />
                  </Col>
                </Row>
                <Row align="middle">
                  <Col sm={4}>
                    <Switch checked={selectedDateFormat === 2} onClick={(selectionStatus: boolean) => {
                      if (selectionStatus) {
                        setSelectedDateFormat(2)
                        setFieldValue("date", undefined);
                      } else {
                        setSelectedDateFormat(1)
                      }
                    }} />
                  </Col>
                  <Col sm={20}>
                    <DatePickerComponent
                      name="year"
                      placeholder="Termination Year"
                      className="input-field"
                      picker={"year"}
                      value={values.year && moment(values.year)}
                      onChange={(value, string) => {
                        setSelectedDateFormat(2);
                        setFieldValue("year", string);
                        setFieldValue("date", undefined);
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </Form>
          </UIModal>
        );
      }}
    </Formik>
  );
};

export default TerminationForm;
