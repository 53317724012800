import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, Row } from "antd";
import React, { FC, useEffect } from "react";
import DiscussionBoardService from "../../../services/DiscussionBoardService/DiscussionBoard.service";
import "./discussionList.scss";
import DiscussionCard from "./DiscussionCard";
import { Topic } from "../../../models/Topic/topic.model";
import AppModal from "../../../shared/components/AppModal";
import DiscussionForm from "./DicussionForm";
import { debounce } from "throttle-debounce";
import { LocalStorage } from "../../../shared/utils/localStorageHelpers";
import LayoutContainer from "../../../store/containers/layoutContainer";
import { LayoutReducerProps } from "../../../store/reducers/layoutReducer";
import DoxCoinsService from "../../../services/DoxCoinsService/DoxCoins.service";

interface DiscussionListProps extends LayoutReducerProps {
  type: "explore" | "myTopic" | "myCompanyTopic";
  viewTopicHandler: (topic: Topic) => void;
}

const DiscussionList: FC<DiscussionListProps> = (props) => {
  const { type, setDoxCoin, viewTopicHandler } = props;

  const user = LocalStorage.getItem("USER");

  const { loading, topics, getTopics, createTopic } = DiscussionBoardService();

  const { getTotalDoxCoins } = DoxCoinsService();

  const fetchTopics = (search?: string) => {
    getTopics(type, {
      search_text: search,
    });
  };

  const fetchTopicList = () => fetchTopics();

  useEffect(() => {
    fetchTopicList();
  }, []);

  const handleSearch = debounce(300, false, (search: string) => {
    fetchTopics(search);
  });

  return (
    <div className="discussion-list">
      <div className="discussion-header">
        <AppModal
          triggerComponent={(openModal) => (
            <div className="name" onClick={openModal}>
              <p>
                {user?.firstName} {user?.lastName}
              </p>
              <h3>Start a new discussion</h3>
            </div>
          )}
          renderComponent={(closeHandler) => (
            <DiscussionForm
              loading={loading}
              closeHandler={closeHandler}
              successHandler={async (topic: Topic) => {
                await createTopic(topic);
                await getTotalDoxCoins(setDoxCoin);
                closeHandler();
              }}
            />
          )}
        />
        <div className="search">
          <Input
            placeholder="Search topics"
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
            suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
          />
        </div>
      </div>
      {topics?.map((topic) => (
        <DiscussionCard
          key={topic?.id}
          topic={topic}
          topicId={topic?.id}
          setTopic={viewTopicHandler}
          fetchTopics={fetchTopicList}
          type={type}
        />
      ))}
    </div>
  );
};

export default LayoutContainer(DiscussionList);
