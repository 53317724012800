import * as ApiRoutes from "../../routes/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { OnboardingItemMetas } from "../../models/OnboardingConfig/onboardingItemMetas.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { generatePath } from "react-router";
import { OnboardingItem } from "../../models/Fund/onboarding.model";

export default class OnboardingItemMetasService {
  static fetchOnboardingItemMetas(
    params: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    //console.log(params)
    axiosInstance
      .get(ApiRoutes.HL_ONBOARDING_ITEMS,
        {
          params: params
        }
      )
      .then((response) => {
        const data = deserialize(
          OnboardingItemMetas,
          response.data["onboarding_item_meta"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: 'Hedge Legal',
        //   description: "Problem in fetching onboarding items!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static addOnboardingItemMetas(
    onboardingItem: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_ONBOARDING_ITEMS);
    const serializeData = serialize(OnboardingItemMetas, onboardingItem);
    const payload = { onboarding_item_metum: serializeData };
    axiosInstance
      .post(API_URL, payload)
      .then((response) => {
        const data = deserialize(
          OnboardingItemMetas,
          response.data["onboarding_item_meta"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: 'Hedge Legal',
        //   description: "Problem in adding onboarding item!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateOnboardingItemMetas(
    onboardingItem: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_SINGLE_ONBOARDING_ITEM, {
      onboardingItemId: onboardingItem.onboardingItemMetaId,
    });
    const serializeData = serialize(OnboardingItemMetas, onboardingItem);
    const payload = { onboarding_item_metum: serializeData };
    axiosInstance
      .put(API_URL, payload)
      .then((response) => {
        const data = deserialize(
          OnboardingItemMetas,
          response.data["onboarding_item_meta"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: 'Hedge Legal',
        //   description: "Problem in updating onboarding item!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteOnboardingItemMetas(
    onboardingItem: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_SINGLE_ONBOARDING_ITEM, {
      onboardingItemId: onboardingItem.onboardingItemMetaId,
    });
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
