import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { LOGIN, REGISTER, HOME } from "../../../routes/appRoutes";
import AuthContainer from "../../../store/containers/AuthContainer";
import RestrictAccess from "../RestrictedAccess";
import SubscriptionRestrict from "../SubscriptionRestrict"

const doAuth = (Component: any, allowedRoles: any = []) => {
  const Authentication = (props: any) => {
    useEffect(() => {
      const { authenticated, history, location } = props;
      const queryParams = new URLSearchParams(location?.search);
      if (queryParams.get("code")) {
        return history.push(REGISTER + location?.search);
      }
      if (!authenticated && history.location.pathname !== LOGIN) {
        return history.push(LOGIN);
      }
      if (props.user.otpModule == 'enabled' && props.user.otpVerified === undefined) {
        return history.push(LOGIN);
      }
    }, [props]);

    if (allowedRoles.length) {
      const { user } = props;
      return allowedRoles.includes(user.role) ? (
        <Component {...props} />
      ) : (
        <RestrictAccess />
      );
    }
    if (props.history.location.pathname != HOME && props.user && props.user.restrictedPages && props.user.restrictedPages.includes(props.history.location.pathname)) {
      return <SubscriptionRestrict />
    }
    return <Component {...props} />;
  };
  return withRouter(AuthContainer(Authentication));
};
export default doAuth;
