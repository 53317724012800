import * as Yup from "yup";

export const HlUsersValidation = Yup.object().shape({
  email: Yup.string()
    .email("E-mail is not valid!")
    .required("E-mail is required!"),
  mobileNumber: Yup.string()
    // .matches(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/, "Mobile number should be valid!")
    .required("Mobile Number is required!"),
  password: Yup.string().ensure().when('id', {
    is: (customerId) => !customerId,
    then: Yup.string().matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password should be alphanumeric and contain minimum 8 characters!"
    ).required("Password is required!")
  }),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Both passwords need to be the same"
  ),
  firstName: Yup.string()
    .required("First Name is required!"),
  lastName: Yup.string()
    .required("Last Name is required!"),
  status: Yup.string()
    .required("Status is required!"),
  role: Yup.string()
    .required("Status is required!"),
});
