import { ReactElement } from "react";
import {
  SET_DOX_COINS,
  SET_HEADER_VALUE,
} from "../definitions/layoutConstants";
import { LayoutActions } from "../reducers/layoutReducer";

export const setHeaderValues = (
  title: string,
  component: ReactElement
): LayoutActions => {
  return {
    type: SET_HEADER_VALUE,
    payload: {
      title,
      component,
    },
  };
};

export const setDoxCoin = (doxCoins: number): LayoutActions => {
  return {
    type: SET_DOX_COINS,
    payload: {
      doxCoins,
    },
  };
};
