
import {serializable, alias, object, list, primitive} from 'serializr';

export class Reply { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('company_name', primitive()))
	companyName?: string;

	@serializable(alias('user_name', primitive()))
	userName?: string;

	@serializable(alias('date', primitive()))
	date?: string;

	@serializable(alias('description', primitive()))
	description?: string;

}