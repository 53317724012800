import { PlusOutlined, SnippetsOutlined } from "@ant-design/icons";
import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { FundDetailsTabs } from "../../../../enums/fundDetails";
import { NotificationTypes } from "../../../../enums/notificationTypes";
import { Fund } from "../../../../models/Fund/fund.model";
import { ProfileModel } from "../../../../models/HFund/hfund.model";
import { HLFundsService } from "../../../../services/FundService/Fund.service";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import { PageHeaderComponent } from "../../../../shared/components/PageHeader";
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";
import Notification from "../../../../shared/components/Notification";
import Onboarding from "./Onboarding";
import OtherItems from "./OtherItems";
import GeneralInfo from "./GeneralInfo";
import PopoverComponent from "../../../../shared/components/PopoverComponent";
import OtherItemNotes from "./OtherItems/OtherItemNotes";
import { Form, Progress } from "antd";
import { Formik } from "formik";
import InputField from "../../../../shared/components/InputField";
import moment from "moment";
import { FundStatusBE } from "../../../../enums/fundStatuses";
import Agreements from "../FundDetails/FundAgreements";
import Loader from "../../../../shared/components/Loader";
import { useDebouncer } from "../../../../shared/hooks/useDebouncer";

interface FundDetailsState {
  companyId?: string;
  fund?: Fund;
}

interface FundDetailsProps { }

const FundDetails: FC<FundDetailsProps> = (params) => {
  const [activeTab, setActiveTab] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [fund, setFund] = useState<Fund>();
  const [notes, setNotes] = useState<{ description: string }>({
    description: "",
  });
  let { state, pathname, search }: { state: FundDetailsState; pathname: string, search: string } =
    useLocation();

  const tabChangeHandler = (e: string) => setActiveTab(+e);
  const id = state?.fund?.id.toString() || pathname.split("/").pop();

  useEffect(() => {
    setFund(new Fund())
    fetchSpecificFuncDetails(id)
  }, [id]);

  const fetchSpecificFuncDetails = (id: string) => {
    setLoading(true)
    const queryParams = new URLSearchParams(search);
    const companyId = queryParams.get("companyId") ?? LocalStorage.getItem("HL_SELECTED_COMPANY_ID");
    HLFundsService.fetchSingleFund(
      id,
      { companyId },
      (fund: Fund) => {
        fetchNotes(fund.id);
        setFund(fund);
        if (!activeTab) {
          setActiveTab(1);
        }
      },
      () => { },
      () => setLoading(false)

    );
  }

  const fetchNotes = (fundId: number) => {
    HLFundsService.fetchNotes(
      "Fund",
      fundId.toString(),
      (response: { description: string }) => {
        setNotes(response);
      },
      (err) => { },
      () => { }
    );
  };

  const createNotes = (fundId: number, description: string = "") => {
    HLFundsService.updateNotes(
      "Fund",
      fundId.toString(),
      { description },
      () => { },
      () => { },
      () => { }
    );
  };

  const handleUpdate = useDebouncer((id: number, notes: string) => {
    createNotes(id, notes)
  }, 1000)

  const Notes = (
    <Formik enableReinitialize initialValues={notes} onSubmit={() => { }}>
      {({ setFieldValue, values, resetForm, isValid }) => {
        return (
          <Form>
            <div className="onboarding-notes">
              <InputField
                showLabel={false}
                placeholder={"Add your notes..."}
                name="description"
                onChange={(event) => {
                  setFieldValue("description", event.target.value || "");
                  //@ts-ignore
                  handleUpdate(fund?.id, event.target.value || "");
                }}
                type="textarea"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  const progressTemplateGenerator = () => {
    const progressTemplate = [];
    progressTemplate.push(
      <Progress
        status="normal"
        className="fund-details--progress"
        strokeWidth={10}
        type="circle"
        percent={fund?.progress || 0}
      ></Progress>
    );
    if (
      (fund?.status === FundStatusBE.ACTIVE ||
        fund?.status === FundStatusBE.TERMINATED) &&
      fund?.targetLaunchDate
    ) {
      progressTemplate.push(
        <div className="fund-details__body__title__date">
          <p className=" days-to-launch">Launched on</p>
          <p className="launch-date">
            {moment(fund?.targetLaunchDate).format("DD MMM YYYY")}
          </p>
        </div>
      );
    } else if (fund?.status === FundStatusBE.PRELAUNCH && fund?.targetLaunchDate) {
      progressTemplate.push(
        <div className="fund-details__body__title__date">
          <p className="launch-date">
            {Math.abs(moment(new Date()).diff(moment(fund.targetLaunchDate), "days")) + 1}
          </p>
          <p className=" days-to-launch">Days to launch</p>
        </div>
      );
    }
    return progressTemplate;
  };
  if (loading && !fund?.id)
    return <Loader />

  return (
    <div className="hf-funds">
      <PageHeaderComponent
        onChangeTab={tabChangeHandler}
        title={fund?.name}
        showTabs={!!fund}
        showBack={!!fund}
        tabs={Object.values(FundDetailsTabs)}
        titleHelper={progressTemplateGenerator()}
        buttons={
          <PopoverComponent trigger={"click"} title="Notes" content={Notes}>
            <ButtonComponent
              className="page-header-title__btn"
              type="primary"
              icon={<SnippetsOutlined />}
              disabled={!fund}
            >
              Notes
            </ButtonComponent>
          </PopoverComponent>
        }
      />
      <div className="funds-list">
        {activeTab === 1 && <Agreements closeHandler={() => fetchSpecificFuncDetails(id)} fund={fund}></Agreements>}
        {activeTab === 2 && <Onboarding fund={fund} id={fund.id}></Onboarding>}
        {activeTab === 3 && (
          <GeneralInfo data={fund} handleEdit={setFund}></GeneralInfo>
        )}
        {activeTab === 4 && <OtherItems fund={fund} id={fund.id}></OtherItems>}
      </div>
    </div>
  );
};

export default FundDetails
