import { combineReducers } from "redux";
import authReducer, { IAuthState } from "./authReducer";
import LayoutReducer, { LayoutProps } from "./layoutReducer";

export interface RootReducerProps {
    auth: IAuthState,
    layout: LayoutProps
}

const RootReducer = combineReducers<RootReducerProps>({
    auth: authReducer,
    layout: LayoutReducer,
});

export default RootReducer;