import { Col, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import { SubscriptionModel } from "../../../models/Auth/auth.model";
import { AuthService } from "../../../services/AuthService/auth.service";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import "./subscriptionPlans.scss";
import AppIcon from "../../../assets/background-images/app-logo.png";
import { CompanyService } from "../../../services/CompanyService/company.service";
import { HOME, PAYMENT_INFORMATION } from "../../../routes/appRoutes";
import { generatePath, useHistory, useLocation, useParams } from "react-router";
import { MetaService } from "../../../services/MetaService/meta.service";
import AuthContainer from "../../../store/containers/AuthContainer";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { LocalStorage } from "../../../shared/utils/localStorageHelpers";
import { deserialize } from "serializr";
import { ProfileModel } from "../../../models/HFund/hfund.model";

const SubscriptionPlans: FC = (props: any) => {
  const { setAuthenticated, setUserType } = props;
  const [plans, setPlans] = useState<SubscriptionModel[]>([]);
  const { search } = useLocation<{ state: any }>();
  const params = useParams<{ companyId: string }>();
  const [services, setServices] = useState<any>();

  const history = useHistory();
  const fetchSubscriptionPlans = () => {
    AuthService.listSubscriptionPlans(
      (response) => setPlans(response),
      () => { }
    );
  };
  const fetchServices = () => {
    AuthService.listSubscriptionServices(
      (response) => setServices(response),
      () => { }
    );
  };

  useEffect(() => {
    fetchServices();
    fetchSubscriptionPlans();
  }, []);

  const handleSubscribe = (subscription: SubscriptionModel) => {
    MetaService.subscribeService(
      params?.companyId,
      {
        subscriptionPlanId: subscription?.id,
      },
      (company) => {
        setAuthenticated(deserialize(ProfileModel, company?.data?.company?.admin));
        setUserType("HF");
        LocalStorage.setItem("USER", deserialize(ProfileModel, company?.data?.company?.admin));
        Notification({
          message: "Hedge Fund",
          description: "Logged in successfully",
          type: NotificationTypes.SUCCESS,
        });
        history.push(HOME, company);
        LocalStorage.setItem(
          "SHOW_SUBSCRIPTION_MODAL",
          subscription?.costPerMonth == 0 ? "free" : "paid"
        );
      },
      () => { },
      () => { }
    );

    //commented for later use (Please don't delete)

    // history.push(
    //   generatePath(PAYMENT_INFORMATION, {
    //     subscriptionId: subscription?.id,
    //     companyId: params?.companyId,
    //   })
    // );
  };

  const getSubscriptionId = () => {
    return new URLSearchParams(search).get("subscriptionId");
  };

  const handleAvailability = () => { };

  return (
    <div className="subscription-plans__container">
      <img src={AppIcon} />
      <h1>Choose your subscription plan</h1>
      <Row className="subscription-plans__wrapper" gutter={24}>
        <Col span={5}>
          <div className="plans-description__wrapper">
            <p className="plans__header">Features</p>
            <p># of Agreements</p>
            <p># of Funds</p>
            <p># of Users</p>
            {services &&
              services.map((value, index) => {
                return <p>{value?.name}</p>;
              })}
          </div>
        </Col>
        <Col span={19} className="subscription-listing">
          {/* <Row gutter={[10, 20]}> */}
          {plans?.map((value, index) => {
            return (
              // <Col>
              <div className="subscription-container">
                <div className="plans__wrapper">
                  <div className="plan__name">
                    <span className="name">{value?.name} </span>
                    <span className="current-plan">
                      {value?.id?.toString() === getSubscriptionId() &&
                        "(current)"}
                    </span>
                  </div>
                  <div className="plan__cost">
                    {value?.name?.toLowerCase() !== "free" && (
                      <span>&#x24;</span>
                    )}
                    {value?.name?.toLowerCase() == "free"
                      ? "Free"
                      : value?.costPerMonth ?? "0"}
                    {value?.name?.toLowerCase() !== "free" && (
                      <span>/Year</span>
                    )}
                  </div>
                  <div className="plan__count">
                    {value?.maxNoOfAgreements}
                  </div>
                  <div className="plan__count">{value?.noOfFunds}</div>
                  <div className="plan__count">{value?.noOfUsers}</div>
                  {services?.map((service, index) => {
                    if (
                      value?.subscriptionPlanServices?.filter(
                        (s) => s?.serviceId == service?.id
                      ).length
                    ) {
                      return (
                        <div className="plan__blue">
                          <CheckOutlined />
                        </div>
                      );
                    } else {
                      return (
                        <div className="plan__grey">
                          <CloseOutlined />
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="plan__btn">
                  <ButtonComponent
                    type="primary"
                    onClick={() => handleSubscribe(value)}
                  >
                    Buy plan
                  </ButtonComponent>
                </div>
              </div>
              // </Col>
            );
          })}
          {/* </Row> */}
        </Col>
        <Col span={24}>
          <p className="subscription__instruction">
            *Input of Key Terms for agreements completed by HedgeLegal at no
            additional charge where HedgeLegal is engaged to negotiate the
            relevant agreement
          </p>
        </Col>
      </Row>
    </div>
  );
};
export default AuthContainer(SubscriptionPlans);
