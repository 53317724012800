import { Dropdown, Menu, Space } from "antd";
import React, { FC, useEffect, useState } from "react";
import { TableComponent } from "../../../../../shared/components/TableComponent";
import NotebookIcon from "../../../../../assets/icons/notebook.svg";
import EditIcon from "../../../../../assets/icons/edit.svg";
import DeleteIcon from "../../../../../assets/icons/delete.svg";
import BellIcon from "../../../../../assets/icons/bell-2.svg";
import { FundsService } from "../../../../../services/FundService/Fund.service";
import {
  OnboardingItem,
  OnboardingItemStatus,
} from "../../../../../models/Fund/onboarding.model";
import "./onboarding.scss";
import OnboardingForm from "./OnboardingForm";
import { ConfirmationAlert } from "../../../../../shared/components/ConfirmationAlert";
import Notification from "../../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../../enums/notificationTypes";
import { ProfileModel } from "../../../../../models/HFund/hfund.model";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";
import { Fund } from "../../../../../models/Fund/fund.model";
import { UIModal } from "../../../../../shared/components/UIModal";
import {
  CaretDownOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import PopoverComponent from "../../../../../shared/components/PopoverComponent";
import OnboardingNotes from "./OnboardingNotes";
import OnboardingNotification from "./OnboardingNotifications";
import FundDetails from "..";
import { OnboardingCategoriesEnum } from "../../../../../enums/fundDetails";
import { tierConverter } from "../../../../../shared/utils/stringToNumber";
import { sorter } from "../../../../../shared/utils/tableSorter";
import { deserialize } from "serializr";
import { MetaService } from "../../../../../services/MetaService/meta.service";
import TableNotifications from "../../../../../shared/components/TableNotifications";
import { NotificationType } from "../../../../../enums/notificationType";

interface OnboardingProps {
  id: number;
  fund: Fund;
}

const Onboarding: FC<OnboardingProps> = (props) => {
  const { id } = props;
  const [generalItems, setGeneralItems] = useState<OnboardingItem[]>([]);
  const [generalItemSearch, setGeneralItemSearch] = useState<string>("");
  const [isdaItems, setIsdaItems] = useState<OnboardingItem[]>([]);
  const [isdaItemSearch, setIsdaItemSearch] = useState<string>("");
  const [formVisibility, setFormVisibility] = useState(false);
  const [selectedTable, setSelectedTable] = useState<string>();
  const [selectedItem, setSelectedItem] = useState<OnboardingItem>();
  const [showDelete, setDeleteVisibility] = useState(false);
  const [showOnboarding, setOnboardingVisibility] = useState(false);
  const [onboardingStatus, setOnboardingStatus] = useState<any[]>([]);

  const fetchItems = (category: string, search: string) => {
    if (!category) {
      return;
    }
    FundsService.fetchOnboardingItem(
      id.toString(),
      { search_text: search, category },
      (item: OnboardingItem[]) => {
        if (category === OnboardingCategoriesEnum.ISDA) {
          setIsdaItems(item);
        } else if (category === OnboardingCategoriesEnum.GENERAL) {
          setGeneralItems(item);
        }
      },
      (error) => {
        //console.log(error);
      },
      () => { }
    );
  };

  useEffect(() => {
    fetchItems(OnboardingCategoriesEnum.GENERAL, generalItemSearch);
  }, [generalItemSearch]);

  useEffect(() => {
    fetchItems(OnboardingCategoriesEnum.ISDA, isdaItemSearch);
  }, [isdaItemSearch]);

  const searchSetter = (item: OnboardingItem) => {
    if (
      item?.onboardingItemMetumCategory === OnboardingCategoriesEnum.GENERAL
    ) {
      return generalItemSearch;
    } else if (
      item?.onboardingItemMetumCategory === OnboardingCategoriesEnum.ISDA
    ) {
      return isdaItemSearch;
    }
    return "";
  };
  const deleteHandler = (item: OnboardingItem) => {
    let search = searchSetter(item);
    FundsService.deleteOnboardingItem(
      { id, itemId: item.id },
      (res) => {
        fetchItems(item.onboardingItemCategory, search);
        Notification({
          message: "Hedge Fund",
          description: "Item deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
        setSelectedItem(null);
        setDeleteVisibility(false);
      },
      (err) => {
        // ;
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Fund deletion failed",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  useEffect(() => {
    MetaService.onboardingItemStatus(
      (res) => {
        const data = deserialize(
          OnboardingItemStatus,
          res.data["onboarding_item_statuses"]
        ) as OnboardingItemStatus[];
        setOnboardingStatus(
          data.map((data) => {
            return { label: data.name.replaceAll("_", " "), value: data.id };
          })
        );
      },
      (err) => {
        // ;
      }
    );
  }, []);

  const statusUpdateMenu = (item: OnboardingItem) => {
    const availableSts = onboardingStatus.filter(
      (sts) => sts.value !== item.onboardingItemStatusId
    );
    return (
      <Menu>
        {availableSts?.map((sts) => (
          <Menu.Item
            key={sts.value}
            onClick={() => changeStatus(item, sts.value)}
          >
            {sts.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const changeStatus = (item: OnboardingItem, toStatusId: number) => {
    const updatedItem = { ...item, onboardingItemStatusId: toStatusId };
    FundsService.updateOnboardingItem(
      id,
      updatedItem,
      (res: OnboardingItem) => {
        fetchItems(res?.onboardingItemMetum.category, searchSetter(res));
      },
      (err) => {
        // ;
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to change status",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  return (
    <div className="onboarding__container">
      <div className="onboarding__container__general">
        <TableComponent
          key="General"
          onRow={(index) => {
            //   setSelectedFund(funds[index]);
          }}
          onAdd={() => {
            setSelectedItem(null);
            setSelectedTable("General");
            setFormVisibility(true);
          }}
          onSearch={setGeneralItemSearch}
          showAdd={true}
          data={generalItems?.sort((a, b) => (a.position || 0) - (b.position || 0))}
          pagination={false}
          scroll={{ x: 750 }}
          title={"General"}
          columns={[
            {
              title: "ITEM",
              dataIndex: "name",
              key: "name",
              width: 200,
              sorter: (a, b) => sorter(a.name, b.name),
              sortDirections: ["ascend", "descend"],
              render: (text, record) => {
                return (
                  <div className="onboarding-item__name">
                    <p>{text}</p>
                    <p
                      className="info-icon"
                      onClick={() => {
                        setSelectedItem(record);
                        setOnboardingVisibility(true);
                      }}
                    >
                      <InfoCircleOutlined />
                    </p>
                  </div>
                );
              },
            },
            {
              title: "STATUS",
              dataIndex: "onboardingItemStatusName",
              key: "onboardingItemStatusName",
              width: 100,
              sorter: (a, b) =>
                sorter(a.onboardingItemStatusName, b.onboardingItemStatusName),
              sortDirections: ["ascend", "descend"],
              render: (text, record) => (
                <Dropdown
                  overlayClassName="status-update--dropdown"
                  overlay={() => statusUpdateMenu(record)}
                  trigger={["click"]}
                >
                  <p className={"onboarding-status " + text}>
                    {text.replaceAll("_", " ")} <CaretDownOutlined />
                  </p>
                </Dropdown>
              ),
            },
            {
              title: "TIER",
              key: "tier",
              dataIndex: "tier",
              width: 100,
              sorter: (a, b) =>
                sorter(tierConverter(a.tier), tierConverter(b.tier)),
              sortDirections: ["ascend", "descend"],
              render: (text) => <span>{tierConverter(text)}</span>,
            },
            {
              title: "PROGRESS",
              key: "onboardingItemStatusProgressPercent",
              dataIndex: "onboardingItemStatusProgressPercent",
              width: 100,
              sorter: (a, b) =>
                sorter(
                  a.onboardingItemStatusProgressPercent,
                  b.onboardingItemStatusProgressPercent
                ),
              sortDirections: ["ascend", "descend"],
              render: (text) => <span>{text + " %"}</span>,
            },
            {
              title: "RESPONSIBLE",
              key: "responsibleUserFirstName",
              dataIndex: "responsibleUserFirstName",
              width: 100,
              sorter: (a, b) =>
                sorter(a.responsibleUserFirstName, b.responsibleUserFirstName),
              sortDirections: ["ascend", "descend"],
              render: (text, record) => (
                <span>
                  {(text ? text + " " : "") +
                    (record.responsibleUserLastName
                      ? record.responsibleUserLastName
                      : "")}
                </span>
              ),
            },
            {
              title: "",
              key: "action",
              width: 150,
              render: (text, record: OnboardingItem) => (
                <Space>
                  <PopoverComponent
                    trigger={"click"}
                    title="Notes"
                    content={<OnboardingNotes id={record.id} />}
                  >
                    <i className="icon-notes hoverable-icon" />
                  </PopoverComponent>
                  <PopoverComponent
                    className="notification-popover"
                    trigger={"click"}
                    title="Notifications"
                    content={
                      <TableNotifications
                        category={NotificationType.ONBOARDING}
                        id={record.id ?? 0}
                        user="Hedge Fund"
                      />
                    }
                  >
                    <i
                      className="icon-notification-1 hoverable-icon"
                      title="Notifications"
                    />
                  </PopoverComponent>
                  <i
                    className="icon-edit"
                    onClick={() => {
                      setSelectedItem(record);
                      setSelectedTable("General");
                      setFormVisibility(true);
                    }}
                    title="Edit"
                  />
                  <i
                    className="icon-delete"
                    onClick={() => {
                      setSelectedItem(record);
                      setDeleteVisibility(true);
                    }}
                    title="Delete"
                  />
                </Space>
              ),
            },
          ]}
        />
      </div>
      <div className="onboarding__container--isda">
        <TableComponent
          key="ISDA"
          onRow={(index) => {
            setSelectedItem(isdaItems[index]);
          }}
          onAdd={() => {
            setSelectedItem(null);
            setSelectedTable("ISDA");
            setFormVisibility(true);
          }}
          onSearch={setIsdaItemSearch}
          showAdd={true}
          data={isdaItems?.sort((a, b) => (a.position || 0) - (b.position || 0))}
          pagination={false}
          scroll={{ x: 750 }}
          title={"ISDA Protocols"}
          columns={[
            {
              title: "ITEM",
              dataIndex: "name",
              key: "name",
              width: 200,
              sorter: (a, b) => sorter(a.name, b.name),
              sortDirections: ["ascend", "descend"],
              render: (text, record) => {
                return (
                  <div className="onboarding-item__name">
                    <p>{text}</p>
                    <p
                      className="info-icon"
                      onClick={() => {
                        setSelectedItem(record);
                        setOnboardingVisibility(true);
                      }}
                    >
                      <InfoCircleOutlined />
                    </p>
                  </div>
                );
              },
            },
            {
              title: "STATUS",
              dataIndex: "onboardingItemStatusName",
              key: "onboardingItemStatusName",
              width: 100,
              sorter: (a, b) =>
                sorter(a.onboardingItemStatusName, b.onboardingItemStatusName),
              sortDirections: ["ascend", "descend"],
              render: (text, record) => (
                <Dropdown
                  overlayClassName="status-update--dropdown"
                  overlay={() => statusUpdateMenu(record)}
                  trigger={["click"]}
                >
                  <p className={"onboarding-status " + text}>
                    {text.replaceAll("_", " ")} <CaretDownOutlined />
                  </p>
                </Dropdown>
              ),
            },
            {
              title: "TIER",
              key: "tier",
              dataIndex: "tier",
              width: 100,
              sorter: (a, b) =>
                sorter(tierConverter(a.tier), tierConverter(b.tier)),
              sortDirections: ["ascend", "descend"],
              render: (text) => <span>{tierConverter(text)}</span>,
            },
            {
              title: "PROGRESS",
              key: "onboardingItemStatusProgressPercent",
              dataIndex: "onboardingItemStatusProgressPercent",
              width: 100,
              sorter: (a, b) =>
                sorter(
                  a.onboardingItemStatusProgressPercent,
                  b.onboardingItemStatusProgressPercent
                ),
              sortDirections: ["ascend", "descend"],
              render: (text) => <span>{text + " %"}</span>,
            },
            {
              title: "RESPONSIBLE",
              key: "responsibleUserFirstName",
              dataIndex: "responsibleUserFirstName",
              width: 100,
              sorter: (a, b) =>
                sorter(a.responsibleUserFirstName, b.responsibleUserFirstName),
              sortDirections: ["ascend", "descend"],
              render: (text, record) => (
                <span>
                  {(text ? text + " " : "") +
                    (record.responsibleUserLastName
                      ? record.responsibleUserLastName
                      : "")}
                </span>
              ),
            },
            {
              title: "",
              key: "action",
              width: 150,
              render: (text, record) => (
                <Space size="large">
                  <PopoverComponent
                    trigger={"click"}
                    title="Notes"
                    content={<OnboardingNotes id={record.id} />}
                  >
                    <img
                      className="table-action-icon"
                      src={NotebookIcon}
                      alt=""
                      title="Notes"
                    />
                  </PopoverComponent>
                  <PopoverComponent
                    className="notification-popover"
                    trigger={"click"}
                    title="Notifications"
                    content={<OnboardingNotification id={record.id} />}
                  >
                    <img
                      className="table-action-icon"
                      src={BellIcon}
                      onClick={() => { }}
                      alt=""
                      title="Notifications"
                    />
                  </PopoverComponent>
                  <img
                    className="table-action-icon"
                    src={EditIcon}
                    onClick={() => {
                      setFormVisibility(true);
                      setSelectedTable("ISDA");
                      setSelectedItem(record);
                    }}
                    alt=""
                    title="Edit"
                  />
                  <img
                    className="table-action-icon"
                    src={DeleteIcon}
                    onClick={() => {
                      setSelectedItem(record);
                      setDeleteVisibility(true);
                    }}
                    alt=""
                    title="Delete"
                  />
                </Space>
              ),
            },
          ]}
        />
      </div>
      <OnboardingForm
        title={"Add new fund"}
        visible={formVisibility}
        id={id}
        data={selectedItem}
        category={selectedTable}
        closeHandler={(item) => {
          fetchItems(item?.onboardingItemMetum?.category, searchSetter(item));
          setSelectedItem(null);
          setFormVisibility(false);
        }}
      />
      <ConfirmationAlert
        closeHandler={() => {
          setSelectedItem(null);
          setDeleteVisibility(false);
        }}
        visible={showDelete}
        type="delete"
        deleteHandler={() => deleteHandler(selectedItem)}
        cancelHandler={() => {
          setSelectedItem(null);
          setDeleteVisibility(false);
        }}
        title={"Delete Onboarding Item?"}
        description={selectedItem?.onboardingItemMetumName}
      />
      {showOnboarding && (
        <UIModal
          visible={true}
          closeModal={() => {
            setOnboardingVisibility(false);
            setSelectedItem(null);
          }}
          noControl={true}
          title={selectedItem?.name}
        >
          <>
            <div>
              <h2>{selectedItem?.onboardingItemMetum.name}</h2>
            </div>
            <div>{selectedItem?.onboardingItemMetum.description}</div>
          </>
        </UIModal>
      )}
    </div>
  );
};

export default Onboarding;
