import * as ApiRoutes from "../../routes/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { KeyTerms } from "../../models/KeyTerms/keyTerms.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { generatePath } from "react-router";

export default class KeyTermsService {
  static fetchKeyTerms(
    agreementTypeId: any,
    keyTermGroupId: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(
        generatePath(ApiRoutes.HL_KEY_TERMS, {
          agreementTypeId,
          keyTermGroupId,
        })
      )
      .then((response) => {
        const data = deserialize(KeyTerms, response.data["key_terms"]);
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in fetching key terms!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createKeyTerm(
    agreementTypeId: any,
    keyTermGroupId: any,
    keyTerm: KeyTerms,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const serializeData = serialize(KeyTerms, keyTerm);
    const payload = { key_term: serializeData };
    axiosInstance
      .post(
        generatePath(ApiRoutes.HL_KEY_TERMS, {
          agreementTypeId,
          keyTermGroupId,
        }),
        payload
      )
      .then((response) => {
        const data = deserialize(KeyTerms, response.data["key_term"]);
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in creating key term!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateKeyTerms(
    agreementTypeId: any,
    keyTermGroupId: any,
    keyTerm: KeyTerms,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_SINGLE_KEY_TERM, {
      keyTermId: keyTerm.id,
      agreementTypeId,
      keyTermGroupId,
    });
    const serializeData = serialize(KeyTerms, keyTerm);
    const payload = { key_term: serializeData };
    axiosInstance
      .put(API_URL, payload)
      .then((response) => {
        const data = deserialize(KeyTerms, response.data["key_term"]);
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in updating key terms!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteKeyTerms(
    agreementTypeId: any,
    keyTermGroupId: any,
    keyTermId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_SINGLE_KEY_TERM, { keyTermId, agreementTypeId, keyTermGroupId });
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in deletiing key term!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
