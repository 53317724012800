import React, { FC, useState } from "react";
import "./cp.scss";
import { PlusOutlined } from "@ant-design/icons";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import { PageHeaderComponent } from "../../../../shared/components/PageHeader";
import { Col, Row } from "antd";
import faker from "faker";
import CPCard from "./CPCard";
import CPForm from "./CPForm";
import AuthContainer from "../../../../store/containers/AuthContainer";
import { roles } from "../../../../shared/components/HOC/roles";
import { CPSuggestions } from "./CPSuggestions";
import HLCPList from "./CPList"
import HLCPGroupList from "./CPGroupList"
import { CounterParties } from "../../../../models/CounterParties/counterParties.model";

const HLCP: FC = (props: any) => {
  const [showCPForm, setFormVisibility] = useState(false);
  const [currentCP, setCurrentCP] = useState<any>(null);
  const [showCPSuggestionsForm, setSuggestionsFormVisibility] = useState(false);
  const [tabKey, setTabKey] = useState("1")
  const [refresh, setRefresh] = useState(false)
  const [category, setCategory] = useState<"cp" | "cp_group">("cp_group")
  const [searchText, setSearchText] = useState<string>('')

  const tabChangeHandler = (key: string) => {
    setTabKey(key)
    setSearchText(undefined)
  }
  return (
    <div className="hlcp-container">
      <PageHeaderComponent
        title="CPs / Brokers"
        showSearch
        showTabs
        onSearch={setSearchText}
        tabs={["CP List", "CP Group List"]}
        onChangeTab={tabChangeHandler}
        buttons={
          <>
            <ButtonComponent
              className="page-header-title__btn"
              type="primary"
              onClick={() => setSuggestionsFormVisibility(true)}
            >
              CP Suggestions
            </ButtonComponent>
            <ButtonComponent
              className="page-header-title__btn"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                props.role == roles.HL_SUPERADMIN ||
                (roles.HL_ADMIN && setFormVisibility(true))
              }
            >
              Add New CP
            </ButtonComponent>
          </>
        }
      />

      <div className="page-body">
        <CPSuggestions
          onApprove={(value) => {
            setCategory("cp")
            setCurrentCP(value)
            setFormVisibility(true)
          }}
          visible={showCPSuggestionsForm}
          closeHandler={() => setSuggestionsFormVisibility(false)}
        />
        <CPForm
          visible={showCPForm}
          closeHandler={(cp?: CounterParties) => {
            if (cp?.id) {
              setRefresh(state => !state)
              setSuggestionsFormVisibility(false)
            }
            setFormVisibility(false);
            setCurrentCP(undefined)
          }}
          data={currentCP}
          category={category}
        />
        {
          tabKey == "1" ?
            <HLCPList refresh={refresh} searchText={searchText} /> : <HLCPGroupList refresh={refresh} searchText={searchText} />
        }

      </div>
    </div>
  );
};
export default AuthContainer(HLCP);
