import React, { useEffect, useState } from "react";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import { PlusOutlined } from "@ant-design/icons";
import { PageHeaderComponent } from "../../../../shared/components/PageHeader";
import AuditIcon from "../../../../assets/icons/audit.svg";
import { TableComponent } from "../../../../shared/components/TableComponent";
import { Space, Tag } from "antd";
import EditIcon from "../../../../assets/icons/edit.svg";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import HLUsersForm from "./HLUsersForm";
import { UserService } from "../../../../services/UserService/user.service";
import { ProfileModel } from "../../../../models/HFund/hfund.model";
import { ConfirmationAlert } from "../../../../shared/components/ConfirmationAlert";
import PlaceholderIcon from "../../../../assets/placeholder/avatar_placeholder.png";
import AppModal from "../../../../shared/components/AppModal";
import ListAuditTrial from "./ListAuditTrial";
import RevertChanges from "../../../../shared/components/RevertChanges";
import { roles } from "../../../../shared/components/HOC/roles";
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";
import userRoles from "../../../../shared/utils/userRoles";

export const HLUsers = () => {
  const user = LocalStorage.getItem("USER")
  const [showForm, setFormVisibility] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<ProfileModel[]>([]);
  const [currentUser, setCurrentUser] = useState<ProfileModel>(null);
  const [showDelete, setDeleteVisibility] = useState(false);
  const [auditId, setAuditId] = useState<number>();

  const fetchUsers = (params: any) => {
    setLoading(true)
    UserService.listAllUsers(
      params,
      (response) => {
        setLoading(false)
        setUsers(response)
      },
      () => {
        setLoading(false)
      }
    );
  };
  useEffect(() => {
    fetchUsers({});
  }, []);
  const handleDelete = (companyId: any) => {
    UserService.deleteUser(
      companyId,
      () => {
        setDeleteVisibility(false);
        fetchUsers({});
      },
      () => { }
    );
  };
  return (
    <div className="hfund-container">
      <RevertChanges
        auditId={auditId}
        closeHandler={() => setAuditId(undefined)}
      />
      <PageHeaderComponent
        title="HL Users"
        buttonTitle="New User"
        buttons={
          <>
            <AppModal
              width={"70vw"}
              title={
                <span>
                  <img src={AuditIcon} /> Audit Trail
                </span>
              }
              renderComponent={(closeModal) => (
                <ListAuditTrial
                  closeHandler={(id) => {
                    closeModal();
                    setAuditId(id);
                  }}
                />
              )}
              triggerComponent={(openModal) => (
                <ButtonComponent
                  className="page-header-title__btn"
                  type="primary"
                  icon={<img src={AuditIcon} className="mr-10" />}
                  onClick={openModal}
                >
                  Audit Trail
                </ButtonComponent>
              )}
            />

            {[roles.HL_SUPERADMIN, roles.HL_ADMIN].includes(user?.role) && <ButtonComponent
              className="page-header-title__btn"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setFormVisibility(true)}
            >
              New User
            </ButtonComponent>
            }
          </>
        }
      ></PageHeaderComponent>
      <ConfirmationAlert
        closeHandler={() => setDeleteVisibility(false)}
        visible={showDelete}
        type="delete"
        logo={PlaceholderIcon}
        deleteHandler={() => handleDelete(currentUser?.id)}
        cancelHandler={() => setDeleteVisibility(false)}
        title={"Delete " + currentUser?.firstName + "" + currentUser?.lastName}
        description={currentUser?.companyName}
      />
      <div className="page-body">
        <HLUsersForm
          data={currentUser}
          visible={showForm}
          closeHandler={() => {
            fetchUsers({});
            setCurrentUser(null);
            setFormVisibility(false);
          }}
        />
        <TableComponent
          title={"HL Users List"}
          data={users}
          onSearch={(value) => fetchUsers({ user_name: value })}
          onRow={(index) => setCurrentUser(users[index])}
          loading={loading}
          columns={[
            {
              title: "NAME",
              dataIndex: "firstName",
              key: "firstName",
              render: (firstName, user: ProfileModel) => `${firstName || ""} ${user?.lastName || ""}`.trim()
            },
            {
              title: "ROLE",
              dataIndex: "role",
              key: "id",
              render: role => <span className="text-capitalize">{userRoles?.[role] || role.replaceAll("_", " ")}</span>
            },
            {
              title: "ACCESS TO HF COMPANIES",
              dataIndex: "userCompanies",
              key: "access",
              render: (tags) => (
                <>
                  {tags[0] && <Tag style={{ border: "none" }} key={0}>
                    {tags[0]?.companyName}
                  </Tag>
                  }
                  {tags?.length > 1 && <Tag style={{ border: "none" }} key={1}>
                    +{(tags?.length || 0) - 1}
                  </Tag>
                  }
                </>
              ),
            },
            {
              title: "STATUS",
              dataIndex: "status",
              key: "status",
              render: (status) => (
                <Tag
                  style={{
                    border: "none",
                    borderRadius: "10px",
                    fontStyle: "italic",
                    margin: "5px 0",
                    textTransform: "capitalize",
                  }}
                  color={status.toLowerCase() == "active" ? "green" : "red"}
                >
                  {status?.replaceAll("_", " ")}
                </Tag>
              ),
            },
            {
              title: "PHONE NUMBER",
              dataIndex: "mobileNumber",
              key: "mobileNumber",
            },
            {
              title: "EMAIL ID",
              dataIndex: "email",
              className: "text-transform-unset",
              key: "email"
            },
            {
              title: "",
              key: "action",
              render: (text, record) => (
                <Space size="middle">
                  <i
                    onClick={() => setFormVisibility(true)}
                    className="icon-edit"
                  />
                  <i
                    className="icon-delete"
                    onClick={() => setDeleteVisibility(true)}
                  />
                </Space>
              ),
            },
          ]}
        />{" "}
      </div>
    </div>
  );
};
