import { Col, Row } from "antd";
import * as React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  Router,
  useHistory,
} from "react-router-dom";
import * as appRoutes from "../../../routes/appRoutes";
import GlobalSearch from "../../../shared/components/GlobalSearch";
import HLSidebar from "./Sidebar";
import { HFund } from "./HFund";
import { HLUsers } from "./HLUsers";
import "./home.scss";
import HLCP from "./CP";
import { HLCPDetails } from "./CP/CPDetails";
import CPGroup from "./CP/CPGroup";
import { KT } from "./Configuration/KT";
import OnboardingConfig from "./Configuration/OnboaringConfig";
import HLFunds from "./Funds";
import HLFundDetails from "./FundDetails";
import HLAgreements from "./Agreements";
import HLAgreementDetails from "./Agreements/AgreementDetails";
import Tickets from "../../App/Tickets";
import Help from "../../App/Help";
import Reports from "../../App/Reports";
import ReportList from "../../App/Reports/ReportList";
import Partners from "../../App/Partners";
import Dox from "../../App/Dox";
import DiscussionBoard from "../../DiscussionBoard";
import HFundDetailView from "./HFund/HFundDetailView";
import AccountDetails from "../../App/AccountDetails";
import SubscriptionManagement from "./Configuration/SubscriptionManagement";
import Notification from "./Configuration/Notifcation";
import Dashboard from "../../HF/Home/Dashboard";
import AuthContainer from "../../../store/containers/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
interface HLHomeProps extends AuthReducerProps { }
const HLHome: React.FC<HLHomeProps> = (props) => {
  const {
    authenticated
  } = props
  const history = useHistory();
  const path = history.location.pathname;
  const id = path.split("/").pop();

  const nonSidebarRoutes = [
    appRoutes.REPORTS_SPECIFIC.replace(":reportId", id),
  ];

  if (!authenticated)
    return null

  return (
    <Row className="page__wrapper">
      {!nonSidebarRoutes.find((route) => route === path) && <HLSidebar />}
      <Col className="body-wrapper">
        {!nonSidebarRoutes.find((route) => route === path) && <GlobalSearch />}
        <div className="app-content__wrapper">
          <Switch>
            <Route
              key={10}
              path={appRoutes.DASHBOARD}
              component={Dashboard}
            />
            <Route exact key={1} path={appRoutes.HFUND} component={HFund} />
            <Route
              key={1}
              path={appRoutes.HFUND_COMPANY_DETAILS}
              component={HFundDetailView}
            />
            <Route
              exact
              key={2}
              path={appRoutes.HL_USERS}
              component={HLUsers}
            />
            <Route exact key={3} path={appRoutes.CP} component={HLCP} />
            <Route
              exact
              key={4}
              path={appRoutes.CP_DETAILS}
              component={HLCPDetails}
            />
            <Route
              exact
              key={5}
              path={appRoutes.CP_GROUP}
              component={CPGroup}
            />
            <Route exact key={6} path={appRoutes.KT} component={KT} />
            <Route
              exact
              key={7}
              path={appRoutes.ONBOARDING_CONFIG}
              component={OnboardingConfig}
            />
            <Route exact key={8} path={appRoutes.FUNDS} component={HLFunds} />
            <Route
              exact
              key={9}
              path={appRoutes.FUNDS_SPECIFIC}
              component={HLFundDetails}
            />
            <Route
              exact
              key={10}
              path={appRoutes.AGREEMENTS}
              component={HLAgreements}
            />
            <Route
              exact
              key={11}
              path={appRoutes.AGREEMENTS_SPECIFIC}
              component={HLAgreementDetails}
            />
            <Route
              exact
              key={12}
              path={appRoutes.TICKETS}
              component={Tickets}
            />
            <Route exact key={13} path={appRoutes.HELP} component={Help} />
            <Route
              exact
              key={14}
              path={appRoutes.REPORTS}
              component={Reports}
            />
            <Route
              exact
              key={15}
              path={appRoutes.REPORTS_SPECIFIC}
              component={ReportList}
            />
            <Route
              exact
              key={16}
              path={appRoutes.PARTNERS}
              component={Partners}
            />
            <Route
              exact
              path={appRoutes.ACCOUNT_DETAILS}
              component={AccountDetails}
            />
            <Route
              exact
              path={appRoutes.HL_SUBSCRIPTION}
              component={SubscriptionManagement}
            />

            <Route
              exact
              path={appRoutes.NOTIFICATION_CONFIGURATION}
              component={Notification}
            />
            <Route exact key={17} path={appRoutes.DOX} component={Dox} />
            <Route
              exact
              path={appRoutes.DISCUSSION_BOARD}
              component={DiscussionBoard}
            />
            <Redirect exact strict from={appRoutes.HOME} to={appRoutes.DASHBOARD} />
          </Switch>
        </div>
      </Col>
    </Row>
  );
};

export default AuthContainer(HLHome)