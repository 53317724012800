import { Row, Col } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import * as appRoutes from "./../../../routes/appRoutes"

const PrivacyPolicy = () => {
    return (
        <Row className='terms-of-use'>
            <Col span={24} className="text-center"><h2>PRIVACY POLICY</h2></Col>
            <Col span={24} className="text-center subtitle">(Last modified on June 28, 2022)</Col>

            <Col span={24}>
                <Row>
                    <Col span={24}>
                        <p className='title'>
                            Welcome!
                        </p>
                        <p>
                            Welcome to the TradingDoX Platform (the <b>“Platform”</b> ) accessible via the URL <Link to={"/"}>https://tradingdox.com</Link> (the <b>“Website”</b> ).  We are delighted that you have chosen to visit us and / or use the Platform to manage your hedge fund documents. TradingDoX Technologies Inc. ( <b>“TDX Inc.”</b> ) (the company that owns and operates the Platform) takes your privacy and security of Personal Information very seriously. We have provided and will continue to provide a secure environment and this strict Privacy Policy (the <b>“Policy”</b> ) that describes the limited ways your information is used and the limited access to such information. We ask that you read it carefully.
                        </p>
                        <p className='title'>
                            Key Elements of this Policy
                        </p>
                        <p>
                            Here are the key elements of the Policy so you can know the important parts right away to make an informed decision about your consent for our collection, use and disclosure of your Personal Information when you use the Platform. You can find the details in the rest of the Policy.
                        </p>
                        <table cellPadding={5} cellSpacing={5}>
                            <tr>
                                <th>Personal Information we collect from you with your consent</th>
                                <th>What we do with it</th>
                                <th>Third parties we share it with</th>
                            </tr>
                            <tr>
                                <td>Account Information and Profile Information </td>
                                <td>Manage your account and Subscription</td>
                                <td>Companies providing technical infrastructure and software for the Platform, such as Amazon AWS</td>
                            </tr>
                            <tr>
                                <td>Authorized User Information</td>
                                <td>Create accounts for your Authorized Users</td>
                                <td>Companies providing technical infrastructure and software for the Platform, such as Amazon AWS</td>
                            </tr>
                        </table>
                        <p className='title'>
                            About Us and Definitions
                        </p>
                        <p>
                            The Website and Platform are owned and operated by TDX Inc., a duly incorporated company in the Province of Quebec, Canada. TDX Inc. owns and operates <b>“TradingDoX”</b>, which refers to the business which includes the Platform and all related business processes and entities. Where the present Policy refers to <b>“TradingDoX”</b>, it may refer to TradingDoX Technologies Inc. and / or its affiliates, and their respective shareholders, officers, directors, employees, agents, partners, principals, representatives, successors and assigns (collectively <b>“Representatives”</b> ), depending on the context. Any reference to <b>“we”</b>, <b>“our”</b>, or <b>“us”</b> in this Policy shall also refer to TradingDoX Technologies Inc. In this Policy a Platform user may be referred to as <b>“you”</b>.
                        </p>
                        <p>
                            Additional definitions shall be made throughout this Policy, but they will be recognizable as they will be capitalized, bolded, and in quotation marks. Additional definitions may also be found in the <Link to={appRoutes.TERMS_OF_USE}>Terms of Use</Link>, and will have the same meaning in this Policy as they do there. The definitions found in this Policy shall also apply to the Terms of Use
                        </p>
                        <p className='title'>
                            Policy Acceptance and Modifications; Contacting Us
                        </p>
                        <p><i>By visiting the Website or using the Platform you agree to be bound by the terms of the Policy. By submitting Personal Information to us through the Platform or via other means, you consent to TradingDoX’ collection, use and disclosure of such Personal Information (as that term is defined hereinbelow) in accordance with this Policy (as amended from time to time) and as permitted or required by law.
                        </i></p>
                        <p>
                            TradingDoX reserves the right, at any time and without prior notice, to modify or replace any of the Policy. Any changes to the Policy can be found at this URL or the relevant URL available through <a href="/">https://www.tradingdox.com</a>. It is your responsibility to check the Policy periodically for changes. Your use of the Platform following the posting of any changes to the Policy constitutes acceptance of those changes. If there are any significant changes to the Policy that materially affect the way we treat your information, we will use commercially reasonable efforts to notify you by posting a prominent notice on the Platform, and emailing you if you an account with us. You can always identify the most recent version of the Policy by the date at the top of this page.
                        </p>
                        <p>
                            The Policy should be read in conjunction with the <Link to={appRoutes.TERMS_OF_USE}>Terms of Use</Link>, as both these documents govern your use of the Platform.
                        </p>
                        <p>
                            If you have any questions about the Policy or the platform itself, or if you wish to inquire about and / or access any Personal Information TradingDoX holds about you, please contact:
                        </p>
                        <p>
                            TradingDoX Privacy Officer
                        </p>
                        <p>
                            <a href="mailto:support@tradingdox.com ">support@tradingdox.com </a>
                        </p>
                        <p className='title'>
                            What Does This Privacy Policy Cover?
                        </p>
                        <p>
                            This Policy covers the treatment of personally-identifiable information, i.e. any information that identifies an individual, or can be used in conjunction with other information to identify an individual ( <b>“Personal Information”</b> ) and other information gathered by TradingDoX when you are visiting the Website or using the Platform. This Policy also covers TradingDoX’ treatment of any Personal Information that TradingDoX may share with its business partners or other third parties under very limited circumstances.
                        </p>
                        <p>
                            This Policy does not apply to the general practices and treatment of information (whether Personal or not) by third parties that TradingDoX does not own or control, including but not limited to any third-party websites or Platform that you elect to access through the Platform or via a link from the Platform that are not owned by TradingDoX ( <b>“Third-Party Websites”</b> ), or to individuals that TradingDoX does not manage or employ. While we attempt to facilitate access or link only to those Third-Party Websites that share our respect for your privacy, we cannot take responsibility for the content or privacy policies of those Third-Party Websites. We encourage you to carefully review the privacy policies of any Third-Party Website you access.
                        </p>
                        <p>
                            Finally, this Policy does not cover any Personal Information you send to TradingDoX via any non-secured, non-encrypted mode of communications such as email, instant messenger or other messaging service, or Twitter or any other social media, as communications via these methods may be subject to interception, loss, or alteration. While we welcome your comments and suggestions sent to us in this manner, we encourage you to carefully examine what Personal Information you send to TradingDoX via these methods.
                        </p>
                        <p className='title'>
                            No Collection of Personal Information as a Matter of Course
                        </p>
                        <p>
                            TradingDoX does not, as a matter of course, gather any of your Personal Information while you visit the Website or use the Platform, unless you are asked to provide such information and voluntarily choose to do so, for example by signing up for an account. Personal Information is only gathered with your informed consent. Where you have provided Personal Information, it will only be used for the stated purpose, as detailed more specifically in the next section.
                        </p>
                        <p className='title'>
                            Personal Information Collected and Its Uses
                        </p>
                        <p>
                            In the following table you will find a non-exhaustive list of Personal Information collected from you when you visit the Website or use the Platform and what we use it for. You acknowledge and agree that you are submitting this Personal Information with your informed consent.
                        </p>
                        <table cellPadding={5} cellSpacing={5}>
                            <tr>
                                <th>Personal Information Category</th>
                                <th>Personal information collected</th>
                                <th>What we use it for</th>
                            </tr>
                            <tr>
                                <td>Account Information</td>
                                <td>First and last name, your email address and phone number</td>
                                <td>Manage your account and Subscription, enable logging in to the Platform, and send you emails or call you about your account</td>
                            </tr>
                            <tr>
                                <td>Profile Information</td>
                                <td>Your first and last name, company name, company phone number, and company address</td>
                                <td>Customize your use of the Platform and create your documents that form part of the TradingDoX Output </td>
                            </tr>
                            <tr>
                                <td>Authorized User Information*</td>
                                <td>First and last name, phone number and email address of Authorized Users</td>
                                <td>Create accounts for your Authorized Users and communicate with them about their accounts</td>
                            </tr>
                            <tr>
                                <td>Billing Information</td>
                                <td>Your billing address</td>
                                <td>To include in the invoice we send you for payment of your Subscription Fees</td>
                            </tr>
                        </table>
                        <p>
                            *Please note that Authorized User Information is collected from the owner of the Subscription. When you submit such Authorized User Information you acknowledge and agree that you have the right to submit it on behalf of the Authorized User and have received their consent to do so.
                        </p>
                        <p>
                            In addition to the functions and uses just described, TradingDoX may also use your Personal Information you have submitted in order to perform any functions as otherwise explicitly described to you at the time of your submission of your Personal Information.
                        </p>
                        <p className='title'>
                            Security of Information
                        </p>
                        <p>
                            TradingDoX uses reasonable efforts (physical, electronic and procedural) in keeping any data collected (including Personal Information) secure. In addition, TradingDoX uses third-party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to operate the Website and Platform, and these third parties have been selected for their high standards of security, both electronic and physical. For example, TradingDoX uses <a href="https://www.microsoft.com/en-ww/microsoft-365/onedrive/online-cloud-storage" target="_blank" rel="noopener noreferrer"> Microsoft OneDrive</a> and <a href="https://aws.amazon.com/" target="_blank" rel="noopener noreferrer">Amazon AWS</a>, recognized leaders in secure data, for hosting of the Platform and related data, and backup storage of data including Personal Information.
                        </p>
                        <p>
                            We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable supervisory authority of a suspected data security breach where data protection laws require us to do so, and within the time frame required by the applicable data protection law.
                        </p>
                        <p>
                            All information, including Personal Information, is transferred with encryption using Secure Sockets Layer (“SSL”) or Transport Layer Security (“TLS”), robust security standards for Internet data transfer and transactions. You can use your browser to check TradingDoX’ valid SSL security certificate for the Platform.
                        </p>
                        <p className='title'>
                            Security of User Data
                        </p>
                        <p>
                            TradingDoX recognises the need for enhanced security practices regarding your User Data you submit through the Platform. In addition to the security measures mentioned above, we have taken the following measures to ensure the security of your User Data:
                        </p>
                        <p>
                            <span className='ml-5 mr-5'>-</span>
                            <span>
                                All sensitive User Data is encrypted and stored in our database using “bcrypt” that uses hashing algorithm to encrypt data.
                            </span>
                        </p>
                        <p>
                            <span className='ml-5 mr-5'>-</span>
                            <span>
                                Two factor authentication is an option for all users.
                            </span>
                        </p>
                        <p>
                            <span className='ml-5 mr-5'>-</span>
                            <span>
                                Amazon Guard Duty is implemented such that security threats are continuously monitored.
                            </span>
                        </p>
                        <p className='title'>
                            Non-disclosure of Personal Information to Third Parties
                        </p>
                        <p>
                            Except as provided in the next paragraph and specified in the next three sections of this Policy, TradingDoX does not divulge any Personal Information gathered via the Platform to third parties. Moreover, TradingDoX does not sell, rent, trade or license any Personal Information regarding its Website visitors or Platform users to third parties. Only the Representatives and subcontractors of TradingDoX are responsible for the management and development of the Website and Platform, and only these individuals have access to the information collected there. These Representatives and subcontractors all have been instructed to comply with the Policy, and are under contractual obligations of non-disclosure.
                        </p>
                        <p>
                            Notwithstanding anything in the Policy to the contrary, we may share any information we have collected about you or that you have submitted (whether Personal Information or not): (1) in response to subpoenas, court orders, or legal process, or to establish, protect, or exercise our legal rights or defend against legal claims or demands; (2) if we believe it is necessary in order to investigate, prevent, or take action regarding illegal activities, fraud, or situations involving potential threats to the safety of any person, or any violation of the Terms of Use; (3) if we believe it is necessary to investigate, prevent, or take action regarding situations that involve abuse of the Website or Platform infrastructure or the Internet in general (such as voluminous spamming, denial of service attacks, or attempts to compromise the security of the Website or Platform infrastructure or the Website or Platform generally); (4) to a parent company, subsidiaries, joint ventures, or other companies under common control with TradingDoX (in which case we will require such entities to honour this Policy); (5) if TradingDoX merges with another entity, is subject to a corporate reorganization, or sells or transfers all or part of its business, assets or shares (in which case we will require such entity to assume our obligations under this Policy, or inform you that you are covered by a new privacy policy).
                        </p>
                        <p className='title'>
                            Transfer of Personal Information to Third Parties
                        </p>
                        <p>
                            We routinely share some of your Personal Information with certain third parties in order to facilitate the functioning of the Platform. These third parties are identified in the table below, along with what they do with the Personal Information transferred to them.
                        </p>
                        <table cellPadding={5} cellSpacing={5}>
                            <tr>
                                <th>Personal Information category</th>
                                <th>Who we transfer it to</th>
                                <th>What they do with it</th>
                            </tr>
                            <tr>
                                <td>Account Information</td>
                                <td>Companies providing technical infrastructure and software for the Platform, such as Amazon AWS</td>
                                <td>Store it in order to manage your account and Subscription, enable logging in to the Platform, and allow us to retrieve it so we can send you emails or call you about your account</td>
                            </tr>
                            <tr>
                                <td>Profile Information</td>
                                <td>Companies providing technical infrastructure and software for the Platform, such as Amazon AWS</td>
                                <td>Store it so that we may retrieve it to customize your use of the Platform and create your documents that form part of the TradingDoX Output </td>
                            </tr>
                            <tr>
                                <td>Account Information </td>
                                <td>Companies providing technical infrastructure and software for the Platform, such as Amazon AWS</td>
                                <td>Manage your account and Subscription, enable logging in to the Platform, and send you emails or call you about your account</td>
                            </tr>
                            <tr>
                                <td>Billing Information</td>
                                <td>Companies providing data storage, specifically Microsoft OneDrive</td>
                                <td>Store it so we may retrieve it to include in the invoice we send you for payment of your Subscription Fees</td>
                            </tr>
                            <tr>
                                <td>Analytics identifiers and IP address </td>
                                <td>Companies that provide data analytics for the Website and Platform, such as <a href="https://marketingplatform.google.com/about/analytics/" target="_blank" rel="noopener noreferrer"> Google Analytics</a></td>
                                <td>Provide us with analytics as to how the Website and Platform are used, as further detailed in the <i>Limited Gathering</i> of Information section below</td>
                            </tr>
                        </table>
                        <p className='title'>
                            Limited Gathering of Information for Statistical, Analytical and Security Purposes
                        </p>
                        <p>
                            TradingDoX automatically collects certain information using third-party analytics program  <a href="https://marketingplatform.google.com/about/analytics/" target="_blank" rel="noopener noreferrer"> Google Analytics</a> to help us understand how our users use the Website and the Platform, but none of this information identifies you personally. For example, each time you visit the Website, we automatically collect (as applicable) your IP address, browser and computer or device type, access times, the web page from which you came, the web page(s) or content you access, and other related information. We use information collected in this manner only to better understand your needs and the needs of Website visitors and Platform users in the aggregate. TradingDoX also makes use of information gathered for statistical purposes to keep track of the number of visits to the Website, the specific pages on the Website, and users with a view to introducing improvements to the Website and Platform.
                        </p>
                        <p>
                            Your IP address and other relevant information we collect using Google Analytics may be used in order to trace any fraudulent or criminal activity, or any activity in violation of the Terms of Use.
                        </p>
                        <p className='title'>
                            Email Communications and Compliance with Anti-Spam Laws
                        </p>
                        <p>
                            TradingDoX uses <a href="https://www.mailgun.com/" target="_blank" rel="noopener noreferrer">mailgun</a> to send out emails related to various Platform functions (the “Email Service Provider”). Personal Information (your email address) is transferred to the Email Service Provider in order to manage the mailing list and for the emails to be sent out properly. Your Contact Information is only used to send out emails; the Email Service Provider does not use this Personal Information for any other purpose, and will not transfer or sell your Personal Information to any other third party.
                        </p>
                        <p>
                            You may unsubscribe from our any TradingDoX publicity emails at any time, by following the link at the bottom of those TradingDoX emails. Other types of emails, such as transactional, relational, and other emails related to certain Platform functions will not have an opt-out option as they are necessary for the use of the Platform.
                        </p>
                        <p>
                            TradingDoX’ practices in regards to its email are designed to be compliant with anti-spam laws, specifically the law unofficially called “CASL”, or Canada’s Anti-Spam Law (S.C. 2010, c. 23). If you believe you have received email in violation of these laws, please contact us using the contact information further up in this Policy.
                        </p>
                        <p className='title'>
                            Tracking Technology ("Cookies") and Related Technologies
                        </p>
                        <p>
                            TradingDoX uses tracking technology (“cookies” and related technology such as tags, pixels and web beacons) on the Platform and by visiting or using the Website you agree to their use. Cookies are small text files placed on your computer or device when you visit a website, in order to track use of the site and to improve the user experience by storing certain data on your computer.
                        </p>
                        <p>
                            Specifically, we use cookies and related technologies for the following functions:
                        </p>
                        <ul>
                            <li>
                                to facilitate logging in to the Platform;
                            </li>
                            <li>
                                to provide general internal and user analytics and to conduct research to improve the content of the Website and Platform using Google Analytics as described above in this Policy; and
                            </li>
                            <li>
                                to assist in identifying possible fraudulent activities.
                            </li>
                        </ul>
                        <p>
                            Your browser can be set to refuse cookies or delete them after they have been stored. You can refer to your browser’s help section for instructions, but here are instructions for the most commonly-used browsers and operating systems:
                        </p>
                        <ul>
                            <li>
                                <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform=Desktop&hl=en" target="_blank" rel="noopener noreferrer">
                                    Google Chrome
                                </a>
                            </li>
                            <li>
                                <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US" target="_blank" rel="noopener noreferrer">
                                    Mozilla Firefox
                                </a>
                            </li>
                            <li>
                                <a href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd" target="_blank" rel="noopener noreferrer">
                                    Microsoft Edge
                                </a>
                            </li>
                            <li>
                                <a href="https://help.opera.com/en/latest/web-preferences/" target="_blank" rel="noopener noreferrer">
                                    Opera
                                </a>
                            </li>
                            <li>
                                <a href="https://support.apple.com/kb/ph21411?locale=en_US" target="_blank" rel="noopener noreferrer">
                                    Apple Safari
                                </a>
                            </li>
                            <li>
                                <a href="https://support.apple.com/en-ca/HT201265" target="_blank" rel="noopener noreferrer">
                                    iOS
                                </a>
                            </li>
                            <li>
                                <a href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform=Android&hl=en" target="_blank" rel="noopener noreferrer">
                                    Android
                                </a>
                            </li>
                        </ul>
                        <p>
                            Please note that deleting these cookies may reduce your user experience on the Platform. Furthermore, deleting cookies may prevent certain functions from working at all. TradingDoX and its Representatives shall not be held responsible for any function that may not operate after you delete cookies, or any losses or damages (pecuniary or otherwise) arising from those functions not operating, or having reduced functionality.
                        </p>
                        <p className='title'>
                            Verification, Correction and Deletion of Personal Information
                        </p>
                        <p>
                            You have the right to: (i) verify what Personal Information TradingDoX holds about you; (ii) ask for your Personal Information to be corrected or updated; and (iii) to withdraw your consent to the use by TradingDoX of your Personal Information and have it deleted from our records. If you wish to inquire about and verify and / or correct Personal Information TradingDoX holds about you, or if you wish to have all your Personal Information permanently deleted from our records, please contact us using the contact information further up in this Policy. Please note that deletion of your Personal Information may make it impossible for you to use the Platform, or certain portions thereof, and TradingDoX and its Representatives accepts no responsibility for any damages, pecuniary or otherwise, resulting therefrom. If you request  deletion of your Personal Information, TradingDoX reserves the right to retain some of your Personal Information for a reasonable time in order to satisfy our legal obligations or under a legal procedure of any sort.
                        </p>
                        <p className='title'>
                            Risks Associated with the Internet and Wireless Data Transmission
                        </p>
                        <p>Despite TradingDoX’s best efforts to ensure that third parties will not access or obtain your Personal Information through your use of the Platform, complete confidentiality and security cannot currently be guaranteed on the Internet and / or where data is transferred via digital wireless technology of any type. Communication via the Internet or wireless data transmission is subject to interception, loss, or alteration. You acknowledge and agree that TradingDoX and its Representatives cannot be held responsible for damages resulting from the transmission of confidential information or Personal Information in this manner and that such communications are at your own risk.</p>
                        <p className="title">
                            Compliance with Privacy Laws
                        </p>
                        <p>This Policy and TradingDoX’s practices in general are designed to be in compliance with Canada’s <i>Personal Information Protection and Electronic Documents Act</i> (“PIPEDA”, S.C. 2000, c. 5) and Quebec’s <i>An Act Respecting the Protection Of Personal Information in the Private Sector</i> (R.S.Q. c. P-39.1). If you have any questions regarding this compliance, please contact us using the contact information further up in this Policy.</p>
                        <p>© TradingDoX Technologies Inc. 2022</p>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default PrivacyPolicy