import * as React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./pageHeader.scss";
import { SearchInput } from "../SearchInput";
import { Tabs } from "antd";
import { useHistory } from "react-router";
const { TabPane } = Tabs;
interface PageHeaderProps {
  title: string;
  subTitle?: string;
  buttonTitle?: string;
  children?: any;
  buttons?: any;
  titleHelper?: JSX.Element | JSX.Element[]
  showSearch?: boolean;
  onSearch?: (value: string) => void;
  showTabs?: boolean;
  tabs?: any[];
  onChangeTab?: (key: any) => void;
  showBack?: boolean;
  defaultValue?: string;
}
export const PageHeaderComponent = ({
  title,
  subTitle,
  showBack = false,
  titleHelper,
  buttons,
  showSearch = false,
  showTabs = false,
  tabs,
  onSearch,
  onChangeTab,
  defaultValue
}: PageHeaderProps) => {
  const history = useHistory()
  return (
    <div className="page-header__container">
      <div className="page-header-title__wrapper">
        <p className="page-header__title">
          {/* {showBack && <span className="page-header__title--back-btn" onClick={() => history.goBack()}><ArrowLeftOutlined /></span>} */}
          <h1 className="page-header__title--title" >{title}</h1>
          {titleHelper && <div className="page-header__title--helper">
            {titleHelper}
          </div>}
        </p>
        <div className="page-header-actions__container">
          {showSearch && <SearchInput onSearch={(value) => onSearch(value)} />}
          <div className="page-header-title-btn__wrapper">{buttons}</div>
        </div>
      </div>
      {showTabs && (
        <Tabs
          defaultActiveKey={defaultValue ?? "1"}
          onChange={(key) => onChangeTab(key)}
          style={{ backgroundColor: "white" }}
        >
          {tabs?.map((value, index) => {
            return <TabPane tab={value} key={index + 1}></TabPane>;
          })}
        </Tabs>
      )}
    </div>
  );
};
