export const fundStructure = [
    { label: "Limited Partnership", value: "limited_partnership" },
    { label: "Limited Company", value: "limited_company" },
    { label: "SICAV", value: "sicav" },
    { label: "Trust", value: "trust" },
    { label: "Other", value: "other" },
]
export const fundStatuses = [
    { label: "Pre Launch", value: "pre_launch" },
    { label: "Active", value: "active" },
    { label: "Terminated", value: "terminated" },
]

export const fundStrategies = [
    { label: "Convertible Arbitrage", value: "convertible_arbitrage" },
    { label: "Credit Long/Short", value: "credit_long_short" },
    { label: "Distressed", value: "distressed" },
    { label: "Equity Long/Short", value: "equity_long_short" },
    { label: "Event Driven", value: "event_driven" },
    { label: "Fixed Income", value: "fixed_income" },
    { label: "Fixed Income Arbitrage", value: "fixed_income_arbitrage" },
    { label: "Fund of Funds", value: "fund_of_funds" },
    { label: "Long Only", value: "long_only" },
    { label: "Macro", value: "macro" },
    { label: "Managed Futures", value: "managed_futures" },
    { label: "Market Neutral", value: "market_neutral" },
    { label: "Merger Arbitrage", value: "merger_arbitrage" },
    { label: "Multi-Strategy", value: "multi_strategy" },
    { label: "Opportunistic", value: "opportunistic" },
    { label: "Volatility Arbitrage", value: "volatility_arbitrage" },
    { label: "Activist Investing", value: "activist_investing" },
    { label: "Relative value Arbitrage", value: "relative_value_arbitrage" },
    { label: "Quantitative", value: "quantitative" },
]
export const fundSubStrategies = [
    { label: "Convertible Arbitrage", value: "sub_convertible_arbitrage" },
    { label: "Credit Long/Short", value: "sub_credit_long_short" },
    { label: "Distressed", value: "sub_distressed" },
    { label: "Equity Long/Short", value: "sub_equity_long_short" },
    { label: "Event Driven", value: "sub_event_driven" },
    { label: "Fixed Income Arbitrage", value: "sub_fixed_income_arbitrage" },
    { label: "Fund of Funds", value: "sub_fund_of_funds" },
    { label: "Long Only", value: "sub_long_only" },
    { label: "Macro", value: "sub_macro" },
    { label: "Managed Futures", value: "sub_managed_futures" },
    { label: "Market Neutral", value: "sub_market_neutral" },
    { label: "Merger Arbitrage", value: "sub_merger_arbitrage" },
    { label: "Multi-Strategy", value: "sub_multi-strategy" },
    { label: "Opportunistic", value: "sub_opportunistic" },
    { label: "Volatility Arbitrage", value: "sub_volatility_arbitrage" },
    { label: "Activist Investing", value: "sub_activist_investing" },
    { label: "Relative value Arbitrage", value: "sub_relative_value_arbitrage" },
    { label: "Quantitative", value: "sub_quantitative" },
]

export const productsTraded = [
    { label: "Cash Equities", value: "cash_equities" },
    { label: "Equity Synthetics", value: "equity_synthetics" },
    { label: "Fixed Income", value: "fixed_income" },
    { label: "Credit", value: "credit" },
    { label: "FX Forwards", value: "fx_forwards" },
    { label: "Interest Rate Swaps", value: "interest_rate_swaps" },
    { label: "Listed Derivatives (futures)", value: "listed_derivatives" },
    { label: "OTC Cleared Swaps", value: "otc_cleared_swaps" },
]

export const geographicFocus = [
    { label: "North America", value: "north_america" },
    { label: "Europe", value: "europe" },
    { label: "Asia", value: "asia" },
    { label: "Emerging Markets", value: "emerging_markets" },
]

export const fundBaseCurrencies = [
    { label: "USD", value: "usd" },
    { label: "CAD", value: "cad" },
    { label: "EUR", value: "eur" },
    { label: "GBP", value: "gbp" },
    { label: "JPY", value: "jpy" },
    { label: "CNY", value: "cny" },
    { label: "CNH", value: "cnh" },
]

export const feederFund = [
    { label: "Yes", value: true },
    { label: "No", value: false },

]