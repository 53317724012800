import React, { useState } from "react";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { User } from "../../models/User/user.model";
import { HL_USER_MANAGEMENT, HF_USER_MANAGEMENT } from "../../routes/apiRoutes";
import { LocalStorage } from "../../shared/utils/localStorageHelpers";

const UserManagementService = () => {
  const [users, setUsers] = useState<User[]>([]);

  const isAdmin = () => {
    const user = LocalStorage.getItem("USER");
    return user?.role?.includes("hl");
  };
  const getUsers = async (search?: string, company_id?: number | string) => {
    try {
      const response = await axiosInstance.get(
        company_id
          ? HL_USER_MANAGEMENT
          : HF_USER_MANAGEMENT
        ,
        {
          params: {
            search,
            company_id,
          },
        }
      );
      const users = deserialize(User, response?.data?.users as any[]);
      setUsers(users);
    } catch (error) {
    }
  };

  const createUser = async (user: User, company_id?: number | string) => {
    try {
      const serializedUser = Object.assign(new User(), { ...user, companyId: company_id });
      const userJSON = {
        user: serialize(serializedUser),
      };
      const { data } = await axiosInstance.post(
        company_id
          ? HL_USER_MANAGEMENT
          : HF_USER_MANAGEMENT,
        userJSON
      );
      const createdUser = deserialize(User, data?.user);
      setUsers((users) => [...users, createdUser]);
      return createdUser;
    } catch (error) {
      return false;
    }
  };

  const updateUser = async (user: User, company_id?: number | string) => {
    try {
      const serializedUser = Object.assign(new User(), { ...user, companyId: company_id });
      const userJSON = {
        user: serialize(serializedUser),
      };
      const URL =
        (company_id
          ? HL_USER_MANAGEMENT
          : HF_USER_MANAGEMENT) +
        ("/" +
          user.id);
      const { data } = await axiosInstance.put(URL, userJSON);
      const updatedUser = deserialize(User, data?.user);
      const index = users.findIndex((user) => user?.id === updatedUser.id);
      setUsers((users) => {
        const cloneUsers = [...users];
        cloneUsers.splice(index, 1, updatedUser);
        return cloneUsers;
      });
      return updatedUser;
    } catch (error) {
      return false;
    }
  };

  const deleteUser = async (userId: string | number, company_id?: number | string) => {
    try {
      const URL =
        (company_id
          ? HL_USER_MANAGEMENT
          : HF_USER_MANAGEMENT) +
        "/" +
        userId;
      await axiosInstance.delete(URL);
      const index = users.findIndex((user) => user?.id === userId);
      setUsers((users) => {
        const cloneUsers = [...users];
        cloneUsers.splice(index, 1);
        return cloneUsers;
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  return {
    users,
    getUsers,
    createUser,
    updateUser,
    deleteUser,
  };
};

export default UserManagementService;
