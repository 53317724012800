import { CloseCircleOutlined } from "@ant-design/icons";
import * as React from "react";
import PlaceholderIcon from "../../../assets/icons/upload logo.svg";
import "./attachmentComponent.scss";
interface AttachmentComponentProps {
  image?: any;
  title?: string;
  onUpload: (value: any) => void;
  accept: string;
  disableClose?: boolean;
}
export const AttachmentComponent = ({
  image,
  onUpload,
  title,
  accept,
  disableClose,
}: AttachmentComponentProps) => {
  const attachmentRef = React.useRef<HTMLInputElement>(null);
  const [imageUrl, setImageUrl] = React.useState<string>();
  const [file, setFile] = React.useState<File>();

  React.useEffect(() => {
    image &&
      setImageUrl(
        typeof image == "object" ? URL.createObjectURL(image) : image
      );
  }, [image]);

  const handleUpload = (file: any) => {
    setImageUrl(URL.createObjectURL(file));
    setFile(file);
    onUpload && onUpload(file);
  };

  const removeAttachment = () => {
    setImageUrl(null);
  };
  return (
    <div className="attachment-container">
      <input
        ref={attachmentRef}
        accept={accept}
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          let file = e.target.files?.length && e.target.files[0];
          handleUpload(file);
        }}
      />
      {imageUrl ? (
        accept?.includes("pdf") ? (
          <div className="attachment__pdf--container">
            {!disableClose && (
              <span
                className="attachment__pdf--remove"
                onClick={removeAttachment}
              >
                <CloseCircleOutlined />
              </span>
            )}
            <embed
              src={imageUrl + "#view=FitH"}
              className="attachment__pdf"
              onClick={() => attachmentRef.current.click()}
            />
          </div>
        ) : (
          <img
            src={imageUrl}
            className="attachment__image"
            onClick={() => attachmentRef.current.click()}
          />
        )
      ) : (
        <>
          <div
            className="attachment__placholder"
            onClick={() => attachmentRef.current.click()}
          >
            <img src={PlaceholderIcon} />
          </div>
          <p className="attachment__title">{title}</p>
        </>
      )}
    </div>
  );
};
