import React, { FC, useEffect, useState } from "react";
import { Tabs } from "antd";
import "./discussionBoard.scss";
import DiscussionList from "./DiscussionList";
import { Topic } from "../../models/Topic/topic.model";
import DiscussionCard from "./DiscussionList/DiscussionCard";
import AppModal from "../../shared/components/AppModal";
import { LocalStorage } from "../../shared/utils/localStorageHelpers";
import { useHistory, useLocation } from "react-router";
import { DISCUSSION_BOARD } from "../../routes/appRoutes";

const { TabPane } = Tabs;

interface DiscussionBoardProps { }

const DiscussionBoard: FC<DiscussionBoardProps> = (props) => {
  const { } = props;

  const [topic, setTopic] = useState<string>();

  const location = useLocation();

  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setTopic(searchParams.get("topicId"));
  }, []);

  const user = LocalStorage.getItem("USER");

  return (
    <div className="discussion-board">
      <div className="header">
        {topic && (
          <i
            className="icon-back"
            onClick={() => {
              setTopic(undefined);
              history.replace(DISCUSSION_BOARD);
            }}
          />
        )}
        <h1>Discussion Board</h1>
      </div>
      {topic ? (
        <div className="discussion-card__details">
          <DiscussionCard setTopic={() => setTopic(undefined)} topicId={topic} showComments noUpdate />
        </div>
      ) : (
        <Tabs defaultActiveKey="1">
          <TabPane tab="Explore" key="1">
            <DiscussionList
              viewTopicHandler={(topic) => setTopic(topic?.id?.toString())}
              type="explore"
            />
          </TabPane>
          <TabPane tab="My Topics" key="2">
            <DiscussionList
              viewTopicHandler={(topic) => setTopic(topic?.id?.toString())}
              type="myTopic"
            />
          </TabPane>
          {user?.role?.includes("hf") ? (
            <TabPane tab="My Company Topics" key="3">
              <DiscussionList
                viewTopicHandler={(topic) => setTopic(topic?.id?.toString())}
                type="myCompanyTopic"
              />
            </TabPane>
          ) : null}
        </Tabs>
      )}
    </div>
  );
};

export default DiscussionBoard;
