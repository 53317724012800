import { Button, Col, Row } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { FC, useEffect, useState } from "react";
import AuditViewTitle from "../../../components/HL/Home/HLUsers/ListAuditTrial/AuditViewTitle";
import AuditTrialService from "../../../services/AuditTrialService/auditTrial.service";
import "./revertChanges.scss";

interface RevertChangesProps {
  auditId?: number | boolean;
  closeHandler: () => void;
}

const RevertChanges: FC<RevertChangesProps> = (props) => {
  const { auditId, closeHandler } = props;

  const { loading, successiveTrails, getAuditTrial, undoAudit, auditTrail } =
    AuditTrialService();

  useEffect(() => {
    if (auditId) {
      getAuditTrial(typeof auditId === "number" ? auditId : undefined);
    }
  }, [auditId]);

  const handleRevert = async () => {
    const success = await undoAudit(
      typeof auditId === "number" ? auditId : auditTrail.id
    );

    if (success) {
      closeHandler();
      window.location.reload();
    }
  };

  return (
    <Modal
      visible={auditId ? true : false}
      width="75vw"
      className="revert-changes__modal"
      destroyOnClose
    >
      <div className="revert-changes">
        <h3 className="text-danger text-center">
          All these Changes will be reverted
        </h3>

        {loading
          ? "Loading . . ."
          : successiveTrails?.length
            ? successiveTrails.filter(trail => trail.event !== "view")?.map((audit) => (
              <AuditViewTitle auditTrail={audit} key={audit?.id} />
            ))
            : <p className="text-center text-grey">Nothing to revert</p>}
        <Row justify="end">
          <Col>
            <Button type="text" className="mr-5" onClick={closeHandler}>
              Cancel
            </Button>
            <Button type="dashed" onClick={handleRevert}>
              Revert
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default RevertChanges;
