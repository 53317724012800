import React, { FC } from "react";
import './emptyState.scss';
import emptyStateImage from "../../../assets/icons/archive.svg";

interface EmptyStateProps {
    text?: string;
    centerAlign?: boolean
}

const EmptyState: FC<EmptyStateProps> = ({
    text = "No results found",
    centerAlign = false
}) => {


    return (
        <div className={`empty-state ${centerAlign ? 'position-abs' : ''}`}>
            <img src={emptyStateImage} alt='' className='empty-state__image' />
            <div className='empty-state__message'>{text}</div>
        </div>
    )
}

export default EmptyState;
