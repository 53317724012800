import { Pagination } from './../../models/Pagination/pagination.model';
import { deserialize } from "serializr";
import { CounterParties } from "./../../models/CounterParties/counterParties.model";
import { Agreement } from "./../../models/Agreements/agreement.model";
import { Fund } from "./../../models/Fund/fund.model";
import { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import * as ApiRoutes from "../../routes/apiRoutes";
import { Topic } from "../../models/Topic/topic.model";
import { KeyTerm } from "../../models/KeyTerm/keyTerm.model";
import { Document } from "../../models/Document/document.model";

const GlobalSearchService = () => {
  const [keyTerms, setKeyTerms] = useState<KeyTerm[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<
    Fund[] | Agreement[] | CounterParties[]
  >([]);
  const [searchPagination, setSearchPagination] = useState<Pagination>()
  const [documents, setDocuments] = useState<Document[]>([])
  const [documentPagination, setDocumentPagination] = useState<Pagination>()

  const fetchRecentSearches = async () => {
    try {
      const response = await axiosInstance.get(ApiRoutes.GLOBAL_SEARCH_TERMS);
      const data = response.data["search_texts"];
      setSearchTerms(data);
    } catch (error) {
    } finally {
    }
  };

  const fetchSearchResults = async (
    search_text: string,
    category: "funds" | "agreements" | "cps" | "discussion_board",
    page: number,
  ) => {
    setLoading(true);
    setSearchResults([]);
    const params = {
      search_text,
      page
    };
    params[category] = true;
    try {
      const response = await axiosInstance.get(
        ApiRoutes.GLOBAL_SEARCH_RESULTS,
        {
          params,
        }
      );
      let data;

      switch (category) {
        case "funds":
          data = deserialize(Fund, response.data["results"] as Fund[]);
          break;
        case "agreements":
          data = deserialize(
            Agreement,
            response.data["results"] as Agreement[]
          );
          break;
        case "cps":
          data = deserialize(
            CounterParties,
            response.data["results"] as CounterParties[]
          );
          break;
        case "discussion_board":
          data = deserialize(Topic, response.data["results"] as Topic[]);
          break;
        default:
          break;
      }
      setSearchPagination(deserialize(Pagination, response.data["meta"]))
      setSearchResults(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getKeyTerm = async (search: string) => {
    try {
      const { data } = await axiosInstance.get(ApiRoutes.GLOBAL_SEARCH_KEY_TERMS, {
        params: {
          search
        }
      });
      const keyTerms = deserialize(KeyTerm, data?.key_terms as any[]);
      setKeyTerms(keyTerms);
      return keyTerms;
    } catch (error) {
      return false;
    }
  }

  const getDocuments = async (search: string, page: number) => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get(ApiRoutes.GLOBAL_SEARCH_DOCUMENTS, {
        params: {
          search,
          page,
          per_page: 20
        }
      });
      const documents = deserialize(Document, data?.documents as any[]);
      const pagination = deserialize(Pagination, data?.meta);
      setDocuments(documents);
      setDocumentPagination(pagination)
      return { documents, pagination };
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    searchTerms,
    searchResults,
    fetchSearchResults,
    fetchRecentSearches,
    keyTerms,
    getKeyTerm,
    documents,
    getDocuments,
    searchPagination,
    documentPagination,
  };
};

export default GlobalSearchService;
