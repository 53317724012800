import { Button, Col, Divider, Row, Switch } from "antd";
import React, { FC, useEffect, useState } from "react";
import { CompanyModel, ProfileModel } from "../../../models/HFund/hfund.model";
import { CompanyService } from "../../../services/CompanyService/company.service";
import BackgroundImage from "../../../assets/background-images/auth-bg.png";
import AppLogo from "../../../assets/background-images/app-logo.png";
import "./accountDetails.scss";
import AppModal from "../../../shared/components/AppModal";
import CompanyDetails from "../../Auth/CompanyDetails";
import Text from "../../../shared/utils/text";
import { generatePath, useHistory, useParams } from "react-router";
import { SUBSCRIPTION } from "../../../routes/appRoutes";
import AuthContainer from "../../../store/containers/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import Loader from "../../../shared/components/Loader";
import { LocalStorage } from "../../../shared/utils/localStorageHelpers";
import { assets } from "../../Auth/CompanyDetails/companyDefinitions";
import MultiFactorAuthentication from "../../Auth/MultiFactorAuthentication";
import MfaBackUpCode from "../../Auth/MfaBackUpCode";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";

interface AccountDetailsProps extends AuthReducerProps { }

const AccountDetails: FC<AccountDetailsProps> = (props) => {
  const { user, setUser, userType } = props;
  const { id: companyId } = useParams<{ id: string }>()
  const history = useHistory();
  const [company, setCompany] = useState<CompanyModel>();

  useEffect(() => {
    if (user?.companyId || companyId) {
      fetchCompany()
    }
  }, []);

  const fetchCompany = () => {
    const id = user?.companyId || companyId
    if (id) {
      CompanyService.viewCompany(
        id,
        user.role as any,
        (company: CompanyModel) => {
          setCompany(company);
        },
        () => { }
      );
    }
  }

  const handleUpgradeSubscription = () => {
    if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == companyId)?.length == 0)) {
      Notification({
        message: "Hedge Legal",
        description: "Access Restricted",
        type: NotificationTypes.ERROR,
      });
    } else {
      if (company?.companyId) {
        const URL = `${generatePath(SUBSCRIPTION, {
          companyId: company?.companyId ?? "",
        })}?subscriptionId=${company.subscriptionPlanId}`;
        history.push(URL);
      }
    }
  };

  const setUpAuthenticator = () => { }
  if (!company && user.role.includes('hf'))
    return <Loader></Loader>

  return (
    <div className="account-details">
      {company && <div className="header">
        <img src={BackgroundImage} alt="" />
        <div className="details">
          <Row>
            <Col className="user-logo">
              <img src={company?.logoUrl ?? BackgroundImage} alt="" />
            </Col>
            <Col className="ml-15 user-detail">
              <h1>
                {" "}
                {company?.user?.firstName + " " + company?.user?.lastName}
                {/* <AppModal
                  title="Edit Profile Details"
                  renderComponent={(closeModal) => (
                    <UserForm user={company?.admin} closeHandler={closeModal} />
                  )}
                  triggerComponent={(openModal) => (
                    <i className="icon-edit" onClick={openModal} />
                  )}
                /> */}
              </h1>
              <p className="text-grey">{user.companyRoleName}</p>
              <Row justify="space-between" className="mt-5">
                <Col span={8} className="align-center">
                  <p>
                    {" "}
                    <i className="icon-email" /> &nbsp;{company?.user?.email}
                  </p>
                </Col>
                <Col span={8} className="align-center">
                  <p>
                    {" "}
                    <i className="icon-phone" />
                    &nbsp;
                    {company?.user?.mobileNumber}
                  </p>
                </Col>
                <Col span={8} />
              </Row>
            </Col>
          </Row>
        </div>
      </div>}
      {company && <div className="company-detail__container">
        <div className="company-details">
          <Row>
            {/* <Col className="mr-15">
              <img
                className="company-logo"
                src={company?.logoUrl ?? BackgroundImage}
                key={company?.logoUrl ?? company?.companyName}
                alt=""
              />
            </Col> */}
            <Col className="ml-15">
              <p className="text-grey">Company Information</p>
              <h2>
                {company?.companyName}
                <AppModal
                  title="Edit company details"
                  triggerComponent={(openModal) => (
                    <i className="icon-edit" onClick={() => {
                      if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == companyId)?.length == 0)) {
                        Notification({
                          message: "Hedge Legal",
                          description: "Access Restricted",
                          type: NotificationTypes.ERROR,
                        });
                      } else {
                        openModal()
                      }
                    }} />
                  )}
                  renderComponent={(closeModal) => (
                    <CompanyDetails
                      companyId={parseInt(company?.companyId)}
                      company={company}
                      closeHandler={(company: CompanyModel) => {
                        if (company) {
                          setCompany(company)
                          if (company.admin && user.id === company.adminId) {
                            const user = LocalStorage.getItem("USER")
                            company.admin.otpVerified = user.otpVerified
                            setUser(company.admin)
                            LocalStorage.setItem("USER", company.admin)
                          }
                        }
                        closeModal();
                      }}
                    />
                  )}
                />
              </h2>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col span={8}>
              <p className="text-grey">Assets Under Management</p>
              <h3>{assets.find(asset => asset.value === company?.assetUnderManagement)?.label}</h3>
            </Col>
            <Col span={8}>
              <p className="text-grey">Strategy</p>
              <h3>{Text.snakeToCamel(company?.strategy)}</h3>
            </Col>
            <Col span={8}>
              <p className="text-grey">Additional Strategies</p>
              <h3>{company?.additionalStrategies.join(" ")?.replaceAll("_", " ")}</h3>
            </Col>
          </Row>
          <Divider />
          <Row>
            {company?.addresses.filter((address) => address.addressType == "registered").map((address, index) => (
              <Col span={8} key={index}>
                <p className="text-grey">
                  {Text.capitalize(address?.addressType)} Address
                </p>
                <p className="address">
                  {" "}
                  <i className="icon-address"></i>{" "}
                  {`${address?.street} ${address?.addressLine2} ${address?.cityName} ${address?.stateName} ${address?.zipCode}`}
                </p>
              </Col>
            ))}
          </Row>
        </div>
        <Row className="subscription">
          <Col span={6}>
            <p className="title text-grey">Subscription</p>
            <h3 className="value">{company?.subscriptionPlan?.name}</h3>
          </Col>
          <Col span={6}>
            {/* <p className="title text-grey">Next Renewal</p>
            <h3 className="value">Nil</h3> */}
          </Col>
          <Col span={6}>
            {/* <p className="title text-grey">Payment Method</p>
            <h3 className="value">Stripe</h3> */}
          </Col>
          <Col span={6} className="upgrade-container">
            <Button type="primary" onClick={handleUpgradeSubscription}>
              Upgrade Subscription Package
            </Button>
          </Col>
        </Row>
      </div>}
      {!companyId && <div className="company-detail__container mfa-setup">
        <div className="company-details">
          <Row>
            <h3>Multi factor Authentication</h3>
          </Row>
          <Row>
            <span>
              Use time-based OTPs generated by google authenticator app to
              securely sign in to your account
            </span>
          </Row>
          <Row className="mt-4">
            <AppModal
              title="Multi factor Authentication"
              triggerComponent={(enabled_disable_mfa) => (
                <span className={"toggle-mfa " + (user?.otpModule == "enabled" ? "green_color" : "light_grey")}>
                  <Switch
                    checked={user?.otpModule == "enabled"}
                    defaultChecked={user?.otpModule == "enabled"}
                    size={"small"}
                    onChange={() => {
                      enabled_disable_mfa()
                    }}
                  />
                  <span className={"status-label " + (user?.otpModule == "enabled" ? "dark_green" : "grey_color")}>
                    {user?.otpModule == "enabled" ? 'Enabled' : "Disabled"}
                  </span>
                </span>
              )}
              renderComponent={(closeModal) => (
                <MultiFactorAuthentication
                  user={user}
                  setUser={setUser}
                  toggleMfa={true}
                  loggedIn={true}
                  closeModalHandler={closeModal}
                  userType={userType}
                />
              )}
            />

            <AppModal
              title="Multi factor Authentication"
              triggerComponent={(setUpAuthenticator) => (
                <Button type="primary" onClick={setUpAuthenticator} className="set-up-mfa">
                  {user?.otpModule == "enabled" ? 'Change Configuration' : 'Setup Now'}
                </Button>
              )}
              renderComponent={(closeModal) => (
                <MultiFactorAuthentication
                  setUser={setUser}
                  user={user}
                  toggleMfa={false}
                  loggedIn={true}
                  closeModalHandler={closeModal}
                  userType={userType}
                />
              )}
            />
          </Row>
        </div>
      </div>}

      {!companyId && (user.otpModule == "enabled") && <div className="company-detail__container mfa-setup">
        <div className="company-details">
          <Row>
            <h3>Backup verification code</h3>
          </Row>
          <Row>
            <span>MFA recovery options will help you restore your account if you lose your device</span>
          </Row>
          <Row className="mt-4">

            <AppModal
              title="Backup verification codes"
              triggerComponent={(setUpAuthenticator) => (
                <Button type="primary" onClick={setUpAuthenticator}>
                  Generate & Download codes
                </Button>
              )}
              renderComponent={(closeModal) => (
                <MfaBackUpCode
                  user={user}
                />
              )}
            />
          </Row>
        </div>
      </div>}
    </div>
  );
};

export default AuthContainer(AccountDetails);
