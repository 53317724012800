import { serializable, alias, object, list, primitive } from "serializr";

export class Document {
  @serializable(alias("document_id", primitive()))
  agreementId?: string;

  @serializable(alias("document_name", primitive()))
  agreementName?: string;

  @serializable(alias("document_type", primitive()))
  agreementType?: string;

  @serializable(alias("ancillary_document_id", primitive()))
  ancillaryDocumentId?: string;

  @serializable(alias("fund_id", primitive()))
  fundId?: string;

  @serializable(alias("fund_name", primitive()))
  fundName?: string;

  @serializable(alias("counter_party_id", primitive()))
  counterPartyId?: string;

  @serializable(alias("counter_party_name", primitive()))
  counterPartyName?: string;

  @serializable(alias("document_created_at", primitive()))
  agreementCreatedAt?: string;

  @serializable(alias("document_model", primitive()))
  agreementModel?: string;
}
