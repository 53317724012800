import React, { FC } from "react";
import { useLocation } from "react-router";
import SandClockIcon from "../../../assets/icons/sand-clock.svg";
import { ProfileModel } from "../../../models/HFund/hfund.model";
import "./profileVerification.scss"
const ProfileVerification: FC = () => {
  const { state }: { state: ProfileModel } = useLocation()
  return (
    <div className="profile-verification__container">
      <h1>Welcome to Trading Dox</h1>
      <h2>{state?.firstName}{" "}{state?.lastName}</h2>
      <h2 className="profile-verification__company">{state?.companyName}</h2>
      <img src={SandClockIcon} />
      <p className="profile-verification__info">
        Your Profile is created and has been sent to<br /> HedgeLegal team for verification.
      </p>
      <p>You will receive an email after your profile has been activated</p>
    </div>
  );
};

export default ProfileVerification;
