import React from "react";
import { Field, ErrorMessage } from "formik";
import { Select } from 'antd';
import Error from "../Error";
import './dropdownField.scss';

interface DropdownFieldProps {
    name: string;
    placeholder?: string;
    mode?: "single" | "tags" | "multiple";
    options: any;
    showSearch?: boolean;
    value?: any;
    onChange?: Function;
    optionFilterProp?: string;
    defaultValue?: any;
    className?: string;
    disabled?: boolean
    showLabel?: boolean
    setFieldTouched?: Function
    onSelect?: Function
}

const DropdownField: React.FC<DropdownFieldProps> = (props) => {
    const { name, setFieldTouched = () => { }, showLabel = true } = props;

    return (
        <div className='dropdown-field' onClick={() => { setFieldTouched() }}>
            {props?.placeholder && showLabel && props?.value !== undefined &&
                <label htmlFor={props?.name} className="field-label">
                    {props?.placeholder}
                </label>
            }
            <Field as={Select} {...props}>
            </Field>
            <ErrorMessage name={name}>
                {(message: string) => <Error message={message} />}
            </ErrorMessage>
        </div>
    )
}

export default DropdownField;
