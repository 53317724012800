export const productsAvailable = [
    {label: "Prime Broker Equity", value: "prime_broker_equity"},
    {label: "Prime Broker Fixed Income", value: "prime_broker_fixed_income"},
    {label: "Derivatives Intermediation", value: "derivatives_intermediation"},
    {label: "Futures Clearer", value: "futures_clearer"},
    {label: "OTC Clearer", value: "otc_clearer"},
    {label: "Swap Dealer", value: "swap_dealer"},
    {label: "Repo", value: "repo"},
    {label: "Custodian", value: "custodian"},
    {label: "Depositary", value: "depositary"},
    {label: "Execution", value: "execution"},
]
export const detailedServices = [
    {label: "Cash Equity PB NA", value: "cash_equity_pb_na"},
    {label: "Cash Equity PB EMEA", value: "cash_equity_pb_emea"},
    {label: "Cash Equity PB EM", value: "cash_equity_pb_em"},
    {label: "Cash Equity Execution NA", value: "cash_equity_execution_na"},
    {label: "Cash Equity Execution EMEA", value: "cash_equity_execution_emea"},
    {label: "Cash Equity Execution EM", value: "cash_equity_execution_em"},
    {label: "Equity Synthetics NA", value: "equity_synthetics_na"},
    {label: "Equity Synthetics EMEA", value: "equity_synthetics_emea"},
    {label: "Equity Synthetics EM", value: "equity_synthetics_em"},
    {label: "Cross Margining", value: "cross_margining"},
    {label: "Arranged Financing", value: "arranged_financing"},
    {label: "Fixed Income Prime Brokerage", value: "fixed_income_prime_brokerage"},
]