import { serializable, alias, object, list, primitive } from "serializr";
import Rating from "../Rating/rating.model";
export class SendInvitation {
  @serializable(alias("firstName", primitive()))
  firstName?: string;

  @serializable(alias("email", primitive()))
  email?: string;
}

export class SuggestedBy {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("assets_under_management", primitive()))
  assetsUnderManagement?: string;

  @serializable(alias("strategy", primitive()))
  strategy?: string;

  @serializable(alias("registered_city_name", primitive()))
  registeredCityName?: string;
}

export class Address {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("addressable_type", primitive()))
  addressableType?: string;

  @serializable(alias("addressable_id", primitive()))
  addressableId?: number;

  @serializable(alias("address_type", primitive()))
  addressType?: string;

  @serializable(alias("street", primitive()))
  street?: string;

  @serializable(alias("address_line_2", primitive()))
  addressLine2?: string;

  @serializable(alias("city_id", primitive()))
  cityId?: number;

  @serializable(alias("city_name", primitive()))
  cityName?: string;

  @serializable(alias("zipcode", primitive()))
  zipcode?: string;

  @serializable(alias("province_id", primitive()))
  provinceId?: number;

  @serializable(alias("province_name", primitive()))
  provinceName?: string;

  @serializable(alias("country_id", primitive()))
  countryId?: number;

  @serializable(alias("country_name", primitive()))
  countryName?: string;
}
export class CounterParties {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("logo_url", primitive()))
  logoUrl?: string;

  @serializable(alias("products_available", list(primitive())))
  productsAvailable?: string[];

  @serializable(alias("detailed_service_offerings", list(primitive())))
  detailedServiceOfferings?: string[];

  @serializable(alias("address", object(Address)))
  address?: Address;

  @serializable(alias("review_count", primitive()))
  reviewCount?: number;

  @serializable(alias("rating_count", primitive()))
  ratingCount?: number;

  @serializable(alias("total_rating", primitive()))
  totalRating?: number;

  @serializable(alias("suggested_by", object(SuggestedBy)))
  suggestedBy?: SuggestedBy;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("cp_group_id", primitive()))
  cpGroupId?: number;

  @serializable(alias("cp_group_name", primitive()))
  cpGroupName?: string;

  @serializable(alias("cp_group_logo_url", primitive()))
  cpGroupLogoUrl?: string;

  @serializable(alias("contact_person_name", primitive()))
  contactPersonName?: string;

  @serializable(alias("contact_person_title", primitive()))
  contactPersonTitle?: string;

  @serializable(alias("contact_person_phone", primitive()))
  contactPersonPhone?: string;

  @serializable(alias("contact_person_email", primitive()))
  contactPersonEmail?: string;

  @serializable(alias("ratings", list(object(Rating))))
  ratings?: Rating[];

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("no_of_agreements", primitive()))
  noOfAgreements?: number;
  

}
