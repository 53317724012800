import { Popover } from "antd";
import React from "react";
import "./popover.scss";

interface PopoverProps {
  title?: string;
  showTitleButton?: boolean;
  titleButton?: React.ReactNode;
  className?: string;
  content: string | React.ReactNode;
  children: any;
  trigger?: string | string[];
  showHeader?: boolean;
  visible?: boolean;
}
const PopoverComponent = (props: PopoverProps) => {
  const {
    title,
    content,
    showTitleButton,
    titleButton,
    className,
    showHeader = true,
    trigger = "click",
    visible
  } = props;
  return (
    <>
      <Popover
      visible={visible}
        overlayClassName={className}
        title={
          showHeader && (
            <div className="popover-component-title">
              <p>{title}</p>
              {showTitleButton && titleButton}
            </div>
          )
        }
        trigger={trigger}
        content={content}
        placement={"bottom"}
      >
        {props.children}
      </Popover>
    </>
  );
};

export default PopoverComponent;
