import React, { FC, useEffect, useState } from "react";
import "./cpInfo.scss";
import faker, { fake } from "faker";
import MarkerIcon from "../../../../../../assets/icons/marker.svg";
import EditIcon from "../../../../../../assets/icons/editWhite.svg";
import MailWhiteIcon from "../../../../../../assets/icons/mailWhite.svg";
import StarIcon from "../../../../../../assets/icons/star.svg";
import PhoneIcon from "../../../../../../assets/icons/phone.svg";
import MailIcon from "../../../../../../assets/icons/mail.svg";
import {
  Col,
  Divider,
  Dropdown,
  Menu,
  Rate,
  Row,
  Skeleton,
  Switch,
} from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import { DotSeparator } from "../../../../../../shared/components/DotSeparator";
import { MediaComponent } from "../../../../../../shared/components/MediaComponent";
import { generatePath, useHistory, useLocation, withRouter } from "react-router-dom";
import { CP_GROUP } from "../../../../../../routes/appRoutes";
import { CounterParties } from "../../../../../../models/CounterParties/counterParties.model";
import { HLCounterPartiesService } from "../../../../../../services/CounterPartiesService/CounterParties.service";
import { Status } from "../../../../../../enums/counterParties";
import CPForm from "../../CPForm";
import moment from "moment";
import { NumberToFixed } from "../../../../../../shared/utils/NumberUtil";
import { assets, strategies } from "../../../../../Auth/CompanyDetails/companyDefinitions";
import { serialize } from "serializr";
import { convertJSONToFormData } from "../../../../../../shared/utils/dataFormatConverter";

interface HLCPInfoProps {
  setCounterPartyGroup: Function;
}

const HLCPInfo: FC<HLCPInfoProps> = (props: any) => {
  const { state }: { state: any } = useLocation();
  const history = useHistory();
  const { setCounterPartyGroup } = props;
  const urlParts = history.location.pathname.split("/");
  const id = urlParts[urlParts.length - 1];
  const [counterParty, setCounterParty] = useState<CounterParties>(null);
  const [showCPForm, setFormVisibility] = useState(false);
  const [showSkeletonLoading, setSkeletonLoading] = useState(true);
  const fetchCounterParty = () => {
    HLCounterPartiesService.showCounterParty(
      id,
      (response) => {
        setCounterPartyGroup(response?.name);
        setCounterParty(response);
        setSkeletonLoading(false);
      },
      () => { },
      () => { }
    );
  };
  useEffect(() => {
    fetchCounterParty();
  }, [id]);

  const updateCounterParty = (values: CounterParties) => {
    const jsonData = serialize(CounterParties, values);
    jsonData["logo"] = values["logo"];
    const formData = convertJSONToFormData({ counter_party: jsonData });
    HLCounterPartiesService.updateCounterParties(
      counterParty?.id,
      formData,
      (data) => {
        fetchCounterParty()
      },
      () => { },
      () => { }
    );
  }

  const disableCPHandler = (checked: boolean) => {
    updateCounterParty({ ...counterParty, status: checked ? Status.ENABLED : Status.DISABLED })
  }
  return (
    <div className="hlcp-info__container">
      <CPForm
        category={"cp"}
        visible={showCPForm}
        closeHandler={(res) => {
          res && fetchCounterParty()
          setFormVisibility(false);
        }}
        data={{ ...counterParty }}
      />
      <div className="cp-details__container">
        {showSkeletonLoading ? (
          <Skeleton active avatar={{ size: 100 }} paragraph={{ rows: 4 }} />
        ) : (
          <>
            <div className="cp-image__wrapper">
              <img src={counterParty?.logoUrl} />
            </div>
            <div className="cp-body__wrapper">
              <div className="cp-details__wrapper">
                <div className="cp-address__wrapper">
                  <p className="cp__name">{counterParty.name}</p>
                  <p className="cp__address">
                    <span>
                      <img src={MarkerIcon} />
                    </span>
                    {`${counterParty?.address?.street}, ${counterParty?.address?.addressLine2}, ${counterParty?.address?.cityName}, ${counterParty?.address?.provinceName}, ${counterParty?.address?.countryName}`}
                  </p>
                </div>
                <Divider type="vertical" />
                <div className="cp-products__wrapper">
                  <p>Products Available</p>
                  <p className="cp__product">
                    {counterParty?.productsAvailable?.join(", ")?.replaceAll("_", " ")}
                  </p>
                </div>
              </div>
              <p className="cp__desc">{counterParty?.description}</p>
            </div>
            <Dropdown
              trigger={["click"]}
              placement={"topRight"}
              overlay={
                <Menu className="cp__menu">
                  <Menu.Item
                    onClick={() => {
                      window.open(`mailto:${counterParty.contactPersonEmail ?? ''}?subject=Invitation from Tradingdox`);
                    }}
                  >
                    <p>
                      <span>
                        <img src={MailWhiteIcon} />
                      </span>
                      Send Invitation
                    </p>
                  </Menu.Item>
                  <Menu.Item onClick={() => setFormVisibility(true)}>
                    <p>
                      <span>
                        <img src={EditIcon} />
                      </span>
                      Edit
                    </p>
                  </Menu.Item>
                  <Menu.Item>
                    <p>
                      <span>
                        <Switch
                          disabled={
                            counterParty?.status === Status.DISABLED
                          }
                          checked={
                            counterParty?.status === Status.ENABLED
                          }
                          size={"small"}
                          onChange={disableCPHandler}
                        />
                      </span>
                      <span className="text-capitalize">{
                        counterParty?.status === Status.ENABLED
                          ? "Enabled"
                          : "Disabled"
                      }</span>
                    </p>
                  </Menu.Item>
                </Menu>
              }
            >
              <span>
                <EllipsisOutlined rotate={90} />
              </span>
            </Dropdown>
          </>
        )}
      </div>
      <Row gutter={16} className="mtp-2">
        <Col span={15}>
          <div className="cp-reviews__container">
            {/* <div className="cp-reviews-header__container">
              <p className="cp__rating">
                {NumberToFixed(counterParty?.totalRating)}
                <span>
                  <img src={StarIcon} />
                </span>
              </p>
              <p className="cp-review-rating__count">
                {counterParty?.ratingCount} Ratings &{" "}
                {counterParty?.reviewCount} Reviews
              </p>
            </div> */}
            {/* <Divider /> */}
            {counterParty?.ratings?.map((rating) => {
              return (
                <div className="cp-reviews" key={rating?.id}>
                  <div className="cp-review-header__wrapper">
                    <div>
                      <p className="cp-desc">
                        <span>
                          {
                            strategies?.find(
                              (strategy) =>
                                strategy?.value === rating?.companyStrategy
                            )?.label
                          }
                        </span>
                        {assets.find(asset => asset.value === rating.companyAum) && <hr className="divider" />}
                        <span className="aum">
                          <span className="aum">{assets.find(asset => asset.value === rating.companyAum)?.label || ''}</span>
                        </span>
                      </p>
                      <p className="cp-name-time">
                        <img src={MarkerIcon} className="mr-5" />
                        {rating.city ? rating.city + " ," : ''}
                        {rating.province ?? ''}
                        <span className="mlp-5">
                          {moment(rating?.createdAt).format("DD MMM, YYYY")}
                        </span>
                      </p>
                    </div>

                    <div>
                      <Rate
                        onChange={() => { }}
                        disabled
                        value={rating?.rating}
                      />
                    </div>
                  </div>
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      expandable: true,
                      symbol: "Read More",
                    }}
                  >
                    {rating?.review}
                  </Paragraph>
                  <Divider />
                </div>
              );
            })}
          </div>
        </Col>
        <Col span={9}>
          <div
            className="cp-group__container"
            onClick={() =>
              history.push(generatePath(CP_GROUP, { cpGroupId: counterParty?.cpGroupId }), {
                id: counterParty?.cpGroupId,
                name: counterParty?.cpGroupName,
              })
            }
          >
            <div className="cp-group__details">
              {showSkeletonLoading ? (
                <Skeleton
                  active
                  avatar={{ size: 100 }}
                  paragraph={{ rows: 2 }}
                />
              ) : (
                <>
                  <img
                    src={counterParty?.cpGroupLogoUrl}
                  />
                  <div className="cp-group__wrapper">
                    <p>CP Group</p>
                    <p className="cp-group__name">
                      {counterParty?.cpGroupName}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="cp-contact__container">
              {showSkeletonLoading ? (
                <Skeleton
                  active
                  avatar={{ size: 100 }}
                  paragraph={{ rows: 3 }}
                />
              ) : (
                <>
                  <p className="contact__title">Contact Person Details</p>
                  <p className="contact__name">
                    {counterParty?.contactPersonName}
                    <DotSeparator />
                    <span>{counterParty?.contactPersonTitle}</span>
                  </p>
                  <p className="contact__phone">
                    <span>
                      <img src={PhoneIcon} />
                    </span>
                    {counterParty?.contactPersonPhone}
                  </p>
                  <p className="contact__email">
                    <span>
                      <img src={MailIcon} />
                    </span>
                    {counterParty?.contactPersonEmail}
                  </p>
                </>
              )}
            </div>
            {/* <div className="cp-docs__container">
              <p>Documents</p>
              <div className="cp-docs__wrapper">
                <MediaComponent
                  title={}
                  thumbnail={}
                  contentType="pdf"
                /> 
              </div>
            </div> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default HLCPInfo;
