import { CreateReducer } from "../../shared/utils/createReducer";
import { ReactElement } from "react";
import {
  SET_DOX_COINS,
  SET_HEADER_VALUE,
} from "../definitions/layoutConstants";
import { ActionProps } from "../../shared/types/action.type";

export interface LayoutProps {
  title?: string;
  component?: ReactElement;
  doxCoins?: number;
}

const initState: LayoutProps = {
  title: "",
  component: undefined,
  doxCoins: 0,
};

export interface LayoutActions extends ActionProps {
  payload: LayoutProps;
}

export interface LayoutReducerProps extends LayoutProps {
  setHeader: (title?: string, component?: ReactElement) => void;
  setDoxCoin: (doxCoins: number) => void;
}

const LayoutReducer = CreateReducer(initState, {
  [SET_HEADER_VALUE](state: LayoutProps, action: LayoutActions) {
    const { title, component } = action.payload;
    return {
      ...state,
      title,
      component,
    };
  },
  [SET_DOX_COINS](state: LayoutProps, action: LayoutActions) {
    const { doxCoins } = action.payload;
    return {
      ...state,
      doxCoins,
    };
  },
});

export default LayoutReducer;
