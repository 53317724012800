import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { date, deserialize } from "serializr";
import { AgreementTypes } from "../../../../../../models/Agreements/agreementTypes.model";
import { AgreementTypesMeta } from "../../../../../../models/Meta/meta.model";
import AgreementTypesService from "../../../../../../services/KeyTermsService/AgreementTypes.service";
import DropdownField from "../../../../../../shared/components/DropdownField";
import { UIModal } from "../../../../../../shared/components/UIModal";
import "./ktReplicateForm.scss";
interface KTReplicateFormProps {
  agreementTypes: any;
  visible: boolean;
  closeHandler: () => void;
}
export const KTReplicateForm: FC<KTReplicateFormProps> = ({
  agreementTypes,
  visible,
  closeHandler,
}) => {
  const [initialValues, setInitialValues] = useState({
    agreementType: undefined,
  });
  const [types, setTypes] = useState<any>();
  useEffect(() => {
    const data = deserialize(AgreementTypesMeta, agreementTypes);
    setTypes(data);
  }, [agreementTypes]);

  const onReplicate = (id) => {
    AgreementTypesService.replicateAgreementTypes(
      id,
      () => {
        closeHandler()
      },
      () => {},
      () => {}
    );
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      enableReinitialize
    >
      {({ setFieldValue, values, resetForm, isValid }) => {
        return (
          <UIModal
            visible={visible}
            closeModal={closeHandler}
            title={"Replicate"}
            submitProps={{
              text: "Replicate",
              clickHandler: () => onReplicate(values?.agreementType),
            }}
            cancelProps={{
              text: "Cancel",
              clickHandler: () => closeHandler(),
            }}
          >
            <Form>
              <div className="kt-replicate-form__container">
                <div className="kt-replicate-form__field">
                  <DropdownField
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"Choose Agreement Type"}
                    name="agreementType"
                    options={types}
                    showSearch={false}
                    value={values.agreementType}
                    onChange={(value) => setFieldValue("agreementType", value)}
                  />
                </div>
              </div>
            </Form>
          </UIModal>
        );
      }}
    </Formik>
  );
};
