import { LoadingOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Form, Row, Col } from "antd";
import { Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { FORGOT_PASSWORD } from "../../../routes/appRoutes";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import InputField from "../../../shared/components/InputField";
import "./forgotPasswordSuccess.scss"
import MailSent from "./../../../assets/icons/mail-unread.svg"

const ForgotPasswordSuccess = (props) => {

    const history = useHistory();
    const initialValues = {
        email: "",
        password: null,
    };
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <div className="forgot-password-success__container">
            <h1>Check your Email</h1>
            <p>Please check your inbox and click in the received link to reset a password</p>
            <img className="mail-sent" src={MailSent} alt="mail_sent" />
            <p>Didn’t received the link?
                <span className="text-link" onClick={() => {
                    history.goBack()
                }}>Resend</span>
            </p>
        </div>
    )
}

export default ForgotPasswordSuccess