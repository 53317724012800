import {
  AUTHENTICATED, UNAUTHENTICATED, USER, USER_TYPE,
} from "../definitions/authConstants";
import { CreateReducer } from "../../shared/utils/createReducer";
import { ProfileModel } from "../../models/HFund/hfund.model";
import { LocalStorage } from "../../shared/utils/localStorageHelpers";

export interface IAuthState {
  authenticated: boolean;
  user: ProfileModel,
  userType: string;
}
const initState: IAuthState = {
  authenticated: LocalStorage.getItem("USER") ? true : false,
  user: LocalStorage.getItem("USER"),
  userType: LocalStorage.getItem("USER") ? (LocalStorage.getItem("USER")?.role?.includes("hl") ? "HL" : "HF") : null,
};
export interface AuthReducerProps extends IAuthState {
  setAuthenticated: (user: ProfileModel) => void;
  setUnAuthenticated: () => void;
  setUserType: (value: string) => void;
  setUser: (user: ProfileModel) => void;
}

const AuthReducer = CreateReducer(initState, {
  [AUTHENTICATED](state: any, action: any) {
    const { authenticated, user } = action.payload;
    return {
      ...state,
      authenticated,
      user
    };
  },
  [UNAUTHENTICATED](state: any, action: any) {
    const { authenticated } = action.payload;
    return {
      ...state,
      authenticated,
    };
  },
  [USER_TYPE](state: any, action: any) {
    const { userType } = action.payload;
    return {
      ...state,
      userType,
    };
  },
  [USER](state: any, action: any) {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
});

export default AuthReducer;
