import React, { FC, useEffect } from "react";
import { Notification as NotificationModel } from "../../../../../models/Notification/notification.model";
import NotificationService from "../../../../../services/NotificationService/notification.service";
import AppModal from "../../../../../shared/components/AppModal";
import { PageHeaderComponent } from "../../../../../shared/components/PageHeader";
import { TableComponent } from "../../../../../shared/components/TableComponent";
import "./notification.scss";
import NotificationForm from "./NotificationForm";

interface NotificationProps { }

const Notification: FC<NotificationProps> = (props) => {
  const { } = props;

  const { notifications, getNotifications, updateNotifications } = NotificationService();

  useEffect(() => {
    getNotifications();
  }, []);


  const notificationColumns = [
    {
      title: "ACTION", dataIndex: "action", key: "action", width: 800,
      render: (text: string) => text?.replaceAll("_", " "),
      className: "text-capitalize"
    },
    {
      title: "PERIOD",
      dataIndex: "period",
      key: "period",
      width: 100,
      render: (period) => `${period} days`,
    },
    {
      title: "",
      dataIndex: "date",
      key: "date",
      width: 100,
      render: (_, record: NotificationModel) => {
        return (
          <AppModal
            title={`Edit ${record?.action?.replaceAll("_", " ")} period`}
            triggerComponent={(openModal) => (
              <i className="icon-edit" onClick={openModal} />
            )}
            renderComponent={(closeModal) => (
              <NotificationForm notification={record} updateNotifications={updateNotifications} closeHandler={closeModal} />
            )}
          />
        );
      },
    },
  ];

  return (
    <div className="notification">
      <PageHeaderComponent
        title="Fund Level Default Notifications"
      />
      <div className="table__wrapper">
        <TableComponent
          showSearch={false}
          data={notifications}
          columns={notificationColumns}
        />
      </div>
    </div>
  );
};

export default Notification;
