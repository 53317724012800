import { Button, Col, message, Row } from "antd";
import React, { FC, useEffect } from "react";
import LinkIcon from "../../../../assets/icons/link.svg";
import DoxCoinIcon from "../../../../assets/icons/dox-coin.svg";
import LinkedInIcon from "../../../../assets/icons/linkedin.svg";
import TwitterIcon from "../../../../assets/icons/twitter.svg";
import "./doXDashboard.scss";
import { Company } from "../../../../models/Company/company.model";
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";
import { useLocation } from "react-router";
import { MinusOutlined } from "@ant-design/icons";
import AppModal from "../../../../shared/components/AppModal";
import DoXCoinUpdateForm from "./DoXCoinUpdateForm";
import DoxCoinsService from "../../../../services/DoxCoinsService/DoxCoins.service";
import LayoutContainer from "../../../../store/containers/layoutContainer";
import { LayoutReducerProps } from "../../../../store/reducers/layoutReducer";
import { LinkedinIcon, LinkedinShareButton, TwitterShareButton } from "react-share";
import { useHistory, useParams } from "react-router";
import Notification from "../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../enums/notificationTypes";

const content = `Trading Dox
Refer your friend and get 50 DoX coins
`;


const generateShareURL = (code: string) => `https://trading-dox.herokuapp.com?code=${code}`;
interface DoXDashboardProps extends LayoutReducerProps {
  company?: Company;
}

const DoXDashboard: FC<DoXDashboardProps> = (props) => {
  const { company, doxCoins } = props;
  const params = useParams<{ id: string }>();

  const user = LocalStorage.getItem("USER");

  const showOnlyHistory = useLocation()?.pathname?.split("/")?.length >= 3;

  // if(user?.role?.includes("hl")) {
  //   return null;
  // }

  const openLink = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div
      className={`do-x-dashboard ${user?.role?.includes("hf") && "content-center"
        }`}
    >
      {user?.role?.includes("hl") && !showOnlyHistory ? (
        <h1 className="ml-15">DoX Coin Management</h1>
      ) : (
        <Row justify="space-between" className="dashboard-wrapper">
          <Col>
            <img src={DoxCoinIcon} alt="" />
            <p className="coin">{company?.doxCoins ?? doxCoins}</p>
            <p className="content">DoX Coins</p>
          </Col>
          <Col>
            {showOnlyHistory ? (
              <Row>
                <Col span={12} className="full-width">
                  <AppModal
                    title="Add DoX Coins"
                    triggerComponent={(open) => (
                      <Button type="primary" onClick={() => {
                        if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == parseInt(params['id']))?.length == 0)) {
                          Notification({
                            message: "Hedge Legal",
                            description: "Access Restricted",
                            type: NotificationTypes.ERROR,
                          });
                        } else {
                          open()
                        }
                      }
                      }>
                        {" "}
                        <i className="icon-add" /> Add DoX Coins
                      </Button>
                    )}
                    renderComponent={(close) => (
                      <DoXCoinUpdateForm type="Add" closeHandler={close} />
                    )}
                  />
                </Col>
                <Col span={12} className="full-width">
                  <AppModal
                    title="Reduce DoX Coins"
                    triggerComponent={(open) => (
                      <Button type="primary" onClick={() => {
                        if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == parseInt(params['id']))?.length == 0)) {
                          Notification({
                            message: "Hedge Legal",
                            description: "Access Restricted",
                            type: NotificationTypes.ERROR,
                          });
                        } else {
                          open()
                        }
                      }}>
                        <MinusOutlined /> Reduce DoX Coins
                      </Button>
                    )}
                    renderComponent={(close) => (
                      <DoXCoinUpdateForm type="Reduce" closeHandler={close} />
                    )}
                  />
                </Col>
              </Row>
            ) : (
              <Row align="middle" className="full-height">
                <Col span={24}>
                  <p>Refer TradingDox</p>
                  <div className="mtp-5">
                    <LinkedinShareButton url={generateShareURL(user?.referralCode)}>
                      <img
                        src={LinkedInIcon}
                        alt=""
                        className="mr-15 cursor-pointer"
                      />
                    </LinkedinShareButton>
                    <TwitterShareButton url={generateShareURL(user?.referralCode)} title={content} hashtags={["trading-dox", "dox-coins"]}>
                      <img
                        src={TwitterIcon}
                        alt=""
                        className="mr-15 cursor-pointer"
                      />
                    </TwitterShareButton>
                    <img
                      src={LinkIcon}
                      alt=""
                      className="mr-15 cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(user?.referralCode);
                        message.success("Referral code copied to clipboard");
                      }}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default LayoutContainer(DoXDashboard);
