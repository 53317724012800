import React, { FC } from "react";
import "./reminderNotification.scss";

interface ReminderNotificationProps {

}

const ReminderNotification: FC<ReminderNotificationProps> = (props) => {

    const {  } = props;

    return <div className="reminder-notification">

    </div>
}

export default ReminderNotification;