import { AgreementKeyTermStatus } from './../../enums/agreementKeyTermStatus';
import {
  serializable,
  alias,
  object,
  list,
  primitive,
  custom,
} from "serializr";

export class AgreementStatus {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("progress_percent", primitive()))
  progress?: string;

  @serializable(alias("color", primitive()))
  color?: string;
}

export class AncillaryDocuments {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("agreement_id", primitive()))
  agreementId?: number;

  @serializable(
    alias(
      "document",
      custom(
        (file) => file,
        (json) => { }
      )
    )
  )
  document?: string;

  @serializable(alias("document_type", primitive()))
  documentType?: string;

  @serializable(alias("document_url", primitive()))
  documentUrl?: string;

  @serializable(alias("ancillary_document_status_id", primitive()))
  ancillaryStatusId?: number;

  @serializable(alias("ancillary_document_status_name", primitive()))
  ancillaryStatusName?: string;

  @serializable(
    alias("ancillary_document_status_progress_percent", primitive())
  )
  ancillaryStatusProgress?: number;

  @serializable(alias("sitting_with", primitive()))
  sittingWith?: string;
}

export class Amendment {

  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("agreement_id", primitive()))
  agreementId?: number;

  @serializable(alias("document_url", primitive()))
  documentUrl?: string;

  @serializable(alias("document",
    custom(
      (file) => file,
      (json) => json
    )
  )
  )
  document?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("date", primitive()))
  date?: string;

  @serializable(alias("deleted_at", primitive()))
  deletedAt?: string;

  @serializable(alias("is_fully_executed", primitive()))
  isFullyExecuted?: boolean;

  @serializable(alias("is_original_version", primitive()))
  isOriginal?: boolean;

}

export class RelatedAgreements {

  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("agreement_id", primitive()))
  agreementId?: number;

  @serializable(alias("agreement_id", primitive()))
  key?: number;

  @serializable(alias("agreement_name", primitive()))
  agreementName?: string;

  @serializable(alias("agreement_type_id", primitive()))
  agreementTypeId?: number;

  @serializable(alias("agreement_type_name", primitive()))
  agreementTypeName?: string;

  @serializable(alias("document_url", primitive()))
  documentUrl?: string;

  @serializable(alias("fund_id", primitive()))
  fundId?: number;

  @serializable(alias("fund_name", primitive()))
  fundName?: number;

  @serializable(alias("counter_party_id", primitive()))
  counterPartyId?: number;

  @serializable(alias("counter_party_name", primitive()))
  counterPartyName?: string;

  @serializable(alias("agreement_date", primitive()))
  agreementDate?: number;

}

export class AgreementType {

  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("count", primitive()))
  count?: string;

  @serializable(alias("color", primitive()))
  color?: string;
}

export class Agreement {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("fund_id", primitive()))
  fundId?: number;

  @serializable(alias("fund_name", primitive()))
  fundName?: string;

  @serializable(alias("company_id", primitive()))
  companyId?: number;

  @serializable(alias("company_name", primitive()))
  companyNameInReports?: number;

  @serializable(alias("hfund_company", primitive()))
  companyName?: string;

  @serializable(alias("counter_party_id", primitive()))
  cpId?: number;

  @serializable(alias("counter_party_name", primitive()))
  cpName?: string;

  @serializable(alias("cp_name", primitive()))
  cpNameReports?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("agreement_type_id", primitive()))
  agreementTypeId?: string;

  @serializable(alias("agreement_type", object(AgreementType)))
  agreementType?: AgreementType;

  @serializable(alias("agreement_type_name", primitive()))
  agreementTypeName?: string;

  @serializable(alias("date", primitive()))
  date?: string;

  @serializable(alias("tier", primitive()))
  tier?: string;

  @serializable(alias("agreement_status_id", primitive()))
  statusId?: string;

  @serializable(alias("agreement_status_name", primitive()))
  statusName?: string;

  @serializable(alias("agreement_status_progress_percent", primitive()))
  statusProgress?: number;

  @serializable(alias("sitting_with", primitive()))
  sittingWith?: string;

  @serializable(alias("responsible_user_id", primitive()))
  responsibleUserId?: string;

  @serializable(alias("responsible_user_first_name", primitive()))
  responsibleFirstName?: string;

  @serializable(alias("responsible_user_last_name", primitive()))
  responsibleLastName?: string;

  @serializable(alias("document", primitive()))
  document?: string;

  @serializable(alias("document_url", primitive()))
  documentUrl?: string;

  @serializable(alias("scorecard", primitive()))
  scorecard?: string;

  @serializable(alias("scorecard_url", primitive()))
  scorecardUrl?: string;

  @serializable(alias("html_document", primitive()))
  htmlDocument?: string;

  @serializable(alias("is_fully_executed", primitive()))
  isFullyExecuted?: boolean;

  @serializable(alias("key_terms", primitive()))
  keyTermStatus?: AgreementKeyTermStatus;

  @serializable(alias("is_completed", primitive()))
  isCompleted?: boolean;
}
