import React, { FC, useEffect, useState } from "react";
import { TableComponent } from "../../../../../../shared/components/TableComponent";
import faker from "faker";
import "./onboardingTemplate.scss";
import { Dropdown, Menu, Switch } from "antd";
import CaretUpIcon from "../../../../../../assets/icons/caretUp.svg";
import CaretDownIcon from "../../../../../../assets/icons/caretDown.svg";
import DoubleCaretUpIcon from "../../../../../../assets/icons/doubleCaretUp.svg";
import DoubleCaretDownIcon from "../../../../../../assets/icons/doubleCaretDown.svg";
import RemoveIcon from "../../../../../../assets/icons/closeCircle.svg";
import { EllipsisOutlined } from "@ant-design/icons";
import { OnboardingItemForm } from "./OnboardingItemForm";
import { OnboardingTemplates } from "../../../../../../models/OnboardingConfig/onboardingTemplates.model";
import OnboardingTemplatesService from "../../../../../../services/OnboardingConfigService/OnboardingTemplates.service";
import { Category } from "../../../../../../enums/onboardingItems";
import { OrderEnum } from "../../../../../../enums/orderEnum";
export const OnboardingTemplate: FC = () => {
  const [formType, setFormType] = useState<string>();
  const [showForm, setFormVisibility] = useState(false);
  const [generalItems, setGeneralItems] = useState<OnboardingTemplates[]>([]);
  const [ISDAProtocols, setISDAProtocols] = useState<OnboardingTemplates[]>([]);
  const [currentItem, setCurrentItem] = useState<OnboardingTemplates>();
  const fetchOnboardingTemplates = () => {
    OnboardingTemplatesService.fetchOnboardingTemplates(
      (response: OnboardingTemplates[]) => {
        setGeneralItems(
          response
            .filter(
              (data) => data.onboardingItemMetum.category == Category.GENERAL
            )
            ?.sort((a, b) => a.order - b.order)
        );
        setISDAProtocols(
          response
            .filter(
              (data) => data.onboardingItemMetum.category == Category.ISDA
            )
            ?.sort((a, b) => a.order - b.order)
        );
      },
      () => { },
      () => { }
    );
  };
  useEffect(() => {
    fetchOnboardingTemplates();
  }, []);

  // const updateOnboardingTemplate = (
  //   position: "up" | "top" | "down" | "bottom",
  //   selectedItem: OnboardingTemplates,
  //   index: number,
  //   items: OnboardingTemplates[]
  // ) => {
  //   const item = items.splice(index, 1)[0]
  //   if (position === "top")
  //     items.unshift(item)
  //   else if (position === "bottom")
  //     items.push(item)
  //   else if (position === "down")
  //     items.splice(index + 1, 0, item)
  //   else
  //     items.splice(index - 1, 0, item)
  //   items.forEach((item, i) => {
  //     updateOrderedData(i + 1, item.id)
  //   })
  // }

  // const updateOnboardingTemplate = (
  //   order: OrderEnum,
  //   id: number
  // ) => {
  //   updateOrderedData(order, id)
  // }

  const updateOrderedData = (order: OrderEnum, id) => {
    OnboardingTemplatesService.updateOnboardingTemplates(
      { order, id } as any,
      (response) => {
        fetchOnboardingTemplates();
      },
      () => { },
      () => { }
    );
  };
  const deleteOnboardingTemplate = (id) => {
    OnboardingTemplatesService.deleteOnboardingTemplates(
      id,
      (response) => {
        fetchOnboardingTemplates();
      },
      () => { },
      () => { }
    );
  };
  return (
    <div className="onboarding-template__container">
      <OnboardingItemForm
        data={formType == Category.GENERAL ? generalItems : ISDAProtocols}
        type={formType}
        visible={showForm}
        closeHandler={() => {
          fetchOnboardingTemplates();
          setFormVisibility(false);
        }}
      />
      <TableComponent
        title="General"
        showAdd
        onRow={(index) => setCurrentItem(generalItems[index])}
        onAdd={() => {
          setFormType(Category.GENERAL);
          setFormVisibility(true);
        }}
        showSearch={false}
        columns={[
          {
            title: "ORDER",
            dataIndex: "order",
            key: "order",
            render: (_, __, i) => <p>{(i || 0) + 1}</p>,
          },
          {
            title: "ONBOARDING ITEMS",
            dataIndex: "onboardingItemMetum",
            key: "onboardingItemMetum",
            render: (item) => <p>{item?.name}</p>,
          },
          {
            title: "",
            key: "action",
            render: (text, record, i) => (
              <Dropdown
                className="onboarding__menu"
                trigger={["click"]}
                overlay={
                  <Menu className="cp__menu">
                    <Menu.Item
                      onClick={() => {
                        updateOrderedData(
                          OrderEnum.MOVE_UP,
                          record.id
                        );
                      }}
                    >
                      <p>
                        <span>
                          <img src={CaretUpIcon} />
                        </span>
                        Move Up
                      </p>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        updateOrderedData(
                          OrderEnum.MOVE_TOP,
                          record.id,
                        );
                      }}
                    >
                      <p>
                        <span>
                          <img src={DoubleCaretUpIcon} />
                        </span>
                        Move to Top
                      </p>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        updateOrderedData(
                          OrderEnum.MOVE_DOWN,
                          record.id,
                        );
                      }}
                    >
                      <p>
                        <span>
                          <img src={CaretDownIcon} />
                        </span>
                        Move Down
                      </p>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        updateOrderedData(
                          OrderEnum.MOVE_BOTTOM,
                          record.id,
                        );
                      }}
                    >
                      <p>
                        <span>
                          <img src={DoubleCaretDownIcon} />
                        </span>
                        Move to Bottom
                      </p>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        deleteOnboardingTemplate(currentItem?.id);
                      }}
                    >
                      <p>
                        <span>
                          <img src={RemoveIcon} />
                        </span>
                        Remove
                      </p>
                    </Menu.Item>
                  </Menu>
                }
              >
                <span className="cell-action__icon">
                  <EllipsisOutlined rotate={90} />
                </span>
              </Dropdown>
            ),
          },
        ]}
        data={generalItems?.sort((a, b) => (a.position || 0) - (b.position || 0))}
      />
      <TableComponent
        title="ISDA Protocols"
        showAdd
        onAdd={() => {
          setFormType(Category.ISDA);
          setFormVisibility(true);
        }}
        onRow={(index) => setCurrentItem(ISDAProtocols[index])}
        showSearch={false}
        columns={[
          {
            title: "ORDER",
            dataIndex: "order",
            key: "order",
            render: (_, __, i) => <p>{(i || 0) + 1}</p>,
          },
          {
            title: "ONBOARDING ITEMS",
            dataIndex: "onboardingItemMetum",
            key: "onboardingItemMetum",
            render: (item) => <p>{item?.name}</p>,
          },
          {
            title: "",
            key: "action",
            render: (text, record, i) => (
              <Dropdown
                className="onboarding__menu"
                trigger={["click"]}
                overlay={
                  <Menu className="cp__menu">
                    <Menu.Item
                      onClick={() => {
                        updateOrderedData(
                          OrderEnum.MOVE_UP,
                          record.id,
                        );
                      }}
                    >
                      <p>
                        <span>
                          <img src={CaretUpIcon} />
                        </span>
                        Move Up
                      </p>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        updateOrderedData(
                          OrderEnum.MOVE_TOP,
                          record.id,
                        );
                      }}
                    >
                      <p>
                        <span>
                          <img src={DoubleCaretUpIcon} />
                        </span>
                        Move to Top
                      </p>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        updateOrderedData(
                          OrderEnum.MOVE_DOWN,
                          record.id,
                        );
                      }}
                    >
                      <p>
                        <span>
                          <img src={CaretDownIcon} />
                        </span>
                        Move Down
                      </p>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        updateOrderedData(
                          OrderEnum.MOVE_BOTTOM,
                          record.id,
                        );
                      }}
                    >
                      <p>
                        <span>
                          <img src={DoubleCaretDownIcon} />
                        </span>
                        Move to Bottom
                      </p>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        deleteOnboardingTemplate(currentItem?.id);
                      }}
                    >
                      <p>
                        <span>
                          <img src={RemoveIcon} />
                        </span>
                        Remove
                      </p>
                    </Menu.Item>
                  </Menu>
                }
              >
                <span className="cell-action__icon">
                  <EllipsisOutlined rotate={90} />
                </span>
              </Dropdown>
            ),
          },
        ]}
        data={ISDAProtocols?.sort((a, b) => (a.position || 0) - (b.position || 0))}
      />
    </div>
  );
};
