import { Col, Row } from "antd";
import React, { Fragment, useEffect } from "react";
import { FC } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  Router,
  useHistory,
  useLocation,
} from "react-router-dom";
import * as appRoutes from "../../../routes/appRoutes";
import GlobalSearch from "../../../shared/components/GlobalSearch";
import HFSidebar from "./Sidebar";
import HFCP from "./CP";
import "./home.scss";
import Dashboard from "./Dashboard";
import { HFCPDetails } from "./CP/CPDetails";
import HFCPGroup from "./CP/CPGroup";
import HFFunds from "./Funds";
import HFFundDetails from "./FundDetails";
import HFAgreements from "../Home/Agreements";
import HFAgreementDetails from "./Agreements/AgreementDetails";
import Help from "../../App/Help";
import Reports from "../../App/Reports";
import ReportList from "../../App/Reports/ReportList";
import Partners from "../../App/Partners";
import Dox from "../../App/Dox";
import LayoutContainer from "../../../store/containers/layoutContainer";
import { LayoutReducerProps } from "../../../store/reducers/layoutReducer";
import DiscussionBoard from "../../DiscussionBoard";
import AccountDetails from "../../App/AccountDetails";
import ManageUsers from "./ManageUsers";
import { LocalStorage } from "../../../shared/utils/localStorageHelpers";
import { Modal } from "antd";

const PAID = {
  title: "Thank you for choosing your subscription plan",
  content: "A TradingDoX representative will be in touch to coordinate payment processing.  In the meanwhile, enjoy using your TradingDoX account!"
}
const FREE = {
  title: "Thank you for selecting Light.",
  content: "Enjoy using your TradingDoX account"
}
interface HFHome extends LayoutReducerProps { }

const HFHome: FC<HFHome> = (props) => {
  const { title, component } = props;
  const history = useHistory();
  const path = history.location.pathname;
  const id = path.split("/").pop();


  const nonSidebarRoutes = [
    appRoutes.REPORTS_SPECIFIC.replace(":reportId", id),
  ];

  const modal = (title: string, content: string) => Modal.success({
    title,
    content,
    okText: "close"
  })

  useEffect(() => {
    setTimeout(() => {
      const type = LocalStorage.getItem("SHOW_SUBSCRIPTION_MODAL");
      if (type === "paid") {
        modal(PAID.title, PAID.content);
      }
      if (type === "free") {
        modal(FREE.title, FREE.content);
      }
      LocalStorage.removeItem("SHOW_SUBSCRIPTION_MODAL");
    }, 1000);
  }, []);

  return (
    <Row className="page__wrapper">
      {!nonSidebarRoutes.find((route) => route === path) && (
        <Col className="sidebar">
          <HFSidebar />
        </Col>
      )}
      <Col className="body-wrapper">
        {!nonSidebarRoutes.find((route) => route === path) && <GlobalSearch />}
        <div className="app-content__wrapper">
          <Switch>
            <Redirect
              exact
              strict
              from={appRoutes.HOME}
              to={appRoutes.DASHBOARD}
            />
            <Route exact path={appRoutes.DASHBOARD} component={Dashboard} />
            <Route
              exact
              path={appRoutes.MANAGE_USERS}
              component={ManageUsers}
            />
            <Route exact path={appRoutes.FUNDS} component={HFFunds} />
            <Route
              exact
              path={appRoutes.FUNDS_SPECIFIC}
              component={HFFundDetails}
            />
            <Route exact path={appRoutes.AGREEMENTS} component={HFAgreements} />
            <Route
              exact
              path={appRoutes.AGREEMENTS_SPECIFIC}
              component={HFAgreementDetails}
            />
            <Route exact path={appRoutes.CP} component={HFCP} />
            <Route exact path={appRoutes.CP_DETAILS} component={HFCPDetails} />
            <Route exact path={appRoutes.CP_GROUP} component={HFCPGroup} />
            <Route exact path={appRoutes.HELP} component={Help} />
            <Route exact path={appRoutes.REPORTS} component={Reports} />
            <Route
              exact
              path={appRoutes.DISCUSSION_BOARD}
              component={DiscussionBoard}
            />
            <Route
              exact
              path={appRoutes.REPORTS_SPECIFIC}
              component={ReportList}
            />
            <Route exact path={appRoutes.PARTNERS} component={Partners} />
            <Route exact path={appRoutes.DOX} component={Dox} />
            <Route
              exact
              path={appRoutes.ACCOUNT_DETAILS}
              component={AccountDetails}
            />
          </Switch>
        </div>
      </Col>
    </Row>
  );
};

export default LayoutContainer(HFHome);
