import moment from "moment";

export const sorter = (a: string, b: string) => {
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }
  const [parsedA, parsedB] = [parseInt(a), parseInt(b)];
  if (isNaN(parsedA) && isNaN(parsedB)) {
    let diff = 0;
    for (let i = 0; i < a.length; i++) {
      diff = a.toLowerCase().charCodeAt(i) - b.toLowerCase().charCodeAt(i);
      if (diff !== 0) {
        break;
      }
    }
    if (diff === 0 || isNaN(diff)) {
      return a.length - b.length;
    } else {
      return diff;
    }
  } else {
    return parsedA - parsedB;
  }
};

export const dateSorter = (a: string, b: string) => moment(a).diff(moment(b));

export const temporarySorter = (record: any[], key: string, order: any) =>{
  if(!key || order === undefined){ return record}
  const sorted = record.sort((a, b)=>sorter(a[key], b[key]))
  if(order === 'ascend'){
    return sorted
  }
return sorted.reverse()
}
