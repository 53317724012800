import Form from "antd/lib/form/Form";
import { Field, Formik, FormikProps } from "formik";
import React, { FC, useEffect, useState } from "react";
import { AttachmentComponent } from "../../../../../shared/components/AttachmentComponent";
import DropdownField from "../../../../../shared/components/DropdownField";
import InputField from "../../../../../shared/components/InputField";
import { UIModal } from "../../../../../shared/components/UIModal";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import "./cpForm.scss";
import { MetaService } from "../../../../../services/MetaService/meta.service";
import { detailedServices, productsAvailable } from "../cpDefinitions";
import CounterPartiesGroupsService from "../../../../../services/CounterPartiesService/CounterPartiesGroups.service";
import {
  CounterPartiesGroups,
  CounterPartiesGroupsMeta,
} from "../../../../../models/CounterParties/counterPartiesGroups.model";
import { deserialize, serialize } from "serializr";
import { HLCounterPartiesService } from "../../../../../services/CounterPartiesService/CounterParties.service";
import { convertJSONToFormData } from "../../../../../shared/utils/dataFormatConverter";
import { CounterParties } from "../../../../../models/CounterParties/counterParties.model";
import { CPFormValidation } from "./cpFormValidation";
import { Status } from "../../../../../enums/counterParties";
interface CPFormProps {
  data: any;
  visible: boolean;
  closeHandler: (cp?: CounterParties) => void;
  category: "cp" | "cp_group";
}

const statuses = [
  { label: "Enabled", value: Status.ENABLED },
  { label: "Disabled", value: Status.DISABLED },
  { label: "Pending Invitation", value: Status.PENDING_INVITATION },
  // { label: "Suggested", value: Status.SUGGESTED }
]

const CPForm: FC<CPFormProps> = ({ data, visible, closeHandler, category }) => {
  const [cpGroupList, setCpGroupList] = useState<CounterPartiesGroupsMeta[]>(
    []
  );

  const [initialValues, setInitialValues] = useState({
    cpCategory: category,
    logo: "",
    productsAvailable: undefined,
    detailedServiceOfferings: undefined,
    cpGroupId: undefined,
    country: undefined,
    status: undefined,
    state: undefined,
    city: undefined,
  });
  const formRef = React.useRef<FormikProps<any>>();
  const [countries, setCountries] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [provinces, setProvinces] = useState<any>();
  const [cities, setCities] = useState<any>();

  const reinitializeFormValues = () => {
    setInitialValues({
      cpCategory: category,
      logo: "",
      productsAvailable: undefined,
      detailedServiceOfferings: undefined,
      cpGroupId: undefined,
      country: undefined,
      state: undefined,
      city: undefined,
      status: undefined
    })
  }


  const fetchCountries = () => {
    MetaService.listCountries(
      (countries) => {
        setCountries(countries);
      },
      () => { }
    );
  };
  const fetchProvinces = (countryId: any) => {
    MetaService.listProvinces(
      countryId,
      (provinces) => {
        setProvinces(provinces);
        //console.log(provinces);
      },
      () => { }
    );
  };
  const fetchCities = (countryId: any, provinceId: any) => {
    MetaService.listCities(
      countryId,
      provinceId,
      (cities) => {
        setCities(cities);
      },
      () => { }
    );
  };
  const fetchCPGroupList = () => {
    CounterPartiesGroupsService.fetchCounterPartiesGroups(
      {},
      (response) => {
        const groupsMeta: any = deserialize(CounterPartiesGroupsMeta, response);
        setCpGroupList(groupsMeta);
      },
      () => { },
      () => { }
    );
  };
  useEffect(() => {
    fetchCountries();
    fetchCPGroupList();
  }, []);

  useEffect(() => {
    if (data) {
      if (category == "cp_group") {
        setInitialValues({
          ...data,
          cpCategory: category,
          logo: data?.logoUrl,
        });
      } else {
        if (data?.address?.countryId) {
          fetchProvinces(data?.address?.countryId);
          data?.address?.provinceId && fetchCities(data?.address?.countryId, data?.address?.provinceId)
        }
        setInitialValues({
          ...data,
          logo: data?.logoUrl,
          street: data?.address?.street,
          address2: data?.address?.addressLine2,
          country: data?.address?.countryId,
          cpCategory: category,
          state: data?.address?.provinceId,
          city: data?.address?.cityId,
        });
      }
    }
  }, [data]);

  const onSubmit = (values: any, resetForm: Function) => {
    setLoading(true)
    if (data) {
      if (values?.cpCategory == "cp_group") {
        const jsonData = serialize(CounterPartiesGroups, values);
        jsonData["logo"] = values["logo"];
        const formData = convertJSONToFormData({ cp_group: jsonData });
        CounterPartiesGroupsService.updateCounterPartiesGroups(
          data?.id,
          formData,
          (data) => {
            resetForm()
            reinitializeFormValues()
            closeHandler(data);
          },
          () => { },
          () => {
            setLoading(false)
          }
        );
      } else {
        values["address"] = {
          addressType: "registered",
          street: values?.street,
          addressLine2: values?.address2,
          countryId: values?.country,
          provinceId: values?.state,
          cityId: values?.city,
        };
        const jsonData = serialize(CounterParties, values);
        jsonData["logo"] = values["logo"];
        const formData = convertJSONToFormData({ counter_party: jsonData });
        HLCounterPartiesService.updateCounterParties(
          data?.id,
          formData,
          (data) => {
            resetForm()
            reinitializeFormValues()
            closeHandler(data);
          },
          () => { },
          () => {
            setLoading(false)
          }
        );
      }
    } else {
      if (values?.cpCategory == "cp_group") {
        const jsonData = serialize(CounterPartiesGroups, values);
        jsonData["logo"] = values["logo"];
        const formData = convertJSONToFormData({ cp_group: jsonData });
        CounterPartiesGroupsService.createCounterPartiesGroups(
          formData,
          (data) => {
            resetForm()
            reinitializeFormValues()
            closeHandler(data);
          },
          () => { },
          () => {
            setLoading(false)
          }
        );
      } else {
        values["address"] = {
          addressType: "registered",
          street: values?.street,
          addressLine2: values?.address2,
          cityId: values?.city,
        };
        //console.log(values);
        const jsonData = serialize(CounterParties, values);
        jsonData["logo"] = values["logo"];
        const formData = convertJSONToFormData({ counter_party: jsonData });
        HLCounterPartiesService.createCounterParty(
          formData,
          (data) => {
            resetForm()
            reinitializeFormValues()
            closeHandler(data);
          },
          () => { },
          () => {
            setLoading(false)
          }
        );
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      validationSchema={CPFormValidation}
      enableReinitialize
      innerRef={formRef}
    >
      {({ setFieldValue, values, resetForm, isValid, dirty, errors }) => {
        return (
          <UIModal
            visible={visible}
            closeModal={() => {
              if (!loading) {
                reinitializeFormValues()
                resetForm()
                closeHandler()
              }
            }}
            title={data ? "Update CP/Broker" : "Add New CP/Broker"}
            submitProps={{
              loading,
              disabled: !isValid || !dirty,
              text: data ? "Update" : "Create",
              clickHandler: () => onSubmit(values, resetForm),
            }}
            cancelProps={{
              text: "Cancel",
              clickHandler: () => {
                resetForm()
                reinitializeFormValues()
                closeHandler();
              },
            }}
          >
            <Form>
              <div className="cp-form__container">
                <div className="cp-form__field">
                  <DropdownField
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"CP Category *"}
                    name="cpCategory"
                    options={[
                      { value: "cp", label: "CP" },
                      { value: "cp_group", label: "CP Group" },
                    ]}
                    disabled={!!data?.id}
                    showSearch={false}
                    showLabel
                    value={values.cpCategory}
                    onChange={(value) => setFieldValue("cpCategory", value)}
                  />
                </div>
                {values?.cpCategory == "cp_group" ? (
                  <>
                    <div className="cp-form__field">
                      <Field name="logo" as="file-upload">
                        <AttachmentComponent
                          accept={"image/*"}
                          image={values?.logo}
                          onUpload={(file) => setFieldValue("logo", file)}
                          title="Upload Logo"
                        />
                      </Field>
                    </div>
                    <div className="cp-form__field">
                      <InputField
                        placeholder={"CP Group Name"}
                        name="name"
                        value={values?.name}
                        showLabel
                        type="text"
                      />
                    </div>
                    <div className="cp-form__field">
                      <InputField
                        placeholder={"Description"}
                        value={values?.description}
                        name="description"
                        type="textarea"
                        showLabel
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="cp-form__field">
                      <Field name="logo" as="file-upload">
                        <AttachmentComponent
                          accept={"image/*"}
                          image={values?.logo}
                          onUpload={(file) => setFieldValue("logo", file)}
                          title="Upload Logo"
                        />
                      </Field>
                    </div>
                    <div className="cp-form__field">
                      <InputField
                        placeholder={"Full Legal Name *"}
                        value={values?.name}
                        name="name"
                        type="text"
                        showLabel
                      />
                    </div>
                    <div className="cp-form__field">
                      <DropdownField
                        mode="multiple"
                        optionFilterProp="label"
                        placeholder={"Services"}
                        name="productsAvailable"
                        options={productsAvailable}
                        showSearch={false}
                        value={values?.productsAvailable}
                        onChange={(value) =>
                          setFieldValue("productsAvailable", value)
                        }
                        showLabel
                      />
                    </div>
                    <div className="cp-form__field">
                      <DropdownField
                        mode="multiple"
                        optionFilterProp="label"
                        placeholder={"Detailed Service Offering"}
                        name="detailedServiceOfferings"
                        options={detailedServices}
                        showSearch={false}
                        value={values?.detailedServiceOfferings}
                        onChange={(value) =>
                          setFieldValue("detailedServiceOfferings", value)
                        }
                        showLabel
                      />
                    </div>
                    <div className="cp-form__field">
                      <DropdownField
                        mode="single"
                        optionFilterProp="label"
                        placeholder={"Corporate Group"}
                        name="cpGroupId"
                        options={cpGroupList}
                        showSearch={false}
                        value={values?.cpGroupId}
                        onChange={(value) => setFieldValue("cpGroupId", value)}
                        showLabel
                      />
                    </div>
                    <div className="cp-form__field">
                      <InputField
                        placeholder={"Description"}
                        value={values?.description}
                        name="description"
                        type="textarea"
                        showLabel
                      />
                    </div>
                    <div className="cp-form__field">
                      <InputField
                        placeholder={"Contact Person Name"}
                        value={values?.contactPersonName}
                        name="contactPersonName"
                        type="text"
                        showLabel
                      />
                    </div>
                    <div className="cp-form__field">
                      <InputField
                        placeholder={"Contact Person Title"}
                        value={values?.contactPersonTitle}
                        name="contactPersonTitle"
                        type="text"
                        showLabel
                      />
                    </div>
                    <div className="cp-form__field">
                      <InputField
                        placeholder={"Contact Person Telephone"}
                        value={values?.contactPersonPhone}
                        name="contactPersonPhone"
                        type="text"
                        suffix={<PhoneOutlined />}
                        showLabel
                      />
                    </div>
                    <div className="cp-form__field">
                      <InputField
                        placeholder={"Contact Person Email"}
                        value={values?.contactPersonEmail}
                        name="contactPersonEmail"
                        type="text"
                        suffix={<MailOutlined />}
                        showLabel
                      />
                    </div>
                    <div className="cp-form__field">
                      <InputField
                        placeholder={"Additional Contacts"}
                        value={values?.additionalContacts}
                        name="additionalContacts"
                        type="textarea"
                        showLabel={true}
                      />
                    </div>
                    <div className="cp-form__field">
                      <InputField
                        placeholder={"Street *"}
                        value={values?.street}
                        name="street"
                        type="text"
                        showLabel
                      />
                    </div>
                    <div className="cp-form__field">
                      <InputField
                        placeholder={"Address 2"}
                        value={values?.address2}
                        name="address2"
                        type="text"
                        showLabel
                      />
                    </div>
                    <div className="cp-form__field">
                      <DropdownField
                        mode="single"
                        optionFilterProp="label"
                        placeholder={"Country *"}
                        name="country"
                        options={countries}
                        showSearch={false}
                        value={values?.country}
                        onChange={(value) => {
                          setFieldValue("country", value);
                          setFieldValue("state", undefined);
                          setFieldValue("city", undefined);
                          fetchProvinces(value);
                        }}
                        showLabel
                      />
                    </div>
                    <div className="cp-form__field">
                      <DropdownField
                        mode="single"
                        optionFilterProp="label"
                        placeholder={"State/Province *"}
                        name="state"
                        options={provinces}
                        showSearch={false}
                        value={values?.state}
                        onChange={(value) => {
                          setFieldValue("state", value);
                          setFieldValue("city", undefined);
                          fetchCities(values?.country, value);
                        }}
                        showLabel
                      />
                    </div>
                    <div className="cp-form__field">
                      <DropdownField
                        mode="single"
                        optionFilterProp="label"
                        placeholder={"City *"}
                        name="city"
                        options={cities}
                        showSearch={false}
                        value={values?.city}
                        onChange={(value) => {
                          setFieldValue("city", value);
                        }}
                        showLabel
                      />
                    </div>
                    {data && <div className="cp-form__field">
                      <DropdownField
                        mode="single"
                        optionFilterProp="label"
                        placeholder={"Status"}
                        name="status"
                        options={statuses}
                        showSearch={false}
                        value={values?.status}
                        onChange={(value) => {
                          setFieldValue("status", value);
                        }}
                        showLabel
                      />
                    </div>}
                  </>
                )}
              </div>
            </Form>
          </UIModal>
        );
      }}
    </Formik>
  );
};
export default CPForm;
