import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router";
import {
  HFUND_COMPANY_DETAILS,
  HFUND_DASHBOARD,
  HFUND_FUNDS,
  HFUND_AGREEMENTS,
  HFUND_MANAGE_USERS,
  HFUND_DOX_COINS,
  HFUND_ACCOUNT_INFO,
} from "../../../../../routes/appRoutes";
import HFFunds from "../../../../HF/Home/Funds";
import HLAgreement from "../../Agreements";
import DoXCoins from "../../../../App/Dox";
import "./hFundDetailView.scss";
import HLFunds from "../../Funds";
import ManageUsers from "../../../../HF/Home/ManageUsers";
import Dashboard from "../../../../HF/Home/Dashboard";
import AccountDetails from "../../../../App/AccountDetails";

const routes = [
  {
    component: Dashboard,
    path: HFUND_DASHBOARD,
  },
  {
    component: HLFunds,
    path: HFUND_FUNDS,
  },
  {
    component: HLAgreement,
    path: HFUND_AGREEMENTS,
  },
  {
    component: ManageUsers,
    path: HFUND_MANAGE_USERS,
  },
  {
    component: DoXCoins,
    path: HFUND_DOX_COINS,
  },
  {
    component: AccountDetails,
    path: HFUND_ACCOUNT_INFO,
  },
];

const HFundDetailView = () => {
  const params = useParams<{ id: string }>();

  return (
    <div className="hfund-detail-view">
      <Switch>
        {routes.map(({ component, path }, index) => (
          <Route key={index} exact path={path} component={component} />
        ))}
      </Switch>
    </div>
  );
};

export default HFundDetailView;
