import * as Yup from "yup";

const ALLOWED_FILE_TYPES = [
    'image/png',
    'image/jpeg',
    'image/jpg',
]
export const PartnerValidation = Yup.object({
    logo: Yup
        .mixed()
        .when(["logoUrl"], {
            is: (logoUrl) => {
                return !logoUrl
            },
            then: Yup.mixed()
                .test("type", "Photograph is required", (value) => value),
        }),
    // .test('fileSize', "File should be less than 2 MB", value => value?.size <= 2000000)
    // .test('fileType', "Please upload pdf or jpeg or png files", value => ALLOWED_FILE_TYPES.includes(value?.type)),
    name: Yup.string().required("Partner name is required!"),
    description: Yup.string().required("Description is required!"),
    services: Yup.string()
        .required("Please provide services in comma seperated manner!"),
    address: Yup.object({
        street: Yup.string().required("Street is required!"),
        addressLine: Yup.string().required("Address is required!"),
        cityId: Yup.number().required("City is required!"),
        provinceId: Yup.number().required("Province is required!"),
        countryId: Yup.number().required("Country is required!"),
    }),
})
