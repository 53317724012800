import React, { FC, ReactNode } from "react";
import { Radio } from "antd";
import "./radioGroup.scss";

interface RadioOption {
	value: string | number;
    label: string | number | ReactNode;
    className?: string;
}

type DisplayType = "space-between" | "default";

interface RadioGroupProps {
	title: string;
	name: string;
	className?: string;
    options: RadioOption[];
    displayType?: DisplayType;
    selectedHandler: (value: any) => void;
    value: any;
}

const RadioGroup: FC<RadioGroupProps> = ({
	title,
	name,
	options,
    className,
    displayType,
    selectedHandler,
    ...rest
}) => {
    const handleSelect = ({target: { value }}: any) => {
        selectedHandler(value);
    }

	return (
		<div className={`radio-group__container ${className}`}>
			<label className="radio-label" htmlFor={name}>{title}</label>
			<Radio.Group className={displayType} onChange={handleSelect} {...rest}>
				{options?.map(({ label, ...rest }) => (
					<Radio {...rest}>{label}</Radio>
				))}
			</Radio.Group>
		</div>
	);
};

export default RadioGroup;
