import React, { useEffect, useState } from "react";
import { Fund } from "../../../../../models/Fund/fund.model";
import { NotificationTypes } from "../../../../../enums/notificationTypes";
import Notification from "../../../../../shared/components/Notification";
import { HLFundsService } from "../../../../../services/FundService/Fund.service";
import { TableComponent } from "../../../../../shared/components/TableComponent";
import moment from "moment";
import { Space } from "antd";
import { FundStatusBE } from "../../../../../enums/fundStatuses";
import FundForm from "../FundForm";
import { useHistory, useLocation, useParams } from "react-router";
import { ProfileModel } from "../../../../../models/HFund/hfund.model";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";
import { ConfirmationAlert } from "../../../../../shared/components/ConfirmationAlert";
import { FUNDS_SPECIFIC } from "../../../../../routes/appRoutes";
import { dateSorter, sorter } from "../../../../../shared/utils/tableSorter";
import { PaginationMeta } from "../../../../../models/Meta/pagination-meta.model";

interface TerminatedFundsProps {
  title: string;
  newFund?: Fund;
}

const TerminatedFunds = (props: TerminatedFundsProps) => {
  const { title, newFund } = props;
  const history = useHistory();

  const [funds, setFunds] = useState<Fund[]>([]);
  const [selectedFund, setSelectedFund] = useState<Fund>();
  const [searchParams, setSearchParams] = useState<{ search?: string, page?: number }>();
  const [formVisibility, setFormVisibility] = useState(false);
  const [companyId, setCompanyId] = useState<number | string>();
  const [showDelete, setDeleteVisibility] = useState(false);
  const { state = {} }: { state: any } = useLocation();
  const [pagination, setPagination] = useState<PaginationMeta>();
  const [loading, setLoading] = useState(false);

  const URLParams = useParams<{ id: string }>();
  const user = LocalStorage.getItem("USER");

  useEffect(() => {
    if (!state?.companyId) {
      const user = LocalStorage.getItem("USER") as ProfileModel;
      setCompanyId(user.companyId);
    }
  }, []);

  useEffect(() => {
    if (!newFund) {
      return;
    }
    setFunds((prevFunds) => [...prevFunds, newFund]);
  }, [newFund]);

  useEffect(() => {
    fetchFunds();
  }, [searchParams]);

  const searchHandler = (search: any) => {
    setSearchParams({ search, page: 1 });
  };

  const fetchFunds = () => {
    setLoading(true)
    const params = {
      page: searchParams?.page || 1,
      status: FundStatusBE.TERMINATED,
      company_ids: URLParams?.id ? [URLParams?.id] : []
    };
    if (searchParams?.search) {
      params["search_text"] = searchParams?.search;
    }
    HLFundsService.fetchFunds(
      params,
      (funds: Fund[], pagination) => {
        setFunds(funds);
        setPagination(pagination)
      },
      (error) => {
        setFunds([]);
        // Notification({
        //   message: "Hedge Legal",
        //   description: "Unable to fetch funds",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => {
        setLoading(false)
      }
    );
  };

  const editHandler = (record: Fund) => {
    setSelectedFund(record);
    setFormVisibility(true);
  };

  const deleteHandler = (id: number, companyId: number) => {
    HLFundsService.deleteFund(
      id,
      { companyId: companyId?.toString() },
      (res) => {
        fetchFunds();
        Notification({
          message: "Hedge Legal",
          description: "Fund deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
      },
      (err) => {
        // ;
        // Notification({
        //   message: "Hedge Legal",
        //   description: "Fund deletion failed",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => {
        setDeleteVisibility(false);
      }
    );
  };
  return (
    <>
      <TableComponent
        className=" row-clickable"
        onRow={(index, record) => {
          if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == parseInt(URLParams['id']))?.length == 0)) {
            Notification({
              message: "Hedge Legal",
              description: "Access Restricted",
              type: NotificationTypes.ERROR,
            });
          } else {
            LocalStorage.setItem('HL_SELECTED_COMPANY_ID', record.companyId)
            history.push(
              FUNDS_SPECIFIC.replace(":fundId", record.id.toString()),
              { fund: record }
            );
          }
        }}
        onSearch={searchHandler}
        data={funds}
        onChange={pagination => setSearchParams(prev => ({ ...prev, page: pagination?.current }))}
        loading={loading}
        pagination={{
          pageSize: 20,
          total: pagination?.totalCount,
          current: pagination?.currentPage,
          showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
        }}
        scroll={{ x: 850 }}
        title={title}
        columns={[
          {
            title: "COMPANY NAME",
            dataIndex: "companyName",
            key: "companyName",
            width: 200,
            sorter: (a, b) => sorter(a.name, b.name),
            sortDirections: ["ascend", "descend"],
            render: (text, record: Fund) => (
              <span
              >
                {text}
              </span>
            ),
          },
          {
            title: "NAME",
            dataIndex: "name",
            key: "name",
            width: 200,
            sorter: (a, b) => sorter(a.name, b.name),
            sortDirections: ["ascend", "descend"],
            render: (text, record: Fund) => (
              <span
                className="fund-details--route"
                onClick={() => {
                  LocalStorage.setItem('HL_SELECTED_COMPANY_ID', record.companyId)
                  history.push(
                    FUNDS_SPECIFIC.replace(":fundId", record.id.toString())
                  );
                }}
              >
                {text}
              </span>
            ),
          },
          {
            title: "STRATEGY",
            dataIndex: "strategy",
            key: "strategy",
            width: 200,
            sorter: (a, b) => sorter(a.strategy, b.strategy),
            sortDirections: ["ascend", "descend"],
            render: (text) => {
              return <p>{text?.replaceAll("_", " ")}</p>
            }
          },
          {
            title: "TERMINATION DATE",
            key: "terminatedAt",
            dataIndex: "terminatedAt",
            width: 200,
            sorter: (a, b) =>
              dateSorter(
                a.terminatedAt,
                b.terminatedAt
              ),
            sortDirections: ["ascend", "descend"],
            render: (text) => text
              ? text?.length === 4
                ? text
                : <p>{moment(text).format("MMM DD, YYYY")}</p>
              : "",
          },
          {
            title: "",
            key: "action",
            width: 50,
            render: (text, record) => (
              <Space size="middle"
                className="table-actions"
                onClick={e => e.stopPropagation()}
              >

                <i
                  className="icon-edit"
                  onClick={() => {
                    if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == parseInt(URLParams['id']))?.length == 0)) {
                      Notification({
                        message: "Hedge Legal",
                        description: "Access Restricted",
                        type: NotificationTypes.ERROR,
                      });
                    } else {
                      editHandler(record)
                    }
                  }}
                  title="Edit"
                />
                <i
                  className="icon-delete"
                  onClick={() => {
                    if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == parseInt(URLParams['id']))?.length == 0)) {
                      Notification({
                        message: "Hedge Legal",
                        description: "Access Restricted",
                        type: NotificationTypes.ERROR,
                      });
                    } else {
                      setSelectedFund(record)
                      setDeleteVisibility(true);
                    }
                  }}
                  title="Delete"
                />
              </Space>
            ),
          },
        ]}
      />
      <ConfirmationAlert
        closeHandler={() => setDeleteVisibility(false)}
        visible={showDelete}
        type="delete"
        deleteHandler={() => deleteHandler(selectedFund?.id, selectedFund?.companyId)}
        cancelHandler={() => setDeleteVisibility(false)}
        title={"Delete Fund"}
        description={selectedFund?.name}
      />
      <FundForm
        title={"Edit fund"}
        visible={formVisibility}
        closeHandler={() => {
          fetchFunds()
          setFormVisibility(false);
        }}
        data={selectedFund}
      ></FundForm>
    </>
  );
};

export default TerminatedFunds;
