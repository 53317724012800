import { Space, Table, Tooltip } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { ReportHistory, ReportsAgreement } from "../../../../models/Report/report.model";
import { ReportsService } from "../../../../services/ReportsService/reports.service";
import EmptyState from "../../../../shared/components/EmptyState";
import { TableComponent } from "../../../../shared/components/TableComponent";
import TableLoader from "../../../../shared/components/TableLoader";
import "./nestedReportTable.scss"



interface NestedReportTableProps {
    data: ReportHistory
    loading: boolean
    isAdmin: boolean
}

const columns = [{
    title: "FUND",
    dataIndex: "fundName",
    key: "fundName",
    width: 120,
    ellipsis: { showTitle: false },
    render: text => <Tooltip placement="topLeft" title={text || ""}>
        {text || ""}
    </Tooltip>
},
{
    title: "CP NAME",
    dataIndex: "cpName",
    key: "cpName",
    width: 120,
    ellipsis: { showTitle: false },
    render: text => <Tooltip placement="topLeft" title={text || ""}>
        {text || ""}
    </Tooltip>
},
{
    title: "AGREEMENT NAME",
    dataIndex: "name",
    key: "name",
    width: 120,
    ellipsis: { showTitle: false },
    render: text => <Tooltip placement="topLeft" title={text || ""}>
        {text || ""}
    </Tooltip>
},
{
    title: "HFUND COMPANY",
    dataIndex: "companyName",
    key: "companyName",
    width: 120,
    ellipsis: { showTitle: false },
    render: text => <Tooltip placement="topLeft" title={text || ""}>
        {text || ""}
    </Tooltip>
},
{
    title: "DATE",
    dataIndex: "date",
    key: "date",
    width: 100,
    ellipsis: { showTitle: false },
    render: text => <Tooltip placement="topLeft" title={text || ""}>
        {text || ""}
    </Tooltip>
}]

const NestedReportTable = (props: NestedReportTableProps) => {

    const [nestedHistoryData, setNestedHistoryData] = useState<ReportsAgreement[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const {
        data,
        isAdmin,
    } = props


    useEffect(() => {
        fetchNestedHistory(data?.id)
    }, [])

    const fetchNestedHistory = (reportId: number) => {
        setLoading(true)
        if (isAdmin) {
            ReportsService.fetchHLReportNestedHistory(
                reportId,
                (nestedHistory: ReportsAgreement[]) => {
                    setNestedHistoryData(nestedHistory || [])
                },
                () => { },
                () => {
                    setLoading(false)
                },
            )
        } else {
            ReportsService.fetchHLReportNestedHistory(
                reportId,
                (nestedHistory: ReportsAgreement[]) => {
                    setNestedHistoryData(nestedHistory || [])
                },
                () => { },
                () => {
                    setLoading(false)
                },
            )
        }
    }

    return <Table
        className="nested-table"
        columns={columns}
        pagination={false}
        dataSource={nestedHistoryData}
        locale={{
            emptyText: loading ? <TableLoader length={+data?.agreements || 2} /> : <EmptyState />
        }}
    />
}

export default NestedReportTable