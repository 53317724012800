import React from "react";
import { PageHeaderComponent } from "../../../../shared/components/PageHeader";

interface ReportsProps {

}

const Reports = (props: ReportsProps) => {

    return <div className="reports__container">
        <PageHeaderComponent title="Reports" />
    </div>
}

export default Reports