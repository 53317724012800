import { Col, Dropdown, Input, Menu, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import DeleteIcon from "../../../../../../assets/icons/deleteWhite.svg";
import ButtonComponent from "../../../../../../shared/components/ButtonComponent";
import "./generalItem.scss";
import {
  PlusOutlined,
  EllipsisOutlined,
  CaretRightFilled,
} from "@ant-design/icons";
import OnboardingItemMetasService from "../../../../../../services/OnboardingConfigService/OnboardingItemMetas.service";
import { OnboardingItemMetas } from "../../../../../../models/OnboardingConfig/onboardingItemMetas.model";
import { Category } from "../../../../../../enums/onboardingItems";
import { AddItemForm } from "../AddItemForm";

interface GeneralItemProps {
  title: string;
  onClickDelete?: () => void;
  onClick?: () => void;
  active?: boolean;
}
export const ItemCell: FC<GeneralItemProps> = ({
  title,
  onClickDelete,
  onClick,
  active,
}) => {
  return (
    <div
      className={`table__cell table-cell__wrapper ${active && "table-cell__active"
        }`}
      onClick={onClick}
    >
      <p>{title}</p>
      <div className="table-cell__actions">
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu className="cp__menu">
              <Menu.Item onClick={onClickDelete}>
                <p>
                  <span>
                    <img src={DeleteIcon} />
                  </span>
                  Delete
                </p>
              </Menu.Item>
            </Menu>
          }
        >
          <span className="cell-action__icon">
            <EllipsisOutlined rotate={90} />
          </span>
        </Dropdown>
        <span className="cell-action__icon">
          <CaretRightFilled />
        </span>
      </div>
    </div>
  );
};
export const GeneralItems: FC = () => {
  const [generalItems, setGeneralItems] = useState<OnboardingItemMetas[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<OnboardingItemMetas>(null);
  const [showControls, setControlsVisibility] = useState(false);
  const [showAddItemForm, setAddFormVisibility] = useState(false);

  const fetchGeneralItems = () => {
    OnboardingItemMetasService.fetchOnboardingItemMetas(
      {},
      (response: OnboardingItemMetas[]) => {
        setGeneralItems(
          response.filter((data) => data.category == Category.GENERAL)
        );
      },
      () => {
        setControlsVisibility(false);
      },
      () => { }
    );
  };
  useEffect(() => {
    fetchGeneralItems();
  }, []);

  useEffect(() => {
    setCurrentItem(generalItems && generalItems[0]);
  }, [generalItems]);

  const onSave = () => {
    setLoading(true)
    OnboardingItemMetasService.updateOnboardingItemMetas(
      {
        description: currentItem?.description,
        onboardingItemMetaId: currentItem?.id,
      },
      () => {
        fetchGeneralItems();
        setControlsVisibility(false);
      },
      () => { },
      () => setLoading(false)
    );
  };

  const onDelete = () => {
    setLoading(true)
    OnboardingItemMetasService.deleteOnboardingItemMetas(
      {
        onboardingItemMetaId: currentItem?.id,
      },
      () => {
        fetchGeneralItems();
        setControlsVisibility(false);
      },
      () => { },
      () => setLoading(false)
    );
  }

  return (
    <div className="general-items__container">
      <AddItemForm
        visible={showAddItemForm}
        category={Category.GENERAL}
        closeHandler={() => {
          setAddFormVisibility(false);
          fetchGeneralItems();
        }}
      />
      <Row className="general-items__table">
        <Col span={8}>
          <div className="item__wrapper">
            <div className="title first">ITEM</div>
            <div className="table__cell">
              <p
                className="table-cell__action"
                onClick={() => {
                  setAddFormVisibility(true);
                }}
              >
                <span>
                  <PlusOutlined />
                </span>
                Add Item
              </p>
            </div>
            {generalItems.map((value, index) => {
              return (
                <ItemCell
                  onClickDelete={() => {
                    let data = currentItem;
                    data.description = null;
                    setCurrentItem({ ...data });
                    // onSave()
                    onDelete()
                  }}
                  active={currentItem?.id == value.id}
                  title={value.name}
                  onClick={() => setCurrentItem(value)}
                />
              );
            })}
          </div>
        </Col>
        <Col span={16}>
          <div className="description__wrapper">
            <div className="title last">DESCRIPTION</div>

            {currentItem && (
              <div className="description__content">
                <p>{currentItem?.name}</p>
                <Input.TextArea
                  value={currentItem?.description}
                  className="description__input"
                  onChange={(e) => {
                    setControlsVisibility(true);
                    let data = currentItem;
                    data.description = e.target.value;
                    setCurrentItem({ ...data });
                  }}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
      {showControls && (
        <div className="general-items__controllers">
          <ButtonComponent
            type="default"
            onClick={() => {
              setControlsVisibility(false);
            }}
          >
            Discard
          </ButtonComponent>
          <ButtonComponent type="primary" onClick={onSave} loading={loading}>
            Save
          </ButtonComponent>
        </div>
      )}
    </div>
  );
};
