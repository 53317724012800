export const strategies = [
  { value: "convertible_arbitrage", label: "Convertible Arbitrage" },
  { value: "credit_long_short", label: "Credit Long Short" },
  { value: "distressed", label: "Distressed" },
  { value: "equity_long_short", label: "Equity Long Short" },
  { value: "event_driven", label: "Event Driven" },
  { value: "fixed_income_arbitrage", label: "Fixed Income Arbitrage" },
  { value: "fund_of_funds", label: "Fund of Funds" },
  { value: "long_only", label: "Long Only" },
  { value: "macro", label: "Macro" },
  { value: "managed_futures", label: "Managed Futures" },
  { value: "market_neutral", label: "Market Neutral" },
  { value: "merger_arbitrage", label: "Merger Arbitrage" },
  { value: "multi_strategy", label: "Multi Strategy" },
  { value: "opportunistic", label: "Opportunistic" },
  { value: "volatility_arbitrage", label: "Volatility Arbitrage" },
  { value: "activist_investing", label: "Activist Investing" },
  { value: "relative_value_arbitrage", label: "Relative Value Arbitrage" },
  { value: "quantitative", label: "Quantitative" },
];
export const assets = [
  { value: "_1_10_million", label: "1 - 10 Million" },
  { value: "_11_25_million", label: "11 - 25 Million" },
  { value: "_26_50_million", label: "26 - 50 Million" },
  { value: "_51_100_million", label: "51 - 100 Million" },
  { value: "_101_200_million", label: "101 - 200 Million" },
  { value: "_201_500_million", label: "201 - 500 Million" },
  { value: "_501_million_1_billion", label: "501 Million - 1 Billion" },
  { value: "_1_2_billion", label: "1 - 2 Billion" },
  { value: "_3_5_billion", label: "3 - 5 Billion" },
  { value: "_6_10_billion", label: "6 - 10 Billion" },
  { value: "_11_20_billion", label: "11 - 20 Billion" },
  { value: "_21_50_billion", label: "21 - 50 Billion" },
  { value: "_over_50_billion", label: "Over 50 Billion" },
];
