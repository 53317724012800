import { PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import ButtonComponent from "../../../../shared/components/ButtonComponent";
import { PageHeaderComponent } from "../../../../shared/components/PageHeader";
import PreLaunchFunds from "./PreLaunchFunds";
import "./funds.scss";
import { FundStatusBE, UIFundStatus } from "../../../../enums/fundStatuses";
import TerminatedFunds from "./TerminatedFunds";
import ActiveFunds from "./ActiveFunds";
import FundForm from "./FundForm";
import { fundStatuses } from "./FundForm/fundDefinitions";
import { Fund } from "../../../../models/Fund/fund.model";
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";
import { isHFUser } from "../../../../shared/utils/userRoles";

const HFFunds = () => {
  const [formVisibility, setFormVisibility] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(1);
  const [newPreLaunchFund, setNewPreLaunchFund] = useState<Fund>();
  const [newActiveFund, setNewActiveFund] = useState<Fund>();
  const [newTerminatedFund, setNewTerminatedFund] = useState<Fund>();

  const users = LocalStorage.getItem("USER");

  const isUser = users?.role?.includes("hf_user");

  const tabChangeHandler = (e: string) => setActiveTab(+e);

  const onFundCreation = (fund: Fund) => {
    if (!fund) {
      return;
    }
    if (fund.status === FundStatusBE.ACTIVE) {
      setNewActiveFund(fund);
    } else if (fund.status === FundStatusBE.PRELAUNCH) {
      setNewPreLaunchFund(fund);
    } else if (fund.status === FundStatusBE.TERMINATED) {
      setNewTerminatedFund(fund);
    }
  };
  return (
    <div className="hf-funds">
      <PageHeaderComponent
        onChangeTab={tabChangeHandler}
        title="Funds"
        showTabs
        tabs={fundStatuses.map((status) => status.label + " Fund")}
        buttons={
          !isHFUser && (
            <>
              <ButtonComponent
                className="page-header-title__btn"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setFormVisibility(true)}
              >
                Add New Fund
              </ButtonComponent>
            </>
          )
        }
      />
      <div className="funds-list">
        {activeTab === 1 && (
          <PreLaunchFunds
            isUser={isUser}
            newFund={newPreLaunchFund}
            title={UIFundStatus.PRELAUNCH}
          />
        )}
        {activeTab === 2 && (
          <ActiveFunds
            isUser={isUser}
            newFund={newActiveFund}
            title={UIFundStatus.ACTIVE}
          />
        )}
        {activeTab === 3 && (
          <TerminatedFunds
            isUser={isUser}
            newFund={newTerminatedFund}
            title={UIFundStatus.TERMINATED}
          />
        )}
      </div>
      <FundForm
        title={"Add new fund"}
        visible={formVisibility}
        closeHandler={(newFund?: Fund) => {
          onFundCreation(newFund);
          setFormVisibility(false);
        }}
      ></FundForm>
    </div>
  );
};

export default HFFunds;
