export enum FundStatusBE {
    PRELAUNCH = "pre_launch",
    ACTIVE = "active",
    TERMINATED = "terminated"
}

export enum UIFundStatus {
    PRELAUNCH = "Pre Launch Funds",
    ACTIVE = "Active Funds",
    TERMINATED = "Terminated Funds"
}