import { Tabs } from "antd";
import React, { FC } from "react";
import OtherNotifications from "./OtherNotifications";
import AgreementAndOnboarding from "./AgreementsAndOnboarding";
import { OtherNotification } from "../../../models/OtherNotification/otherNotification.model";

const { TabPane } = Tabs;

interface NotificationProps {
  otherNotifications: OtherNotification[];
  clearNotification: (id?: number) => void;
  closeModal: () => void;
}

const Notification: FC<NotificationProps> = (props) => {
  const { otherNotifications, clearNotification } = props;

  const tabs = [
    {
      title: "Other Notification",
      component: <OtherNotifications clearNotification={clearNotification} otherNotifications={otherNotifications}/>,
    },
    {
      title: "Agreement & Onboarding Notifications",
      component: <AgreementAndOnboarding />,
    },
  ];

  return (
    <div className="notification">
      <Tabs defaultValue="1">
        {tabs?.map(({ title, component }, index) => (
          <TabPane key={index} tab={title} tabKey={index?.toString()}>
            {component}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default Notification;
