import { Col, Form, Row } from "antd";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Notification from "../../../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../../../enums/notificationTypes";
import { HLFundsService } from "../../../../../../services/FundService/Fund.service";
import { MetaService } from "../../../../../../services/MetaService/meta.service";
import DropdownField from "../../../../../../shared/components/DropdownField";
import { UIModal } from "../../../../../../shared/components/UIModal";
import { deserialize } from "serializr";
import InputField from "../../../../../../shared/components/InputField";
import {
  OtherItem,
  OtherItemStatus,
} from "../../../../../../models/Fund/otherItems.model";
import { ResponsibleUser } from "../../../../../../models/Fund/onboarding.model";
import { OtherItemValidation } from "../../../../../HF/Home/FundDetails/OtherItems/OtherItemForm/otherItemValidation";
interface OtherItemFormProps {
  visible: boolean;
  title: string;
  closeHandler: () => void;
  id: number;
  data?: OtherItem;
}

const OtherItemForm = (props: OtherItemFormProps) => {
  const { visible, title, closeHandler, id, data } = props;
  const { state = {} }: { state: any } = useLocation();

  const [otherItemFormData, setOtherItemFormData] = useState<OtherItem>(
    new OtherItem()
  );
  const [otherItemStatus, setOtherItemStatus] = useState<any[]>([]);
  const [responsibleUsers, setResponsibleUsers] = useState<any[]>([]);

  useEffect(() => {
    MetaService.otherItemStatus(
      (res) => {
        const data = deserialize(
          OtherItemStatus,
          res.data["other_item_statuses"]
        ) as OtherItemStatus[];
        setOtherItemStatus(
          data.map((data) => {
            return { ...data, label: data.label.replaceAll("_", " ") };
          })
        );
      },
      (err) => {
        // ;
      }
    );

    MetaService.responsibleHLUsers(
      id.toString(),
      (res) => {
        const data = deserialize(
          ResponsibleUser,
          res.data["users"]
        ) as ResponsibleUser[];
        setResponsibleUsers(
          data.map((data) => {
            return {
              value: data.id,
              label: data.firstName + " " + data.lastName,
            };
          })
        );
      },
      (err) => {
        // ;
      }
    );
  }, []);

  const onSubmit = (values: OtherItem, resetForm: Function) => {
    if (values.id) {
      HLFundsService.updateOtherItemsList(
        id,
        values,
        (res) => {
          //console.log(res);
          Notification({
            message: "Hedge Legal",
            description: "Other item edited successfully",
            type: NotificationTypes.SUCCESS,
          });
          resetForm();
          closeHandler();
        },
        (err) => {
          // ;
          // Notification({
          //   message: "Hedge Legal",
          //   description: "Unable to edit other item",
          //   type: NotificationTypes.ERROR,
          // });
        },
        () => { }
      );
    } else {
      HLFundsService.createOtherItemsList(
        id,
        values,
        (res) => {
          //console.log(res);
          Notification({
            message: "Hedge Legal",
            description: "New other item create",
            type: NotificationTypes.SUCCESS,
          });
          resetForm();
          closeHandler();
        },
        (err) => {
          // ;
          // Notification({
          //   message: "Hedge Legal",
          //   description: "Unable to create new other item",
          //   type: NotificationTypes.ERROR,
          // });
        },
        () => { }
      );
    }
  };

  useEffect(() => {
    if (data) {
      setOtherItemFormData(data);
    } else {
      setOtherItemFormData(new OtherItem());
    }
  }, [data]);

  return (
    <Formik
      enableReinitialize
      validationSchema={OtherItemValidation}
      initialValues={otherItemFormData}
      onSubmit={() => { }}
    >
      {({ setFieldValue, values, resetForm, isValid, dirty }) => {
        return (
          <UIModal
            className="other-form__modal"
            visible={visible}
            closeModal={() => {
              resetForm();
              closeHandler();
            }}
            title={
              (otherItemFormData.id ? "Edit " : "Add New ") + " Other Item"
            }
            submitProps={{
              text: otherItemFormData.id ? "Update" : "Create",
              clickHandler: () => {
                onSubmit(values, resetForm);
              },
              disabled: !dirty || !isValid,
            }}
            cancelProps={{
              text: "Cancel",
              clickHandler: () => {
                resetForm();
                closeHandler();
              },
            }}
          >
            <Form>
              <div className="other-form__container">
                <Row>
                  <Col sm={24} className="other-form__field">
                    <InputField
                      placeholder={"Item"}
                      name="name"
                      type="textarea"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} className="other-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"Responsible"}
                      name="responsibleUserId"
                      options={responsibleUsers}
                      showSearch={false}
                      value={values.responsibleUserId}
                      onChange={(value) =>
                        setFieldValue("responsibleUserId", value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} className="other-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"Status"}
                      name="otherItemStatusId"
                      options={otherItemStatus}
                      showSearch={false}
                      value={values.otherItemStatusId}
                      onChange={(value) =>
                        setFieldValue("otherItemStatusId", value)
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Form>
          </UIModal>
        );
      }}
    </Formik>
  );
};

export default OtherItemForm;
