export const tierConverter = (a: string) => {
  const tier = a?.toLowerCase()?.trim();
  if (tier === "one") {
    return "1";
  } else if (tier === "two") {
    return "2";
  } else if (tier === "three") {
    return "3";
  } else {
    return null;
  }
};

export const aumConvertor = (a: number) => {
  const [thousand, million, billion, trillion, quadrillion] = [
    1000, 1000000, 1000000000, 1000000000000, 1000000000000000
  ];

  if (a >= quadrillion) {
    return a / quadrillion + "Q";
  }
  if (a >= trillion) {
    return a / trillion + "T";
  }
  if (a >= billion) {
    return a / billion + "B";
  }
  if (a >= million) {
    return a / million + "M";
  }
  if (a >= thousand) {
    return a / thousand + "K";
  }
  return a
};
