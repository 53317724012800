import { Form, Row, Col } from "antd";
import { Formik, FormikValues } from "formik";
import React from "react";
import { VideoModel } from "../../../../../models/Video/video.model";
import InputField from "../../../../../shared/components/InputField";
import { UIModal } from "../../../../../shared/components/UIModal";
import "./videoForm.scss"
import { VideoFormValidation } from "./videoFormValidation";

interface VideoFormProps {
    visible: boolean
    loading: boolean
    closeHandler: () => void
    submitHandler: (values: FormikValues, resetForm: Function) => void
    videoData?: VideoModel
}

const VideoForm = (props: VideoFormProps) => {

    const {
        visible,
        loading,
        closeHandler,
        submitHandler,
        videoData = new VideoModel()
    } = props

    return (
        <Formik enableReinitialize
            initialValues={videoData}
            onSubmit={() => { }}
            validationSchema={VideoFormValidation}
        >
            {({ setFieldValue, errors, values, dirty, resetForm, isValid, setFieldTouched }) => {
                return (
                    <UIModal
                        className="video-form__modal"
                        visible={visible}
                        closeModal={closeHandler}
                        title={(videoData?.id ? "Edit " : "Add New ") + " Video"}
                        submitProps={{
                            text: videoData?.id ? "Update " : "Create",
                            clickHandler: () => {
                                submitHandler(values, resetForm);
                            },
                            disabled: !isValid || !dirty,
                            loading: loading
                        }}
                        cancelProps={{
                            text: "Cancel",
                            clickHandler: () => {
                                resetForm();
                                closeHandler();
                            },
                        }}
                    >
                        <Form>
                            <Row>
                                <Col sm={24} className="video-form__field">
                                    <InputField
                                        placeholder={"Title *"}
                                        name="title"
                                        type="text"
                                        value={values?.title}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="video-form__field">
                                    <InputField
                                        placeholder={"Description *"}
                                        name="description"
                                        type="textarea"
                                        value={values?.description}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="video-form__field">
                                    <InputField
                                        placeholder={"Vimeo video id *"}
                                        name="link"
                                        type="number"
                                        value={values?.link}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </UIModal>
                );
            }}
        </Formik>
    );
}

export default VideoForm