import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import HFund from "../../../../../assets/icons/HFund company Grey.svg";
import Fund from "../../../../../assets/icons/fund grey.svg";
import CpBroker from "../../../../../assets/icons/CP-broker grey.svg";
import DoxCoin from "../../../../../assets/icons/DOX coin grey.svg";
import User from "../../../../../assets/icons/internal users copy.svg";
import Ticket from "../../../../../assets/icons/ticket grey.svg";
import "./metrices.scss";
import { MetaService } from "../../../../../services/MetaService/meta.service";

const data = [
  {
    title: "HFund Companies",
    value: 273,
    key: "hfund_companies",
    icon: <img src={HFund} alt="" className="icon" />,
  },
  {
    title: "Funds",
    value: 273,
    key: "funds",
    icon: <img src={Fund} alt="" className="icon" />,
  },
  {
    title: "CPs/Brokers",
    value: 273,
    key: "counter_parties",
    icon: <img src={CpBroker} alt="" className="icon" />,
  },
  // {
  //   title: "DoX Coins",
  //   value: 273,
  //   key: `dox_coins`,
  //   bottom: {
  //     text: "Yesterday",
  //     key: "increase_since_yesterday",
  //   },
  //   icon: <img src={DoxCoin} alt="" className="icon" />,
  // },
  {
    title: "Subscription",
    value: 273,
    key: `subscriptions`,
    top: [
      {
        title: "Light",
        value: "20k",
        child: "light",
      },
      {
        title: "Premium 1",
        value: "03K",
        child: "Premium 1",
      },
      {
        title: "Premium 1",
        value: "03K",
        child: "Premium 2",
      },
    ],
    icon: <img src={User} alt="" className="icon" />,
  },
  {
    title: "Tickets",
    key: "tickets",
    value: 273,
    top: [
      {
        title: "Completed",
        value: "20",
        child: "completed",
      },
      {
        title: "In Progress",
        value: "30",
        child: "in_progress",
      },
    ],
    icon: <img src={Ticket} alt="" className="icon" />,
  },
];

const Metrices = () => {
  const [stats, setStats] = useState<any>();
  useEffect(() => {
    MetaService.getHLStatics()
      .then((data) => {
        setStats(data?.overall_stats);
      })
      .catch(() => {});
  }, []);

  return (
    <Row className="metrices" gutter={[10, 10]}>
      {data?.map(({ title, value,  top, icon, key }, index) => {
        return (
          <Col span={4}>
            <Row className="card">
              {icon}
              <Col span={12}>
                <p className="heading">{title}</p>
                <h2>
                  {stats
                    ? typeof stats[key] === "object"
                      ? stats[key]?.total
                      : stats[key]
                    : "00"}
                </h2>
              </Col>
              <Col
                span={12}
                // style={
                //   bottom
                //     ? {
                //         display: "flex",
                //         alignItems: "flex-end",
                //       }
                //     : {}
                // }
              >
                {/* {bottom ? (
                  <p className="bt-text">{`${
                    stats ? stats[key][bottom.key] : "00"
                  } ${bottom?.text}`}</p>
                ) : null} */}
                {top
                  ? top?.map(({ title, value, child }, index) => (
                      <div className="value" key={index}>
                        <p>{title}</p>
                        <p className="v">{stats ? stats[key][child] : "00"}</p>
                      </div>
                    ))
                  : null}
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default Metrices;
