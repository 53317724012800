import { Divider } from "antd";
import React, { FC } from "react";
import "./cpSuggestionCard.scss";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
interface CPSuggestionCardProps {
  data: any;
  onClickStatus: (value: string) => void
}
export const CPSuggestionCard: FC<CPSuggestionCardProps> = ({ data, onClickStatus }) => {
  return (
    <div className="cp-suggestion-card__container">
      <div className="cp-details__wrapper">
        <div className="cp-name__wrapper">
          <p className="cp__name">{data?.cpName}</p>
          <div className="cp-status__container">
            <CheckOutlined onClick={() => onClickStatus("accept")} />
            <CloseOutlined onClick={() => onClickStatus("reject")} />
          </div>
        </div>
        <div className="cp-address__wrapper">
          <div>
            <p>Address</p>
            <p className="cp__address">{data?.cpAddress}</p>
          </div>
          <div className="cp-products__wrapper">
            <p>Products Available</p>
            <p className="cp__product">
              {data?.cpProducts?.join(", ")?.replaceAll("_", " ")}
            </p>
          </div>
        </div>
      </div>
      <Divider type="vertical" />
      <div className="cp-group-details__wrapper">
        <div className="cp-group-image__container">
          <img src={data?.companyLogo} />
        </div>
        <div className="cp-group-suggestion__container">
          <p>Suggested By</p>
          <p className="cp-company__name">{data?.companyName}</p>
          <p className="cp-company__desc">{data?.companyDescription}</p>
          <p className="cp-company__city">
            {data?.city} . {data?.asset}
          </p>
        </div>
      </div>
    </div>
  );
};
