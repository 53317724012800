import {serializable, alias, primitive, object, list} from 'serializr';

export class OtherItemStatus {
    @serializable(alias('id', primitive()))
	value?: number;

	@serializable(alias('name', primitive()))
	label?: string;
}


export class OtherItem {
	
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('fund_id', primitive()))
	fundId?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('responsible_user_id', primitive()))
	responsibleUserId?: number;


	@serializable(alias('responsible_user_first_name', primitive()))
	responsibleUserFirstName?: string;

	@serializable(alias('responsible_user_last_name', primitive()))
	responsibleUserLastName?: string;

	@serializable(alias('other_item_status_id', primitive()))
	otherItemStatusId?: number;


	@serializable(alias('other_item_status_name', primitive()))
	otherItemStatus?: string;

	@serializable(alias('other_item_status_progress_percent', primitive()))
	otherItemProgress?: number;

}
