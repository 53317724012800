
import {serializable, alias, object, list, primitive} from 'serializr';

export class Changeset { 

	@serializable(alias('id', list(primitive())))
	id?: any[];

	@serializable(alias('company_id', list(primitive())))
	companyId?: any[];

	@serializable(alias('country_id', list(primitive())))
	countryId?: any[];

	@serializable(alias('name', list(primitive())))
	name?: any[];

	@serializable(alias('fund_structure', list(primitive())))
	fundStructure?: any[];

	@serializable(alias('feeder_fund_name', list(primitive())))
	feederFundName?: any[];

	@serializable(alias('investment_manager_name', list(primitive())))
	investmentManagerName?: any[];

	@serializable(alias('sub_advisor_name', list(primitive())))
	subAdvisorName?: any[];

	@serializable(alias('strategy', list(primitive())))
	strategy?: any[];

	@serializable(alias('leverage_long', list(primitive())))
	leverageLong?: any[];

	@serializable(alias('leverage_short', list(primitive())))
	leverageShort?: any[];

	@serializable(alias('leverage_net', list(primitive())))
	leverageNet?: any[];

	@serializable(alias('tier', list(primitive())))
	tier?: any[];

	@serializable(alias('lei', list(primitive())))
	lei?: any[];

	@serializable(alias('partner_name', list(primitive())))
	partnerName?: any[];

	@serializable(alias('fund_administrator_name', list(primitive())))
	fundAdministratorName?: any[];

	@serializable(alias('target_launch_date', list(primitive())))
	targetLaunchDate?: any[];

	@serializable(alias('launch_date', list(primitive())))
	launchDate?: any[];

	@serializable(alias('target_aum', list(primitive())))
	targetAum?: any[];

	@serializable(alias('aum', list(primitive())))
	aum?: any[];

	@serializable(alias('base_currency', list(primitive())))
	baseCurrency?: any[];

	@serializable(alias('status', list(primitive())))
	status?: any[];

	@serializable(alias('created_at', list(primitive())))
	createdAt?: any[];
}