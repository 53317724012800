import React, { FC, useEffect, useState } from "react";
import { Col, Row } from "antd";
import AuthContainer from "../../../../../store/containers/AuthContainer";
import { roles } from "../../../../../shared/components/HOC/roles";
import { CounterPartiesGroups } from "../../../../../models/CounterParties/counterPartiesGroups.model";
import CounterPartiesGroupsService from "../../../../../services/CounterPartiesService/CounterPartiesGroups.service";
import { CPGroupCard } from "../../../../HL/Home/CP/CPGroupCard";
import { generatePath, withRouter } from "react-router";
import { CP_GROUP } from "../../../../../routes/appRoutes";
interface HLCPGroupListProps {
  refresh: boolean;
}
const HFCPGroupList: FC<HLCPGroupListProps> = (props: any) => {
  const [cpGroupList, setCpGroupList] = useState<CounterPartiesGroups[]>([]);

  const fetchCPGroupList = () => {
    CounterPartiesGroupsService.fetchCounterPartiesGroups(
      {},
      (response) => {
        setCpGroupList(response);
      },
      () => { },
      () => { }
    );
  };

  useEffect(() => {
    fetchCPGroupList();
  }, [props.refresh]);

  return (
    <Row gutter={[24, 24]}>
      {cpGroupList.map((value, index) => {
        return (
          <Col span={12}>
            <CPGroupCard
              name={value.name}
              logo={value.logoUrl}
              onClick={() => { props.history.push(generatePath(CP_GROUP, { cpGroupId: value?.id }), { name: value.name, id: value.id }) }}
            />
          </Col>
        );
      })}
    </Row>
  );
};
export default withRouter(AuthContainer(HFCPGroupList));
