
import { serializable, alias, object, list, primitive } from 'serializr';
import { Fund } from '../Fund/fund.model';

export class UserFund {

	@serializable(alias('fund_id', primitive()))
	id?: number;

	@serializable(alias('fund_name', primitive()))
	name?: number;

}

export class User {
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('first_name', primitive()))
	firstName?: string;

	@serializable(alias('last_name', primitive()))
	lastName?: string;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('mobile_number', primitive()))
	phoneNumber?: string;

	// @serializable(alias('managing_funds', list(object(Fund))))
	// managingFunds?: Fund[];

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('password', primitive()))
	password?: string;

	@serializable(alias('confirm_password', primitive()))
	confirmPassword?: string;

	@serializable(alias('role', primitive()))
	role?: string;

	@serializable(alias('funds', list(object(UserFund))))
	funds?: UserFund[];

	@serializable(alias('fund_ids', list(primitive())))
	fundIds?: number[];

	@serializable(alias('company_id', primitive()))
	companyId?: number;
}

