
import {serializable, alias, object, list, primitive} from 'serializr';

export class AncillaryNotification { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('company_name', primitive()))
	companyName?: string;

	@serializable(alias('fund_name', primitive()))
	fundName?: string;

	@serializable(alias('cp_name', primitive()))
	cpName?: string;

	@serializable(alias('agreement_name', primitive()))
	agreementName?: string;

	@serializable(alias('agreement_type', primitive()))
	agreementType?: string;

	@serializable(alias('document_type', primitive()))
	documentType?: string;

	@serializable(alias('due_time', primitive()))
	dueTime?: string;

	@serializable(alias('description', primitive()))
	description?: string;

	@serializable(alias('notified', primitive()))
	notified?: string;

	@serializable(alias('actionable_id', primitive()))
	actionableId?: string;

}