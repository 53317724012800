import { buildFormData, convertJSONToFormData } from './../../shared/utils/dataFormatConverter';
import { Partner } from './../../models/Partner/partner.model';
import { deserialize, serialize } from 'serializr';
import axiosInstance from "../../interceptor/axiosInstance"
import { HF_PARTNERS, HL_PARTNER, HL_PARTNERS } from "../../routes/apiRoutes"

export class PartnerService {

    static fetchHFPartners = async (
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const response = await axiosInstance.get(HF_PARTNERS)
            const data = deserialize(Partner, response.data['partners'])
            onSuccess(data)
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

    static fetchHLPartners = async (
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const response = await axiosInstance.get(HL_PARTNERS)
            const data = deserialize(Partner, response.data['partners'])
            onSuccess(data)
        } catch (error) {
            onError()
        } finally {
            onFinal()
        }
    }

    static createHLPartners = async (
        partner: Partner,
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const payload = {
                partner: serialize(Partner, partner)
            }
            await axiosInstance.post(HL_PARTNERS, convertJSONToFormData(payload))
            onSuccess()
        } catch (error) {
            console.log(error);

            onError()
        } finally {
            onFinal()
        }
    }

    static updateHLPartners = async (
        partner: Partner,
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            const payload = {
                partner: serialize(Partner, partner)
            }
            await axiosInstance.put(HL_PARTNER.replace(":partnerId", String(partner?.id)), convertJSONToFormData(payload))
            onSuccess()
        } catch (error) {
            console.log(error);

            onError()
        } finally {
            onFinal()
        }
    }

    static deleteHLPartners = async (
        partnerId: number,
        onSuccess: Function,
        onError: Function,
        onFinal: Function,
    ) => {
        try {
            await axiosInstance.delete(HL_PARTNER.replace(":partnerId", String(partnerId)))
            onSuccess()
        } catch (error) {
            console.log(error);

            onError()
        } finally {
            onFinal()
        }
    }
}