import React from 'react';
import "./filterListing.scss"
import rejectImage from "../../../assets/icons/reject.svg"
import { Button } from 'antd';

const FilterListing = ({ filterInfo, dataMap, setFilterInfo }) => {

  const getFilterLabel = (key, value) =>
    (dataMap?.find(data => data?.key === key)?.value?.find(item => item?.value === value)?.label) || null;

  const handleRemoveItem = (key, item) => {
    const updatedValue = filterInfo[key]?.filter(value => value !== item);
    setFilterInfo(prevFilterInfo => ({
      ...prevFilterInfo,
      [key]: updatedValue
    }));
  };
  
  const getHeading=(key)=> (dataMap?.find(data => data?.key === key)?.label)

  

  return (
    <div className='filter-listing'> 
      {Object.entries(filterInfo)?.map(([key, value]) =>
        Array.isArray(value) && value.length > 0 && (
          <div key={key}>
            <h3>{getHeading(key)}</h3>
            <div className='filter-listing__container' key={key}>
              {value?.map((item) => (
                  <Button onClick={() => handleRemoveItem(key, item)}> 
                  <span>{getFilterLabel(key, item)} </span>
                  <img src={rejectImage} width={10} height={10} alt='cross'/>
                  </Button>
                 
            
              ))}
                  </div>
       
          </div>
        )
      )}
    </div>
  );
};

export default FilterListing;
