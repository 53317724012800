import React, { FC, useEffect, useState } from "react";
import { Col, Empty, Row, Spin } from "antd";
import faker from "faker";
import AuthContainer from "../../../../../store/containers/AuthContainer";
import { roles } from "../../../../../shared/components/HOC/roles";
import { CounterPartiesGroups } from "../../../../../models/CounterParties/counterPartiesGroups.model";
import CounterPartiesGroupsService from "../../../../../services/CounterPartiesService/CounterPartiesGroups.service";
import { CPGroupCard } from "../CPGroupCard";
import { generatePath, withRouter } from "react-router";
import { CP_GROUP } from "../../../../../routes/appRoutes";
interface HLCPGroupListProps {
  refresh: boolean;
  searchText: string;
}
const HLCPGroupList: FC<HLCPGroupListProps> = (props: any) => {
  const { refresh, searchText } = props;
  const [cpGroupList, setCpGroupList] = useState<CounterPartiesGroups[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const searchTimeOut = setTimeout(() => {
      fetchCPGroupList();
    }, 500);
    return () => {
      clearTimeout(searchTimeOut);
    };
  }, [searchText, refresh]);

  const fetchCPGroupList = () => {
    setLoading(true);
    CounterPartiesGroupsService.fetchCounterPartiesGroups(
      {
        name: searchText
      },
      (response) => {
        setCpGroupList(response);
      },
      () => { },
      () => {
        setLoading(false);
      }
    );
  };

  const CPListTemplate = () => {
    let generatedList = cpGroupList.map((value, index) => {
      return (
        <Col span={12}>
          <CPGroupCard
            name={value.name}
            logo={value.logoUrl}
            onClick={() => {
              props.history.push(generatePath(CP_GROUP, { cpGroupId: value.id }), { name: value.name, id: value.id });
            }}
          />
        </Col>
      );
    });
    if (!generatedList.length) {
      generatedList = [<Empty className="center-align" />];
    }
    return generatedList;
  };

  return (
    <Row gutter={[24, 24]}>
      {!loading && CPListTemplate()}
      {loading && (
        <div className="loading-spinner">
          <Spin />
        </div>
      )}
    </Row>
  );
};
export default withRouter(AuthContainer(HLCPGroupList));
