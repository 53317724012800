
import {serializable, alias, object, list, primitive} from 'serializr';

export class DoxCoinAllocation { 
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('action_type', primitive()))
	actionsType?: string;

	@serializable(alias('allocated_no_of_coins', primitive()))
	allocatedNoOfCoins?: number;
}