import * as ApiRoutes from "../../routes/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { OnboardingTemplates } from "../../models/OnboardingConfig/onboardingTemplates.model";
import { date, deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { generatePath } from "react-router";

export default class OnboardingTemplatesService {
  static fetchOnboardingTemplates(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.HL_ONBAORDING_TEMPLATES)
      .then((response) => {
        const data = deserialize(
          OnboardingTemplates,
          response.data["onboarding_templates"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in fetching onboarding templates!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createOnboardingTemplates(
    onboardingTemplate: OnboardingTemplates,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const serializeData = serialize(OnboardingTemplates, onboardingTemplate);
    const payload = { onboarding_template: serializeData };

    axiosInstance
      .post(ApiRoutes.HL_ONBAORDING_TEMPLATES, payload)
      .then((response) => {
        const data = deserialize(
          OnboardingTemplates,
          response.data["onboarding_template"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in creating onboarding template!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateOnboardingTemplates(
    onboardingTemplate: OnboardingTemplates,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_SINGLE_ONBOARDING_TEMPLATE, {
      onboardingTemplateId: onboardingTemplate.id,
    });
    const serializeData = serialize(OnboardingTemplates, onboardingTemplate);
    const payload = { onboarding_template: serializeData };
    axiosInstance
      .put(API_URL, payload)
      .then((response) => {
        const data = deserialize(
          OnboardingTemplates,
          response.data["onboarding_template"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in updating onboarding template!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteOnboardingTemplates(
    onboardingTemplateId: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_SINGLE_ONBOARDING_TEMPLATE, {
      onboardingTemplateId,
    });
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in deleting onboarding template!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
