import { AutoComplete } from 'antd';
// import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import React, { FC, useState } from 'react'
import './dropdownAndInputField.scss'

interface DropdownAndInputFieldProps {
    value: string | number,
    options: any[],
    onSelect: (value: string, option: any) => void;
    onSearch: (value: string) => void;
    placeholder: string
}

const DropdownAndInputField: FC<DropdownAndInputFieldProps> = (props) => {

    const { value,
        options,
        onSearch,
        onSelect,
        placeholder
    } = props

    const [isFocussed, setIsFocussed] = useState<boolean>(false)

    return (
        <div className="dropdownAndInput__container">
            {isFocussed &&
                <span className="dropdownAndInput--label">
                    {placeholder}
                </span>}
            <AutoComplete
                onFocus={() => setIsFocussed(true)}
                value={value as string}
                options={options}
                onSelect={onSelect}
                onSearch={onSearch}
                placeholder={placeholder}
            />
        </div>
    )
}

export default DropdownAndInputField