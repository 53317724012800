import { Form, Row, Col, Checkbox } from "antd";
import { Field, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NotificationTypes } from "../../../../../enums/notificationTypes";
import {
  Agreement,
} from "../../../../../models/Agreements/agreement.model";
import { HFAgreementService, HLAgreementService } from "../../../../../services/AgreementService/agreement.service";
import DatePickerComponent from "../../../../../shared/components/DatePickerComponent";
import DropdownField from "../../../../../shared/components/DropdownField";
import InputField from "../../../../../shared/components/InputField";
import { UIModal } from "../../../../../shared/components/UIModal";
import "./agreement-form.scss";
import Notification from "../../../../../shared/components/Notification";
import { Fund } from "../../../../../models/Fund/fund.model";
import { AgreementValidation } from "./validationSchema";
import UppyFileUploader from "../../../../../shared/components/UppyFileUploader";
import { agreementTiers } from "../../../../HF/Home/Agreements/agreementsDefinitions";
import ErrorMessage from "../../../../../shared/components/Error";

interface AgreementFormProps {
  visible: boolean;
  closeHandler: (agreement?: Agreement) => void;
  agreement?: Agreement;
  fundId?: number;
  funds: any[];
  counterParties: any[];
  agreementTypes: any[];
  agreementStatus: any[];
}

const AgreementForm = (props: AgreementFormProps) => {
  const {
    agreement,
    visible,
    closeHandler,
    fundId,
    funds,
    counterParties,
    agreementStatus,
    agreementTypes,
  } = props;

  const [agreementFormData, setAgreementFormData] = useState<Agreement>(
    new Agreement()
  );
  const [agreementFileName, setAgreementFileName] = useState<string>('');
  const [agreementUploadUrl, setAgreementUploadUrl] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);

  const onSubmit = (values: Agreement, resetForm: Function) => {
    setButtonLoader(true)
    if (agreementFileName) {
      values.document = agreementFileName;
    }

    if (values?.id) {
      HLAgreementService.updateAgreement(
        values,
        (response) => {
          Notification({
            message: "Hedge Fund",
            description: "Agreement updated successfully",
            type: NotificationTypes.SUCCESS,
          });
          handleSuccess(resetForm, response);
        },
        (error) => {
          //console.log(error);
          // Notification({
          //   message: "Hedge Fund",
          //   description: "Unable to update agreement",
          //   type: NotificationTypes.ERROR,
          // });
        },
        () => {
          setButtonLoader(false)
        }
      );
    } else {
      HLAgreementService.createAgreement(
        values,
        (response) => {
          Notification({
            message: "Hedge Fund",
            description: "Agreement created successfully",
            type: NotificationTypes.SUCCESS,
          });
          handleSuccess(resetForm, response);
        },
        (error) => {
          //console.log(error);
          // Notification({
          //   message: "Hedge Fund",
          //   description: "Unable to add agreement",
          //   type: NotificationTypes.ERROR,
          // });
        },
        () => {
          setButtonLoader(false)
        }
      );
    }
  };

  const handleSuccess = (resetForm: Function, response) => {
    resetForm();
    closeHandler(response);
    setAgreementUploadUrl('');
    setAgreementFileName('');
  }


  useEffect(() => {
    setAgreementFormData(new Agreement());
    setAgreementUploadUrl('');
    setAgreementFileName('');
    if (agreement?.id) {
      HLAgreementService.fetchSingleAgreements(
        agreement?.id,
        (response: Agreement) => {
          setAgreementFormData(response);
          setSelectedStatus(response.statusName)
          setAgreementUploadUrl(response?.documentUrl);
        },
        () => { },
        () => { },
      )
    }
    if (fundId) {
      setAgreementFormData((prev) => {
        return {
          ...prev,
          fundId,
        };
      });
    }
    return () => {
      setAgreementFormData(new Agreement());
    };
  }, [agreement, fundId]);

  return (
    <Formik
      enableReinitialize
      initialValues={agreementFormData}
      onSubmit={() => { }}
      validationSchema={AgreementValidation}
    >
      {({ setFieldValue, values, resetForm, isValid, dirty }) => {
        return (
          <UIModal
            className="agreement-form__modal"
            visible={visible}
            closeModal={closeHandler}
            title={
              (agreementFormData?.id ? "Edit " : "Add New ") + " Agreement"
            }
            submitProps={{
              text: agreementFormData?.id ? "Update " : "Create",
              clickHandler: () => {
                //console.log(values);
                onSubmit(values, resetForm);
              },
              disabled: !isValid,
              loading: buttonLoader
            }}
            cancelProps={{
              text: "Cancel",
              clickHandler: () => {
                resetForm();
                closeHandler();
                setAgreementUploadUrl('');
                setAgreementFileName('');
              },
            }}
          >
            <Form>
              <div className="agreement-form__container">
                <Row>
                  <Col sm={24} className="agreement-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"Fund"}
                      name="fundId"
                      options={funds}
                      showSearch={false}
                      disabled={!!fundId}
                      value={values?.fundId}
                      onChange={(value) => {
                        setFieldValue("fundId", value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} className="agreement-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"CP / Broker"}
                      name="cpId"
                      options={counterParties}
                      showSearch={false}
                      value={values?.cpId}
                      onChange={(value) => {
                        setFieldValue("cpId", value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} className="agreement-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp="label"
                      placeholder={"Agreement Type"}
                      name="agreementTypeId"
                      options={agreementTypes}
                      disabled={!!agreement?.id}
                      showSearch={false}
                      value={
                        values?.agreementTypeId || values?.agreementType?.id
                      }
                      onChange={(value) => {
                        setFieldValue("agreementTypeId", value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} className="agreement-form__field">
                    <InputField
                      placeholder={"Agreement Name"}
                      name="name"
                      type="text"
                      value={values?.name}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} className="agreement-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp={"label"}
                      placeholder={"Status"}
                      name="statusId"
                      options={agreementStatus}
                      showSearch={false}
                      value={values?.statusId}
                      onChange={(value, option) => {
                        setFieldValue("statusId", value);
                        setSelectedStatus(option.label)
                        if (!option.label.includes('fully'))
                          setAgreementFileName(undefined)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} className="agreement-form__field">
                    <DropdownField
                      mode="single"
                      optionFilterProp={"label"}
                      placeholder={"Tier"}
                      name="tier"
                      options={agreementTiers}
                      showSearch={false}
                      value={values?.tier}
                      onChange={(value) => {
                        setFieldValue("tier", value);
                      }}
                    />
                  </Col>
                </Row>
                {values?.statusProgress === 100 && (
                  <Row>
                    <Col sm={24} className="agreement-form__field">
                      <DatePickerComponent
                        name="date"
                        placeholder="Date"
                        className="input-field"
                        value={values?.date && moment(values?.date)}
                        onChange={(value, string) => {
                          setFieldValue("date", string);
                        }}
                      />
                    </Col>
                  </Row>
                )}
                {!agreement && (
                  <Row>
                    <Col
                      sm={24}
                      className="agreement-form__field is-fully-executed"
                    >
                      <Field name="isFullyExecuted">
                        {({ field }) => (
                          <Checkbox
                            {...field}
                            checked={values?.isFullyExecuted}
                          >
                            Agreement is fully executed
                          </Checkbox>
                        )}
                      </Field>
                    </Col>
                  </Row>
                )}
                {values?.isFullyExecuted && !selectedStatus?.includes('fully') && <ErrorMessage message="Agreement status is not fully executed. Revise the agreement status to upload agreement" />}
                {((values?.isFullyExecuted) || (agreementFormData?.id && !agreementFormData.documentUrl)) && selectedStatus?.includes('fully') && <Row>
                  <Col
                    sm={24}
                    className="agreement-form__field agreement-form__field--file-upload"
                  >
                    <Field name="document" as="file-upload">
                      <UppyFileUploader setFileUploadUrl={setAgreementUploadUrl}
                        setFileUploadName={setAgreementFileName}
                        fileUploadUrl={agreementUploadUrl}
                      />
                    </Field>
                  </Col>
                </Row>}
              </div>
            </Form>
          </UIModal>
        );
      }}
    </Formik>
  );
};

export default AgreementForm;
