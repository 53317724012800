import React, { FC, useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  SyncOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import "./otpForm.scss";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { Formik } from "formik";
import Form from "antd/lib/form/Form";
import { Col, Row } from "antd";
import OTPInput from "../../../shared/components/OTPInput";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { COMPANY_DETAILS, LOGIN, PROFILE_VERIFICATION } from "../../../routes/appRoutes";
import { AuthService } from "../../../services/AuthService/auth.service";
import { idText, isBreakStatement } from "typescript";
import { ProfileModel } from "../../../models/HFund/hfund.model";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
interface OTPFormProps extends AuthReducerProps { }
export const OTPForm: FC<OTPFormProps> = (props) => {

  const {
    setAuthenticated,
    setUserType,
  } = props

  const history = useHistory();
  const location = useLocation()
  const initialValues = {
    otp: "",
  };
  const { state }: { state: any } = useLocation();
  const [timer, setTimer] = useState(59);
  const [email, setEmail] = useState<string>()

  useEffect(() => {
    if (!state) {
      const paramString = location.search
      const email = new URLSearchParams(paramString).get('email')
      setEmail(email)
    }
  })

  const startTimer = () => {
    let ms = 59;
    let otpTimer = setInterval(() => {
      if (ms == 0) {
        clearInterval(otpTimer);
        return;
      }
      --ms;
      setTimer(ms);
    }, 1000);
  };

  const handleOTP = (values: any) => {
    AuthService.verifyAccount(
      { ...values, email: state?.email || email, },
      (response: ProfileModel) => {
        if (response.companyStatus == "pending") {
          history.push(PROFILE_VERIFICATION, response)
        } else if (response.companyStatus == "setup_pending") {
          history.push(
            generatePath(COMPANY_DETAILS, {
              companyId: response?.companyId,
            }),
            response
          );
        } else {
          history.push(LOGIN);
        }
      },
      () => { }
    );
  };

  const handleResend = () => {
    AuthService.resendOTP(
      {
        email: state?.email || email,
      },
      () => {
        Notification({
          type: NotificationTypes.SUCCESS,
          message: "OTP resent to " + (state?.email || email),
          description: ""
        })
        // history.push(LOGIN);
      },
      () => { }
    );
  };
  useEffect(() => {
    startTimer();
  }, []);

  return (
    <div className="otp-form__container">
      <div>
        <ArrowLeftOutlined onClick={() => history.goBack()} />
      </div>
      <h1>Verification </h1>
      <div className="">Enter verification code send to {state?.email || email}</div>

      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          //console.log(values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Row>
              <Col span={24}>
                <OTPInput
                  count={6}
                  onChange={(value) => setFieldValue("otp", value)}
                />
              </Col>
              <Col span={24}>
                {!timer ? (
                  <p
                    className="otp-form__timeout"
                    onClick={() => handleResend()}
                  >
                    <span>
                      <SyncOutlined />
                    </span>
                    Resend Code
                  </p>
                ) : (
                  <p className="otp-form__timer">
                    <span>
                      <SyncOutlined />
                    </span>
                    Resend Code in {timer} s
                  </p>
                )}
              </Col>
              <Col span={24}>
                <Row className="btn__wrapper">
                  <Col span={24}>
                    <ButtonComponent
                      type={"primary"}
                      onClick={() => handleOTP(values)}
                      iconRight={<ArrowRightOutlined />}
                      displayType="space-between"
                    >
                      Verify
                    </ButtonComponent>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>

      <p className="info">
        If you have any issues,
        please contact <a href="mailto: support@TradingDoX.com" target="_blank">support@TradingDoX.com</a> and we will help establish your account
      </p>
    </div>
  );
};
