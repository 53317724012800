class ViewSDKClient {
  readyPromise = null
  adobeDCView = null
  constructor() {
    this.readyPromise = new Promise<void>((resolve) => {
      if ((window as any).AdobeDC) {
        resolve();
      } else {
        /* Wait for Adobe Document Services PDF Embed API to be ready */
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          resolve();
        });
      }
    });
    this.adobeDCView = undefined;
  }

  ready() {
    return this.readyPromise;
  }

  previewFile(divId, { url, fileName }, viewerConfig) {
    const config = {
      /* Pass your registered client id */
      // clientId: '2583b8e28721480bb789c1cf2a0ccb18', // local
      // clientId: '2583b8e28721480bb789c1cf2a0ccb18', // local
      // clientId: "c90670a657224d7da6682ea81ee98c35", //heroku
      // clientId: "d9f9ef15481f472ca3e150ca007dde7a", //tradingdox.com
      // clientId: "c90670a657224d7da6682ea81ee98c35", //heroku
      // clientId: "f9a98d4ed9024ddbb2a18670703c2dd3", 
      clientId: "f9a98d4ed9024ddbb2a18670703c2dd3", //Production
      divId
    };
    // if (divId) {
    //   /* Optional only for Light Box embed mode */
    //   /* Pass the div id in which PDF should be rendered */
    //   config.divId = divId;
    // }
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new (window as any).AdobeDC.View(config);

    /* Invoke the file preview API on Adobe DC View object */
    const previewFilePromise = this.adobeDCView.previewFile(
      {
        /* Pass information on how to access the file */
        content: {
          /* Location of file where it is hosted */
          location: {
            url
            // url: "https://documentcloud.adobe.com/view-sdk-demo/PDFs/Bodea Brochure.pdf"
            /*
                    If the file URL requires some additional headers, then it can be passed as follows:-
                    headers: [
                        {
                            key: "<HEADER_KEY>",
                            value: "<HEADER_VALUE>",
                        }
                    ]
                    */
          },
        },
        /* Pass meta data of file */
        metaData: {
          /* file name */
          fileName,
          /* file ID */
          id: "6d07d124-ac85-43b3-a867-36930f502ac6",
        },
      },
      viewerConfig
    );

    return previewFilePromise;
  }

  previewFileUsingFilePromise(divId, filePromise, fileName) {
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new (window as any).AdobeDC.View({
      /* Pass your registered client id */
      clientId: "c90670a657224d7da6682ea81ee98c35",
      /* Pass the div id in which PDF should be rendered */
      divId,
    });

    /* Invoke the file preview API on Adobe DC View object */
    this.adobeDCView.previewFile(
      {
        /* Pass information on how to access the file */
        content: {
          /* pass file promise which resolve to arrayBuffer */
          promise: filePromise,
        },
        /* Pass meta data of file */
        metaData: {
          /* file name */
          fileName: fileName,
        },
      },
      {}
    );
  }

  registerSaveApiHandler() {
    /* Define Save API Handler */
    const saveApiHandler = (metaData, content, options) => {
      return new Promise((resolve) => {
        /* Dummy implementation of Save API, replace with your business logic */
        setTimeout(() => {
          const response = {
            code: (window as any).AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: {
              metaData: Object.assign(metaData, {
                updatedAt: new Date().getTime(),
              }),
            },
          };
          resolve(response);
        }, 2000);
      });
    };

    this.adobeDCView.registerCallback(
      (window as any).AdobeDC.View.Enum.CallbackType.SAVE_API,
      saveApiHandler,
      {}
    );
  }

  registerEventsHandler() {
    /* Register the callback to receive the events */
    this.adobeDCView.registerCallback(
      /* Type of call back */
      (window as any).AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      /* call back function */
      (event) => {
        // console.log(event);
      },
      /* options to control the callback execution */
      {
        /* Enable PDF analytics events on user interaction. */
        enablePDFAnalytics: true,
      }
    );
  }
}

export default ViewSDKClient;
