import Form from "antd/lib/form/Form";
import { Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { date, deserialize } from "serializr";
import { Category } from "../../../../../../enums/onboardingItems";
import { AgreementTypes } from "../../../../../../models/Agreements/agreementTypes.model";
import { AgreementTypesMeta } from "../../../../../../models/Meta/meta.model";
import { OnboardingItemMetas } from "../../../../../../models/OnboardingConfig/onboardingItemMetas.model";
import AgreementTypesService from "../../../../../../services/KeyTermsService/AgreementTypes.service";
import OnboardingItemMetasService from "../../../../../../services/OnboardingConfigService/OnboardingItemMetas.service";
import DropdownField from "../../../../../../shared/components/DropdownField";
import InputField from "../../../../../../shared/components/InputField";
import { UIModal } from "../../../../../../shared/components/UIModal";
import "./addItemForm.scss";
interface AddItemFormProps {
  category: Category;
  visible: boolean;
  closeHandler: () => void;
}
export const AddItemForm: FC<AddItemFormProps> = ({
  visible,
  closeHandler,
  category
}) => {
  const [initialValues, setInitialValues] = useState({
    name: undefined,
    description: undefined
  });
  const [items, setItems] = useState<any>();
  useEffect(() => {
    const data = deserialize(AgreementTypesMeta, items);
    setItems(data);
  }, []);
  const fetchOnboardingItemMetas = () => {
    OnboardingItemMetasService.fetchOnboardingItemMetas(
      {},
      (response: OnboardingItemMetas[]) => {
        if (category == Category.GENERAL) {
          const data = response.filter(
            (data) => data.category == Category.GENERAL
          )?.map(({ name, id }) => ({ label: name, value: id }));
          // console.log(data)
          setItems(data);
        } else {
          const data = response
            .filter(data => data.category == Category.ISDA)
            ?.map(({ name, id }) => ({ label: name, value: id }))
          // console.log(data)
          setItems(data);
        }

      },
      () => { },
      () => { }
    );
  };

  const onUpdateItem = (values, resetForm) => {
    OnboardingItemMetasService.addOnboardingItemMetas(
      { ...values, category },
      () => {
        resetForm()
        closeHandler()
      },
      () => { },
      () => { }
    );
  };

  useEffect(() => {
    // visible && fetchOnboardingItemMetas();
  }, [visible]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      enableReinitialize
    >
      {({ setFieldValue, values, resetForm, isValid }) => {
        return (
          <UIModal
            visible={visible}
            closeModal={closeHandler}
            title={"Add Item"}
            submitProps={{
              text: "Add",
              clickHandler: () => onUpdateItem(values, resetForm),
            }}
            cancelProps={{
              text: "Cancel",
              clickHandler: () => closeHandler(),
            }}
          >
            <Form>
              <div className="kt-replicate-form__container">
                <div className="kt-replicate-form__field">
                  {/* <DropdownField
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"Choose Onboarding Items"}
                    name="name"
                    options={items}
                    showSearch={false}
                    value={values.name}
                    onChange={(value) =>
                      setFieldValue("name", value)
                    }
                  /> */}
                  <InputField
                    placeholder={"Enter onboarding item name"}
                    name="name"
                    type="text"
                    value={values?.name}
                  />
                </div>
                <div className="kt-replicate-form__field">
                  <InputField
                    placeholder="Enter description"
                    name="description"
                    type="textarea"
                    value={values?.description}
                  />
                </div>
              </div>
            </Form>
          </UIModal>
        );
      }}
    </Formik>
  );
};
