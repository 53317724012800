import { Button, Col, Row } from "antd";
import React, { FC, useEffect } from "react";
import { AuditTrail } from "../../../../../models/AuditTrail/auditTrail.model";
import AuditTrialService from "../../../../../services/AuditTrialService/auditTrial.service";
import AppModal from "../../../../../shared/components/AppModal";
import { TableComponent } from "../../../../../shared/components/TableComponent";
import AuditTrialView from "./AuditTrialView";
import AuditViewTitle from "./AuditViewTitle";
import "./listAuditTrial.scss";

interface ListAuditTrialProps {
  closeHandler?: (id?: number) => void;
}

const ListAuditTrial: FC<ListAuditTrialProps> = (props) => {
  const { closeHandler } = props;

  const { getAuditTrials, auditTrails, loading } = AuditTrialService();

  useEffect(() => {
    getAuditTrials();
  }, []);

  const circulationColumns = [
    {
      title: "HFUND COMPANY",
      dataIndex: "name",
      key: "name",
      render: (_, auditTrail: AuditTrail) => auditTrail?.fund?.companyName,
    },
    {
      title: "USER TYPE",
      dataIndex: "name",
      key: "name",
      render: (_, auditTrail: AuditTrail) => auditTrail?.user?.role?.replaceAll("_", " "),
    },
    {
      title: "USER NAME",
      dataIndex: "name",
      key: "name",
      render: (_, auditTrail: AuditTrail) =>
        `${auditTrail?.user?.firstName} ${auditTrail?.user?.lastName}`,
    },
    {
      title: "FUND",
      dataIndex: "name",
      key: "name",
      render: (_, auditTrail: AuditTrail) => auditTrail?.fund?.name,
    },
    {
      title: "EVENT TYPE",
      dataIndex: "itemType",
      key: "itemType",
    },
    {
      title: "INFORMATION",
      dataIndex: "name",
      key: "name",
      render: (_, auditTrail: AuditTrail) =>
        `${auditTrail?.event} ${auditTrail?.itemType}`,
    },
    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (_, auditTrail: AuditTrail) => (
        <AppModal
          title={<AuditViewTitle auditTrail={auditTrail} />}
          width="80vw"
          triggerComponent={(openModal) => (
            <i className="icon-view cursor-pointer" onClick={openModal} />
          )}
          renderComponent={(closeModal) => (
            <AuditTrialView
              auditTrail={auditTrail}
              closeHandler={(id) => {
                closeModal();
                closeHandler(id);
              }}
            />
          )}
        />
      ),
    },
  ];

  return (
    <div className="list-audit-trial">
      <TableComponent
        title=""
        showSearch={false}
        columns={circulationColumns}
        data={auditTrails}
        loading={loading}
      />
    </div>
  );
};

export default ListAuditTrial;
