import { Col, Row, Table, TableColumnProps } from "antd";
import React, { FC, ReactElement, useEffect, useState } from "react";
import {
  SubscriptionModel,
  SubscriptionPlanServicesModel,
} from "../../../../../../models/Auth/auth.model";
import { Service } from "../../../../../../models/Service/service.model";
import { AuthService } from "../../../../../../services/AuthService/auth.service";
import { TableComponent } from "../../../../../../shared/components/TableComponent";
import "./subscriptionPackages.scss";
import { CheckOutlined } from "@ant-design/icons";
import AppModal from "../../../../../../shared/components/AppModal";
import SubscriptionForm from "../SubscriptionForm";

interface TableProps {
  title: string | ReactElement;
  dataIndex: string;
  key: string;
  subscriptionPlanServices: number[];
  render: (
    s?: any,
    presentIndex?: number,
    index?: number
  ) => string | number | ReactElement;
}

const ServiceColumns = [
  {
    title: "FEATURES",
    dataIndex: "name",
    key: "name",
  },
];

interface SubscriptionPackageProps {
  services: Service[];
  reload?: boolean;
}

const SubscriptionPackages: FC<SubscriptionPackageProps> = (props) => {
  const { services, reload } = props;

  const [subscriptionList, setSubscriptionList] = useState<TableProps[]>([]);

  const genSubscription = (subscription: SubscriptionModel) => {
    const subscriptionPlanServices = services?.map((service) =>
      subscription?.subscriptionPlanServices?.findIndex(
        (s) => s.serviceId === service.id
      )
    );
    return {
      title: (
        <AppModal
          title="Edit Subscription Package"
          triggerComponent={(openModal) => (
            <span>
              {subscription?.name}{" "}
              <i className="icon-edit" onClick={openModal} />
            </span>
          )}
          renderComponent={(closeModal) => (
            <SubscriptionForm
              subscriptionPlan={subscription}
              services={services.slice(4)}
              successHandler={() => {
                fetchSubscriptionPlans();
                closeModal();
              }}
              closeHandler={closeModal}
            />
          )}
        />
      ),
      dataIndex: "serviceName",
      key: "serviceName",
      subscriptionPlanServices,
      render: (_, presentIndex, index) => {
        if (index === 0)
          return (subscription.costPerMonth || 0) + "/ year"
        if (index === 1)
          return subscription.maxNoOfAgreements || subscription.minNoOfAgreements || 0
        if (index === 2)
          return subscription.noOfFunds || 0
        if (index === 3)
          return subscription.noOfUsers || 0
        return (
          <div className="outer-container" key={index}>
            <div
              className={`is-subscribed ${presentIndex > -1 ? "has-func" : "has-no-func"
                }`}
            >
              {presentIndex > -1 ? (
                <CheckOutlined style={{ color: "white" }} />
              ) : (
                <i className="icon-close" />
              )}
            </div>
          </div>
        );
      },
    };
  };

  const fetchSubscriptionPlans = () => {
    AuthService.listSubscriptionPlans(
      (subscriptions) => {
        const subscriptionList = subscriptions.map((subscription) =>
          genSubscription(subscription)
        );
        setSubscriptionList(subscriptionList);
      },
      () => { }
    );
  };

  useEffect(() => {
    fetchSubscriptionPlans();
  }, [reload]);

  return (
    <div className="subscription-packages">
      <Row gutter={[15, 15]}>
        <Col span={6}>
          <TableComponent
            columns={ServiceColumns}
            data={services}
            showSearch={false}
          />
        </Col>
        <Col span={18} className="list-subscriptions">
          {subscriptionList?.map(
            ({ subscriptionPlanServices, ...tableProps }) => {
              return (
                <div className="table__outer__container">
                  <TableComponent
                    columns={[tableProps]}
                    data={subscriptionPlanServices}
                    showSearch={false}
                  />
                </div>
              );
            }
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SubscriptionPackages;
