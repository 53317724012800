import { Card, Table } from "antd";
import React, { FC, useCallback, useEffect, useState } from "react";
import { CounterParties } from "../../../../../models/CounterParties/counterParties.model";
import {
  HFCounterPartiesService,
  HLCounterPartiesService,
} from "../../../../../services/CounterPartiesService/CounterParties.service";
import Star from "../../../../../assets/icons/review star.svg";
import "./CPBrokers.scss";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";
import { generatePath, useHistory } from "react-router";
import { CP, CP_DETAILS } from "../../../../../routes/appRoutes";
import { useVT } from "virtualizedtableforantd4";
import { PaginationMeta } from "../../../../../models/Meta/pagination-meta.model";

interface CPBrokersProps {
  companyId?: string
}

const CPBrokers: FC<CPBrokersProps> = (props) => {
  const {
    companyId,
  } = props

  const [countryParties, setCountryParties] = useState<CounterParties[]>([]);

  const history = useHistory();

  const [loader, setLoader] = useState(false);

  const [page, setPage] = useState(1);

  const [hasMore, setHasMore] = useState(true);

  const [vt] = useVT(
    () => ({
      onScroll({ isEnd }) {
        if (isEnd && hasMore) setPage((page) => ++page);
      },
      scroll: {
        y: 400,
      },
    }),
    [hasMore]
  );

  const fetchCounterParties = useCallback(() => {
    setLoader(true);
    const user = LocalStorage.getItem("USER");
    const isAdmin = user?.role?.includes("hl");
    (isAdmin
      ? HLCounterPartiesService
      : HFCounterPartiesService
    ).fetchCounterParties(
      {
        order_by: "agreements",
        company_id: companyId,
        page,
        order_direction: "desc",
      },
      (countryParties: CounterParties[], paginationMeta: PaginationMeta) => {
        setCountryParties((parties) => [...parties, ...countryParties]);
        setHasMore(!!paginationMeta?.nextPage);
      },
      () => { },
      () => {
        setLoader(false);
      }
    );
  }, [companyId, page]);

  useEffect(() => {
    fetchCounterParties();
  }, [fetchCounterParties]);

  const columns = [
    {
      title: "CP Name",
      dataIndex: "name",
      key: "name",
      width: "70%",
    },
    {
      title: "Number of Agreements",
      dataIndex: "noOfAgreements",
      key: "noOfAgreements",
    },
    // {
    //   title: "Rating",
    //   dataIndex: "totalRating",
    //   key: "totalRating",
    //   render: (rating: string) => {
    //     return (
    //       <div className="rating text-right">
    //         {rating ? parseFloat(rating).toPrecision(2) : 0}{" "}
    //         <img src={Star} alt="" />
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "# of Reviews",
    //   dataIndex: "reviewCount",
    //   key: "reviewCount",
    //   render: (count) => <span>&nbsp;&nbsp;&nbsp;{count}</span>,
    // },
  ];

  const handleExplore = () => {
    history.push(CP);
  };

  const handleRedirectToCPDetail = (cp: CounterParties) => {
    return {
      onClick: () => {
        history.push(
          generatePath(CP_DETAILS, {
            cpId: cp?.id,
          })
        );
      },
    };
  };

  return (
    <Card
      className="cp-brokers"
      title="CPs/Brokers"
      extra={<span onClick={handleExplore}>Explore</span>}
    >
      <Table
        bordered
        components={vt}
        pagination={false}
        columns={columns}
        dataSource={countryParties}
        loading={loader}
        onRow={handleRedirectToCPDetail}
        scroll={{ scrollToFirstRowOnChange: false, y: 400 }}
      />
    </Card>
  );
};

export default CPBrokers;
