import React, { FC, useState, useEffect, Dispatch, SetStateAction } from "react";
import { Button, Col, Divider, Row } from "antd";
import { UserService } from "../../../services/UserService/user.service";
import { ProfileModel } from "../../../models/HFund/hfund.model";
import "./mfaBackUpCode.scss"

import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";

interface MFABackUpCodeProps {
    user?: ProfileModel;
}


const MfaBackUpCode: FC<MFABackUpCodeProps> = (props) => {
    const { user } = props;
    const [codes, setCodes] = useState([])

    useEffect(() => {
        UserService.generate_backup_code(
            (response) => {
                setCodes(response.data.codes)
            },
            () => { }
        )
    }, [])

    const downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob([codes.toString()], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "backUpCode.txt";
        document.body.appendChild(element);
        element.click();
        Notification({
            message: "Hedge Legal",
            description: "Downloaded Recovery Codes",
            type: NotificationTypes.SUCCESS,
        });
    }

    const copyCode = () => {
        navigator.clipboard.writeText(codes.toString())
        Notification({
            message: "Hedge Legal",
            description: "Copied to Clipboard",
            type: NotificationTypes.SUCCESS,
        });
    }

    return (
        <div className="multi-factor-authentication__container">
            <div>
                <p className="multi-factor-authentication__info">
                    These codes can be used to access your account if you have trouble signing in.
                </p>
                <ul className="backup-codes">
                    {codes.length && (codes.map((code, index) =>
                        <li key={index}> {code} </li>
                    ))}
                </ul>
                {codes.length && <div className="code-actions mt-2">
                    <Button type="text" onClick={() => copyCode()}> Copy </Button>
                    <Button type="primary" onClick={downloadTxtFile}> Download </Button>
                </div>}

            </div>
        </div >
    );
};

export default MfaBackUpCode;
