import { Input } from "antd";
import { ErrorMessage, Field } from "formik";
import * as React from "react";
import Error from "../Error";
import "./inputField.scss";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
interface InputFieldProps {
  name: string;
  type: string;
  prefix?: any;
  suffix?: any;
  placeholder: string;
  className?: string;
  showEye?: boolean;
  onChange?: Function;
  onBlur?: Function;
  onClick?: Function
  value?: any;
  disabled?: boolean;
  showLabel?: boolean
  validateOnBlur?: boolean
}
const InputField: React.FC<InputFieldProps> = (props) => {
  const { name, className, type, showEye, onChange, showLabel = true } = props;
  return (
    <div>
      {props?.placeholder && !!props?.value && showLabel && (
        <label htmlFor={props?.name} className="field-label input-label">
          {props?.placeholder}
        </label>
      )}
      {type == "password" && showEye ? (
        <>
          <Field
            as={Input.Password}
            {...props}
            className={`input-field ${className}`}
            iconRender={(visible: boolean) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          <ErrorMessage name={name}>
            {(message: string) => <Error message={message} />}
          </ErrorMessage>
        </>
      ) : (
        <>
          {onChange ? (
            <Field
              onChange={onChange}
              as={type == "textarea" ? TextArea : Input}
              {...props}
              className={`input-field ${className}`}
            />
          ) : (
            <Field
              as={type == "textarea" ? TextArea : Input}
              {...props}
              className={`input-field ${className}`}
            />
          )}
          <ErrorMessage name={name}>
            {(message: string) => <Error message={message} />}
          </ErrorMessage>
        </>
      )}
    </div>
  );
};

export default InputField;
