import { SearchOutlined } from "@ant-design/icons";
import { Checkbox, Col, Collapse, Input, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { Dispatch, MouseEventHandler, useEffect, useState } from "react";
import {
  KeyTermGroups,
  KeyTermGroupsKeyTerm,
} from "../../../../models/KeyTerms/keyTermGroups.model";
import KeyTermGroupsService from "../../../../services/KeyTermsService/KeyTermGroups.service";
import { MetaService } from "../../../../services/MetaService/meta.service";
import EmptyState from "../../../../shared/components/EmptyState";
import Loader from "../../../../shared/components/Loader";
import { arraysEqual } from "../../../../shared/utils/arrayEquals";
import "./keyTermPanels.scss";

interface KeyTermPanelsProps {
  agreementTypeId: number;
  setSelectedKeyTerms: Dispatch<number[]>;
  setAvailableKeyTerms: Dispatch<KeyTermGroupsKeyTerm[]>;
  isAllKeyTermsSelected: boolean;
}

const { Panel } = Collapse;

const KeyTermPanels = (props: KeyTermPanelsProps) => {
  const {
    agreementTypeId,
    setSelectedKeyTerms,
    setAvailableKeyTerms,
    isAllKeyTermsSelected,
  } = props;

  const [keyTermGroups, setKeyTermGroups] = useState<KeyTermGroups[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [checkedBoxes, setCheckedBoxes] = useState<any>({});
  useEffect(() => {
    if (agreementTypeId) {
      fetchKeyTermGroups();
    }
  }, [agreementTypeId, search]);

  useEffect(() => {
    const keyTermsArray = keyTermGroups.map((group) => group.keyTerms);
    const keyTerms = [].concat.apply([], keyTermsArray);
    setAvailableKeyTerms(keyTerms);
  }, [keyTermGroups]);

  const fetchKeyTermGroups = () => {
    setLoading(true);
    MetaService.keyTermGroups(
      agreementTypeId,
      {
        search_text: search,
      },
      (keyTermGroups: KeyTermGroups[]) => {
        setKeyTermGroups(keyTermGroups);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    const valuesArray = Object.values(checkedBoxes);
    const values = [].concat.apply([], valuesArray);
    setSelectedKeyTerms(values);
  }, [checkedBoxes]);

  const checkBoxGroupChangeHandler = (value, id) => {
    const data = {};
    data[id] = value;
    setCheckedBoxes((prev) => {
      return {
        ...prev,
        ...data,
      };
    });
  };

  const selectAllKeyTermGroup = (value: number, e?: any) => {
    e?.stopPropagation();
    const keyTerms = keyTermGroups.find(
      (group) => group?.id === value
    ).keyTerms;

    let selectedKeyTerms = keyTerms.map((keyTerm) => keyTerm?.id);
    if (arraysEqual(checkedBoxes[value], selectedKeyTerms)) {
      selectedKeyTerms = [];
    }
    const latestData = { ...checkedBoxes };
    latestData[value] = selectedKeyTerms;
    setCheckedBoxes((prev) => ({
      ...prev,
      ...latestData,
    }));
  };

  const genCheckBoxGroup = (value: number, name: string) => (
    <Checkbox
      value={value}
      checked={!!checkedBoxes[value]?.length}
      onClick={(e) => selectAllKeyTermGroup(value, e)}
    >
      {name}
    </Checkbox>
  );

  const selectedAllKeyTerms = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      keyTermGroups.forEach((group) => {
        selectAllKeyTermGroup(group.id);
      });
    } else {
      setCheckedBoxes({});
    }
  };

  return (
    <Row className="document-details--body">
      <Col span={24} className="document-details--body-keyterms-toolbar">
        <p className="document-details--body-keyterms-toolbar--title">
          <Checkbox onChange={selectedAllKeyTerms} />
          Key Terms
        </p>
        <div className="document-details--body-keyterms-toolbar__scroll">
          <Input
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
          />
          <Collapse
            className="reports-key-term-groups"
            expandIconPosition="right"
          >
            {loading ? (
              <Loader />
            ) : keyTermGroups?.length ? (
              keyTermGroups?.map((keyTermGroup) => {
                return (
                  <Panel
                    key={keyTermGroup?.id}
                    header={genCheckBoxGroup(
                      keyTermGroup?.id,
                      keyTermGroup?.name
                    )}
                  >
                    {/* <Checkbox.Group options={keyTermGroup.keyTerms} defaultValue={['Pear']} onChange={value=>checkBoxGroupChangeHandler(value)} /> */}
                    <Checkbox.Group
                      onChange={(value) =>
                        checkBoxGroupChangeHandler(value, keyTermGroup?.id)
                      }
                      value={checkedBoxes[keyTermGroup?.id]}
                    >
                      {keyTermGroup?.keyTerms?.map((keyTerm) => (
                        <Checkbox
                          key={keyTerm.id}
                          value={keyTerm.id}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {keyTerm.name}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Panel>
                );
              })
            ) : (
              <EmptyState />
            )}
          </Collapse>
        </div>
      </Col>
    </Row>
  );
};

export default KeyTermPanels;
