import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import * as appRoutes from "../../routes/appRoutes";
import Login from "./Login";
import { Register } from "./Register";
import "./auth.scss";
import AppIcon from "../../assets/background-images/app-logo.png";
import { OTPForm } from "./OTPForm";
import ProfileVerification from "./ProfileVerification";
import CompanyDetails from "./CompanyDetails";
import SubscriptionPlans from "./SubscriptionPlans";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordSuccess from "./ForgotPasswordSuccess";
import ResetPassword from "./ResetPassword";
import PaymentInformation from "./PaymentInformation";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { LocalStorage } from "../../shared/utils/localStorageHelpers";
import MfaVerification from "./MfaVerification";

const stripePromise = loadStripe(
  "pk_test_51JbQV5H6CIb9bhcSTe3om1l5yq7Fnl40NfT4Ypu52ZdkjqUNXn0SjFAIOq156gVDzws5oF2khqlwL8CZ5oai1Qha00ObboxIfc"
);

export const AuthRouter = () => {
  const location = useLocation();

  const history = useHistory();

  const token = LocalStorage.getItem("ACCESS_TOKEN");

  useEffect(() => {
    // if (token && !location.pathname?.includes("/auth/subscription")) {
    //   history.push(appRoutes.DASHBOARD);
    // }
  }, []);

  return (
    <Row className="auth-component">
      {!location?.pathname?.includes("subscription") && (
        <Col span={14} className="auth-bg__container">
          <div className="auth-bg__content">
            <img src={AppIcon} />
            <h5>
              Simplifying the Lifecycle of Counterparty Documents
            </h5>
            <p>
              Onboarding &gt; Negotiation &gt; Document Storage &gt; Key Term Tracking &gt; Contract Analytics
            </p>
            {location?.pathname?.includes("login") ? (
              <div
                className="auth-btn"
                onClick={() => {
                  history?.push(appRoutes.REGISTER);
                }}
              >
                Signup here
              </div>
            ) : (
              <div
                className="auth-btn"
                onClick={() => history?.push(appRoutes.LOGIN)}
              >
                Login here
              </div>
            )}
          </div>
        </Col>
      )}
      <Col span={location?.pathname?.includes("subscription") ? 24 : 10}>
        <Switch>
          <Route exact path={appRoutes.LOGIN} component={Login} />
          <Route exact path={appRoutes.REGISTER} component={Register} />
          <Route exact path={appRoutes.VERIFY_OTP} component={OTPForm} />
          <Route
            exact
            path={appRoutes.RESET_PASSWORD}
            component={ResetPassword}
          />
          <Route
            exact
            path={appRoutes.FORGOT_PASSWORD}
            component={ForgotPassword}
          />
          <Route
            exact
            path={appRoutes.FORGOT_PASSWORD_SUCCESS}
            component={ForgotPasswordSuccess}
          />
          <Route
            exact
            path={appRoutes.PROFILE_VERIFICATION}
            component={ProfileVerification}
          />
          <Route
            exact
            path={appRoutes.COMPANY_DETAILS}
            component={CompanyDetails}
          />
          <Route
            exact
            path={appRoutes.SUBSCRIPTION}
            component={SubscriptionPlans}
          />
          <Route
            exact
            path={appRoutes.PAYMENT_INFORMATION}
            component={(props) => (
              <Elements stripe={stripePromise}>
                <PaymentInformation {...props} />
              </Elements>
            )}
          />
          <Route
            exact
            path={appRoutes.MFA_VERIFICATION}
            component={MfaVerification}
          />
          <Redirect exact strict from={appRoutes.AUTH} to={appRoutes.LOGIN} />
        </Switch>
      </Col>
    </Row>
  );
};
