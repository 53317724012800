import * as Yup from "yup";

export const CPFormValidation = Yup.object().shape({
  name: Yup.string().required("Name is required!"),
  street: Yup.string().when(["cpCategory"], {
    is: (value) => value === "cp",
    then: Yup.string().required("Street is required!")
  }),
  country: Yup.number().when(["cpCategory"], {
    is: (value) => value === "cp",
    then: Yup.number().required("Country is required!")
  }),
  city: Yup.number().when(["cpCategory"], {
    is: (value) => value === "cp",
    then: Yup.number().required("City is required!")
  }),
  state: Yup.number().when(["cpCategory"], {
    is: (value) => value === "cp",
    then: Yup.number().required("State is required!")
  }),
  // contactPersonName: Yup.string().when(["cpCategory"], {
  //   is: (value) => value === "cp",
  //   then: Yup.string().required("Contact Person Name is required!")
  // }),
  // contactPersonTitle: Yup.string().when(["cpCategory"], {
  //   is: (value) => value === "cp",
  //   then: Yup.string().required("Contact Person Title is required!")
  // }),
  // contactPersonPhone: Yup.string().when(["cpCategory"], {
  //   is: (value) => value === "cp",
  //   then: Yup.string()
  //     .matches(
  //       /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
  //       "Mobile number should be valid!"
  //     )
  //     .required("Contact Person Phone is required!")
  // }),
  // contactPersonEmail: Yup.string().when(["cpCategory"], {
  //   is: (value) => value === "cp",
  //   then: Yup.string().required("Contact Person Email is required!")
  // }),
  // productsAvailable: Yup.string().when(["cpCategory"], {
  //   is: (value) => value === "cp",
  //   then: Yup.string().required("Products Available is required!")
  // }),
  // zipCode: Yup.string()
  //   .matches(/^[0-9]{1,}$/, "Zip Code/Postal Code should be valid!")
  //   .required("Zip Code/Postal Code is required!"),
  // contactPersonName: Yup.string().required("Contact Person Name is required!"),
  // contactPersonTitle: Yup.string().required(
  //   "Contact Person Title is required!"
  // ),
  // contactPersonPhone: Yup.string()
  // .matches(
  //   /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
  //   "Mobile number should be valid!"
  // )
  // .required("Contact Person Phone is required!"),
  // contactPersonEmail: Yup.string()
  //   .email("E-mail is not valid!")
  //   .required("Contact Person Email is required!"),
  // name: Yup.string().required("Name is required!"),
  // productsAvailable: Yup.array().required("Products Available is required!"),
  // cpGroupId: Yup.array().required("Corporate Group is required!"),
});
