import { Button, Col, Row } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, {
  FC,
  ForwardedRef,
  forwardRef,
  Fragment,
  ReactElement,
  useState,
} from "react";

interface AppModalProps {
  triggerComponent: (openModal: () => void) => ReactElement;
  renderComponent: (closeModal: () => void) => ReactElement;
  title?: string | ReactElement;
  noPadding?: boolean;
  width?: string;
}

const AppModal: FC<AppModalProps> = (props) => {
  const {
    width,
    noPadding,
    triggerComponent,
    renderComponent,
    title = null,
  } = props;

  const [visible, setVisible] = useState(false);

  const handleOpenModal = () => setVisible(true);

  const handleCloseModal = () => setVisible(false);

  return (
    <Fragment>
      {triggerComponent(handleOpenModal)}
      <Modal
        visible={visible}
        destroyOnClose
        closable
        title={title}
        className={`app-modal ${noPadding && "modal-no-padding"}`}
        centered
        width={width}
        onCancel={handleCloseModal}
      >
        {renderComponent(handleCloseModal)}
      </Modal>
    </Fragment>
  );
};

export default AppModal;
