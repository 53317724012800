import { deserialize, serialize } from "serializr";
import { store } from "../../components/store";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import {
  LoginModel,
  OtpModel,
  SignUpModel,
  SubscriptionModel,
} from "../../models/Auth/auth.model";
import { ProfileModel } from "../../models/HFund/hfund.model";
import { Service } from "../../models/Service/service.model";
import {
  GET_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_SERVICES,
  LOGIN,
  RESEND_OTP,
  SIGNUP,
  VERIFY_ACCOUNT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
} from "../../routes/apiRoutes";
import { VERIFY_OTP } from "../../routes/appRoutes";
import Notification from "../../shared/components/Notification";
import { LocalStorage } from "../../shared/utils/localStorageHelpers";
import { UNAUTHENTICATED } from "../../store/definitions/authConstants";

export class AuthService {
  static listSubscriptionPlans(
    onSuccess: (subscriptionModel: SubscriptionModel[]) => void,
    onError: Function
  ) {
    return axiosInstance
      .get(GET_SUBSCRIPTION_PLANS)
      .then((response) => {
        let plans = deserialize(
          SubscriptionModel,
          response["data"]["subscription_plans"] as any[]
        );
        onSuccess(plans);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static listSubscriptionServices(
    onSuccess: (services: Service[]) => void,
    onError: Function
  ) {
    return axiosInstance
      .get(GET_SUBSCRIPTION_SERVICES)
      .then((response) => {
        let plans = deserialize(Service, response["data"]["services"] as any[]);
        onSuccess(plans);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static signUp(data: any, onSuccess: Function, onError: Function) {
    const serializeData = serialize(SignUpModel, data);
    const payload = { user: serializeData };

    return axiosInstance
      .post(SIGNUP, payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        console.log(error)
        // Notification({
        //   message: "Registration failed",
        //   description: error?.data?.error || "",
        //   type: NotificationTypes.ERROR,
        // });
        onError(error);
      });
  }
  static verifyAccount(data: any, onSuccess: Function, onError: Function) {
    const serializeData = serialize(OtpModel, data);
    const payload = serializeData;
    return axiosInstance
      .post(VERIFY_ACCOUNT, payload)
      .then((response) => {
        const company = deserialize(ProfileModel, response["data"]["user"]);
        onSuccess(company);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static resendOTP(data: any, onSuccess: Function, onError: Function) {
    const serializeData = serialize(OtpModel, data);
    const payload = serializeData;
    return axiosInstance
      .post(RESEND_OTP, payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static forgotPassword = async (email: string,
    redirect_url: string,
    onSuccess: Function,
    onError: Function,
    onFinal: Function) => {
    try {
      const data = {
        user: {
          email,
          redirect_url
        }
      }
      const response = await axiosInstance.post(FORGOT_PASSWORD, data)
      Notification({
        message: "",
        description: "Reset password link sent to your registered mail id",
        type: NotificationTypes.SUCCESS,
      });
      onSuccess()
    } catch (error) {
      Notification({
        message: "",
        description: error.response?.data?.error || "Unable to reach your mailbox",
        type: NotificationTypes.ERROR,
      });
      onError()
    } finally {
      onFinal()
    }

  }
  static resetPassword = async (
    data: {
      email: string,
      password: string,
      token: string,
    },
    onSuccess: Function,
    onError: Function,
    onFinal: Function) => {
    try {
      const payload = {
        user: {
          ...data,
          new_password: data.password,
          reset_password_token: data.token
        }
      }
      const response = await axiosInstance.put(RESET_PASSWORD, payload)
      Notification({
        message: "",
        description: "Password reset successful",
        type: NotificationTypes.SUCCESS,
      });
      onSuccess()
    } catch (error) {
      Notification({
        message: "",
        description: error.response?.data?.error || "Unable to reset password",
        type: NotificationTypes.ERROR,
      });
      onError()
    } finally {
      onFinal()
    }

  }
  static login(data: any, onSuccess: Function, final: () => void) {
    const serializeData = serialize(LoginModel, data);
    const payload = { user: serializeData };
    return axiosInstance
      .post(LOGIN, payload)
      .then((response) => {
        const details = deserialize(ProfileModel, response["data"]["user"]);
        // if (details.companyStatus == "setup_complete" || details.role.includes("hl")) {
        LocalStorage.setItem("USER", details);
        store.dispatch({
          type: LOGIN,
          payload: details,
        });
        // }
        onSuccess(details);
        const token = response["data"]["token"];
        LocalStorage.setItem("ACCESS_TOKEN", token?.access_token);
        LocalStorage.setItem("REFRESH_TOKEN", token?.refresh_token);
      })
      .catch((error) => {
        // Notification({
        //   message: "Login failed",
        //   description: "Incorrect username or password!",
        //   type: NotificationTypes.ERROR,
        // });
      }).finally(final);
  }

  static changePassword(
    user: any,
    onSuccess: Function,
    onError: Function,
    onFinal: Function
  ) {
    axiosInstance.put(CHANGE_PASSWORD, { user })
      .then(response => {
        Notification({
          type: NotificationTypes.SUCCESS,
          message: `Hedge ${user.isAdmin ? "Legal" : "Fund"}`,
          description: response.data["success"]
        })
        onSuccess()
      }).catch(() => {
        onError()
      }).finally(() => {
        onFinal()
      })
  }
  // static logOut(
  //   onSuccess: Function,
  //   onError: Function,
  // ) {
  //   return axiosInstance.
  // }
}
