export const downloadAllChoices = [
    { label: "All Agreements", value: 'all' },
    { label: "All Ancillary Documents", value: 'ancillary_documents' },
    { label: "All Amendments", value: 'amendments' },
    { label: "All Archieved Documents", value: 'archived_documents' },
    { label: "All Related Documents", value: 'related_agreements' },
]
export const sittingWithOptions = [
    { label: "Manager", value: 'manager' },
    { label: "Hedge Legal", value: 'hedge_legal' },
    { label: "CP", value: 'cp' },
]
export const agreementTiers = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
]
export const agreementProgress = [
    { label: "10 %", value: "10" },
    { label: "20 %", value: "20" },
    { label: "30 %", value: "30" },
    { label: "40 %", value: "40" },
    { label: "50 %", value: "50" },
    { label: "60 %", value: "60" },
    { label: "70 %", value: "70" },
    { label: "80 %", value: "80" },
    { label: "90 %", value: "90" },
    { label: "100 %", value: "100" },
]