import { KeyTermGroups } from "./../../models/KeyTerms/keyTermGroups.model";
import {
  ANCILLARY_STATUS,
  DASHBOARD_STATS_HL,
  GET_USER_ROLES,
  KEY_TERM_GROUPS,
  STUB_BASE_URL,
} from "./../../routes/apiRoutes";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import {
  AGREEMENT_STATUS,
  CITIES,
  COUNTRIES,
  HF_META_ACCESSIBLE_USERS,
  HF_META_ONBOARDING_ITEM,
  HF_META_ONBOARDING_STATUS,
  HF_META_OTHER_STATUS,
  HL_META_ACCESSIBLE_USERS,
  PROVINCES,
  SUBSCRIBE_PLAN,
} from "../../routes/apiRoutes";
import { RegionMetaModel } from "../../models/Meta/meta.model";
import { generatePath } from "react-router";
import { CompanyModel } from "../../models/HFund/hfund.model";
import { SubscriptionModel } from "../../models/Auth/auth.model";
export class MetaService {
  static listCountries(onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(COUNTRIES)
      .then((response) => {
        const countries = deserialize(
          RegionMetaModel,
          response["data"]["countries"]
        );
        onSuccess(countries);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static listProvinces(countryId: any, onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(generatePath(PROVINCES, { countryId }))
      .then((response) => {
        const countries = deserialize(
          RegionMetaModel,
          response["data"]["provinces"]
        );
        onSuccess(countries);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static listCities(
    countryId: any,
    provinceId: any,
    onSuccess: Function,
    onError: Function
  ) {
    return axiosInstance
      .get(generatePath(CITIES, { countryId, provinceId }))
      .then((response) => {
        const countries = deserialize(
          RegionMetaModel,
          response["data"]["cities"]
        );
        onSuccess(countries);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static async subscribeService(
    companyId: any,
    data: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void,
  ) {

    try {
      const payload = serialize(CompanyModel, data);
      const API_URL = generatePath(SUBSCRIBE_PLAN, { companyId });
      const response = await axiosInstance.put(API_URL, payload);
      onSuccess(response);
    } catch (error) {
      console.log(error?.message);
      onError(error);
    } finally {
      onFinal();
    }
  }

  static async rolesService(
    userType: "hl" | "hf",
    onSuccess: Function,
    onError: Function,
    onFinal: () => void,
  ) {

    try {
      const API_URL = generatePath(GET_USER_ROLES, { userType });
      const response = await axiosInstance.get(API_URL);
      onSuccess(response.data["roles"]);
    } catch (error) {
      console.log(error?.message);
      onError(error);
    } finally {
      onFinal();
    }
  }

  static onboardingItemStatus(onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(generatePath(HF_META_ONBOARDING_STATUS))
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static onboardingItemMeta(
    category: string,
    onSuccess: Function,
    onError: Function
  ) {
    return axiosInstance
      .get(generatePath(HF_META_ONBOARDING_ITEM), {
        params: {
          category,
        },
      })
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static otherItemStatus(onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(generatePath(HF_META_OTHER_STATUS))
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static responsibleHFUsers(
    fundId: string,
    onSuccess: Function,
    onError: Function
  ) {
    return axiosInstance
      .get(generatePath(HF_META_ACCESSIBLE_USERS.replace(":fundId", fundId)))
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static responsibleHLUsers(
    fundId: string,
    onSuccess: Function,
    onError: Function
  ) {
    return axiosInstance
      .get(generatePath(HL_META_ACCESSIBLE_USERS.replace(":fundId", fundId)))
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static agreementStatus(onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(generatePath(AGREEMENT_STATUS))
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static ancillaryStatus(onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(generatePath(ANCILLARY_STATUS))
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static keyTermGroups(
    agreementTypeId: number | string,
    params: {
      search_text?: string;
    },
    onSuccess: Function,
    onError: Function
  ) {
    return axiosInstance
      .get(
        generatePath(
          KEY_TERM_GROUPS.replace(":agreementTypeId", String(agreementTypeId))
        ),
        { params }
      )
      .then((response) => {
        const data = deserialize(
          KeyTermGroups,
          response.data["key_term_groups"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static async getHLStatics() {
    try {
      const { data } = await axiosInstance.get(DASHBOARD_STATS_HL);
      return data;
    } catch (error) {

    }
  }
}
