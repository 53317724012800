import { serializable, alias, primitive, list, object } from 'serializr';

export class ReportsAgreement {

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('fund_name', primitive()))
    fundName?: string;

    @serializable(alias('date', primitive()))
    date?: string;

    @serializable(alias('cp_name', primitive()))
    cpName?: number;
}

export class ReportsKeyTermGroup {

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: string;
}

export class Report {
    @serializable(alias('report_id', primitive()))
    id?: number;

    @serializable(alias('report_date', primitive()))
    date?: number;

    @serializable(alias('agreement_type', primitive()))
    agreementType?: number;

    @serializable(alias('report_agreements', primitive()))
    agreementSelection?: string;

    @serializable(alias('key_terms', primitive()))
    keyTerms?: string;

    @serializable(alias('agreements', list(object(ReportsAgreement))))
    agreements?: ReportsAgreement[];

    @serializable(alias('key_term_groups', list(object(ReportsKeyTermGroup))))
    keyTermGroups?: ReportsKeyTermGroup[];

}

class ReportResultsAgreement {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("agreement_id", primitive()))
  agreementId?: number;

  @serializable(alias("value", primitive()))
  value?: number;
  @serializable(alias("outlier_status", primitive()))
  outlierStatus?: boolean;
}

export class ReportResults {

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('agreement_key_terms', list(object(ReportResultsAgreement))))
    agreementKeyTerms?: ReportResultsAgreement[];

}
export class ReportHistory {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('report_date', primitive()))
    date?: string;

    @serializable(alias('agreement_type', primitive()))
    agreementType?: string;

    @serializable(alias('agreements', primitive()))
    agreements?: string | number;

    @serializable(alias('key_terms', primitive()))
    keyTerms?: string;

    @serializable(alias('company_name', primitive()))
    companyName?: string;

}