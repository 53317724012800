import { generatePath } from "react-router";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { ProfileModel } from "../../models/HFund/hfund.model";
import { HF_USERS, HL_USERS, MANAGE_HL_USER, STUB_BASE_URL, SET_MFA, AUTHENTICATE_OTP, ENABLE_DISABLE_MFA, GENERATE_BACKUP_CODES } from "../../routes/apiRoutes";

export class UserService {
  static listAllUsers(params: any, onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(HL_USERS, {
        params: params
      })
      .then((response) => {
        const users = deserialize(ProfileModel, response["data"]["users"]);
        onSuccess(users);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static createUser(data: any, onSuccess: Function, onError: Function) {
    const serializeData = serialize(ProfileModel, data)
    const payload = { user: serializeData }
    return axiosInstance
      .post(HL_USERS, payload)
      .then((response) => {
        // const users = deserialize(ProfileModel, response["data"]["users"]);
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      });
  }
  static updateUser(userId: number, data: any, onSuccess: Function, onError: Function) {
    const serializeData = serialize(ProfileModel, data)
    const payload = { user: serializeData }
    return axiosInstance
      .put(generatePath(MANAGE_HL_USER, { userId }), payload)
      .then((response) => {
        // const users = deserialize(ProfileModel, response["data"]["users"]);
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      });
  }
  static deleteUser(userId: number, onSuccess: Function, onError: Function) {
    return axiosInstance
      .delete(generatePath(MANAGE_HL_USER, { userId }))
      .then((response) => {
        // const users = deserialize(ProfileModel, response["data"]["users"]);
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      });
  }
  static listAllHFUsers(params: any, onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(HF_USERS, {
        params: params
      })
      .then((response) => {
        const users = deserialize(ProfileModel, response["data"]["users"]);
        onSuccess(users);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static setUpMultiFactorAuthentication(userId: number, onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(generatePath(SET_MFA))
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static authenticateOtp(otp_number: any, onSuccess: Function, onError: Function) {
    const payload = { otp_number: otp_number }
    return axiosInstance
      .post(AUTHENTICATE_OTP, payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static enabled_disable_mfa(otp_number: any, onSuccess: Function, onError: Function) {
    const payload = { otp_number: otp_number }
    return axiosInstance
      .post(ENABLE_DISABLE_MFA, payload)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static generate_backup_code(onSuccess: Function, onError: Function) {
    return axiosInstance
      .get(GENERATE_BACKUP_CODES)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

}
