
export enum FundDetailsTabs {
    AGREEMENTS = "Agreements",
    ONBOARDING = "Onboarding",
    GENERAL_INFO = "General Info",
    OTHER_ITEMS = "Other Items"
}
export enum NotesAndNotificationServerItems {
    OTHER_ITEMS = "OtherItem",
    ONBOARDING = "OnboardingItem",
}

export enum OnboardingCategoriesEnum {
    GENERAL = "general",
    ISDA = "isda",
}
