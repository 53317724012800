import React from "react";
import "./partnerCard.scss"
import { Row, Col, Divider, Menu, Dropdown } from "antd";
import { Partner } from "../../../models/Partner/partner.model";

interface PartnerCardProps {
    partner: Partner
    isAdmin: boolean
    editHandler: (partner: Partner) => void
    deleteHandler: (partner: Partner) => void
}

const PartnerCard = (props: PartnerCardProps) => {

    const {
        isAdmin,
        editHandler,
        deleteHandler,
        partner: {
            name,
            logoUrl,
            description,
            services,
            address,
        }
    } = props

    const addressArray = [address?.street, address?.addressLine, address?.city, address?.province, address?.country]

    const menu = (
        <Menu theme="dark">
            <Menu.Item key="0" className="edit-menu-item" onClick={() => editHandler(props.partner)}>
                <i className="icon-edit"></i>Edit
            </Menu.Item>
            <Menu.Item key="1" className="delete-menu-item" onClick={() => deleteHandler(props.partner)}>
                <i className="icon-delete"></i>Delete
            </Menu.Item>
        </Menu>
    );

    return <Row className="partner-card" wrap={false}>
        <Col className="partner-card__logo">
            <img src={logoUrl} alt={name} />
        </Col>
        <Col className="partner-card__desc--cover">
            <Row className="partner-card__desc">
                <Col span={10}>
                    <h1 className="partner-name">{name}</h1>
                    <p className="partner-address">{addressArray.join(", ")}</p>
                </Col>
                <Col span={2}>
                    <Divider type="vertical" />
                </Col>
                <Col span={12}>
                    <p className="partner-service-title">Service provided</p>
                    <p className="partner-services">{services?.length ? services.join(", ") : "-"}</p>
                </Col>
                <Col span={24}>{description}</Col>
            </Row>
        </Col>
        {isAdmin && <div className="partner-card--actions">
            <Dropdown overlay={menu} trigger={['click']}>
                <i className="icon-more"></i>
            </Dropdown>
        </div>}
    </Row>
}

export default PartnerCard