import { Button, Col, Row, Tabs } from "antd";
import React, { FC, useEffect, useState } from "react";
import { Service } from "../../../../../models/Service/service.model";
import { AuthService } from "../../../../../services/AuthService/auth.service";
import AppModal from "../../../../../shared/components/AppModal";
import { PageHeaderComponent } from "../../../../../shared/components/PageHeader";
import PaymentLogs from "./PaymentLogs";
import "./subscription.scss";
import SubscriptionForm from "./SubscriptionForm";
import SubscriptionPackages from "./SubscriptionPackages";

const { TabPane } = Tabs;

interface SubscriptionManagementProps { }

const SubscriptionManagement: FC<SubscriptionManagementProps> = (props) => {
  const { } = props;

  const [services, setServices] = useState<Service[]>([]);

  const [reload, setReload] = useState(false);

  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    AuthService.listSubscriptionServices(
      (services) => {
        setServices([
          { id: "costPerMonth", name: "Cost per annum", deletable: false },
          { id: -2, name: "# of Agreements", deletable: false },
          { id: -3, name: "# of Funds", deletable: false },
          { id: -4, name: "# of Users", deletable: false },
          ...services]);
      },
      () => { }
    );
  }, []);

  const handleReload = () => setReload((reload) => !reload);

  return (
    <div className="hl-subscription">
      <PageHeaderComponent
        title="Subscription Management"
        buttons={<AppModal
          title="Add New Subscription Package"
          triggerComponent={(openModal) => (
            <Button
              type="primary"
              icon={<i className="icon-add" />}
              onClick={openModal}
            >
              {" "}
              Add New Subscription
            </Button>
          )}
          renderComponent={(closeModal) => (
            <SubscriptionForm
              services={services.slice(4)}
              successHandler={() => {
                handleReload();
                closeModal();
              }}
              closeHandler={closeModal}
            />
          )}
        />}
      />

      <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Subscription Packages" key="1">
          {Boolean(services?.length) && (
            <SubscriptionPackages services={services} reload={reload} />
          )}
        </TabPane>
        {/* <TabPane tab="Payment Logs" key="2">
          <PaymentLogs />
        </TabPane> */}
      </Tabs>
    </div>
  );
};

export default SubscriptionManagement;
