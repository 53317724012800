import { Col, Row } from "antd";
import { Form, Formik } from "formik";
import React, { FC, createRef } from "react";
import "./otpInput.scss";
import NumberInput from "./NumberInput";

interface OTPInputProps {
  count: number;
  onChange: (value: any) => void;
}

const OTPInput: FC<OTPInputProps> = ({ count, onChange }) => {
  const generateValues = () => {
    let objects: any = {};
    for (var x = 0; x < count; x++) {
      objects[x] = "";
    }
    return objects;
  };
  const initialValues = generateValues();

  // const onSubmit = (values: any) => {
  //   const otp = Object.values(values).join("");
  //   //console.log(otp);
  // };
  const refs = Array(count)
    ?.fill("")
    ?.map(() => createRef());
  return (
    <div className="otp-input__container">
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        {({ setFieldValue, values }) => (
          <Form>
            <Row>
              <Col span={24} className="otp-wrapper">
                {Array(count)
                  ?.fill("")
                  ?.map((_, index) => (
                    <NumberInput
                      index={index}
                      refs={refs}
                      setFieldValue={(key, value) => {
                        setFieldValue(key, value);
                        let otp = { ...values, [key]: value };
                        onChange(Object.values(otp).join(""));
                      }}
                      values={values}
                    />
                  ))}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OTPInput;
