import { Card, Table, Tooltip } from "antd";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { FundStatusBE } from "../../../../../enums/fundStatuses";
import { Fund } from "../../../../../models/Fund/fund.model";
import {
  FundsService,
  HLFundsService,
} from "../../../../../services/FundService/Fund.service";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";
import HLFundForm from "../../../../HL/Home/Funds/FundForm";
import HFFundForm from "../../../../HF/Home/Funds/FundForm";
import { generatePath, useHistory } from "react-router";
import { FUNDS_SPECIFIC } from "../../../../../routes/appRoutes";
import { aumConvertor } from "../../../../../shared/utils/stringToNumber";
import Notification from "../../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../../enums/notificationTypes";

interface FundProps {
  companyId?: string
}

interface FundLoader {
  preFunds: boolean;
  activeFunds: boolean;
}

const Funds: FC<FundProps> = (props) => {
  const { companyId } = props;

  const history = useHistory();

  const [perFunds, setPreFunds] = useState<Fund[]>([]);

  const [activeFunds, setActiveFunds] = useState<Fund[]>([]);

  const [formVisibility, setFormVisibility] = useState(false);

  const [loading, setLoading] = useState<FundLoader>({
    activeFunds: false,
    preFunds: false,
  });

  const user = LocalStorage.getItem("USER");

  const isAdmin = user?.role?.includes("hl");

  const Columns = [
    ...(isAdmin && [{
      title: "HF Name",
      dataIndex: "companyName",
      key: "companyName",
      ellipsis: { showTitle: false },
      render: (name: string) => <Tooltip title={name}>{name}</Tooltip>
    }]),
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
  ];

  const preFundColumns = [
    {
      title: "Launch date",
      dataIndex: "targetLaunchDate",
      key: "targetLaunchDate",
      width: 100,
      render: (date: string) => date ? moment(date).format("MMM D, YYYY") : "-",
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      className: "text-right",
      render: (progress) => <span>{(progress || 0) + "%"} &nbsp;&nbsp;&nbsp;</span>,
    },
  ];

  const activeFundColumns = [
    {
      title: "AUM",
      dataIndex: "aum",
      key: "aum",
      className: "text-right",
      render: (aum: number) => <div className="aum">{aumConvertor(aum) || "-"}</div>,
    },
  ];

  const onFundCreation = (fund: Fund) => {
    if (!fund) {
      return;
    }
    if (fund.status === FundStatusBE.ACTIVE) {
      setActiveFunds((existingFunds) => [fund, ...existingFunds]);
    } else if (fund.status === FundStatusBE.PRELAUNCH) {
      setPreFunds((existingFunds) => [fund, ...existingFunds]);
    }
  };

  useEffect(() => {
    setLoading({
      activeFunds: true,
      preFunds: true,
    });
    (isAdmin ? HLFundsService : FundsService).fetchFunds(
      {
        status: FundStatusBE.PRELAUNCH,
        company_ids: companyId ? [companyId] : [],
        order_by: "recently_viewed",
        page: 1,
        per_page: 3,
        order_direction: "dsc",
      },
      (preFunds: Fund[]) => {
        setPreFunds(preFunds);
      },
      () => { },
      () => {
        setLoading((loaders) => ({
          ...loaders,
          preFunds: false,
        }));
      }
    );
    (isAdmin ? HLFundsService : FundsService).fetchFunds(
      {
        status: FundStatusBE.ACTIVE,
        company_ids: companyId ? [companyId] : [],
        order_by: "recently_viewed",
        page: 1,
        per_page: 3,
        order_direction: "dsc",
      },
      (preFunds: Fund[]) => {
        setActiveFunds(preFunds);
      },
      () => { },
      () => {
        setLoading((loaders) => ({
          ...loaders,
          activeFunds: false,
        }));
      }
    );
  }, []);

  const handleRedirectToFunds = (fund: Fund) => {
    return {
      onClick: () => {
        const URL = generatePath(FUNDS_SPECIFIC, {
          fundId: fund.id,
        });
        history.push(URL + "?companyId=" + fund?.companyId);
      },
    };
  };

  return (
    <Card
      className="funds"
      title="Funds"
      extra={
        <span onClick={() => {
          if (user.accessToRead && (user?.userCompanies.filter((company) => company.companyId == parseInt(companyId))?.length == 0)) {
            Notification({
              message: "Hedge Legal",
              description: "Access Restricted",
              type: NotificationTypes.ERROR,
            });
          } else {
            setFormVisibility(true)
          }
        }}>
          <i className="icon-add" /> Add New
        </span>
      }
    >
  
      <h3 className="mt-5">Active Funds by recently viewed</h3>
      <Table
        pagination={false}
        dataSource={activeFunds.slice(0, 3)}
        columns={[...Columns, ...activeFundColumns]}
        loading={loading.activeFunds}
        bordered
        onRow={handleRedirectToFunds}
      />
      
      <h3>Pre-Launch by launch date</h3>
      <Table
        className="pre-fund-table"
        pagination={false}
        dataSource={perFunds.slice(0, 3)}
        columns={[...Columns, ...preFundColumns]}
        loading={loading.preFunds}
        bordered
        onRow={handleRedirectToFunds}
      />
      {isAdmin ? (
        <HLFundForm
          title={"Add new fund"}
          visible={isAdmin && formVisibility}
          disableTerminatedStatus
          closeHandler={(newFund?: Fund) => {
            onFundCreation(newFund);
            setFormVisibility(false);
          }}
        />
      ) : (
        <HFFundForm
          title={"Add new fund"}
          visible={!isAdmin && formVisibility}
          disableTerminatedStatus
          closeHandler={(newFund?: Fund) => {
            onFundCreation(newFund);
            setFormVisibility(false);
          }}
        />
      )}
    </Card>
  );
};

export default Funds;
