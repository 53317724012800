import { Col, Dropdown, Menu, Row, Skeleton, Switch } from "antd";
import React, { Dispatch, FC, useEffect, useState } from "react";
import EditIcon from "../../../../../../assets/icons/editWhite.svg";
import { EllipsisOutlined } from "@ant-design/icons";
import faker from "faker";
import "./cpGroupInfo.scss";
import CPCard from "../../CPCard";
import { useHistory, useLocation, useParams } from "react-router";
import { CP_DETAILS } from "../../../../../../routes/appRoutes";
import CounterPartiesGroupsService from "../../../../../../services/CounterPartiesService/CounterPartiesGroups.service";
import { CounterPartiesGroups } from "../../../../../../models/CounterParties/counterPartiesGroups.model";
import CPForm from "../../CPForm";

interface CPGroupInfoProps {
  setCounterPartiesIdOfCounterPartyGroup: Dispatch<number[]>
  setCounterPartyGroup: Function
}

export const CPGroupInfo: FC<CPGroupInfoProps> = (props) => {
  const history = useHistory();
  const {
    setCounterPartyGroup,
    setCounterPartiesIdOfCounterPartyGroup
  } = props
  const { cpGroupId } = useParams<any>()
  const [cpGroup, setCpGroup] = useState<CounterPartiesGroups>();
  const [showSkeletonLoading, setSkeletonLoading] = useState(true);
  const { state }: { state: any } = useLocation();
  const [showCPForm, setFormVisibility] = useState(false);
  const fetchCounterPartyGroup = () => {
    CounterPartiesGroupsService.showCounterPartiesGroup(
      cpGroupId,
      (response: CounterPartiesGroups) => {
        setCounterPartyGroup(response.name)
        setCpGroup(response);
        setCounterPartiesIdOfCounterPartyGroup(response.counterParties.map(cp => cp.id));
        setSkeletonLoading(false);
      },
      () => { },
      () => { }
    );
  };
  useEffect(() => {
    fetchCounterPartyGroup();
  }, []);
  return (
    <Row className="cp-group-info__container" gutter={[24, 24]}>
      <CPForm
        category={"cp_group"}
        visible={showCPForm}
        closeHandler={() => {
          fetchCounterPartyGroup()
          setFormVisibility(false);
        }}
        data={cpGroup}
      />
      <Col span={24}>
        <div className="cp-details__container">
          {showSkeletonLoading ? (
            <Skeleton active avatar={{ size: 100 }} paragraph={{ rows: 2 }} />
          ) : (
            <>
              <div className="cp-image__wrapper">
                <img src={cpGroup?.logoUrl} />
              </div>
              <div className="cp-body__wrapper">
                <div className="cp-details__wrapper">
                  <p className="cp__name">{cpGroup?.name}</p>
                </div>
                <p className="cp__desc">{cpGroup?.description}</p>
              </div>
              <Dropdown
                trigger={["click"]}
                placement={"topRight"}
                overlay={
                  <Menu className="cp__menu">
                    <Menu.Item
                      onClick={() => setFormVisibility(true)}
                    >
                      <p>
                        <span>
                          <img src={EditIcon} />
                        </span>
                        Edit
                      </p>
                    </Menu.Item>
                    <Menu.Item>
                      <p>
                        <span>
                          <Switch
                            defaultChecked
                            size={"small"}
                            onChange={() => { }}
                          />
                        </span>
                        Enabled
                      </p>
                    </Menu.Item>
                  </Menu>
                }
              >
                <span>
                  <EllipsisOutlined rotate={90} />
                </span>
              </Dropdown>
            </>
          )}
        </div>
      </Col>
      {cpGroup?.counterParties?.map((value, index) => {
        return (
          <Col span={12}>
            <CPCard
              cpLogo={value.logoUrl}
              cpName={value.name}
              cpAddress={`${value.address?.street}, ${value.address?.addressLine2}, ${value.address?.cityName}, ${value.address?.provinceName}, ${value.address?.countryName}`}
              cpRating={value.totalRating}
              cpReviews={value.reviewCount}
              cpProducts={value.productsAvailable}
              onClick={() =>
                history.push(CP_DETAILS.replace(':cpId', value?.id?.toString()), {
                  name: value.name,
                })
              }
            />
          </Col>
        );
      })}
    </Row>
  );
};
