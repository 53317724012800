import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import * as LayoutActions from "../actions/layout";
import { RootReducerProps } from "../reducers";
import { LayoutProps } from "../reducers/layoutReducer";

const mapStateToProps = (
  state: RootReducerProps,
  ownProps: any
): LayoutProps => {
  return {
    title: state.layout.title,
    component: state.layout.component,
    doxCoins: state.layout.doxCoins,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(LayoutActions, dispatch);

const LayoutContainer = (component: any) => {
  return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default LayoutContainer;
