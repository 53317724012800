import { Button, Dropdown, Input, Menu, Space, Tooltip } from "antd";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { TableComponent } from "../../../../../shared/components/TableComponent";
import moment from "moment";
import "./listAgreements.scss";
import PopoverComponent from "../../../../../shared/components/PopoverComponent";
import KeyContacts from "../../../../../shared/components/KeyContacts";
import TableNotes from "../../../../../shared/components/TableNotes";
import TableNotifications from "../../../../../shared/components/TableNotifications";
import { Agreement } from "../../../../../models/Agreements/agreement.model";
import AgreementForm from "../AgreementForm";
import { ConfirmationAlert } from "../../../../../shared/components/ConfirmationAlert";
import { NotificationTypes } from "../../../../../enums/notificationTypes";
import Notification from "../../../../../shared/components/Notification";
import { HFAgreementService } from "../../../../../services/AgreementService/agreement.service";
import { AGREEMENTS_SPECIFIC } from "../../../../../routes/appRoutes";
import { useHistory } from "react-router";
import {
  CaretDownFilled,
  CaretDownOutlined,
} from "@ant-design/icons";
import { ResponsibleUser } from "../../../../../models/Fund/onboarding.model";
import {
  agreementProgress,
  agreementTiers,
  sittingWithOptions,
} from "../agreementsDefinitions";
import { deserialize } from "serializr";
import { MetaService } from "../../../../../services/MetaService/meta.service";
import { generateStatusStyle } from "../../../../../shared/utils/statusStyleGenerator";
import TableFilterDropDown from "../../../../../shared/components/TableFilterDropDown";
import { dateSorter, sorter } from "../../../../../shared/utils/tableSorter";
import { fileDownloadFromUrl } from "../../../../../shared/utils/fileDownloader";
import { NotificationType } from "../../../../../enums/notificationType";
import { PaginationMeta } from "../../../../../models/Meta/pagination-meta.model";
import FilterListing from "../../../../../shared/components/FilterListing";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";
import { roles } from "../../../../../shared/components/HOC/roles";

interface AgreementsListProps {
  funds: any[];
  data: Agreement[];
  counterParties: any[];
  agreementStatus: any[];
  agreementTypes: any[];
  showAdd?: boolean;
  showSearch?: boolean;
  loading?: boolean;
  onAdd?: () => void;
  filters?: any
  pagination?: PaginationMeta
  onRow?: (index: number) => void;
  closeHandler?: Function;
  additionalButtons?: ReactNode;
  updateFilter?: Function;
}

const AgreementsList: FC<AgreementsListProps> = (props) => {
  const {
    onRow = () => { },
    additionalButtons,
    showAdd,
    showSearch,
    data,
    loading,
    onAdd,
    closeHandler,
    agreementStatus,
    counterParties,
    funds,
    pagination,
    agreementTypes,
    updateFilter,
    filters,
  } = props;
  const history = useHistory();
  const [visible, setVisible] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const [agreements, setAgreements] = useState<Agreement[]>(data);
  const [selectedAgreement, setSelectedAgreement] = useState<Agreement>();
  const [showDelete, setDeleteVisibility] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [responsibleUsers, setResponsibleUsers] = useState<any[]>([]);
  const [availableDates, setAvailableDates] = useState<any[]>([]);
  const [sortInfo, setSortInfo] = useState<{ key: string; order: string }>();
  const [filterInfo, setFilterInfo] = useState<any>({});
  const user = LocalStorage.getItem("USER");
  const isHFUser = [roles.HF_USER, roles.HF_ADMIN, roles.HF_SUPER_ADMIN].includes(user?.role);

  

  const completionTypes = [
    {
      label: "Yes",
      value: "true",
    },
    {
      label: "No",
      value: "false",
    },
  ];

  useEffect(() => {
    setTableLoading(true);
    if (data) {
      setTableLoading(false);
      setAgreements(data);
      const allAvailableDates = data.map((agreement) => agreement.date);
      if (!availableDates?.length && allAvailableDates?.length) {
        const uniqueDates = [];
        allAvailableDates.forEach((date) => {
          if (!uniqueDates.includes(date) && !!date) {
            uniqueDates.push(date);
          }
        });
        setAvailableDates(
          uniqueDates.map((date) => {
            return {
              label: moment(date).format("DD MMM, YYYY"),
              value: date,
            };
          })
        );
      }
    } else {
      setTableLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (selectedAgreement?.fundId) {
      fetchResponsibleUsers(selectedAgreement.fundId.toString());
    }
  }, [selectedAgreement]);

  useEffect(() => {
    if (updateFilter) {
      setTableLoading(true);
      updateFilter({ status: filters?.status, type: filters?.type, ...filterInfo, page: filterInfo?.page || 1 });
    }
  }, [filterInfo]);

  const deleteHandler = (agreement: Agreement) => {
    HFAgreementService.deleteAgreement(
      agreement,
      (res) => {
        closeHandler(agreement, filterInfo);
        Notification({
          message: "Hedge Fund",
          description: "Agreement deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
      },
      (err) => {
        // ;
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Agreement deletion failed",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => {
        setDeleteVisibility(false);
      }
    );
  };

  const updateMenu = (agreement: Agreement, key: string, options) => {
    const availableOptions = options.filter(
      (option) => option.value !== agreement[key]
    );
    return (
      <Menu>
        {availableOptions?.map((option) => (
          <Menu.Item
            key={option.value}
            onClick={(e) => {
              e.domEvent.stopPropagation()
              const updatedAgreement = {
                ...agreement,
              };
              updatedAgreement[key] = option?.value;
              updateAgreement(updatedAgreement);
            }}
          >
            {option?.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const responsibleUpdateMenu = (agreement: Agreement) => {
    setSelectedAgreement(agreement);
    return updateMenu(agreement, "responsibleUserId", responsibleUsers);
  };

  const updateAgreement = (values: Agreement) => {
    setTableLoading(true);
    HFAgreementService.updateAgreement(
      values,
      (response) => {
        closeHandler(response, filterInfo);
      },
      (error) => {
        //console.log(error);
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to update agreement",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => {
        setTableLoading(false);
      }
    );
  };

  const fetchResponsibleUsers = (fundId: string) => {
    MetaService.responsibleHFUsers(
      fundId,
      (res) => {
        const data = deserialize(
          ResponsibleUser,
          res.data["users"]
        ) as ResponsibleUser[];
        setResponsibleUsers(
          data.map((data) => {
            return {
              value: data.id,
              label: data.firstName + " " + data.lastName,
            };
          })
        );
      },
      (err) => {
        // ;
      }
    );
  };

  const handleClearFilters = () => {
    setFilterInfo(Object.create({ status: "", type: '', agreement_status_ids: [], counter_party_ids: [], fund_ids: [], dates: [], agreement_type_ids: [], tiers: [], progress_percents: [], sitting_with: [] }));
    setSortInfo(Object.create({}));
  };

  const sortHandler = (key: string, order: string) => {
    setSortInfo({
      key,
      order,
    });
  };

  const filterHandler = (key: string, value: any) => {
    const currentFilter = { ...filterInfo };
    currentFilter[key] = Array.isArray(value) ? Array.from(new Set(value)) : value;
    if (JSON.stringify(filterInfo) === JSON.stringify(currentFilter)) {
      return
    }
    setFilterInfo({ ...currentFilter, search_text: search });
  };
  const filterProperties = (dataIndex: string, sortIndex: string, records) => {
    return {
      filterDropdown: (filterProps) => (
        <TableFilterDropDown
          {...filterProps}
          defaultSelected={[...(filterInfo[dataIndex] || []), ...((filters || {})[dataIndex] || [])]}
          onApply={(key, value) => filterHandler(key, value)}
          sortIndex={sortIndex}
          dataIndex={dataIndex}
          checkboxValues={records}
          onSort={sortHandler}
        />
      ),
      filterIcon: () => <CaretDownOutlined />,
    };
  };

  const handleSearch = (search_text = "") => {
    if (updateFilter)
      setFilterInfo({ ...filters, ...filterInfo, search_text, page: 1 });
    setSearch(search_text)
  }
  
  const dataMap = [
    { key: "fund_ids", value: funds, label: "Fund" },
    { key: "counter_party_ids", value: counterParties, label: "CP Name" },
    { key: "agreement_type_ids", value: agreementTypes, label: "Type" },
    { key: "dates", value: availableDates, label: "Date" },
    { key: "tiers", value: agreementTiers, label: "Tier" },
    { key: "agreement_status_ids", value: agreementStatus, label: "Status" },
    { key: "progress_percents", value: agreementProgress, label: "Progress" },
    { key: "sitting_with", value: sittingWithOptions, label: "Sitting With" },
    { key: "completed", value: completionTypes, label: "Completed" }
  ];
  

  return (
    <>
     <FilterListing
      filterInfo={filterInfo}
      dataMap={dataMap}
      setFilterInfo={setFilterInfo}
    />
      <TableComponent
        className="sort-remover row-clickable"
        onRow={(index: number, record) => {
        const url = AGREEMENTS_SPECIFIC.replace(":agreementId", record.id.toString());
        const newWindow = window.open(url, "_blank");
        newWindow && (newWindow.opener = null);
        }}
        loading={tableLoading || loading}
        titleHelper={
          <span className="title-helper" onClick={handleClearFilters}>
            Clear All Filters
          </span>
        }
        showAdd={!isHFUser}
        onAdd={onAdd}
        showSearch={showSearch}
        onSearch={handleSearch}
        // onChange={pagination => updateFilter && updateFilter({ ...filterInfo, search_text: search, page: pagination?.current })}
        onChange={pagination => setFilterInfo({ ...filters, ...filterInfo, search_text: search, page: pagination?.current })}
        pagination={{
          pageSize: 20,
          total: pagination?.totalCount,
          current: pagination?.currentPage,
          showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
        }}
        scroll={{ x: 1850, y: 'calc(100vh - 140px - 60px)' }}
        title={"Agreements"}
        columns={[
          {
            title: "FUND",
            dataIndex: "fundName",
            key: "fundName",
            width: 200,
            sorter: (a, b) => sorter(a.fundName, b.fundName),
            sortOrder: sortInfo?.key === "fundName" && sortInfo?.order,
            ...filterProperties("fund_ids", "fundName", funds),
            render: (text) => <Tooltip placement="topLeft" title={text || ""}>
              <span>{text ?? ""}</span>
            </Tooltip>
          },
          {
            title: "CP NAME",
            dataIndex: "cpName",
            key: "cpName",
            width: 150,
            sorter: (a, b) => sorter(a.cpName, b.cpName),
            sortOrder: sortInfo?.key === "cpName" && sortInfo?.order,
            ...filterProperties("counter_party_ids", "cpName", counterParties),
            render: (text) => <Tooltip placement="topLeft" title={text || ""}>
              <span>{text ?? ""}</span>
            </Tooltip>
          },
          {
            title: "AGREEMENT NAME",
            key: "name",
            dataIndex: "name",
            width: 200,
            render: (text) => <Tooltip placement="topLeft" title={text || ""}>
              <span>{text ?? ""}</span>
            </Tooltip>
          },
          {
            title: "TYPE",
            key: "agreementTypeName",
            dataIndex: "agreementTypeName",
            width: 150,
            sorter: (a, b) => sorter(a.agreementTypeName, b.agreementTypeName),
            sortOrder: sortInfo?.key === "agreementTypeName" && sortInfo?.order,
            ...filterProperties(
              "agreement_type_ids",
              "agreementTypeName",
              agreementTypes
            ),
            render: (text) => <Tooltip placement="topLeft" title={text?.replaceAll("_", " ") || ""}>
              <span>{text && text.replaceAll("_", " ")}</span>
            </Tooltip>
          },
          {
            title: "DATE",
            dataIndex: "date",
            key: "date",
            width: 150,
            sorter: (a, b) => dateSorter(a.date, b.date),
            sortOrder: sortInfo?.key === "date" && sortInfo?.order,
            ...filterProperties("dates", "date", availableDates),
            render: (text) => (
              <span>{text ? moment(text).format("DD MMM, YYYY") : "--"}</span>
            ),
          },
          {
            title: "TIER",
            dataIndex: "tier",
            key: "tier",
            width: 100,
            sorter: (a, b) => sorter(a.tier, b.tier),
            sortOrder: sortInfo?.key === "tier" && sortInfo?.order,
            ...filterProperties("tiers", "tier", agreementTiers),
            render: (text, record) => (
              <Dropdown
                overlayClassName="status-update--dropdown"
                overlay={() => updateMenu(record, "tier", agreementTiers)}
                trigger={["click"]}
                disabled={isHFUser}
              >
                <span
                  className={
                    "table-selection-dropdown" + (!text ? " empty" : "")
                  }
                  onClick={e => e.stopPropagation()}
                >
                  {text ? text : "Unallocated"}
                  <CaretDownFilled />
                </span>
              </Dropdown>
            ),
          },
          {
            title: "STATUS",
            dataIndex: "statusName",
            key: "statusName",
            width: 150,
            sorter: (a, b) => sorter(a.statusName, b.statusName),
            sortOrder: sortInfo?.key === "statusName" && sortInfo?.order,
            ...filterProperties(
              "agreement_status_ids",
              "statusName",
              agreementStatus
            ),
            filters: agreementStatus.map((sts) => {
              return { text: sts.label, value: sts.value };
            }),
            onFilter: (value, record) => {
              return record?.statusId === value;
            },
            render: (text, record) => (
              <Dropdown
                overlayClassName="status-update--dropdown"
                overlay={() => updateMenu(record, "statusId", agreementStatus)}
                trigger={["click"]}
                disabled={isHFUser}
              >
                <span
                  style={generateStatusStyle(agreementStatus, record?.statusId)}
                  className={
                    "table-selection-dropdown" + (!text ? " empty" : "")
                  }
                  onClick={e => e.stopPropagation()}
                >
                  {text ? text?.replaceAll("_", " ") : "Unallocated"}
                  <CaretDownFilled />
                </span>
              </Dropdown>
            ),
          },
          {
            title: "PROGRESS",
            dataIndex: "statusProgress",
            key: "statusProgress",
            width: 150,
            sorter: (a, b) => sorter(a.statusProgress, b.statusProgress),
            sortOrder: sortInfo?.key === "statusProgress" && sortInfo?.order,
            ...filterProperties(
              "progress_percents",
              "statusProgress",
              agreementProgress
            ),
            render: (text) => <span>{text + " %"}</span>,
          },
          {
            title: "SITTING WITH",
            dataIndex: "sittingWith",
            key: "sittingWith",
            width: 200,
            sorter: (a, b) => sorter(a.sittingWith, b.sittingWith),
            sortOrder: sortInfo?.key === "sittingWith" && sortInfo?.order,
            ...filterProperties(
              "sitting_with",
              "sittingWith",
              sittingWithOptions
            ),
            render: (text, record) => (
              <Dropdown
                overlayClassName="status-update--dropdown"
                overlay={() =>
                  updateMenu(record, "sittingWith", sittingWithOptions)
                }
                trigger={["click"]}
                disabled={isHFUser}
              >
                <span
                  className={
                    "table-selection-dropdown" + (!text ? " empty" : "")
                  }
                  onClick={e => e.stopPropagation()}
                >
                  {text ? text === "cp" ? "CP" : text?.replaceAll("_", " ") : "Unallocated"}{" "}
                  <CaretDownFilled />
                </span>
              </Dropdown>
            ),
          },
          {
            title: "RESPONSIBLE",
            dataIndex: "responsibleFirstName",
            key: "responsibleFirstName",
            width: 200,
            render: (text, record) => (
              <Dropdown
                overlayClassName="status-update--dropdown"
                overlay={() => responsibleUpdateMenu(record)}
                trigger={["click"]}
              disabled={isHFUser}
              >
                <span
                  className={
                    "table-selection-dropdown" + (!text ? " empty" : "")
                  }
                  onClick={e => e.stopPropagation()}
                >
                  {text
                    ? text + " " + record?.responsibleLastName
                    : "Unallocated"}{" "}
                  <CaretDownFilled />
                </span>
              </Dropdown>
            ),
          },
          {
            title: "COMPLETED",
            dataIndex: "isCompleted",
            key: "isCompleted",
            width: 200,
            sorter: (a, b) => sorter(b?.isCompleted, a?.isCompleted),
            sortOrder: sortInfo?.key === "isCompleted" && sortInfo?.order,
            ...filterProperties("completed", "isCompleted", completionTypes),
            render: (isCompleted: boolean) => <span>{isCompleted ? "Yes" : "No"}</span>
          },
          // {
          //   title: "KEY TERMS",
          //   dataIndex: "keyTerms",
          //   key: "keyTerms",
          //   width: 100,
          // },
          {
            title: "",
            key: "action",
            width: 280,
            render: (text, record: Agreement) => (
              <Space size="middle"
                className="table-actions"
                onClick={e => e.stopPropagation()}
              >
                <PopoverComponent
                  showTitleButton={true}
                  title="Notes"
                  content={<TableNotes id={record?.id} category="Agreement" />}
                >
                  <i
                    className="icon-notes hoverable-icon"
                    title="Notes"
                  />
                </PopoverComponent>
                <PopoverComponent
                  showTitleButton={true}
                  title="Key contacts"
                  content={
                    <KeyContacts
                      contactableType="Agreement"
                      contactableId={record?.id.toString()}
                    />
                  }
                >
                  <i
                    className="icon-key-contacts hoverable-icon"
                    title="Key contacts"
                  />
                </PopoverComponent>
                <PopoverComponent
                  showTitleButton={true}
                  title="Notifications"
                  content={
                    <TableNotifications
                      id={record.id}
                      category={NotificationType.AGREEMENT}
                      user="Hedge Fund"
                    />
                  }
                >
                  <i
                    className="icon-notification-1  hoverable-icon"
                    title="Notifications"
                  />
                </PopoverComponent>
                <i
                  className="icon-download  hoverable-icon"
                  onClick={(e) => {
                    e.stopPropagation()
                    HFAgreementService.fetchSingleAgreements(
                      record?.id,
                      (data) => {
                        fileDownloadFromUrl(data)
                      },
                      () => { },
                      () => { },
                    )
                  }}
                  title="Download"
                />
              {!isHFUser &&  <i
                  className="icon-edit"
                  onClick={(e) => {
                    e.stopPropagation()
                    setSelectedAgreement(record);
                    setVisible(true);
                  }}
                  title="Edit"
                /> }
                   {!isHFUser &&  <i
                  className="icon-delete"
                  onClick={(e) => {
                    e.stopPropagation()
                    setSelectedAgreement(record);
                    setDeleteVisibility(true);
                  }}
                  title="Delete"
                />}
              </Space>
            ),
          },
        ]}
        additionalButtons={additionalButtons}
        data={agreements}
      />

      <ConfirmationAlert
        closeHandler={() => setDeleteVisibility(false)}
        visible={showDelete}
        type="delete"
        deleteHandler={() => deleteHandler(selectedAgreement)}
        cancelHandler={() => setDeleteVisibility(false)}
        title={"Delete Agreement"}
        description={selectedAgreement?.name}
      />
      <AgreementForm
        funds={funds}
        counterParties={counterParties}
        agreementStatus={agreementStatus}
        agreementTypes={agreementTypes}
        agreement={selectedAgreement}
        visible={visible}
        closeHandler={(agreement?: Agreement) => {
          setVisible(false);
          closeHandler(agreement);
        }}
      ></AgreementForm>
    </>
  );
};
export default AgreementsList;
