import { Tag, Space, Checkbox, Menu, Dropdown, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import KeyContacts from "../../../../../../shared/components/KeyContacts";
import PopoverComponent from "../../../../../../shared/components/PopoverComponent";
import { TableComponent } from "../../../../../../shared/components/TableComponent";
import TableNotes from "../../../../../../shared/components/TableNotes";
import TableNotifications from "../../../../../../shared/components/TableNotifications";
import NotebookIcon from "../../../../../../assets/icons/notebook.svg";
import EditIcon from "../../../../../../assets/icons/edit.svg";
import DeleteIcon from "../../../../../../assets/icons/delete.svg";
import DownloadIcon from "../../../../../../assets/icons/download.svg";
import ContactIcon from "../../../../../../assets/icons/contact.svg";
import BellIcon from "../../../../../../assets/icons/bell-2.svg";
import {
  Agreement,
  AgreementStatus,
  AncillaryDocuments,
} from "../../../../../../models/Agreements/agreement.model";
import {
  CaretDownFilled,
  CaretDownOutlined,
  DownloadOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import ButtonComponent from "../../../../../../shared/components/ButtonComponent";
import {
  agreementProgress,
  sittingWithOptions,
} from "../../agreementsDefinitions";
import { NotificationTypes } from "../../../../../../enums/notificationTypes";
import { HFAgreementService } from "../../../../../../services/AgreementService/agreement.service";
import Notification from "../../../../../../shared/components/Notification";
import AncillaryForm from "./AncillaryForm";
import { ConfirmationAlert } from "../../../../../../shared/components/ConfirmationAlert";
import { deserialize } from "serializr";
import { MetaService } from "../../../../../../services/MetaService/meta.service";
import AgreementModal from "../../../../../../shared/components/AgreementModal";
import { generateStatusStyle } from "../../../../../../shared/utils/statusStyleGenerator";
import TableFilterDropDown from "../../../../../../shared/components/TableFilterDropDown";
import { sorter } from "../../../../../../shared/utils/tableSorter";
import { fileDownloadFromUrl } from "../../../../../../shared/utils/fileDownloader";
import { NotificationType } from "../../../../../../enums/notificationType";
import { useLocation, useParams } from "react-router";
import { ancillaryDocmentTypes } from "./AncillaryForm/ancillaryDefinitions";
interface AncillaryDocumentsProps {
  agreement: Agreement;
  onAgreementUpdate?: Function;
}

const HFAncillaryDocuments = (props: AncillaryDocumentsProps) => {
  const { agreement } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [documents, setDocuments] = useState<AncillaryDocuments[]>([]);
  const [selectedDocument, setSelectedDocument] =
    useState<AncillaryDocuments>();
  const [showDelete, setDeleteVisibility] = useState(false);
  const [successVisible, setSuccessVisibility] = useState(false);
  const [ancillaryStatus, setAncillaryStatus] = useState<any[]>([]);
  const [agreementModalStatus, setAgreementModalStatus] =
    useState<{ ancillaryDocument: AncillaryDocuments; visibility: boolean }>();
  const [searchText, setSearchText] = useState<string>("");
  const [sortInfo, setSortInfo] = useState<{ key: string; order: string }>();
  const [filterInfo, setFilterInfo] = useState({});
  const [tableLoading, setTableLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    fetchDocuments(filterInfo);
    fetchAncillaryStatus();
  }, []);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      fetchDocuments(filterInfo);
    }, 500);
    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchText, filterInfo]);

  const fetchDocuments = (filter) => {
    setTableLoading(true);
    HFAgreementService.fetchAncillaryDocuments(
      agreement?.id?.toString(),
      { search_text: searchText, ...filter },
      (response) => {
        setDocuments(response);
        const p = new URLSearchParams(location.search);
        if (p.get("type")?.trim() === "ancillary") {
          const doc = response?.find((doc) => doc.id == p.get("id"));
          setAgreementModalStatus({
            ancillaryDocument: doc,
            visibility: true,
          });
        }
      },
      (error) => {
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to fetch documents",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => {
        setTableLoading(false);
      }
    );
  };

  const fetchAncillaryStatus = () => {
    MetaService.ancillaryStatus(
      (response) => {
        const data = deserialize(
          AgreementStatus,
          response.data["ancillary_document_statuses"]
        ) as AgreementStatus[];
        const formattedData = data.map((sts) => {
          return {
            label: (sts.name ?? "")?.replaceAll("_", " "),
            value: sts.id,
            color: sts.color,
          };
        });
        setAncillaryStatus(formattedData);
      },
      (error) => {
      }
    );
  };

  const downloadAncillaryDocuments = () => {
    HFAgreementService.downloadAncillaryDocuments(
      agreement?.id,
      {},
      (response) => {
        setSuccessVisibility(true);
      },
      (error) => {
        //console.log(error);
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to download all the ancillary documents",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  const deleteHandler = (document: AncillaryDocuments) => {
    HFAgreementService.deleteAncillaryDocument(
      document,
      agreement,
      (res) => {
        fetchDocuments(filterInfo);
        setSelectedDocument(null);
        Notification({
          message: "Hedge Fund",
          description: "Agreement deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
      },
      (err) => {
        // ;
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Agreement deletion failed",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => {
        setDeleteVisibility(false);
      }
    );
  };

  const sittingUpdateMenu = (agreement: AncillaryDocuments) => {
    const availableSitting = sittingWithOptions.filter(
      (sitting) => sitting.value !== agreement?.sittingWith
    );
    return (
      <Menu>
        {availableSitting?.map((sitting) => (
          <Menu.Item
            key={sitting.value}
            onClick={(e) => {
              e.domEvent.stopPropagation();
              const updatedDocument = {
                ...agreement,
                sittingWith: sitting.value,
              };
              updateAncillaryDocument(updatedDocument);
            }}
          >
            {sitting.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const statusUpdateMenu = (document: AncillaryDocuments) => {
    const availableStatus = ancillaryStatus.filter(
      (status) => status.value !== document?.ancillaryStatusId
    );
    return (
      <Menu>
        {availableStatus?.map((status) => (
          <Menu.Item
            key={status.value}
            onClick={(e) => {
              e.domEvent.stopPropagation();
              const updatedDocument = {
                ...document,
                ancillaryStatusId: status.value,
              };
              updateAncillaryDocument(updatedDocument);
            }}
          >
            {status.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const updateAncillaryDocument = (values: AncillaryDocuments) => {
    HFAgreementService.updateAncillaryDocument(
      values,
      agreement?.id?.toString(),
      (response) => {
        fetchDocuments(filterInfo);
      },
      (error) => {
        //console.log(error);
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to update agreement",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  const sortHandler = (key: string, order: string) => {
    setSortInfo({
      key,
      order,
    });
  };

  const filterHandler = (key: string, value: any) => {
    const currentFilter = { ...filterInfo };
    currentFilter[key] = value;
    if (JSON.stringify(filterInfo) === JSON.stringify(currentFilter)) {
      return;
    }
    setFilterInfo(currentFilter);
  };
  const filterProperties = (dataIndex: string, sortIndex: string, records) => {
    return {
      filterDropdown: (filterProps) => (
        <TableFilterDropDown
          {...filterProps}
          defaultSelected={filterInfo[dataIndex]}
          onApply={(key, value) => filterHandler(key, value)}
          dataIndex={dataIndex}
          sortIndex={sortIndex}
          checkboxValues={records}
          onSort={sortHandler}
        />
      ),
      filterIcon: () => <CaretDownOutlined />,
    };
  };

  return (
    <>
      <TableComponent
        title="Ancillary Documents"
        className="sort-remover row-clickable"
        onRow={(index: number, record) => {
          setAgreementModalStatus({
            ancillaryDocument: record,
            visibility: true,
          });
        }}
        columns={[
          {
            title: "TYPE",
            dataIndex: "documentType",
            key: "documentType",
            width: 250,
            render: (text, ancillaryDocument) => (
              <Tooltip placement="topLeft" title={ancillaryDocmentTypes.find(doc => doc.value.toLowerCase() === text?.toLowerCase()).label || ""}>
                <span
                  className="table-details--route text-capitalize"
                  onClick={() =>
                    setAgreementModalStatus({
                      ancillaryDocument,
                      visibility: true,
                    })
                  }
                >
                  {ancillaryDocmentTypes.find(doc => doc.value.toLowerCase() === text?.toLowerCase()).label || ""}
                </span>
              </Tooltip>
            ),
          },
          {
            title: "STATUS",
            dataIndex: "ancillaryStatusName",
            key: "ancillaryStatusName",
            sorter: (a, b) =>
              sorter(a.ancillaryStatusName, b.ancillaryStatusName),
            sortOrder:
              sortInfo?.key === "ancillaryStatusName" && sortInfo?.order,
            ...filterProperties(
              "ancillary_document_status_ids",
              "ancillaryStatusName",
              ancillaryStatus
            ),
            width: 100,
            render: (text, record) => (
              <Dropdown
                overlayClassName="status-update--dropdown"
                overlay={() => statusUpdateMenu(record)}
                trigger={["click"]}
              >
                <span
                  className={
                    "table-selection-dropdown status-text " +
                    (!text ? " empty" : "")
                  }
                  style={generateStatusStyle(
                    ancillaryStatus,
                    record?.ancillaryStatusId
                  )}
                  onClick={(e) => e.stopPropagation()}
                >
                  {text ? text?.replaceAll("_", " ") : "Unallocated"}{" "}
                  <CaretDownFilled />
                </span>
              </Dropdown>
            ),
          },
          {
            title: "PROGRESS",
            dataIndex: "ancillaryStatusProgress",
            key: "ancillaryStatusProgress",
            sorter: (a, b) =>
              sorter(a.ancillaryStatusProgress, b.ancillaryStatusProgress),
            sortOrder:
              sortInfo?.key === "ancillaryStatusProgress" && sortInfo?.order,
            ...filterProperties(
              "ancillary_document_status_progress_percents",
              "ancillaryStatusProgress",
              agreementProgress
            ),
            width: 150,
            render: (text) => <span>{text + " %"}</span>,
          },
          {
            title: "SITTING WITH",
            dataIndex: "sittingWith",
            key: "sittingWith",
            width: 150,
            render: (text, record) => (
              <Dropdown
                overlayClassName="status-update--dropdown"
                overlay={() => sittingUpdateMenu(record)}
                trigger={["click"]}
              >
                <span
                  className={
                    "table-selection-dropdown" + (!text ? " empty" : "")
                  }
                  onClick={(e) => e.stopPropagation()}
                >
                  {text ? text === "cp" ? "CP" : text?.replaceAll("_", " ") : "Unallocated"}{" "}
                  <CaretDownFilled />
                </span>
              </Dropdown>
            ),
          },
          {
            title: "",
            key: "action",
            width: 100,
            render: (text, record) => (
              <Space
                size="middle"
                className="table-actions"
                onClick={(e) => e.stopPropagation()}
              >
                <PopoverComponent
                  showTitleButton={true}
                  title="Notes"
                  content={
                    <TableNotes id={record?.id} category="AncillaryDocument" />
                  }
                >
                  <i className="icon-notes hoverable-icon" title="Notes" />
                </PopoverComponent>
                <PopoverComponent
                  showTitleButton={true}
                  title="Key contacts"
                  content={
                    <KeyContacts
                      contactableType="AncillaryDocument"
                      contactableId={record?.id}
                    />
                  }
                >
                  <i
                    className="icon-key-contacts hoverable-icon"
                    title="Key contacts"
                  />
                </PopoverComponent>
                <PopoverComponent
                  showTitleButton={true}
                  title="Notifications"
                  content={
                    <TableNotifications
                      id={record.id}
                      category={NotificationType.ANCILLARY_DOCUMENT}
                      user="Hedge Fund"
                    />
                  }
                >
                  <i
                    className="icon-notification-1  hoverable-icon"
                    title="Notifications"
                  />
                </PopoverComponent>
                <i
                  className="icon-download  hoverable-icon"
                  onClick={() => {
                    HFAgreementService.fetchAncillaryDocumentDetails(
                      agreement?.id,
                      record?.id,
                      (data) => {
                        fileDownloadFromUrl(data);
                      },
                      () => { },
                      () => { }
                    );
                  }}
                  title="Download"
                />
                <i
                  className="icon-edit"
                  onClick={() => {
                    setSelectedDocument(record);
                    setVisible(true);
                  }}
                  title="Edit"
                />
                <i
                  className="icon-delete"
                  onClick={() => {
                    setSelectedDocument(record);
                    setDeleteVisibility(true);
                  }}
                  title="Delete"
                />
              </Space>
            ),
          },
        ]}
        data={documents}
        loading={tableLoading}
        showSearch={true}
        onSearch={setSearchText}
        showAdd={true}
        onAdd={() => {
          setVisible(true);
        }}
        scroll={{ x: 800 }}
        additionalButtons={
          <>
            <ButtonComponent
              type="default"
              size="large"
              icon={<DownloadOutlined />}
              onClick={() => {
                downloadAncillaryDocuments();
              }}
            />
            <ButtonComponent
              type="default"
              size="large"
              icon={<i className="icon-export primary" />}
              onClick={() => {
                HFAgreementService.exportAncillaryDocuments(
                  agreement?.id?.toString(),
                  agreement?.name,
                  { ...filterInfo },
                  () => { },
                  () => { },
                  () => { }
                );
              }}
            />
          </>
        }
      />
      <ConfirmationAlert
        closeHandler={() => setDeleteVisibility(false)}
        visible={showDelete}
        type="delete"
        deleteHandler={() => deleteHandler(selectedDocument)}
        cancelHandler={() => setDeleteVisibility(false)}
        title={"Delete Ancillary Document"}
        description={selectedDocument?.documentType?.replaceAll("_", " ")}
      />
      <AncillaryForm
        document={selectedDocument}
        agreement={agreement}
        visible={visible}
        closeHandler={(document?: AncillaryDocuments) => {
          if (document?.id) {
            fetchDocuments(filterInfo);
          }
          setSelectedDocument(null);
          setVisible(false);
        }}
      />
      <ConfirmationAlert
        closeHandler={() => {
          setSuccessVisibility(false);
        }}
        visible={successVisible}
        type="success"
        customButtons={
          <div className="download-success--buttons">
            <ButtonComponent
              onClick={() => {
                setSuccessVisibility(false);
              }}
              type="ghost"
            >
              Close
            </ButtonComponent>
          </div>
        }
        cancelHandler={() => {
          setSuccessVisibility(false);
        }}
        title={"Download request successful"}
        description="Link to the documents will be shared with your mail id shortly.."
      />
      <AgreementModal
        agreement={agreement}
        ancillaryDocument={agreementModalStatus?.ancillaryDocument}
        visible={agreementModalStatus?.visibility}
        closeHandler={() => {
          setAgreementModalStatus({
            ancillaryDocument: null,
            visibility: false,
          });
        }}
      />
    </>
  );
};

export default HFAncillaryDocuments;
