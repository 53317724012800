import { BellOutlined, PlusOutlined } from "@ant-design/icons";
import { Checkbox, Col, Form, List, Row } from "antd";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NotificationType } from "../../../enums/notificationType";
import { NotificationTypes } from "../../../enums/notificationTypes";
// import { NotificationModel } from "../../../models/Meta/notification.model";
import { Notification as NotificationModel } from "../../../models/Notification/notification.model";
import { FundsService } from "../../../services/FundService/Fund.service";
import NotificationService from "../../../services/NotificationService/notification.service";
import ButtonComponent from "../ButtonComponent";
import DatePickerComponent from "../DatePickerComponent";
import InputField from "../InputField";
import Notification from "../Notification";
import "./table-notifications.scss";

interface TableNotificationsProps {
  id: number;
  category: NotificationType;
  user: "Hedge Fund" | "Hedge Legal";
}

const TableNotifications = (props: TableNotificationsProps) => {
  const { id, category, user } = props;
  const [notifications, setNotifications] = useState<NotificationModel[]>([]);
  const [notification, setNotification] = useState<NotificationModel>(
    new NotificationModel()
  );

  const { tableNotifications } = NotificationService();

  const [addFormMode, setAddFormMode] = useState<boolean>();

  const fetchNotifications = () => {
    FundsService.fetchNotifications(
      { notificationType: category, notificationId: id.toString() },
      (response) => {
        setNotifications(response);
      },
      (err) => {
        // ;
      },
      () => { }
    );
  };

  const completeNotification = async (
    bool: boolean,
    notification: NotificationModel
  ) => {
    if (!bool) {
      return;
    }
    FundsService.updateNotificationCompletion(
      { notificationType: category, notificationId: id.toString() },
      notification,
      (response) => {
        fetchNotifications();
      },
      (err) => {
        // ;
      },
      () => { }
    );
  };

  const createNotification = async (values: NotificationModel) => {
    FundsService.createNotifications(
      { notificationType: category, notificationId: id.toString() },
      values,
      (response) => {
        fetchNotifications();
        setAddFormMode(false);
        Notification({
          message: user,
          description: "Notification created successfully",
          type: NotificationTypes.SUCCESS,
        });
      },
      (err) => {
        // ;
        // Notification({
        //   message: user,
        //   description: "Unable to create notification",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  const notificationsListGenerator = (notifications) =>
    notifications?.map((notification) => (
      <List.Item key={notification.id} onClick={e => e.stopPropagation()}>
        <List.Item.Meta
          title={
            <>
              <BellOutlined />
              <p className="table-notification--time">
                {moment(notification.notifyAt).format("hh:mm - MMM DD, YYYY")}
              </p>
            </>
          }
          description={<p>{notification.description}</p>}
        />

        <div>
          <Checkbox
            onChange={() => {
              completeNotification(true, notification);
            }}
          ></Checkbox>
        </div>
      </List.Item>
    ));

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <>
      {!addFormMode && (
        <PlusOutlined
          className="popover-add-btn"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setAddFormMode(true)
          }}
        />
      )}
      <div className="table-notification__container" onClick={e => e.stopPropagation()}>
        {!addFormMode ? (
          <List
            itemLayout="horizontal"
            dataSource={[notifications]}
            renderItem={(notifications) => {
              let notificationList: any =
                notificationsListGenerator(notifications);
              if (!notificationList.length) {
                notificationList = (
                  <p className="empty-notification">
                    No notifications found...
                  </p>
                );
              }
              return notificationList;
            }}
          ></List>
        ) : (
          <Formik
            enableReinitialize
            initialValues={notification}
            onSubmit={() => { }}
          >
            {({ setFieldValue, values, resetForm, isValid }) => {
              return (
                <Form
                  onClick={e => e.stopPropagation()}
                >
                  <div className="table-notification">
                    <Row>
                      <Col sm={24}>
                        <InputField
                          placeholder={"Description"}
                          name="description"
                          type="textarea"
                          value={values.description}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={24}>
                        <DatePickerComponent
                          placeholder={"Remind me at..."}
                          name="dueTime"
                          className="input-field"
                          onChange={(value) => {
                            setFieldValue(
                              "dueTime",
                              moment(value).format("hh:mm - MMM DD, YYYY")
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <ButtonComponent
                          type={"default"}
                          onClick={(e) => {
                            e.stopPropagation()
                            setAddFormMode(false);
                          }}
                          className="table-notification--action"
                        >
                          Cancel
                        </ButtonComponent>
                      </Col>
                      <Col sm={12}>
                        <ButtonComponent
                          type={"primary"}
                          onClick={(e) => {
                            e.stopPropagation()
                            createNotification(values);
                          }}
                          className="table-notification--action"
                        >
                          Create
                        </ButtonComponent>
                      </Col>
                    </Row>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </>
  );
};

export default TableNotifications;
