import { EllipsisOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import moment from "moment";
import React from "react";
import { SupportTicket } from "../../../models/Ticket/ticket.model";
import "./raisedTicket.scss"

interface RaisedTicketProps {
    ticket: SupportTicket
    deleteHandler: (id: number) => void
}

const RaisedTicket = (props: RaisedTicketProps) => {
    const {
        ticket,
        deleteHandler,
    } = props

    const menu = (
        <Menu theme="dark">
            <Menu.Item key="0" onClick={() => deleteHandler(ticket?.id)}>
                <i className="icon-delete"></i>
                Delete Ticket
            </Menu.Item>
        </Menu>
    );

    return <div className="raised-ticket" key={ticket?.id}>
        <div className="raised-ticket__header">
            <div className="raised-ticket--title">
                {ticket?.title}
            </div>
            <div className="raised-ticket--actions">
                <p className="raised-ticket--subtitle">
                    <span>ID: {ticket?.id}</span>
                    <hr />
                    <span>{moment(ticket?.raisedAt).format('DD, MMM YYYY')}</span>
                </p>
                <p className={`raised-ticket--status ${ticket?.status}`}>{ticket?.status}</p>
                <p className="raised-ticket--menu">
                    <Dropdown overlay={menu} trigger={['click']}>
                        <EllipsisOutlined />
                    </Dropdown>
                </p>
            </div>
        </div>
        <p className="raised-ticket-description">
            {ticket?.description}
        </p>
    </div>
}

export default RaisedTicket