export const ancillaryDocmentTypes = [
    { label: "IM Side Letter", value: "i_m_side_letter" },
    { label: "Sub-IM Side Letter", value: "sub_i_m_side_letter" },
    { label: "Guarantee – Fund", value: "gurantee_fund" },
    { label: "Guarantee – CP", value: "gurantee_cp" },
    { label: "Third Party Authorization Form", value: "third_party_authorization_form" },
    { label: "Account Opening Information", value: "account_opening_information" },
    { label: "Authorized Signatory Form", value: "authorised_signat_form" },
    { label: "Incumbency Certificate - Fund", value: "incumbency_certificate_fund" },
    { label: "Incumbency Certificate – CP", value: "incumbency_certificate_cp" },
    { label: "Board Resolutions (Re Trading Agreements)", value: "board_resolutions_re_trading_agreements" },
    { label: "Other", value: "other" }
]