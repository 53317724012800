import { Button, Col, Input, Row } from "antd";
import { Formik, Form } from "formik";
import React, { FC, useState } from "react";
import Placeholder from "../../../../../../assets/background-images/auth-bg.png";
import InputField from "../../../../../../shared/components/InputField";
import Rating from "../../../../../../models/Rating/rating.model";
import "./reviewForm.scss";
import { CounterParties } from "../../../../../../models/CounterParties/counterParties.model";
import { HFCounterPartiesService, HLCounterPartiesService } from "../../../../../../services/CounterPartiesService/CounterParties.service";
import LayoutContainer from "../../../../../../store/containers/layoutContainer";
import { LayoutReducerProps } from "../../../../../../store/reducers/layoutReducer";
import DoxCoinsService from "../../../../../../services/DoxCoinsService/DoxCoins.service";
import { InfoCircleFilled } from "@ant-design/icons";

interface ReviewFormProps extends LayoutReducerProps {
  id: string,
  cp?: CounterParties
  successHandler: (rating: Rating) => void;
  closeHandler: () => void;
}

const ReviewForm: FC<ReviewFormProps> = (props) => {
  const { id, successHandler, closeHandler, setDoxCoin, cp } = props;

  const [rating, setRating] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  const { getTotalDoxCoins } = DoxCoinsService();

  const handleSubmit = (values: Rating) => {
    setLoading(true)
    const rating = Object.assign(new Rating(), values);
    HFCounterPartiesService.postReview(id, rating, (rating) => {
      successHandler(rating);
      getTotalDoxCoins(setDoxCoin);
      closeHandler();
      setLoading(false)
    }, () => {
      setLoading(false)
    })
  };

  return (
    <div className="review-form">
      <Row justify="center">
        <Col className="company-title">
          <img src={cp.logoUrl || Placeholder} alt="company.png" />
          <p>{cp.name || ""}</p>
        </Col>
      </Row>
      <Formik initialValues={new Rating()} onSubmit={handleSubmit}>
        {({ setFieldValue }) => (
          <Form>
            <div className="rating-list mb-5">
              {Array(5)
                .fill("")
                ?.map((_, index) => (
                  <i
                    className={`icon-review-star ${index + 1 <= rating && "review-selected"
                      }`}
                    onClick={() => {
                      setFieldValue("rating", index + 1);
                      setRating(index + 1);
                    }}
                  />
                ))}
            </div>
            <InputField
              className="mt-5"
              type="textarea"
              placeholder="Type your review"
              name="review"
            />
            <p className="hint mt-5">
              <InfoCircleFilled /> Please note that your review is anonymous.  Your Name and Firm name will NOT be displayed next to this review.  This is to protect your privacy.  We will show metrics however as to the strategy, location, and range bracket of AUM.  Please keep reviews constructive and appropriate in respect of all the users of this community
            </p>
            <Row gutter={[20, 0]} justify="space-between" className="mt-5">
              <Col span={12}>
                <Button
                  type="text"
                  className="full-width"
                  onClick={closeHandler}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button loading={loading} htmlType="submit" type="primary" className="full-width">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LayoutContainer(ReviewForm);
