import { Col, Row } from "antd";
import moment from "moment";
import React, { FC } from "react";
import { AuditTrail } from "../../../../../../models/AuditTrail/auditTrail.model";

interface AuditViewTitleProps {
  auditTrail: AuditTrail;
}

const AuditViewTitle: FC<AuditViewTitleProps> = (props) => {
  const { auditTrail } = props;
  return (
    <Row className="audit-view-title" gutter={[15, 0]}>
      <Col>
        <p className="title">Date</p>
        <p className="value">{auditTrail?.createdAt && moment(auditTrail?.createdAt).format("MMM DD, YYYY")}</p>
      </Col>
      <Col>
        <p className="title">User Name</p>
        <p className="value">{auditTrail?.user?.firstName} {auditTrail?.user?.lastName}</p>
      </Col>
      <Col>
        <p className="title">User Type</p>
        <p className="value">{auditTrail?.user?.role}</p>
      </Col>
      <Col>
        <p className="title">HFund Company</p>
        <p className="value">{auditTrail?.fund?.companyName}</p>
      </Col>
      <Col>
        <p className="title">Fund</p>
        <p className="value">{auditTrail?.fund?.name}</p>
      </Col>
      <Col>
        <p className="title">Event Type</p>
        <p className="value">{auditTrail?.itemType}</p>
      </Col>
      <Col>
        <p className="title">Information</p>
        <p className="value">
          <span className="text-capitalize">
            {auditTrail?.event}
          </span>
          {" "}
          <span>
            {auditTrail?.itemType}
          </span>
        </p>
      </Col>
    </Row>
  );
};

export default AuditViewTitle;
