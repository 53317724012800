import { serializable, alias, object, list, primitive } from "serializr";

export class AgreementTypes {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("name", primitive()))
  value?: string;

  @serializable(alias("name", primitive()))
  label?: string;

  @serializable(alias("status", primitive()))
  status?: string;
}
