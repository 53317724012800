import React, { Fragment, useEffect } from "react";
import {
    Col,
    Row
} from "antd";

import HFSidebar from "../../../components/HF/Home/Sidebar";
import GlobalSearch from "../../../shared/components/GlobalSearch";
import Badge from "../../../../javascript/assets/icons/badge.svg";

import './subscriptionRestrict.scss'

const SubscriptionRestrict = (props) => {
    return (
        <Row className="page__wrapper" >
            <Col className="sidebar" >
                <HFSidebar />
            </Col>
            <Col className="body-wrapper">
                <GlobalSearch />
                <div className="app-content__wrapper">
                    <div className="message">
                        <img src={Badge} /> <br />
                        <span>
                            Upgrade Subscription <br />
                            to avail this feature
                        </span>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default SubscriptionRestrict