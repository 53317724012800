import { Card, Col, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import Agreement from "./Agreements";
import CPBrokers from "./CPBrokers";
import DiscussionBoard from "./DiscussionBoard";
import Funds from "./Funds";
import "./dashboard.scss";
import Metrices from "./Metrices";
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";
import { useLocation, useParams } from "react-router";
import DashboardReports from "./DashboardReports";

interface DashboardProps { }

const Dashboard: FC<DashboardProps> = () => {
  const location = useLocation();
  const user = LocalStorage.getItem("USER");

  const { id: companyId } = useParams<{ id: string }>()

  return (
    <div className="dashboard">
      {(user?.role?.includes("hl") && !companyId) ? <Metrices /> : null}
      <Agreement companyId={companyId} />
      <Row gutter={[14, 14]} className="mt-3">
        <Col span={10}>
          <Funds companyId={companyId} />
        </Col>
        <Col span={7}>
          <CPBrokers companyId={companyId} />
        </Col>
        <Col span={7}>
          <DashboardReports companyId={companyId} />
          {/* <DiscussionBoard companyId={companyId} /> */}
        </Col>
      </Row>
    </div>
  );
};
export default Dashboard;
