import Form from "antd/lib/form/Form";
import { Field, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { AttachmentComponent } from "../../../../../shared/components/AttachmentComponent";
import DropdownField from "../../../../../shared/components/DropdownField";
import InputField from "../../../../../shared/components/InputField";
import { UIModal } from "../../../../../shared/components/UIModal";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import "./cpForm.scss";
import { MetaService } from "../../../../../services/MetaService/meta.service";
import { productsAvailable } from "../../../../HL/Home/CP/cpDefinitions";
import { serialize } from "serializr";
import { HFCounterPartiesService } from "../../../../../services/CounterPartiesService/CounterParties.service";
import { convertJSONToFormData } from "../../../../../shared/utils/dataFormatConverter";
import { CounterParties } from "../../../../../models/CounterParties/counterParties.model";
interface CPFormProps {
  data: any;
  visible: boolean;
  closeHandler: () => void;
}
const HFCPForm: FC<CPFormProps> = ({ data, visible, closeHandler }) => {
  const [initialValues, setInitialValues] = useState({
    productsAvailable: undefined,
    country: undefined,
    state: undefined,
    city: undefined,
  });
  const formRef = React.useRef<any>();
  const [countries, setCountries] = useState<any>();
  const [provinces, setProvinces] = useState<any>();
  const [cities, setCities] = useState<any>();
  const fetchCountries = () => {
    MetaService.listCountries(
      (countries) => {
        setCountries(countries);
      },
      () => {}
    );
  };
  const fetchProvinces = (countryId: any) => {
    MetaService.listProvinces(
      countryId,
      (provinces) => {
        setProvinces(provinces);
        //console.log(provinces);
      },
      () => {}
    );
  };
  const fetchCities = (countryId: any, provinceId: any) => {
    MetaService.listCities(
      countryId,
      provinceId,
      (cities) => {
        setCities(cities);
      },
      () => {}
    );
  };
  const onSubmit = (values: any,resetForm: Function) => {
    values["address"] = {
      addressType: "registered",
      street: values?.street,
      addressLine2: values?.address2,
      cityId: values?.city,
      zipCode: values?.zipCode,
    }
    //console.log(values);
    const jsonData = serialize(CounterParties, values);
    jsonData["logo"] = values["logo"];
    const formData = convertJSONToFormData({ counter_party: jsonData });
    HFCounterPartiesService.createCounterParty(
      formData,
      () => {
        resetForm()
        closeHandler();
      },
      () => {},
      () => {}
    );
    }
  useEffect(() => {
    fetchCountries();
  }, []);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      enableReinitialize
      innerRef={formRef}
    >
      {({ setFieldValue, values, resetForm, isValid }) => {
        return (
          <UIModal
            visible={visible}
            closeModal={closeHandler}
            title={"Suggest New CP"}
            submitProps={{
              text: "Submit",
              clickHandler: () => {onSubmit(values,resetForm)},
            }}
            cancelProps={{
              text: "Cancel",
              clickHandler: () => {closeHandler()},
            }}
          >
            <Form>
              <div className="cp-form__container">
                <div className="cp-form__field">
                  <InputField
                    placeholder={"Full Legal Name *"}
                    name="name"
                    type="text"
                  />
                </div>
                <div className="cp-form__field">
                  <DropdownField
                    mode="multiple"
                    optionFilterProp="label"
                    placeholder={"Products Available"}
                    name="productsAvailable"
                    options={productsAvailable}
                    showSearch={false}
                    value={values.productsAvailable}
                    onChange={(value) =>
                      setFieldValue("productsAvailable", value)
                    }
                  />
                </div>

                <div className="cp-form__field">
                  <InputField
                    placeholder={"Street"}
                    name="street"
                    type="text"
                  />
                </div>
                <div className="cp-form__field">
                  <InputField
                    placeholder={"Address 2"}
                    name="address2"
                    type="text"
                  />
                </div>
                <div className="cp-form__field">
                  <DropdownField
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"Country"}
                    name="country"
                    options={countries}
                    showSearch={false}
                    value={values.country}
                    onChange={(value) => {
                      setFieldValue("country", value);
                      fetchProvinces(value);
                    }}
                  />
                </div>
                <div className="cp-form__field">
                  <DropdownField
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"State/Province"}
                    name="state"
                    options={provinces}
                    showSearch={false}
                    value={values.state}
                    onChange={(value) => {
                      setFieldValue("state", value);
                      fetchCities(values?.country, value);
                    }}
                  />
                </div>
                <div className="cp-form__field">
                  <DropdownField
                    mode="single"
                    optionFilterProp="label"
                    placeholder={"City"}
                    name="city"
                    options={cities}
                    showSearch={false}
                    value={values.city}
                    onChange={(value) => {
                      setFieldValue("city", value);
                    }}
                  />
                </div>
              </div>
            </Form>
          </UIModal>
        );
      }}
    </Formik>
  );
};
export default HFCPForm;
