import { Button, Collapse, Drawer } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NotificationTypes } from "../../../enums/notificationTypes";
import {
  Agreement,
  Amendment,
  AncillaryDocuments,
  RelatedAgreements,
} from "../../../models/Agreements/agreement.model";
import { UIModal } from "../UIModal";
import "./agreement-modal.scss";

interface AgreementModalProps {
  visible: boolean;
  relatedAgreement?: RelatedAgreements;
  amendment?: Amendment;
  ancillaryDocument?: AncillaryDocuments;
  agreement: Agreement;
  closeHandler: (agreement?: any) => void;
}

const AgreementModal = (props: AgreementModalProps) => {
  const {
    visible,
    closeHandler,
    amendment,
    agreement,
    relatedAgreement,
    ancillaryDocument,
  } = props;

  const { Panel } = Collapse;
  const [openAgreementDrawer, setOpenAgreementDrawer] = useState(false);

  return (
    <UIModal
      noControl={true}
      className="agreement-modal"
      visible={visible}
      closeModal={closeHandler}
      title={
        relatedAgreement
          ? "Related Agreement - " + relatedAgreement?.agreementName
          : amendment
            ? "Amendment - " + amendment?.name
            : "Ancillary Document - " +
            ancillaryDocument?.documentType?.replaceAll("_", " ")
      }
    >
      <>
        <div className="agreement__header--details">
          {relatedAgreement?.fundName && (
            <div className="agreement__header--details__container">
              <p className="agreement__header--details__container--title">
                Fund Name
              </p>
              <p className="agreement__header--details__container--value">
                {relatedAgreement?.fundName}
              </p>
            </div>
          )}
          {relatedAgreement?.counterPartyName && (
            <div className="agreement__header--details__container">
              <p className="agreement__header--details__container--title">
                CP Name
              </p>
              <p className="agreement__header--details__container--value">
                {relatedAgreement?.counterPartyName}
              </p>
            </div>
          )}
          {relatedAgreement?.agreementTypeName && (
            <div className="agreement__header--details__container">
              <p className="agreement__header--details__container--title">
                Type
              </p>
              <p className="agreement__header--details__container--value">
                {relatedAgreement?.agreementTypeName?.replaceAll("_", " ")}
              </p>
            </div>
          )}
          {relatedAgreement?.agreementDate && (
            <div className="agreement__header--details__container">
              <p className="agreement__header--details__container--title">
                Date
              </p>
              <p className="agreement__header--details__container--value">
                {moment(relatedAgreement?.agreementDate).format("DD MMM, YYYY")}
              </p>
            </div>
          )}
          {amendment?.date && (
            <div className="agreement__header--details__container">
              <p className="agreement__header--details__container--title">
                Date
              </p>
              <p className="agreement__header--details__container--value">
                {amendment?.date}
              </p>
            </div>
          )}
          {!!amendment && (
            <div className="agreement__header--details__container">
              <p className="agreement__header--details__container--title">
                In force Status
              </p>
              <p className="agreement__header--details__container--value">
                {amendment?.deletedAt ? "No" : "Yes"}
              </p>
            </div>
          )}
          {ancillaryDocument?.ancillaryStatusName && (
            <div className="agreement__header--details__container">
              <p className="agreement__header--details__container--title">
                Status
              </p>
              <p className="agreement__header--details__container--value">
                {ancillaryDocument?.ancillaryStatusName?.replaceAll("_", " ")}
              </p>
            </div>
          )}
          {ancillaryDocument?.ancillaryStatusProgress >= 0 && (
            <div className="agreement__header--details__container">
              <p className="agreement__header--details__container--title">
                Progress
              </p>
              <p className="agreement__header--details__container--value">
                {ancillaryDocument?.ancillaryStatusProgress} %
              </p>
            </div>
          )}
          {ancillaryDocument?.sittingWith && (
            <div className="agreement__header--details__container">
              <p className="agreement__header--details__container--title">
                Sitting with
              </p>
              <p className="agreement__header--details__container--value">
                {ancillaryDocument?.sittingWith?.replaceAll("_", " ")}
              </p>
            </div>
          )}
        </div>
        <Collapse className="agreement__collapse">
          <Panel header={agreement?.name} key="1">
            <div className="agreement__header--details">
              {agreement?.fundName && (
                <div className="agreement__header--details__container">
                  <p className="agreement__header--details__container--title">
                    Fund Name
                  </p>
                  <p className="agreement__header--details__container--value">
                    {agreement?.fundName}
                  </p>
                </div>
              )}
              {agreement?.cpName && (
                <div className="agreement__header--details__container">
                  <p className="agreement__header--details__container--title">
                    CP Name
                  </p>
                  <p className="agreement__header--details__container--value">
                    {agreement?.cpName}
                  </p>
                </div>
              )}
              {agreement?.agreementType?.name && (
                <div className="agreement__header--details__container">
                  <p className="agreement__header--details__container--title">
                    Type
                  </p>
                  <p className="agreement__header--details__container--value">
                    {agreement?.agreementType?.name?.replaceAll("_", " ")}
                  </p>
                </div>
              )}
              {agreement?.date && (
                <div className="agreement__header--details__container">
                  <p className="agreement__header--details__container--title">
                    Date
                  </p>
                  <p className="agreement__header--details__container--value">
                    {moment(agreement?.date).format("DD MMM, YYYY")}
                  </p>
                </div>
              )}
              {agreement?.statusName && (
                <div className="agreement__header--details__container">
                  <p className="agreement__header--details__container--title">
                    Status
                  </p>
                  <p className="agreement__header--details__container--value">
                    {agreement?.statusName?.replaceAll("_", " ")}
                  </p>
                </div>
              )}
              {agreement?.sittingWith && (
                <div className="agreement__header--details__container">
                  <p className="agreement__header--details__container--title">
                    Sitting with
                  </p>
                  <p className="agreement__header--details__container--value">
                    {agreement?.sittingWith?.replaceAll("_", " ")}
                  </p>
                </div>
              )}
              {agreement?.tier && (
                <div className="agreement__header--details__container">
                  <p className="agreement__header--details__container--title">
                    Tier
                  </p>
                  <p className="agreement__header--details__container--value">
                    {agreement?.tier}
                  </p>
                </div>
              )}
              <div className="agreement__header--details__container">
                <p className="agreement__header--details__container--title">
                  Progress
                </p>
                <p className="agreement__header--details__container--value">
                  {agreement?.statusProgress + " %"}
                </p>
              </div>
              {agreement?.responsibleFirstName && (
                <div className="agreement__header--details__container">
                  <p className="agreement__header--details__container--title">
                    User responsible
                  </p>
                  <p className="agreement__header--details__container--value">
                    {agreement?.responsibleFirstName +
                      " " +
                      agreement?.responsibleLastName}
                  </p>
                </div>
              )}
            </div>
          </Panel>
        </Collapse>
        {relatedAgreement?.documentUrl ? (
          <embed
            className="agreement-modal--document"
            src={
              relatedAgreement?.documentUrl +
              "#statusbar=0&navpanes=0&toolbar=0&scrollbar=0&view=fitH"
            }
          />
        ) : (
          relatedAgreement?.id && <p>Empty</p>
        )}
        {amendment?.documentUrl ? (
          <embed
            className="agreement-modal--document"
            src={
              amendment?.documentUrl +
              "#statusbar=0&navpanes=0&toolbar=0&scrollbar=0&view=fitH"
            }
          />
        ) : (
          amendment?.id && <p>Empty</p>
        )}
        {ancillaryDocument?.documentUrl ? (
          <embed
            className="agreement-modal--document"
            src={
              ancillaryDocument?.documentUrl +
              "#statusbar=0&navpanes=0&toolbar=0&scrollbar=0&view=fitH"
            }
          />
        ) : (
          ancillaryDocument?.id && <p>Empty</p>
        )}
      </>
    </UIModal>
  );
};

export default AgreementModal;
