import { LoadingOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Form, Row, Col } from "antd";
import { Formik, FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS, LOGIN, RESET_PASSWORD } from "../../../routes/appRoutes";
import { AuthService } from "../../../services/AuthService/auth.service";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import InputField from "../../../shared/components/InputField";
import "./resetPassword.scss"

const ResetPassword = (props) => {

    const history = useHistory();
    const initialValues = {
        email: "",
        password: null,
        confirmPassword: null,
        token: ""
    };
    const [loading, setLoading] = useState<boolean>(false);

    const url = new URLSearchParams(history.location.search);
    initialValues.token = url.get("reset_password_token");
    initialValues.email = url.get("email");
    if (!initialValues.email || !initialValues.token) {
        history.push(RESET_PASSWORD)
    }

    const resetPasswordSubmissionHandler = (values: { email: string; password: string; token: string; }) => {
        setLoading(true)
        AuthService.resetPassword(
            values,
            () => {
                history.push(LOGIN)
            },
            () => { },
            () => {
                setLoading(false)
            }
        )
    }
    return (
        <div className="reset-password-form__container">
            <h1>Reset Password</h1>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values) => {
                }}
            >
                {({ values }) => (
                    <Form
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                resetPasswordSubmissionHandler(values);
                            }
                        }}
                    >
                        <Row >
                            <Col span={24} className="reset-password-form__wrapper">
                                <InputField
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    showEye
                                />
                            </Col>
                            <Col span={24} className="reset-password-form__wrapper">
                                <InputField
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    showEye
                                />
                            </Col>
                            <Col span={24}>
                                <div className="btn__wrapper">
                                    <ButtonComponent
                                        type={"primary"}
                                        onClick={() => {
                                            resetPasswordSubmissionHandler(values);
                                        }}
                                        iconRight={
                                            loading ? <LoadingOutlined /> : <ArrowRightOutlined />
                                        }
                                        displayType="space-between"
                                        disabled={!values.password || values.password !== values.confirmPassword}
                                    >
                                        Update & Proceed
                                    </ButtonComponent>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ResetPassword