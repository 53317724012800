import { Button, Col, Row } from "antd";
import { Formik, FormikValues, Form } from "formik";
import React, { FC, useEffect, useState } from "react";
import { ProfileModel } from "../../../../models/HFund/hfund.model";
import { UserService } from "../../../../services/UserService/user.service";
import InputField from "../../../../shared/components/InputField";

interface UserFormProps {
  user?: ProfileModel;
  closeHandler: () => void;
}

const UserForm: FC<UserFormProps> = (props) => {
  const { user, closeHandler } = props;

  const [fromValue, setFormValue] = useState<ProfileModel>(new ProfileModel());

  useEffect(() => {
    if (user) {
      setFormValue(user);
    }
  }, [user]);
  const handleSubmit = (values: FormikValues) => {
      
  };
  return (
    <Formik initialValues={fromValue} enableReinitialize onSubmit={handleSubmit}>
      <Form>
        <InputField name="firstName" placeholder="First Name" type="text" />
        <InputField name="lastName" placeholder="Last Name" type="text" />
        <InputField name="mobileNumber" placeholder="Phone Number" type="text" />
        <InputField name="email" placeholder="Email ID" type="text" />
        <Row className="mt-5" gutter={[20, 0]}>
          <Col span={12}>
            <Button className="full-width" onClick={closeHandler}>
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button type="primary" className="full-width">
              Update
            </Button>
          </Col>
        </Row>
      </Form>
    </Formik>
  );
};

export default UserForm;
