import { Col, Form, Row } from 'antd'
import { Formik, FormikHelpers } from 'formik'
import React, { FC, useState } from 'react'
import { AuthService } from '../../../services/AuthService/auth.service'
import ButtonComponent from '../../../shared/components/ButtonComponent'
import InputField from '../../../shared/components/InputField'
import { UIModal } from '../../../shared/components/UIModal'
import AuthContainer from '../../../store/containers/AuthContainer'
import { AuthReducerProps } from '../../../store/reducers/authReducer'
import { changePasswordValidationSchema } from './changePassword.validation'

interface ChangePasswordModalProps extends AuthReducerProps {
    visible: boolean
    closeHandler: () => void
}

class ChangePasswordModel {
    password: string;
    confirmPassword: string
}

const ChangePasswordModal: FC<ChangePasswordModalProps> = (props) => {

    const {
        user,
        visible,
        closeHandler,
    } = props

    const [loading, setLoading] = useState(false)


    const onSubmit = (values: ChangePasswordModel, helpers: FormikHelpers<ChangePasswordModel>) => {
        setLoading(true)
        AuthService.changePassword({
            email: user.email,
            new_password: values.password,
            isAdmin: user?.role?.toLowerCase()?.includes("hl")
        },
            () => {
                helpers.resetForm()
                closeHandler()
            },
            () => { },
            () => {
                setLoading(false)
            }
        )
    }

    return (
        <UIModal
            noControl
            title='Change Password'
            visible={visible}
            closeModal={closeHandler}>
            <Formik
                validationSchema={changePasswordValidationSchema}
                initialValues={new ChangePasswordModel()}
                onSubmit={onSubmit}>
                {({ submitForm }) => <Form>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <InputField
                                showEye
                                name={'password'}
                                type={'password'}
                                placeholder={'New password'} />
                        </Col>
                        <Col span={24}>
                            <InputField
                                showEye
                                type={'password'}
                                name={'confirmPassword'}
                                placeholder={'Confirm password'} />
                        </Col>
                        <Col span={12}>
                            <ButtonComponent
                                htmlType='button'
                                onClick={closeHandler}
                                type="default">Cancel</ButtonComponent>
                        </Col>
                        <Col span={12}>
                            <ButtonComponent
                                onClick={submitForm}
                                htmlType="submit"
                                loading={loading}
                                type="primary">Update</ButtonComponent>
                        </Col>
                    </Row>
                </Form>}
            </Formik>

        </UIModal>
    )
}

export default AuthContainer(ChangePasswordModal)