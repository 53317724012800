import { Agreement } from './../../models/Agreements/agreement.model';
import { serialize } from 'serializr';
import { AgreementDocumentHtml, AgreementKeyTerm } from './../../models/KeyTerms/agreementKeyTerms.model';
import { KeyTerms } from './../../models/KeyTerms/keyTerms.model';
import { KeyTermGroups } from './../../models/KeyTerms/keyTermGroups.model';
import { deserialize } from 'serializr';
import { useState } from 'react';
import axiosInstance from '../../interceptor/axiosInstance';
import {
    HF_AGREEMENTS_DOX_COINS_REQUEST_KEY_TERMS,
    HF_AGREEMENTS_HTML,
    HF_AGREEMENT_KEY_TERM,
    HF_AGREEMENT_KEY_TERM_REQUEST,
    HL_AGREEMENTS_HTML,
    HL_AGREEMENT_KEY_TERM,
    HL_AGREEMENT_KEY_TERM_DISCARD,
    HL_AGREEMENT_KEY_TERM_GROUPS,
    HL_AGREEMENT_KEY_TERM_IMPORT,
    HL_AGREEMENT_KEY_TERM_MARK_COMPLETE,
    STUB_BASE_URL,

} from '../../routes/apiRoutes';
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from '../../enums/notificationTypes';
import { Service } from '../../models/Service/service.model';

const AgreementKeyTermService = () => {

    const [loading, setLoading] = useState<boolean>(false)
    const [htmlContentLoading, setHtmlContentLoading] = useState<boolean>(false)
    const [markCompleteLoading, setMarkCompleteLoading] = useState<boolean>(false)
    const [htmlContent, setHtmlContent] = useState<AgreementDocumentHtml[]>([])
    const [keyTermGroups, setKeyTermGroups] = useState<KeyTermGroups[]>([])
    const [keyTerms, setKeyTerms] = useState<AgreementKeyTerm[]>([])
    const [importableAgreements, setImportableAgreements] = useState<Agreement[]>([])

    const requestAgreementKeyTerms = async (agreementId: string) => {
        setLoading(true)
        try {
            const response = await axiosInstance.get(HF_AGREEMENT_KEY_TERM_REQUEST.replace(':agreementId', agreementId))
            Notification({
                message: "Hedge Fund",
                description: "Key terms requested successfully",
                type: NotificationTypes.SUCCESS,
            });
            return new Error()
        } catch (error) {
            // Notification({
            //     message: "Hedge Fund",
            //     description: "Key Terms request failed",
            //     type: NotificationTypes.ERROR,
            // });
            throw error
        } finally {
            setLoading(false)
        }
    }

    const fetchAgreementKeyTermGroups = async (agreementId: string) => {
        setKeyTermGroups([])
        setLoading(true)
        try {
            const response = await axiosInstance.get(HL_AGREEMENT_KEY_TERM_GROUPS.replace(':agreementId', agreementId))
            const data = deserialize(KeyTermGroups, response.data['key_term_groups']) as KeyTermGroups[]
            setKeyTermGroups(data)
        } catch (error) {
            // Notification({
            //     message: "Hedge Fund",
            //     description: "Key Term Groups fetching failed",
            //     type: NotificationTypes.ERROR,
            // });
            throw error
        } finally {
            setLoading(false)
        }
    }

    const fetchAgreementKeyTerms = async (agreementId: string, params: {
        key_term_group_id: string
    }) => {
        setLoading(true)
        setKeyTerms([])
        try {
            const response = await axiosInstance.get(HL_AGREEMENT_KEY_TERM.replace(':agreementId', agreementId), { params })
            const data = deserialize(KeyTerms, response.data['agreement_key_terms']) as AgreementKeyTerm[]
            setKeyTerms(data)
        } catch (error) {
            // Notification({
            //     message: "Hedge Fund",
            //     description: "Key Terms fetching failed",
            //     type: NotificationTypes.ERROR,
            // });
            throw error
        } finally {
            setLoading(false)
        }
    }


    const fetchAgreementHFKeyTerms = async (agreementId: string, params: {
        key_term_group_id: string
    }) => {
        setLoading(true)
        setKeyTerms([])
        try {
            const response = await axiosInstance.get(HF_AGREEMENT_KEY_TERM.replace(':agreementId', agreementId), { params })
            const data = deserialize(KeyTerms, response.data['agreement_key_terms']) as AgreementKeyTerm[]
            setKeyTerms(data)
        } catch (error) {
            // Notification({
            //     message: "Hedge Fund",
            //     description: "Key Terms fetching failed",
            //     type: NotificationTypes.ERROR,
            // });
            throw error
        } finally {
            setLoading(false)
        }
    }

    const fetchAgreementHtml = async (agreementId: string) => {
        setHtmlContentLoading(true)
        const totalPages: AgreementDocumentHtml[] = []
        try {
            while (totalPages.length === 0 || totalPages[totalPages.length - 1]?.next) {
                const params = {
                    next_id: totalPages[totalPages.length - 1]?.next
                }
                const response = await axiosInstance.get(HF_AGREEMENTS_HTML.replace(':agreementId', agreementId), {
                    params
                })
                const data = deserialize(AgreementDocumentHtml, response.data["html_contents"]) as AgreementDocumentHtml[]
                totalPages.push(...data)
            }
            setHtmlContent(totalPages)
        } catch (error) {
            console.log(error);
        } finally {
            setHtmlContentLoading(false)
        }
    }

    const updateAgreementHtml = async (agreementId: string, payload: {
        id: string,
        html: string
    }) => {
        setHtmlContentLoading(true)
        try {
            const response = await axiosInstance.put(HL_AGREEMENTS_HTML.replace(':agreementId', agreementId), payload)
        } catch (error) {
            console.log(error);
        } finally {
            setHtmlContentLoading(false)
        }
    }

    const importKeyTermsFromAgreement = async (agreementId: string, params: {
        import_from: number
    }) => {
        setLoading(true)
        try {
            const response = await axiosInstance.get(HL_AGREEMENT_KEY_TERM_IMPORT.replace(':agreementId', agreementId), { params })
            // const data = deserialize(KeyTerms, response.data['agreement_key_terms']) as Agreement[]
            // setKeyTerms(data)
        } catch (error) {
            // Notification({
            //     message: "Hedge Fund",
            //     description: "Unable to import key terms",
            //     type: NotificationTypes.ERROR,
            // });
            throw error
        } finally {
            setLoading(false)
        }
    }

    const createAgreementKeyTerms = async (values: AgreementKeyTerm, agreementId: string) => {
        setLoading(true)
        try {
            const serializedData = serialize(AgreementKeyTerm, values)
            const payload = {
                agreement_key_term: serializedData
            }
            const response = await axiosInstance.post(HL_AGREEMENT_KEY_TERM.replace(':agreementId', agreementId), payload)

        } catch (error) {
            // Notification({
            //     message: "Hedge Fund",
            //     description: "Key Terms creation failed",
            //     type: NotificationTypes.ERROR,
            // });
            throw error
        } finally {
            setLoading(false)
        }
    }

    const updateAgreementKeyTerms = async (values: AgreementKeyTerm, agreementId: string) => {
        setLoading(true)
        try {
            const serializedData = serialize(AgreementKeyTerm, values)
            const payload = {
                agreement_key_term: serializedData
            }
            const response = await axiosInstance.put(HL_AGREEMENT_KEY_TERM.replace(':agreementId', agreementId) + '/' + values?.id, payload)

        } catch (error) {
            // Notification({
            //     message: "Hedge Fund",
            //     description: "Key Terms update failed",
            //     type: NotificationTypes.ERROR,
            // });
            throw error
        } finally {
            setLoading(false)
        }
    }

    const updateAgreementKeyTermsCompleted = async (agreementId: string) => {
        setMarkCompleteLoading(true)
        try {
            const response = await axiosInstance.get(HL_AGREEMENT_KEY_TERM_MARK_COMPLETE.replace(':agreementId', agreementId))

            Notification({
                message: "Hedge Fund",
                description: "Agreement marked as completed",
                type: NotificationTypes.SUCCESS,
            });
        } catch (error) {
            // Notification({
            //     message: "Hedge Fund",
            //     description: "Unable to mark agreement as completed",
            //     type: NotificationTypes.ERROR,
            // });
            throw error
        } finally {
            setMarkCompleteLoading(false)
        }
    }

    const deleteAgreementKeyTerms = async (agreementId: string) => {
        setLoading(true)
        try {
            const response = await axiosInstance.delete(HL_AGREEMENT_KEY_TERM_DISCARD.replace(':agreementId', agreementId))
            Notification({
                message: "Hedge Fund",
                description: "Key Terms discarded successfully",
                type: NotificationTypes.SUCCESS,
            });
        } catch (error) {
            // Notification({
            //     message: "Hedge Fund",
            //     description: "Key Terms discard failed",
            //     type: NotificationTypes.ERROR,
            // });
            throw error
        } finally {
            setLoading(false)
        }
    }

    const getKeyTermRequestCoins = async () => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(HF_AGREEMENTS_DOX_COINS_REQUEST_KEY_TERMS, {
                params: {
                    service: "Free Key Term Extraction",
                }
            });

            const service = deserialize(Service, data?.service);
            return service;
        } catch (error) {
            // Notification({
            //     message: "Hedge Fund",
            //     description: "Key Terms discard failed",
            //     type: NotificationTypes.ERROR,
            // });
            throw error
        } finally {
            setLoading(false)
        }
    }

    return {
        loading,
        keyTerms,
        keyTermGroups,
        htmlContent,
        setKeyTerms,
        htmlContentLoading,
        fetchAgreementHtml,
        markCompleteLoading,
        updateAgreementHtml,
        fetchAgreementKeyTerms,
        deleteAgreementKeyTerms,
        createAgreementKeyTerms,
        updateAgreementKeyTerms,
        requestAgreementKeyTerms,
        fetchAgreementHFKeyTerms,
        fetchAgreementKeyTermGroups,
        importKeyTermsFromAgreement,
        updateAgreementKeyTermsCompleted,
        getKeyTermRequestCoins
    }
}
export default AgreementKeyTermService;
