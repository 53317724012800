import { CaretDownFilled } from "@ant-design/icons";
import { Dropdown, Menu, Tooltip } from "antd";
import moment from "moment";
import React, { Dispatch, Fragment, useEffect, useState } from "react";
import { Agreement } from "../../../../models/Agreements/agreement.model";
import { ProfileModel } from "../../../../models/HFund/hfund.model";
import { Ticket } from "../../../../models/Ticket/ticket.model";
import { UserService } from "../../../../services/UserService/user.service";
import { ConfirmationAlert } from "../../../../shared/components/ConfirmationAlert";
import { TableComponent } from "../../../../shared/components/TableComponent";
import TicketModal from "../../../../shared/components/TicketModal";
import { generateStatusStyle } from "../../../../shared/utils/statusStyleGenerator";
import { TicketStatuses } from "../TicketForm/ticketDefinitions";
import { HelpService } from "./../../../../services/HelpService/helpService.service"
import "./ticketList.scss"

interface TicketListProps {
    setTicketData: Dispatch<Ticket>
    fetchTickets: Function
    updateTicket: Function
    onSearch: (value: string) => void
    tickets: Ticket[]
    loading: boolean
    setLoading: Dispatch<boolean>
}


const TicketList = (props: TicketListProps) => {

    const {
        setTicketData,
        fetchTickets,
        updateTicket,
        tickets,
        loading,
        onSearch,
    } = props
    const [ticketDeleteFormData, setDeleteTicketFormData] = useState<Ticket>()
    const [ticketDetailModal, setTicketDetailModal] = useState<Ticket>()
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [hfundUser, setHfundUser] = useState<any[]>([])

    useEffect(() => {
        fetchUsers()
    }, [])

    const fetchUsers = () => {
        UserService.listAllUsers(
            {},
            (response: ProfileModel[]) => {
                const users = response.map(user => {
                    return {
                        label: `${user.firstName || ''} ${user.lastName || ''}`,
                        value: user.id
                    }
                })
                setHfundUser(users)
            },
            () => { }
        );
    };

    const responsibleUpdateMenu = (agreement: Ticket) => {
        return updateMenu(agreement, "processedById", hfundUser);
    };

    const updateMenu = (ticket: Ticket, key: string, options) => {
        const availableOptions = options.filter(
            (option) => option.value !== ticket[key]
        );
        return (
            <Menu>
                {availableOptions?.map((option) => (
                    <Menu.Item
                        key={option.value}
                        onClick={(e) => {
                            e.domEvent.stopPropagation()
                            const updatedTicket = {
                                ...ticket,
                            };
                            updatedTicket[key] = option?.value;
                            updateTicket(updatedTicket);
                        }}
                    >
                        {option?.label}
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    const columns = [
        {
            title: "TICKET ID",
            dataIndex: "id",
            key: "id",
            width: 100
        },
        {
            title: "RAISED DATE",
            dataIndex: "raisedAt",
            key: "raisedAt",
            width: 150,
            ellipsis: { showTitle: false },
            render: text => <Tooltip placement="topLeft" title={text && moment(text).format("DD, MMM YYYY") || ""}>
                {text && moment(text).format("DD, MMM YYYY") || ""}
            </Tooltip>,
        },
        {
            title: "HFUND COMPANY",
            dataIndex: "raisedByCompany",
            key: "raisedByCompany",
            width: 150,
            ellipsis: { showTitle: false },
            render: text => <Tooltip placement="topLeft" title={text || ""}>
                {text || ""}
            </Tooltip>,
        },
        {
            title: "HFUND USER",
            dataIndex: "raisedBy",
            key: "raisedBy",
            width: 150,
            ellipsis: { showTitle: false },
            render: text => <Tooltip placement="topLeft" title={text || ""}>
                {text || ""}
            </Tooltip>,
        },
        {
            title: "MAIL ID",
            dataIndex: "email",
            key: "email",
            width: 200,
            ellipsis: { showTitle: false },
            render: text => <Tooltip placement="topLeft" title={text || ""}>
                {text || ""}
            </Tooltip>,
        },
        {
            title: "TICKET TITLE",
            dataIndex: "title",
            key: "title",
            width: 150,
            ellipsis: { showTitle: false },
            render: text => <Tooltip placement="topLeft" title={text || ""}>
                {text || ""}
            </Tooltip>,
        },
        {
            title: "TICKET DESCRIPTION",
            dataIndex: "description",
            key: "description",
            width: 250,
            ellipsis: { showTitle: false },
            render: text => <Tooltip placement="topLeft" title={text || ""}>
                {text || ""}
            </Tooltip>,
        },
        {
            title: "USER ASSIGNED",
            dataIndex: "processedBy",
            key: "processedBy",
            width: 150,
            render: (text, record) => (
                <Dropdown
                    overlayClassName="status-update--dropdown"
                    overlay={() => responsibleUpdateMenu(record)}
                    trigger={["click"]}
                >
                    <span
                        className={
                            "table-selection-dropdown" + (!text ? " empty" : "")
                        }
                        onClick={e => e.stopPropagation()}
                    >
                        {text
                            ? text
                            : "Unallocated"}{" "}
                        <CaretDownFilled />
                    </span>
                </Dropdown>
            ),
        },
        {
            title: "STATUS",
            dataIndex: "status",
            key: "status",
            width: 150,
            render: (text, record) => (
                <Dropdown
                    overlayClassName="status-update--dropdown"
                    overlay={() => updateMenu(record, "status", TicketStatuses)}
                    trigger={["click"]}
                >
                    <span
                        style={generateStatusStyle(TicketStatuses, record?.status)}
                        className={
                            "table-selection-dropdown" + (!text ? " empty" : "")
                        }
                        onClick={e => e.stopPropagation()}
                    >
                        {text ? text?.replaceAll("_", " ") : "Unallocated"}
                        <CaretDownFilled />
                    </span>
                </Dropdown>
            ),
        },
        {
            title: "",
            key: "",
            width: 100,
            render: (value, record) => {
                return <Fragment>
                    <i className="icon-edit" onClick={(e) => {
                        e.stopPropagation()
                        setTicketData(record)
                    }}></i>
                    <i className="icon-delete" onClick={(e) => {
                        e.stopPropagation()
                        setDeleteTicketFormData(record)
                    }}></i>
                </Fragment>
            }
        },
    ]

    const deleteHandler = () => {
        setSubmitting(true)
        HelpService.deleteHLTickets(
            ticketDeleteFormData.id,
            () => {
                fetchTickets()
                setDeleteTicketFormData(undefined)
            },
            (error) => { },
            () => {
                setSubmitting(false)
            },
        )
    }

    const closeDeleteHandler = () => {
        setDeleteTicketFormData(undefined)
    }
    return <div className="ticket-list__container">
        <TableComponent
            loading={loading}
            className="row-clickable"
            onRow={(i) => setTicketDetailModal(tickets[i])}
            title="Ticket List"
            columns={columns}
            pagination={false}
            data={tickets}
            scroll={{ x: 1350 }}
            onSearch={onSearch}
        />
        <TicketModal
            ticketData={ticketDetailModal}
            onClose={() => setTicketDetailModal(undefined)}
            visible={!!ticketDetailModal} />
        <ConfirmationAlert
            type="delete"
            visible={!!ticketDeleteFormData}
            title="Are your sure to delete the FAQ"
            description={ticketDeleteFormData?.title}
            deleteHandler={deleteHandler}
            closeHandler={closeDeleteHandler}
            cancelHandler={closeDeleteHandler}
        ></ConfirmationAlert>
    </div>
}

export default TicketList