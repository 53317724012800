import {
  Empty,
  Input,
  Skeleton,
  Space,
  Table,
  TablePaginationConfig,
} from "antd";
import React, { ReactElement } from "react";
import ButtonComponent from "../ButtonComponent";
import { SearchInput } from "../SearchInput";
import "./tableComponent.scss";
import { SortOrder } from "antd/lib/table/interface";
import { ReactNode } from "react";
import TableLoader from "../TableLoader";
import EmptyState from "../EmptyState";
interface TableComponentProps {
  title?: string;
  columns: any;
  data: any;
  titleHelper?: ReactNode;
  showSearch?: boolean;
  loading?: boolean;
  onRow?: (index: number, record?: any) => void;
  onSearch?: (value: string) => void;
  showAdd?: boolean;
  onAdd?: () => void;
  pagination?: false | TablePaginationConfig;
  sortDirections?: SortOrder[];
  scroll?: any;
  additionalButtons?: ReactNode;
  rowSelection?: any;
  onChange?: any;
  className?: string;
  rowKey?: string;
  expandable?: any;
  locale?: any;
  addComponent?: ReactElement;
  showCheckbox?: Boolean
}
export const TableComponent = ({
  title,
  titleHelper,
  data,
  columns,
  showSearch = true,
  onRow = () => { },
  showAdd = false,
  onSearch,
  onAdd,
  additionalButtons,
  pagination = false,
  sortDirections,
  scroll,
  rowSelection,
  loading,
  onChange,
  className = "",
  rowKey,
  expandable,
  locale,
  addComponent,
  showCheckbox = false
}: TableComponentProps) => {
  // const [header, setHeader] = React.useState<any>()
  // React.useEffect(() => {
  //     let format = []
  //     columns?.map((value, index) =>{
  //         format.push({
  //             title: value,
  //             dataIndex: value?.toLowerCase(),
  //             key: value?.toLowerCase(),
  //         })
  //     })
  //     setHeader(format)
  // }, [])
  return (
    <>
      {(title || titleHelper || showSearch || additionalButtons || showAdd) && (
        <div className="table-title__container">
          <p>
            {title}
            {titleHelper}
          </p>
          <Space className="table-title__container--btn-list">
            {showSearch && (
              <SearchInput
                onSearch={(value) => {
                  onSearch && onSearch(value);
                }}
              />
            )}
            {additionalButtons}
            {showAdd ? (
              addComponent ? (
                addComponent
              ) : (
                <div className="table-add__btn">
                  <ButtonComponent
                    onClick={onAdd}
                    type="primary"
                    icon={<i className="icon-add light" />}
                  >
                    {" "}
                  </ButtonComponent>
                </div>
              )
            ) : null}
          </Space>
        </div>
      )}
      {showCheckbox && <Table
        expandable={expandable}
        onChange={onChange}
        rowSelection={{
          type: "checkbox",
          onChange,
        }}
        showSorterTooltip={false}
        pagination={pagination}
        scroll={scroll}
        className={"table-container " + className}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => onRow(rowIndex, record),
          };
        }}
        dataSource={loading ? [] : data}
        columns={columns}
        rowKey={rowKey || "id"}
        locale={{
          emptyText: loading ? <TableLoader /> : <EmptyState />,
        }}
      />}

      {!showCheckbox && <Table
        expandable={expandable}
        onChange={onChange}
        showSorterTooltip={false}
        pagination={pagination}
        scroll={scroll}
        className={"table-container " + className}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => onRow(rowIndex, record),
          };
        }}
        dataSource={loading ? [] : data}
        columns={columns}
        rowKey={rowKey || "id"}
        locale={{
          emptyText: loading ? <TableLoader /> : <EmptyState />,
        }}
      />}
    </>
  );
};
