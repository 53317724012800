import { Checkbox, Dropdown, Menu, Space, Tag, Tooltip } from "antd";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { TableComponent } from "../../../../../shared/components/TableComponent";
import NotebookIcon from "../../../../../assets/icons/notebook.svg";
import EditIcon from "../../../../../assets/icons/edit.svg";
import DeleteIcon from "../../../../../assets/icons/delete.svg";
import DownloadIcon from "../../../../../assets/icons/download.svg";
import ContactIcon from "../../../../../assets/icons/contact.svg";
import BellIcon from "../../../../../assets/icons/bell-2.svg";
import moment from "moment";
import PopoverComponent from "../../../../../shared/components/PopoverComponent";
import KeyContacts from "../../../../../shared/components/KeyContacts";
import TableNotes from "../../../../../shared/components/TableNotes";
import TableNotifications from "../../../../../shared/components/TableNotifications";
import {
  Agreement,
  AgreementStatus,
} from "../../../../../models/Agreements/agreement.model";
import { ConfirmationAlert } from "../../../../../shared/components/ConfirmationAlert";
import { NotificationTypes } from "../../../../../enums/notificationTypes";
import Notification from "../../../../../shared/components/Notification";
import { HLAgreementService } from "../../../../../services/AgreementService/agreement.service";
import { AGREEMENTS_SPECIFIC } from "../../../../../routes/appRoutes";
import { useHistory } from "react-router";
import { LocalStorage } from "../../../../../shared/utils/localStorageHelpers";
import {
  CaretDownFilled,
  CaretDownOutlined,
  DownloadOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import AgreementForm from "../../Agreements/AgreementForm";
import ButtonComponent from "../../../../../shared/components/ButtonComponent";
import { Fund } from "../../../../../models/Fund/fund.model";
import { deserialize } from "serializr";
import { ResponsibleUser } from "../../../../../models/Fund/onboarding.model";
import { MetaService } from "../../../../../services/MetaService/meta.service";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { generateStatusStyle } from "../../../../../shared/utils/statusStyleGenerator";
import { AgreementTypes } from "../../../../../models/Agreements/agreementTypes.model";
import { CounterParties } from "../../../../../models/CounterParties/counterParties.model";
import { HLCounterPartiesService } from "../../../../../services/CounterPartiesService/CounterParties.service";
import { HLFundsService } from "../../../../../services/FundService/Fund.service";
import AgreementTypesService from "../../../../../services/KeyTermsService/AgreementTypes.service";
import TableFilterDropDown from "../../../../../shared/components/TableFilterDropDown";
import { dateSorter, sorter } from "../../../../../shared/utils/tableSorter";
import { fileDownloadFromUrl } from "../../../../../shared/utils/fileDownloader";
import { sittingWithOptions, agreementTiers, agreementProgress, downloadAllChoices } from "../../../../HF/Home/Agreements/agreementsDefinitions";
import { NotificationType } from "../../../../../enums/notificationType";
import { PaginationMeta } from "../../../../../models/Meta/pagination-meta.model";
import { OnboardingItems } from "../../../../../models/Meta/meta.model";

interface FundAgreementsProps {
  fund: Fund;
  closeHandler?: Function;
}

const FundAgreements: FC<FundAgreementsProps> = (props) => {
  let { closeHandler, fund } = props;
  const history = useHistory();

  const [visible, setVisible] = useState<boolean>(false);
  const [selectedAgreement, setSelectedAgreement] = useState<Agreement>();
  const [pagination, setPagination] = useState<PaginationMeta>();
  const [showDelete, setDeleteVisibility] = useState(false);
  const [agreements, setAgreements] = useState<Agreement[]>([]);
  const [agreementStatus, setAgreementStatus] = useState<any[]>([]);
  const [responsibleUsers, setResponsibleUsers] = useState<any[]>([]);
  const [successVisible, setSuccessVisibility] = useState(false);
  const [downloadChoices, setDownloadChoices] = useState<
    string[] | CheckboxValueType[]
  >(["all"]);
  const choicePopoverButton = useRef(null);
  const [counterParties, setCounterParties] = useState<any[]>([]);
  const [funds, setFunds] = useState<any[]>([]);
  const [agreementTypes, setAgreementTypes] = useState<any[]>([]);
  const [availableDates, setAvailableDates] = useState<any[]>([]);
  const [sortInfo, setSortInfo] = useState<{ key: string; order: string }>();
  const [filterInfo, setFilterInfo] = useState({});
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    if (!fund.id) { return }
    fetchAgreements(filterInfo);
    fetchFundsList();
    fetchCPList();
    fetchAgreementTypes();
    fetchAgreementStatus();
  }, [fund]);

  useEffect(() => {
    setTableLoading(true);
    fetchAgreements(filterInfo);
  }, [filterInfo]);

  const fetchAgreementStatus = () => {
    MetaService.agreementStatus(
      (response) => {
        const data = deserialize(
          AgreementStatus,
          response.data["agreement_statuses"]
        ) as AgreementStatus[];
        const formattedData = data.map((sts) => {
          return {
            label: sts.name.replaceAll("_", " "),
            value: sts.id,
            color: sts.color,
          };
        });
        setAgreementStatus(formattedData);
      },
      (error) => {
        //console.log(error);
      }
    );
  };

  const fetchCPList = () => {
    HLCounterPartiesService.fetchCounterParties(
      { meta: true },
      (response: CounterParties[]) => {
        setCounterParties(deserialize(OnboardingItems, response));
      },
      () => { },
      () => { }
    );
  };

  const fetchFundsList = () => {
    HLFundsService.fetchFunds(
      { meta: true },
      (response: Fund[]) => {
        setFunds(deserialize(OnboardingItems, response));
      },
      (error) => {
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to fetch funds",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  const fetchAgreementTypes = () => {
    AgreementTypesService.fetchAgreementTypes(
      (response: AgreementTypes[]) => {
        const foramattedData = response.map((agreement) => {
          return { label: agreement.name, value: agreement.id };
        });
        setAgreementTypes(foramattedData);
      },
      () => { },
      () => { }
    );
  };

  useEffect(() => {
    if (selectedAgreement?.fundId) {
      fetchResponsibleUsers(selectedAgreement.fundId.toString());
    }
  }, [selectedAgreement]);

  const deleteHandler = (agreement: Agreement) => {
    HLAgreementService.deleteAgreement(
      agreement,
      (res) => {
        fetchAgreements(filterInfo);
        setSelectedAgreement(null);
        setDeleteVisibility(false);
        Notification({
          message: "Hedge Fund",
          description: "Agreement deleted successfully",
          type: NotificationTypes.SUCCESS,
        });
      },
      (err) => {
        ;
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Agreement deletion failed",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  const dowloadAllChoicesHandler = (downloadChoices: any[]) => {
    const params = {};
    downloadChoices.forEach((value) => {
      params[value] = true;
    });
    HLAgreementService.downloadAgreements(
      params,
      (response) => {
        setDownloadChoices(["all"]);;
        choicePopoverButton.current.click();
        setSuccessVisibility(true);
      },
      (error) => {
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to download all the agreements",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  const fetchAgreements = (filter) => {
    setTableLoading(true);
    HLAgreementService.fetchAgreements(
      {
        fund_ids: [fund?.id?.toString()],
        ...filter,
      },
      (response, pagination) => {
        setAgreements(response);
        setPagination(pagination)
        const allAvailableDates = response.map((agreement) => agreement.date);
        if (!!availableDates?.length && allAvailableDates?.length) {
          const uniqueDates = [];
          allAvailableDates.forEach((date) => {
            if (!uniqueDates.includes(date) && !!date) {
              uniqueDates.push(date);
            }
          });
          setAvailableDates(
            uniqueDates.map((date) => {
              return {
                label: moment(date).format("DD MMM, YYYY"),
                value: date,
              };
            })
          );
        }
      },
      (error) => {
        // console.log(error);
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to fetch agreements",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => {
        setTableLoading(false);
      }
    );
  };

  const sittingUpdateMenu = (agreement: Agreement) => {
    const availableSitting = sittingWithOptions.filter(
      (sitting) => sitting.value !== agreement?.sittingWith
    );
    return (
      <Menu>
        {availableSitting?.map((sitting) => (
          <Menu.Item
            key={sitting.value}
            onClick={(e) => {
              e?.domEvent?.stopPropagation();
              const updatedAgreement = {
                ...agreement,
                sittingWith: sitting.value,
              };
              updateAgreement(updatedAgreement);
            }}
          >
            {sitting.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  const statusUpdateMenu = (agreement: Agreement) => {
    const availableStatus = agreementStatus.filter(
      (status) => status.value !== agreement?.statusId
    );
    return (
      <Menu>
        {availableStatus?.map((status) => (
          <Menu.Item
            key={status.value}
            onClick={(e) => {
              e?.domEvent?.stopPropagation();
              const updatedAgreement = { ...agreement, statusId: status.value };
              updateAgreement(updatedAgreement);
            }}
          >
            {status.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  const tierUpdateMenu = (agreement: Agreement) => {
    const availableTiers = agreementTiers.filter(
      (tier) => tier.value !== agreement?.tier
    );
    return (
      <Menu>
        {availableTiers?.map((tier) => (
          <Menu.Item
            key={tier.value}
            onClick={(e) => {
              e?.domEvent?.stopPropagation();
              const updatedAgreement = { ...agreement, tier: tier.value };
              updateAgreement(updatedAgreement);
            }}
          >
            {tier.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const responsibleUpdateMenu = (agreement: Agreement) => {
    setSelectedAgreement(agreement);
    const availableUsers = responsibleUsers.filter(
      (user) => user.value !== agreement?.responsibleUserId
    );
    return (
      <Menu>
        {availableUsers?.map((user) => (
          <Menu.Item
            key={user.value}
            onClick={(e) => {
              e?.domEvent?.stopPropagation();
              const updatedAgreement = {
                ...agreement,
                responsibleUserId: user.value,
              };
              updateAgreement(updatedAgreement);
            }}
          >
            {user.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const updateAgreement = (values: Agreement) => {
    HLAgreementService.updateAgreement(
      values,
      (response) => {
        fetchAgreements(filterInfo);
        closeHandler && closeHandler()
      },
      (error) => {
        // console.log(error);
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to update agreement",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  const fetchResponsibleUsers = (fundId: string) => {
    MetaService.responsibleHFUsers(
      fundId,
      (res) => {
        const data = deserialize(
          ResponsibleUser,
          res.data["users"]
        ) as ResponsibleUser[];
        setResponsibleUsers(
          data.map((data) => {
            return {
              value: data.id,
              label: data.firstName + " " + data.lastName,
            };
          })
        );
      },
      (err) => {
        // ;
      }
    );
  };

  const handleClearFilters = () => {
    setFilterInfo(Object.create({}));
    setSortInfo(Object.create({}));
  };

  const sortHandler = (key: string, order: string) => {
    setSortInfo({
      key,
      order,
    });
  };

  const filterHandler = (key: string, value: any) => {
    const currentFilter = { ...filterInfo };
    currentFilter[key] = value;
    if (JSON.stringify(filterInfo) === JSON.stringify(currentFilter)) {
      return;
    }
    setFilterInfo({ ...currentFilter, page: 1 });
  };
  const filterProperties = (dataIndex: string, sortIndex: string, records) => {
    return {
      filterDropdown: (filterProps) => (
        <TableFilterDropDown
          {...filterProps}
          defaultSelected={filterInfo[dataIndex]}
          onApply={(key, value) => filterHandler(key, value)}
          dataIndex={dataIndex}
          sortIndex={sortIndex}
          checkboxValues={records}
          onSort={sortHandler}
        />
      ),
      filterIcon: () => <CaretDownOutlined />,
    };
  };

  const exportAgreements = () => {
    if (fund.id)
      HLAgreementService.exportAgreements(
        {
          fund_ids: [fund.id + ""]
        },
        () => { },
        () => { },
        () => { },
      )
  };
  return (
    <>
      <TableComponent
        className="sort-remover"
        titleHelper={
          <span className="title-helper" onClick={handleClearFilters}>
            Clear All Filters
          </span>
        }
        showAdd={true}
        loading={tableLoading}
        onAdd={() => {
          setVisible(true);
        }}
        showSearch={false}
        scroll={{ x: 1650, y: 'calc(100vh - 210px - 60px)' }}
        title={"Agreements"}
        onChange={pagination => {
          setFilterInfo(prev => ({ ...prev, page: pagination?.current || 1 }))
        }}
        pagination={{
          pageSize: 20,
          total: pagination?.totalCount,
          current: pagination?.currentPage,
          showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
        }}
        onRow={(index: number, record) => {
          history.push(
            AGREEMENTS_SPECIFIC.replace(
              ":agreementId",
              record.id.toString()
            )
          );
        }}
        columns={[
          {
            title: "AGREEMENT NAME",
            key: "name",
            dataIndex: "name",
            width: 200,
            render: (text) => <Tooltip placement="topLeft" title={text || ""}>
              <span>{text ?? ""}</span>
            </Tooltip>
          },
          {
            title: "CP NAME",
            dataIndex: "cpName",
            key: "cpName",
            sorter: (a, b) => sorter(a.cpName, b.cpName),
            sortOrder: sortInfo?.key === "cpName" && sortInfo?.order,
            ...filterProperties("counter_party_ids", "cpName", counterParties),
            width: 150,
            render: (text) => <Tooltip placement="topLeft" title={text || ""}>
              <span>{text ?? ""}</span>
            </Tooltip>
          },
          {
            title: "TYPE",
            key: "agreementTypeName",
            dataIndex: "agreementTypeName",
            sorter: (a, b) => sorter(a.agreementTypeName, b.agreementTypeName),
            sortOrder: sortInfo?.key === "agreementTypeName" && sortInfo?.order,
            ...filterProperties(
              "agreement_type_ids",
              "agreementTypeName",
              agreementTypes
            ),
            width: 150,
            render: (text) => <Tooltip placement="topLeft" title={text?.replaceAll("_", " ") || ""}>
              <span>{text && text?.replaceAll("_", " ")}</span>
            </Tooltip>
          },
          {
            title: "DATE",
            dataIndex: "date",
            key: "date",
            sorter: (a, b) => dateSorter(a.date, b.date),
            sortOrder: sortInfo?.key === "date" && sortInfo?.order,
            ...filterProperties("dates", "date", availableDates),
            width: 150,
            // render: (date: string) => moment(date).format("MMM D, YYYY")
            render: (date: string) => date ? moment(date).format("MMM D, YYYY") : "",
          },
          {
            title: "TIER",
            dataIndex: "tier",
            key: "tier",
            sorter: (a, b) => sorter(a.tier, b.tier),
            sortOrder: sortInfo?.key === "tier" && sortInfo?.order,
            ...filterProperties("tiers", "tier", agreementTiers),
            width: 100,
            render: (text, record) => (
              <Dropdown
                overlayClassName="status-update--dropdown"
                overlay={() => tierUpdateMenu(record)}
                trigger={["click"]}
              >
                <span
                  onClick={(e) => e?.stopPropagation()}
                  className={
                    "table-selection-dropdown" + (!text ? " empty" : "")
                  }
                >
                  {text ? text : "Unallocated"}
                  <CaretDownFilled />
                </span>
              </Dropdown>
            ),
          },
          {
            title: "STATUS",
            dataIndex: "statusName",
            key: "statusName",
            sorter: (a, b) => sorter(a.statusName, b.statusName),
            sortOrder: sortInfo?.key === "statusName" && sortInfo?.order,
            ...filterProperties(
              "agreement_status_ids",
              "statusName",
              agreementStatus
            ),
            width: 150,
            render: (text, record) => (
              <Dropdown
                overlayClassName="status-update--dropdown"
                overlay={() => statusUpdateMenu(record)}
                trigger={["click"]}
              >
                <span
                  onClick={e => e?.stopPropagation()}
                  className={
                    "table-selection-dropdown" + (!text ? " empty" : "")
                  }
                  style={generateStatusStyle(agreementStatus, record?.statusId)}
                >
                  {text ? text?.replaceAll("_", " ") : "Unallocated"}
                  <CaretDownFilled />
                </span>
              </Dropdown>
            ),
          },
          {
            title: "PROGRESS",
            dataIndex: "statusProgress",
            key: "statusProgress",
            sorter: (a, b) => sorter(a.statusProgress, b.statusProgress),
            sortOrder: sortInfo?.key === "statusProgress" && sortInfo?.order,
            ...filterProperties(
              "progress_percents",
              "statusProgress",
              agreementProgress
            ),
            width: 150,
            render: (text) => <span>{text + " %"}</span>,
          },
          {
            title: "SITTING WITH",
            dataIndex: "sittingWith",
            key: "sittingWith",
            sorter: (a, b) => sorter(a.sittingWith, b.sittingWith),
            sortOrder: sortInfo?.key === "sittingWith" && sortInfo?.order,
            ...filterProperties(
              "sitting_with",
              "sittingWith",
              sittingWithOptions
            ),
            width: 200,
            render: (text, record) => (
              <Dropdown
                overlayClassName="status-update--dropdown"
                overlay={() => sittingUpdateMenu(record)}
                trigger={["click"]}
              >
                <span
                  onClick={e => e?.stopPropagation()}
                  className={
                    "table-selection-dropdown" + (!text ? " empty" : "")
                  }
                >
                  {text ? text === "cp" ? "CP" : text?.replaceAll("_", " ") : "Unallocated"}{" "}
                  <CaretDownFilled />
                </span>
              </Dropdown>
            ),
          },
          {
            title: "RESPONSIBLE",
            dataIndex: "responsibleFirstName",
            key: "responsibleFirstName",
            width: 200,
            render: (text, record) => (
              <Dropdown
                overlayClassName="status-update--dropdown"
                overlay={() => responsibleUpdateMenu(record)}
                trigger={["click"]}
              >
                <span
                  onClick={e => e?.stopPropagation()}
                  className={
                    "table-selection-dropdown" + (!text ? " empty" : "")
                  }
                >
                  {text
                    ? text + " " + record?.responsibleLastName
                    : "Unallocated"}{" "}
                  <CaretDownFilled />
                </span>
              </Dropdown>
            ),
          },
          // {
          //   title: "KEY TERMS",
          //   dataIndex: "keyTerms",
          //   key: "keyTerms",
          //   width: 100,
          // },
          {
            title: "",
            key: "action",
            width: 225,
            render: (text, record: Agreement) => (
              <Space size="middle" onClick={e => e?.stopPropagation()}>
                <PopoverComponent
                  showTitleButton={true}
                  title="Notes"
                  content={<TableNotes id={record?.id} category="Agreement" />}
                >
                  <img
                    className="table-action-icon"
                    src={NotebookIcon}
                    onClick={(e) => e?.stopPropagation()}
                    alt=''
                    title="Notes"
                  />
                </PopoverComponent>
                <PopoverComponent
                  showTitleButton={true}
                  title="Key contacts"
                  content={
                    <KeyContacts
                      contactableType="Agreement"
                      contactableId={record?.id.toString()}
                    />
                  }
                >
                  <img
                    className="table-action-icon"
                    src={ContactIcon}
                    onClick={(e) => e?.stopPropagation()}
                    alt=''
                    title="Key contacts"
                  />
                </PopoverComponent>
                <PopoverComponent
                  showTitleButton={true}
                  title="Notifications"
                  content={
                    <TableNotifications
                      id={record.id}
                      category={NotificationType.AGREEMENT}
                      user="Hedge Fund"
                    />
                  }
                >
                  <img
                    className="table-action-icon"
                    src={BellIcon}
                    onClick={(e) => e?.stopPropagation()}
                    alt=''
                    title="Notifications"
                  />
                </PopoverComponent>
                <img
                  className="table-action-icon"
                  src={DownloadIcon}
                  onClick={(e) => {
                    e?.stopPropagation();
                    HLAgreementService.fetchSingleAgreements(
                      record?.id,
                      (data) => {
                        fileDownloadFromUrl(data)
                      },
                      () => { },
                      () => { },
                    )
                  }}
                  alt=''
                  title="Download"
                />
                <img
                  className="table-action-icon"
                  src={EditIcon}
                  onClick={(e) => {
                    e?.stopPropagation();
                    setSelectedAgreement(record);
                    setVisible(true);
                  }}
                  alt=''
                  title="Edit"
                />
                <img
                  className="table-action-icon"
                  src={DeleteIcon}
                  onClick={(e) => {
                    e?.stopPropagation();
                    setSelectedAgreement(record);
                    setDeleteVisibility(true);
                  }}
                  alt=''
                  title="Delete"
                />
              </Space>
            ),
          },
        ]}
        additionalButtons={
          <>
            <PopoverComponent
              className="download-all"
              showHeader={false}
              content={
                <>
                  <Checkbox.Group
                    className="download-all-options"
                    options={downloadAllChoices}
                    value={downloadChoices}
                    onChange={(values) => setDownloadChoices(values)}
                  />
                  <ButtonComponent
                    size="small"
                    type="primary"
                    className="download-all--btn"
                    onClick={() => dowloadAllChoicesHandler(downloadChoices)}
                  >
                    Download
                  </ButtonComponent>
                </>
              }
            >
              <div ref={choicePopoverButton}>
                <ButtonComponent
                  type="default"
                  size="large"
                  icon={<DownloadOutlined />}
                />
              </div>
            </PopoverComponent>
            <ButtonComponent
              type="default"
              size="large"
              onClick={exportAgreements}
              icon={<i className="icon-export primary" />}
            />
          </>
        }
        data={agreements}
      />

      <ConfirmationAlert
        closeHandler={() => {
          setSelectedAgreement(null);
          setDeleteVisibility(false);
        }}
        visible={showDelete}
        type="delete"
        deleteHandler={() => {
          deleteHandler(selectedAgreement);
        }}
        cancelHandler={() => {
          setSelectedAgreement(null);
          setDeleteVisibility(false);
        }}
        title={"Delete Agreement"}
        description={selectedAgreement?.name}
      />
      <AgreementForm
        fundId={fund?.id}
        agreement={selectedAgreement}
        visible={visible}
        funds={funds}
        counterParties={counterParties}
        agreementStatus={agreementStatus}
        agreementTypes={agreementTypes}
        closeHandler={(agreement?: Agreement) => {
          if (agreement?.id) {
            fetchAgreements(filterInfo);
          }
          setSelectedAgreement(null);
          setVisible(false);
        }}
      />
      <ConfirmationAlert
        closeHandler={() => {
          setSuccessVisibility(false);
        }}
        visible={successVisible}
        type="success"
        customButtons={
          <div className="download-success--buttons">
            <ButtonComponent
              onClick={() => {
                setSuccessVisibility(false);
              }}
              type="ghost"
            >
              Close
            </ButtonComponent>
          </div>
        }
        cancelHandler={() => {
          setSuccessVisibility(false);
        }}
        title={"Download request successful"}
        description="Link to the documents will be shared with your mail id shortly.."
      />
    </>
  );
};
export default FundAgreements;
