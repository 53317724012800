import React, { FC, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import "./paymentInformation.scss";
import { Button } from "antd";
import { useHistory, useParams } from "react-router";
import { HOME } from "../../../routes/appRoutes";
import { MetaService } from "../../../services/MetaService/meta.service";
import { LocalStorage } from "../../../shared/utils/localStorageHelpers";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/containers/AuthContainer";
import { deserialize } from "serializr";
import { ProfileModel } from "../../../models/HFund/hfund.model";

interface PaymentInformationProps extends AuthReducerProps { }

const PaymentInformation: FC<PaymentInformationProps> = (props) => {
  const { user, setUserType, setAuthenticated } = props;

  const stripe = useStripe();

  const elements = useElements();

  const params = useParams<{ companyId: string; subscriptionId: string }>();

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleMakePayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    const cardElement: any = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        // name: values.nameOnCard ?? "",
        // email: values.email,
        // address: {
        //   line1: values.billingAddress,
        //   country: values.country?.name,
        //   postal_code: values.postalCode,
        // },
      },
    });

    if (error) {
      setLoading(false);
      return;
    }

    MetaService.subscribeService(
      params?.companyId,
      {
        subscriptionPlanId: params?.subscriptionId,
      },
      (company) => {
        setAuthenticated(deserialize(ProfileModel, company?.data?.company?.admin));
        setUserType("HF");
        LocalStorage.setItem("USER", deserialize(ProfileModel, company?.data?.company?.admin));
        Notification({
          message: "Hedge Fund",
          description: "Logged in successfully",
          type: NotificationTypes.SUCCESS,
        });
        history.push(HOME, company);
      },
      () => { },
      () => {
        setLoading(false);
      }
    );
  };

  return (
    <div className="payment-information">
      <h2 className="text-primary">Payment information</h2>
      <p>Card Information</p>
      <CardElement
        className="card-wrapper"
        options={{
          hidePostalCode: true,
          style: {
            base: {
              fontSize: "16px",
              color: "#424770",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#9e2146",
            },
          },
        }}
      />
      <Button
        type="primary"
        className="full-width space-between mt-5"
        onClick={handleMakePayment}
      >
        <span>Make Payment</span> <i className="icon-proceed" />
      </Button>
    </div>
  );
};

export default AuthContainer(PaymentInformation);
