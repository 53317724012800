
import { serializable, alias, primitive } from 'serializr';

export class Comment {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('company_name', primitive()))
	companyName?: string;

	@serializable(alias('user_name', primitive()))
	userName?: string;

	@serializable(alias('date', primitive()))
	date?: string;

	@serializable(alias('description', primitive()))
	description?: string;

	@serializable(alias('created_by_id', primitive()))
	createdBy?: number;

	// descrption

	@serializable(alias('no_of_replies', primitive()))
	noOfReplies?: number;

	@serializable(alias('likes', primitive()))
	likes?: number;

	@serializable(alias('dislikes', primitive()))
	dislikes?: number;

	@serializable(alias('you_like', primitive()))
	youLike?: string;

	@serializable(alias('you_dislike', primitive()))
	youDislike?: string;

}