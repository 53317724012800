import { Form, Row, Col } from "antd";
import { Formik, FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { ProfileModel } from "../../../../models/HFund/hfund.model";
import { Ticket } from "../../../../models/Ticket/ticket.model";
import { CompanyService } from "../../../../services/CompanyService/company.service";
import { HelpService } from "../../../../services/HelpService/helpService.service";
import { UserService } from "../../../../services/UserService/user.service";
import DatePickerComponent from "../../../../shared/components/DatePickerComponent";
import DropdownField from "../../../../shared/components/DropdownField";
import InputField from "../../../../shared/components/InputField";
import { UIModal } from "../../../../shared/components/UIModal";
import { fundStructure } from "../../../HF/Home/Funds/FundForm/fundDefinitions";
import { FundFormValidation } from "../../../HF/Home/Funds/FundForm/fundFormValidation";
import { TicketStatuses } from "./ticketDefinitions"
import { TicketFormValidation } from "./ticketFormValidation";
interface TicketFormProps {
    visible: boolean
    closeHandler: (id?: number) => void
    ticketData: Ticket
}

const TicketForm = (props: TicketFormProps) => {

    const {
        visible,
        closeHandler,
        ticketData = new Ticket()
    } = props

    const [loading, setLoading] = useState<boolean>(false)
    const [hfundUser, setHfundUser] = useState<any[]>([])
    const [hfUsers, setHFUsers] = useState<any[]>([])
    const [companies, setCompanies] = useState<any[]>([]);

    const submitHandler = (values: FormikValues, resetForm: Function) => {
        setLoading(true)
        if (ticketData.id) {
            HelpService.editHLTickets(
                values,
                (res: Ticket) => {
                    resetForm()
                    closeHandler(res?.id)
                },
                () => { },
                () => {
                    setLoading(false)
                },
            )
        } else {
            HelpService.createHLTickets(
                values,
                (res: Ticket) => {
                    closeHandler(res?.id)
                },
                () => { },
                () => {
                    setLoading(false)
                },
            )
        }
    }

    useEffect(() => {
        fetchUsers()
        fetchCompanies()
    }, [])

    useEffect(() => {
        if (ticketData?.hfundCompanyId) {
            fetchHFUsers(ticketData?.hfundCompanyId)
        }
    }, [ticketData])

    const fetchUsers = () => {
        UserService.listAllUsers(
            {},
            (response: ProfileModel[]) => {
                const users = response.map(user => {
                    return {
                        label: `${user.firstName || ''} ${user.lastName || ''}`,
                        value: user.id
                    }
                })
                setHfundUser(users)
            },
            () => { }
        );
    };
    const fetchHFUsers = (companyId?: number) => {
        UserService.listAllHFUsers(
            {
                company_id: [companyId]
            },
            (response: ProfileModel[]) => {
                const users = response.map(user => {
                    return {
                        label: `${user.firstName || ''} ${user.lastName || ""}`,
                        value: user.id
                    }
                })
                setHFUsers(users)
            },
            (err) => {
            }
        );
    };

    const fetchCompanies = () => {
        CompanyService.listAllCompanies(
            {},
            (data) => {
                const formattedData = data.map((data) => {
                    return {
                        value: data?.companyId,
                        label: data?.companyName,
                    };
                })
                setCompanies(formattedData);
            },
            (err) => {
            }
        );
    };

    return (
        <Formik enableReinitialize
            initialValues={ticketData}
            onSubmit={() => { }}
            validationSchema={TicketFormValidation}
        >
            {({ setFieldValue, validateField, values, dirty, errors, resetForm, isValid, setFieldTouched }) => {
                return (
                    <UIModal
                        className="ticket-form__modal"
                        visible={visible}
                        closeModal={() => closeHandler()}
                        title={(ticketData.id ? "Edit " : "Add New ") + " Ticket"}
                        submitProps={{
                            text: ticketData.id ? "Update " : "Create",
                            clickHandler: () => {
                                submitHandler(values, resetForm);
                            },
                            disabled: !isValid || !dirty,
                            loading
                        }}
                        cancelProps={{
                            text: "Cancel",
                            clickHandler: () => {
                                resetForm();
                                closeHandler();
                            },
                        }}
                    >
                        <Form>
                            <Row>
                                <Col sm={24} className="ticket-form__field">
                                    <DatePickerComponent
                                        name="date"
                                        placeholder="Date"
                                        className="input-field"
                                        value={values.raisedAt}
                                        onChange={(value, string) => {
                                            setFieldValue("raisedAt", string);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="ticket-form__field">
                                    <DropdownField
                                        mode="single"
                                        optionFilterProp="label"
                                        placeholder={"HFund Company *"}
                                        name="hfundCompany"
                                        options={companies}
                                        showSearch={false}
                                        value={values.hfundCompany || values.raisedByCompany}
                                        onChange={(value) => {
                                            setFieldValue("raisedById", null);
                                            setFieldValue("raisedBy", null);
                                            fetchHFUsers(value)
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="ticket-form__field">
                                    <DropdownField
                                        mode="single"
                                        optionFilterProp="label"
                                        placeholder={"HFund User *"}
                                        name="raisedById"
                                        options={hfUsers}
                                        showSearch={false}
                                        value={values.raisedById || values.raisedBy}
                                        onChange={(value) => {
                                            setFieldValue("raisedById", value);

                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="ticket-form__field">
                                    <InputField
                                        placeholder={"Ticket Title *"}
                                        name="title"
                                        type="text"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="ticket-form__field">
                                    <InputField
                                        placeholder={"Ticket Description *"}
                                        name="description"
                                        type="textarea"
                                    />
                                </Col>
                            </Row> <Row>
                                <Col sm={24} className="ticket-form__field">
                                    <DropdownField
                                        mode="single"
                                        optionFilterProp="label"
                                        placeholder={"User Assigned *"}
                                        name="processedById"
                                        options={hfundUser}
                                        showSearch={false}
                                        value={values.processedById || values.processedBy}
                                        onChange={(value) => {
                                            setFieldValue("processedById", value);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="ticket-form__field">
                                    <DropdownField
                                        mode="single"
                                        optionFilterProp="label"
                                        placeholder={"Status *"}
                                        name="status"
                                        options={TicketStatuses}
                                        showSearch={false}
                                        value={values.status}
                                        onChange={(value) => {
                                            setFieldValue("status", value);
                                        }}
                                    />
                                </Col>
                            </Row>

                        </Form>
                    </UIModal>
                );
            }}
        </Formik>
    );
}

export default TicketForm