import { PaginationMeta } from './../../models/Meta/pagination-meta.model';
import {
  UserCompaniesModel,
  CompanyModel,
} from "./../../models/HFund/hfund.model";
import { OnboardingItem } from "./../../models/Fund/onboarding.model";
import { FundStatusBE } from "./../../enums/fundStatuses";
import { Fund } from "./../../models/Fund/fund.model";
import * as ApiRoutes from "../../routes/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { generatePath } from "react-router";
import { deserialize, serialize } from "serializr";
import { OtherItem } from "../../models/Fund/otherItems.model";
import { NotificationModel } from "../../models/Meta/notification.model";
import { LocalStorage } from "../../shared/utils/localStorageHelpers";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from '../../enums/notificationTypes';

const getUserType = () => {
  const user = LocalStorage.getItem("USER");
  return user?.role?.includes("hf") ? "hf" : "hl";
};
export class FundsService {
  static fetchFunds(
    params: {
      status?: FundStatusBE;
      search_text?: string;
      meta?: boolean;
      company_ids?: string[];
      order_by?: "recently_viewed";
      order_direction?: "asc" | "dsc";
      page?: number;
      per_page?: number;
    },
    onSuccess: (funds: Fund[], pagination?: PaginationMeta) => void,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HF_FUNDS);
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        const data = deserialize(Fund, response.data["funds"]) as Fund[];
        const pagination = deserialize(PaginationMeta, response.data["meta"]);
        onSuccess(data, pagination);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createFund(
    fund: Fund,
    { companyId }: { companyId: string },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HF_SINGLE_FUND.replace(":companyId", companyId).replace(
        ":fundId",
        ""
      )
    );

    const serializedFund = serialize(Fund, fund);
    const payload = { fund: serializedFund };
    axiosInstance
      .post(API_URL, payload)
      .then((res) => {
        const data = deserialize(Fund, res.data["fund"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateFund(
    fund: Fund,
    { companyId }: { companyId: string },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HF_SINGLE_FUND.replace(":companyId", companyId).replace(
        ":fundId",
        fund.id.toString()
      )
    );
    const serializedFund = serialize(Fund, fund);
    const payload = { fund: serializedFund };
    axiosInstance
      .put(API_URL, payload)
      .then((res) => {
        const data = deserialize(Fund, res.data["fund"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteFund(
    id: number,
    { companyId }: { companyId: string },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HF_SINGLE_FUND.replace(":companyId", companyId).replace(
        ":fundId",
        id.toString()
      )
    );
    axiosInstance
      .delete(API_URL)
      .then(({ data }: { data: Fund }) => {
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static async requestKeyTerm(
    params: { redeem_dox_coins?: boolean },
    id: string,
    onSuccess: (doxCoin: number) => void,
    onFinal?: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(
        ApiRoutes.REQUEST_KEY_TERM?.replace(
          ":userRole",
          getUserType()
        )?.replace(":id", id),
        {
          params,
        }
      );
      Notification({
        message: "Hedge Fund",
        description: "Key terms requested successfully",
        type: NotificationTypes.SUCCESS,
      });
      onSuccess(data?.dox_coins);
    } catch (error) {
      // Notification({
      //   message: "Hedge Fund",
      //   description: "Key Terms request failed",
      //   type: NotificationTypes.ERROR,
      // });
    } finally {
      onFinal && onFinal();
    }
  }

  static fetchSingleFund(
    id: string,
    { companyId }: { companyId: string },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HF_SINGLE_FUND.replace(":companyId", companyId).replace(
        ":fundId",
        id
      )
    );
    axiosInstance
      .get(API_URL)
      .then((res) => {
        const data = deserialize(Fund, res.data["fund"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchOnboardingItem(
    id: string,
    params: { search_text: string; category: string },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HF_FUNDS_ONBOARDING.replace(":fundId", id)
    );
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        const data = deserialize(
          OnboardingItem,
          response.data["onboarding_items"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createOnboardingItem(
    id: number,
    onboardingItem: OnboardingItem,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HF_FUNDS_ONBOARDING.replace(":fundId", id.toString())
    );
    const serializedItem = serialize(OnboardingItem, onboardingItem);
    const payload = { onboarding_item: serializedItem };
    axiosInstance
      .post(API_URL, payload)
      .then((res) => {
        const data = deserialize(OnboardingItem, res.data["onboarding_item"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateOnboardingItem(
    id: number,
    onboardingItem: OnboardingItem,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HF_FUNDS_ONBOARDING.replace(":fundId", id.toString()) +
      "/" +
      onboardingItem.id
    );
    const serializedItem = serialize(OnboardingItem, onboardingItem);
    //console.log(serializedItem);
    const payload = { onboarding_item: serializedItem };
    axiosInstance
      .put(API_URL, payload)
      .then((res) => {
        const data = deserialize(OnboardingItem, res.data["onboarding_item"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteOnboardingItem(
    { id, itemId }: { id: number; itemId: number },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HF_FUNDS_ONBOARDING.replace(":fundId", id.toString()) +
      "/" +
      itemId
    );
    axiosInstance
      .delete(API_URL)
      .then((res) => {
        onSuccess(res);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchOtherItemsList(
    id: string,
    params: { search_text: string },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HF_FUNDS_OTHERS.replace(":fundId", id)
    );
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        const data = deserialize(OtherItem, response.data["other_items"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createOtherItemsList(
    id: number,
    otherItem: OtherItem,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HF_FUNDS_OTHERS.replace(":fundId", id.toString())
    );
    const serializedItem = serialize(OtherItem, otherItem);
    const payload = { other_item: serializedItem };
    axiosInstance
      .post(API_URL, payload)
      .then((res) => {
        const data = deserialize(OtherItem, res.data["other_item"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
  static updateOtherItemsList(
    id: number,
    otherItem: OtherItem,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HF_FUNDS_OTHERS.replace(":fundId", id.toString()) +
      "/" +
      otherItem.id
    );
    const serializedItem = serialize(OtherItem, otherItem);
    const payload = { other_item: serializedItem };
    axiosInstance
      .put(API_URL, payload)
      .then((res) => {
        const data = deserialize(OtherItem, res.data["other_item"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteOtherItemsList(
    { id, itemId }: { id: number; itemId: number },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HF_FUNDS_OTHERS.replace(":fundId", id.toString()) + "/" + itemId
    );
    axiosInstance
      .delete(API_URL)
      .then((res) => {
        onSuccess(res);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchNotes(
    noteType: string,
    noteId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.NOTES_TYPE_AND_ID.replace(":noteType", noteType).replace(
        ":noteId",
        noteId
      )
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = response.data["note"];
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
  static updateNotes(
    noteType: string,
    noteId: string,
    note: { description: string },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.NOTES_TYPE_AND_ID.replace(":noteType", noteType).replace(
        ":noteId",
        noteId
      )
    );
    const payload = { note: { description: note?.description || "" } };
    axiosInstance
      .put(API_URL, payload)
      .then((response) => {
        const data = response.data["note"];
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchNotifications(
    params: { notificationType: string; notificationId: string },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const { notificationType, notificationId } = params;
    const API_URL = generatePath(
      ApiRoutes.NOTIFICATIONS_TYPE_AND_ID.replace(
        ":actionableType",
        notificationType
      ).replace(":actionableId", notificationId)
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          NotificationModel,
          response.data["action_items"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createNotifications(
    params: { notificationType: string; notificationId: string },
    notification: NotificationModel,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const { notificationType, notificationId } = params;
    const API_URL = generatePath(
      ApiRoutes.NOTIFICATIONS_TYPE_AND_ID.replace(
        ":actionableType",
        notificationType
      ).replace(":actionableId", notificationId)
    );
    const serializedItem = serialize(NotificationModel, notification);
    const payload = { action_item: serializedItem };
    axiosInstance
      .post(API_URL, payload)
      .then((response) => {
        const data = deserialize(
          NotificationModel,
          response.data["action_item"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateNotificationCompletion(
    params: { notificationType: string; notificationId: string },
    notification: NotificationModel,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const { notificationType, notificationId } = params;
    const API_URL = generatePath(
      ApiRoutes.NOTIFICATIONS_UPDATE_COMPLETION.replace(
        ":actionableType",
        notificationType
      )
        .replace(":actionableId", notificationId)
        .replace(":notificationId", notification?.id?.toString())
    );
    axiosInstance
      .put(API_URL)
      .then((response) => {
        const data = deserialize(
          NotificationModel,
          response.data["action_item"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
}

export class HLFundsService {
  static fetchFunds(
    params: {
      status?: FundStatusBE;
      search_text?: string;
      meta?: boolean;
      company_ids?: string[];
      order_by?: "recently_viewed";
      order_direction?: "asc" | "dsc";
      page?: number;
      per_page?: number;
    },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_FUNDS);
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        const data = deserialize(Fund, response.data["funds"]);
        const pagination = deserialize(PaginationMeta, response.data["meta"]);
        onSuccess(data, pagination);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createFund(
    fund: Fund,
    { companyId }: { companyId: string },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HL_SINGLE_FUND.replace(":companyId", companyId).replace(
        ":fundId",
        ""
      )
    );
    const serializedFund = serialize(Fund, fund);
    const payload = { fund: serializedFund };
    axiosInstance
      .post(API_URL, payload)
      .then((res) => {
        const data = deserialize(Fund, res.data["fund"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateFund(
    fund: Fund,
    { companyId }: { companyId: string },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HL_SINGLE_FUND.replace(":companyId", companyId).replace(
        ":fundId",
        fund.id.toString()
      )
    );
    const serializedFund = serialize(Fund, fund);
    const payload = { fund: serializedFund };
    axiosInstance
      .put(API_URL, payload)
      .then((res) => {
        const data = deserialize(Fund, res.data["fund"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteFund(
    id: number,
    { companyId }: { companyId: string },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HL_SINGLE_FUND.replace(":companyId", companyId).replace(
        ":fundId",
        id.toString()
      )
    );
    axiosInstance
      .delete(API_URL)
      .then(({ data }: { data: Fund }) => {
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchSingleFund(
    id: string,
    { companyId }: { companyId: string },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HL_SINGLE_FUND.replace(":companyId", companyId).replace(
        ":fundId",
        id
      )
    );
    axiosInstance
      .get(API_URL)
      .then((res) => {
        const data = deserialize(Fund, res.data["fund"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchOnboardingItem(
    id: string,
    params: { search_text: string; category: string },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HL_FUNDS_ONBOARDING.replace(":fundId", id)
    );
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        const data = deserialize(
          OnboardingItem,
          response.data["onboarding_items"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createOnboardingItem(
    id: number,
    onboardingItem: OnboardingItem,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HL_FUNDS_ONBOARDING.replace(":fundId", id.toString())
    );
    const serializedItem = serialize(OnboardingItem, onboardingItem);
    const payload = { onboarding_item: serializedItem };
    axiosInstance
      .post(API_URL, payload)
      .then((res) => {
        const data = deserialize(OnboardingItem, res.data["onboarding_item"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateOnboardingItem(
    id: number,
    onboardingItem: OnboardingItem,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HL_FUNDS_ONBOARDING.replace(":fundId", id.toString()) +
      "/" +
      onboardingItem.id
    );
    const serializedItem = serialize(OnboardingItem, onboardingItem);
    //console.log(serializedItem);
    const payload = { onboarding_item: serializedItem };
    axiosInstance
      .put(API_URL, payload)
      .then((res) => {
        const data = deserialize(OnboardingItem, res.data["onboarding_item"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteOnboardingItem(
    { id, itemId }: { id: number; itemId: number },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HL_FUNDS_ONBOARDING.replace(":fundId", id.toString()) +
      "/" +
      itemId
    );
    axiosInstance
      .delete(API_URL)
      .then((res) => {
        onSuccess(res);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchOtherItemsList(
    id: string,
    params: { search_text: string },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HL_FUNDS_OTHERS.replace(":fundId", id)
    );
    axiosInstance
      .get(API_URL, { params })
      .then((response) => {
        const data = deserialize(OtherItem, response.data["other_items"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createOtherItemsList(
    id: number,
    otherItem: OtherItem,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HL_FUNDS_OTHERS.replace(":fundId", id.toString())
    );
    const serializedItem = serialize(OtherItem, otherItem);
    const payload = { other_item: serializedItem };
    axiosInstance
      .post(API_URL, payload)
      .then((res) => {
        const data = deserialize(OtherItem, res.data["other_item"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
  static updateOtherItemsList(
    id: number,
    otherItem: OtherItem,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HL_FUNDS_OTHERS.replace(":fundId", id.toString()) +
      "/" +
      otherItem.id
    );
    const serializedItem = serialize(OtherItem, otherItem);
    const payload = { other_item: serializedItem };
    axiosInstance
      .put(API_URL, payload)
      .then((res) => {
        const data = deserialize(OtherItem, res.data["other_item"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteOtherItemsList(
    { id, itemId }: { id: number; itemId: number },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.HL_FUNDS_OTHERS.replace(":fundId", id.toString()) + "/" + itemId
    );
    axiosInstance
      .delete(API_URL)
      .then((res) => {
        onSuccess(res);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchNotes(
    noteType: string,
    noteId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(
      ApiRoutes.NOTES_TYPE_AND_ID.replace(":noteType", noteType).replace(
        ":noteId",
        noteId
      )
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = response.data["note"];
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
  static updateNotes(
    noteType: string,
    noteId: string,
    note: { description: string },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    console.log(note)
    const API_URL = generatePath(
      ApiRoutes.NOTES_TYPE_AND_ID.replace(":noteType", noteType).replace(
        ":noteId",
        noteId
      )
    );
    const payload = { note };
    axiosInstance
      .put(API_URL, payload)
      .then((response) => {
        const data = response.data["note"];
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchNotifications(
    params: { notificationType: string; notificationId: string },
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const { notificationType, notificationId } = params;
    const API_URL = generatePath(
      ApiRoutes.NOTIFICATIONS_TYPE_AND_ID.replace(
        ":actionableType",
        notificationType
      ).replace(":actionableId", notificationId)
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          NotificationModel,
          response.data["action_items"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createNotifications(
    params: { notificationType: string; notificationId: string },
    notification: NotificationModel,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const { notificationType, notificationId } = params;
    const API_URL = generatePath(
      ApiRoutes.NOTIFICATIONS_TYPE_AND_ID.replace(
        ":actionableType",
        notificationType
      ).replace(":actionableId", notificationId)
    );
    const serializedItem = serialize(NotificationModel, notification);
    const payload = { action_item: serializedItem };
    axiosInstance
      .post(API_URL, payload)
      .then((response) => {
        const data = deserialize(
          NotificationModel,
          response.data["action_item"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateNotificationCompletion(
    params: { notificationType: string; notificationId: string },
    notification: NotificationModel,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const { notificationType, notificationId } = params;
    const API_URL = generatePath(
      ApiRoutes.NOTIFICATIONS_UPDATE_COMPLETION.replace(
        ":actionableType",
        notificationType
      )
        .replace(":actionableId", notificationId)
        .replace(":notificationId", notification?.id?.toString())
    );
    axiosInstance
      .put(API_URL)
      .then((response) => {
        const data = deserialize(
          NotificationModel,
          response.data["action_item"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchCompanyMeta(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HFUND_COMPANIES);
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(CompanyModel, response.data["companies"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
}
