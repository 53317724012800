import { FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { Partner } from "../../../models/Partner/partner.model";
import { PartnerService } from "../../../services/PartnerService/partner.service";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { ConfirmationAlert } from "../../../shared/components/ConfirmationAlert";
import EmptyState from "../../../shared/components/EmptyState";
import Loader from "../../../shared/components/Loader";
import { PageHeaderComponent } from "../../../shared/components/PageHeader";
import PartnerCard from "../../../shared/components/PartnerCard";
import AuthContainer from "../../../store/containers/AuthContainer";
import { IAuthState } from "../../../store/reducers/authReducer";
import PartnerForm from "./PartnerForm";
import "./partners.scss"

interface PartnersProps extends IAuthState { }

const Partners = (props: PartnersProps) => {

    const {
        userType,
    } = props

    const isAdmin = userType === "HL"

    const [partners, setPartners] = useState<Partner[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [formVisible, setFormVisible] = useState<boolean>(false)
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false)
    const [selectedPartner, setSelectedPartner] = useState<Partner>()

    useEffect(() => {
        fetchPartners()
    }, [])

    const fetchPartners = () => {
        setLoading(true)
        if (isAdmin) {
            fetchHLReports()
        } else {
            fetchHFReports()
        }
    }

    const fetchHFReports = () => {
        PartnerService.fetchHFPartners(
            (partners: Partner[]) => { setPartners(partners) },
            () => { },
            () => {
                setLoading(false)
            }
        )
    }
    const fetchHLReports = () => {
        PartnerService.fetchHFPartners(
            (partners: Partner[]) => { setPartners(partners) },
            () => { },
            () => {
                setLoading(false)
            }
        )
    }

    const openPartnerFormHandler = (partner?: Partner) => {
        setFormVisible(true)
        if (partner)
            setSelectedPartner(partner)
    }

    const openPartnerDeleteHandler = (partner?: Partner) => {
        setDeleteVisible(true)
        if (partner)
            setSelectedPartner(partner)
    }

    const closePartnerFormHandler = (success?: boolean) => {
        setSelectedPartner(undefined)
        setFormVisible(false)
        if (success)
            fetchPartners()
    }

    const closePartnerDeleteHandler = (success?: boolean) => {
        setSelectedPartner(undefined)
        setDeleteVisible(false)
        if (success)
            fetchPartners()
    }

    const submitFormHandler = (values: Partner, helpers: FormikHelpers<any>) => {
        if (!isAdmin) { return }
        setLoading(true)
        const services = (values.services as unknown as string).split(",").map(val => val.trim()).filter(val => val)
        if (values?.id) {
            PartnerService.updateHLPartners(
                {
                    ...values,
                    services
                },
                () => {
                    helpers.resetForm()
                    closePartnerFormHandler(true)
                },
                () => { },
                () => {
                    setLoading(false)
                })
        } else {
            PartnerService.createHLPartners(
                {
                    ...values,
                    services
                },
                () => {
                    helpers.resetForm()
                    closePartnerFormHandler(true)
                },
                () => { },
                () => {
                    setLoading(false)
                })
        }
    }

    const deleteHandler = () => {
        PartnerService.deleteHLPartners(
            selectedPartner?.id,
            () => {
                closePartnerDeleteHandler(true)
            },
            () => { },
            () => { },
        )
    }

    const headerActionButtonsTemplate = isAdmin
        ? <ButtonComponent
            type="primary"
            onClick={() => openPartnerFormHandler()}
        ><i className="icon-add" /> Add New Partner</ButtonComponent>
        : null

    return (
        <div className="partners__container">
            <PageHeaderComponent title="Partners" buttons={headerActionButtonsTemplate} />
            <div className="partners__list">
                {loading
                    ? <Loader />
                    : partners.length
                        ? partners.map(partner => <PartnerCard
                            key={partner?.id}
                            editHandler={openPartnerFormHandler}
                            deleteHandler={openPartnerDeleteHandler}
                            isAdmin={isAdmin}
                            partner={partner} />)
                        : <EmptyState />
                }
            </div>
            <PartnerForm
                closeHandler={closePartnerFormHandler}
                submitHandler={submitFormHandler}
                data={selectedPartner}
                visible={formVisible}
                loading={loading}
            />
            <ConfirmationAlert
                type={"delete"}
                visible={deleteVisible}
                title={`Are you sure to be delete the partner ?`}
                description={selectedPartner?.name}
                logo={selectedPartner?.logoUrl}
                deleteHandler={deleteHandler}
                cancelHandler={() => closePartnerDeleteHandler()}
                closeHandler={() => closePartnerDeleteHandler()}
            ></ConfirmationAlert>
        </div >
    )
}

export default AuthContainer(Partners)