import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { TableComponent } from "../../../../../shared/components/TableComponent";
import EditIcon from "../../../../../assets/icons/edit.svg";
import DeleteIcon from "../../../../../assets/icons/delete.svg";
import { ConfirmationAlert } from "../../../../../shared/components/ConfirmationAlert";
import PlaceholderIcon from "../../../../../assets/placeholder/avatar_placeholder.png";
import { CompanyService } from "../../../../../services/CompanyService/company.service";
import { CompanyModel } from "../../../../../models/HFund/hfund.model";
import { generatePath, useHistory } from "react-router";
import { HFUND, HFUND_COMPANY_DETAILS, HFUND_FUNDS } from "../../../../../routes/appRoutes";
interface HFundCompaniesProps {
  editHandler: (company: CompanyModel) => void;
  refresh: boolean;
}
export const HFundCompanies = ({
  editHandler,
  refresh,
}: HFundCompaniesProps) => {
  const [showDelete, setDeleteVisibility] = useState<CompanyModel>();

  const [companies, setCompanies] = useState<CompanyModel[]>([]);

  const [currentCompany, setCurrentCompany] = useState<CompanyModel>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const [search, setSearch] = useState<string>();

  const history = useHistory();

  useEffect(() => {
    fetchCompanies({ name: search || "" });
  }, [refresh, search]);

  const fetchCompanies = (params: any) => {
    setLoading(true)
    CompanyService.listAllCompanies(
      params,
      (companies) => {
        setLoading(false)
        setCompanies(companies.filter((data) => data.status !== "pending"));
      },
      () => {
        setLoading(false)
      }
    );
  };

  const handleDelete = (companyId: any) => {
    CompanyService.deleteCompany(
      companyId,
      () => {
        setDeleteVisibility(undefined);
        fetchCompanies({});
      },
      () => { }
    );
  };

  return (
    <>
      <ConfirmationAlert
        closeHandler={() => setDeleteVisibility(undefined)}
        visible={!!showDelete?.companyId}
        type="delete"
        logo={PlaceholderIcon}
        deleteHandler={() => handleDelete(showDelete?.companyId)}
        cancelHandler={() => setDeleteVisibility(undefined)}
        title={"Delete " + (showDelete?.companyName || "")}
      />
      <TableComponent
        onSearch={setSearch}
        className="sort-remover bottom-scroll"
        scroll={{ x: 1200, y: 'calc(100vh - 140px - 250px)' }}
        onRow={(index) => {
          setCurrentCompany(companies[index]);
          history.push(
            generatePath(HFUND_FUNDS, {
              id: companies[index]?.companyId,
            })
          );
        }}
        title={"HFund Companies"}
        data={companies}
        loading={loading}
        columns={[
          {
            title: "HFUND COMPANY",
            dataIndex: "companyName",
            key: "companyName",
            width: 170,
            render: (text) => <p className="white-wrap">{text}</p>,
          },
          {
            title: "ADMIN NAME",
            dataIndex: "admin",
            key: "admin",
            width: 200,
            render: (text) => (
              <p className="white-wrap">
                {text?.firstName + " " + text?.lastName}
              </p>
            ),
          },
          {
            title: "EMAIL",
            key: "admin",
            dataIndex: "admin",
            width: 200,
            render: (text) => <p>{text?.email}</p>,
          },
          {
            title: "PHONE NUMBER",
            key: "admin",
            dataIndex: "admin",
            width: 170,
            render: (text) => <p>{text?.mobileNumber}</p>,
          },
          {
            title: "SUBSCRIPTION",
            dataIndex: "subscriptionPlan",
            key: "subscriptionPlan",
            width: 150,
            render: (text) => <p>{text?.name}</p>,
          },
          {
            title: "FUNDS",
            dataIndex: "subscriptionPlan",
            key: "subscriptionPlan",
            width: 50,
            render: (text) => <p>{text?.noOfFunds}</p>,
          },
          {
            title: "AGREEMENTS",
            dataIndex: "subscriptionPlan",
            key: "subscriptionPlan",
            width: 50,
            render: (text) => <p>{text?.maxNoOfAgreements}</p>,
          },
          {
            title: "USERS",
            dataIndex: "subscriptionPlan",
            key: "subscriptionPlan",
            width: 50,
            render: (text) => <p>{text?.noOfUsers}</p>,
          },
          {
            title: "",
            key: "action",
            width: 100,
            render: (text, record) => (
              <Space
                size="middle"
                className="table-actions"
                onClick={(e) => e.stopPropagation()}
              >
                <i
                  className="icon-edit"
                  onClick={() => {
                    editHandler(record);
                  }}
                  title="Edit"
                />
                <i
                  className="icon-delete"
                  onClick={() => {
                    setDeleteVisibility(record);
                  }}
                  title="Delete"
                />
              </Space>
            ),
          },
        ]}
      />
    </>
  );
};
