import * as ApiRoutes from "../../routes/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { KeyTermGroups } from "../../models/KeyTerms/keyTermGroups.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { generatePath } from "react-router";

export default class KeyTermGroupsService {
  static fetchKeyTermGroups(
    agreementTypeId: any,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(generatePath(ApiRoutes.HL_KEY_TERM_GROUPS, { agreementTypeId }))
      .then((response) => {
        const data = deserialize(
          KeyTermGroups,
          response.data["key_term_groups"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in fetching key term groups!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createKeyTermGroups(
    agreementTypeId: any,
    keyTermGroup: KeyTermGroups,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const serializeData = serialize(KeyTermGroups, keyTermGroup);
    const payload = { key_term_group: serializeData };
    axiosInstance
      .post(
        generatePath(ApiRoutes.HL_KEY_TERM_GROUPS, { agreementTypeId }),
        payload
      )
      .then((response) => {
        const data = deserialize(
          KeyTermGroups,
          response.data["key_term_group"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in creating key term group!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateKeyTermGroups(
    agreementTypeId: any,
    keyTermGroup: KeyTermGroups,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_SINGLE_KEY_TERM_GROUP, {
      keyTermGroupId: keyTermGroup.id,
      agreementTypeId,
    });
    const serializeData = serialize(KeyTermGroups, keyTermGroup);
    const payload = { key_term_group: serializeData };
    axiosInstance
      .put(API_URL, payload)
      .then((response) => {
        const data = deserialize(
          KeyTermGroups,
          response.data["key_term_group"]
        );
        onSuccess(data);
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in updating key term group!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteKeyTermGroups(
    agreementTypeId: any,
    keyTermGroupId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = generatePath(ApiRoutes.HL_SINGLE_KEY_TERM_GROUP, {
      keyTermGroupId,
      agreementTypeId,
    });

    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        // Notification({
        //   message: error,
        //   description: "Problem in deleting key term group!",
        //   type: NotificationTypes.ERROR,
        // });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
