import { Skeleton } from "antd";
import Button from "antd/lib/skeleton/Button";
import React from "react";
import "./tableLoader.scss"
interface TableLoaderProps {
    length?: number
    size?: "large" | "small"
}
const TableLoader = (props: TableLoaderProps) => {
    const {
        length = 15,
        size,
    } = props

    const items = new Array(length).fill('')

    return <div className={`table-loader__container ${size}`}>
        {
            items?.map((item, i) => <Skeleton.Button key={i} active={true} size="small" shape="round" />)
        }
    </div>
}

export default TableLoader