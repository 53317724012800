import { Button, Divider, Dropdown, LayoutProps, Menu, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import "./sidebar.scss";
import AppLogo from "../../../../assets/background-images/app-logo.png";
import HedgeLegalLogo from "../../../../assets/background-images/hedge-legal.svg";
import DashboardIcon from "../../../../assets/icons/dashboard.svg";
import HFundIcon from "../../../../assets/icons/hfund.svg";
import PartnersIcon from "../../../../assets/icons/users.svg";
import * as appRoutes from "../../../../routes/appRoutes";
import AgreementsIcon from "../../../../assets/icons/agreements.svg";
import ReportsIcon from "../../../../assets/icons/reports.svg";
import TicketsIcon from "../../../../assets/icons/tickets.svg";
import HLUsersIcon from "../../../../assets/icons/hl-users.svg";
import DoxIcon from "../../../../assets/icons/dox.svg";
import CpIcon from "../../../../assets/icons/cp.svg";
import DiscussionIcon from "../../../../assets/icons/discussion.svg";
import ConfigurationIcon from "../../../../assets/icons/configuration.svg";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import SubMenu from "antd/lib/menu/SubMenu";
import { HFUND, HL_USERS } from "../../../../routes/appRoutes";
import AuthContainer from "../../../../store/containers/AuthContainer";
import { CaretDownOutlined } from "@ant-design/icons";
import { LocalStorage } from "../../../../shared/utils/localStorageHelpers";
import { AuthService } from "../../../../services/AuthService/auth.service";
import DoxCoinsService from "../../../../services/DoxCoinsService/DoxCoins.service";
import { LayoutReducerProps } from "../../../../store/reducers/layoutReducer";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import LayoutContainer from "../../../../store/containers/layoutContainer";
import { Fund } from "../../../../models/Fund/fund.model";
import HLFundForm from "../../../HL/Home/Funds/FundForm";
import HFFundForm from "../../../HF/Home/Funds/FundForm";
import HLAgreementForm from "../../../HL/Home/Agreements/AgreementForm";
import HFAgreementForm from "../../../HF/Home/Agreements/AgreementForm";
import {
  Agreement,
  AgreementStatus,
} from "../../../../models/Agreements/agreement.model";
import {
  HFCounterPartiesService,
  HLCounterPartiesService,
} from "../../../../services/CounterPartiesService/CounterParties.service";
import { CounterParties } from "../../../../models/CounterParties/counterParties.model";
import AgreementTypesService from "../../../../services/KeyTermsService/AgreementTypes.service";
import { AgreementTypes } from "../../../../models/Agreements/agreementTypes.model";
import { MetaService } from "../../../../services/MetaService/meta.service";
import { deserialize } from "serializr";
import {
  FundsService,
  HLFundsService,
} from "../../../../services/FundService/Fund.service";
import ChangePasswordModal from "../../../Auth/ChangePasswordModal";
import { roles } from "../../../../shared/components/HOC/roles";

interface HFSidebarProps extends LayoutReducerProps, AuthReducerProps {

  
 }

const HFSidebar: FC<HFSidebarProps> = (props) => {
  const { user, setUnAuthenticated, setDoxCoin, doxCoins } = props;

  const [fundVisibility, setFundVisibility] = useState(false);

  const [changePasswordVisibility, setChangePasswordVisibility] = useState(false);

  const [agreementVisibility, setAgreementVisibility] = useState(false);

  const [agreementStatus, setAgreementStatus] = useState<any[]>([]);

  const [counterParties, setCounterParties] = useState<any[]>([]);

  const [funds, setFunds] = useState<any[]>([]);

  const [agreementTypes, setAgreementTypes] = useState<any[]>([]);

  const history = useHistory();

  const location = useLocation();

  const { company, getTotalDoxCoins } = DoxCoinsService();

  const isAdmin = user?.role?.includes("hl");

  const isUser = user?.role?.includes("hf_user");
  const isHFUser = [roles.HF_USER, roles.HF_ADMIN, roles.HF_SUPER_ADMIN].includes(user?.role);

  React.useEffect(() => {
    getTotalDoxCoins(setDoxCoin);
  }, []);

  const fetchFundsList = () => {
    (isAdmin ? HLFundsService : FundsService).fetchFunds(
      {},
      (response: Fund[]) => {
        const foramattedData = response.map((fund) => {
          return { label: fund.name, value: fund.id };
        });
        setFunds(foramattedData);
      },
      (error) => { },
      () => { }
    );
  };

  const fetchCPList = () => {
    (isAdmin
      ? HLCounterPartiesService
      : HFCounterPartiesService
    ).fetchCounterParties(
      {},
      (response: CounterParties[]) => {
        const foramattedData = response.map((cp) => {
          return { label: cp.name, value: cp.id };
        });
        setCounterParties(foramattedData);
      },
      () => { },
      () => { }
    );
  };

  const fetchAgreementTypes = () => {
    AgreementTypesService.fetchAgreementTypes(
      (response: AgreementTypes[]) => {
        const foramattedData = response.map((agreement) => {
          return { label: agreement.name, value: agreement.id };
        });
        setAgreementTypes(foramattedData);
      },
      () => { },
      () => { }
    );
  };

  const fetchAgreementStatus = () => {
    MetaService.agreementStatus(
      (response) => {
        const data = deserialize(
          AgreementStatus,
          response.data["agreement_statuses"]
        ) as AgreementStatus[];
        const formattedData = data.map((sts) => {
          return {
            label: sts.name.replaceAll("_", " "),
            value: sts.id,
            color: sts.color,
          };
        });
        setAgreementStatus(formattedData);
      },
      (error) => {
        //console.log(error);
      }
    );
  };

  useEffect(() => {
    fetchFundsList();
    fetchCPList();
    fetchAgreementTypes();
    fetchAgreementStatus();
  }, []);

  const handleLogout = () => {
    // AuthService.logoutUser(
    //   () => {
    setUnAuthenticated();
    localStorage.clear();
    history.push(appRoutes.LOGIN);
    //   },
    //   () => {},
    //   () => {}
    // ).then();
  };

  const AddContent = (
    <div>
      <p onClick={() => setFundVisibility(true)}>New Fund</p>
      <div className="divider" />
      <p onClick={() => setAgreementVisibility(true)}>New Agreement</p>
    </div>
  );

  return (
    <Menu
      className="hf-sidebar"
      defaultSelectedKeys={["1"]}
      triggerSubMenuAction={"click"}
      mode="inline"
      theme="dark"
    >
      <div className="sidebar-app__logo">
        <img src={AppLogo} />
      </div>

     { !isHFUser && <div className="add-new-sidebar">
        <Popover content={AddContent} placement="right">
          <Button type="primary">
            <i className="icon-add" /> New
          </Button>
        </Popover>
      </div> }

      <Menu.Item
        key="1"
        onClick={() => history.push(appRoutes.DASHBOARD)}
        icon={<img src={DashboardIcon} />}
      >
        Dashboard
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<img src={HFundIcon} />}
        onClick={() => {
          history.push(appRoutes.FUNDS);
        }}
      >
        Funds
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={<img src={AgreementsIcon} />}
        onClick={() => {
          history.push(appRoutes.AGREEMENTS);
        }}
      >
        Agreements
      </Menu.Item>
      <Menu.Item
        key="4"
        icon={<img src={ReportsIcon} />}
        onClick={() => history.push(appRoutes.REPORTS)}
      >
        Reports
      </Menu.Item>
      {!isUser && (
        <Menu.Item
          key="5"
          icon={<img src={HLUsersIcon} />}
          onClick={() => {
            history.push(appRoutes.MANAGE_USERS);
          }}
        >
          Manage users
        </Menu.Item>
      )}
      {/* <Menu.Item
        key="6"
        icon={<img src={DiscussionIcon} />}
        onClick={() => {
          history.push(appRoutes.DISCUSSION_BOARD);
        }}
      >
        Discussion Board
      </Menu.Item> */}
      {/* <Menu.Item
        key="7"
        icon={<img src={DoxIcon} />}
        onClick={() => {
          history.push(appRoutes.DOX);
        }}
      >
        {doxCoins} DoX Coins
      </Menu.Item> */}
      <Menu.Item
        key="8"
        icon={<img src={CpIcon} />}
        onClick={() => {
          history.push(appRoutes.CP);
        }}
      >
        CPs / Brokers
      </Menu.Item>
      {/* <Menu.Item
        key="9"
        icon={<img src={PartnersIcon} />}
        onClick={() => {
          history.push(appRoutes.PARTNERS);
        }}
      >
        Partners
      </Menu.Item> */}
      <Dropdown
        className="hf-sidebar__account"
        trigger={["click"]}
        overlay={
          <Menu className="hf-sidebar-account__menu">
            <Menu.Item onClick={() => history.push(appRoutes.ACCOUNT_DETAILS)}>
              <p>Account details</p>
            </Menu.Item>
            <Menu.Item onClick={() => setChangePasswordVisibility(true)}>
              <p>Change password</p>
            </Menu.Item>
            <Menu.Item>
              <p onClick={() => handleLogout()}>Logout</p>
            </Menu.Item>
          </Menu>
        }
      >
        <p>
          {user?.firstName ?? ""}{" "}
          {user?.lastName ?? ""}&nbsp;&nbsp;
          <CaretDownOutlined />
        </p>
      </Dropdown>
      <ChangePasswordModal
        visible={changePasswordVisibility}
        closeHandler={() => setChangePasswordVisibility(false)} />
      <div className="sidebar-bottom__logo">
        <img src={HedgeLegalLogo} />
      </div>
      {isAdmin ? (
        <HLFundForm
          title={"Add new fund"}
          visible={isAdmin && fundVisibility}
          disableTerminatedStatus
          closeHandler={(newFund?: Fund) => {
            // onFundCreation(newFund);
            setFundVisibility(false);
          }}
        />
      ) : (
        <HFFundForm
          title={"Add new fund"}
          visible={!isAdmin && fundVisibility}
          disableTerminatedStatus
          closeHandler={(newFund?: Fund) => {
            // onFundCreation(newFund);
            setFundVisibility(false);
          }}
        />
      )}
      {isAdmin ? (
        <HLAgreementForm
          visible={agreementVisibility}
          funds={funds}
          counterParties={counterParties}
          agreementStatus={agreementStatus}
          agreementTypes={agreementTypes}
          closeHandler={(agreement?: Agreement) => {
            // if (agreement?.id) {
            //   setAgreements((agreements) => [agreement, ...agreements]);
            // }
            setAgreementVisibility(false);
          }}
        />
      ) : (
        <HFAgreementForm
          visible={agreementVisibility}
          funds={funds}
          counterParties={counterParties}
          agreementStatus={agreementStatus}
          agreementTypes={agreementTypes}
          closeHandler={(agreement?: Agreement) => {
            // if (agreement?.id) {
            //   setAgreements((agreements) => [agreement, ...agreements]);
            // }
            setAgreementVisibility(false);
          }}
        />
      )}
    </Menu>
  );
};
export default LayoutContainer(AuthContainer(HFSidebar));
