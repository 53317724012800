import * as Yup from "yup";

export const RegisterValidation = Yup.object().shape({
  email: Yup.string()
    .email("E-mail is not valid!")
    .required("E-mail is required!"),
  companyName: Yup.string()
    .required("Company Name is required!"),
  isAgreed: Yup.boolean()
    .typeError("Please agree terms of use and privacy policy to proceed")
    .required("Please agree terms of use and privacy policy to proceed"),
  companyRoleName: Yup.string()
    .required("Position is required!"),
  mobileNumber: Yup.string()
    // .matches(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/, "Mobile number should be valid!")
    .required("Mobile Number is required!"),
  password: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password should be alphanumeric and contain minimum 8 characters!"
    )
    .required("Password is required!"),
  confirmPassword: Yup.string()
    .required("Confirm password is required!")
    .oneOf(
      [Yup.ref("password")],
      "Both passwords need to be the same"
    ),
});
