
import {serializable, alias, object, list, primitive} from 'serializr';

export class OtherNotification { 
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('title', primitive()))
	title?: string;

	@serializable(alias('description', primitive()))
	description?: string;

	@serializable(alias('redirectable_id', primitive()))
	redirectableId?: string;

	@serializable(alias('is_redirectable', primitive()))
	isRedirectable?: boolean;

	@serializable(alias('is_seen', primitive()))
	isSeen?: boolean;
}