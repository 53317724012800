import { createStore, applyMiddleware } from "redux";

// import thunk from 'redux-thunk';

// import { composeWithDevTools } from 'redux-devtools-extension';

const initialState = { 
  things: [
    {
      name: "test",
      guid: "123"
    }
  ] 
};

// import rootReducer from "./reducers";

function rootReducer(state, action){
    console.log(action.type);
    switch(action.type){
        default:
            return state
    }
}

export default function configureStore() {
  const store = createStore(
    rootReducer,
    initialState);
  return store;
}