
import {serializable, alias, object, list, primitive} from 'serializr';

export class Payment { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('date', primitive()))
	date?: string;

	@serializable(alias('company_id', primitive()))
	companyId?: number;

	@serializable(alias('company_name', primitive()))
	companyName?: string;

	@serializable(alias('subscription_type', primitive()))
	subscriptionType?: string;

	@serializable(alias('amount', primitive()))
	amount?: number;

	@serializable(alias('payment_status', primitive()))
	paymentStatus?: string;

}