import {
  CaretDownOutlined,
  DownloadOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { Space, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ButtonComponent from "../../../../../../shared/components/ButtonComponent";
import PopoverComponent from "../../../../../../shared/components/PopoverComponent";
import { TableComponent } from "../../../../../../shared/components/TableComponent";
import CloseIcon from "../../../../../../assets/icons/closeCircle.svg";
import DownloadIcon from "../../../../../../assets/icons/download.svg";
import NotebookIcon from "../../../../../../assets/icons/notebook.svg";
import {
  Agreement,
  RelatedAgreements,
} from "../../../../../../models/Agreements/agreement.model";
import RelatedDocumentsForm from "./RelatedDocumentsForm";
import { NotificationTypes } from "../../../../../../enums/notificationTypes";
import { HFAgreementService } from "../../../../../../services/AgreementService/agreement.service";
import Notification from "../../../../../../shared/components/Notification";
import { ConfirmationAlert } from "../../../../../../shared/components/ConfirmationAlert";
import TableNotes from "../../../../../../shared/components/TableNotes";
import AgreementModal from "../../../../../../shared/components/AgreementModal";
import TableFilterDropDown from "../../../../../../shared/components/TableFilterDropDown";
import { sorter, dateSorter } from "../../../../../../shared/utils/tableSorter";
import { AgreementTypes } from "../../../../../../models/Agreements/agreementTypes.model";
import { CounterParties } from "../../../../../../models/CounterParties/counterParties.model";
import { Fund } from "../../../../../../models/Fund/fund.model";
import { HFCounterPartiesService } from "../../../../../../services/CounterPartiesService/CounterParties.service";
import { FundsService } from "../../../../../../services/FundService/Fund.service";
import AgreementTypesService from "../../../../../../services/KeyTermsService/AgreementTypes.service";
import { fileDownloadFromUrl } from "../../../../../../shared/utils/fileDownloader";

interface RelatedDocumentsProps {
  agreement: Agreement;
  onAgreementUpdate?: Function;
}

const RelatedDocuments = (props: RelatedDocumentsProps) => {
  const { agreement } = props;
  const [formVisible, setFormVisible] = useState<boolean>();
  const [documents, setDocuments] = useState<RelatedAgreements[]>([]);
  const [successVisible, setSuccessVisibility] = useState(false);
  const [agreementModalStatus, setAgreementModalStatus] =
    useState<{ relatedAgreement: RelatedAgreements; visibility: boolean }>();
  const [counterParties, setCounterParties] = useState<any[]>([]);
  const [funds, setFunds] = useState<any[]>([]);
  const [agreementTypes, setAgreementTypes] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [availableDates, setAvailableDates] = useState<any[]>([]);
  const [sortInfo, setSortInfo] = useState<{ key: string; order: string }>();
  const [filterInfo, setFilterInfo] = useState({});
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    fetchFundsList();
    fetchCPList();
    fetchAgreementTypes();
  }, []);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setTableLoading(true);
      fetchDocuments(filterInfo)
    }, 500)
    return () => {
      clearTimeout(searchTimeout)
    }
  }, [searchText, filterInfo])

  const fetchDocuments = (filter) => {
    HFAgreementService.fetchRelatedAgreements(
      agreement,
      {
        ...filter,
        search_text: searchText,
        unrelated: false,
      },
      (response) => {
        setDocuments(response);
        const allAvailableDates = response.map((amendment) => amendment?.date);
        if (!availableDates?.length && allAvailableDates?.length) {
          const uniqueDates = [];
          allAvailableDates.forEach((date) => {
            if (!uniqueDates.includes(date) && !!date) {
              uniqueDates.push(date);
            }
          });
          setAvailableDates(
            uniqueDates.map((date) => {
              return {
                label: moment(date).format("DD MMM, YYYY"),
                value: date,
              };
            })
          );
        }
      },
      (error) => {
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to fetch documents",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => {
        setTableLoading(false)
      }
    );
  };

  const fetchCPList = () => {
    HFCounterPartiesService.fetchCounterParties(
      {},
      (response: CounterParties[]) => {
        const foramattedData = response.map((cp) => {
          return { label: cp.name, value: cp.id };
        });
        setCounterParties(foramattedData);
      },
      () => { },
      () => { }
    );
  };

  const fetchFundsList = () => {
    FundsService.fetchFunds(
      {},
      (response: Fund[]) => {
        const foramattedData = response.map((fund) => {
          return { label: fund.name, value: fund.id };
        });
        setFunds(foramattedData);
      },
      (error) => {
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to fetch funds",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  const fetchAgreementTypes = () => {
    AgreementTypesService.fetchAgreementTypes(
      (response: AgreementTypes[]) => {
        const foramattedData = response.map((agreement) => {
          return { label: agreement.name, value: agreement.id };
        });
        setAgreementTypes(foramattedData);
      },
      () => { },
      () => { }
    );
  };

  const downloadRelatedDocuments = () => {
    HFAgreementService.downloadRelatedDocuments(
      agreement?.id,
      {},
      (response) => {
        setSuccessVisibility(true);
      },
      (error) => {
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to download all the ancillary documents",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };

  const deleteRelatedDocuments = (relatedDocumentId: number) => {
    HFAgreementService.deleteRelatedAgreements(
      agreement,
      relatedDocumentId.toString(),
      (response) => {
        fetchDocuments(filterInfo);
        Notification({
          message: "Hedge Fund",
          description: "Agreement removed",
          type: NotificationTypes.SUCCESS,
        });
      },
      (error) => {
        // Notification({
        //   message: "Hedge Fund",
        //   description: "Unable to remove agreement",
        //   type: NotificationTypes.ERROR,
        // });
      },
      () => { }
    );
  };


  const sortHandler = (key: string, order: string) => {
    setSortInfo({
      key,
      order,
    });
  };

  const filterHandler = (key: string, value: any) => {
    const currentFilter = { ...filterInfo };
    currentFilter[key] = value;
    if (JSON.stringify(filterInfo) === JSON.stringify(currentFilter)) {
      return;
    }
    setFilterInfo(currentFilter);
  };
  const filterProperties = (dataIndex: string, sortIndex: string, records) => {
    return {
      filterDropdown: (filterProps) => (
        <TableFilterDropDown
          {...filterProps}
          defaultSelected={filterInfo[dataIndex]}
          onApply={(key, value) => filterHandler(key, value)}
          dataIndex={dataIndex}
          sortIndex={sortIndex}
          checkboxValues={records}
          onSort={sortHandler}
        />
      ),
      filterIcon: () => <CaretDownOutlined />,
    };
  };

  return (
    <>
      <TableComponent
        loading={tableLoading}
        className="sort-remover"
        scroll={{ x: 1050 }}
        pagination={false}
        title="Related Documents"
        columns={[
          {
            title: "FUND",
            dataIndex: "fundName",
            key: "fundName",
            sorter: (a, b) => sorter(a.fundName, b.fundName),
            sortOrder: sortInfo?.key === "fundName" && sortInfo?.order,
            ...filterProperties(
              "fund_ids",
              "fundName",
              funds
            ),
            width: 300,
            render: (text, data) => <Tooltip placement="topLeft" title={text || ""}>
              <span
                className="table-details--route"
                onClick={() => {
                  HFAgreementService.fetchSingleAgreements(
                    data?.agreementId,
                    (relatedAgreement) => {
                      setAgreementModalStatus({
                        relatedAgreement,
                        visibility: true,
                      })
                    },
                    () => { },
                    () => { },
                  )
                }
                }
              >
                {text}
              </span>
            </Tooltip>
          },
          {
            title: "CP NAME",
            dataIndex: "counterPartyName",
            key: "counterPartyName",
            sorter: (a, b) => sorter(a.counterPartyName, b.counterPartyName),
            sortOrder: sortInfo?.key === "counterPartyName" && sortInfo?.order,
            ...filterProperties(
              "counter_party_ids",
              "counterPartyName",
              counterParties
            ),
            width: 200,
            render: (text) => <Tooltip placement="topLeft" title={text || ""}>
              <span>{text ?? ""}</span>
            </Tooltip>
          },
          {
            title: "AGREEMENT NAME",
            dataIndex: "agreementName",
            key: "agreementName",
            width: 200,
            render: (text) => <Tooltip placement="topLeft" title={text || ""}>
              <span>{text ?? ""}</span>
            </Tooltip>
          },
          {
            title: "TYPE",
            dataIndex: "agreementTypeName",
            key: "agreementTypeName",
            sorter: (a, b) => sorter(a.agreementTypeName, b.agreementTypeName),
            sortOrder: sortInfo?.key === "agreementTypeName" && sortInfo?.order,
            ...filterProperties(
              "agreement_type_ids",
              "agreementTypeName",
              agreementTypes
            ),
            width: 150,
            render: (text) => <Tooltip placement="topLeft" title={text?.replaceAll("_", " ") || ""}>
              <span>{text && text?.replaceAll("_", " ")}</span>
            </Tooltip>
          },
          {
            title: "DATE",
            dataIndex: "agreement_date",
            key: "agreement_date",
            sorter: (a, b) => dateSorter(a.agreement_date, b.agreement_date),
            sortOrder: sortInfo?.key === "agreement_date" && sortInfo?.order,
            ...filterProperties("agreement_date", "date", availableDates),
            width: 150,
            render: (text) => (
              <span>{text ? moment(text).format("DD MMM, YYYY") : "--"}</span>
            ),
          },
          {
            title: "",
            key: "action",
            width: 50,
            render: (text, record) => (
              <Space size="middle">
                <PopoverComponent
                  showTitleButton={true}
                  title="Notes"
                  content={
                    <TableNotes id={record?.id} category="RelatedAgreement" />
                  }
                >
                  <img
                    className="table-action-icon"
                    src={NotebookIcon}
                    onClick={() => {
                    }}
                    alt=''
                    title="Notes"
                  />
                </PopoverComponent>
                <img
                  className="table-action-icon"
                  src={DownloadIcon}
                  onClick={() => {
                    HFAgreementService.fetchSingleAgreements(
                      record?.agreementId,
                      (data) => {
                        fileDownloadFromUrl(data)
                      },
                      () => { },
                      () => { },
                    )
                  }}
                  alt=''
                  title="Download"
                />
                <img
                  className="table-action-icon"
                  src={CloseIcon}
                  onClick={() => {
                    deleteRelatedDocuments(record?.id);
                  }}
                  alt=''
                  title="Delete"
                />
              </Space>
            ),
          },
        ]}
        data={documents}
        showSearch={true}
        onSearch={setSearchText}
        showAdd={true}
        onAdd={() => {
          setFormVisible(true);
        }}
        additionalButtons={
          <>
            <ButtonComponent
              type="default"
              size="large"
              icon={<DownloadOutlined />}
              onClick={() => {
                downloadRelatedDocuments();
              }}
            />
            <ButtonComponent
              type="default"
              size="large"
              icon={<i className="icon-export primary" />}
              onClick={() => {
                HFAgreementService.exportRelatedAgreements(
                  agreement,
                  { ...filterInfo },
                  () => { },
                  () => { },
                  () => { },
                )
              }
              }
            />
          </>
        }
      />
      <RelatedDocumentsForm
        visible={formVisible}
        agreement={agreement}
        closeHandler={(success?: boolean) => {
          if (success) {
            fetchDocuments(filterInfo);
          }
          setFormVisible(false);
        }}
      ></RelatedDocumentsForm>
      <ConfirmationAlert
        closeHandler={() => {
          setSuccessVisibility(false);
        }}
        visible={successVisible}
        type="success"
        customButtons={
          <div className="download-success--buttons">
            <ButtonComponent
              onClick={() => {
                setSuccessVisibility(false);
              }}
              type="ghost"
            >
              Close
            </ButtonComponent>
          </div>
        }
        cancelHandler={() => {
          setSuccessVisibility(false);
        }}
        title={"Download request successful"}
        description="Link to the documents will be shared with your mail id shortly.."
      />
      <AgreementModal
        agreement={agreement}
        relatedAgreement={agreementModalStatus?.relatedAgreement}
        visible={agreementModalStatus?.visibility}
        closeHandler={() => {
          setAgreementModalStatus({
            relatedAgreement: null,
            visibility: false,
          });
        }}
      ></AgreementModal>
    </>
  );
};

export default RelatedDocuments;
