import { Form, Row, Col } from "antd";
import { Formik, FormikValues } from "formik";
import React, { useState } from "react";
import { SupportTicket } from "../../../../../models/Ticket/ticket.model";
import { VideoModel } from "../../../../../models/Video/video.model";
import InputField from "../../../../../shared/components/InputField";
import { UIModal } from "../../../../../shared/components/UIModal";
import "./supportTicketForm.scss"

interface VideoFormProps {
    loading: boolean
    visible: boolean
    closeHandler: () => void
    submitHandler: (values: FormikValues, resetForm: Function) => void
}

const SupportTicketForm = (props: VideoFormProps) => {

    const {
        loading,
        visible,
        closeHandler,
        submitHandler,
    } = props

    const [supportTicketData, setSupportTicketData] = useState<SupportTicket>(new SupportTicket())

    return (
        <Formik enableReinitialize
            initialValues={supportTicketData}
            onSubmit={() => { }}
        >
            {({ setFieldValue, errors, values, dirty, resetForm, isValid, setFieldTouched }) => {
                return (
                    <UIModal
                        className="support-ticket-form__modal"
                        visible={visible}
                        closeModal={closeHandler}
                        title="Raise New Ticket"
                        submitProps={{
                            text: "Submit",
                            clickHandler: () => {
                                submitHandler(values, resetForm);
                            },
                            disabled: !isValid || !dirty,
                            loading: loading
                        }}
                        cancelProps={{
                            text: "Cancel",
                            clickHandler: () => {
                                resetForm();
                                closeHandler();
                            },
                        }}
                    >
                        <Form>
                            <Row>
                                <Col sm={24} className="support-ticket-form__field">
                                    <InputField
                                        className="ticket-title"
                                        placeholder={"Type your ticket title..."}
                                        name="title"
                                        type="text"
                                        showLabel={false}
                                        value={values?.title}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={24} className="support-ticket-form__field">
                                    <InputField
                                        className="ticket-desc"
                                        placeholder={"Type your ticket description..."}
                                        name="description"
                                        type="textarea"
                                        showLabel={false}
                                        value={values?.description}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </UIModal>
                );
            }}
        </Formik>
    );
}

export default SupportTicketForm