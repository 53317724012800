import { Comment } from "../Comment/comment.model";

import { serializable, alias, object, list, primitive } from "serializr";

export class Topic {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("company_logo_url", primitive()))
  companyLogoUrl?: string;

  @serializable(alias("company_name", primitive()))
  companyName?: string;

  @serializable(alias("user_name", primitive()))
  userName?: string;

  @serializable(alias("date", primitive()))
  date?: string;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("top_comment", object(Comment)))
  topComment?: Comment;

  @serializable(alias("no_of_comments", primitive()))
  noOfComments?: number;

  @serializable(alias("created_by_id", primitive()))
  createdById?: number;

  @serializable(alias("likes", primitive()))
  likes?: number;

  @serializable(alias("dislikes", primitive()))
  dislikes?: number;

  @serializable(alias("you_like", primitive()))
  youLike?: string;

  @serializable(alias("you_dislike", primitive()))
  youDislike?: string;

  @serializable(alias('created_by_id', primitive()))
  createdBy?: number;

}
