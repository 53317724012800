import { Button, Col, Row } from "antd";
import { Formik, Form } from "formik";
import React, { FC } from "react";
import { DoxCoinAllocation } from "../../../../models/DoxCoinAllocation/doxCoinAllocation.model";
import DoxCoinsService from "../../../../services/DoxCoinsService/DoxCoins.service";
import InputField from "../../../../shared/components/InputField";

interface CoinAllocationFormProps {
  formValues: DoxCoinAllocation;
  successHandler: (doxCoinAllocation: DoxCoinAllocation) => void;
  closeModalHandler: () => void;
}

const CoinAllocationForm: FC<CoinAllocationFormProps> = (props) => {
  const { formValues, closeModalHandler, successHandler } = props;

  const { loading, updateCoinsUpdateMechanism } = DoxCoinsService();

  const handleSubmit = async (doxCoinAllocation: DoxCoinAllocation) => {
    const updatedDoxCoinAllocation = await updateCoinsUpdateMechanism(doxCoinAllocation);
    if (updatedDoxCoinAllocation) {
      successHandler(updatedDoxCoinAllocation);
    }
  };

  const formikProps = {
    initialValues: formValues ?? new DoxCoinAllocation(),
    enableReinitialize: true,
    onSubmit: handleSubmit,
  };

  return (
    <div className="coin-allocation-form">
      <Formik {...formikProps}>
        {({ values }) => (
          <Form>
            <h2 className="mb-2">{values.actionsType}</h2>
            <InputField type="text" placeholder="DoX Coins" showLabel value={values.allocatedNoOfCoins} name="allocatedNoOfCoins" />
            <Row gutter={[15, 15]} className="mtp-6">
              <Col span={12}>
                <Button type="text" className="full-width" onClick={closeModalHandler}>Cancel</Button>
              </Col>
              <Col span={12}>
                <Button type="primary" className="full-width" htmlType="submit" loading={loading}>Update</Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CoinAllocationForm;
