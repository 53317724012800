import { Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { CaretRightOutlined } from '@ant-design/icons';
import "./faq.scss"
import FaqForm from "./FaqForm";
import { Faq as FaqModel } from "../../../../models/Faq/faq.model";
import { ConfirmationAlert } from "../../../../shared/components/ConfirmationAlert";
import { HelpService } from "../../../../services/HelpService/helpService.service";
import Loader from "../../../../shared/components/Loader";
import EmptyState from "../../../../shared/components/EmptyState";

interface FaqProps {
    faqFormVisible: boolean
    isAdmin: boolean
    setFaqFormVisible: (visiblity: boolean, faq?: FaqModel) => void
}

const { Panel } = Collapse
const Faq = (props: FaqProps) => {

    const {
        isAdmin,
        faqFormVisible,
        setFaqFormVisible,
    } = props

    const [selectedFaqData, setSelectedFaqData] = useState<FaqModel>(new FaqModel())
    const [faqDeleteVisible, setFaqDeleteVisible] = useState<boolean>(false)
    const [faqs, setFaqs] = useState<FaqModel[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)

    useEffect(() => {
        fetchFaqs()
    }, [])

    const fetchFaqs = () => {
        if (isAdmin) {
            fetchHLFaqs()
        } else {
            fetchHFFaqs()
        }
    }


    const fetchHLFaqs = () => {
        setLoading(true)
        HelpService.fetchHLFaqs(
            (tickets: FaqModel[]) => {
                setFaqs(tickets)
            },
            (error) => { },
            () => {
                setLoading(false)
            },
        )
    }

    const fetchHFFaqs = () => {
        setLoading(true)
        HelpService.fetchHFFaqs(
            (tickets: FaqModel[]) => {
                setFaqs(tickets)
            },
            (error) => { },
            () => {
                setLoading(false)
            },
        )
    }


    const submitHandler = (values: FaqModel, resetForm: Function) => {
        setSubmitting(true)
        if (values?.id) {
            HelpService.editHLFaqs(
                values,
                () => {
                    resetForm()
                    setFaqFormVisible(false)
                },
                () => { },
                () => {
                    setSubmitting(false)
                    fetchFaqs()
                }
            );
        } else {
            HelpService.createHLFaqs(
                values,
                () => {
                    resetForm()
                    setFaqFormVisible(false)
                },
                () => { },
                () => {
                    setSubmitting(false)
                    fetchFaqs()
                }
            );
        }

    }

    const closeFormHandler = () => {
        setSelectedFaqData(new FaqModel())
        setFaqFormVisible(false)
    }

    const closeDeleteHandler = () => {
        setSelectedFaqData(new FaqModel())
        setFaqDeleteVisible(false)
    }


    const deleteHandler = () => {
        HelpService.deleteHLFaqs(
            selectedFaqData.id,
            () => {
                fetchFaqs()
                setFaqFormVisible(false)
                closeDeleteHandler()
            },
            () => { },
            () => {
            }
        );
    }

    const panelActionsGenerator = (faq: FaqModel) => {
        if (isAdmin) {
            return <div className="faq-panel--actions">
                <i className="icon-edit" onClick={(e) => {
                    e.stopPropagation()
                    setSelectedFaqData(faq)
                    setFaqFormVisible(true)
                }}></i>
                <i className="icon-delete" onClick={(e) => {
                    e.stopPropagation()
                    setSelectedFaqData(faq)
                    setFaqDeleteVisible(true)
                }}></i>
            </div>
        }
        return null
    }

    return <div className="faq__container">
        {loading
            ? <Loader />
            : faqs.length
                ? <Collapse accordion
                    defaultActiveKey={[faqs[0]?.id]}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                >
                    {faqs?.map(faq => <Panel header={faq?.title} key={faq?.id} extra={panelActionsGenerator(faq)}>
                        <p>{faq?.descrption}</p>
                    </Panel>)}
                </Collapse>
                : <EmptyState centerAlign={true} />}
        <FaqForm
            visible={faqFormVisible}
            closeHandler={closeFormHandler}
            submitHandler={submitHandler}
            faqData={selectedFaqData}
            loading={submitting}
        />
        <ConfirmationAlert
            type="delete"
            visible={faqDeleteVisible}
            title="Are your sure to delete the FAQ"
            description={selectedFaqData?.title}
            deleteHandler={deleteHandler}
            closeHandler={closeDeleteHandler}
            cancelHandler={closeDeleteHandler}
        ></ConfirmationAlert>
    </div>
}

export default Faq