import { Button, Col, Row } from "antd";
import { Formik, Form } from "formik";
import React, { FC } from "react";
import { useParams } from "react-router";
import { Company } from "../../../../../models/Company/company.model";
import DoxCoinsService from "../../../../../services/DoxCoinsService/DoxCoins.service";
import InputField from "../../../../../shared/components/InputField";
import LayoutContainer from "../../../../../store/containers/layoutContainer";
import { LayoutReducerProps } from "../../../../../store/reducers/layoutReducer";

interface DoXCoinUpdateFormProps extends LayoutReducerProps{
  type: "Add" | "Reduce";
  closeHandler: () => void;
}

const DoXCoinUpdateForm: FC<DoXCoinUpdateFormProps> = (props) => {
  const { type, setDoxCoin, closeHandler } = props;

  const { updateDoxCoinHL, loading } = DoxCoinsService();

  const params = useParams<{ id: string }>();

  const handleSubmit = async (values: Company) => {
    const company = Object.assign(new Company(), values);
    const updatedCompany = await updateDoxCoinHL(params?.id, type, company);
    closeHandler();
    if(updatedCompany) {
        setDoxCoin(updatedCompany?.doxCoins);
    }
  };

  return (
    <div className="dox-coin-update-form">
      <Formik initialValues={new Company()} onSubmit={handleSubmit}>
        <Form>
          <InputField
            name="doxCoins"
            placeholder="Enter DoX Coins"
            type="number"
          />
          <InputField name="reason" placeholder="Reason" type="textarea" />
          <Row gutter={[20, 0]} className="mt-5">
            <Col span={12}>
              <Button className="full-width" type="text" onClick={closeHandler}>
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button className="full-width" type="primary" htmlType="submit" loading={loading}>
                {type}
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </div>
  );
};

export default LayoutContainer(DoXCoinUpdateForm);
